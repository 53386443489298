import PropTypes from "prop-types";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from "@mui/material";
import { useSelector } from "react-redux";
import BOQ from "../../Pages/Projects/Operational/BOQ.page";

export const FormStepper = ({ steps, page, touched, setTouched }) => {
  const [activeStep, setActiveStep] = useState(0);
  const selectedRow = useSelector((state) => state.selectedRow.selected);
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Paper
            style={{
              marginTop: "3ch",
              padding: "2ch",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ mt: 2, mb: 1 }}>
              Project Timeline Added Successfully!
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                pt: 2,
                gap: "3ch",
              }}
            >
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset} variant="outlined">
                Add Another Main Task
              </Button>
              <Button
                onClick={() => {
                  navigate(`/View/ViewProject/${selectedRow.id}/DailyWorkLog`);
                }}
                variant="contained"
              >
                Go to Project
              </Button>
            </Box>
            <BOQ project={selectedRow} />
          </Paper>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ mt: 5, mb: 1 }}>{page[activeStep]}</Box>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />

            <Button
              onClick={() => {
                if (touched) {
                  setOpen(true);
                } else {
                  handleNext();
                  if (activeStep === steps.length - 1) {
                    if (
                      location.pathname ===
                      "/View/ViewProject/ProjectAddDetail/AddProjectTask"
                    ) {
                      navigate(
                        "/View/ViewProject/ProjectAddDetail/AddProjectTask",
                      );
                    }
                  }
                }
              }}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
          <Dialog
            open={open}
            onClose={() => {
              setOpen(false);
            }}
          >
            <DialogTitle>There is unsaved data?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                You are about to go to the next step without saving the data are
                you sure?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <Button
                onClick={() => {
                  setOpen(false);
                  setTouched(false);
                  handleNext();
                  if (activeStep === steps.length - 1) {
                    navigate(
                      "/View/ViewProject/ProjectAddDetail/AddProjectTask",
                    );
                  }
                }}
                color="error"
              >
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      )}
    </Box>
  );
};

FormStepper.propTypes = {
  /**
   * Page Component to be rendered
   */
  page: PropTypes.any,
  /**
   * step names
   */
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  finishButton: PropTypes.func,
  loading: PropTypes.bool,
  submitButton: PropTypes.func,
};
