import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
    Box,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { EditRounded } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { patcher, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import { BudgetURL, CostCenterURL } from "../../../../utils/config";
import dateFormatter from "../../../../utils/dateFormater";

const EditBudget = (props) => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState("");
    const [options1, setOptions1] = useState([]);
    const [optionsId1, setOptionsID1] = useState();
    const [optionSelect1, setOptionSelect1] = useState("");
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [BudgetReason, setBudgetReason] = useState(selectedRow.budget_reason);
    const [msg, setMsg] = useState("");

    useEffect(() => {
        if (optionSelect1 === "")
            setOptionSelect1(
                `${selectedRow.cost_center?.id} - ${selectedRow.costCenter}`
            );
    }, [selectedRow, optionSelect1]);

    const BUDGET_REASON = ["Cost Center", "Project"];

    const INITIAL_VALUES = {
        name: selectedRow.name,
        fiscal_year: dateFormatter(selectedRow.fiscal_year),
        project_name: selectedRow.project_name,
        cost_center_id: selectedRow.cost_center?.id,
        isProtectedForEdit: false,
        budget_reason: BudgetReason,
    };

    const FORM_VALIDATION = Yup.object().shape({
        name: Yup.string().min(1),
        fiscal_year: Yup.string().min(1).required("*Required"),
        budget_reason: Yup.number().min(1).required("*Required"),
        project_name: Yup.string().min(1),
        // cost_center_id: Yup.number().min(1),
    });
    useEffect(() => {
        get(CostCenterURL, {
            limit: 10,
            filter: {
                cost_center_code: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.cost_center_code}`
                    );
                });
            res && setOptionsID1(res.data);
            setOptions1(data);
        });
    }, [filter]);

    const formSubmitHandler = (updateData, actions) => {
        setLoading(true);
        const update = {
            id: selectedRow.id,
            updateData,
        };

        patcher(BudgetURL, update).then((res) => {
            setLoading(false);
            // console.log(res.error);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                dispatch(setRefresh(!refreshTable));
                setSuccess(true);
                setError(false);
                props.close();
            }
        });
    };

    return (
        <div>
            <TitleHeader title="Edit Budget" icon={<EditRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        values.budget_reason = BudgetReason;

                        optionsId1.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.cost_center_code}` ===
                                optionSelect1
                            ) {
                                values.cost_center_id = name.id;
                            }
                        });

                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="name"
                                    label="Name"
                                    variant="outlined"
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>Fiscal Year</FormLabel>
                                    <TextFieldWrapper
                                        name="fiscal_year"
                                        variant="outlined"
                                        type="date"
                                    />
                                </FormControl>
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    select
                                    name="budget_reason"
                                    label="Budget Reason"
                                    variant="outlined"
                                    value={BudgetReason}
                                    options={BUDGET_REASON ?? " "}
                                    onChange={(e) => {
                                        setBudgetReason(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="project_name"
                                    label="Project Name"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect1}
                                    loading={options1.length < 1 ? true : false}
                                    disablePortal
                                    options={options1}
                                    onChange={(event, newValue) => {
                                        setOptionSelect1(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Cost Center"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Apply"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default EditBudget;
