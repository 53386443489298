import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import AddInvoiceTracking from "./AddInvoiceTracking.page";
import ViewInvoiceTracking from "./ViewInvoiceTracking.page";

const InvoiceTracking = ({ next, name, handleTabChange }) => {
    const location = useLocation();
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    return (
        <>
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/InvoiceTracking/AddInvoiceTracking` && (
                <AddInvoiceTracking
                    next={next}
                    name={name}
                    handleTabChange={handleTabChange}
                />
            )}
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/InvoiceTracking` && (
                <>
                    <ViewInvoiceTracking />
                </>
            )}
        </>
    );
};

export default InvoiceTracking;
