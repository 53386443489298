import React, { useState } from "react";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import { Divider, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import { useNavigate } from "react-router";

import { caller } from "../../../utils/Caller";
import TextFieldWrapper from "../../../Components/TextFieldWrapper.component";
import { ChangePasswordURL } from "../../../utils/config";
import { SnackBar } from "../../../Components/SnackBar/SnackBar.component";

const ChangePassword = () => {
    const [loading, setloading] = useState(false);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    //----------------------------------FORM VALIDATE--------------------------------------------

    const INITIALVALUES = {
        newPassword: "",
        confirmPassword: "",
    };

    const FORMVALIDATE = Yup.object().shape({
        newPassword: Yup.string()
            .min(8, "Password must be more than 8 characters")
            .required("Password field can not be empty"),
        confirmPassword: Yup.string()
            .min(8)
            .required("Password field can not be empty")
            .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
    });

    //------------------------------------------------------------------------------------------

    const changePasswordHandler = (values) => {
        setloading(true);
        const tempToken = localStorage.getItem("accessToken");
        caller(ChangePasswordURL, {
            newPassword: values.newPassword,
            tempAccessToken: tempToken,
        }).then((res) => {
            setloading(false);
            if (res.data?.success) {
                navigate("/");
            } else {
                setError(true);
            }
        });
    };

    return (
        <div className="middleContainer">
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "2ch",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "2ch",
                }}
            >
                <LockRoundedIcon />
                <Typography variant="h6">Change Password</Typography>
            </div>
            <Divider />
            <Formik
                initialValues={{ ...INITIALVALUES }}
                validationSchema={FORMVALIDATE}
                onSubmit={(values) => {
                    changePasswordHandler(values);
                }}
            >
                <Form
                    style={{
                        display: "flex",
                        marginTop: "5ch",
                        alignItems: "center",
                    }}
                >
                    <TextFieldWrapper
                        name="newPassword"
                        label="New Password"
                        variant="outlined"
                        type="password"
                    />
                    <TextFieldWrapper
                        name="confirmPassword"
                        label="Confirm Password"
                        variant="outlined"
                        type="password"
                    />
                    <LoadingButton
                        loading={loading}
                        type="submit"
                        className="ButtonContained"
                        style={{
                            marginTop: "2ch",
                            justifySelf: "center",
                        }}
                    >
                        {loading ? null : "Proceed"}
                    </LoadingButton>
                </Form>
            </Formik>
            <SnackBar error={error} severity="error" message="Error Occuered" />
        </div>
    );
};

export default ChangePassword;
