import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
    Box,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { caller, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    AttendanceCapturedURL,
    EmployeeURL,
    PunchDeviceURL,
} from "../../../../utils/config";

const AddAttendanceCaptured = () => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState("");
    const [filter2, setFilter2] = useState("");
    const [options1, setOptions1] = useState([]);
    const [optionsId1, setOptionsID1] = useState();
    const [optionSelect1, setOptionSelect1] = useState("");
    const [options2, setOptions2] = useState([]);
    const [optionsId2, setOptionsID2] = useState();
    const [optionSelect2, setOptionSelect2] = useState("");
    const [InOutMode, setInOutMode] = useState(1);
    const [msg, setMsg] = useState("");

    const IN_OUT_MODE = [
        "Check In",
        "Check Out",
        "Break Out",
        "Break In",
        "OT_In",
        "OT_Out",
    ];

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        date: "",
        in_out_mode: InOutMode,
        punch_device_id: "",
        employee_id: "",
    };

    const FORM_VALIDATION = Yup.object().shape({
        date: Yup.string().min(1).required("*Required"),
        in_out_mode: Yup.number().min(1).required("*Required"),
        // punch_device_id: Yup.number().min(1).required("*Required"),
        // employee_id: Yup.number().min(1),
    });
    useEffect(() => {
        get(PunchDeviceURL, {
            limit: 10,
            filter: {
                device_name: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.device_name}`
                    );
                });
            res && setOptionsID1(res.data);
            setOptions1(data);
        });

        get(EmployeeURL, {
            limit: 10,
            filter: {
                first_name: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.first_name}`
                    );
                });
            res && setOptionsID2(res.data);
            setOptions2(data);
        });
    }, [filter, filter2]);

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        caller(AttendanceCapturedURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                actions.resetForm();
                return 1;
            }
        });
    };

    return (
        <div>
            <TitleHeader
                title="Add Attendance Captured"
                icon={<AddRounded />}
            />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        values.in_out_mode = InOutMode;

                        optionsId1.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.device_name}` ===
                                optionSelect1
                            ) {
                                values.punch_device_id = name.id;
                            }
                        });
                        optionsId2.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.first_name}` ===
                                optionSelect2
                            ) {
                                values.employee_id = name.id;
                            }
                        });

                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Box width={"100%"} />
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>Date</FormLabel>
                                    <TextFieldWrapper
                                        name="date"
                                        variant="outlined"
                                        type="date"
                                    />
                                </FormControl>
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    select
                                    name="in_out_mode"
                                    label="In Out Mode"
                                    variant="outlined"
                                    value={InOutMode}
                                    options={IN_OUT_MODE ?? " "}
                                    onChange={(e) => {
                                        setInOutMode(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    loading={options1.length < 1 ? true : false}
                                    disablePortal
                                    options={options1}
                                    onChange={(event, newValue) => {
                                        setOptionSelect1(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Punch Device"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    loading={options2.length < 1 ? true : false}
                                    disablePortal
                                    options={options2}
                                    onChange={(event, newValue) => {
                                        setOptionSelect2(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Employee"
                                            onChange={(event) => {
                                                setFilter2(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default AddAttendanceCaptured;
