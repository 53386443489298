import {
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
    Box,
    Stack,
    Button,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../../Components/TextFieldWrapper.component";
import "../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../../../Components/SnackBar/SnackBar.component.jsx";
import { get } from "../../../utils/Caller";
import TitleHeader from "../../../Components/TitleHeader/TitleHeader.component";
import { EmployeeURL } from "../../../utils/config";
import { PayrollSlipURL } from "../../../utils/config";
import { BsFileEarmarkPdfFill } from "react-icons/bs";

const PayrollSlip = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState("");
    const [options, setOptions] = useState([]);
    const [optionSelect, setOptionSelect] = useState("");
    const [msg, setMsg] = useState("");
    const [action, setAction] = useState(1);
    const [pdfFiles, setPdfFiles] = useState([]);

    const ACTION = ["Get Slip", "Send Slip"];
    const INITIAL_VALUES = {
        fromDate: "",
        toDate: "",
        selectedEmployees: [],
    };

    const FORM_VALIDATION = Yup.object().shape({
        fromDate: Yup.string().required("*Required"),
        toDate: Yup.string().required("*Required"),
    });

    useEffect(() => {
        get(EmployeeURL, {
            limit: 10,
            filter: {
                all: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].first_name}`
                    );
                });
            setOptions(data);
        });
    }, [filter]);

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        let pdf = [];
        get(PayrollSlipURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data) {
                res.data.forEach((pdfData) => {
                    pdf.push(pdfData);
                    setPdfFiles(pdf);
                });
                setSuccess(true);
                setError(false);
                // actions.resetForm();
            }
        });
    };

    const toPdf = (pdfData) => {
        const strPdf = pdfData.pdf;
        const buff = Buffer.from(strPdf);
        const pdfFile = URL.createObjectURL(
            new Blob([buff], {
                type: "application/pdf",
            })
        );
        window.open(pdfFile, "_blank");
    };

    return (
        <div>
            <TitleHeader title="Payroll Slip" icon={<AddRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        optionSelect.forEach((option, index) => {
                            let employee_id = parseInt(option.split("-")[0]);
                            values.selectedEmployees.push(employee_id);
                        });

                        if (action === 1) {
                            //get
                            values.sendOrGet = false;
                        } else if (action === 2) {
                            //get
                            values.sendOrGet = true;
                        }

                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid
                            container
                            spacing={2}
                            style={{ alignItems: "end" }}
                        >
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    select
                                    size="small"
                                    name="action"
                                    label="Action"
                                    variant="outlined"
                                    options={ACTION}
                                    value={action}
                                    onChange={(e) => {
                                        setAction(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>From Date</FormLabel>
                                    <TextFieldWrapper
                                        size="small"
                                        name="fromDate"
                                        variant="outlined"
                                        type="date"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>End Date</FormLabel>
                                    <TextFieldWrapper
                                        size="small"
                                        name="toDate"
                                        variant="outlined"
                                        type="date"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack spacing={3}>
                                    <Autocomplete
                                        multiple
                                        size="small"
                                        getOptionLabel={(option) =>
                                            option || ""
                                        }
                                        filterSelectedOptions
                                        loading={
                                            options.length < 1 ? true : false
                                        }
                                        options={options}
                                        onChange={(event, newValue) => {
                                            setOptionSelect(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Employee"
                                                onChange={(event) => {
                                                    setFilter(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        {pdfFiles.length > 0 && (
                            <Box
                                border={"solid 1px silver"}
                                borderRadius={"5px"}
                                padding={"2ch"}
                                display={"flex"}
                                flexDirection={"row"}
                            >
                                {pdfFiles.map((emp, index) => (
                                    <Button
                                        key={index}
                                        endIcon={<BsFileEarmarkPdfFill color="red"/>}
                                        onClick={(e) => {
                                            toPdf(emp);
                                        }}
                                    >
                                        {`${emp.id} - ${emp.first_name} ${emp.middle_name} `}
                                    </Button>
                                ))}
                            </Box> 
                        )}

                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Submit"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>

            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default PayrollSlip;
