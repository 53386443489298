import {
  Add,
  SearchRounded,
  DeleteRounded,
  PrintRounded,
} from "@mui/icons-material";
import {
  Button,
  InputAdornment,
  TextField,
  IconButton,
  List,
  ListItem,
  Typography,
  LinearProgress,
  CircularProgress,
  Modal,
  Paper,
  TableCell,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TablePagination,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

// import OperationsTable from "../../../../Components/Table/OperationsTable.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { get, deleter } from "../../../../utils/Caller";
import {
  ProjectAllRequestURL,
  ProjectDetailRequestURL,
  ProjectRequestURL,
} from "../../../../utils/config";
import { Columns } from "../../../Dashboard/ProjectDashboard/ColumnsData";

const APPROVAL_STATUS = ["Pending", "Approved", "Rejected", "Checked"];
const REQUEST_TYPE = ["Payment Request", "Manpower Request", "Store"];
const PRIORITY = ["Low", "Medium", "High"];

const ViewRequestForm = () => {
  const [rows, setRows] = useState([]);
  const [column, setColumn] = useState([]);
  const [rowsDetail, setRowsDetail] = useState([]);
  const [loading, setloading] = useState(false);
  const [loadingDetail, setloadingDetail] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const refreshTable = useSelector((state) => state.refreshTable.init);
  const selectedRow = useSelector((state) => state.selectedRow.selected);
  const [filter, setFilter] = useState("");
  const dispatch = useDispatch();
  const [Search, setSearch] = useState("");
  const [fieldName, setfieldName] = useState([]);
  const user = useSelector((state) => state.userState.user);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  useEffect(() => {
    let tableData = [];
    if (rows.length === 0) {
      setloading(true);
    }
    get(ProjectAllRequestURL, {
      limit: 1000,
      filter: { project_id: selectedRow.id, all: filter },
      sort: { creationDate: 0 },
    }).then((res) => {
      // console.log(res);
      res && res.data && setloading(false);
      res &&
        res.data.forEach((data, index) => {
          tableData.push({
            ...data,
            preparedBy: data.prepared_by?.first_name,
            projectName: data.project?.name,
            requestType: REQUEST_TYPE[data.request_type - 1],
            approvalStatus: APPROVAL_STATUS[data.approval_status - 1],
            priorityName: PRIORITY[data.priority - 1],
          });
        });
      setRows(tableData);
    });
  }, [refreshTable, filter, rows.length, selectedRow.id]);

  const closeModal = () => {
    setOpen(false);
  };

  const fetchData = (request_type, id) => {
    setloadingDetail(true);
    get(ProjectDetailRequestURL, {
      limit: 1000,
      filter: { request_type: request_type, id: id },
    }).then((res) => {
      setloadingDetail(false);
      setOpen(true);
      if (res?.data) {
        setRowsDetail(res.data);
      }
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deletePending = (id) => {
    deleter(ProjectRequestURL, { id: id }).then((res) => {
      dispatch(setRefresh(!refreshTable));
    });
  };

  return (
    <div className="ViewProject">
      <div className="SearchField">
        <TextField
          name="searchField"
          label="Search"
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  onClick={(e) => {
                    setloading(true);
                    setFilter(Search);
                    dispatch(setRefresh(!refreshTable));
                  }}
                >
                  <SearchRounded />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <div style={{ display: "flex", gap: "2ch" }}>
          {user.actions?.indexOf("HEAD") === -1 &&
            (user.actions?.indexOf("PROJECT_ONE") !== -1 ||
              user.actions?.indexOf("PROJECT_TWO") !== -1) && (
              <Button
                variant="contained"
                color="success"
                endIcon={<Add />}
                onClick={() => {
                  navigate(
                    `/View/ViewProject/${selectedRow.id}/ProjectRequests/AddProjectRequests`,
                  );
                }}
              >
                Add Request
              </Button>
            )}
        </div>
      </div>
      <div className="TableContainer">
        {loading && <LinearProgress />}
        <List>
          {rows.length > 0 &&
            rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((data, index) => (
                <ListItem
                  style={{
                    display: "flex",
                    borderRadius: "5px",
                    boxShadow: "0 0 15px #e5e5e5",
                    justifyContent: "space-between",
                    padding: "2ch",
                    width: "100%",
                    cursor: "pointer",
                    marginBottom: "2ch",
                  }}
                  onClick={(e) => {
                    let arr = [];
                    if (data.request_type === 1) {
                      setColumn(Columns.requestPayment);
                      Columns.requestPayment.forEach((head, index) =>
                        arr.push(head.field),
                      );
                    } else if (data.request_type === 2) {
                      setColumn(Columns.manpowerRequest);
                      Columns.manpowerRequest.forEach((head, index) =>
                        arr.push(head.field),
                      );
                    } else if (data.request_type === 3) {
                      setColumn(Columns.store);
                      Columns.store.forEach((head, index) =>
                        arr.push(head.field),
                      );
                    }
                    setfieldName(arr);
                    fetchData(data.request_type, data.id);
                  }}
                >
                  <Box>
                    <Typography fontWeight={"bold"}>
                      {data.requestType}
                    </Typography>
                    <Typography>{data.preparedBy}</Typography>
                    <Typography
                      style={{
                        color:
                          data.priority === 3
                            ? "red"
                            : data.priority === 2
                            ? "dodgerblue"
                            : "green",
                        fontWeight: "bolder",
                      }}
                    >
                      {data.priorityName}
                    </Typography>
                    <Typography>
                      {data?.creationDate &&
                        new Date(data?.creationDate).toDateString()}
                    </Typography>
                  </Box>
                  {loadingDetail ? (
                    <CircularProgress />
                  ) : (
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color:
                            data.approval_status === 1
                              ? "dodgerblue"
                              : data.approval_status === 2
                              ? "green"
                              : data.approval_status === 4
                              ? "warning"
                              : "red",
                          fontWeight: "bolder",
                        }}
                      >
                        {data.approvalStatus}
                      </Typography>
                      {data.approval_status === 1 && (
                        <IconButton
                          onClick={() => {
                            deletePending(data.id);
                            setOpen(false);
                          }}
                        >
                          <DeleteRounded color="error" />
                        </IconButton>
                      )}
                    </Box>
                  )}
                </ListItem>
              ))}
        </List>
        <TablePagination
          rowsPerPageOptions={[8, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Modal className="Modal" open={open} onClose={closeModal}>
          <Box
            className="ModalContainer"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              maxWidth: "90%",
              width: "70%",
              objectFit: "fill",
            }}
          >
            <Paper
              style={{
                minWidth: "100%",
                minHeight: "50vh",
                padding: "2ch",
              }}
            >
              <Table>
                <TableHead>
                  {column.map((head, i) => (
                    <TableCell key={i}>{head.headerName}</TableCell>
                  ))}
                </TableHead>
                <TableBody>
                  {rowsDetail.map((rowData, ind) =>
                    rowData?.individual_requests.map((row, i) => (
                      <TableRow key={i}>
                        {fieldName.map((field) => (
                          <TableCell>{row[field]}</TableCell>
                        ))}
                      </TableRow>
                    )),
                  )}
                </TableBody>
              </Table>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "2ch",
                  justifyContent: "right",
                  alignItems: "flex-end",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    minWidth: "30%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography fontWeight={"bolder"}>Vat</Typography>
                  <Typography>{rowsDetail[0]?.vat_amount ?? "---------"}</Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    minWidth: "30%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography fontWeight={"bolder"}>Sub Total</Typography>
                  <Typography>{rowsDetail[0]?.sub_total ?? "---------"}</Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    minWidth: "30%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography fontWeight={"bolder"}>Total</Typography>
                  <Typography>{rowsDetail[0]?.total_amount ?? "---------"}</Typography>
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "2ch",
                }}
              >
                <Typography fontWeight={"bolder"}>Prepared By -</Typography>
                <Typography>
                  {`${rowsDetail[0]?.prepared_by?.first_name} ${rowsDetail[0]?.prepared_by?.last_name}`}
                </Typography>
              </Box>
              {rowsDetail[0]?.checked_by && (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "2ch",
                  }}
                >
                  <Typography fontWeight={"bolder"}>Prepared By -</Typography>
                  <Typography>
                    {`${rowsDetail[0]?.checked_by?.first_name} ${rowsDetail[0]?.checked_by?.last_name}`}
                  </Typography>
                </Box>
              )}

              <Button
                style={{
                  float: "right",
                  marginTop: "2ch",
                }}
                endIcon={<PrintRounded />}
                onClick={() => {
                  navigate(`/RequestPrint`, {
                    state: {
                      column,
                      rowsDetail,
                      fieldName,
                    },
                  });
                }}
              >
                Print
              </Button>
            </Paper>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default ViewRequestForm;
