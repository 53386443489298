import {
    Autocomplete,
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    TextField,
    Box,
    Button,
    Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddRounded, FileUploadRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { fileCaller, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import { DisciplineURL, DisciplineCaseTypeURL } from "../../../../utils/config";
import { useSelector } from "react-redux";

const AddDiscipline = () => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter2, setFilter2] = useState("");
    // const [filter3, setFilter3] = useState("");
    const [options2, setOptions2] = useState([]);
    const [optionsId2, setOptionsID2] = useState();
    const [optionSelect2, setOptionSelect2] = useState("");
    // const [options3, setOptions3] = useState([]);
    // const [optionsId3, setOptionsID3] = useState();
    // const [optionSelect3, setOptionSelect3] = useState("");
    const [msg, setMsg] = useState("");
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const [path, setPath] = useState();
    const [fileName, setFileName] = useState("");
    const UploadRef = useRef();

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        case_description: "",
        file: null,
        case_type_id: null,
        employee_id: selectedRow.id,
    };

    const FORM_VALIDATION = Yup.object().shape({
        case_description: Yup.string().min(2).required("*Required"),
    });

    useEffect(() => {
        get(DisciplineCaseTypeURL, {
            limit: 10,
            filter: {
                case_description: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.case_description}`
                    );
                });
            setOptionsID2(res?.data);
            setOptions2(data);
        });
        // get(EmployeeURL, {
        //     limit: 10,
        //     filter: {
        //         first_name: filter3,
        //     },
        // }).then((res) => {
        //     let data = [];
        //     res &&
        //         res.data.forEach((name, index) => {
        //             data.push(
        //                 `${res.data[index]?.id} - ${res.data[index]?.first_name}`
        //             );
        //         });
        //     setOptionsID3(res.data);
        //     setOptions3(data);
        // });
    }, [filter2]);

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        fileCaller(DisciplineURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                actions.resetForm();
            }
        });
    };

    return (
        <div>
            <TitleHeader title="Add Discipline" icon={<AddRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        values.employee_id = selectedRow.id;
                        values.file = path;
                        optionsId2.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.case_description}` ===
                                optionSelect2
                            ) {
                                values.case_type_id = name.id;
                            }
                        });
                        // optionsId3.forEach((name, index) => {
                        //     if (
                        //         `${name?.id} - ${name.first_name}` ===
                        //         optionSelect3
                        //     ) {
                        //         values.employee_id = name.id;
                        //     }
                        // });
                        // console.log(values);
                        /* Then create a new FormData obj */
                        let formData = new FormData();

                        // /* FormData requires name: id */
                        // formData.append("website", "question");

                        /* append input field values to formData */
                        for (let value in values) {
                            formData.append(value, values[value]);
                        }

                        formSubmitHandler(formData, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextFieldWrapper
                                    name="case_description"
                                    label="Case Description"
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                display={"flex"}
                                sx={{ gap: "2ch" }}
                            >
                                <Button
                                    data-testid="uploadButton"
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                        UploadRef.current.click();
                                    }}
                                >
                                    Discipline Attachment File
                                    {
                                        <FileUploadRounded
                                            style={{ paddingLeft: "2ch" }}
                                        />
                                    }
                                </Button>
                                <input
                                    name="file"
                                    hidden
                                    ref={UploadRef}
                                    type="file"
                                    accept=".pdf"
                                    onChange={(e) => {
                                        setPath(e.target.files[0]);
                                        setFileName(e.target.files[0].name);
                                    }}
                                />
                                <Typography>{fileName}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    loading={options2.length < 1 ? true : false}
                                    disablePortal
                                    options={options2}
                                    onChange={(event, newValue) => {
                                        setOptionSelect2(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Discipline Case"
                                            onChange={(event) => {
                                                setFilter2(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            {/* <Grid item xs={4}>
                                <Autocomplete
                                    loading={options3.length < 1 ? true : false}
                                    disablePortal
                                    options={options3}
                                    onChange={(event, newValue) => {
                                        setOptionSelect3(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Employee"
                                            onChange={(event) => {
                                                setFilter3(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid> */}
                            <Box width={"100%"} />
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default AddDiscipline;
