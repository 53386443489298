import React, { useEffect, useState } from "react";
import { ImportExport } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import { SnackBar } from "../../../../Components/SnackBar/SnackBar.component";
import {
    Box,
    Button,
    Modal,
    Paper,
    FormLabel,
    Grid,
    FormControl,
    Stack,
    Autocomplete,
    TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import {
    AccountTransactionsExcelURL,
    ChartOfAccountURL,
    GeneralLedgerExcelURL,
    JournalsExcelURL,
    TaxSummaryExcelURL,
    TrailBalanceExcelURL,
} from "../../../../utils/config";
import { caller, get } from "../../../../utils/Caller";
import { GiBookmark } from "react-icons/gi";
import { FaCashRegister } from "react-icons/fa";
import { MdAccountBalanceWallet } from "react-icons/md";
import { GrTransaction } from "react-icons/gr";
import { BsJournal } from "react-icons/bs";

const AccountantExport = () => {
    const [page, setPage] = useState(null);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setMsg] = useState("");
    const [loadingFile, setloadingFile] = useState(false);
    const [reportBasis, setReportBasis] = useState(1);
    const [exportAs, setExportAs] = useState(1);
    const [reportBy, setReportBy] = useState(1);
    const [filter, setFilter] = useState();
    const [options, setOptions] = useState([]);
    const [optionSelect, setOptionSelect] = useState("");

    const REPORT_BASIS = ["Accrual", "Cash", "Both"];
    const EXPORT_AS = ["xlsx", "Pdf"];
    const REPORT_BY = ["Date", "Account Type", "Account", "Transaction Type"];

    const Buttons = [
        { name: "General Ledger", icon: <GiBookmark /> },
        { name: "Account Transactions", icon: <GrTransaction /> },
        { name: "Journals", icon: <BsJournal /> },
        { name: "Trail Balance", icon: <MdAccountBalanceWallet /> },
        { name: "Tax Summary", icon: <FaCashRegister /> },
    ];

    const INITIAL_VALUES = {
        from: "",
        to: "",
        reportBasis: reportBasis,
        accounts: [],
        reportBy: reportBy,
    };

    const FORM_VALIDATION = Yup.object().shape({
        from: Yup.string().min(2).required("*Required"),
        to: Yup.string().min(2).required("*Required"),
    });

    /**
     *
     * @param {number} index
     * @param {{from:string,to:string,reportBasis: number}} data
     */
    const handleButtonClick = (index, data) => {
        setloadingFile(true);
        let url = "";
        if (index === 0) {
            url = GeneralLedgerExcelURL;
        } else if (index === 1) {
            url = AccountTransactionsExcelURL;
        } else if (index === 2) {
            url = JournalsExcelURL;
        } else if (index === 3) {
            url = TrailBalanceExcelURL;
        } else if (index === 4) {
            url = TaxSummaryExcelURL;
        }

        let formatedData = {
            reportBasis: data.reportBasis,
            dateRange: [data.from, data.to],
            exportAs: EXPORT_AS[exportAs - 1],
        };

        if (page === 1) {
            formatedData = {
                ...formatedData,
                accounts: data.accounts,
                reportBy: reportBy,
            };
        }

        caller(url, formatedData).then((res) => {
            setloadingFile(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data) {
                if (exportAs === 1) {
                    toExcel(res.data);
                } else {
                    toPdf(res.data);
                }
                setSuccess(true);
                setError(false);
                setTimeout(() => {
                    setOpen(false);
                }, 300);
            }
        });
    };

    const toExcel = (pdfData) => {
        const strPdf = pdfData;
        const buff = Buffer.from(strPdf);
        const excelFile = URL.createObjectURL(
            new Blob([buff], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64",
            })
        );
        window.open(excelFile, "_blank");
    };

    const toPdf = (pdfData) => {
        const strPdf = pdfData;
        const buff = Buffer.from(strPdf);
        const pdfFile = URL.createObjectURL(
            new Blob([buff], {
                type: "application/pdf",
            })
        );
        window.open(pdfFile, "_blank");
    };

    useEffect(() => {
        get(ChartOfAccountURL, {
            limit: 10,
            filter: {
                all: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.account_code}`
                    );
                });
            setOptions(data);
        });
    }, [filter]);

    return (
        <div style={{ alignItems: "center" }}>
            <TitleHeader title="Accountant Export" icon={<ImportExport />} />
            <div
                className="FormContainer"
                style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: "2ch",
                }}
            >
                {Buttons.map((button, index) => (
                    <Button
                        className="LargeButton"
                        key={index}
                        onClick={() => {
                            setPage(index);
                            setOpen(true);
                        }}
                        startIcon={button.icon}
                    >
                        {button.name}
                    </Button>
                ))}
                <Modal
                    className="Modal"
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Paper style={{ padding: "2ch" }}>
                            <Box>
                                <Formik
                                    initialValues={{ ...INITIAL_VALUES }}
                                    validationSchema={FORM_VALIDATION}
                                    onSubmit={(values, actions) => {
                                        values.reportBasis = reportBasis;
                                        values.reportBy = reportBy;
                                        if (page === 1) {
                                            optionSelect?.forEach(
                                                (option, index) => {
                                                    let account_id = parseInt(
                                                        option.split("-")[0]
                                                    );
                                                    values.accounts.push(
                                                        account_id
                                                    );
                                                }
                                            );
                                        }
                                        handleButtonClick(page, values);
                                    }}
                                >
                                    <Form>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <FormLabel>
                                                        From Date
                                                    </FormLabel>
                                                    <TextFieldWrapper
                                                        name="from"
                                                        type="date"
                                                        variant="outlined"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <FormLabel>
                                                        To Date
                                                    </FormLabel>
                                                    <TextFieldWrapper
                                                        name="to"
                                                        type="date"
                                                        variant="outlined"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextFieldWrapper
                                                    select
                                                    name="reportBasis"
                                                    label="Report Basis"
                                                    variant="outlined"
                                                    value={reportBasis}
                                                    options={
                                                        REPORT_BASIS ?? " "
                                                    }
                                                    onChange={(e) => {
                                                        setReportBasis(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                            {page === 1 && (
                                                <>
                                                    <Grid item xs={6}>
                                                        <TextFieldWrapper
                                                            select
                                                            name="reportBy"
                                                            label="Report By"
                                                            variant="outlined"
                                                            value={reportBy}
                                                            options={
                                                                REPORT_BY ?? " "
                                                            }
                                                            onChange={(e) => {
                                                                setReportBy(
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Stack spacing={3}>
                                                            <Autocomplete
                                                                multiple
                                                                getOptionLabel={(
                                                                    option
                                                                ) =>
                                                                    option || ""
                                                                }
                                                                filterSelectedOptions
                                                                loading={
                                                                    options.length <
                                                                    1
                                                                        ? true
                                                                        : false
                                                                }
                                                                options={
                                                                    options
                                                                }
                                                                onChange={(
                                                                    event,
                                                                    newValue
                                                                ) => {
                                                                    setOptionSelect(
                                                                        newValue
                                                                    );
                                                                }}
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Accounts"
                                                                        onChange={(
                                                                            event
                                                                        ) => {
                                                                            setFilter(
                                                                                event
                                                                                    .target
                                                                                    .value
                                                                            );
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Stack>
                                                    </Grid>
                                                </>
                                            )}

                                            <Box width={"100%"} />
                                            <Grid item xs={3}>
                                                <TextFieldWrapper
                                                    select
                                                    size="small"
                                                    name="exportAs"
                                                    label="Export As"
                                                    variant="filled"
                                                    value={exportAs}
                                                    options={EXPORT_AS ?? " "}
                                                    onChange={(e) => {
                                                        setExportAs(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <div>
                                            <LoadingButton
                                                data-testid="AddButton"
                                                loading={loadingFile}
                                                type="submit"
                                                className="ButtonContained"
                                                style={{
                                                    marginTop: "2ch",
                                                    float: "right",
                                                }}
                                            >
                                                {loadingFile
                                                    ? null
                                                    : exportAs === 1
                                                    ? "Get Excel"
                                                    : "Get PDF"}
                                            </LoadingButton>
                                        </div>
                                    </Form>
                                </Formik>
                            </Box>
                        </Paper>
                    </Box>
                </Modal>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};

export default AccountantExport;
