import {
    Autocomplete,
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../../../../Components/SnackBar/SnackBar.component.jsx";
import { caller, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    HCMConfigurationURL,
    ChartOfAccountURL,
} from "../../../../utils/config";
import { Box } from "@mui/system";

const AddHCMConfiguration = () => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setMsg] = useState("");
    const [optionSelect, setOptionSelect] = useState("");
    const [optionSelect2, setOptionSelect2] = useState("");
    const [optionSelect3, setOptionSelect3] = useState("");
    const [optionSelect4, setOptionSelect4] = useState("");
    const [filter, setFilter] = useState("");
    const [options, setOptions] = useState([]);

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        income_tax_payable_id: null,
        employer_tax_id: null,
        employer_tax_control_id: null,
        employer_pension_account_id: null,
        employer_pension: "",
        employee_pension: "",
        employee_retirement_age: "",
    };

    const FORM_VALIDATION = Yup.object().shape({
        // income_tax_payable_id: Yup.number().min(1).required("*Required"),
        // employer_tax_id: Yup.number().min(1).required("*Required"),
        // employer_tax_control_id: Yup.number().min(1).required("*Required"),
        // employer_pension_account_id: Yup.number().min(1).required("*Required"),
        employer_pension: Yup.number().min(1).required("*Required"),
        employee_pension: Yup.number().min(1).required("*Required"),
        employee_retirement_age: Yup.number()
            .min(40, "Retirement Age Should Be above 40")
            .max(100, "Retirement Age Should Be below 100")
            .required("*Required"),
    });

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        caller(HCMConfigurationURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                actions.resetForm();
            }
        });
    };

    useEffect(() => {
        get(ChartOfAccountURL, {
            limit: 10,
            filter: {
                all: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data?.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].account_name}`
                    );
                });
            setOptions(data);
        });
    }, [filter]);

    return (
        <div>
            <TitleHeader title="Add HCM Configuration" icon={<AddRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        values.income_tax_payable_id = parseInt(
                            optionSelect.split("-")[0]
                        );
                        values.employer_tax_id = parseInt(
                            optionSelect2.split("-")[0]
                        );
                        values.employer_tax_control_id = parseInt(
                            optionSelect3.split("-")[0]
                        );
                        values.employer_pension_account_id = parseInt(
                            optionSelect4.split("-")[0]
                        );

                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Autocomplete
                                    loading={options.length < 1 ? true : false}
                                    disablePortal
                                    options={options}
                                    onChange={(event, newValue) => {
                                        setOptionSelect(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Income Tax Payable Id"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={4}>
                                <Autocomplete
                                    loading={options.length < 1 ? true : false}
                                    disablePortal
                                    options={options}
                                    onChange={(event, newValue) => {
                                        setOptionSelect2(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Employer Tax Id"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    loading={options.length < 1 ? true : false}
                                    disablePortal
                                    options={options}
                                    onChange={(event, newValue) => {
                                        setOptionSelect3(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Employer Tax Control Id"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    loading={options.length < 1 ? true : false}
                                    disablePortal
                                    options={options}
                                    onChange={(event, newValue) => {
                                        setOptionSelect4(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Employer Pension Account Id"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="employer_pension"
                                    label="Employer Pension"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="employee_pension"
                                    label="Employee Pension"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="employee_retirement_age"
                                    label="Employee Retirement Age"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default AddHCMConfiguration;
