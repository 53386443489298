import PropTypes from "prop-types";
import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { deleteWorkLog } from "../../Pages/Projects/Operational/WorkLogAndBranchOfWork/deleteWorkLog";
import { deleteSubTask } from "../../Pages/Projects/Operational/SubTask/deleteSubTask";

const AlertDialog = (props) => {
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={() => {
                    props.close();
                }}
            >
                <DialogTitle>Are you sure You Want to Delete?</DialogTitle>
                <DialogActions>
                    <Button
                        color={"primary"}
                        variant={"outlined"}
                        onClick={() => {
                            props.close();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        color={"error"}
                        variant={"contained"}
                        onClick={() => {
                            if (props.workLog) {
                                deleteWorkLog(props.id);
                            } else if (props.subTask) {
                                deleteSubTask(props.id);
                            }
                            props.close();
                        }}
                        autoFocus
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

AlertDialog.propTypes = {
    close: PropTypes.func,
    /**closing function to set open false */
    id: PropTypes.any,
    open: PropTypes.bool,
    /**open triger */
    subTask: PropTypes.bool,
    /**Pass true if this is for subTask page */
    workLog: PropTypes.bool,
    /**Pass true if this is for worklog page */
};

export default AlertDialog;
