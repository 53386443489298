import { Add, SearchRounded } from "@mui/icons-material";
import {
    Button,
    IconButton,
    InputAdornment,
    TextField,
    Modal,
    Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import Table from "../../../../Components/Table/Table.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { setSelected } from "../../../../Redux/Slice/selectedRow";
import { get } from "../../../../utils/Caller";
import { AllEmployeeURL } from "../../../../utils/config";
import GeneralExport from "../../../Finance/FinanceOperational/GeneralExport/GeneralExport.page";

const GENDERS = ["Male", "Female"];
const MARITALSTATUS = ["Single", "Married", "Widowed", "Divorced"];

const ViewEmployee = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(20);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState();
    const [Search, setSearch] = useState();
    const [open, setOpen] = useState(false);

    const columns = [
        {
            field: "id_number",
            headerName: "ID NO.",
            width: 80,
        },
        {
            field: "first_name",
            headerName: "First Name",
            width: 100,
        },
        {
            field: "middle_name",
            headerName: "Middle Name",
            width: 100,
        },
        {
            field: "last_name",
            headerName: "Last Name",
            width: 100,
        },
        // {
        //     field: "genderValue",
        //     headerName: "Gender",
        //     width: 80,
        // },
        // {
        //     field: "date_of_birth",
        //     headerName: "Date Of Birth",
        //     width: 100,
        // },
        // {
        //     field: "employment_start_date",
        //     headerName: "Employment Start Date",
        //     width: 100,
        // },
        // {
        //     field: "date_of_joining",
        //     headerName: "Date Of Joining",
        //     width: 100,
        // },
        // {
        //     field: "maritalStatus",
        //     headerName: "Marital Status",
        //     width: 100,
        // },
        // {
        //     field: "date_of_birth",
        //     headerName: "Date Of Birth",
        //     width: 100,
        // },
        // {
        //     field: "marital_since",
        //     headerName: "Marital Since",
        //     width: 100,
        // },
        // {
        //     field: "date_of_birth",
        //     headerName: "Date Of Birth",
        //     width: 100,
        // },
        // {
        //     field: "place_of_birth",
        //     headerName: "Place Of Birth",
        //     width: 100,
        // },
        {
            field: "is_employee_active",
            headerName: "Is Employee Active",
            width: 60,
        },
        // {
        //     field: "type",
        //     headerName: "Type",
        //     width: 60,
        // },
        // {
        //     field: "prev_employment_leave_days",
        //     headerName: "Prev Employment Leave Days",
        //     width: 80,
        // },
        // {
        //     field: "bank_account_number",
        //     headerName: "Bank Account Number",
        //     width: 150,
        // },
        // {
        //     field: "document_ref",
        //     headerName: "Document Ref",
        //     width: 80,
        // },
        // {
        //     field: "pension_ref",
        //     headerName: "Pension Ref",
        //     width: 80,
        // },
        // {
        //     field: "nationalityName",
        //     headerName: "Nationality",
        //     width: 100,
        // },
        // {
        //     field: "countryName",
        //     headerName: "Country",
        //     width: 100,
        // },
        // {
        //     field: "languageName",
        //     headerName: "Language",
        //     width: 100,
        // },
        {
            field: "titleName",
            headerName: "Title",
            width: 100,
        },
        // {
        //     field: "religionName",
        //     headerName: "Religion",
        //     width: 100,
        // },
        {
            field: "employeeType",
            headerName: "Employee Type",
            width: 100,
        },
        // {
        //     field: "startDate",
        //     headerName: "Start Date",
        //     width: 100,
        // },
        // {
        //     field: "endDate",
        //     headerName: "End Date",
        //     width: 100,
        // },
    ];

    useEffect(() => {
        let EmployeeData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(AllEmployeeURL, {
            limit: limit,
            filter: { all: filter },
        }).then((res) => {
            res && res.data && setloading(false);
            res &&
                res.data?.forEach((data, index) => {
                    EmployeeData.push({
                        ...data,
                        nationalityName: data?.nationality?.nationality_name,
                        countryName: data?.country?.country_name,
                        date_of_joining: new Date(
                            data?.date_of_joining,
                        ).toDateString(),
                        employment_start_date: new Date(
                            data?.employment_start_date,
                        ).toDateString(),
                        date_of_birth: new Date(
                            data?.date_of_birth,
                        ).toDateString(),
                        languageName: data?.language?.name,
                        titleName: data?.title?.name,
                        religionName: data?.religion?.name,
                        employeeType: data?.employee_type?.description,
                        locationName: data?.location?.location_name,
                        actionReason: data?.action_reason?.reason_description,
                        businessUnit: data?.business_unit?.name,
                        employeeGroup: data?.employee_group?.group_description,
                        genderValue: GENDERS[data.gender - 1],
                        maritalStatus: MARITALSTATUS[data.marital_status - 1],
                        startDate: new Date(data?.startDate).toDateString(),
                        endDate: new Date(data?.endDate).toDateString(),
                    });
                });
            setRows(EmployeeData);
        });
    }, [refreshTable, filter, limit, rows.length]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    const ViewEmployee = (selectedEmployee) => {
        dispatch(setSelected(selectedEmployee));
        navigate(`/View/HCM/Employee/${selectedEmployee?.id}/`);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setloading(true);
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <div style={{ display: "flex", gap: "2ch" }}>
                    <Button
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        Export
                    </Button>
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<Add />}
                        onClick={() => {
                            navigate("/View/HCM/Employee/AddEmployee");
                        }}
                    >
                        Add Employee
                    </Button>
                </div>
            </div>
            <div className="TableContainer">
                <Table
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                    ViewEmployee={ViewEmployee}
                />
                <Modal
                    className="Modal"
                    open={open}
                    onClose={() => {
                        setOpen(false);
                    }}
                >
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <GeneralExport type={32} />
                    </Box>
                </Modal>
            </div>
        </div>
    );
};

export default ViewEmployee;
