import { deleter } from "../../../utils/Caller";
import { ManPowerURL } from "../../../utils/config";

export const deleteManpower = (id) => {
    const body = {
        id: id,
    };
    // console.log(body);

    return new Promise((resolve, reject) => {
        deleter(ManPowerURL, body).then((res) => {
            if (res.error) {
                reject("Error Occured!!");
            } else if (res.data.success) {
                resolve("Success");
            }
        });
    });
};
