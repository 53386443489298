import {
    Grid,
    Paper,
    Skeleton,
    Avatar,
    Divider,
    Typography,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    IconButton,
    Box,
    Popover,
    Modal,
    Card,
} from "@mui/material";
import {
    Edit,
    MoreVert,
    PriorityHighRounded,
    ArrowBackIos,
    Print,
    ImageOutlined,
    PunchClock,
} from "@mui/icons-material";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router";
import {
    exportComponentAsPDF,
    exportComponentAsPNG,
} from "react-component-export-image";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
    AccountBox,
    PersonRounded,
    Settings,
    AttachMoney,
    Check,
    ViewAgenda,
    DocumentScannerRounded,
} from "@mui/icons-material";
import Logo from "../../../Assets/img/logo.svg";
import "../../../Assets/Sass/AddProject.style.scss";
import { baseURL, hostURL } from "../../../utils/Caller";
import { useSelector } from "react-redux";
import ViewAddress from "./Address/ViewAddress.page";
import ViewDependent from "./Dependent/ViewDependent.page";
// import ViewDiscipline from "./Discipline/ViewDiscipline.page";
import ViewEducation from "./Education/ViewEducation.page";
import ViewEmployeeAction from "./EmployeeAction/ViewEmployeeAction.page";
import ViewEmployeeAttachment from "./EmployeeAttachment/ViewEmployeeAttachment.page";
import ViewEmployeeCommitment from "./EmployeeCommitment/ViewEmployeeCommitment.page";
import ViewEmployeeContact from "./EmployeeContact/ViewEmployeeContact.page";
import ViewEmployeeLoanRepayment from "./EmployeeLoanRepayment/ViewEmployeeLoanRepayment.page";
import ViewEmployeePayFrequency from "./EmployeePayFrequency/ViewEmployeePayFrequency.page";
import ViewEmployeePaygrade from "./EmployeePaygrade/ViewEmployeePaygrade.page";
import ViewEmployeeSalaryComponent from "./EmployeeSalaryComponent/ViewEmployeeSalaryComponent.page";
import ViewExperience from "./Experience/ViewExperience.page";
import ViewLeaveAssignment from "./LeaveAssignment/ViewLeaveAssignment.page";
import ViewLeaveEntitlement from "./LeaveEntitlement/ViewLeaveEntitlement.page";
import ViewLeaveTransfer from "./LeaveTransfer/ViewLeaveTransfer.page";
import ViewLicense from "./License/ViewLicense.page";
import ViewServiceAllowancePay from "./ServiceAllowancePay/ViewServiceAllowancePay.page";
import ViewShiftAssignment from "./ShiftAssignment/ViewShiftAssignment.page";
import EditEmployee from "./Employee/EditEmployee.page";
import ViewEmployeeBackPenalityDeduction from "../Payroll/EmployeeBackPenalityDeduction/ViewEmployeeBackPenalityDeduction.page";
import ViewEmployeePenality from "../Payroll/EmployeePenality/ViewEmployeePenality.page";
import ViewEmployeePenalityDetailMst from "../Payroll/EmployeePenalityDetailMst/ViewEmployeePenalityDetailMst.page";
import QRCode from "react-qr-code";

const EMPLOYEE_STATUS = ["Active", "Inactive", "Retired", "Withdraw"];

const EmployeeDetail = () => {
    const [preview, setPreview] = useState(null);
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const [loaded, setLoaded] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const menus = useRef();
    const [menuToggle, setMenuToggle] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const printable = useRef();
    const printable_back = useRef();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setMenuToggle(false);
        setAnchorEl(null);
    };

    const GENDERS = ["Male", "Female"];

    const MENUS = [
        {
            name: "Employee Profile",
            icon: <PersonRounded />,
        },
        {
            name: "Employee Actions",
            icon: <Settings />,
        },
        {
            name: "Employee Finance",
            icon: <AttachMoney />,
        },
        {
            name: "Leave",
            icon: <Check />,
        },
        {
            name: "Penality",
            icon: <PriorityHighRounded />,
        },
        {
            name: "Documentation",
            icon: <DocumentScannerRounded />,
        },
        {
            name: "Shift Assignment",
            icon: <PunchClock />,
        },
    ];
    const EMPLOYEEPATH = [
        `/View/HCM/Employee/${selectedRow?.id}/Address`,
        // `/View/HCM/Employee/${selectedRow?.id}/Attachment`,
        `/View/HCM/Employee/${selectedRow?.id}/Dependent`,
        `/View/HCM/Employee/${selectedRow?.id}/Discipline`,
        `/View/HCM/Employee/${selectedRow?.id}/Education`,
        `/View/HCM/Employee/${selectedRow?.id}/EmployeeAction`,
        `/View/HCM/Employee/${selectedRow?.id}/EmployeeAttachment`,
        `/View/HCM/Employee/${selectedRow?.id}/EmployeeCommitment`,
        `/View/HCM/Employee/${selectedRow?.id}/EmployeeContact`,
        `/View/HCM/Employee/${selectedRow?.id}/EmployeeLoanRepayment`,
        `/View/HCM/Employee/${selectedRow?.id}/EmployeePayFrequency`,
        `/View/HCM/Employee/${selectedRow?.id}/EmployeePaygrade`,
        `/View/HCM/Employee/${selectedRow?.id}/EmployeeSalaryComponent`,
        `/View/HCM/Employee/${selectedRow?.id}/Experience`,
        `/View/HCM/Employee/${selectedRow?.id}/LeaveEntitlement`,
        `/View/HCM/Employee/${selectedRow?.id}/LeaveAssignment`,
        `/View/HCM/Employee/${selectedRow?.id}/LeaveTransfer`,
        `/View/HCM/Employee/${selectedRow?.id}/License`,
        `/View/HCM/Employee/${selectedRow?.id}/ServiceAllowancePay`,
        `/View/HCM/Employee/${selectedRow?.id}/ShiftAssignment`,
        `/View/HCM/Employee/${selectedRow?.id}/EmployeeBackPenalityDeduction`,
        `/View/HCM/Employee/${selectedRow?.id}/EmployeePenality`,
        `/View/HCM/Employee/${selectedRow?.id}/EmployeePenalityDetailMst`,
    ];

    const columns = [
        {
            label: "ID NO.",
            name: "id_number",
        },
        {
            label: "First Name",
            name: "first_name",
        },
        {
            label: "Middle Name",
            name: "middle_name",
        },
        {
            label: "Last Name",
            name: "last_name",
        },
        {
            label: "Gender",
            name: "genderValue",
        },
        {
            label: "Date Of Birth",
            name: "date_of_birth",
        },
        {
            label: "Place Of Birth",
            name: "place_of_birth",
        },
        {
            label: "Marital Status",
            name: "maritalStatus",
        },
        {
            label: "Married Since",
            name: "marital_since",
        },
        {
            label: "Is Employee Active",
            name: "is_employee_active",
        },
        {
            label: "Type",
            name: "type",
        },
        {
            label: "Bank Account Number",
            name: "bank_account_number",
        },
        {
            label: "Tin Number",
            name: "tin_number",
        },
        {
            label: "Document Ref",
            name: "document_ref",
        },
        {
            label: "Pension Ref",
            name: "pension_ref",
        },
        {
            label: "Nationality",
            name: "nationalityName",
        },
        {
            label: "Country",
            name: "countryName",
        },
        {
            label: "Language",
            name: "languageName",
        },
        {
            label: "Title",
            name: "titleName",
        },
        {
            label: "Employee Type",
            name: "employeeType",
        },
        {
            label: "Start Date",
            name: "startDate",
        },
        {
            label: "End Date",
            name: "endDate",
        },
    ];

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        if (index === 0) {
            navigate(`${EMPLOYEEPATH[0]}`);
        } else if (index === 1) {
            navigate(`${EMPLOYEEPATH[4]}`);
        } else if (index === 2) {
            navigate(`${EMPLOYEEPATH[8]}`);
        } else if (index === 3) {
            navigate(`${EMPLOYEEPATH[13]}`);
        } else if (index === 4) {
            navigate(`${EMPLOYEEPATH[19]}`);
        } else if (index === 5) {
            navigate(`${EMPLOYEEPATH[5]}`);
        } else if (index === 6) {
            navigate(`${EMPLOYEEPATH[18]}`);
        }
        setValue("1");
    };

    useEffect(() => {
        if (
            location.pathname === EMPLOYEEPATH[0] ||
            location.pathname === EMPLOYEEPATH[1] ||
            location.pathname === EMPLOYEEPATH[3] ||
            location.pathname === EMPLOYEEPATH[12] ||
            location.pathname === EMPLOYEEPATH[16] ||
            location.pathname === EMPLOYEEPATH[7]
        ) {
            let a = [
                EMPLOYEEPATH[0],
                EMPLOYEEPATH[1],
                EMPLOYEEPATH[3],
                EMPLOYEEPATH[12],
                EMPLOYEEPATH[16],
                EMPLOYEEPATH[7],
            ];
            setSelectedIndex(0);
            setValue(String(a.indexOf(location.pathname) + 1));
        }
        if (
            location.pathname === EMPLOYEEPATH[4] ||
            location.pathname === EMPLOYEEPATH[6]
        ) {
            let a = [EMPLOYEEPATH[4], EMPLOYEEPATH[6]];
            setSelectedIndex(1);
            setValue(String(a.indexOf(location.pathname) + 1));
        }
        if (
            location.pathname === EMPLOYEEPATH[8] ||
            location.pathname === EMPLOYEEPATH[9] ||
            location.pathname === EMPLOYEEPATH[10] ||
            location.pathname === EMPLOYEEPATH[11] ||
            location.pathname === EMPLOYEEPATH[17]
        ) {
            let a = [
                EMPLOYEEPATH[8],
                EMPLOYEEPATH[9],
                EMPLOYEEPATH[10],
                EMPLOYEEPATH[11],
                EMPLOYEEPATH[17],
            ];
            setSelectedIndex(2);
            setValue(String(a.indexOf(location.pathname) + 1));
        }
        if (
            location.pathname === EMPLOYEEPATH[13] ||
            location.pathname === EMPLOYEEPATH[14] ||
            location.pathname === EMPLOYEEPATH[15]
        ) {
            let a = [EMPLOYEEPATH[13], EMPLOYEEPATH[14], EMPLOYEEPATH[15]];
            setSelectedIndex(3);
            setValue(String(a.indexOf(location.pathname) + 1));
        }
        if (
            location.pathname === EMPLOYEEPATH[19] ||
            location.pathname === EMPLOYEEPATH[20] ||
            location.pathname === EMPLOYEEPATH[21]
        ) {
            let a = [EMPLOYEEPATH[19], EMPLOYEEPATH[20], EMPLOYEEPATH[21]];
            setSelectedIndex(4);
            setValue(String(a.indexOf(location.pathname) + 1));
        }
        if (location.pathname === EMPLOYEEPATH[5]) {
            let a = [EMPLOYEEPATH[5]];
            setSelectedIndex(5);
            setValue(String(a.indexOf(location.pathname) + 1));
        }
        if (location.pathname === EMPLOYEEPATH[18]) {
            let a = [EMPLOYEEPATH[18]];
            setSelectedIndex(6);
            setValue(String(a.indexOf(location.pathname) + 1));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const [value, setValue] = useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const closeModal = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (selectedRow) {
            if (!preview && selectedRow.photo)
                setPreview(baseURL + selectedRow?.photo);
            if (location.pathname === `/View/HCM/Employee/${selectedRow?.id}/`)
                navigate(location.pathname + "Address");
        } else {
            navigate(`/View/HCM/Employee`);
        }
    }, [location.pathname, navigate, preview, selectedRow]);

    const SideMenus = () => {
        return (
            <Paper
                variant="outlined"
                style={{
                    backgroundColor: "#f5f5f5",
                    maxHeight: "50vh",
                    overflowY: "scroll",
                }}
            >
                <List component="nav">
                    {MENUS.map((menu, index) => {
                        return (
                            <ListItemButton
                                key={index}
                                selected={selectedIndex === index}
                                onClick={(event) =>
                                    handleListItemClick(event, index)
                                }
                                style={{
                                    backgroundColor:
                                        selectedIndex === index && `#8ab1b9`,

                                    color: selectedIndex === index && "#000000",
                                }}
                            >
                                <ListItemIcon>{menu.icon}</ListItemIcon>
                                <ListItemText
                                    primary={menu.name}
                                    style={{
                                        fontWeight: "700 !important",
                                    }}
                                />
                            </ListItemButton>
                        );
                    })}
                </List>
            </Paper>
        );
    };

    return (
        <Paper
            style={{
                padding: "2ch",
                boxShadow: "0 0 20px silver",
                // width: window.innerWidth < 480 && window.innerWidth - 100,
            }}
            elevation={0}
            className={"EmployeeDetail"}
        >
            <IconButton
                onClick={() => {
                    window.history.go(-2);
                }}
            >
                <ArrowBackIos />
            </IconButton>
            <Grid
                container
                spacing={2}
                style={{
                    alignItems: "center",
                    paddingBottom: "2ch",
                    flexWrap: "nowrap",
                }}
            >
                <Grid item style={{ paddingRight: "2ch" }}>
                    {!loaded && (
                        <Skeleton
                            variant="circular"
                            animation={"wave"}
                            style={{
                                height: "10ch",
                                width: "10ch",
                            }}
                        />
                    )}
                    <Avatar
                        onLoad={() => {
                            setLoaded(true);
                        }}
                        src={preview}
                        alt=""
                        style={{
                            height: "10ch",
                            width: "10ch",
                            display: loaded ? "block" : "none",
                        }}
                    />
                </Grid>

                <Grid item>
                    <Grid item xs={12}>
                        <Typography
                            style={{ fontWeight: "700", color: "#14213d" }}
                        >
                            {selectedRow?.id_number}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            style={{
                                fontWeight: "700",
                                fontSize: "larger",
                                color: "#0a1128",
                            }}
                        >
                            {selectedRow?.first_name +
                                " " +
                                selectedRow?.middle_name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            style={{ fontWeight: "700", color: "#14213d" }}
                        >
                            {selectedRow.jobTitle}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            style={{ fontWeight: "700", color: "#14213d" }}
                        >
                            {EMPLOYEE_STATUS[selectedRow.employeeStatus - 1]}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid item>
                        <IconButton
                            onClick={() =>
                                navigate(
                                    `/View/HCM/Employee/${selectedRow?.id}/Edit`,
                                )
                            }
                        >
                            <Edit color="primary" fontSize="small" />
                        </IconButton>
                    </Grid>
                    <Grid item className="MenuButton">
                        <IconButton
                            onClick={(e) => {
                                setMenuToggle(true);
                                handleClick(e);
                            }}
                        >
                            <MoreVert color="primary" fontSize="small" />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={(e) => {
                                setOpen(true);
                            }}
                        >
                            <ViewAgenda color="primary" fontSize="small" />
                        </IconButton>
                    </Grid>
                </Grid>

                <Popover
                    open={menuToggle}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                >
                    <SideMenus />
                </Popover>
            </Grid>
            <Divider />
            <Grid container spacing={2} style={{ paddingTop: "2ch" }}>
                <Grid item xs={12} md={10}>
                    <Paper
                        className="EmployeeDetailContainer"
                        variant="outlined"
                        style={{
                            maxHeight: "100vh",
                            overflow: "scroll",
                        }}
                    >
                        {(location.pathname === `${EMPLOYEEPATH[0]}` ||
                            location.pathname === `${EMPLOYEEPATH[1]}` ||
                            location.pathname === `${EMPLOYEEPATH[3]}` ||
                            location.pathname === `${EMPLOYEEPATH[12]}` ||
                            location.pathname === `${EMPLOYEEPATH[16]}` ||
                            location.pathname === `${EMPLOYEEPATH[7]}`) && (
                            <TabContext value={value}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: "divider",
                                    }}
                                >
                                    <TabList
                                        onChange={handleChange}
                                        variant="scrollable"
                                    >
                                        <Tab
                                            label="Address"
                                            value="1"
                                            onClick={() =>
                                                navigate(EMPLOYEEPATH[0])
                                            }
                                        />
                                        <Tab
                                            label="Dependent"
                                            value="2"
                                            onClick={() =>
                                                navigate(EMPLOYEEPATH[1])
                                            }
                                        />
                                        <Tab
                                            label="Education"
                                            value="3"
                                            onClick={() =>
                                                navigate(EMPLOYEEPATH[3])
                                            }
                                        />
                                        <Tab
                                            label="Experience"
                                            value="4"
                                            onClick={() =>
                                                navigate(EMPLOYEEPATH[12])
                                            }
                                        />
                                        <Tab
                                            label="License"
                                            value="5"
                                            onClick={() =>
                                                navigate(EMPLOYEEPATH[16])
                                            }
                                        />
                                        <Tab
                                            label="Employee Contact"
                                            value="6"
                                            onClick={() =>
                                                navigate(EMPLOYEEPATH[7])
                                            }
                                        />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <ViewAddress />
                                </TabPanel>
                                <TabPanel value="2">
                                    <ViewDependent />
                                </TabPanel>
                                <TabPanel value="3">
                                    <ViewEducation />
                                </TabPanel>
                                <TabPanel value="4">
                                    <ViewExperience />
                                </TabPanel>
                                <TabPanel value="5">
                                    <ViewLicense />
                                </TabPanel>
                                <TabPanel value="6">
                                    <ViewEmployeeContact />
                                </TabPanel>
                            </TabContext>
                        )}
                        {(location.pathname === `${EMPLOYEEPATH[4]}` ||
                            location.pathname === `${EMPLOYEEPATH[6]}`) && (
                            <TabContext value={value}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: "divider",
                                    }}
                                >
                                    <TabList
                                        onChange={handleChange}
                                        variant="scrollable"
                                    >
                                        <Tab
                                            label="Employee Action"
                                            value="1"
                                            onClick={() =>
                                                navigate(EMPLOYEEPATH[4])
                                            }
                                        />
                                        <Tab
                                            label="Employee Commitment"
                                            value="2"
                                            onClick={() =>
                                                navigate(EMPLOYEEPATH[6])
                                            }
                                        />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <ViewEmployeeAction />
                                </TabPanel>
                                <TabPanel value="2">
                                    <ViewEmployeeCommitment />
                                </TabPanel>
                            </TabContext>
                        )}
                        {(location.pathname === `${EMPLOYEEPATH[8]}` ||
                            location.pathname === `${EMPLOYEEPATH[9]}` ||
                            location.pathname === `${EMPLOYEEPATH[10]}` ||
                            location.pathname === `${EMPLOYEEPATH[11]}` ||
                            location.pathname === `${EMPLOYEEPATH[17]}`) && (
                            <TabContext value={value}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: "divider",
                                    }}
                                >
                                    <TabList
                                        onChange={handleChange}
                                        variant="scrollable"
                                    >
                                        <Tab
                                            label="Employee Loan Repayment"
                                            value="1"
                                            onClick={() =>
                                                navigate(EMPLOYEEPATH[8])
                                            }
                                        />
                                        <Tab
                                            label="Employee Pay Frequency"
                                            value="2"
                                            onClick={() =>
                                                navigate(EMPLOYEEPATH[9])
                                            }
                                        />
                                        <Tab
                                            label="Employee Paygrade"
                                            value="3"
                                            onClick={() =>
                                                navigate(EMPLOYEEPATH[10])
                                            }
                                        />
                                        <Tab
                                            label="Employee Salary Component"
                                            value="4"
                                            onClick={() =>
                                                navigate(EMPLOYEEPATH[11])
                                            }
                                        />
                                        <Tab
                                            label="Service Allowance Pay"
                                            value="5"
                                            onClick={() =>
                                                navigate(EMPLOYEEPATH[17])
                                            }
                                        />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <ViewEmployeeLoanRepayment />
                                </TabPanel>
                                <TabPanel value="2">
                                    <ViewEmployeePayFrequency />
                                </TabPanel>
                                <TabPanel value="3">
                                    <ViewEmployeePaygrade />
                                </TabPanel>
                                <TabPanel value="4">
                                    <ViewEmployeeSalaryComponent />
                                </TabPanel>
                                <TabPanel value="5">
                                    <ViewServiceAllowancePay />
                                </TabPanel>
                            </TabContext>
                        )}
                        {(location.pathname === `${EMPLOYEEPATH[13]}` ||
                            location.pathname === `${EMPLOYEEPATH[14]}` ||
                            location.pathname === `${EMPLOYEEPATH[15]}`) && (
                            <TabContext value={value}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: "divider",
                                    }}
                                >
                                    <TabList
                                        onChange={handleChange}
                                        variant="scrollable"
                                    >
                                        <Tab
                                            label="Leave Entitlement"
                                            value="1"
                                            onClick={() =>
                                                navigate(EMPLOYEEPATH[13])
                                            }
                                        />
                                        <Tab
                                            label="Leave Assignment"
                                            value="2"
                                            onClick={() =>
                                                navigate(EMPLOYEEPATH[14])
                                            }
                                        />
                                        <Tab
                                            label="Leave Transfer"
                                            value="3"
                                            onClick={() =>
                                                navigate(EMPLOYEEPATH[15])
                                            }
                                        />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <ViewLeaveEntitlement />
                                </TabPanel>
                                <TabPanel value="2">
                                    <ViewLeaveAssignment />
                                </TabPanel>
                                <TabPanel value="3">
                                    <ViewLeaveTransfer />
                                </TabPanel>
                            </TabContext>
                        )}
                        {(location.pathname === `${EMPLOYEEPATH[19]}` ||
                            location.pathname === `${EMPLOYEEPATH[20]}` ||
                            location.pathname === `${EMPLOYEEPATH[21]}`) && (
                            <TabContext value={value}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: "divider",
                                    }}
                                >
                                    <TabList
                                        onChange={handleChange}
                                        variant="scrollable"
                                    >
                                        <Tab
                                            label="Back Penality Deduction"
                                            value="1"
                                            onClick={() =>
                                                navigate(EMPLOYEEPATH[19])
                                            }
                                        />
                                        <Tab
                                            label="Penality"
                                            value="2"
                                            onClick={() =>
                                                navigate(EMPLOYEEPATH[20])
                                            }
                                        />
                                        <Tab
                                            label="Penality Detail"
                                            value="3"
                                            onClick={() =>
                                                navigate(EMPLOYEEPATH[21])
                                            }
                                        />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <ViewEmployeeBackPenalityDeduction />
                                </TabPanel>
                                <TabPanel value="2">
                                    <ViewEmployeePenality />
                                </TabPanel>
                                <TabPanel value="3">
                                    <ViewEmployeePenalityDetailMst />
                                </TabPanel>
                            </TabContext>
                        )}
                        {location.pathname === `${EMPLOYEEPATH[5]}` && (
                            <TabContext value={value}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: "divider",
                                    }}
                                >
                                    <TabList
                                        onChange={handleChange}
                                        variant="scrollable"
                                    >
                                        <Tab
                                            label="Employee Attachment"
                                            value="1"
                                            onClick={() =>
                                                navigate(EMPLOYEEPATH[6])
                                            }
                                        />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <ViewEmployeeAttachment />
                                </TabPanel>
                            </TabContext>
                        )}
                        {location.pathname === `${EMPLOYEEPATH[18]}` && (
                            <TabContext value={value}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: "divider",
                                    }}
                                >
                                    <TabList
                                        onChange={handleChange}
                                        variant="scrollable"
                                    >
                                        <Tab
                                            label="Shift Assignment"
                                            value="1"
                                            onClick={() =>
                                                navigate(EMPLOYEEPATH[18])
                                            }
                                        />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <ViewShiftAssignment />
                                </TabPanel>
                            </TabContext>
                        )}
                        {location.pathname ===
                            `/View/HCM/Employee/${selectedRow?.id}/Edit` && (
                            <EditEmployee />
                        )}
                    </Paper>
                </Grid>
                <Grid item md={2} className={"Menus"} ref={menus}>
                    <SideMenus />
                </Grid>
            </Grid>
            <Modal className="Modal" open={open} onClose={closeModal}>
                <Paper
                    className="ModalContainer"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        flexDirection: "row",
                        minWidth: "80%",
                        maxWidth: "90%",
                        maxHeight: "80vh",
                        padding: "5ch",
                        overflowY: "scroll",
                        gap: "2ch",
                    }}
                >
                    <Box
                        style={{
                            paddingTop: "4ch",
                            display: "flex",
                            flexDirection: "row",
                            gap: "2ch",
                            alignItems: "baseline",
                        }}
                    >
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                paddingTop: "4ch",
                                paddingBottom: "4ch",
                            }}
                        >
                            <Card
                                className="id-front"
                                ref={printable}
                                style={{
                                    backgroundColor: "#fccb46e5",
                                    padding: "2ch",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "1ch",
                                }}
                            >
                                <Box
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        gap: "2ch",
                                    }}
                                >
                                    <Avatar
                                        src={Logo}
                                        alt=""
                                        style={{
                                            height: "4ch",
                                            width: "4ch",
                                        }}
                                    />
                                    <Typography fontWeight="bolder">
                                        El-Hadar Engineering Plc.
                                    </Typography>
                                </Box>
                                <Divider />
                                <Box
                                    style={{
                                        padding: "2ch",
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "2ch",
                                    }}
                                >
                                    <Box
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                        }}
                                    >
                                        {preview ? (
                                            <Avatar
                                                variant="square"
                                                onLoad={() => {
                                                    setLoaded(true);
                                                }}
                                                src={preview}
                                                alt=""
                                                style={{
                                                    height: "10ch",
                                                    width: "10ch",
                                                    display: loaded
                                                        ? "block"
                                                        : "none",
                                                }}
                                            />
                                        ) : (
                                            <AccountBox
                                                style={{
                                                    color: "lightgray",
                                                    height: "10ch",
                                                    width: "10ch",
                                                }}
                                            />
                                        )}

                                        <Box
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bolder">
                                                {`${selectedRow.first_name} ${selectedRow.middle_name}`}
                                            </Typography>
                                            <Typography>
                                                {selectedRow.jobTitle}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider variant="middle" />
                                    <Box>
                                        <Box
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Typography fontWeight="bolder">
                                                ID No.
                                            </Typography>
                                            <Typography>
                                                {selectedRow.id_number}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Typography fontWeight="bolder">
                                                Gender
                                            </Typography>
                                            <Typography>
                                                {
                                                    GENDERS[
                                                        selectedRow.gender - 1
                                                    ]
                                                }
                                            </Typography>
                                        </Box>

                                        <Box
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Typography fontWeight="bolder">
                                                Nationality
                                            </Typography>
                                            <Typography>
                                                {selectedRow.nationalityName}
                                            </Typography>
                                        </Box>
                                        <Divider />
                                        {/* <Box
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <QRCode
                                                value={
                                                    hostURL +
                                                    selectedRow.id +
                                                    "/employee-data"
                                                }
                                            />
                                        </Box> */}
                                    </Box>
                                </Box>
                            </Card>
                            <Box
                                style={{
                                    bottom: 0,
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "1ch",
                                }}
                            >
                                <IconButton
                                    onClick={() =>
                                        exportComponentAsPNG(printable)
                                    }
                                >
                                    <ImageOutlined color="primary" />
                                </IconButton>
                                <IconButton
                                    onClick={() =>
                                        exportComponentAsPDF(printable, {
                                            fileName: `${selectedRow.first_name}`,
                                            pdfOptions: {
                                                h: 210,
                                                w: 148,
                                                unit: "mm",
                                            },
                                        })
                                    }
                                >
                                    <Print color="primary" />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                paddingTop: "2ch",
                                paddingBottom: "2ch",
                            }}
                        >
                            <Card
                                className="id-back"
                                ref={printable_back}
                                style={{
                                    backgroundColor: "#fccb46e5",
                                    padding: "2ch",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "1ch",
                                }}
                            >
                                <Box
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        gap: "2ch",
                                    }}
                                >
                                    <Avatar
                                        src={Logo}
                                        alt=""
                                        style={{
                                            height: "4ch",
                                            width: "4ch",
                                        }}
                                    />
                                    <Typography fontWeight="bolder">
                                        El-Hadar Engineering Plc.
                                    </Typography>
                                </Box>
                                <Divider />
                                <Box
                                    style={{
                                        padding: "2ch",
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "2ch",
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <QRCode
                                            value={
                                                hostURL +
                                                selectedRow.id +
                                                "/employee-data"
                                            }
                                        />
                                    </Box>
                                    <Box
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Box
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bolder">
                                                {`${selectedRow.first_name} ${selectedRow.middle_name}`}
                                            </Typography>
                                            <Typography>
                                                {selectedRow.jobTitle}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider variant="middle" />
                                    <Box>
                                        <Box
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bolder">
                                                Date of Issue
                                            </Typography>
                                            <Typography>
                                                {new Date(
                                                    selectedRow.startDate,
                                                ).toLocaleDateString()}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bolder">
                                                Date of Expiration
                                            </Typography>
                                            <Typography>
                                                {new Date(
                                                    selectedRow.endDate,
                                                ).toLocaleDateString()}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Card>
                            <Box
                                style={{
                                    bottom: 0,
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "1ch",
                                }}
                            >
                                <IconButton
                                    onClick={() =>
                                        exportComponentAsPNG(printable_back)
                                    }
                                >
                                    <ImageOutlined color="primary" />
                                </IconButton>
                                <IconButton
                                    onClick={() =>
                                        exportComponentAsPDF(printable_back, {
                                            fileName: `${selectedRow.first_name} back`,
                                        })
                                    }
                                >
                                    <Print color="primary" />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                    <Divider orientation="vertical" variant="fullWidth" />
                    <Box width={"50%"}>
                        {columns.map((column, i) => (
                            <Box
                                key={i}
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography style={{ fontWeight: "bold" }}>
                                    {column.label}
                                </Typography>
                                <Typography>
                                    {selectedRow[column.name]}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Paper>
            </Modal>
        </Paper>
    );
};
export default EmployeeDetail;
