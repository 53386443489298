import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { EditRounded } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";

import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { patcher, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    ShiftAssignmentURL,
    ShiftScheduleHdrURL,
} from "../../../../utils/config";

const EditShiftAssignment = (props) => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState("");
    // const [filter2, setFilter2] = useState("");
    const [options, setOptions] = useState([]);
    const [optionsId, setOptionsID] = useState();
    const [optionSelect, setOptionSelect] = useState("");
    // const [options2, setOptions2] = useState([]);
    // const [optionsId2, setOptionsID2] = useState();
    // const [optionSelect2, setOptionSelect2] = useState("");
    const selectedDetail = useSelector(
        (state) => state.selectedDetail.selected
    );
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [msg, setMsg] = useState("");

    useEffect(() => {
        if (optionSelect === "")
            setOptionSelect(
                `${selectedDetail.shift_schedule_hdr?.id} - ${selectedDetail.shiftName}`
            );
        // if (optionSelect2 === "")
        //     setOptionSelect2(
        //         `${selectedDetail.employee?.id} - ${selectedDetail.employeeName}`
        //     );
    }, [selectedDetail, optionSelect]);

    const INITIAL_VALUES = {
        shift_schedule_hdr_id: selectedDetail.shift_schedule_hdr?.id,
        employee_id: selectedDetail.employee?.id,
        isProtectedForEdit: false,
    };

    const FORM_VALIDATION = Yup.object().shape({});
    useEffect(() => {
        get(ShiftScheduleHdrURL, {
            limit: 10,
            filter: {
                shift_name: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.shift_name}`
                    );
                });
            res && setOptionsID(res.data);
            setOptions(data);
        });

        // get(EmployeeURL, {
        //     limit: 10,
        //     filter: {
        //         first_name: filter2,
        //     },
        // }).then((res) => {
        //     let data = [];
        //     res &&
        //         res.data.forEach((name, index) => {
        //             data.push(
        //                 `${res.data[index]?.id} - ${res.data[index]?.first_name}`
        //             );
        //         });
        //     res && setOptionsID2(res.data);
        //     setOptions2(data);
        // });
    }, [filter]);

    const formSubmitHandler = (updateData, actions) => {
        setLoading(true);
        const update = {
            id: selectedDetail.id,
            updateData,
        };

        patcher(ShiftAssignmentURL, update).then((res) => {
            setLoading(false);
            // console.log(res.error);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                dispatch(setRefresh(!refreshTable));
                setSuccess(true);
                setError(false);
                props.close();
            }
        });
    };

    return (
        <div>
            <TitleHeader title="Edit Shift Assignment" icon={<EditRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        values.employee_id = selectedDetail.employee?.id;
                        optionsId.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.shift_name}` ===
                                optionSelect
                            ) {
                                values.shift_schedule_hdr_id = name.id;
                            }
                        });
                        // optionsId2.forEach((name, index) => {
                        //     if (
                        //         `${name?.id} - ${name.first_name}` ===
                        //         optionSelect2
                        //     ) {
                        //         values.employee_id = name.id;
                        //     }
                        // });

                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect}
                                    loading={options.length < 1 ? true : false}
                                    disablePortal
                                    options={options}
                                    onChange={(event, newValue) => {
                                        setOptionSelect(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Shift Schedule Hdr"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            {/* <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect2}
                                    loading={options2.length < 1 ? true : false}
                                    disablePortal
                                    options={options2}
                                    onChange={(event, newValue) => {
                                        setOptionSelect2(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Employee"
                                            onChange={(event) => {
                                                setFilter2(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid> */}
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Apply"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default EditShiftAssignment;
