import { TreeItem, TreeView } from "@mui/lab";
import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import { get } from "../../../../utils/Caller";
import { AccountTypeFinancialStatementSectionURL } from "../../../../utils/config";
import { RiFilePaperFill } from "react-icons/ri";
import { FcOpenedFolder, FcFolder } from "react-icons/fc";

const ViewAccountTypeFinancialStatementSection = () => {
    const [data, setData] = useState([]);
    const [loading, setloading] = useState(false);

    useEffect(() => {
        setloading(true);
        get(AccountTypeFinancialStatementSectionURL).then((res) => {
            // console.log(res);
            res && res?.data && setloading(false);
            setData({ id: "root", name: "", children: [...res.data] });
        });
    }, []);

    const renderTree = (nodes) => (
        <TreeItem
            key={nodes.id}
            nodeId={nodes.id}
            label={nodes.name}
            style={{ borderLeft: `black 1px dashed`, padding: "1ch" }}
        >
            {Array.isArray(nodes.children)
                ? nodes.children.map((node) => renderTree(node))
                : null}
        </TreeItem>
    );

    console.log(data);
    return (
        <div className="ViewProject">
            <TitleHeader
                title="Account Type Financial Statement Section"
                icon={<RiFilePaperFill />}
            />
            <div className="TableContainer">
                {loading ? (
                    <CircularProgress />
                ) : (
                    <Box
                        style={{
                            border: "1px solid lightgray",
                            borderRadius: "5px", 
                            padding: "3ch",
                        }}
                    >
                        <TreeView
                            aria-label="rich object"
                            defaultCollapseIcon={<FcOpenedFolder />}
                            disableSelection={true}
                            defaultExpanded={["root", 100000, 100001, 100002]}
                            defaultExpandIcon={<FcFolder />}
                            sx={{
                                height: "fitContent",
                                flexGrow: 1,
                                overflowX: "clip",
                            }}
                        >
                            {renderTree(data)}
                        </TreeView>
                    </Box>
                )}
            </div>
        </div>
    );
};

export default ViewAccountTypeFinancialStatementSection;
