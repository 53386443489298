import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
    Button,
    Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { fileCaller, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    ExternalApplicantURL,
    TrainingTypeURL,
} from "../../../../utils/config";
import { useSelector } from "react-redux";

const AddExternalApplicant = () => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState("");
    const [options1, setOptions1] = useState([]);
    const [optionsId1, setOptionsID1] = useState();
    const [optionSelect1, setOptionSelect1] = useState("");
    const UploadRef = useRef();
    const [path, setPath] = useState();
    const [fileName, setFileName] = useState("");
    const [genderValue, setGenderValue] = useState("");
    const [maritalStatus, setMaritalStatus] = useState("");
    const [msg, setMsg] = useState("");
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    const GENDER = ["Male", "Female"];
    const MARITAL_STATUS = ["Single", "Married", "Widowed", "Divorced"];
    // const phoneRegex = /^(251-)?\d{9}$/;

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        vacancy_id: selectedRow?.id,
        applicant_name: "",
        father_name: "",
        grand_father_name: "",
        mobile_number: "",
        email: "",
        experience_year: "",
        gender: "",
        marital_status: "",
        file: "",
        name: "",
        type: "",
        training_type_id: "",
    };

    const FORM_VALIDATION = Yup.object().shape({
        applicant_name: Yup.string().min(1),
        father_name: Yup.string().min(1),
        grand_father_name: Yup.string().min(1).required("*Required"),
        mobile_number: Yup.string()
            // .matches(phoneRegex, "Enter a vaild phone number")
            .required("*Required"),
        email: Yup.string().email().required("*Required"),
        experience_year: Yup.number().min(1).required("*Required"),
        // gender: Yup.number().min(1),
        // marital_status: Yup.number().min(1),
        // file: Yup.string().min(1).required("*Required"),
        name: Yup.string().min(1).required("*Required"),
        type: Yup.string().min(1).required("*Required"),
        // training_type_id: Yup.number().min(1),
    });
    useEffect(() => {
        get(TrainingTypeURL, {
            limit: 10,
            filter: {
                training_type_name: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.training_type_name}`
                    );
                });
            res && setOptionsID1(res.data);
            setOptions1(data);
        });
    }, [filter]);
    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        fileCaller(ExternalApplicantURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                actions.resetForm();
                return 1;
            }
        });
    };

    return (
        <div>
            <TitleHeader title="Add External Applicant" icon={<AddRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        values.file = path;
                        values.gender = genderValue;
                        values.marital_status = maritalStatus;

                        optionsId1.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.training_type_name}` ===
                                optionSelect1
                            ) {
                                values.training_type_id = name.id;
                            }
                        });

                        /* Then create a new FormData obj */
                        let formData = new FormData();

                        for (let value in values) {
                            if (value === "isProtectedForEdit") {
                                values[value] = false;
                            }
                            formData.append(value, values[value]);
                        }

                        /* Can't console.log(formData), must
                              use formData.entries() - example:  */
                        // for (let property of formData.entries()) {
                        //     console.log(property[0], typeof property[1]);
                        // }
                        formSubmitHandler(formData, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="applicant_name"
                                    label="Applicant Name"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="father_name"
                                    label="Father Name"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="grand_father_name"
                                    label="Grand Father Name"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="mobile_number"
                                    label="Mobile Number"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="experience_year"
                                    label="Experience Year"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    select
                                    name="gender"
                                    label="Gender"
                                    variant="outlined"
                                    value={genderValue}
                                    options={GENDER ?? " "}
                                    onChange={(e) => {
                                        setGenderValue(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    select
                                    name="marital_status"
                                    label="Marital Status"
                                    variant="outlined"
                                    value={maritalStatus}
                                    options={MARITAL_STATUS ?? " "}
                                    onChange={(e) => {
                                        setMaritalStatus(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                display={"flex"}
                                sx={{ gap: "2ch" }}
                            >
                                <Button
                                    data-testid="uploadButton"
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                        UploadRef.current.click();
                                    }}
                                >
                                    File
                                </Button>
                                <input
                                    name="file"
                                    hidden
                                    ref={UploadRef}
                                    type="file"
                                    accept=".pdf"
                                    onChange={(e) => {
                                        setPath(e.target.files[0]);
                                        setFileName(e.target.files[0].name);
                                    }}
                                />
                                <Typography>{fileName}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="name"
                                    label="Name"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="type"
                                    label="Type"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    loading={options1.length < 1 ? true : false}
                                    disablePortal
                                    options={options1}
                                    onChange={(event, newValue) => {
                                        setOptionSelect1(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Training Type"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default AddExternalApplicant;
