import { Route } from "react-router";

import AddActionType from "../Pages/HCM/MasterData/ActionType/AddActionType.page";
import ViewActionType from "../Pages/HCM/MasterData/ActionType/ViewActionType.page";
import ViewAddressType from "../Pages/HCM/MasterData/AddressType/ViewAddressType.page";
import ViewCountry from "../Pages/HCM/MasterData/Country/ViewCountry.page";
import ViewCommitment from "../Pages/HCM/MasterData/Commitment/ViewCommitment.page";
import ViewCustomReport from "../Pages/HCM/MasterData/CustomReport/ViewCustomReport.page";
import ViewCurrency from "../Pages/HCM/MasterData/Currency/ViewCurrency.page";
import ViewDisciplineCaseType from "../Pages/HCM/MasterData/DisciplineCaseType/ViewDisciplineCaseType.page";
import ViewDurationMeasure from "../Pages/HCM/MasterData/DurationMeasure/ViewDurationMeasure.page";
import ViewEmployeeGroup from "../Pages/HCM/MasterData/EmployeeGroup/ViewEmployeeGroup.page";
import ViewEmployeeType from "../Pages/HCM/MasterData/EmployeeType/ViewEmployeeType.page";
import ViewFieldOfStudy from "../Pages/HCM/MasterData/FieldOfStudy/ViewFieldOfStudy.page";
import ViewHCMConfiguration from "../Pages/HCM/MasterData/HCMConfiguration/ViewHCMConfiguration.page";
import ViewEmployeeTransactionLock from "../Pages/HCM/MasterData/EmployeeTransactionLock/ViewEmployeeTransactionLock.page";
import ViewLicenseType from "../Pages/HCM/MasterData/LicenseType/ViewLicenseType.page";
import ViewNationality from "../Pages/HCM/MasterData/Nationality/ViewNationality.page";
import ViewReligion from "../Pages/HCM/MasterData/Religion/ViewReligion.page";
import ViewTrainingType from "../Pages/HCM/MasterData/TrainingType/ViewTrainingType.page";
import AddAddressType from "../Pages/HCM/MasterData/AddressType/AddAddressType.page";
import AddCommitment from "../Pages/HCM/MasterData/Commitment/AddCommitment.page";
import AddCountry from "../Pages/HCM/MasterData/Country/AddCountry.page";
import AddCurrency from "../Pages/HCM/MasterData/Currency/AddCurrency.page";
import AddCustomReport from "../Pages/HCM/MasterData/CustomReport/AddCustomReport.page";
import AddDisciplineCaseType from "../Pages/HCM/MasterData/DisciplineCaseType/AddDisciplineCaseType.page";
import AddDurationMeasure from "../Pages/HCM/MasterData/DurationMeasure/AddDurationMeasure.page";
import AddEmployeeGroup from "../Pages/HCM/MasterData/EmployeeGroup/AddEmployeeGroup.page";
import AddEmployeeTransactionLock from "../Pages/HCM/MasterData/EmployeeTransactionLock/AddEmployeeTransactionLock.page";
import AddLanguage from "../Pages/HCM/MasterData/Language/AddLanguage.page";
import ViewLanguage from "../Pages/HCM/MasterData/Language/ViewLanguage.page";
import AddEmployeeType from "../Pages/HCM/MasterData/EmployeeType/AddEmployeeType.page";
import AddFieldOfStudy from "../Pages/HCM/MasterData/FieldOfStudy/AddFieldOfStudy.page";
import AddLicenseType from "../Pages/HCM/MasterData/LicenseType/AddLicenseType.page";
import AddTrainingType from "../Pages/HCM/MasterData/TrainingType/AddTrainingType.page";
import AddReligion from "../Pages/HCM/MasterData/Religion/AddReligion.page";
import AddNationality from "../Pages/HCM/MasterData/Nationality/AddNationality.page";
import ViewTitle from "../Pages/HCM/MasterData/Title/ViewTitle.page";
import AddTitle from "../Pages/HCM/MasterData/Title/AddTitle.page";
import ViewActionReason from "../Pages/HCM/MasterData/ActionReason/ViewActionReason.page";
import AddActionReason from "../Pages/HCM/MasterData/ActionReason/AddActionReason.page";
import AddHCMConfiguration from "../Pages/HCM/MasterData/HCMConfiguration/AddHCMConfiguration.page";
import AddJobSafetyEquipment from "../Pages/HCM/JobPositions/JobSafetyEquipment/AddJobSafetyEquipment.page";
import AddSafetyEquipment from "../Pages/HCM/JobPositions/SafetyEquipment/AddSafetyEquipment.page";
import ViewSafetyEquipment from "../Pages/HCM/JobPositions/SafetyEquipment/ViewSafetyEquipment.page";
import AddJobTitle from "../Pages/HCM/JobPositions/JobTitle/AddJobTitle.page";
import ViewJobTitle from "../Pages/HCM/JobPositions/JobTitle/ViewJobTitle.page";
import AddJobCategory from "../Pages/HCM/JobPositions/JobCategory/AddJobCategory.page";
import ViewJobCategory from "../Pages/HCM/JobPositions/JobCategory/ViewJobCategory.page";
import ViewJobSafetyEquipment from "../Pages/HCM/JobPositions/JobSafetyEquipment/ViewJobSafetyEquipment.page";
import ViewAddress from "../Pages/HCM/EmployeeMaster/Address/ViewAddress.page";
import AddAddress from "../Pages/HCM/EmployeeMaster/Address/AddAddress.page";
import ViewAttachment from "../Pages/HCM/EmployeeMaster/Attachment/ViewAttachment.page";
import AddAttachment from "../Pages/HCM/EmployeeMaster/Attachment/AddAttachment.page";
import ViewDependent from "../Pages/HCM/EmployeeMaster/Dependent/ViewDependent.page";
import AddDependent from "../Pages/HCM/EmployeeMaster/Dependent/AddDependent.page";
import ViewDiscipline from "../Pages/HCM/EmployeeMaster/Discipline/ViewDiscipline.page";
import AddDiscipline from "../Pages/HCM/EmployeeMaster/Discipline/AddDiscipline.page";
import ViewEducation from "../Pages/HCM/EmployeeMaster/Education/ViewEducation.page";
import AddEducation from "../Pages/HCM/EmployeeMaster/Education/AddEducation.page";
import ViewEmployee from "../Pages/HCM/EmployeeMaster/Employee/ViewEmployee.page";
import AddEmployee from "../Pages/HCM/EmployeeMaster/Employee/AddEmployee.page";
import ViewEmployeeAction from "../Pages/HCM/EmployeeMaster/EmployeeAction/ViewEmployeeAction.page";
import AddEmployeeAction from "../Pages/HCM/EmployeeMaster/EmployeeAction/AddEmployeeAction.page";
import ViewEmployeeAttachment from "../Pages/HCM/EmployeeMaster/EmployeeAttachment/ViewEmployeeAttachment.page";
import AddEmployeeAttachment from "../Pages/HCM/EmployeeMaster/EmployeeAttachment/AddEmployeeAttachment.page";
import ViewEmployeeCommitment from "../Pages/HCM/EmployeeMaster/EmployeeCommitment/ViewEmployeeCommitment.page";
import AddEmployeeCommitment from "../Pages/HCM/EmployeeMaster/EmployeeCommitment/AddEmployeeCommitment.page";
import ViewEmployeeContact from "../Pages/HCM/EmployeeMaster/EmployeeContact/ViewEmployeeContact.page";
import AddEmployeeContact from "../Pages/HCM/EmployeeMaster/EmployeeContact/AddEmployeeContact.page";
import ViewEmployeeIdRange from "../Pages/HCM/EmployeeMaster/EmployeeIdRange/ViewEmployeeIdRange.page";
import AddEmployeeIdRange from "../Pages/HCM/EmployeeMaster/EmployeeIdRange/AddEmployeeIdRange.page";
import ViewEmployeeLoanRepayment from "../Pages/HCM/EmployeeMaster/EmployeeLoanRepayment/ViewEmployeeLoanRepayment.page";
import AddEmployeeLoanRepayment from "../Pages/HCM/EmployeeMaster/EmployeeLoanRepayment/AddEmployeeLoanRepayment.page";
import ViewEmployeePayFrequency from "../Pages/HCM/EmployeeMaster/EmployeePayFrequency/ViewEmployeePayFrequency.page";
import AddEmployeePayFrequency from "../Pages/HCM/EmployeeMaster/EmployeePayFrequency/AddEmployeePayFrequency.page";
import ViewEmployeePaygrade from "../Pages/HCM/EmployeeMaster/EmployeePaygrade/ViewEmployeePaygrade.page";
import AddEmployeePaygrade from "../Pages/HCM/EmployeeMaster/EmployeePaygrade/AddEmployeePaygrade.page";
import ViewEmployeeSalaryComponent from "../Pages/HCM/EmployeeMaster/EmployeeSalaryComponent/ViewEmployeeSalaryComponent.page";
import AddEmployeeSalaryComponent from "../Pages/HCM/EmployeeMaster/EmployeeSalaryComponent/AddEmployeeSalaryComponent.page";
import ViewExperience from "../Pages/HCM/EmployeeMaster/Experience/ViewExperience.page";
import AddExperience from "../Pages/HCM/EmployeeMaster/Experience/AddExperience.page";
import ViewLeaveAssignment from "../Pages/HCM/EmployeeMaster/LeaveAssignment/ViewLeaveAssignment.page";
import AddLeaveAssignment from "../Pages/HCM/EmployeeMaster/LeaveAssignment/AddLeaveAssignment.page";
import ViewLeaveEntitlement from "../Pages/HCM/EmployeeMaster/LeaveEntitlement/ViewLeaveEntitlement.page";
import AddLeaveEntitlement from "../Pages/HCM/EmployeeMaster/LeaveEntitlement/AddLeaveEntitlement.page";
import ViewLeaveTransfer from "../Pages/HCM/EmployeeMaster/LeaveTransfer/ViewLeaveTransfer.page";
import AddLeaveTransfer from "../Pages/HCM/EmployeeMaster/LeaveTransfer/AddLeaveTransfer.page";
import ViewLicense from "../Pages/HCM/EmployeeMaster/License/ViewLicense.page";
import AddLicense from "../Pages/HCM/EmployeeMaster/License/AddLicense.page";
import ViewOrgAssignment from "../Pages/HCM/EmployeeMaster/OrgAssignment/ViewOrgAssignment.page";
import AddOrgAssignment from "../Pages/HCM/EmployeeMaster/OrgAssignment/AddOrgAssignment.page";
import ViewServiceAllowancePay from "../Pages/HCM/EmployeeMaster/ServiceAllowancePay/ViewServiceAllowancePay.page";
import AddServiceAllowancePay from "../Pages/HCM/EmployeeMaster/ServiceAllowancePay/AddServiceAllowancePay.page";
import ViewShiftAssignment from "../Pages/HCM/EmployeeMaster/ShiftAssignment/ViewShiftAssignment.page";
import AddShiftAssignment from "../Pages/HCM/EmployeeMaster/ShiftAssignment/AddShiftAssignment.page";
import ViewBusinessUnit from "../Pages/HCM/CompanyStructure/BusinessUnit/ViewBusinessUnit.page";
import AddBusinessUnit from "../Pages/HCM/CompanyStructure/BusinessUnit/AddBusinessUnit.page";
import ViewCompany from "../Pages/HCM/CompanyStructure/Company/ViewCompany.page";
import AddCompany from "../Pages/HCM/CompanyStructure/Company/AddCompany.page";
import ViewLocation from "../Pages/HCM/CompanyStructure/Location/ViewLocation.page";
import AddLocation from "../Pages/HCM/CompanyStructure/Location/AddLocation.page";
import ViewCompanyPrimaryContact from "../Pages/HCM/CompanyStructure/CompanyPrimaryContact/ViewCompanyPrimaryContact.page";
import AddCompanyPrimaryContact from "../Pages/HCM/CompanyStructure/CompanyPrimaryContact/AddCompanyPrimaryContact.page";
import ViewEmployeeBackPenalityDeduction from "../Pages/HCM/Payroll/EmployeeBackPenalityDeduction/ViewEmployeeBackPenalityDeduction.page";
import AddEmployeeBackPenalityDeduction from "../Pages/HCM/Payroll/EmployeeBackPenalityDeduction/AddEmployeeBackPenalityDeduction.page";
import ViewEmployeePayScale from "../Pages/HCM/Payroll/EmployeePayScale/ViewEmployeePayScale.page";
import AddEmployeePayScale from "../Pages/HCM/Payroll/EmployeePayScale/AddEmployeePayScale.page";
import ViewEmployeePenality from "../Pages/HCM/Payroll/EmployeePenality/ViewEmployeePenality.page";
import AddEmployeePenality from "../Pages/HCM/Payroll/EmployeePenality/AddEmployeePenality.page";
import ViewEmployeePenalityDetailMst from "../Pages/HCM/Payroll/EmployeePenalityDetailMst/ViewEmployeePenalityDetailMst.page";
import AddEmployeePenalityDetailMst from "../Pages/HCM/Payroll/EmployeePenalityDetailMst/AddEmployeePenalityDetailMst.page";
import ViewEmployeePenalityType from "../Pages/HCM/Payroll/EmployeePenalityType/ViewEmployeePenalityType.page";
import AddEmployeePenalityType from "../Pages/HCM/Payroll/EmployeePenalityType/AddEmployeePenalityType.page";
import ViewEmployeeTax from "../Pages/HCM/Payroll/EmployeeTax/ViewEmployeeTax.page";
import AddEmployeeTax from "../Pages/HCM/Payroll/EmployeeTax/AddEmployeeTax.page";
import ViewGlobalPayrollAccountMapping from "../Pages/HCM/Payroll/GlobalPayrollAccountMapping/ViewGlobalPayrollAccountMapping.page";
import AddGlobalPayrollAccountMapping from "../Pages/HCM/Payroll/GlobalPayrollAccountMapping/AddGlobalPayrollAccountMapping.page";
import ViewOvertime from "../Pages/HCM/Payroll/Overtime/ViewOvertime.page";
import AddOvertime from "../Pages/HCM/Payroll/Overtime/AddOvertime.page";
import ViewOvertimeRate from "../Pages/HCM/Payroll/OvertimeRate/ViewOvertimeRate.page";
import AddOvertimeRate from "../Pages/HCM/Payroll/OvertimeRate/AddOvertimeRate.page";
import ViewPaygrade from "../Pages/HCM/Payroll/Paygrade/ViewPaygrade.page";
import AddPaygrade from "../Pages/HCM/Payroll/Paygrade/AddPaygrade.page";
import ViewPaygradeSalaryComponent from "../Pages/HCM/Payroll/PaygradeSalaryComponent/ViewPaygradeSalaryComponent.page";
import AddPaygradeSalaryComponent from "../Pages/HCM/Payroll/PaygradeSalaryComponent/AddPaygradeSalaryComponent.page";
import ViewPaygradeScale from "../Pages/HCM/Payroll/PaygradeScale/ViewPaygradeScale.page";
import AddPaygradeScale from "../Pages/HCM/Payroll/PaygradeScale/AddPaygradeScale.page";
import ViewPayrollDetail from "../Pages/HCM/Payroll/PayrollDetail/ViewPayrollDetail.page";
import AddPayrollDetail from "../Pages/HCM/Payroll/PayrollDetail/AddPayrollDetail.page";
import ViewPayrollDetailHistory from "../Pages/HCM/Payroll/PayrollDetailHistory/ViewPayrollDetailHistory.page";
import AddPayrollDetailHistory from "../Pages/HCM/Payroll/PayrollDetailHistory/AddPayrollDetailHistory.page";
import ViewPayrollFrequencyType from "../Pages/HCM/Payroll/PayrollFrequencyType/ViewPayrollFrequencyType.page";
import AddPayrollFrequencyType from "../Pages/HCM/Payroll/PayrollFrequencyType/AddPayrollFrequencyType.page";
import ViewPayrollLocationSetting from "../Pages/HCM/Payroll/PayrollLocationSetting/ViewPayrollLocationSetting.page";
import AddPayrollLocationSetting from "../Pages/HCM/Payroll/PayrollLocationSetting/AddPayrollLocationSetting.page";
import ViewPayrollLog from "../Pages/HCM/Payroll/PayrollLog/ViewPayrollLog.page";
import AddPayrollLog from "../Pages/HCM/Payroll/PayrollLog/AddPayrollLog.page";
import ViewPayrollLogEmployee from "../Pages/HCM/Payroll/PayrollLogEmployee/ViewPayrollLogEmployee.page";
import AddPayrollLogEmployee from "../Pages/HCM/Payroll/PayrollLogEmployee/AddPayrollLogEmployee.page";
import ViewPayrollPeriodAutogen from "../Pages/HCM/Payroll/PayrollPeriodAutogen/ViewPayrollPeriodAutogen.page";
import AddPayrollPeriodAutogen from "../Pages/HCM/Payroll/PayrollPeriodAutogen/AddPayrollPeriodAutogen.page";
import ViewPayrollPeriodAutogenLog from "../Pages/HCM/Payroll/PayrollPeriodAutogenLog/ViewPayrollPeriodAutogenLog.page";
import AddPayrollPeriodAutogenLog from "../Pages/HCM/Payroll/PayrollPeriodAutogenLog/AddPayrollPeriodAutogenLog.page";
import ViewPayrollPeriodTemplate from "../Pages/HCM/Payroll/PayrollPeriodTemplate/ViewPayrollPeriodTemplate.page";
import AddPayrollPeriodTemplate from "../Pages/HCM/Payroll/PayrollPeriodTemplate/AddPayrollPeriodTemplate.page";
import ViewPayrollPostingLog from "../Pages/HCM/Payroll/PayrollPostingLog/ViewPayrollPostingLog.page";
import AddPayrollPostingLog from "../Pages/HCM/Payroll/PayrollPostingLog/AddPayrollPostingLog.page";
import ViewPayrollProcessingLog from "../Pages/HCM/Payroll/PayrollProcessingLog/ViewPayrollProcessingLog.page";
import AddPayrollProcessingLog from "../Pages/HCM/Payroll/PayrollProcessingLog/AddPayrollProcessingLog.page";
import ViewPayrollSummary from "../Pages/HCM/Payroll/PayrollSummary/ViewPayrollSummary.page";
import AddPayrollSummary from "../Pages/HCM/Payroll/PayrollSummary/AddPayrollSummary.page";
import ViewPayrollSummaryHistory from "../Pages/HCM/Payroll/PayrollSummaryHistory/ViewPayrollSummaryHistory.page";
import AddPayrollSummaryHistory from "../Pages/HCM/Payroll/PayrollSummaryHistory/AddPayrollSummaryHistory.page";
import ViewPayScaleHistoryLog from "../Pages/HCM/Payroll/PayScaleHistoryLog/ViewPayScaleHistoryLog.page";
import AddPayScaleHistoryLog from "../Pages/HCM/Payroll/PayScaleHistoryLog/AddPayScaleHistoryLog.page";
import ViewSalaryAdjustment from "../Pages/HCM/Payroll/SalaryAdjustment/ViewSalaryAdjustment.page";
import AddSalaryAdjustment from "../Pages/HCM/Payroll/SalaryAdjustment/AddSalaryAdjustment.page";
import ViewSalaryComponent from "../Pages/HCM/Payroll/SalaryComponent/ViewSalaryComponent.page";
import AddSalaryComponent from "../Pages/HCM/Payroll/SalaryComponent/AddSalaryComponent.page";
import ViewSalaryComponentAccountMapping from "../Pages/HCM/Payroll/SalaryComponentAccountMapping/ViewSalaryComponentAccountMapping.page";
import AddSalaryComponentAccountMapping from "../Pages/HCM/Payroll/SalaryComponentAccountMapping/AddSalaryComponentAccountMapping.page";
import ViewExternalApplicant from "../Pages/HCM/Recruitment/ExternalApplicant/ViewExternalApplicant.page";
import AddExternalApplicant from "../Pages/HCM/Recruitment/ExternalApplicant/AddExternalApplicant.page";
import ViewVacancy from "../Pages/HCM/Recruitment/Vacancy/ViewVacancy.page";
import AddVacancy from "../Pages/HCM/Recruitment/Vacancy/AddVacancy.page";
import ViewVacancyResult from "../Pages/HCM/Recruitment/VacancyResult/ViewVacancyResult.page";
import AddVacancyResult from "../Pages/HCM/Recruitment/VacancyResult/AddVacancyResult.page";
import ViewVacancyExaminer from "../Pages/HCM/Recruitment/VacancyExaminer/ViewVacancyExaminer.page";
import AddVacancyExaminer from "../Pages/HCM/Recruitment/VacancyExaminer/AddVacancyExaminer.page";
import ViewVacancyInternalApplicant from "../Pages/HCM/Recruitment/VacancyInternalApplicant/ViewVacancyInternalApplicant.page";
import AddVacancyInternalApplicant from "../Pages/HCM/Recruitment/VacancyInternalApplicant/AddVacancyInternalApplicant.page";
import ViewVacancyRequestReason from "../Pages/HCM/Recruitment/VacancyRequestReason/ViewVacancyRequestReason.page";
import AddVacancyRequestReason from "../Pages/HCM/Recruitment/VacancyRequestReason/AddVacancyRequestReason.page";
import ViewAttendanceAbscenceType from "../Pages/HCM/TimeAndLeave/AttendanceAbscenceType/ViewAttendanceAbscenceType.page";
import AddAttendanceAbscenceType from "../Pages/HCM/TimeAndLeave/AttendanceAbscenceType/AddAttendanceAbscenceType.page";
import ViewAttendanceCaptured from "../Pages/HCM/TimeAndLeave/AttendanceCaptured/ViewAttendanceCaptured.page";
import AddAttendanceCaptured from "../Pages/HCM/TimeAndLeave/AttendanceCaptured/AddAttendanceCaptured.page";
import ViewAttendancePayroll from "../Pages/HCM/TimeAndLeave/AttendancePayroll/ViewAttendancePayroll.page";
import AddAttendancePayroll from "../Pages/HCM/TimeAndLeave/AttendancePayroll/AddAttendancePayroll.page";
import ViewDeviceIdMapping from "../Pages/HCM/TimeAndLeave/DeviceIdMapping/ViewDeviceIdMapping.page";
import AddDeviceIdMapping from "../Pages/HCM/TimeAndLeave/DeviceIdMapping/AddDeviceIdMapping.page";
import ViewHoliday from "../Pages/HCM/TimeAndLeave/Holiday/ViewHoliday.page";
import AddHoliday from "../Pages/HCM/TimeAndLeave/Holiday/AddHoliday.page";
import ViewHolidayCharacter from "../Pages/HCM/TimeAndLeave/HolidayCharacter/ViewHolidayCharacter.page";
import AddHolidayCharacter from "../Pages/HCM/TimeAndLeave/HolidayCharacter/AddHolidayCharacter.page";
import ViewLeavePeriod from "../Pages/HCM/TimeAndLeave/LeavePeriod/ViewLeavePeriod.page";
import AddLeavePeriod from "../Pages/HCM/TimeAndLeave/LeavePeriod/AddLeavePeriod.page";
import ViewLeavePlan from "../Pages/HCM/TimeAndLeave/LeavePlan/ViewLeavePlan.page";
import AddLeavePlan from "../Pages/HCM/TimeAndLeave/LeavePlan/AddLeavePlan.page";
import ViewPunch from "../Pages/HCM/TimeAndLeave/Punch/ViewPunch.page";
import AddPunch from "../Pages/HCM/TimeAndLeave/Punch/AddPunch.page";
import ViewPunchDevice from "../Pages/HCM/TimeAndLeave/PunchDevice/ViewPunchDevice.page";
import AddPunchDevice from "../Pages/HCM/TimeAndLeave/PunchDevice/AddPunchDevice.page";
import ViewPunchLog from "../Pages/HCM/TimeAndLeave/PunchLog/ViewPunchLog.page";
import AddPunchLog from "../Pages/HCM/TimeAndLeave/PunchLog/AddPunchLog.page";
import ViewPunchManualImport from "../Pages/HCM/TimeAndLeave/PunchManualImport/ViewPunchManualImport.page";
import AddPunchManualImport from "../Pages/HCM/TimeAndLeave/PunchManualImport/AddPunchManualImport.page";
import ViewPunchTime from "../Pages/HCM/TimeAndLeave/PunchTime/ViewPunchTime.page";
import AddPunchTime from "../Pages/HCM/TimeAndLeave/PunchTime/AddPunchTime.page";
import ViewShiftScheduleDtl from "../Pages/HCM/TimeAndLeave/ShiftScheduleDtl/ViewShiftScheduleDtl.page";
import AddShiftScheduleDtl from "../Pages/HCM/TimeAndLeave/ShiftScheduleDtl/AddShiftScheduleDtl.page";
import ViewShiftScheduleHdr from "../Pages/HCM/TimeAndLeave/ShiftScheduleHdr/ViewShiftScheduleHdr.page";
import AddShiftScheduleHdr from "../Pages/HCM/TimeAndLeave/ShiftScheduleHdr/AddShiftScheduleHdr.page";
import ViewSubShiftGroup from "../Pages/HCM/TimeAndLeave/SubShiftGroup/ViewSubShiftGroup.page";
import AddSubShiftGroup from "../Pages/HCM/TimeAndLeave/SubShiftGroup/AddSubShiftGroup.page";
import EmployeeDetail from "../Pages/HCM/EmployeeMaster/EmployeeDetail.page";
import EditEmployee from "../Pages/HCM/EmployeeMaster/Employee/EditEmployee.page";
import HCMApproval from "../Pages/HCM/HCMApproval.page";
import PayrollSlip from "../Pages/HCM/Payroll/PayrollSlip.page";
import VacancyDetail from "../Pages/HCM/Recruitment/Vacancy/VacancyDetail.page";
import AttendanceRelease from "../Pages/HCM/TimeAndLeave/AttendanceSheet/AttendanceRelease.page";
import AttendanceCreate from "../Pages/HCM/TimeAndLeave/AttendanceSheet/AttendanceCreate.page";
import AddCalendar from "../Pages/HCM/EmployeeMaster/Calendar/AddCalendar.page";
import PayrollControl from "../Pages/HCM/PayrollControl.page";

export const HCMRoutes = [
    <Route path="HCM">
        <Route path="Approval" element={<HCMApproval />} />
        <Route path="PayrollControl" element={<PayrollControl />} />
        <Route path="ActionType" element={<ViewActionType />}>
            <Route path="AddActionType" element={<AddActionType />} />
        </Route>
        <Route path="ActionReason" element={<ViewActionReason />}>
            <Route path="AddActionReason" element={<AddActionReason />} />
        </Route>
        <Route path="AddressType" element={<ViewAddressType />}>
            <Route path="AddAddressType" element={<AddAddressType />} />
        </Route>
        <Route path="Commitment" element={<ViewCommitment />}>
            <Route path="AddCommitment" element={<AddCommitment />} />
        </Route>
        <Route path="Country" element={<ViewCountry />}>
            <Route path="AddCountry" element={<AddCountry />} />
        </Route>
        <Route path="Currency" element={<ViewCurrency />}>
            <Route path="AddCurrency" element={<AddCurrency />} />
        </Route>
        <Route path="CustomReport" element={<ViewCustomReport />}>
            <Route path="AddCustomReport" element={<AddCustomReport />} />
        </Route>
        <Route path="DisciplineCaseType" element={<ViewDisciplineCaseType />}>
            <Route
                path="AddDisciplineCaseType"
                element={<AddDisciplineCaseType />}
            />
        </Route>
        <Route path="DurationMeasure" element={<ViewDurationMeasure />}>
            <Route path="AddDurationMeasure" element={<AddDurationMeasure />} />
        </Route>
        <Route path="EmployeeGroup" element={<ViewEmployeeGroup />}>
            <Route path="AddEmployeeGroup" element={<AddEmployeeGroup />} />
        </Route>
        <Route path="EmployeeType" element={<ViewEmployeeType />}>
            <Route path="AddEmployeeType" element={<AddEmployeeType />} />
        </Route>
        <Route path="FieldOfStudy" element={<ViewFieldOfStudy />}>
            <Route path="AddFieldOfStudy" element={<AddFieldOfStudy />} />
        </Route>
        <Route path="HCMConfiguration" element={<ViewHCMConfiguration />}>
            <Route
                path="AddHCMConfiguration"
                element={<AddHCMConfiguration />}
            />
        </Route>
        <Route
            path="EmployeeTransactionLock"
            element={<ViewEmployeeTransactionLock />}
        >
            <Route
                path="AddEmployeeTransactionLock"
                element={<AddEmployeeTransactionLock />}
            />
        </Route>
        <Route path="Language" element={<ViewLanguage />}>
            <Route path="AddLanguage" element={<AddLanguage />} />
        </Route>
        <Route path="LicenseType" element={<ViewLicenseType />}>
            <Route path="AddLicenseType" element={<AddLicenseType />} />
        </Route>
        <Route path="Nationality" element={<ViewNationality />}>
            <Route path="AddNationality" element={<AddNationality />} />
        </Route>
        <Route path="Religion" element={<ViewReligion />}>
            <Route path="AddReligion" element={<AddReligion />} />
        </Route>
        <Route path="Title" element={<ViewTitle />}>
            <Route path="AddTitle" element={<AddTitle />} />
        </Route>
        <Route path="TrainingType" element={<ViewTrainingType />}>
            <Route path="AddTrainingType" element={<AddTrainingType />} />
        </Route>

        {/* ========================||JOB POSITIONS||============================== */}

        <Route path="JobCategory" element={<ViewJobCategory />}>
            <Route path="AddJobCategory" element={<AddJobCategory />} />
        </Route>
        <Route path="JobTitle" element={<ViewJobTitle />}>
            <Route path="AddJobTitle" element={<AddJobTitle />} />
        </Route>
        <Route path="SafetyEquipment" element={<ViewSafetyEquipment />}>
            <Route path="AddSafetyEquipment" element={<AddSafetyEquipment />} />
        </Route>
        <Route path="JobSafetyEquipment" element={<ViewJobSafetyEquipment />}>
            <Route
                path="AddJobSafetyEquipment"
                element={<AddJobSafetyEquipment />}
            />
        </Route>

        {/* ========================||HCM EMPLOYEE MASTER||============================== */}

        <Route path="AddCalendar" element={<AddCalendar />} />
        <Route path="Address" element={<ViewAddress />}>
            <Route path="AddAddress" element={<AddAddress />} />
        </Route>

        <Route path="Dependent" element={<ViewDependent />}>
            <Route path="AddDependent" element={<AddDependent />} />
        </Route>
        <Route path="Discipline" element={<ViewDiscipline />}>
            <Route path="AddDiscipline" element={<AddDiscipline />} />
        </Route>
        <Route path="Education" element={<ViewEducation />}>
            <Route path="AddEducation" element={<AddEducation />} />
        </Route>
        <Route path="Employee" element={<ViewEmployee />}>
            <Route path=":EmployeeId" element={<EmployeeDetail />}>
                <Route path="Edit" element={<EditEmployee />} />
                <Route path="Attachment" element={<ViewAttachment />}>
                    <Route path="AddAttachment" element={<AddAttachment />} />
                </Route>
                <Route path="Address" element={<ViewAddress />}>
                    <Route path="AddAddress" element={<AddAddress />} />
                </Route>
                <Route path="Dependent" element={<ViewDependent />}>
                    <Route path="AddDependent" element={<AddDependent />} />
                </Route>
                <Route path="Discipline" element={<ViewDiscipline />}>
                    <Route path="AddDiscipline" element={<AddDiscipline />} />
                </Route>
                <Route path="Education" element={<ViewEducation />}>
                    <Route path="AddEducation" element={<AddEducation />} />
                </Route>
                <Route path="EmployeeAction" element={<ViewEmployeeAction />}>
                    <Route
                        path="AddEmployeeAction"
                        element={<AddEmployeeAction />}
                    />
                </Route>
                <Route
                    path="EmployeeAttachment"
                    element={<ViewEmployeeAttachment />}
                >
                    <Route
                        path="AddEmployeeAttachment"
                        element={<AddEmployeeAttachment />}
                    />
                </Route>
                <Route
                    path="EmployeeCommitment"
                    element={<ViewEmployeeCommitment />}
                >
                    <Route
                        path="AddEmployeeCommitment"
                        element={<AddEmployeeCommitment />}
                    />
                </Route>
                <Route path="EmployeeContact" element={<ViewEmployeeContact />}>
                    <Route
                        path="AddEmployeeContact"
                        element={<AddEmployeeContact />}
                    />
                </Route>
                <Route
                    path="EmployeeLoanRepayment"
                    element={<ViewEmployeeLoanRepayment />}
                >
                    <Route
                        path="AddEmployeeLoanRepayment"
                        element={<AddEmployeeLoanRepayment />}
                    />
                </Route>
                <Route
                    path="EmployeePayFrequency"
                    element={<ViewEmployeePayFrequency />}
                >
                    <Route
                        path="AddEmployeePayFrequency"
                        element={<AddEmployeePayFrequency />}
                    />
                </Route>
                <Route
                    path="EmployeePaygrade"
                    element={<ViewEmployeePaygrade />}
                >
                    <Route
                        path="AddEmployeePaygrade"
                        element={<AddEmployeePaygrade />}
                    />
                </Route>
                <Route
                    path="EmployeeSalaryComponent"
                    element={<ViewEmployeeSalaryComponent />}
                >
                    <Route
                        path="AddEmployeeSalaryComponent"
                        element={<AddEmployeeSalaryComponent />}
                    />
                </Route>
                <Route path="Experience" element={<ViewExperience />}>
                    <Route path="AddExperience" element={<AddExperience />} />
                </Route>
                <Route path="LeaveAssignment" element={<ViewLeaveAssignment />}>
                    <Route
                        path="AddLeaveAssignment"
                        element={<AddLeaveAssignment />}
                    />
                </Route>
                <Route
                    path="LeaveEntitlement"
                    element={<ViewLeaveEntitlement />}
                >
                    <Route
                        path="AddLeaveEntitlement"
                        element={<AddLeaveEntitlement />}
                    />
                </Route>
                <Route path="LeaveTransfer" element={<ViewLeaveTransfer />}>
                    <Route
                        path="AddLeaveTransfer"
                        element={<AddLeaveTransfer />}
                    />
                </Route>
                <Route path="License" element={<ViewLicense />}>
                    <Route path="AddLicense" element={<AddLicense />} />
                </Route>
                <Route
                    path="ServiceAllowancePay"
                    element={<ViewServiceAllowancePay />}
                >
                    <Route
                        path="AddServiceAllowancePay"
                        element={<AddServiceAllowancePay />}
                    />
                </Route>
                <Route path="ShiftAssignment" element={<ViewShiftAssignment />}>
                    <Route
                        path="AddShiftAssignment"
                        element={<AddShiftAssignment />}
                    />
                </Route>
                <Route
                    path="EmployeeBackPenalityDeduction"
                    element={<ViewEmployeeBackPenalityDeduction />}
                >
                    <Route
                        path="AddEmployeeBackPenalityDeduction"
                        element={<AddEmployeeBackPenalityDeduction />}
                    />
                </Route>
                <Route
                    path="EmployeePenality"
                    element={<ViewEmployeePenality />}
                >
                    <Route
                        path="AddEmployeePenality"
                        element={<AddEmployeePenality />}
                    />
                </Route>
                <Route
                    path="EmployeePenalityDetailMst"
                    element={<ViewEmployeePenalityDetailMst />}
                >
                    <Route
                        path="AddEmployeePenalityDetailMst"
                        element={<AddEmployeePenalityDetailMst />}
                    />
                </Route>
            </Route>
            <Route path="AddEmployee" element={<AddEmployee />} />
        </Route>
        <Route path="EmployeeAction" element={<ViewEmployeeAction />}>
            <Route path="AddEmployeeAction" element={<AddEmployeeAction />} />
        </Route>
        <Route path="EmployeeAttachment" element={<ViewEmployeeAttachment />}>
            <Route
                path="AddEmployeeAttachment"
                element={<AddEmployeeAttachment />}
            />
        </Route>
        <Route path="EmployeeCommitment" element={<ViewEmployeeCommitment />}>
            <Route
                path="AddEmployeeCommitment"
                element={<AddEmployeeCommitment />}
            />
        </Route>
        <Route path="EmployeeContact" element={<ViewEmployeeContact />}>
            <Route path="AddEmployeeContact" element={<AddEmployeeContact />} />
        </Route>
        <Route path="EmployeeIdRange" element={<ViewEmployeeIdRange />}>
            <Route path="AddEmployeeIdRange" element={<AddEmployeeIdRange />} />
        </Route>
        <Route
            path="EmployeeLoanRepayment"
            element={<ViewEmployeeLoanRepayment />}
        >
            <Route
                path="AddEmployeeLoanRepayment"
                element={<AddEmployeeLoanRepayment />}
            />
        </Route>
        <Route
            path="EmployeePayFrequency"
            element={<ViewEmployeePayFrequency />}
        >
            <Route
                path="AddEmployeePayFrequency"
                element={<AddEmployeePayFrequency />}
            />
        </Route>
        <Route path="EmployeePaygrade" element={<ViewEmployeePaygrade />}>
            <Route
                path="AddEmployeePaygrade"
                element={<AddEmployeePaygrade />}
            />
        </Route>
        <Route
            path="EmployeeSalaryComponent"
            element={<ViewEmployeeSalaryComponent />}
        >
            <Route
                path="AddEmployeeSalaryComponent"
                element={<AddEmployeeSalaryComponent />}
            />
        </Route>
        <Route path="Experience" element={<ViewExperience />}>
            <Route path="AddExperience" element={<AddExperience />} />
        </Route>
        <Route path="LeaveAssignment" element={<ViewLeaveAssignment />}>
            <Route path="AddLeaveAssignment" element={<AddLeaveAssignment />} />
        </Route>
        <Route path="LeaveEntitlement" element={<ViewLeaveEntitlement />}>
            <Route
                path="AddLeaveEntitlement"
                element={<AddLeaveEntitlement />}
            />
        </Route>
        <Route path="LeaveTransfer" element={<ViewLeaveTransfer />}>
            <Route path="AddLeaveTransfer" element={<AddLeaveTransfer />} />
        </Route>
        <Route path="License" element={<ViewLicense />}>
            <Route path="AddLicense" element={<AddLicense />} />
        </Route>
        <Route path="OrgAssignment" element={<ViewOrgAssignment />}>
            <Route path="AddOrgAssignment" element={<AddOrgAssignment />} />
        </Route>
        <Route path="ServiceAllowancePay" element={<ViewServiceAllowancePay />}>
            <Route
                path="AddServiceAllowancePay"
                element={<AddServiceAllowancePay />}
            />
        </Route>
        <Route path="ShiftAssignment" element={<ViewShiftAssignment />}>
            <Route path="AddShiftAssignment" element={<AddShiftAssignment />} />
        </Route>
        <Route
            path="EmployeeBackPenalityDeduction"
            element={<ViewEmployeeBackPenalityDeduction />}
        >
            <Route
                path="AddEmployeeBackPenalityDeduction"
                element={<AddEmployeeBackPenalityDeduction />}
            />
        </Route>
        <Route path="EmployeePenality" element={<ViewEmployeePenality />}>
            <Route
                path="AddEmployeePenality"
                element={<AddEmployeePenality />}
            />
        </Route>
        <Route
            path="EmployeePenalityDetailMst"
            element={<ViewEmployeePenalityDetailMst />}
        >
            <Route
                path="AddEmployeePenalityDetailMst"
                element={<AddEmployeePenalityDetailMst />}
            />
        </Route>

        {/* =====================================||HCM COMPANY STRUCTURE||======================================================= */}

        <Route path="BusinessUnit" element={<ViewBusinessUnit />}>
            <Route path="AddBusinessUnit" element={<AddBusinessUnit />} />
        </Route>
        <Route path="Company" element={<ViewCompany />}>
            <Route path="AddCompany" element={<AddCompany />} />
        </Route>
        <Route path="Location" element={<ViewLocation />}>
            <Route path="AddLocation" element={<AddLocation />} />
        </Route>
        <Route
            path="CompanyPrimaryContact"
            element={<ViewCompanyPrimaryContact />}
        >
            <Route
                path="AddCompanyPrimaryContact"
                element={<AddCompanyPrimaryContact />}
            />
        </Route>

        {/* =========================================|| HCM PAYROLLS ||======================================================== */}

        <Route path="PayrollSlip" element={<PayrollSlip />} />

        <Route path="EmployeePayScale" element={<ViewEmployeePayScale />}>
            <Route
                path="AddEmployeePayScale"
                element={<AddEmployeePayScale />}
            />
        </Route>
        <Route
            path="EmployeePenalityType"
            element={<ViewEmployeePenalityType />}
        >
            <Route
                path="AddEmployeePenalityType"
                element={<AddEmployeePenalityType />}
            />
        </Route>
        <Route path="EmployeeTax" element={<ViewEmployeeTax />}>
            <Route path="AddEmployeeTax" element={<AddEmployeeTax />} />
        </Route>
        <Route
            path="GlobalPayrollAccountMapping"
            element={<ViewGlobalPayrollAccountMapping />}
        >
            <Route
                path="AddGlobalPayrollAccountMapping"
                element={<AddGlobalPayrollAccountMapping />}
            />
        </Route>
        <Route path="Overtime" element={<ViewOvertime />}>
            <Route path="AddOvertime" element={<AddOvertime />} />
        </Route>
        <Route path="OvertimeRate" element={<ViewOvertimeRate />}>
            <Route path="AddOvertimeRate" element={<AddOvertimeRate />} />
        </Route>
        <Route path="Paygrade" element={<ViewPaygrade />}>
            <Route path="AddPaygrade" element={<AddPaygrade />} />
        </Route>
        <Route
            path="PaygradeSalaryComponent"
            element={<ViewPaygradeSalaryComponent />}
        >
            <Route
                path="AddPaygradeSalaryComponent"
                element={<AddPaygradeSalaryComponent />}
            />
        </Route>
        <Route path="PaygradeScale" element={<ViewPaygradeScale />}>
            <Route path="AddPaygradeScale" element={<AddPaygradeScale />} />
        </Route>
        <Route path="PayrollDetail" element={<ViewPayrollDetail />}>
            <Route path="AddPayrollDetail" element={<AddPayrollDetail />} />
        </Route>
        <Route
            path="PayrollDetailHistory"
            element={<ViewPayrollDetailHistory />}
        >
            <Route
                path="AddPayrollDetailHistory"
                element={<AddPayrollDetailHistory />}
            />
        </Route>
        <Route
            path="PayrollFrequencyType"
            element={<ViewPayrollFrequencyType />}
        >
            <Route
                path="AddPayrollFrequencyType"
                element={<AddPayrollFrequencyType />}
            />
        </Route>
        <Route
            path="PayrollLocationSetting"
            element={<ViewPayrollLocationSetting />}
        >
            <Route
                path="AddPayrollLocationSetting"
                element={<AddPayrollLocationSetting />}
            />
        </Route>
        <Route path="PayrollLog" element={<ViewPayrollLog />}>
            <Route path="AddPayrollLog" element={<AddPayrollLog />} />
        </Route>
        <Route path="PayrollLogEmployee" element={<ViewPayrollLogEmployee />}>
            <Route
                path="AddPayrollLogEmployee"
                element={<AddPayrollLogEmployee />}
            />
        </Route>
        <Route
            path="PayrollPeriodAutogen"
            element={<ViewPayrollPeriodAutogen />}
        >
            <Route
                path="AddPayrollPeriodAutogen"
                element={<AddPayrollPeriodAutogen />}
            />
        </Route>
        <Route
            path="PayrollPeriodAutogenLog"
            element={<ViewPayrollPeriodAutogenLog />}
        >
            <Route
                path="AddPayrollPeriodAutogenLog"
                element={<AddPayrollPeriodAutogenLog />}
            />
        </Route>
        <Route
            path="PayrollPeriodTemplate"
            element={<ViewPayrollPeriodTemplate />}
        >
            <Route
                path="AddPayrollPeriodTemplate"
                element={<AddPayrollPeriodTemplate />}
            />
        </Route>
        <Route path="PayrollPostingLog" element={<ViewPayrollPostingLog />}>
            <Route
                path="AddPayrollPostingLog"
                element={<AddPayrollPostingLog />}
            />
        </Route>
        <Route
            path="PayrollProcessingLog"
            element={<ViewPayrollProcessingLog />}
        >
            <Route
                path="AddPayrollProcessingLog"
                element={<AddPayrollProcessingLog />}
            />
        </Route>
        <Route path="PayrollSummary" element={<ViewPayrollSummary />}>
            <Route path="AddPayrollSummary" element={<AddPayrollSummary />} />
        </Route>
        <Route
            path="PayrollSummaryHistory"
            element={<ViewPayrollSummaryHistory />}
        >
            <Route
                path="AddPayrollSummaryHistory"
                element={<AddPayrollSummaryHistory />}
            />
        </Route>
        <Route path="PayScaleHistoryLog" element={<ViewPayScaleHistoryLog />}>
            <Route
                path="AddPayScaleHistoryLog"
                element={<AddPayScaleHistoryLog />}
            />
        </Route>
        <Route path="SalaryAdjustment" element={<ViewSalaryAdjustment />}>
            <Route
                path="AddSalaryAdjustment"
                element={<AddSalaryAdjustment />}
            />
        </Route>
        <Route path="SalaryComponent" element={<ViewSalaryComponent />}>
            <Route path="AddSalaryComponent" element={<AddSalaryComponent />} />
        </Route>
        <Route
            path="SalaryComponentAccountMapping"
            element={<ViewSalaryComponentAccountMapping />}
        >
            <Route
                path="AddSalaryComponentAccountMapping"
                element={<AddSalaryComponentAccountMapping />}
            />
        </Route>

        {/* =========================================|| HCM RECRUITMENT ||============================================= */}

        <Route path="Vacancy" element={<ViewVacancy />}>
            <Route path="AddVacancy" element={<AddVacancy />} />
            <Route path=":VacancyId" element={<VacancyDetail />}>
                <Route
                    path="ExternalApplicant"
                    element={<ViewExternalApplicant />}
                >
                    <Route
                        path="AddExternalApplicant"
                        element={<AddExternalApplicant />}
                    />
                </Route>
                <Route path="VacancyResult" element={<ViewVacancyResult />}>
                    <Route
                        path="AddVacancyResult"
                        element={<AddVacancyResult />}
                    />
                </Route>
                <Route path="VacancyExaminer" element={<ViewVacancyExaminer />}>
                    <Route
                        path="AddVacancyExaminer"
                        element={<AddVacancyExaminer />}
                    />
                </Route>
                <Route
                    path="VacancyInternalApplicant"
                    element={<ViewVacancyInternalApplicant />}
                >
                    <Route
                        path="AddVacancyInternalApplicant"
                        element={<AddVacancyInternalApplicant />}
                    />
                </Route>
            </Route>
        </Route>

        <Route
            path="VacancyRequestReason"
            element={<ViewVacancyRequestReason />}
        >
            <Route
                path="AddVacancyRequestReason"
                element={<AddVacancyRequestReason />}
            />
        </Route>

        {/* ==========================================HCM TIME AND LEAVE=============================================== */}

        <Route path="AttendanceRelease" element={<AttendanceRelease />} />
        <Route path="AttendanceCreate" element={<AttendanceCreate />} />
        <Route
            path="AttendanceAbscenceType"
            element={<ViewAttendanceAbscenceType />}
        >
            <Route
                path="AddAttendanceAbscenceType"
                element={<AddAttendanceAbscenceType />}
            />
        </Route>
        <Route path="AttendanceCaptured" element={<ViewAttendanceCaptured />}>
            <Route
                path="AddAttendanceCaptured"
                element={<AddAttendanceCaptured />}
            />
        </Route>
        <Route path="AttendancePayroll" element={<ViewAttendancePayroll />}>
            <Route
                path="AddAttendancePayroll"
                element={<AddAttendancePayroll />}
            />
        </Route>
        <Route path="DeviceIdMapping" element={<ViewDeviceIdMapping />}>
            <Route path="AddDeviceIdMapping" element={<AddDeviceIdMapping />} />
        </Route>
        <Route path="Holiday" element={<ViewHoliday />}>
            <Route path="AddHoliday" element={<AddHoliday />} />
        </Route>
        <Route path="HolidayCharacter" element={<ViewHolidayCharacter />}>
            <Route
                path="AddHolidayCharacter"
                element={<AddHolidayCharacter />}
            />
        </Route>
        <Route path="LeavePeriod" element={<ViewLeavePeriod />}>
            <Route path="AddLeavePeriod" element={<AddLeavePeriod />} />
        </Route>
        <Route path="LeavePlan" element={<ViewLeavePlan />}>
            <Route path="AddLeavePlan" element={<AddLeavePlan />} />
        </Route>
        <Route path="Punch" element={<ViewPunch />}>
            <Route path="AddPunch" element={<AddPunch />} />
        </Route>
        <Route path="PunchDevice" element={<ViewPunchDevice />}>
            <Route path="AddPunchDevice" element={<AddPunchDevice />} />
        </Route>
        <Route path="PunchLog" element={<ViewPunchLog />}>
            <Route path="AddPunchLog" element={<AddPunchLog />} />
        </Route>
        <Route path="PunchManualImport" element={<ViewPunchManualImport />}>
            <Route
                path="AddPunchManualImport"
                element={<AddPunchManualImport />}
            />
        </Route>
        <Route path="PunchTime" element={<ViewPunchTime />}>
            <Route path="AddPunchTime" element={<AddPunchTime />} />
        </Route>
        <Route path="ShiftScheduleDtl" element={<ViewShiftScheduleDtl />}>
            <Route
                path="AddShiftScheduleDtl"
                element={<AddShiftScheduleDtl />}
            />
        </Route>
        <Route path="ShiftScheduleHdr" element={<ViewShiftScheduleHdr />}>
            <Route
                path="AddShiftScheduleHdr"
                element={<AddShiftScheduleHdr />}
            />
        </Route>
        <Route path="SubShiftGroup" element={<ViewSubShiftGroup />}>
            <Route path="AddSubShiftGroup" element={<AddSubShiftGroup />} />
        </Route>
    </Route>,
];
