import {
    Autocomplete,
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    TextField,
    Box,
    Stack,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { caller, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    JobCategoryURL,
    JobTitleURL,
    PaygradeURL,
    SafetyEquipmentURL,
    TrainingTypeURL,
} from "../../../../utils/config";

const AddJobTitle = () => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState("");
    const [filter2, setFilter2] = useState("");
    const [filter3, setFilter3] = useState("");
    const [filter4, setFilter4] = useState("");
    const [options, setOptions] = useState([]);
    const [optionsId, setOptionsID] = useState();
    const [optionSelect, setOptionSelect] = useState("");
    const [options2, setOptions2] = useState([]);
    const [optionsId2, setOptionsID2] = useState();
    const [optionSelect2, setOptionSelect2] = useState("");
    const [options3, setOptions3] = useState([]);
    const [optionsId3, setOptionsID3] = useState();
    const [optionSelect3, setOptionSelect3] = useState("");
    const [msg, setMsg] = useState("");
    const [options4, setOptions4] = useState([]);
    const [optionSelect4, setOptionSelect4] = useState([]);

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        title_name: "",
        job_specification: "",
        min_experience: "",
        paygrade_id: null,
        training_type_id: null,
        job_category_id: null,
    };

    const FORM_VALIDATION = Yup.object().shape({
        isProtectedForEdit: false,
        title_name: Yup.string().min(2).required("*Required"),
        job_specification: Yup.string(),
        min_experience: Yup.number(),
    });

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        caller(JobTitleURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                actions.resetForm();
                return 0;
            }
        });
    };

    useEffect(() => {
        get(JobCategoryURL, {
            limit: 10,
            filter: {
                category_description: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].category_description}`
                    );
                });
            res && setOptionsID(res.data);
            setOptions(data);
        });
        get(PaygradeURL, {
            limit: 10,
            filter: {
                paygrade_name: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].paygrade_name}`
                    );
                });
            res && setOptionsID2(res.data);
            setOptions2(data);
        });
        get(TrainingTypeURL, {
            limit: 10,
            filter: {
                training_type_name: filter3,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].training_type_name}`
                    );
                });
            res && setOptionsID3(res.data);
            setOptions3(data);
        });
        get(SafetyEquipmentURL, {
            limit: 10,
            filter: {
                all: filter4,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].name}`
                    );
                });
            setOptions4(data);
        });
    }, [filter, filter2, filter3, filter4]);

    return (
        <div>
            <TitleHeader title="Add Job Title" icon={<AddRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;

                        optionsId.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.category_description}` ===
                                optionSelect
                            ) {
                                values.job_category_id = name.id;
                            }
                        });
                        optionsId2.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.paygrade_name}` ===
                                optionSelect2
                            ) {
                                values.paygrade_id = name.id;
                            }
                        });
                        optionsId3.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.training_type_name}` ===
                                optionSelect3
                            ) {
                                values.training_type_id = name.id;
                            }
                        });
                        let safetyEquipments = [];
                        optionSelect4?.forEach((option, index) => {
                            let safety_equipment_id = parseInt(
                                option?.split("-")[0]
                            );
                            safetyEquipments.push(safety_equipment_id);
                        });

                        formSubmitHandler(
                            { jobTitle: values, safetyEquipments },
                            actions
                        );
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextFieldWrapper
                                    name="title_name"
                                    label="Title Name"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="min_experience"
                                    label="Min Experience"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="job_specification"
                                    label="Job Specification"
                                    variant="outlined"
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={6}>
                                <Autocomplete
                                    name="job_category_id"
                                    loading={options.length < 1 ? true : false}
                                    disablePortal
                                    options={options}
                                    onChange={(event, newValue) => {
                                        setOptionSelect(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Job Category"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    name="paygrade_id"
                                    loading={options2.length < 1 ? true : false}
                                    disablePortal
                                    options={options2}
                                    onChange={(event, newValue) => {
                                        setOptionSelect2(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Pay Grade"
                                            onChange={(event) => {
                                                setFilter2(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    name="training_type_id"
                                    loading={options3.length < 1 ? true : false}
                                    disablePortal
                                    options={options3}
                                    onChange={(event, newValue) => {
                                        setOptionSelect3(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Training Type"
                                            onChange={(event) => {
                                                setFilter3(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={3}>
                                    <Autocomplete
                                        multiple
                                        getOptionLabel={(option4) =>
                                            option4 || ""
                                        }
                                        filterSelectedOptions
                                        loading={
                                            options4.length < 1 ? true : false
                                        }
                                        options={options4}
                                        onChange={(event, newValue) => {
                                            setOptionSelect4(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Safety Equipment"
                                                onChange={(event) => {
                                                    setFilter4(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default AddJobTitle;
