import PropTypes from "prop-types";
import { IconButton, Paper, Typography } from "@mui/material";
import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const TitleHeader = (props) => {
    let backButton = props.backButton===undefined ? true : props.backButton;
    
    return (
        <Paper
            variant="elevation"
            style={{
                ...props.style,
                padding: "2ch",
                marginBottom: "2ch",
                display: "flex",
                flexDirection: "row",
                gap: "4ch",
                boxShadow: "0px 0px 10px #e6e6e6",
                borderRadius: "10px",
                alignItems: "center",
            }}
        >
            {backButton && !Boolean(props.title?.match(/Edit/gm)) && (
                <IconButton
                    onClick={() => {
                        window.history.back();
                    }}
                >
                    <ArrowBackIosIcon />
                </IconButton>
            )}

            {props.icon}
            <Typography {...props}>{props.title}</Typography>
        </Paper>
    );
};

TitleHeader.propTypes = {
  backButton: PropTypes.bool,
  icon: PropTypes.element, //*Icon to be displayed ReactIcons or Material UI 
  style: PropTypes.any,
  title: PropTypes.string.isRequired, //*Title
}

export default TitleHeader;
