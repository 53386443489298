//For any one working on this code i know its chika💩 don't judge😭

import React, { Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Backdrop, CircularProgress, Paper } from "@mui/material";
import AddProjectTask from "../../../Pages/Projects/AddProjectTask.page";
import TaskManager from "../../../Pages/Projects/Operational/TaskManager/TaskManager.page";
import ManpowerRequirement from "../../../Pages/Projects/Operational/ManpowerRequirement/ManpowerRequirement.page";
import RequiredEquipment from "../../../Pages/Projects/Operational/RequiredEquipment/RequiredEquipment.page";
import RequiredMaterial from "../../../Pages/Projects/Operational/RequiredMaterial/RequiredMaterial.page";
import ToDos from "../../../Pages/Projects/Operational/ToDos/ToDos.page";
import AddCalendar from "../../../Pages/HCM/EmployeeMaster/Calendar/AddCalendar.page";
import PayrollControl from "../../../Pages/HCM/PayrollControl.page";
import ViewQuote from "../../../Pages/Sales/ViewQuote.page";
import AddQuote from "../../../Pages/Sales/AddQuote.page";

const Dashboard = lazy(() =>
    import("../../../Pages/Dashboard/ProjectDashboard/Dashboard.page")
);
const AddProject = lazy(() =>
    import("../../../Pages/Projects/AddProject.Page")
);
const ViewProject = lazy(() =>
    import("../../../Pages/Projects/ViewProject.page")
);
const ProjectAddDetail = lazy(() =>
    import("../../../Pages/Projects/ProjectAddDetail.page")
);
const OperationalProjects = lazy(() =>
    import("../../../Pages/Projects/Operational/OperationalProjects.page")
);
const Material = lazy(() =>
    import("../../../Pages/MasterData/Material/Material.page")
);
const Equipment = lazy(() =>
    import("../../../Pages/MasterData/Equipment/Equipment.page")
);
const Evaluation = lazy(() =>
    import("../../../Pages/MasterData/Evaluation/Evaluation.page")
);
const Instruction = lazy(() =>
    import("../../../Pages/MasterData/Instruction/Instruction.page")
);
const Manpower = lazy(() =>
    import("../../../Pages/MasterData/Manpower/Manpower.page")
);
const MaterialCategory = lazy(() =>
    import("../../../Pages/MasterData/MaterialCategory/MaterialCategory.page")
);
const Phase = lazy(() => import("../../../Pages/MasterData/Phase/Phase.page"));
const WorkCategory = lazy(() =>
    import("../../../Pages/MasterData/WorkCategory/WorkCategory.page")
);
const Priority = lazy(() =>
    import("../../../Pages/MasterData/Priority/Priority.page")
);
const AddMaterial = lazy(() =>
    import("../../../Pages/MasterData/Material/AddMaterial.page")
);
const AddEquipment = lazy(() =>
    import("../../../Pages/MasterData/Equipment/AddEquipment.page")
);
const AddMaterialCategory = lazy(() =>
    import(
        "../../../Pages/MasterData/MaterialCategory/AddMaterialCategory.page"
    )
);
const AddEvaluation = lazy(() =>
    import("../../../Pages/MasterData/Evaluation/AddEvaluation.page")
);
const AddInstruction = lazy(() =>
    import("../../../Pages/MasterData/Instruction/AddInstruction.page")
);
const AddManpower = lazy(() =>
    import("../../../Pages/MasterData/Manpower/AddManpower.page")
);
const AddPhase = lazy(() =>
    import("../../../Pages/MasterData/Phase/AddPhase.page")
);
const AddWorkCategory = lazy(() =>
    import("../../../Pages/MasterData/WorkCategory/AddWorkCategory.page")
);
const AddPriority = lazy(() =>
    import("../../../Pages/MasterData/Priority/AddPriority.page")
);
const AddClient = lazy(() => import("../../../Pages/Client/AddClient.page"));
const ViewClient = lazy(() => import("../../../Pages/Client/ViewClient.Page"));
const Documentation = lazy(() =>
    import("../../../Pages/MasterData/Documentation/Documentation.page")
);
const AddDocumentation = lazy(() =>
    import("../../../Pages/MasterData/Documentation/AddDocumentation.page")
);
const AddDocumentCategory = lazy(() =>
    import(
        "../../../Pages/MasterData/DocumentCategory/AddDocumentCategory.page"
    )
);
const DocumentCategory = lazy(() =>
    import("../../../Pages/MasterData/DocumentCategory/DocumentCategory.page")
);
const Currency = lazy(() =>
    import("../../../Pages/HCM/MasterData/Currency/Currency.page")
);
const AddCurrency = lazy(() =>
    import("../../../Pages/HCM/MasterData/Currency/AddCurrency.page")
);
const Country = lazy(() =>
    import("../../../Pages/HCM/MasterData/Country/Country.page")
);
const AddCountry = lazy(() =>
    import("../../../Pages/HCM/MasterData/Country/AddCountry.page")
);
const Title = lazy(() =>
    import("../../../Pages/HCM/MasterData/Title/Title.page")
);
const AddTitle = lazy(() =>
    import("../../../Pages/HCM/MasterData/Title/AddTitle.page")
);
const Language = lazy(() =>
    import("../../../Pages/HCM/MasterData/Language/Language.page")
);
const AddLanguage = lazy(() =>
    import("../../../Pages/HCM/MasterData/Language/AddLanguage.page")
);
const ActionReason = lazy(() =>
    import("../../../Pages/HCM/MasterData/ActionReason/ActionReason.page")
);
const AddActionReason = lazy(() =>
    import("../../../Pages/HCM/MasterData/ActionReason/AddActionReason.page")
);
const ActionType = lazy(() =>
    import("../../../Pages/HCM/MasterData/ActionType/ActionType.page")
);
const AddActionType = lazy(() =>
    import("../../../Pages/HCM/MasterData/ActionType/AddActionType.page")
);
const AddressType = lazy(() =>
    import("../../../Pages/HCM/MasterData/AddressType/AddressType.page")
);
const AddAddressType = lazy(() =>
    import("../../../Pages/HCM/MasterData/AddressType/AddAddressType.page")
);
const Commitment = lazy(() =>
    import("../../../Pages/HCM/MasterData/Commitment/Commitment.page")
);
const AddCommitment = lazy(() =>
    import("../../../Pages/HCM/MasterData/Commitment/AddCommitment.page")
);
const CustomReport = lazy(() =>
    import("../../../Pages/HCM/MasterData/CustomReport/CustomReport.page")
);
const AddCustomReport = lazy(() =>
    import("../../../Pages/HCM/MasterData/CustomReport/AddCustomReport.page")
);
const DisciplineCaseType = lazy(() =>
    import(
        "../../../Pages/HCM/MasterData/DisciplineCaseType/DisciplineCaseType.page"
    )
);
const AddDisciplineCaseType = lazy(() =>
    import(
        "../../../Pages/HCM/MasterData/DisciplineCaseType/AddDisciplineCaseType.page"
    )
);
const DurationMeasure = lazy(() =>
    import("../../../Pages/HCM/MasterData/DurationMeasure/DurationMeasure.page")
);
const AddDurationMeasure = lazy(() =>
    import(
        "../../../Pages/HCM/MasterData/DurationMeasure/AddDurationMeasure.page"
    )
);
const EmployeeGroup = lazy(() =>
    import("../../../Pages/HCM/MasterData/EmployeeGroup/EmployeeGroup.page")
);
const AddEmployeeGroup = lazy(() =>
    import("../../../Pages/HCM/MasterData/EmployeeGroup/AddEmployeeGroup.page")
);
const EmployeeType = lazy(() =>
    import("../../../Pages/HCM/MasterData/EmployeeType/EmployeeType.page")
);
const AddEmployeeType = lazy(() =>
    import("../../../Pages/HCM/MasterData/EmployeeType/AddEmployeeType.page")
);
const FieldOfStudy = lazy(() =>
    import("../../../Pages/HCM/MasterData/FieldOfStudy/FieldOfStudy.page")
);
const AddFieldOfStudy = lazy(() =>
    import("../../../Pages/HCM/MasterData/FieldOfStudy/AddFieldOfStudy.page")
);
const HCMConfiguration = lazy(() =>
    import(
        "../../../Pages/HCM/MasterData/HCMConfiguration/HCMConfiguration.page"
    )
);
const AddHCMConfiguration = lazy(() =>
    import(
        "../../../Pages/HCM/MasterData/HCMConfiguration/AddHCMConfiguration.page"
    )
);
const EmployeeTransactionLock = lazy(() =>
    import(
        "../../../Pages/HCM/MasterData/EmployeeTransactionLock/EmployeeTransactionLock.page"
    )
);
const AddEmployeeTransactionLock = lazy(() =>
    import(
        "../../../Pages/HCM/MasterData/EmployeeTransactionLock/AddEmployeeTransactionLock.page"
    )
);
const LicenseType = lazy(() =>
    import("../../../Pages/HCM/MasterData/LicenseType/LicenseType.page")
);
const AddLicenseType = lazy(() =>
    import("../../../Pages/HCM/MasterData/LicenseType/AddLicenseType.page")
);
const Nationality = lazy(() =>
    import("../../../Pages/HCM/MasterData/Nationality/Nationality.page")
);
const AddNationality = lazy(() =>
    import("../../../Pages/HCM/MasterData/Nationality/AddNationality.page")
);
const Religion = lazy(() =>
    import("../../../Pages/HCM/MasterData/Religion/Religion.page")
);
const AddReligion = lazy(() =>
    import("../../../Pages/HCM/MasterData/Religion/AddReligion.page")
);
const TrainingType = lazy(() =>
    import("../../../Pages/HCM/MasterData/TrainingType/TrainingType.page")
);
const AddTrainingType = lazy(() =>
    import("../../../Pages/HCM/MasterData/TrainingType/AddTrainingType.page")
);
const JobCategory = lazy(() =>
    import("../../../Pages/HCM/JobPositions/JobCategory/JobCategory.page")
);
const AddJobCategory = lazy(() =>
    import("../../../Pages/HCM/JobPositions/JobCategory/AddJobCategory.page")
);
const JobSafetyEquipment = lazy(() =>
    import(
        "../../../Pages/HCM/JobPositions/JobSafetyEquipment/JobSafetyEquipment.page"
    )
);
const AddJobSafetyEquipment = lazy(() =>
    import(
        "../../../Pages/HCM/JobPositions/JobSafetyEquipment/AddJobSafetyEquipment.page"
    )
);
const AddJobTitle = lazy(() =>
    import("../../../Pages/HCM/JobPositions/JobTitle/AddJobTitle.page")
);
const JobTitle = lazy(() =>
    import("../../../Pages/HCM/JobPositions/JobTitle/JobTitle.page")
);
const AddSafetyEquipment = lazy(() =>
    import(
        "../../../Pages/HCM/JobPositions/SafetyEquipment/AddSafetyEquipment.page"
    )
);
const SafetyEquipment = lazy(() =>
    import(
        "../../../Pages/HCM/JobPositions/SafetyEquipment/SafetyEquipment.page"
    )
);
const Address = lazy(() =>
    import("../../../Pages/HCM/EmployeeMaster/Address/Address.page")
);
const AddAddress = lazy(() =>
    import("../../../Pages/HCM/EmployeeMaster/Address/AddAddress.page")
);
const Attachment = lazy(() =>
    import("../../../Pages/HCM/EmployeeMaster/Attachment/Attachment.page")
);
const AddAttachment = lazy(() =>
    import("../../../Pages/HCM/EmployeeMaster/Attachment/AddAttachment.page")
);
const Dependent = lazy(() =>
    import("../../../Pages/HCM/EmployeeMaster/Dependent/Dependent.page")
);
const AddDependent = lazy(() =>
    import("../../../Pages/HCM/EmployeeMaster/Dependent/AddDependent.page")
);
const Discipline = lazy(() =>
    import("../../../Pages/HCM/EmployeeMaster/Discipline/Discipline.page")
);
const AddDiscipline = lazy(() =>
    import("../../../Pages/HCM/EmployeeMaster/Discipline/AddDiscipline.page")
);
const Education = lazy(() =>
    import("../../../Pages/HCM/EmployeeMaster/Education/Education.page")
);
const AddEducation = lazy(() =>
    import("../../../Pages/HCM/EmployeeMaster/Education/AddEducation.page")
);
const Employee = lazy(() =>
    import("../../../Pages/HCM/EmployeeMaster/Employee/Employee.page")
);
const AddEmployee = lazy(() =>
    import("../../../Pages/HCM/EmployeeMaster/Employee/AddEmployee.page")
);
const EmployeeAction = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/EmployeeAction/EmployeeAction.page"
    )
);
const AddEmployeeAction = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/EmployeeAction/AddEmployeeAction.page"
    )
);
const EmployeeAttachment = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/EmployeeAttachment/EmployeeAttachment.page"
    )
);
const AddEmployeeAttachment = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/EmployeeAttachment/AddEmployeeAttachment.page"
    )
);
const EmployeeCommitment = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/EmployeeCommitment/EmployeeCommitment.page"
    )
);
const AddEmployeeCommitment = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/EmployeeCommitment/AddEmployeeCommitment.page"
    )
);
const EmployeeContact = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/EmployeeContact/EmployeeContact.page"
    )
);
const AddEmployeeContact = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/EmployeeContact/AddEmployeeContact.page"
    )
);
const EmployeeIdRange = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/EmployeeIdRange/EmployeeIdRange.page"
    )
);
const AddEmployeeIdRange = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/EmployeeIdRange/AddEmployeeIdRange.page"
    )
);
const EmployeeLoanRepayment = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/EmployeeLoanRepayment/EmployeeLoanRepayment.page"
    )
);
const AddEmployeeLoanRepayment = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/EmployeeLoanRepayment/AddEmployeeLoanRepayment.page"
    )
);
const EmployeePayFrequency = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/EmployeePayFrequency/EmployeePayFrequency.page"
    )
);
const AddEmployeePayFrequency = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/EmployeePayFrequency/AddEmployeePayFrequency.page"
    )
);
const EmployeePaygrade = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/EmployeePaygrade/EmployeePaygrade.page"
    )
);
const AddEmployeePaygrade = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/EmployeePaygrade/AddEmployeePaygrade.page"
    )
);
const EmployeeSalaryComponent = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/EmployeeSalaryComponent/EmployeeSalaryComponent.page"
    )
);
const AddEmployeeSalaryComponent = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/EmployeeSalaryComponent/AddEmployeeSalaryComponent.page"
    )
);
const Experience = lazy(() =>
    import("../../../Pages/HCM/EmployeeMaster/Experience/Experience.page")
);
const AddExperience = lazy(() =>
    import("../../../Pages/HCM/EmployeeMaster/Experience/AddExperience.page")
);
const LeaveAssignment = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/LeaveAssignment/LeaveAssignment.page"
    )
);
const AddLeaveAssignment = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/LeaveAssignment/AddLeaveAssignment.page"
    )
);
const LeaveEntitlement = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/LeaveEntitlement/LeaveEntitlement.page"
    )
);
const AddLeaveEntitlement = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/LeaveEntitlement/AddLeaveEntitlement.page"
    )
);
const LeaveTransfer = lazy(() =>
    import("../../../Pages/HCM/EmployeeMaster/LeaveTransfer/LeaveTransfer.page")
);
const AddLeaveTransfer = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/LeaveTransfer/AddLeaveTransfer.page"
    )
);
const License = lazy(() =>
    import("../../../Pages/HCM/EmployeeMaster/License/License.page")
);
const AddLicense = lazy(() =>
    import("../../../Pages/HCM/EmployeeMaster/License/AddLicense.page")
);
const OrgAssignment = lazy(() =>
    import("../../../Pages/HCM/EmployeeMaster/OrgAssignment/OrgAssignment.page")
);
const AddOrgAssignment = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/OrgAssignment/AddOrgAssignment.page"
    )
);
const ServiceAllowancePay = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/ServiceAllowancePay/ServiceAllowancePay.page"
    )
);
const AddServiceAllowancePay = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/ServiceAllowancePay/AddServiceAllowancePay.page"
    )
);
const ShiftAssignment = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/ShiftAssignment/ShiftAssignment.page"
    )
);
const AddShiftAssignment = lazy(() =>
    import(
        "../../../Pages/HCM/EmployeeMaster/ShiftAssignment/AddShiftAssignment.page"
    )
);
const BusinessUnit = lazy(() =>
    import("../../../Pages/HCM/CompanyStructure/BusinessUnit/BusinessUnit.page")
);
const AddBusinessUnit = lazy(() =>
    import(
        "../../../Pages/HCM/CompanyStructure/BusinessUnit/AddBusinessUnit.page"
    )
);
const Company = lazy(() =>
    import("../../../Pages/HCM/CompanyStructure/Company/Company.page")
);
const AddCompany = lazy(() =>
    import("../../../Pages/HCM/CompanyStructure/Company/AddCompany.page")
);
const CompanyPrimaryContact = lazy(() =>
    import(
        "../../../Pages/HCM/CompanyStructure/CompanyPrimaryContact/CompanyPrimaryContact.page"
    )
);
const AddCompanyPrimaryContact = lazy(() =>
    import(
        "../../../Pages/HCM/CompanyStructure/CompanyPrimaryContact/AddCompanyPrimaryContact.page"
    )
);
const Location = lazy(() =>
    import("../../../Pages/HCM/CompanyStructure/Location/Location.page")
);
const AddLocation = lazy(() =>
    import("../../../Pages/HCM/CompanyStructure/Location/AddLocation.page")
);
const EmployeeBackPenalityDeduction = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/EmployeeBackPenalityDeduction/EmployeeBackPenalityDeduction.page"
    )
);
const AddEmployeeBackPenalityDeduction = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/EmployeeBackPenalityDeduction/AddEmployeeBackPenalityDeduction.page"
    )
);
const EmployeePayScale = lazy(() =>
    import("../../../Pages/HCM/Payroll/EmployeePayScale/EmployeePayScale.page")
);
const AddEmployeePayScale = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/EmployeePayScale/AddEmployeePayScale.page"
    )
);
const EmployeePenality = lazy(() =>
    import("../../../Pages/HCM/Payroll/EmployeePenality/EmployeePenality.page")
);
const AddEmployeePenality = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/EmployeePenality/AddEmployeePenality.page"
    )
);
const EmployeePenalityDetailMst = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/EmployeePenalityDetailMst/EmployeePenalityDetailMst.page"
    )
);
const AddEmployeePenalityDetailMst = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/EmployeePenalityDetailMst/AddEmployeePenalityDetailMst.page"
    )
);
const EmployeePenalityType = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/EmployeePenalityType/EmployeePenalityType.page"
    )
);
const AddEmployeePenalityType = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/EmployeePenalityType/AddEmployeePenalityType.page"
    )
);
const EmployeeTax = lazy(() =>
    import("../../../Pages/HCM/Payroll/EmployeeTax/EmployeeTax.page")
);
const AddEmployeeTax = lazy(() =>
    import("../../../Pages/HCM/Payroll/EmployeeTax/AddEmployeeTax.page")
);
const GlobalPayrollAccountMapping = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/GlobalPayrollAccountMapping/GlobalPayrollAccountMapping.page"
    )
);
const AddGlobalPayrollAccountMapping = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/GlobalPayrollAccountMapping/AddGlobalPayrollAccountMapping.page"
    )
);
const Overtime = lazy(() =>
    import("../../../Pages/HCM/Payroll/Overtime/Overtime.page")
);
const AddOvertime = lazy(() =>
    import("../../../Pages/HCM/Payroll/Overtime/AddOvertime.page")
);
const OvertimeRate = lazy(() =>
    import("../../../Pages/HCM/Payroll/OvertimeRate/OvertimeRate.page")
);
const AddOvertimeRate = lazy(() =>
    import("../../../Pages/HCM/Payroll/OvertimeRate/AddOvertimeRate.page")
);
const Paygrade = lazy(() =>
    import("../../../Pages/HCM/Payroll/Paygrade/Paygrade.page")
);
const AddPaygrade = lazy(() =>
    import("../../../Pages/HCM/Payroll/Paygrade/AddPaygrade.page")
);
const PaygradeSalaryComponent = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/PaygradeSalaryComponent/PaygradeSalaryComponent.page"
    )
);
const AddPaygradeSalaryComponent = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/PaygradeSalaryComponent/AddPaygradeSalaryComponent.page"
    )
);
const PaygradeScale = lazy(() =>
    import("../../../Pages/HCM/Payroll/PaygradeScale/PaygradeScale.page")
);
const AddPaygradeScale = lazy(() =>
    import("../../../Pages/HCM/Payroll/PaygradeScale/AddPaygradeScale.page")
);
const PayrollDetail = lazy(() =>
    import("../../../Pages/HCM/Payroll/PayrollDetail/PayrollDetail.page")
);
const AddPayrollDetail = lazy(() =>
    import("../../../Pages/HCM/Payroll/PayrollDetail/AddPayrollDetail.page")
);
const PayrollDetailHistory = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/PayrollDetailHistory/PayrollDetailHistory.page"
    )
);
const AddPayrollDetailHistory = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/PayrollDetailHistory/AddPayrollDetailHistory.page"
    )
);
const PayrollFrequencyType = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/PayrollFrequencyType/PayrollFrequencyType.page"
    )
);
const AddPayrollFrequencyType = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/PayrollFrequencyType/AddPayrollFrequencyType.page"
    )
);
const PayrollLocationSetting = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/PayrollLocationSetting/PayrollLocationSetting.page"
    )
);
const AddPayrollLocationSetting = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/PayrollLocationSetting/AddPayrollLocationSetting.page"
    )
);
const PayrollLog = lazy(() =>
    import("../../../Pages/HCM/Payroll/PayrollLog/PayrollLog.page")
);
const AddPayrollLog = lazy(() =>
    import("../../../Pages/HCM/Payroll/PayrollLog/AddPayrollLog.page")
);
const PayrollLogEmployee = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/PayrollLogEmployee/PayrollLogEmployee.page"
    )
);
const AddPayrollLogEmployee = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/PayrollLogEmployee/AddPayrollLogEmployee.page"
    )
);
const PayrollPeriodAutogen = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/PayrollPeriodAutogen/PayrollPeriodAutogen.page"
    )
);
const AddPayrollPeriodAutogen = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/PayrollPeriodAutogen/AddPayrollPeriodAutogen.page"
    )
);
const PayrollPeriodAutogenLog = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/PayrollPeriodAutogenLog/PayrollPeriodAutogenLog.page"
    )
);
const AddPayrollPeriodAutogenLog = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/PayrollPeriodAutogenLog/AddPayrollPeriodAutogenLog.page"
    )
);
const PayrollPeriodTemplate = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/PayrollPeriodTemplate/PayrollPeriodTemplate.page"
    )
);
const AddPayrollPeriodTemplate = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/PayrollPeriodTemplate/AddPayrollPeriodTemplate.page"
    )
);
const PayrollPostingLog = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/PayrollPostingLog/PayrollPostingLog.page"
    )
);
const AddPayrollPostingLog = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/PayrollPostingLog/AddPayrollPostingLog.page"
    )
);
const PayrollProcessingLog = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/PayrollProcessingLog/PayrollProcessingLog.page"
    )
);
const AddPayrollProcessingLog = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/PayrollProcessingLog/AddPayrollProcessingLog.page"
    )
);
const PayrollSummary = lazy(() =>
    import("../../../Pages/HCM/Payroll/PayrollSummary/PayrollSummary.page")
);
const AddPayrollSummary = lazy(() =>
    import("../../../Pages/HCM/Payroll/PayrollSummary/AddPayrollSummary.page")
);
const PayrollSummaryHistory = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/PayrollSummaryHistory/PayrollSummaryHistory.page"
    )
);
const AddPayrollSummaryHistory = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/PayrollSummaryHistory/AddPayrollSummaryHistory.page"
    )
);
const PayScaleHistoryLog = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/PayScaleHistoryLog/PayScaleHistoryLog.page"
    )
);
const AddPayScaleHistoryLog = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/PayScaleHistoryLog/AddPayScaleHistoryLog.page"
    )
);
const SalaryAdjustment = lazy(() =>
    import("../../../Pages/HCM/Payroll/SalaryAdjustment/SalaryAdjustment.page")
);
const AddSalaryAdjustment = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/SalaryAdjustment/AddSalaryAdjustment.page"
    )
);
const SalaryComponent = lazy(() =>
    import("../../../Pages/HCM/Payroll/SalaryComponent/SalaryComponent.page")
);
const AddSalaryComponent = lazy(() =>
    import("../../../Pages/HCM/Payroll/SalaryComponent/AddSalaryComponent.page")
);
const SalaryComponentAccountMapping = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/SalaryComponentAccountMapping/SalaryComponentAccountMapping.page"
    )
);
const AddSalaryComponentAccountMapping = lazy(() =>
    import(
        "../../../Pages/HCM/Payroll/SalaryComponentAccountMapping/AddSalaryComponentAccountMapping.page"
    )
);
const ExternalApplicant = lazy(() =>
    import(
        "../../../Pages/HCM/Recruitment/ExternalApplicant/ExternalApplicant.page"
    )
);
const AddExternalApplicant = lazy(() =>
    import(
        "../../../Pages/HCM/Recruitment/ExternalApplicant/AddExternalApplicant.page"
    )
);
const Vacancy = lazy(() =>
    import("../../../Pages/HCM/Recruitment/Vacancy/Vacancy.page")
);
const AddVacancy = lazy(() =>
    import("../../../Pages/HCM/Recruitment/Vacancy/AddVacancy.page")
);
const VacancyResult = lazy(() =>
    import("../../../Pages/HCM/Recruitment/VacancyResult/VacancyResult.page")
);
const AddVacancyResult = lazy(() =>
    import("../../../Pages/HCM/Recruitment/VacancyResult/AddVacancyResult.page")
);
const VacancyExaminer = lazy(() =>
    import(
        "../../../Pages/HCM/Recruitment/VacancyExaminer/VacancyExaminer.page"
    )
);
const AddVacancyExaminer = lazy(() =>
    import(
        "../../../Pages/HCM/Recruitment/VacancyExaminer/AddVacancyExaminer.page"
    )
);
const VacancyInternalApplicant = lazy(() =>
    import(
        "../../../Pages/HCM/Recruitment/VacancyInternalApplicant/VacancyInternalApplicant.page"
    )
);
const AddVacancyInternalApplicant = lazy(() =>
    import(
        "../../../Pages/HCM/Recruitment/VacancyInternalApplicant/AddVacancyInternalApplicant.page"
    )
);
const VacancyRequestReason = lazy(() =>
    import(
        "../../../Pages/HCM/Recruitment/VacancyRequestReason/VacancyRequestReason.page"
    )
);
const AddVacancyRequestReason = lazy(() =>
    import(
        "../../../Pages/HCM/Recruitment/VacancyRequestReason/AddVacancyRequestReason.page"
    )
);
const AttendanceAbscenceType = lazy(() =>
    import(
        "../../../Pages/HCM/TimeAndLeave/AttendanceAbscenceType/AttendanceAbscenceType.page"
    )
);
const AddAttendanceAbscenceType = lazy(() =>
    import(
        "../../../Pages/HCM/TimeAndLeave/AttendanceAbscenceType/AddAttendanceAbscenceType.page"
    )
);
const AttendanceCaptured = lazy(() =>
    import(
        "../../../Pages/HCM/TimeAndLeave/AttendanceCaptured/AttendanceCaptured.page"
    )
);
const AddAttendanceCaptured = lazy(() =>
    import(
        "../../../Pages/HCM/TimeAndLeave/AttendanceCaptured/AddAttendanceCaptured.page"
    )
);
const AttendancePayroll = lazy(() =>
    import(
        "../../../Pages/HCM/TimeAndLeave/AttendancePayroll/AttendancePayroll.page"
    )
);
const AddAttendancePayroll = lazy(() =>
    import(
        "../../../Pages/HCM/TimeAndLeave/AttendancePayroll/AddAttendancePayroll.page"
    )
);
const DeviceIdMapping = lazy(() =>
    import(
        "../../../Pages/HCM/TimeAndLeave/DeviceIdMapping/DeviceIdMapping.page"
    )
);
const AddDeviceIdMapping = lazy(() =>
    import(
        "../../../Pages/HCM/TimeAndLeave/DeviceIdMapping/AddDeviceIdMapping.page"
    )
);
const Holiday = lazy(() =>
    import("../../../Pages/HCM/TimeAndLeave/Holiday/Holiday.page")
);
const AddHoliday = lazy(() =>
    import("../../../Pages/HCM/TimeAndLeave/Holiday/AddHoliday.page")
);
const HolidayCharacter = lazy(() =>
    import(
        "../../../Pages/HCM/TimeAndLeave/HolidayCharacter/HolidayCharacter.page"
    )
);
const AddHolidayCharacter = lazy(() =>
    import(
        "../../../Pages/HCM/TimeAndLeave/HolidayCharacter/AddHolidayCharacter.page"
    )
);
const LeavePeriod = lazy(() =>
    import("../../../Pages/HCM/TimeAndLeave/LeavePeriod/LeavePeriod.page")
);
const AddLeavePeriod = lazy(() =>
    import("../../../Pages/HCM/TimeAndLeave/LeavePeriod/AddLeavePeriod.page")
);
const LeavePlan = lazy(() =>
    import("../../../Pages/HCM/TimeAndLeave/LeavePlan/LeavePlan.page")
);
const AddLeavePlan = lazy(() =>
    import("../../../Pages/HCM/TimeAndLeave/LeavePlan/AddLeavePlan.page")
);
const Punch = lazy(() =>
    import("../../../Pages/HCM/TimeAndLeave/Punch/Punch.page")
);
const AddPunch = lazy(() =>
    import("../../../Pages/HCM/TimeAndLeave/Punch/AddPunch.page")
);
const PunchDevice = lazy(() =>
    import("../../../Pages/HCM/TimeAndLeave/PunchDevice/PunchDevice.page")
);
const AddPunchDevice = lazy(() =>
    import("../../../Pages/HCM/TimeAndLeave/PunchDevice/AddPunchDevice.page")
);
const PunchLog = lazy(() =>
    import("../../../Pages/HCM/TimeAndLeave/PunchLog/PunchLog.page")
);
const AddPunchLog = lazy(() =>
    import("../../../Pages/HCM/TimeAndLeave/PunchLog/AddPunchLog.page")
);
const PunchManualImport = lazy(() =>
    import(
        "../../../Pages/HCM/TimeAndLeave/PunchManualImport/PunchManualImport.page"
    )
);
const AddPunchManualImport = lazy(() =>
    import(
        "../../../Pages/HCM/TimeAndLeave/PunchManualImport/AddPunchManualImport.page"
    )
);
const PunchTime = lazy(() =>
    import("../../../Pages/HCM/TimeAndLeave/PunchTime/PunchTime.page")
);
const AddPunchTime = lazy(() =>
    import("../../../Pages/HCM/TimeAndLeave/PunchTime/AddPunchTime.page")
);
const ShiftScheduleDtl = lazy(() =>
    import(
        "../../../Pages/HCM/TimeAndLeave/ShiftScheduleDtl/ShiftScheduleDtl.page"
    )
);
const AddShiftScheduleDtl = lazy(() =>
    import(
        "../../../Pages/HCM/TimeAndLeave/ShiftScheduleDtl/AddShiftScheduleDtl.page"
    )
);
const ShiftScheduleHdr = lazy(() =>
    import(
        "../../../Pages/HCM/TimeAndLeave/ShiftScheduleHdr/ShiftScheduleHdr.page"
    )
);
const AddShiftScheduleHdr = lazy(() =>
    import(
        "../../../Pages/HCM/TimeAndLeave/ShiftScheduleHdr/AddShiftScheduleHdr.page"
    )
);
const SubShiftGroup = lazy(() =>
    import("../../../Pages/HCM/TimeAndLeave/SubShiftGroup/SubShiftGroup.page")
);
const AddSubShiftGroup = lazy(() =>
    import(
        "../../../Pages/HCM/TimeAndLeave/SubShiftGroup/AddSubShiftGroup.page"
    )
);
const UnitOfMeasure = lazy(() =>
    import(
        "../../../Pages/Inventory/InventoryMaster/UnitOfMeasure/UnitOfMeasure.page"
    )
);
const AddUnitOfMeasure = lazy(() =>
    import(
        "../../../Pages/Inventory/InventoryMaster/UnitOfMeasure/AddUnitOfMeasure.page"
    )
);
const Warehouse = lazy(() =>
    import("../../../Pages/Inventory/InventoryMaster/Warehouse/Warehouse.page")
);
const AddWarehouse = lazy(() =>
    import(
        "../../../Pages/Inventory/InventoryMaster/Warehouse/AddWarehouse.page"
    )
);
const StockBatch = lazy(() =>
    import("../../../Pages/Inventory/InventoryStock/StockBatch/StockBatch.page")
);
const AddStockBatch = lazy(() =>
    import(
        "../../../Pages/Inventory/InventoryStock/StockBatch/AddStockBatch.page"
    )
);
const StockItem = lazy(() =>
    import("../../../Pages/Inventory/InventoryStock/StockItem/StockItem.page")
);
const AddStockItem = lazy(() =>
    import(
        "../../../Pages/Inventory/InventoryStock/StockItem/AddStockItem.page"
    )
);
const StockTransactionDetail = lazy(() =>
    import(
        "../../../Pages/Inventory/InventoryStock/StockTransactionDetail/StockTransactionDetail.page"
    )
);
const AddStockTransactionDetail = lazy(() =>
    import(
        "../../../Pages/Inventory/InventoryStock/StockTransactionDetail/AddStockTransactionDetail.page"
    )
);
const StockTransactionHeader = lazy(() =>
    import(
        "../../../Pages/Inventory/InventoryStock/StockTransactionHeader/StockTransactionHeader.page"
    )
);
const AddStockTransactionHeader = lazy(() =>
    import(
        "../../../Pages/Inventory/InventoryStock/StockTransactionHeader/AddStockTransactionHeader.page"
    )
);
const AccountCategory = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/AccountCategory/AccountCategory.page"
    )
);
const AddAccountCategory = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/AccountCategory/AddAccountCategory.page"
    )
);
const AccountType = lazy(() =>
    import("../../../Pages/Finance/FinanceMaster/AccountType/AccountType.page")
);
const AddAccountType = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/AccountType/AddAccountType.page"
    )
);
const ClosingNote = lazy(() =>
    import("../../../Pages/Finance/FinanceMaster/ClosingNote/ClosingNote.page")
);
const AddClosingNote = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/ClosingNote/AddClosingNote.page"
    )
);
const FinancialStatementSection = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/FinancialStatementSection/FinancialStatementSection.page"
    )
);
const AddFinancialStatementSection = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/FinancialStatementSection/AddFinancialStatementSection.page"
    )
);
const Bank = lazy(() =>
    import("../../../Pages/Finance/FinanceMaster/Bank/Bank.page")
);
const AddBank = lazy(() =>
    import("../../../Pages/Finance/FinanceMaster/Bank/AddBank.page")
);
const Contact = lazy(() =>
    import("../../../Pages/Finance/FinanceMaster/Contact/Contact.page")
);
const AddContact = lazy(() =>
    import("../../../Pages/Finance/FinanceMaster/Contact/AddContact.page")
);
const ContactAddress = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/ContactAddress/ContactAddress.page"
    )
);
const AddContactAddress = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/ContactAddress/AddContactAddress.page"
    )
);
const ContactPerson = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/ContactPerson/ContactPerson.page"
    )
);
const AddContactPerson = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/ContactPerson/AddContactPerson.page"
    )
);
const CostCenter = lazy(() =>
    import("../../../Pages/Finance/FinanceMaster/CostCenter/CostCenter.page")
);
const AddCostCenter = lazy(() =>
    import("../../../Pages/Finance/FinanceMaster/CostCenter/AddCostCenter.page")
);
const CostCenterAccounts = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/CostCenterAccounts/CostCenterAccounts.page"
    )
);
const AddCostCenterAccounts = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/CostCenterAccounts/AddCostCenterAccounts.page"
    )
);
const PaymentTerm = lazy(() =>
    import("../../../Pages/Finance/FinanceMaster/PaymentTerm/PaymentTerm.page")
);
const AddPaymentTerm = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/PaymentTerm/AddPaymentTerm.page"
    )
);
const ExchangeRate = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/ExchangeRate/ExchangeRate.page"
    )
);
const AddExchangeRate = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/ExchangeRate/AddExchangeRate.page"
    )
);
const CompanyAddress = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/CompanyAddress/CompanyAddress.page"
    )
);
const AddCompanyAddress = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/CompanyAddress/AddCompanyAddress.page"
    )
);
const DateFormatType = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/DateFormatType/DateFormatType.page"
    )
);
const AddDateFormatType = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/DateFormatType/AddDateFormatType.page"
    )
);
const FinancialSettings = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/FinancialSettings/FinancialSettings.page"
    )
);
const AddFinancialSettings = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/FinancialSettings/AddFinancialSettings.page"
    )
);
const Industry = lazy(() =>
    import("../../../Pages/Finance/FinanceMaster/Industry/Industry.page")
);
const AddIndustry = lazy(() =>
    import("../../../Pages/Finance/FinanceMaster/Industry/AddIndustry.page")
);
const JournalUsers = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/JournalUsers/JournalUsers.page"
    )
);
const AddJournalUsers = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/JournalUsers/AddJournalUsers.page"
    )
);
const PrimaryContact = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/PrimaryContact/PrimaryContact.page"
    )
);
const AddPrimaryContact = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/PrimaryContact/AddPrimaryContact.page"
    )
);
const FootNote = lazy(() =>
    import("../../../Pages/Finance/FinanceMaster/FootNote/FootNote.page")
);
const AddFootNote = lazy(() =>
    import("../../../Pages/Finance/FinanceMaster/FootNote/AddFootNote.page")
);
const AssociatedTaxGroup = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/AssociatedTaxGroup/AssociatedTaxGroup.page"
    )
);
const AddAssociatedTaxGroup = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/AssociatedTaxGroup/AddAssociatedTaxGroup.page"
    )
);
const Tax = lazy(() =>
    import("../../../Pages/Finance/FinanceMaster/Tax/Tax.page")
);
const AddTax = lazy(() =>
    import("../../../Pages/Finance/FinanceMaster/Tax/AddTax.page")
);
const TaxAuthority = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/TaxAuthority/TaxAuthority.page"
    )
);
const AddTaxAuthority = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/TaxAuthority/AddTaxAuthority.page"
    )
);
const TaxExemption = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/TaxExemption/TaxExemption.page"
    )
);
const AddTaxExemption = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/TaxExemption/AddTaxExemption.page"
    )
);
const TaxGroup = lazy(() =>
    import("../../../Pages/Finance/FinanceMaster/TaxGroup/TaxGroup.page")
);
const AddTaxGroup = lazy(() =>
    import("../../../Pages/Finance/FinanceMaster/TaxGroup/AddTaxGroup.page")
);
const TaxRule = lazy(() =>
    import("../../../Pages/Finance/FinanceMaster/TaxRule/TaxRule.page")
);
const AddTaxRule = lazy(() =>
    import("../../../Pages/Finance/FinanceMaster/TaxRule/AddTaxRule.page")
);
const AccountingPeriod = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/AccountingPeriod/AccountingPeriod.page"
    )
);
const AddAccountingPeriod = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/AccountingPeriod/AddAccountingPeriod.page"
    )
);
const JournalType = lazy(() =>
    import("../../../Pages/Finance/FinanceMaster/JournalType/JournalType.page")
);
const AddJournalType = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceMaster/JournalType/AddJournalType.page"
    )
);
const ChartOfAccount = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/ChartOfAccount/ChartOfAccount.page"
    )
);
const AddChartOfAccount = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/ChartOfAccount/AddChartOfAccount.page"
    )
);
const AccountTypeFinancialStatementSection = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/AccountTypeFinancialStatementSection/AccountTypeFinancialStatementSection.page"
    )
);
const AddAccountTypeFinancialStatementSection = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/AccountTypeFinancialStatementSection/AddAccountTypeFinancialStatementSection.page"
    )
);
const BankReconcilation = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/BankReconcilation/BankReconcilation.page"
    )
);
const AddBankReconcilation = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/BankReconcilation/AddBankReconcilation.page"
    )
);
const ChartOfAccountFiles = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/ChartOfAccountFiles/ChartOfAccountFiles.page"
    )
);
const AddChartOfAccountFiles = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/ChartOfAccountFiles/AddChartOfAccountFiles.page"
    )
);
const ReconcilationTransaction = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/ReconcilationTransaction/ReconcilationTransaction.page"
    )
);
const AddReconcilationTransaction = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/ReconcilationTransaction/AddReconcilationTransaction.page"
    )
);
const Asset = lazy(() =>
    import("../../../Pages/Finance/FinanceOperational/Asset/Asset.page")
);
const AddAsset = lazy(() =>
    import("../../../Pages/Finance/FinanceOperational/Asset/AddAsset.page")
);
const EstimatedTotalProductionUnit = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/EstimatedTotalProductionUnit/EstimatedTotalProductionUnit.page"
    )
);
const AddEstimatedTotalProductionUnit = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/EstimatedTotalProductionUnit/AddEstimatedTotalProductionUnit.page"
    )
);
const RecurringJournalOccurrence = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/RecurringJournalOccurrence/RecurringJournalOccurrence.page"
    )
);
const AddRecurringJournalOccurrence = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/RecurringJournalOccurrence/AddRecurringJournalOccurrence.page"
    )
);
const Budget = lazy(() =>
    import("../../../Pages/Finance/FinanceOperational/Budget/Budget.page")
);
const AddBudget = lazy(() =>
    import("../../../Pages/Finance/FinanceOperational/Budget/AddBudget.page")
);
const BudgetAccount = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/BudgetAccount/BudgetAccount.page"
    )
);
const AddBudgetAccount = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/BudgetAccount/AddBudgetAccount.page"
    )
);
const BudgetAccountPeriod = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/BudgetAccountPeriod/BudgetAccountPeriod.page"
    )
);
const AddBudgetAccountPeriod = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/BudgetAccountPeriod/AddBudgetAccountPeriod.page"
    )
);
const BudgetControlAction = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/BudgetControlAction/BudgetControlAction.page"
    )
);
const AddBudgetControlAction = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/BudgetControlAction/AddBudgetControlAction.page"
    )
);
const GeneralJournalDetail = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/GeneralJournalDetail/GeneralJournalDetail.page"
    )
);
const AddGeneralJournalDetail = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/GeneralJournalDetail/AddGeneralJournalDetail.page"
    )
);
const GeneralJournalFiles = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/GeneralJournalFiles/GeneralJournalFiles.page"
    )
);
const AddGeneralJournalFiles = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/GeneralJournalFiles/AddGeneralJournalFiles.page"
    )
);
const GeneralJournalHeader = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/GeneralJournalHeader/GeneralJournalHeader.page"
    )
);
const AddGeneralJournalHeader = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/GeneralJournalHeader/AddGeneralJournalHeader.page"
    )
);
const GeneralLedger = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/GeneralLedger/GeneralLedger.page"
    )
);
const AddGeneralLedger = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/GeneralLedger/AddGeneralLedger.page"
    )
);
const JournalComment = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/JournalComment/JournalComment.page"
    )
);
const AddJournalComment = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/JournalComment/AddJournalComment.page"
    )
);
const NumberTracker = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/NumberTracker/NumberTracker.page"
    )
);
const AddNumberTracker = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/NumberTracker/AddNumberTracker.page"
    )
);
const RecurringGeneralJournal = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/RecurringGeneralJournal/RecurringGeneralJournal.page"
    )
);
const AddRecurringGeneralJournal = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/RecurringGeneralJournal/AddRecurringGeneralJournal.page"
    )
);
const TransactionLock = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/TransactionLock/TransactionLock.page"
    )
);
const AddTransactionLock = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/TransactionLock/AddTransactionLock.page"
    )
);
const OpeningBalance = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/OpeningBalance/OpeningBalance.page"
    )
);
const AddOpeningBalance = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/OpeningBalance/AddOpeningBalance.page"
    )
);
const EmployeeDetail = lazy(() =>
    import("../../../Pages/HCM/EmployeeMaster/EmployeeDetail.page")
);
const HCMApproval = lazy(() => import("../../../Pages/HCM/HCMApproval.page"));
const PayrollSlip = lazy(() =>
    import("../../../Pages/HCM/Payroll/PayrollSlip.page")
);
const VacancyDetail = lazy(() =>
    import("../../../Pages/HCM/Recruitment/Vacancy/VacancyDetail.page")
);
const AttendanceRelease = lazy(() =>
    import(
        "../../../Pages/HCM/TimeAndLeave/AttendanceSheet/AttendanceRelease.page"
    )
);
const AttendanceCreate = lazy(() =>
    import(
        "../../../Pages/HCM/TimeAndLeave/AttendanceSheet/AttendanceCreate.page"
    )
);
const CashPaymentCustom = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/CashPaymentCustom/CashPaymentCustom.page"
    )
);
const AddCashPaymentCustom = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/CashPaymentCustom/AddCashPaymentCustom.page"
    )
);
const CashPaymentVoucher = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/CashPaymentVoucher/CashPaymentVoucher.page"
    )
);
const AddCashPaymentVoucher = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/CashPaymentVoucher/AddCashPaymentVoucher.page"
    )
);
const CpvBank = lazy(() =>
    import("../../../Pages/Finance/FinanceOperational/CpvBank/CpvBank.page")
);
const AddCpvBank = lazy(() =>
    import("../../../Pages/Finance/FinanceOperational/CpvBank/AddCpvBank.page")
);
const CpvPaymentRequest = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/CpvPaymentRequest/CpvPaymentRequest.page"
    )
);
const AddCpvPaymentRequest = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/CpvPaymentRequest/AddCpvPaymentRequest.page"
    )
);
const CpvReceipt = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/CpvReceipt/CpvReceipt.page"
    )
);
const AddCpvReceipt = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/CpvReceipt/AddCpvReceipt.page"
    )
);
const CpvType = lazy(() =>
    import("../../../Pages/Finance/FinanceOperational/CpvType/CpvType.page")
);
const AddCpvType = lazy(() =>
    import("../../../Pages/Finance/FinanceOperational/CpvType/AddCpvType.page")
);
const PettyCash = lazy(() =>
    import("../../../Pages/Finance/FinanceOperational/PettyCash/PettyCash.page")
);
const AddPettyCash = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/PettyCash/AddPettyCash.page"
    )
);
const CrvPayment = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/CrvPayment/CrvPayment.page"
    )
);
const AddCrvPayment = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/CrvPayment/AddCrvPayment.page"
    )
);
const CrvType = lazy(() =>
    import("../../../Pages/Finance/FinanceOperational/CrvType/CrvType.page")
);
const AddCrvType = lazy(() =>
    import("../../../Pages/Finance/FinanceOperational/CrvType/AddCrvType.page")
);
const PVRequest = lazy(() =>
    import("../../../Pages/Finance/FinanceOperational/PVRequest/PVRequest.page")
);
const AddPVRequest = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/PVRequest/AddPVRequest.page"
    )
);
const AccountantExport = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/Accountant Export/AccountantExport.page"
    )
);
const BusinessOverview = lazy(() =>
    import(
        "../../../Pages/Finance/FinanceOperational/BusinessOverview/BusinessOverview.page"
    )
);
const FinanceApproval = lazy(() =>
    import("../../../Pages/Finance/FinanceApproval.page")
);
const User = lazy(() => import("../../../Pages/Account/User/User.page"));
const AddUser = lazy(() => import("../../../Pages/Account/User/AddUser.page"));
const Role = lazy(() => import("../../../Pages/Account/Role/Role.page"));
const AddRole = lazy(() => import("../../../Pages/Account/Role/AddRole.page"));
const Privilege = lazy(() =>
    import("../../../Pages/Account/Privilege/Privilege.page")
);
const AddPrivilege = lazy(() =>
    import("../../../Pages/Account/Privilege/AddPrivilege.page")
);

const Body = () => {
    const location = useLocation();
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    return (
        <Paper className="Body">
            <Suspense
                fallback={
                    <Backdrop
                        sx={{ color: "white", zIndex: "1000" }}
                        open={true}
                    >
                        <CircularProgress />
                    </Backdrop>
                }
            >
                {location.pathname === "/View/Dashboard" && <Dashboard />}
                {location.pathname === "/View/ViewProject" && <ViewProject />}
                {location.pathname === "/View/AddProject" && <AddProject />}
                {location.pathname === "/View/AddClient" && <AddClient />}
                {location.pathname === "/View/ViewClient" && <ViewClient />}
                {location.pathname === "/View/ViewProject/ProjectAddDetail" && (
                    <ProjectAddDetail />
                )}
                {location.pathname ===
                    "/View/ViewProject/ProjectAddDetail/AddProjectTask" && (
                    <>
                        <AddProjectTask />
                    </>
                )}
                {location.pathname ===
                    "/View/ViewProject/ProjectAddDetail/AddProjectTask/TaskManager" && (
                    <TaskManager />
                )}
                {location.pathname ===
                    "/View/ViewProject/ProjectAddDetail/AddProjectTask/ManpowerRequirement" && (
                    <ManpowerRequirement />
                )}
                {location.pathname ===
                    "/View/ViewProject/ProjectAddDetail/AddProjectTask/RequiredEquipment" && (
                    <RequiredEquipment />
                )}
                {location.pathname ===
                    "/View/ViewProject/ProjectAddDetail/AddProjectTask/RequiredMaterial" && (
                    <RequiredMaterial />
                )}
                {location.pathname ===
                    "/View/ViewProject/ProjectAddDetail/AddProjectTask/ToDos" && (
                    <ToDos />
                )}

                {/* -----------------------PROJECT OPERATIONS-------------------------- */}

                {selectedRow &&
                    location.pathname.match(
                        /\/View\/ViewProject\/[0-9]+\/.*/
                    ) && <OperationalProjects />}

                {/* {selectedRow &&
                    location.pathname ===
                        `/View/ViewProject/${selectedRow.id}` && (
                        <OperationalProjects />
                    )} */}
                {/* =======User====== */}

                {location.pathname === "/View/Account/User" && <User />}
                {location.pathname === "/View/Account/User/AddUser" && (
                    <AddUser />
                )}
                {/* =======Role====== */}

                {location.pathname === "/View/Account/Role" && <Role />}
                {location.pathname === "/View/Account/Role/AddRole" && (
                    <AddRole />
                )}
                {/* =======Privilege====== */}

                {location.pathname === "/View/Account/Privilege" && (
                    <Privilege />
                )}
                {location.pathname ===
                    "/View/Account/Privilege/AddPrivilege" && <AddPrivilege />}

                {/* -----------------------PROJECT MASTER DATA-------------------------- */}

                {/* =======MATERIAL====== */}

                {location.pathname === "/View/Material" && <Material />}
                {location.pathname === "/View/Material/AddMaterial" && (
                    <AddMaterial />
                )}

                {/* ======Equipment====== */}

                {location.pathname === "/View/Equipment" && <Equipment />}
                {location.pathname === "/View/Equipment/AddEquipment" && (
                    <AddEquipment />
                )}

                {/* ======Evaluation====== */}

                {location.pathname === "/View/Evaluation" && <Evaluation />}
                {location.pathname === "/View/Evaluation/AddEvaluation" && (
                    <AddEvaluation />
                )}

                {/* ======Instruction====== */}

                {location.pathname === "/View/Instruction" && <Instruction />}
                {location.pathname === "/View/Instruction/AddInstruction" && (
                    <AddInstruction />
                )}

                {/* ======Manpower====== */}

                {location.pathname === "/View/Manpower" && <Manpower />}
                {location.pathname === "/View/Manpower/AddManpower" && (
                    <AddManpower />
                )}

                {/* ======MaterialCategory====== */}

                {location.pathname === "/View/MaterialCategory" && (
                    <MaterialCategory />
                )}
                {location.pathname ===
                    "/View/MaterialCategory/AddMaterialCategory" && (
                    <AddMaterialCategory />
                )}

                {/* ======Phase====== */}

                {location.pathname === "/View/Phase" && <Phase />}
                {location.pathname === "/View/Phase/AddPhase" && <AddPhase />}

                {/* ======WorkCategory====== */}

                {location.pathname === "/View/WorkCategory" && <WorkCategory />}
                {location.pathname === "/View/WorkCategory/AddWorkCategory" && (
                    <AddWorkCategory />
                )}

                {/* ======Priority====== */}

                {location.pathname === "/View/Priority" && <Priority />}
                {location.pathname === "/View/Priority/AddPriority" && (
                    <AddPriority />
                )}

                {/* ======Documentation====== */}

                {location.pathname === "/View/Documentation" && (
                    <Documentation />
                )}
                {location.pathname ===
                    "/View/Documentation/AddDocumentation" && (
                    <AddDocumentation />
                )}

                {/* ======Document Category====== */}

                {location.pathname === "/View/DocumentCategory" && (
                    <DocumentCategory />
                )}
                {location.pathname ===
                    "/View/DocumentCategory/AddDocumentCategory" && (
                    <AddDocumentCategory />
                )}

                {/* ---------------------------HCM MASTER------------------------------------ */}

                {location.pathname === "/View/HCM/Approval" && <HCMApproval />}
                {location.pathname === "/View/HCM/PayrollControl" && (
                    <PayrollControl />
                )}

                {/* =======Currency====== */}

                {location.pathname === "/View/HCM/Currency" && <Currency />}
                {location.pathname === "/View/HCM/Currency/AddCurrency" && (
                    <AddCurrency />
                )}

                {/* =======Country====== */}

                {location.pathname === "/View/HCM/Country" && <Country />}
                {location.pathname === "/View/HCM/Country/AddCountry" && (
                    <AddCountry />
                )}

                {/* =======Title====== */}

                {location.pathname === "/View/HCM/Title" && <Title />}
                {location.pathname === "/View/HCM/Title/AddTitle" && (
                    <AddTitle />
                )}

                {/* =======Language====== */}

                {location.pathname === "/View/HCM/Language" && <Language />}
                {location.pathname === "/View/HCM/Language/AddLanguage" && (
                    <AddLanguage />
                )}

                {/* =======ActionReason====== */}

                {location.pathname === "/View/HCM/ActionReason" && (
                    <ActionReason />
                )}
                {location.pathname ===
                    "/View/HCM/ActionReason/AddActionReason" && (
                    <AddActionReason />
                )}

                {/* =======ActionType====== */}

                {location.pathname === "/View/HCM/ActionType" && <ActionType />}
                {location.pathname === "/View/HCM/ActionType/AddActionType" && (
                    <AddActionType />
                )}

                {/* =======AddressType====== */}

                {location.pathname === "/View/HCM/AddressType" && (
                    <AddressType />
                )}
                {location.pathname ===
                    "/View/HCM/AddressType/AddAddressType" && (
                    <AddAddressType />
                )}

                {/* =======Commitment====== */}

                {location.pathname === "/View/HCM/Commitment" && <Commitment />}
                {location.pathname === "/View/HCM/Commitment/AddCommitment" && (
                    <AddCommitment />
                )}

                {/* =======CustomReport====== */}

                {location.pathname === "/View/HCM/CustomReport" && (
                    <CustomReport />
                )}
                {location.pathname ===
                    "/View/HCM/CustomReport/AddCustomReport" && (
                    <AddCustomReport />
                )}

                {/* =======DisciplineCaseType====== */}

                {location.pathname === "/View/HCM/DisciplineCaseType" && (
                    <DisciplineCaseType />
                )}
                {location.pathname ===
                    "/View/HCM/DisciplineCaseType/AddDisciplineCaseType" && (
                    <AddDisciplineCaseType />
                )}

                {/* =======DurationMeasure====== */}

                {location.pathname === "/View/HCM/DurationMeasure" && (
                    <DurationMeasure />
                )}
                {location.pathname ===
                    "/View/HCM/DurationMeasure/AddDurationMeasure" && (
                    <AddDurationMeasure />
                )}

                {/* =======EmployeeGroup====== */}

                {location.pathname === "/View/HCM/EmployeeGroup" && (
                    <EmployeeGroup />
                )}
                {location.pathname ===
                    "/View/HCM/EmployeeGroup/AddEmployeeGroup" && (
                    <AddEmployeeGroup />
                )}

                {/* =======EmployeeType====== */}

                {location.pathname === "/View/HCM/EmployeeType" && (
                    <EmployeeType />
                )}
                {location.pathname ===
                    "/View/HCM/EmployeeType/AddEmployeeType" && (
                    <AddEmployeeType />
                )}

                {/* =======FieldOfStudy====== */}

                {location.pathname === "/View/HCM/FieldOfStudy" && (
                    <FieldOfStudy />
                )}
                {location.pathname ===
                    "/View/HCM/FieldOfStudy/AddFieldOfStudy" && (
                    <AddFieldOfStudy />
                )}

                {/* =======HCMConfiguration====== */}

                {location.pathname === "/View/HCM/HCMConfiguration" && (
                    <HCMConfiguration />
                )}
                {location.pathname ===
                    "/View/HCM/HCMConfiguration/AddHCMConfiguration" && (
                    <AddHCMConfiguration />
                )}

                {/* =======EmployeeTransactionLock====== */}

                {location.pathname === "/View/HCM/EmployeeTransactionLock" && (
                    <EmployeeTransactionLock />
                )}
                {location.pathname ===
                    "/View/HCM/EmployeeTransactionLock/AddEmployeeTransactionLock" && (
                    <AddEmployeeTransactionLock />
                )}

                {/* =======LicenseType====== */}

                {location.pathname === "/View/HCM/LicenseType" && (
                    <LicenseType />
                )}
                {location.pathname ===
                    "/View/HCM/LicenseType/AddLicenseType" && (
                    <AddLicenseType />
                )}

                {/* =======Nationality====== */}

                {location.pathname === "/View/HCM/Nationality" && (
                    <Nationality />
                )}
                {location.pathname ===
                    "/View/HCM/Nationality/AddNationality" && (
                    <AddNationality />
                )}

                {/* =======Religion====== */}

                {location.pathname === "/View/HCM/Religion" && <Religion />}
                {location.pathname === "/View/HCM/Religion/AddReligion" && (
                    <AddReligion />
                )}

                {/* =======TrainingType====== */}

                {location.pathname === "/View/HCM/TrainingType" && (
                    <TrainingType />
                )}
                {location.pathname ===
                    "/View/HCM/TrainingType/AddTrainingType" && (
                    <AddTrainingType />
                )}

                {/* --------------------------------------------------HCM JOP POSITIONS--------------------------------------- */}
                {/* =======JobCategory====== */}

                {location.pathname === "/View/HCM/JobCategory" && (
                    <JobCategory />
                )}
                {location.pathname ===
                    "/View/HCM/JobCategory/AddJobCategory" && (
                    <AddJobCategory />
                )}
                {/* =======JobSafetyEquipment====== */}

                {location.pathname === "/View/HCM/JobSafetyEquipment" && (
                    <JobSafetyEquipment />
                )}
                {location.pathname ===
                    "/View/HCM/JobSafetyEquipment/AddJobSafetyEquipment" && (
                    <AddJobSafetyEquipment />
                )}
                {/* =======JobTitle====== */}

                {location.pathname === "/View/HCM/JobTitle" && <JobTitle />}
                {location.pathname === "/View/HCM/JobTitle/AddJobTitle" && (
                    <AddJobTitle />
                )}
                {/* =======SafetyEquipment====== */}

                {location.pathname === "/View/HCM/SafetyEquipment" && (
                    <SafetyEquipment />
                )}
                {location.pathname ===
                    "/View/HCM/SafetyEquipment/AddSafetyEquipment" && (
                    <AddSafetyEquipment />
                )}

                {/* ==========================================EMPLOYEE MASTER===================================================*/}

                {/* =======Address====== */}

                {location.pathname === "/View/HCM/Address" && <Address />}
                {location.pathname === "/View/HCM/Address/AddAddress" && (
                    <AddAddress />
                )}
                {/* =======Attachment====== */}

                {location.pathname === "/View/HCM/Attachment" && <Attachment />}
                {location.pathname === "/View/HCM/Attachment/AddAttachment" && (
                    <AddAttachment />
                )}
                {/* =======Dependent====== */}

                {location.pathname === "/View/HCM/Dependent" && <Dependent />}
                {location.pathname === "/View/HCM/Dependent/AddDependent" && (
                    <AddDependent />
                )}
                {/* =======Discipline====== */}

                {location.pathname === "/View/HCM/Discipline" && <Discipline />}
                {location.pathname === "/View/HCM/Discipline/AddDiscipline" && (
                    <AddDiscipline />
                )}
                {/* =======Education====== */}

                {location.pathname === "/View/HCM/Education" && <Education />}
                {location.pathname === "/View/HCM/Education/AddEducation" && (
                    <AddEducation />
                )}
                {/* =======Employee====== */}

                {location.pathname.match(
                    /\/View\/HCM\/Employee\/[0-9]+\/.*/
                ) && <EmployeeDetail />}
                {location.pathname === "/View/HCM/Employee" && <Employee />}
                {location.pathname === "/View/HCM/Employee/AddEmployee" && (
                    <AddEmployee />
                )}
                {/* =======EmployeeAction====== */}

                {location.pathname === "/View/HCM/EmployeeAction" && (
                    <EmployeeAction />
                )}
                {location.pathname ===
                    "/View/HCM/EmployeeAction/AddEmployeeAction" && (
                    <AddEmployeeAction />
                )}
                {/* =======EmployeeAttachment====== */}

                {location.pathname === "/View/HCM/EmployeeAttachment" && (
                    <EmployeeAttachment />
                )}
                {location.pathname ===
                    "/View/HCM/EmployeeAttachment/AddEmployeeAttachment" && (
                    <AddEmployeeAttachment />
                )}
                {/* =======EmployeeCommitment====== */}

                {location.pathname === "/View/HCM/EmployeeCommitment" && (
                    <EmployeeCommitment />
                )}
                {location.pathname ===
                    "/View/HCM/EmployeeCommitment/AddEmployeeCommitment" && (
                    <AddEmployeeCommitment />
                )}
                {/* =======EmployeeContact====== */}

                {location.pathname === "/View/HCM/EmployeeContact" && (
                    <EmployeeContact />
                )}
                {location.pathname ===
                    "/View/HCM/EmployeeContact/AddEmployeeContact" && (
                    <AddEmployeeContact />
                )}
                {/* =======Calendar====== */}

                {location.pathname === "/View/HCM/AddCalendar" && (
                    <AddCalendar />
                )}
                {/* =======EmployeeIdRange====== */}

                {location.pathname === "/View/HCM/EmployeeIdRange" && (
                    <EmployeeIdRange />
                )}
                {location.pathname ===
                    "/View/HCM/EmployeeIdRange/AddEmployeeIdRange" && (
                    <AddEmployeeIdRange />
                )}
                {/* =======EmployeeLoanRepayment====== */}

                {location.pathname === "/View/HCM/EmployeeLoanRepayment" && (
                    <EmployeeLoanRepayment />
                )}
                {location.pathname ===
                    "/View/HCM/EmployeeLoanRepayment/AddEmployeeLoanRepayment" && (
                    <AddEmployeeLoanRepayment />
                )}
                {/* =======EmployeePayFrequency====== */}

                {location.pathname === "/View/HCM/EmployeePayFrequency" && (
                    <EmployeePayFrequency />
                )}
                {location.pathname ===
                    "/View/HCM/EmployeePayFrequency/AddEmployeePayFrequency" && (
                    <AddEmployeePayFrequency />
                )}
                {/* =======EmployeePaygrade====== */}

                {location.pathname === "/View/HCM/EmployeePaygrade" && (
                    <EmployeePaygrade />
                )}
                {location.pathname ===
                    "/View/HCM/EmployeePaygrade/AddEmployeePaygrade" && (
                    <AddEmployeePaygrade />
                )}
                {/* =======EmployeeSalaryComponent====== */}

                {location.pathname === "/View/HCM/EmployeeSalaryComponent" && (
                    <EmployeeSalaryComponent />
                )}
                {location.pathname ===
                    "/View/HCM/EmployeeSalaryComponent/AddEmployeeSalaryComponent" && (
                    <AddEmployeeSalaryComponent />
                )}
                {/* =======Experience====== */}

                {location.pathname === "/View/HCM/Experience" && <Experience />}
                {location.pathname === "/View/HCM/Experience/AddExperience" && (
                    <AddExperience />
                )}
                {/* =======LeaveAssignment====== */}

                {location.pathname === "/View/HCM/LeaveAssignment" && (
                    <LeaveAssignment />
                )}
                {location.pathname ===
                    "/View/HCM/LeaveAssignment/AddLeaveAssignment" && (
                    <AddLeaveAssignment />
                )}
                {/* =======LeaveEntitlement====== */}

                {location.pathname === "/View/HCM/LeaveEntitlement" && (
                    <LeaveEntitlement />
                )}
                {location.pathname ===
                    "/View/HCM/LeaveEntitlement/AddLeaveEntitlement" && (
                    <AddLeaveEntitlement />
                )}
                {/* =======LeaveTransfer====== */}

                {location.pathname === "/View/HCM/LeaveTransfer" && (
                    <LeaveTransfer />
                )}
                {location.pathname ===
                    "/View/HCM/LeaveTransfer/AddLeaveTransfer" && (
                    <AddLeaveTransfer />
                )}
                {/* =======License====== */}

                {location.pathname === "/View/HCM/License" && <License />}
                {location.pathname === "/View/HCM/License/AddLicense" && (
                    <AddLicense />
                )}
                {/* =======OrgAssignment====== */}

                {location.pathname === "/View/HCM/OrgAssignment" && (
                    <OrgAssignment />
                )}
                {location.pathname ===
                    "/View/HCM/OrgAssignment/AddOrgAssignment" && (
                    <AddOrgAssignment />
                )}
                {/* =======ServiceAllowancePay====== */}

                {location.pathname === "/View/HCM/ServiceAllowancePay" && (
                    <ServiceAllowancePay />
                )}
                {location.pathname ===
                    "/View/HCM/ServiceAllowancePay/AddServiceAllowancePay" && (
                    <AddServiceAllowancePay />
                )}
                {/* =======ShiftAssignment====== */}

                {location.pathname === "/View/HCM/ShiftAssignment" && (
                    <ShiftAssignment />
                )}
                {location.pathname ===
                    "/View/HCM/ShiftAssignment/AddShiftAssignment" && (
                    <AddShiftAssignment />
                )}
                {/* =======================================================HCM COMPANY STRUCTURE================================================= */}

                {/* =======BusinessUnit====== */}

                {location.pathname === "/View/HCM/BusinessUnit" && (
                    <BusinessUnit />
                )}
                {location.pathname ===
                    "/View/HCM/BusinessUnit/AddBusinessUnit" && (
                    <AddBusinessUnit />
                )}
                {/* =======Company====== */}

                {location.pathname === "/View/HCM/Company" && <Company />}
                {location.pathname === "/View/HCM/Company/AddCompany" && (
                    <AddCompany />
                )}
                {/* =======Location====== */}

                {location.pathname === "/View/HCM/Location" && <Location />}
                {location.pathname === "/View/HCM/Location/AddLocation" && (
                    <AddLocation />
                )}
                {/* =======CompanyPrimaryContact====== */}

                {location.pathname === "/View/HCM/CompanyPrimaryContact" && (
                    <CompanyPrimaryContact />
                )}
                {location.pathname ===
                    "/View/HCM/CompanyPrimaryContact/AddCompanyPrimaryContact" && (
                    <AddCompanyPrimaryContact />
                )}

                {/* ===========================================================HCM PAYROLL=========================================================== */}

                {/* =======PAYROLL SLIP====== */}

                {location.pathname === "/View/HCM/PayrollSlip" && (
                    <PayrollSlip />
                )}

                {/* =======EmployeeBackPenalityDeduction====== */}

                {location.pathname ===
                    "/View/HCM/EmployeeBackPenalityDeduction" && (
                    <EmployeeBackPenalityDeduction />
                )}
                {location.pathname ===
                    "/View/HCM/EmployeeBackPenalityDeduction/AddEmployeeBackPenalityDeduction" && (
                    <AddEmployeeBackPenalityDeduction />
                )}
                {/* =======EmployeePayScale====== */}

                {location.pathname === "/View/HCM/EmployeePayScale" && (
                    <EmployeePayScale />
                )}
                {location.pathname ===
                    "/View/HCM/EmployeePayScale/AddEmployeePayScale" && (
                    <AddEmployeePayScale />
                )}
                {/* =======EmployeePenality====== */}

                {location.pathname === "/View/HCM/EmployeePenality" && (
                    <EmployeePenality />
                )}
                {location.pathname ===
                    "/View/HCM/EmployeePenality/AddEmployeePenality" && (
                    <AddEmployeePenality />
                )}
                {/* =======EmployeePenalityDetailMst====== */}

                {location.pathname ===
                    "/View/HCM/EmployeePenalityDetailMst" && (
                    <EmployeePenalityDetailMst />
                )}
                {location.pathname ===
                    "/View/HCM/EmployeePenalityDetailMst/AddEmployeePenalityDetailMst" && (
                    <AddEmployeePenalityDetailMst />
                )}
                {/* =======EmployeePenalityType====== */}

                {location.pathname === "/View/HCM/EmployeePenalityType" && (
                    <EmployeePenalityType />
                )}
                {location.pathname ===
                    "/View/HCM/EmployeePenalityType/AddEmployeePenalityType" && (
                    <AddEmployeePenalityType />
                )}
                {/* =======EmployeeTax====== */}

                {location.pathname === "/View/HCM/EmployeeTax" && (
                    <EmployeeTax />
                )}
                {location.pathname ===
                    "/View/HCM/EmployeeTax/AddEmployeeTax" && (
                    <AddEmployeeTax />
                )}
                {/* =======GlobalPayrollAccountMapping====== */}

                {location.pathname ===
                    "/View/HCM/GlobalPayrollAccountMapping" && (
                    <GlobalPayrollAccountMapping />
                )}
                {location.pathname ===
                    "/View/HCM/GlobalPayrollAccountMapping/AddGlobalPayrollAccountMapping" && (
                    <AddGlobalPayrollAccountMapping />
                )}
                {/* =======Overtime====== */}

                {location.pathname === "/View/HCM/Overtime" && <Overtime />}
                {location.pathname === "/View/HCM/Overtime/AddOvertime" && (
                    <AddOvertime />
                )}
                {/* =======OvertimeRate====== */}

                {location.pathname === "/View/HCM/OvertimeRate" && (
                    <OvertimeRate />
                )}
                {location.pathname ===
                    "/View/HCM/OvertimeRate/AddOvertimeRate" && (
                    <AddOvertimeRate />
                )}
                {/* =======Paygrade====== */}

                {location.pathname === "/View/HCM/Paygrade" && <Paygrade />}
                {location.pathname === "/View/HCM/Paygrade/AddPaygrade" && (
                    <AddPaygrade />
                )}
                {/* =======PaygradeSalaryComponent====== */}

                {location.pathname === "/View/HCM/PaygradeSalaryComponent" && (
                    <PaygradeSalaryComponent />
                )}
                {location.pathname ===
                    "/View/HCM/PaygradeSalaryComponent/AddPaygradeSalaryComponent" && (
                    <AddPaygradeSalaryComponent />
                )}
                {/* =======PaygradeScale====== */}

                {location.pathname === "/View/HCM/PaygradeScale" && (
                    <PaygradeScale />
                )}
                {location.pathname ===
                    "/View/HCM/PaygradeScale/AddPaygradeScale" && (
                    <AddPaygradeScale />
                )}
                {/* =======PayrollDetail====== */}

                {location.pathname === "/View/HCM/PayrollDetail" && (
                    <PayrollDetail />
                )}
                {location.pathname ===
                    "/View/HCM/PayrollDetail/AddPayrollDetail" && (
                    <AddPayrollDetail />
                )}
                {/* =======PayrollDetailHistory====== */}

                {location.pathname === "/View/HCM/PayrollDetailHistory" && (
                    <PayrollDetailHistory />
                )}
                {location.pathname ===
                    "/View/HCM/PayrollDetailHistory/AddPayrollDetailHistory" && (
                    <AddPayrollDetailHistory />
                )}
                {/* =======PayrollFrequencyType====== */}

                {location.pathname === "/View/HCM/PayrollFrequencyType" && (
                    <PayrollFrequencyType />
                )}
                {location.pathname ===
                    "/View/HCM/PayrollFrequencyType/AddPayrollFrequencyType" && (
                    <AddPayrollFrequencyType />
                )}
                {/* =======PayrollLocationSetting====== */}

                {location.pathname === "/View/HCM/PayrollLocationSetting" && (
                    <PayrollLocationSetting />
                )}
                {location.pathname ===
                    "/View/HCM/PayrollLocationSetting/AddPayrollLocationSetting" && (
                    <AddPayrollLocationSetting />
                )}
                {/* =======PayrollLog====== */}

                {location.pathname === "/View/HCM/PayrollLog" && <PayrollLog />}
                {location.pathname === "/View/HCM/PayrollLog/AddPayrollLog" && (
                    <AddPayrollLog />
                )}
                {/* =======PayrollLogEmployee====== */}

                {location.pathname === "/View/HCM/PayrollLogEmployee" && (
                    <PayrollLogEmployee />
                )}
                {location.pathname ===
                    "/View/HCM/PayrollLogEmployee/AddPayrollLogEmployee" && (
                    <AddPayrollLogEmployee />
                )}
                {/* =======PayrollPeriodAutogen====== */}

                {location.pathname === "/View/HCM/PayrollPeriodAutogen" && (
                    <PayrollPeriodAutogen />
                )}
                {location.pathname ===
                    "/View/HCM/PayrollPeriodAutogen/AddPayrollPeriodAutogen" && (
                    <AddPayrollPeriodAutogen />
                )}
                {/* =======PayrollPeriodAutogenLog====== */}

                {location.pathname === "/View/HCM/PayrollPeriodAutogenLog" && (
                    <PayrollPeriodAutogenLog />
                )}
                {location.pathname ===
                    "/View/HCM/PayrollPeriodAutogenLog/AddPayrollPeriodAutogenLog" && (
                    <AddPayrollPeriodAutogenLog />
                )}
                {/* =======PayrollPeriodTemplate====== */}

                {location.pathname === "/View/HCM/PayrollPeriodTemplate" && (
                    <PayrollPeriodTemplate />
                )}
                {location.pathname ===
                    "/View/HCM/PayrollPeriodTemplate/AddPayrollPeriodTemplate" && (
                    <AddPayrollPeriodTemplate />
                )}
                {/* =======PayrollPostingLog====== */}

                {location.pathname === "/View/HCM/PayrollPostingLog" && (
                    <PayrollPostingLog />
                )}
                {location.pathname ===
                    "/View/HCM/PayrollPostingLog/AddPayrollPostingLog" && (
                    <AddPayrollPostingLog />
                )}
                {/* =======PayrollProcessingLog====== */}

                {location.pathname === "/View/HCM/PayrollProcessingLog" && (
                    <PayrollProcessingLog />
                )}
                {location.pathname ===
                    "/View/HCM/PayrollProcessingLog/AddPayrollProcessingLog" && (
                    <AddPayrollProcessingLog />
                )}
                {/* =======PayrollSummary====== */}

                {location.pathname === "/View/HCM/PayrollSummary" && (
                    <PayrollSummary />
                )}
                {location.pathname ===
                    "/View/HCM/PayrollSummary/AddPayrollSummary" && (
                    <AddPayrollSummary />
                )}
                {/* =======PayrollSummaryHistory====== */}

                {location.pathname === "/View/HCM/PayrollSummaryHistory" && (
                    <PayrollSummaryHistory />
                )}
                {location.pathname ===
                    "/View/HCM/PayrollSummaryHistory/AddPayrollSummaryHistory" && (
                    <AddPayrollSummaryHistory />
                )}
                {/* =======PayScaleHistoryLog====== */}

                {location.pathname === "/View/HCM/PayScaleHistoryLog" && (
                    <PayScaleHistoryLog />
                )}
                {location.pathname ===
                    "/View/HCM/PayScaleHistoryLog/AddPayScaleHistoryLog" && (
                    <AddPayScaleHistoryLog />
                )}
                {/* =======SalaryAdjustment====== */}

                {location.pathname === "/View/HCM/SalaryAdjustment" && (
                    <SalaryAdjustment />
                )}
                {location.pathname ===
                    "/View/HCM/SalaryAdjustment/AddSalaryAdjustment" && (
                    <AddSalaryAdjustment />
                )}
                {/* =======SalaryComponent====== */}

                {location.pathname === "/View/HCM/SalaryComponent" && (
                    <SalaryComponent />
                )}
                {location.pathname ===
                    "/View/HCM/SalaryComponent/AddSalaryComponent" && (
                    <AddSalaryComponent />
                )}
                {/* =======SalaryComponentAccountMapping====== */}

                {location.pathname ===
                    "/View/HCM/SalaryComponentAccountMapping" && (
                    <SalaryComponentAccountMapping />
                )}
                {location.pathname ===
                    "/View/HCM/SalaryComponentAccountMapping/AddSalaryComponentAccountMapping" && (
                    <AddSalaryComponentAccountMapping />
                )}

                {/* ===============================================HCM RECRUITMENT================================================ */}

                {/* =======ExternalApplicant====== */}

                {location.pathname === "/View/HCM/ExternalApplicant" && (
                    <ExternalApplicant />
                )}
                {location.pathname ===
                    "/View/HCM/ExternalApplicant/AddExternalApplicant" && (
                    <AddExternalApplicant />
                )}
                {/* =======Vacancy====== */}

                {location.pathname.match(
                    /\/View\/HCM\/Vacancy\/[0-9]+\/.*/
                ) && <VacancyDetail />}
                {location.pathname === "/View/HCM/Vacancy" && <Vacancy />}
                {location.pathname === "/View/HCM/Vacancy/AddVacancy" && (
                    <AddVacancy />
                )}
                {/* =======VacancyResult====== */}

                {location.pathname === "/View/HCM/VacancyResult" && (
                    <VacancyResult />
                )}
                {location.pathname ===
                    "/View/HCM/VacancyResult/AddVacancyResult" && (
                    <AddVacancyResult />
                )}
                {/* =======VacancyExaminer====== */}

                {location.pathname === "/View/HCM/VacancyExaminer" && (
                    <VacancyExaminer />
                )}
                {location.pathname ===
                    "/View/HCM/VacancyExaminer/AddVacancyExaminer" && (
                    <AddVacancyExaminer />
                )}
                {/* =======VacancyInternalApplicant====== */}

                {location.pathname === "/View/HCM/VacancyInternalApplicant" && (
                    <VacancyInternalApplicant />
                )}
                {location.pathname ===
                    "/View/HCM/VacancyInternalApplicant/AddVacancyInternalApplicant" && (
                    <AddVacancyInternalApplicant />
                )}
                {/* =======VacancyRequestReason====== */}

                {location.pathname === "/View/HCM/VacancyRequestReason" && (
                    <VacancyRequestReason />
                )}
                {location.pathname ===
                    "/View/HCM/VacancyRequestReason/AddVacancyRequestReason" && (
                    <AddVacancyRequestReason />
                )}
                {/* =======AttendanceAbscenceType====== */}

                {location.pathname === "/View/HCM/AttendanceRelease" && (
                    <AttendanceRelease />
                )}
                {location.pathname === "/View/HCM/AttendanceCreate" && (
                    <AttendanceCreate />
                )}
                {location.pathname === "/View/HCM/AttendanceAbscenceType" && (
                    <AttendanceAbscenceType />
                )}
                {location.pathname ===
                    "/View/HCM/AttendanceAbscenceType/AddAttendanceAbscenceType" && (
                    <AddAttendanceAbscenceType />
                )}
                {/* =======AttendanceCaptured====== */}

                {location.pathname === "/View/HCM/AttendanceCaptured" && (
                    <AttendanceCaptured />
                )}
                {location.pathname ===
                    "/View/HCM/AttendanceCaptured/AddAttendanceCaptured" && (
                    <AddAttendanceCaptured />
                )}
                {/* =======AttendancePayroll====== */}

                {location.pathname === "/View/HCM/AttendancePayroll" && (
                    <AttendancePayroll />
                )}
                {location.pathname ===
                    "/View/HCM/AttendancePayroll/AddAttendancePayroll" && (
                    <AddAttendancePayroll />
                )}
                {/* =======DeviceIdMapping====== */}

                {location.pathname === "/View/HCM/DeviceIdMapping" && (
                    <DeviceIdMapping />
                )}
                {location.pathname ===
                    "/View/HCM/DeviceIdMapping/AddDeviceIdMapping" && (
                    <AddDeviceIdMapping />
                )}
                {/* =======Holiday====== */}

                {location.pathname === "/View/HCM/Holiday" && <Holiday />}
                {location.pathname === "/View/HCM/Holiday/AddHoliday" && (
                    <AddHoliday />
                )}
                {/* =======HolidayCharacter====== */}

                {location.pathname === "/View/HCM/HolidayCharacter" && (
                    <HolidayCharacter />
                )}
                {location.pathname ===
                    "/View/HCM/HolidayCharacter/AddHolidayCharacter" && (
                    <AddHolidayCharacter />
                )}
                {/* =======LeavePeriod====== */}

                {location.pathname === "/View/HCM/LeavePeriod" && (
                    <LeavePeriod />
                )}
                {location.pathname ===
                    "/View/HCM/LeavePeriod/AddLeavePeriod" && (
                    <AddLeavePeriod />
                )}
                {/* =======LeavePlan====== */}

                {location.pathname === "/View/HCM/LeavePlan" && <LeavePlan />}
                {location.pathname === "/View/HCM/LeavePlan/AddLeavePlan" && (
                    <AddLeavePlan />
                )}
                {/* =======Punch====== */}

                {location.pathname === "/View/HCM/Punch" && <Punch />}
                {location.pathname === "/View/HCM/Punch/AddPunch" && (
                    <AddPunch />
                )}
                {/* =======PunchDevice====== */}

                {location.pathname === "/View/HCM/PunchDevice" && (
                    <PunchDevice />
                )}
                {location.pathname ===
                    "/View/HCM/PunchDevice/AddPunchDevice" && (
                    <AddPunchDevice />
                )}
                {/* =======PunchLog====== */}

                {location.pathname === "/View/HCM/PunchLog" && <PunchLog />}
                {location.pathname === "/View/HCM/PunchLog/AddPunchLog" && (
                    <AddPunchLog />
                )}
                {/* =======PunchManualImport====== */}

                {location.pathname === "/View/HCM/PunchManualImport" && (
                    <PunchManualImport />
                )}
                {location.pathname ===
                    "/View/HCM/PunchManualImport/AddPunchManualImport" && (
                    <AddPunchManualImport />
                )}
                {/* =======PunchTime====== */}

                {location.pathname === "/View/HCM/PunchTime" && <PunchTime />}
                {location.pathname === "/View/HCM/PunchTime/AddPunchTime" && (
                    <AddPunchTime />
                )}
                {/* =======ShiftScheduleDtl====== */}

                {location.pathname === "/View/HCM/ShiftScheduleDtl" && (
                    <ShiftScheduleDtl />
                )}
                {location.pathname ===
                    "/View/HCM/ShiftScheduleDtl/AddShiftScheduleDtl" && (
                    <AddShiftScheduleDtl />
                )}
                {/* =======ShiftScheduleHdr====== */}

                {location.pathname === "/View/HCM/ShiftScheduleHdr" && (
                    <ShiftScheduleHdr />
                )}
                {location.pathname ===
                    "/View/HCM/ShiftScheduleHdr/AddShiftScheduleHdr" && (
                    <AddShiftScheduleHdr />
                )}
                {/* =======SubShiftGroup====== */}

                {location.pathname === "/View/HCM/SubShiftGroup" && (
                    <SubShiftGroup />
                )}
                {location.pathname ===
                    "/View/HCM/SubShiftGroup/AddSubShiftGroup" && (
                    <AddSubShiftGroup />
                )}
                {/* =======UnitOfMeasure====== */}

                {location.pathname === "/View/Inventory/UnitOfMeasure" && (
                    <UnitOfMeasure />
                )}
                {location.pathname ===
                    "/View/Inventory/UnitOfMeasure/AddUnitOfMeasure" && (
                    <AddUnitOfMeasure />
                )}
                {/* =======Warehouse====== */}

                {location.pathname === "/View/Inventory/Warehouse" && (
                    <Warehouse />
                )}
                {location.pathname ===
                    "/View/Inventory/Warehouse/AddWarehouse" && (
                    <AddWarehouse />
                )}

                {/* =======StockBatch====== */}

                {location.pathname === "/View/Inventory/StockBatch" && (
                    <StockBatch />
                )}
                {location.pathname ===
                    "/View/Inventory/StockBatch/AddStockBatch" && (
                    <AddStockBatch />
                )}
                {/* =======StockItem====== */}

                {location.pathname === "/View/Inventory/StockItem" && (
                    <StockItem />
                )}
                {location.pathname ===
                    "/View/Inventory/StockItem/AddStockItem" && (
                    <AddStockItem />
                )}
                {/* =======StockTransactionDetail====== */}

                {location.pathname ===
                    "/View/Inventory/StockTransactionDetail" && (
                    <StockTransactionDetail />
                )}
                {location.pathname ===
                    "/View/Inventory/StockTransactionDetail/AddStockTransactionDetail" && (
                    <AddStockTransactionDetail />
                )}
                {/* =======StockTransactionHeader====== */}

                {location.pathname ===
                    "/View/Inventory/StockTransactionHeader" && (
                    <StockTransactionHeader />
                )}
                {location.pathname ===
                    "/View/Inventory/StockTransactionHeader/AddStockTransactionHeader" && (
                    <AddStockTransactionHeader />
                )}

                {/* ---------------------------------------FINANCE MASTER-------------------------------------------------- */}

                {/* =======AccountCategory====== */}

                {location.pathname === "/View/Finance/AccountCategory" && (
                    <AccountCategory />
                )}
                {location.pathname ===
                    "/View/Finance/AccountCategory/AddAccountCategory" && (
                    <AddAccountCategory />
                )}
                {/* =======AccountType====== */}

                {location.pathname === "/View/Finance/AccountType" && (
                    <AccountType />
                )}
                {location.pathname ===
                    "/View/Finance/AccountType/AddAccountType" && (
                    <AddAccountType />
                )}
                {/* =======ClosingNote====== */}

                {location.pathname === "/View/Finance/ClosingNote" && (
                    <ClosingNote />
                )}
                {location.pathname ===
                    "/View/Finance/ClosingNote/AddClosingNote" && (
                    <AddClosingNote />
                )}
                {/* =======FinancialStatementSection====== */}

                {location.pathname ===
                    "/View/Finance/FinancialStatementSection" && (
                    <FinancialStatementSection />
                )}
                {location.pathname ===
                    "/View/Finance/FinancialStatementSection/AddFinancialStatementSection" && (
                    <AddFinancialStatementSection />
                )}
                {/* =======Bank====== */}

                {location.pathname === "/View/Finance/Bank" && <Bank />}
                {location.pathname === "/View/Finance/Bank/AddBank" && (
                    <AddBank />
                )}
                {/* =======Contact====== */}

                {location.pathname === "/View/Finance/Contact" && <Contact />}
                {location.pathname === "/View/Finance/Contact/AddContact" && (
                    <AddContact />
                )}
                {/* =======ContactAddress====== */}

                {location.pathname === "/View/Finance/ContactAddress" && (
                    <ContactAddress />
                )}
                {location.pathname ===
                    "/View/Finance/ContactAddress/AddContactAddress" && (
                    <AddContactAddress />
                )}
                {/* =======ContactPerson====== */}

                {location.pathname === "/View/Finance/ContactPerson" && (
                    <ContactPerson />
                )}
                {location.pathname ===
                    "/View/Finance/ContactPerson/AddContactPerson" && (
                    <AddContactPerson />
                )}
                {/* =======CostCenter====== */}

                {location.pathname === "/View/Finance/CostCenter" && (
                    <CostCenter />
                )}
                {location.pathname ===
                    "/View/Finance/CostCenter/AddCostCenter" && (
                    <AddCostCenter />
                )}
                {/* =======CostCenterAccounts====== */}

                {location.pathname === "/View/Finance/CostCenterAccounts" && (
                    <CostCenterAccounts />
                )}
                {location.pathname ===
                    "/View/Finance/CostCenterAccounts/AddCostCenterAccounts" && (
                    <AddCostCenterAccounts />
                )}
                {/* =======PaymentTerm====== */}

                {location.pathname === "/View/Finance/PaymentTerm" && (
                    <PaymentTerm />
                )}
                {location.pathname ===
                    "/View/Finance/PaymentTerm/AddPaymentTerm" && (
                    <AddPaymentTerm />
                )}
                {/* =======ExchangeRate====== */}

                {location.pathname === "/View/Finance/ExchangeRate" && (
                    <ExchangeRate />
                )}
                {location.pathname ===
                    "/View/Finance/ExchangeRate/AddExchangeRate" && (
                    <AddExchangeRate />
                )}
                {/* =======CompanyAddress====== */}

                {location.pathname === "/View/Finance/CompanyAddress" && (
                    <CompanyAddress />
                )}
                {location.pathname ===
                    "/View/Finance/CompanyAddress/AddCompanyAddress" && (
                    <AddCompanyAddress />
                )}
                {/* =======DateFormatType====== */}

                {location.pathname === "/View/Finance/DateFormatType" && (
                    <DateFormatType />
                )}
                {location.pathname ===
                    "/View/Finance/DateFormatType/AddDateFormatType" && (
                    <AddDateFormatType />
                )}
                {/* =======FinancialSettings====== */}

                {location.pathname === "/View/Finance/FinancialSettings" && (
                    <FinancialSettings />
                )}
                {location.pathname ===
                    "/View/Finance/FinancialSettings/AddFinancialSettings" && (
                    <AddFinancialSettings />
                )}
                {/* =======Industry====== */}

                {location.pathname === "/View/Finance/Industry" && <Industry />}
                {location.pathname === "/View/Finance/Industry/AddIndustry" && (
                    <AddIndustry />
                )}
                {/* =======JournalUsers====== */}

                {location.pathname === "/View/Finance/JournalUsers" && (
                    <JournalUsers />
                )}
                {location.pathname ===
                    "/View/Finance/JournalUsers/AddJournalUsers" && (
                    <AddJournalUsers />
                )}
                {/* =======PrimaryContact====== */}

                {location.pathname === "/View/Finance/PrimaryContact" && (
                    <PrimaryContact />
                )}
                {location.pathname ===
                    "/View/Finance/PrimaryContact/AddPrimaryContact" && (
                    <AddPrimaryContact />
                )}
                {/* =======FootNote====== */}

                {location.pathname === "/View/Finance/FootNote" && <FootNote />}
                {location.pathname === "/View/Finance/FootNote/AddFootNote" && (
                    <AddFootNote />
                )}
                {/* =======AssociatedTaxGroup====== */}

                {location.pathname === "/View/Finance/AssociatedTaxGroup" && (
                    <AssociatedTaxGroup />
                )}
                {location.pathname ===
                    "/View/Finance/AssociatedTaxGroup/AddAssociatedTaxGroup" && (
                    <AddAssociatedTaxGroup />
                )}
                {/* =======Tax====== */}

                {location.pathname === "/View/Finance/Tax" && <Tax />}
                {location.pathname === "/View/Finance/Tax/AddTax" && <AddTax />}
                {/* =======TaxAuthority====== */}

                {location.pathname === "/View/Finance/TaxAuthority" && (
                    <TaxAuthority />
                )}
                {location.pathname ===
                    "/View/Finance/TaxAuthority/AddTaxAuthority" && (
                    <AddTaxAuthority />
                )}
                {/* =======TaxExemption====== */}

                {location.pathname === "/View/Finance/TaxExemption" && (
                    <TaxExemption />
                )}
                {location.pathname ===
                    "/View/Finance/TaxExemption/AddTaxExemption" && (
                    <AddTaxExemption />
                )}
                {/* =======TaxGroup====== */}

                {location.pathname === "/View/Finance/TaxGroup" && <TaxGroup />}
                {location.pathname === "/View/Finance/TaxGroup/AddTaxGroup" && (
                    <AddTaxGroup />
                )}
                {/* =======TaxRule====== */}

                {location.pathname === "/View/Finance/TaxRule" && <TaxRule />}
                {location.pathname === "/View/Finance/TaxRule/AddTaxRule" && (
                    <AddTaxRule />
                )}
                {/* =======AccountingPeriod====== */}

                {location.pathname === "/View/Finance/AccountingPeriod" && (
                    <AccountingPeriod />
                )}
                {location.pathname ===
                    "/View/Finance/AccountingPeriod/AddAccountingPeriod" && (
                    <AddAccountingPeriod />
                )}
                {/* =======JournalType====== */}

                {location.pathname === "/View/Finance/JournalType" && (
                    <JournalType />
                )}
                {location.pathname ===
                    "/View/Finance/JournalType/AddJournalType" && (
                    <AddJournalType />
                )}

                {/* ---------------------------------------------FINANCE OPERATIONS----------------------------------------------- */}

                {/* =======ChartOfAccount====== */}

                {location.pathname === "/View/Finance/ChartOfAccount" && (
                    <ChartOfAccount />
                )}
                {location.pathname ===
                    "/View/Finance/ChartOfAccount/AddChartOfAccount" && (
                    <AddChartOfAccount />
                )}
                {/* =======AccountTypeFinancialStatementSection====== */}

                {location.pathname ===
                    "/View/Finance/AccountTypeFinancialStatementSection" && (
                    <AccountTypeFinancialStatementSection />
                )}
                {location.pathname ===
                    "/View/Finance/AccountTypeFinancialStatementSection/AddAccountTypeFinancialStatementSection" && (
                    <AddAccountTypeFinancialStatementSection />
                )}
                {/* =======BankReconcilation====== */}

                {location.pathname === "/View/Finance/BankReconcilation" && (
                    <BankReconcilation />
                )}
                {location.pathname ===
                    "/View/Finance/BankReconcilation/AddBankReconcilation" && (
                    <AddBankReconcilation />
                )}
                {/* =======ChartOfAccountFiles====== */}

                {location.pathname === "/View/Finance/ChartOfAccountFiles" && (
                    <ChartOfAccountFiles />
                )}
                {location.pathname ===
                    "/View/Finance/ChartOfAccountFiles/AddChartOfAccountFiles" && (
                    <AddChartOfAccountFiles />
                )}
                {/* =======ReconcilationTransaction====== */}

                {location.pathname ===
                    "/View/Finance/ReconcilationTransaction" && (
                    <ReconcilationTransaction />
                )}
                {location.pathname ===
                    "/View/Finance/ReconcilationTransaction/AddReconcilationTransaction" && (
                    <AddReconcilationTransaction />
                )}
                {/* =======Asset====== */}

                {location.pathname === "/View/Finance/Asset" && <Asset />}
                {location.pathname === "/View/Finance/Asset/AddAsset" && (
                    <AddAsset />
                )}
                {/* =======EstimatedTotalProductionUnit====== */}

                {location.pathname ===
                    "/View/Finance/EstimatedTotalProductionUnit" && (
                    <EstimatedTotalProductionUnit />
                )}
                {location.pathname ===
                    "/View/Finance/EstimatedTotalProductionUnit/AddEstimatedTotalProductionUnit" && (
                    <AddEstimatedTotalProductionUnit />
                )}
                {/* =======RecurringJournalOccurrence====== */}

                {location.pathname ===
                    "/View/Finance/RecurringJournalOccurrence" && (
                    <RecurringJournalOccurrence />
                )}
                {location.pathname ===
                    "/View/Finance/RecurringJournalOccurrence/AddRecurringJournalOccurrence" && (
                    <AddRecurringJournalOccurrence />
                )}
                {/* =======Budget====== */}

                {location.pathname === "/View/Finance/Budget" && <Budget />}
                {location.pathname === "/View/Finance/Budget/AddBudget" && (
                    <AddBudget />
                )}
                {/* =======BudgetAccount====== */}

                {location.pathname === "/View/Finance/BudgetAccount" && (
                    <BudgetAccount />
                )}
                {location.pathname ===
                    "/View/Finance/BudgetAccount/AddBudgetAccount" && (
                    <AddBudgetAccount />
                )}
                {/* =======BudgetAccountPeriod====== */}

                {location.pathname === "/View/Finance/BudgetAccountPeriod" && (
                    <BudgetAccountPeriod />
                )}
                {location.pathname ===
                    "/View/Finance/BudgetAccountPeriod/AddBudgetAccountPeriod" && (
                    <AddBudgetAccountPeriod />
                )}
                {/* =======BudgetControlAction====== */}

                {location.pathname === "/View/Finance/BudgetControlAction" && (
                    <BudgetControlAction />
                )}
                {location.pathname ===
                    "/View/Finance/BudgetControlAction/AddBudgetControlAction" && (
                    <AddBudgetControlAction />
                )}
                {/* =======GeneralJournalDetail====== */}

                {location.pathname === "/View/Finance/GeneralJournalDetail" && (
                    <GeneralJournalDetail />
                )}
                {location.pathname ===
                    "/View/Finance/GeneralJournalDetail/AddGeneralJournalDetail" && (
                    <AddGeneralJournalDetail />
                )}
                {/* =======GeneralJournalFiles====== */}

                {location.pathname === "/View/Finance/GeneralJournalFiles" && (
                    <GeneralJournalFiles />
                )}
                {location.pathname ===
                    "/View/Finance/GeneralJournalFiles/AddGeneralJournalFiles" && (
                    <AddGeneralJournalFiles />
                )}
                {/* =======GeneralJournalHeader====== */}

                {location.pathname === "/View/Finance/GeneralJournalHeader" && (
                    <GeneralJournalHeader />
                )}
                {location.pathname ===
                    "/View/Finance/GeneralJournalHeader/AddGeneralJournalHeader" && (
                    <AddGeneralJournalHeader />
                )}
                {/* =======GeneralLedger====== */}

                {location.pathname === "/View/Finance/GeneralLedger" && (
                    <GeneralLedger />
                )}
                {location.pathname ===
                    "/View/Finance/GeneralLedger/AddGeneralLedger" && (
                    <AddGeneralLedger />
                )}
                {/* =======JournalComment====== */}

                {location.pathname === "/View/Finance/JournalComment" && (
                    <JournalComment />
                )}
                {location.pathname ===
                    "/View/Finance/JournalComment/AddJournalComment" && (
                    <AddJournalComment />
                )}
                {/* =======NumberTracker====== */}

                {location.pathname === "/View/Finance/NumberTracker" && (
                    <NumberTracker />
                )}
                {location.pathname ===
                    "/View/Finance/NumberTracker/AddNumberTracker" && (
                    <AddNumberTracker />
                )}
                {/* =======RecurringGeneralJournal====== */}

                {location.pathname ===
                    "/View/Finance/RecurringGeneralJournal" && (
                    <RecurringGeneralJournal />
                )}
                {location.pathname ===
                    "/View/Finance/RecurringGeneralJournal/AddRecurringGeneralJournal" && (
                    <AddRecurringGeneralJournal />
                )}
                {/* =======TransactionLock====== */}

                {location.pathname === "/View/Finance/TransactionLock" && (
                    <TransactionLock />
                )}
                {location.pathname ===
                    "/View/Finance/TransactionLock/AddTransactionLock" && (
                    <AddTransactionLock />
                )}
                {/* =======OpeningBalance====== */}

                {location.pathname === "/View/Finance/OpeningBalance" && (
                    <OpeningBalance />
                )}
                {location.pathname ===
                    "/View/Finance/OpeningBalance/AddOpeningBalance" && (
                    <AddOpeningBalance />
                )}
                {/* =======CashPaymentCustom====== */}

                {location.pathname === "/View/Finance/CashPaymentCustom" && (
                    <CashPaymentCustom />
                )}
                {location.pathname ===
                    "/View/Finance/CashPaymentCustom/AddCashPaymentCustom" && (
                    <AddCashPaymentCustom />
                )}
                {/* =======CashPaymentVoucher====== */}

                {location.pathname === "/View/Finance/CashPaymentVoucher" && (
                    <CashPaymentVoucher />
                )}
                {location.pathname ===
                    "/View/Finance/CashPaymentVoucher/AddCashPaymentVoucher" && (
                    <AddCashPaymentVoucher />
                )}
                {/* =======CpvBank====== */}

                {location.pathname === "/View/Finance/CpvBank" && <CpvBank />}
                {location.pathname === "/View/Finance/CpvBank/AddCpvBank" && (
                    <AddCpvBank />
                )}
                {/* =======CpvPaymentRequest====== */}

                {location.pathname === "/View/Finance/CpvPaymentRequest" && (
                    <CpvPaymentRequest />
                )}
                {location.pathname ===
                    "/View/Finance/CpvPaymentRequest/AddCpvPaymentRequest" && (
                    <AddCpvPaymentRequest />
                )}
                {/* =======CpvReceipt====== */}

                {location.pathname === "/View/Finance/CpvReceipt" && (
                    <CpvReceipt />
                )}
                {location.pathname ===
                    "/View/Finance/CpvReceipt/AddCpvReceipt" && (
                    <AddCpvReceipt />
                )}
                {/* =======CpvType====== */}

                {location.pathname === "/View/Finance/CpvType" && <CpvType />}
                {location.pathname === "/View/Finance/CpvType/AddCpvType" && (
                    <AddCpvType />
                )}
                {/* =======PettyCash====== */}

                {location.pathname === "/View/Finance/PettyCash" && (
                    <PettyCash />
                )}
                {location.pathname ===
                    "/View/Finance/PettyCash/AddPettyCash" && <AddPettyCash />}
                {/* =======CrvPayment====== */}

                {location.pathname === "/View/Finance/CrvPayment" && (
                    <CrvPayment />
                )}
                {location.pathname ===
                    "/View/Finance/CrvPayment/AddCrvPayment" && (
                    <AddCrvPayment />
                )}
                {/* =======CrvType====== */}

                {location.pathname === "/View/Finance/CrvType" && <CrvType />}
                {location.pathname === "/View/Finance/CrvType/AddCrvType" && (
                    <AddCrvType />
                )}
                {/* =======PV Request====== */}

                {location.pathname === "/View/Finance/PVRequest" && (
                    <PVRequest />
                )}
                {location.pathname ===
                    "/View/Finance/PVRequest/AddPVRequest" && <AddPVRequest />}

                {/* =============================================ACCOUNTANT EXPORT========================================= */}
                {location.pathname === "/View/Finance/AccountantExport" && (
                    <AccountantExport />
                )}
                {location.pathname === "/View/Finance/BusinessOverview" && (
                    <BusinessOverview />
                )}
                {location.pathname === "/View/Finance/FinanceApproval" && (
                    <FinanceApproval />
                )}
                {location.pathname === "/View/Sales/AddQuote" && (
                    <AddQuote />
                )}
                {location.pathname === "/View/Sales/ViewQuote" && (
                    <ViewQuote />
                )}
            </Suspense>
        </Paper>
    );
};

export default Body;
