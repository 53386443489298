import { Box, Checkbox, FormControl, FormLabel, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { caller } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import { AttendanceAbscenceTypeURL } from "../../../../utils/config";

const AddAttendanceAbscenceType = () => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [IsWithQuota, setIsWithQuota] = useState(false);
    const [IsWithPay, setIsWithPay] = useState(false);
    const [IsAnnualLeave, setIsAnnualLeave] = useState(false);
    const [IsWithEntitlement, setIsWithEntitlement] = useState(false);
    const [IsRegularAttendance, setIsRegularAttendance] = useState(false);
    const [IsAbsenceIncludesDayOff, setIsAbsenceIncludesDayOff] =
        useState(false);
    const [msg, setMsg] = useState("");
    const [aaType, setaaType] = useState(1);

    const AATYPE = ["Attendance", "Absence"];

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        aa_description: "",
        worked_time: "",
        aa_type: aaType,
        is_with_quota: IsWithQuota, //Absence
        is_with_pay: IsWithPay, //
        is_annual_leave: IsAnnualLeave,
        is_with_entitlement: IsWithEntitlement, //
        is_regular_attendance: IsRegularAttendance,
        number_of_days: "", //quota&&Ab
        number_of_increment_each_year: "",
        is_absence_includes_day_off: IsAbsenceIncludesDayOff,
    };

    const FORM_VALIDATION = Yup.object().shape({
        aa_description: Yup.string().min(1).required("*Required"),
        is_with_quota: Yup.boolean().required("*Required"),
        is_with_pay: Yup.boolean().required("*Required"),
        is_annual_leave: Yup.boolean().required("*Required"),
        is_with_entitlement: Yup.boolean().required("*Required"),
        is_regular_attendance: Yup.boolean().required("*Required"),
        number_of_days: Yup.number().when("is_with_quota", {
            is: (value) => {
                value = IsWithQuota;
                return value;
            },
            then: Yup.number().required("*Required").nullable(),
            otherwise: Yup.number(),
        }),
        number_of_increment_each_year: Yup.number().when("is_annual_leave", {
            is: (value) => {
                value = IsAnnualLeave;
                return value;
            },
            then: Yup.number().required("*Required"),
            otherwise: Yup.number(),
        }),
    });

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        caller(AttendanceAbscenceTypeURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                actions.resetForm();
            }
        });
    };

    return (
        <div>
            <TitleHeader
                title="Add Attendance Absence Type"
                icon={<AddRounded />}
            />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        values.is_annual_leave = IsAnnualLeave;
                        values.is_regular_attendance = IsRegularAttendance;
                        values.is_with_entitlement = IsWithEntitlement;
                        values.is_with_pay = IsWithPay;
                        values.is_with_quota = IsWithQuota;
                        values.aa_type = aaType;
                        values.is_absence_includes_day_off =
                            IsAbsenceIncludesDayOff;
                        values.number_of_days =
                            values.number_of_days || undefined;
                        values.number_of_increment_each_year =
                            values.number_of_increment_each_year || undefined;

                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextFieldWrapper
                                    name="aa_description"
                                    label="Attendance Absence Description"
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="worked_time"
                                    label="Work Hours"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    select
                                    name="aa_type"
                                    label="Attendance Absence Type"
                                    variant="outlined"
                                    options={AATYPE}
                                    value={aaType}
                                    onChange={(e) => {
                                        setaaType(e.target.value);
                                    }}
                                />
                            </Grid>
                            {aaType === 2 && (
                                <>
                                    <Box width={"100%"} />
                                    <Grid item xs={6}>
                                        <FormControl
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                            }}
                                        >
                                            <FormLabel>Is With Quota</FormLabel>
                                            <Checkbox
                                                name="is_with_quota"
                                                checked={IsWithQuota}
                                                onChange={(event) => {
                                                    setIsWithQuota(
                                                        event.target.checked
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {IsWithQuota && (
                                        <Grid item xs={4}>
                                            <TextFieldWrapper
                                                name="number_of_days"
                                                label="Number Of Days"
                                                variant="outlined"
                                                type="number"
                                            />
                                        </Grid>
                                    )}

                                    <Grid item xs={6}>
                                        <FormControl
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                            }}
                                        >
                                            <FormLabel>Is With Pay</FormLabel>
                                            <Checkbox
                                                name="is_with_pay"
                                                checked={IsWithPay}
                                                onChange={(event) => {
                                                    setIsWithPay(
                                                        event.target.checked
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                            }}
                                        >
                                            <FormLabel>
                                                Is With Entitlement
                                            </FormLabel>
                                            <Checkbox
                                                name="is_with_entitlement"
                                                checked={IsWithEntitlement}
                                                onChange={(event) => {
                                                    setIsWithEntitlement(
                                                        event.target.checked
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Box width={"100%"} />
                                </>
                            )}

                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>Is Annual Leave</FormLabel>
                                    <Checkbox
                                        name="is_annual_leave"
                                        checked={IsAnnualLeave}
                                        onChange={(event) => {
                                            setIsAnnualLeave(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            {IsAnnualLeave && (
                                <Grid item xs={4}>
                                    <TextFieldWrapper
                                        name="number_of_increment_each_year"
                                        label="Number Of Increment Each Year"
                                        variant="outlined"
                                        type="number"
                                    />
                                </Grid>
                            )}

                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>Is Regular Attendance</FormLabel>
                                    <Checkbox
                                        name="is_regular_attendance"
                                        checked={IsRegularAttendance}
                                        onChange={(event) => {
                                            setIsRegularAttendance(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>
                                        Is Absence Includes Day Off
                                    </FormLabel>
                                    <Checkbox
                                        name="is_absence_includes_day_off"
                                        checked={IsAbsenceIncludesDayOff}
                                        onChange={(event) => {
                                            setIsAbsenceIncludesDayOff(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default AddAttendanceAbscenceType;
