import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { JournalUsersURL, GetUser } from "../../../../utils/config";
import { SnackBar } from "../../../../Components/SnackBar/SnackBar.component.jsx";
import { caller, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import { Add, AddRounded } from "@mui/icons-material";
import { Renderlist } from "../../../../Components/RenderList/RenderList.component";

const AddJournalUsers = () => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setMsg] = useState("");
    const [listLength, setlistLength] = useState([0]);
    const [filter1, setFilter1] = useState("");
    const [options1, setOptions1] = useState([]);
    const [optionSelect1, setOptionSelect1] = useState("");
    const [AccessName, setAccessName] = useState({ 0: 1 });

    const ACCESS_NAME = ["Manual Journal", "Recurring Journal"];
    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        list: [
            {
                access_name: "",
                user_id: null,
            },
        ],
    };

    const FORM_VALIDATION = Yup.object().shape({
        list: Yup.array().of(
            Yup.object().shape({
                access_name: Yup.number().min(1).required("*Required"),
                // user_id: Yup.number().min(1).required("*Required"),
            })
        ),
    });

    useEffect(() => {
        get(GetUser, {
            limit: 10,
            filter: {
                all: filter1,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.username}`
                    );
                });
            setOptions1(data);
        });
    }, [filter1]);

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        caller(JournalUsersURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                actions.resetForm();
            }
        });
    };

    const list = (key) => {
        return (
            <Grid container spacing={2} key={key}>
                <Grid item xs={6}>
                    <TextFieldWrapper
                        select
                        name={`list.${key}.access_name`}
                        label="Access Name"
                        variant="outlined"
                        value={AccessName[key]}
                        options={ACCESS_NAME ?? " "}
                        onChange={(e) => {
                            setAccessName({
                                ...AccessName,
                                [key]: e.target.value,
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        loading={options1.length < 1 ? true : false}
                        disablePortal
                        options={options1}
                        onChange={(event, newValue) => {
                            setOptionSelect1({
                                ...optionSelect1,
                                [key]: newValue,
                            });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="User"
                                onChange={(event) => {
                                    setFilter1(event.target.value);
                                }}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        );
    };

    const addlist = () => {
        const max = Math.max(...listLength);
        setlistLength([...listLength, max + 1]);
    };

    const setListSize = (theRest) => {
        setlistLength([...theRest]);
    };

    return (
        <div>
            <TitleHeader title="Add  Journal Users" icon={<AddRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        listLength.forEach((data) => {
                            values.list[data].user_id = parseInt(
                                optionSelect1[data]?.split("-")[0]
                            );
                        });

                        listLength.forEach((data) => {
                            values.list[data].access_name = parseInt(
                                AccessName[data]
                            );
                        });

                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <>
                                <Box
                                    style={{
                                        margin: "2ch 0ch 0ch 2ch",
                                        width: "100%",
                                    }}
                                >
                                    {listLength.map((i, index) => {
                                        return (
                                            <Renderlist
                                                label="JournalUsers"
                                                i={i}
                                                key={index}
                                                listLength={listLength}
                                                setListSize={setListSize}
                                                list={list}
                                            />
                                        );
                                    })}
                                    <Button
                                        endIcon={<Add />}
                                        onClick={() => {
                                            addlist();
                                        }}
                                        style={{ margin: "2ch" }}
                                    >
                                        Add Another Journal Users
                                    </Button>
                                </Box>
                            </>

                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default AddJournalUsers;
