import React, { useState } from "react";
import { Paper, Typography, Divider, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";

import TextFieldWrapper from "../../../Components/TextFieldWrapper.component";
import { caller } from "../../../utils/Caller";
import { ForgotPassURL, SendCode } from "../../../utils/config";
import { useNavigate } from "react-router";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [loading, setloading] = useState(false);
    const [page, setPage] = useState(1);
    const [userValue, setUserValue] = useState(null);
    const [codeValue, setCodeValue] = useState(null);

    // const emailRegex =
    //     /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const emailRegex =
        /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const phoneRegex = /^\d*$/gm;

    //-----------------------------------------FORM VALIDATE--------------------------------------
    const INITIALVALUE = {
        emailOrPhone: "",
    };

    const VALIDATION = Yup.object().shape({
        emailOrPhone: Yup.string("Enter Email or Phone Number")
            .required("Email or Phone Number is required")
            .test("test-name", "Enter Valid Phone/Email", function (value) {
                let isValidEmail = emailRegex.test(value);
                let isValidPhone = phoneRegex.test(value);
                if (!isValidEmail && !isValidPhone) {
                    return false;
                }
                return true;
            }),
    });

    const submitForgotPass = (user) => {
        setloading(true);
        setUserValue(user);
        try {
            caller(ForgotPassURL, {
                email: user.email,
                phone_number: user.phone,
            }).then((res) => {
                setloading(false);
                setPage(2);
            });
        } catch (error) {
            console.log(error);
        }
    };

    const sendCode = () => {
        setloading(true);
        caller(SendCode, {
            email: userValue.email,
            phone_number: userValue.phone,
            code: parseInt(codeValue),
        }).then((res) => {
            setloading(false);
            localStorage.setItem("accessToken", res.data.tempAccessToken);
            navigate("ChangePassword");
        });
    };

    return (
        <Paper
            style={{
                padding: "5ch",
                display: "flex",
                flexDirection: "column",
                gap: "2ch",
            }}
        >
            {page === 1 && (
                <Typography>
                    Please enter your email or phone number to get the reset
                    code
                </Typography>
            )}
            {page === 2 && <Typography>Please Enter the code</Typography>}
            <Divider />
            {page === 1 && (
                <Formik
                    initialValues={{ ...INITIALVALUE }}
                    validationSchema={VALIDATION}
                    onSubmit={(values, actions) => {
                        const user = {
                            email: "",
                            phone: "",
                        };
                        if (emailRegex.test(values.emailOrPhone)) {
                            user.email = values.emailOrPhone;
                        } else if (phoneRegex.test(values.emailOrPhone)) {
                            user.phone = values.emailOrPhone;
                        }
                        submitForgotPass(user);
                    }}
                >
                    <Form
                        style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <TextFieldWrapper
                            name="emailOrPhone"
                            label="Email or Phone"
                            variant="outlined"
                        />

                        <LoadingButton
                            data-testid="forgotPasswordButton"
                            loading={loading}
                            type="submit"
                            className="ButtonContained"
                            style={{
                                marginTop: "2ch",
                                justifySelf: "center",
                            }}
                        >
                            {!loading && "Get Code"}
                        </LoadingButton>
                    </Form>
                </Formik>
            )}

            {page === 2 && (
                <>
                    <TextField
                        type="number"
                        name="code"
                        label="Code"
                        variant="filled"
                        onChange={(event) => {
                            setCodeValue(event.target.value);
                        }}
                    />

                    <LoadingButton
                        loading={loading}
                        className="ButtonContained"
                        style={{
                            marginTop: "2ch",
                            alignSelf: "center",
                        }}
                        onClick={() => {
                            sendCode();
                        }}
                    >
                        {!loading && "Continue"}
                    </LoadingButton>
                </>
            )}
        </Paper>
    );
};

export default ForgotPassword;
