import { PrintRounded, CancelRounded } from "@mui/icons-material";
import { Typography, IconButton, List, ListItem, Divider } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { get } from "../../../../utils/Caller";
import { CompanyURL } from "../../../../utils/config";

const DailyReportPrint = () => {
    const { state } = useLocation();
    const { selectedReport } = state;
    const [load, setLoad] = useState(false);
    const [header, setHeader] = useState();
    const [footer, setFooter] = useState();

    useEffect(() => {
        let retry = setInterval(() => {
            if (load) {
                window.print();
                clearInterval(retry);
            }
        }, 1000);
    }, [load]);

    useEffect(() => {
        get(CompanyURL, {
            limit: 1,
        }).then((res) => {
            res &&
                res.data.forEach((data, index) => {
                    setHeader(data?.header);
                    setFooter(data?.footer);
                });
        });
    }, []);

    return (
        <div>
            <Box
                className="hide-on-print"
                style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                }}
            >
                <IconButton
                    onClick={() => {
                        window.print();
                    }}
                >
                    <PrintRounded />
                </IconButton>
                <IconButton
                    onClick={() => {
                        window.history.back();
                    }}
                >
                    <CancelRounded color="error" />
                </IconButton>
            </Box>
            <Box width={"100%"} style={{ objectFit: "scale-down" }}>
                <img
                    src={header}
                    alt=""
                    width={"100%"}
                    onLoad={() => {
                        setLoad(true);
                    }}
                />
            </Box>
            <List>
                <ListItem
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "2ch",
                    }}
                >
                    <Typography fontWeight={"bold"}>Date -</Typography>
                    <Typography>
                        {new Date(selectedReport.date).toDateString()}
                    </Typography>
                </ListItem>
                <Divider variant="middle" />
                <ListItem
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "2ch",
                    }}
                >
                    <Typography fontWeight={"bold"}>
                        Activities Performed -
                    </Typography>
                    <Typography>
                        {selectedReport.activities_performed}
                    </Typography>
                </ListItem>
                <Divider variant="middle" />
                <ListItem
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "2ch",
                    }}
                >
                    <Typography fontWeight={"bold"}>
                        Material Delivered -
                    </Typography>
                    <Typography>{selectedReport.material_delivered}</Typography>
                </ListItem>
                <Divider variant="middle" />
                <ListItem
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "2ch",
                    }}
                >
                    <Typography fontWeight={"bold"}>
                        Available Machine On Site -
                    </Typography>
                    <Typography>
                        {selectedReport.available_machine_on_site}
                    </Typography>
                </ListItem>
                <Divider variant="middle" />
                <ListItem
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "2ch",
                    }}
                >
                    <Typography fontWeight={"bold"}>
                        Problem Encountered -
                    </Typography>
                    <Typography>
                        {selectedReport.problem_encountered}
                    </Typography>
                </ListItem>
                <Divider variant="middle" />
                <ListItem
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "2ch",
                    }}
                >
                    <Typography fontWeight={"bold"}>No Of Labours -</Typography>
                    <Typography>{selectedReport.no_of_labours}</Typography>
                </ListItem>
                <Divider variant="middle" />
                <ListItem
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "2ch",
                    }}
                >
                    <Typography fontWeight={"bold"}>Remark -</Typography>
                    <Typography>{selectedReport.remark}</Typography>
                </ListItem>
                <Divider variant="middle" />
                {selectedReport.remark2 && (
                    <ListItem
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "2ch",
                        }}
                    >
                        <Typography fontWeight={"bold"}>
                            Manager's Remark -
                        </Typography>
                        <Typography>{selectedReport.remark2}</Typography>
                    </ListItem>
                )}
                <Divider variant="middle" />
                <ListItem
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "2ch",
                    }}
                >
                    <Typography fontWeight={"bold"}>Project -</Typography>
                    <Typography>{selectedReport.project?.name}</Typography>
                </ListItem>
                <Divider variant="middle" />
                <ListItem
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "2ch",
                    }}
                >
                    <Typography fontWeight={"bold"}>Todo -</Typography>
                    <Typography>
                        {selectedReport.todos.map((todo) => todo.name)}
                    </Typography>
                </ListItem>
                <Divider variant="middle" />
                <ListItem
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "2ch",
                    }}
                >
                    <Typography fontWeight={"bold"}>Employee</Typography>
                    <Typography>{`${selectedReport.employee?.first_name} ${selectedReport.employee?.last_name}`}</Typography>
                </ListItem>
            </List>
            <Box
                width={"100%"}
                style={{
                    objectFit: "scale-down",
                    position: "absolute",
                    bottom: 0,
                }}
            >
                <img src={footer} alt="" width={"100%"} />
            </Box>
        </div>
    );
};

export default DailyReportPrint;
