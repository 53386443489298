import React, { useState } from "react";
import { AccountBalance } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import { SnackBar } from "../../../../Components/SnackBar/SnackBar.component";
import {
    Box,
    Button,
    Modal,
    Paper,
    FormLabel,
    Grid,
    FormControl,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import {
    BalanceSheetExcelURL,
    CashFlowExcelURL,
    ProfitLossExcelURL,
} from "../../../../utils/config";
import { caller } from "../../../../utils/Caller";
import { FaCoins } from "react-icons/fa";
import { GiCash } from "react-icons/gi";
import { BsFileEarmarkSpreadsheetFill } from "react-icons/bs";

const BusinessOverview = () => {
    const [page, setPage] = useState(null);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setMsg] = useState("");
    const [loadingFile, setloadingFile] = useState(false);
    const [reportBasis, setReportBasis] = useState(1);
    const [exportAs, setExportAs] = useState(1);

    const REPORT_BASIS = ["Accrual", "Cash", "Both"];
    const EXPORT_AS = ["xlsx", "Pdf"];

    const Buttons = [
        { name: "Profit And Loss", icon: <FaCoins /> },
        { name: "Cash Flow", icon: <GiCash /> },
        { name: "Balance Sheet", icon: <BsFileEarmarkSpreadsheetFill /> },
    ];

    const INITIAL_VALUES = {
        from: "",
        to: "",
        reportBasis: reportBasis,
    };

    const FORM_VALIDATION = Yup.object().shape({
        from: Yup.string().min(2).required("*Required"),
        to: Yup.string().min(2).required("*Required"),
    });

    /**
     *
     * @param {number} index
     * @param {{from:string,to:string,reportBasis: number}} data
     */
    const handleButtonClick = (index, data) => {
        setloadingFile(true);
        let url = "";
        if (index === 0) {
            url = ProfitLossExcelURL;
        } else if (index === 1) {
            url = CashFlowExcelURL;
        } else if (index === 2) {
            url = BalanceSheetExcelURL;
        }

        let formatedData = {
            reportBasis: data.reportBasis,
            dateRange: [data.from, data.to],
            exportAs: EXPORT_AS[exportAs - 1],
        };

        caller(url, formatedData).then((res) => {
            setloadingFile(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data) {
                if (exportAs === 1) {
                    toExcel(res.data);
                } else {
                    toPdf(res.data);
                }
                setSuccess(true);
                setError(false);
                setTimeout(() => {
                    setOpen(false);
                }, 300);
            }
        });
    };

    const toExcel = (pdfData) => {
        const strPdf = pdfData;
        const buff = Buffer.from(strPdf);
        const excelFile = URL.createObjectURL(
            new Blob([buff], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64",
            })
        );
        window.open(excelFile, "_blank");
    };

    const toPdf = (pdfData) => {
        const strPdf = pdfData;
        const buff = Buffer.from(strPdf);
        const pdfFile = URL.createObjectURL(
            new Blob([buff], {
                type: "application/pdf",
            })
        );
        window.open(pdfFile, "_blank");
    };

    return (
        <div style={{ alignItems: "center" }}>
            <TitleHeader title="Business Overview" icon={<AccountBalance />} />
            <div
                className="FormContainer"
                style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: "2ch",
                }}
            >
                {Buttons.map((button, index) => (
                    <Button
                        className="LargeButton"
                        key={index}
                        onClick={() => {
                            setPage(index);
                            setOpen(true);
                        }}
                        startIcon={button.icon}
                    >
                        {button.name}
                    </Button>
                ))}
                <Modal
                    className="Modal"
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Paper style={{ padding: "2ch" }}>
                            <Box>
                                <Formik
                                    initialValues={{ ...INITIAL_VALUES }}
                                    validationSchema={FORM_VALIDATION}
                                    onSubmit={(values, actions) => {
                                        values.reportBasis = reportBasis;
                                        handleButtonClick(page, values);
                                    }}
                                >
                                    <Form>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <FormLabel>
                                                        From Date
                                                    </FormLabel>
                                                    <TextFieldWrapper
                                                        name="from"
                                                        type="date"
                                                        variant="outlined"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <FormLabel>
                                                        To Date
                                                    </FormLabel>
                                                    <TextFieldWrapper
                                                        name="to"
                                                        type="date"
                                                        variant="outlined"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextFieldWrapper
                                                    select
                                                    name="reportBasis"
                                                    label="Report Basis"
                                                    variant="outlined"
                                                    value={reportBasis}
                                                    options={
                                                        REPORT_BASIS ?? " "
                                                    }
                                                    onChange={(e) => {
                                                        setReportBasis(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                            <Box width={"100%"} />
                                            <Grid item xs={3}>
                                                <TextFieldWrapper
                                                    select
                                                    size="small"
                                                    name="exportAs"
                                                    label="Export As"
                                                    variant="filled"
                                                    value={exportAs}
                                                    options={EXPORT_AS ?? " "}
                                                    onChange={(e) => {
                                                        setExportAs(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <div>
                                            <LoadingButton
                                                data-testid="AddButton"
                                                loading={loadingFile}
                                                type="submit"
                                                className="ButtonContained"
                                                style={{
                                                    marginTop: "2ch",
                                                    float: "right",
                                                }}
                                            >
                                                {loadingFile
                                                    ? null
                                                    : exportAs === 1
                                                    ? "Get Excel"
                                                    : "Get PDF"}
                                            </LoadingButton>
                                        </div>
                                    </Form>
                                </Formik>
                            </Box>
                        </Paper>
                    </Box>
                </Modal>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};

export default BusinessOverview;
