import PropTypes from "prop-types";
import { Alert, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SentenceCase } from "../../utils/SentenceCase";

export const SnackBar = (props) => {
    const [open, setOpen] = useState(false);
    const [myMsg, setMyMsg] = useState("");

    const snackBarToggle = () => {
        setOpen(true);
    };

    useEffect(() => {
        if ((props.error || props.success) && props.message) {
            snackBarToggle();
            setMyMsg(props.message.split("^")[0]);
        }
    }, [props.error, props.message, props.success]);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
        >
            <Alert
                variant={"filled"}
                onClose={handleClose}
                severity={
                    props.error ? "error" : props.success ? "success" : "info"
                }
                sx={{ width: "40ch" }}
            >
                {SentenceCase(myMsg)}
            </Alert>
        </Snackbar>
    );
};

SnackBar.propTypes = {
    error: PropTypes.bool,
    /**send true if error */
    success: PropTypes.bool,
    /**send true if success */
    message: PropTypes.string,
    /**message to be displayed */
};
