import { Route } from "react-router";
import ViewAccountCategory from "../Pages/Finance/FinanceMaster/AccountCategory/ViewAccountCategory.page";
import AddAccountCategory from "../Pages/Finance/FinanceMaster/AccountCategory/AddAccountCategory.page";
import ViewAccountType from "../Pages/Finance/FinanceMaster/AccountType/ViewAccountType.page";
import AddAccountType from "../Pages/Finance/FinanceMaster/AccountType/AddAccountType.page";
import ViewClosingNote from "../Pages/Finance/FinanceMaster/ClosingNote/ViewClosingNote.page";
import AddClosingNote from "../Pages/Finance/FinanceMaster/ClosingNote/AddClosingNote.page";
import ViewFinancialStatementSection from "../Pages/Finance/FinanceMaster/FinancialStatementSection/ViewFinancialStatementSection.page";
import AddFinancialStatementSection from "../Pages/Finance/FinanceMaster/FinancialStatementSection/AddFinancialStatementSection.page";
import ViewBank from "../Pages/Finance/FinanceMaster/Bank/ViewBank.page";
import AddBank from "../Pages/Finance/FinanceMaster/Bank/AddBank.page";
import ViewContact from "../Pages/Finance/FinanceMaster/Contact/ViewContact.page";
import AddContact from "../Pages/Finance/FinanceMaster/Contact/AddContact.page";
import ViewContactAddress from "../Pages/Finance/FinanceMaster/ContactAddress/ViewContactAddress.page";
import AddContactAddress from "../Pages/Finance/FinanceMaster/ContactAddress/AddContactAddress.page";
import ViewContactPerson from "../Pages/Finance/FinanceMaster/ContactPerson/ViewContactPerson.page";
import AddContactPerson from "../Pages/Finance/FinanceMaster/ContactPerson/AddContactPerson.page";
import ViewCostCenter from "../Pages/Finance/FinanceMaster/CostCenter/ViewCostCenter.page";
import AddCostCenter from "../Pages/Finance/FinanceMaster/CostCenter/AddCostCenter.page";
import ViewCostCenterAccounts from "../Pages/Finance/FinanceMaster/CostCenterAccounts/ViewCostCenterAccounts.page";
import AddCostCenterAccounts from "../Pages/Finance/FinanceMaster/CostCenterAccounts/AddCostCenterAccounts.page";
import ViewPaymentTerm from "../Pages/Finance/FinanceMaster/PaymentTerm/ViewPaymentTerm.page";
import AddPaymentTerm from "../Pages/Finance/FinanceMaster/PaymentTerm/AddPaymentTerm.page";
import ViewExchangeRate from "../Pages/Finance/FinanceMaster/ExchangeRate/ViewExchangeRate.page";
import AddExchangeRate from "../Pages/Finance/FinanceMaster/ExchangeRate/AddExchangeRate.page";
import ViewCompanyAddress from "../Pages/Finance/FinanceMaster/CompanyAddress/ViewCompanyAddress.page";
import AddCompanyAddress from "../Pages/Finance/FinanceMaster/CompanyAddress/AddCompanyAddress.page";
import ViewDateFormatType from "../Pages/Finance/FinanceMaster/DateFormatType/ViewDateFormatType.page";
import AddDateFormatType from "../Pages/Finance/FinanceMaster/DateFormatType/AddDateFormatType.page";
import ViewFinancialSettings from "../Pages/Finance/FinanceMaster/FinancialSettings/ViewFinancialSettings.page";
import AddFinancialSettings from "../Pages/Finance/FinanceMaster/FinancialSettings/AddFinancialSettings.page";
import ViewIndustry from "../Pages/Finance/FinanceMaster/Industry/ViewIndustry.page";
import AddIndustry from "../Pages/Finance/FinanceMaster/Industry/AddIndustry.page";
import ViewJournalUsers from "../Pages/Finance/FinanceMaster/JournalUsers/ViewJournalUsers.page";
import AddJournalUsers from "../Pages/Finance/FinanceMaster/JournalUsers/AddJournalUsers.page";
import ViewPrimaryContact from "../Pages/Finance/FinanceMaster/PrimaryContact/ViewPrimaryContact.page";
import AddPrimaryContact from "../Pages/Finance/FinanceMaster/PrimaryContact/AddPrimaryContact.page";
import ViewFootNote from "../Pages/Finance/FinanceMaster/FootNote/ViewFootNote.page";
import AddFootNote from "../Pages/Finance/FinanceMaster/FootNote/AddFootNote.page";
import ViewAssociatedTaxGroup from "../Pages/Finance/FinanceMaster/AssociatedTaxGroup/ViewAssociatedTaxGroup.page";
import AddAssociatedTaxGroup from "../Pages/Finance/FinanceMaster/AssociatedTaxGroup/AddAssociatedTaxGroup.page";
import ViewTax from "../Pages/Finance/FinanceMaster/Tax/ViewTax.page";
import AddTax from "../Pages/Finance/FinanceMaster/Tax/AddTax.page";
import ViewTaxAuthority from "../Pages/Finance/FinanceMaster/TaxAuthority/ViewTaxAuthority.page";
import AddTaxAuthority from "../Pages/Finance/FinanceMaster/TaxAuthority/AddTaxAuthority.page";
import ViewTaxExemption from "../Pages/Finance/FinanceMaster/TaxExemption/ViewTaxExemption.page";
import AddTaxExemption from "../Pages/Finance/FinanceMaster/TaxExemption/AddTaxExemption.page";
import ViewTaxGroup from "../Pages/Finance/FinanceMaster/TaxGroup/ViewTaxGroup.page";
import AddTaxGroup from "../Pages/Finance/FinanceMaster/TaxGroup/AddTaxGroup.page";
import ViewTaxRule from "../Pages/Finance/FinanceMaster/TaxRule/ViewTaxRule.page";
import AddTaxRule from "../Pages/Finance/FinanceMaster/TaxRule/AddTaxRule.page";
import ViewAccountingPeriod from "../Pages/Finance/FinanceMaster/AccountingPeriod/ViewAccountingPeriod.page";
import AddAccountingPeriod from "../Pages/Finance/FinanceMaster/AccountingPeriod/AddAccountingPeriod.page";
import ViewJournalType from "../Pages/Finance/FinanceMaster/JournalType/ViewJournalType.page";
import AddJournalType from "../Pages/Finance/FinanceMaster/JournalType/AddJournalType.page";
import ViewChartOfAccount from "../Pages/Finance/FinanceOperational/ChartOfAccount/ViewChartOfAccount.page";
import AddChartOfAccount from "../Pages/Finance/FinanceOperational/ChartOfAccount/AddChartOfAccount.page";
import ViewAccountTypeFinancialStatementSection from "../Pages/Finance/FinanceOperational/AccountTypeFinancialStatementSection/ViewAccountTypeFinancialStatementSection.page";
import AddAccountTypeFinancialStatementSection from "../Pages/Finance/FinanceOperational/AccountTypeFinancialStatementSection/AddAccountTypeFinancialStatementSection.page";
import ViewBankReconcilation from "../Pages/Finance/FinanceOperational/BankReconcilation/ViewBankReconcilation.page";
import AddBankReconcilation from "../Pages/Finance/FinanceOperational/BankReconcilation/AddBankReconcilation.page";
import ViewChartOfAccountFiles from "../Pages/Finance/FinanceOperational/ChartOfAccountFiles/ViewChartOfAccountFiles.page";
import AddChartOfAccountFiles from "../Pages/Finance/FinanceOperational/ChartOfAccountFiles/AddChartOfAccountFiles.page";
import ViewReconcilationTransaction from "../Pages/Finance/FinanceOperational/ReconcilationTransaction/ViewReconcilationTransaction.page";
import AddReconcilationTransaction from "../Pages/Finance/FinanceOperational/ReconcilationTransaction/AddReconcilationTransaction.page";
import ViewAsset from "../Pages/Finance/FinanceOperational/Asset/ViewAsset.page";
import AddAsset from "../Pages/Finance/FinanceOperational/Asset/AddAsset.page";
import ViewEstimatedTotalProductionUnit from "../Pages/Finance/FinanceOperational/EstimatedTotalProductionUnit/ViewEstimatedTotalProductionUnit.page";
import AddEstimatedTotalProductionUnit from "../Pages/Finance/FinanceOperational/EstimatedTotalProductionUnit/AddEstimatedTotalProductionUnit.page";
import ViewRecurringJournalOccurrence from "../Pages/Finance/FinanceOperational/RecurringJournalOccurrence/ViewRecurringJournalOccurrence.page";
import AddRecurringJournalOccurrence from "../Pages/Finance/FinanceOperational/RecurringJournalOccurrence/AddRecurringJournalOccurrence.page";
import ViewBudget from "../Pages/Finance/FinanceOperational/Budget/ViewBudget.page";
import AddBudget from "../Pages/Finance/FinanceOperational/Budget/AddBudget.page";
import ViewBudgetAccount from "../Pages/Finance/FinanceOperational/BudgetAccount/ViewBudgetAccount.page";
import AddBudgetAccount from "../Pages/Finance/FinanceOperational/BudgetAccount/AddBudgetAccount.page";
import ViewBudgetAccountPeriod from "../Pages/Finance/FinanceOperational/BudgetAccountPeriod/ViewBudgetAccountPeriod.page";
import AddBudgetAccountPeriod from "../Pages/Finance/FinanceOperational/BudgetAccountPeriod/AddBudgetAccountPeriod.page";
import ViewBudgetControlAction from "../Pages/Finance/FinanceOperational/BudgetControlAction/ViewBudgetControlAction.page";
import AddBudgetControlAction from "../Pages/Finance/FinanceOperational/BudgetControlAction/AddBudgetControlAction.page";
import ViewGeneralJournalDetail from "../Pages/Finance/FinanceOperational/GeneralJournalDetail/ViewGeneralJournalDetail.page";
import AddGeneralJournalDetail from "../Pages/Finance/FinanceOperational/GeneralJournalDetail/AddGeneralJournalDetail.page";
import ViewGeneralJournalFiles from "../Pages/Finance/FinanceOperational/GeneralJournalFiles/ViewGeneralJournalFiles.page";
import AddGeneralJournalFiles from "../Pages/Finance/FinanceOperational/GeneralJournalFiles/AddGeneralJournalFiles.page";
import ViewGeneralJournalHeader from "../Pages/Finance/FinanceOperational/GeneralJournalHeader/ViewGeneralJournalHeader.page";
import AddGeneralJournalHeader from "../Pages/Finance/FinanceOperational/GeneralJournalHeader/AddGeneralJournalHeader.page";
import ViewGeneralLedger from "../Pages/Finance/FinanceOperational/GeneralLedger/ViewGeneralLedger.page";
import AddGeneralLedger from "../Pages/Finance/FinanceOperational/GeneralLedger/AddGeneralLedger.page";
import ViewJournalComment from "../Pages/Finance/FinanceOperational/JournalComment/ViewJournalComment.page";
import AddJournalComment from "../Pages/Finance/FinanceOperational/JournalComment/AddJournalComment.page";
import ViewNumberTracker from "../Pages/Finance/FinanceOperational/NumberTracker/ViewNumberTracker.page";
import AddNumberTracker from "../Pages/Finance/FinanceOperational/NumberTracker/AddNumberTracker.page";
import ViewRecurringGeneralJournal from "../Pages/Finance/FinanceOperational/RecurringGeneralJournal/ViewRecurringGeneralJournal.page";
import AddRecurringGeneralJournal from "../Pages/Finance/FinanceOperational/RecurringGeneralJournal/AddRecurringGeneralJournal.page";
import ViewTransactionLock from "../Pages/Finance/FinanceOperational/TransactionLock/ViewTransactionLock.page";
import AddTransactionLock from "../Pages/Finance/FinanceOperational/TransactionLock/AddTransactionLock.page";
import ViewOpeningBalance from "../Pages/Finance/FinanceOperational/OpeningBalance/ViewOpeningBalance.page";
import AddOpeningBalance from "../Pages/Finance/FinanceOperational/OpeningBalance/AddOpeningBalance.page";
import ViewCashPaymentCustom from "../Pages/Finance/FinanceOperational/CashPaymentCustom/ViewCashPaymentCustom.page";
import AddCashPaymentCustom from "../Pages/Finance/FinanceOperational/CashPaymentCustom/AddCashPaymentCustom.page";
import ViewCashPaymentVoucher from "../Pages/Finance/FinanceOperational/CashPaymentVoucher/ViewCashPaymentVoucher.page";
import AddCashPaymentVoucher from "../Pages/Finance/FinanceOperational/CashPaymentVoucher/AddCashPaymentVoucher.page";
import ViewCpvBank from "../Pages/Finance/FinanceOperational/CpvBank/ViewCpvBank.page";
import AddCpvBank from "../Pages/Finance/FinanceOperational/CpvBank/AddCpvBank.page";
import ViewCpvPaymentRequest from "../Pages/Finance/FinanceOperational/CpvPaymentRequest/ViewCpvPaymentRequest.page";
import AddCpvPaymentRequest from "../Pages/Finance/FinanceOperational/CpvPaymentRequest/AddCpvPaymentRequest.page";
import ViewCpvReceipt from "../Pages/Finance/FinanceOperational/CpvReceipt/ViewCpvReceipt.page";
import AddCpvReceipt from "../Pages/Finance/FinanceOperational/CpvReceipt/AddCpvReceipt.page";
import ViewCpvType from "../Pages/Finance/FinanceOperational/CpvType/ViewCpvType.page";
import AddCpvType from "../Pages/Finance/FinanceOperational/CpvType/AddCpvType.page";
import ViewPettyCash from "../Pages/Finance/FinanceOperational/PettyCash/ViewPettyCash.page";
import AddPettyCash from "../Pages/Finance/FinanceOperational/PettyCash/AddPettyCash.page";
import ViewCrvPayment from "../Pages/Finance/FinanceOperational/CrvPayment/ViewCrvPayment.page";
import AddCrvPayment from "../Pages/Finance/FinanceOperational/CrvPayment/AddCrvPayment.page";
import ViewCrvType from "../Pages/Finance/FinanceOperational/CrvType/ViewCrvType.page";
import AddCrvType from "../Pages/Finance/FinanceOperational/CrvType/AddCrvType.page";
import AccountantExport from "../Pages/Finance/FinanceOperational/Accountant Export/AccountantExport.page";
import BusinessOverview from "../Pages/Finance/FinanceOperational/BusinessOverview/BusinessOverview.page";
import ViewPVRequest from "../Pages/Finance/FinanceOperational/PVRequest/ViewPVRequest.page";
import AddPVRequest from "../Pages/Finance/FinanceOperational/PVRequest/AddPVRequest.page";
import FinanceApproval from "../Pages/Finance/FinanceApproval.page";

export const FinanceRoutes = [
    <Route path="Finance">
        {/* =====================================FINANCE MASTER================================ */}
        <Route path="FinanceApproval" element={<FinanceApproval/>}/>
        <Route path="AccountCategory" element={<ViewAccountCategory />}>
            <Route path="AddAccountCategory" element={<AddAccountCategory />} />
        </Route>
        <Route path="PVRequest" element={<ViewPVRequest />}>
            <Route path="AddPVRequest" element={<AddPVRequest />} />
        </Route>
        <Route path="AccountType" element={<ViewAccountType />}>
            <Route path="AddAccountType" element={<AddAccountType />} />
        </Route>
        <Route path="ClosingNote" element={<ViewClosingNote />}>
            <Route path="AddClosingNote" element={<AddClosingNote />} />
        </Route>
        <Route
            path="FinancialStatementSection"
            element={<ViewFinancialStatementSection />}
        >
            <Route
                path="AddFinancialStatementSection"
                element={<AddFinancialStatementSection />}
            />
        </Route>
        <Route path="Bank" element={<ViewBank />}>
            <Route path="AddBank" element={<AddBank />} />
        </Route>
        <Route path="Contact" element={<ViewContact />}>
            <Route path="AddContact" element={<AddContact />} />
        </Route>
        <Route path="ContactAddress" element={<ViewContactAddress />}>
            <Route path="AddContactAddress" element={<AddContactAddress />} />
        </Route>
        <Route path="ContactPerson" element={<ViewContactPerson />}>
            <Route path="AddContactPerson" element={<AddContactPerson />} />
        </Route>
        <Route path="CostCenter" element={<ViewCostCenter />}>
            <Route path="AddCostCenter" element={<AddCostCenter />} />
        </Route>
        <Route path="CostCenterAccounts" element={<ViewCostCenterAccounts />}>
            <Route
                path="AddCostCenterAccounts"
                element={<AddCostCenterAccounts />}
            />
        </Route>
        <Route path="PaymentTerm" element={<ViewPaymentTerm />}>
            <Route path="AddPaymentTerm" element={<AddPaymentTerm />} />
        </Route>
        <Route path="ExchangeRate" element={<ViewExchangeRate />}>
            <Route path="AddExchangeRate" element={<AddExchangeRate />} />
        </Route>
        <Route path="CompanyAddress" element={<ViewCompanyAddress />}>
            <Route path="AddCompanyAddress" element={<AddCompanyAddress />} />
        </Route>
        <Route path="DateFormatType" element={<ViewDateFormatType />}>
            <Route path="AddDateFormatType" element={<AddDateFormatType />} />
        </Route>
        <Route path="FinancialSettings" element={<ViewFinancialSettings />}>
            <Route
                path="AddFinancialSettings"
                element={<AddFinancialSettings />}
            />
        </Route>
        <Route path="Industry" element={<ViewIndustry />}>
            <Route path="AddIndustry" element={<AddIndustry />} />
        </Route>
        <Route path="JournalUsers" element={<ViewJournalUsers />}>
            <Route path="AddJournalUsers" element={<AddJournalUsers />} />
        </Route>
        <Route path="PrimaryContact" element={<ViewPrimaryContact />}>
            <Route path="AddPrimaryContact" element={<AddPrimaryContact />} />
        </Route>
        <Route path="FootNote" element={<ViewFootNote />}>
            <Route path="AddFootNote" element={<AddFootNote />} />
        </Route>
        <Route path="AssociatedTaxGroup" element={<ViewAssociatedTaxGroup />}>
            <Route
                path="AddAssociatedTaxGroup"
                element={<AddAssociatedTaxGroup />}
            />
        </Route>
        <Route path="Tax" element={<ViewTax />}>
            <Route path="AddTax" element={<AddTax />} />
        </Route>
        <Route path="TaxAuthority" element={<ViewTaxAuthority />}>
            <Route path="AddTaxAuthority" element={<AddTaxAuthority />} />
        </Route>
        <Route path="TaxExemption" element={<ViewTaxExemption />}>
            <Route path="AddTaxExemption" element={<AddTaxExemption />} />
        </Route>
        <Route path="TaxGroup" element={<ViewTaxGroup />}>
            <Route path="AddTaxGroup" element={<AddTaxGroup />} />
        </Route>
        <Route path="TaxRule" element={<ViewTaxRule />}>
            <Route path="AddTaxRule" element={<AddTaxRule />} />
        </Route>
        <Route path="AccountingPeriod" element={<ViewAccountingPeriod />}>
            <Route
                path="AddAccountingPeriod"
                element={<AddAccountingPeriod />}
            />
        </Route>
        <Route path="JournalType" element={<ViewJournalType />}>
            <Route path="AddJournalType" element={<AddJournalType />} />
        </Route>

        {/* =====================================FINANCE OPERATIONS=============================================== */}

        <Route path="AccountantExport" element={<AccountantExport />} />
        <Route path="BusinessOverview" element={<BusinessOverview />} />

        <Route path="ChartOfAccount" element={<ViewChartOfAccount />}>
            <Route path="AddChartOfAccount" element={<AddChartOfAccount />} />
        </Route>
        <Route
            path="AccountTypeFinancialStatementSection"
            element={<ViewAccountTypeFinancialStatementSection />}
        >
            <Route
                path="AddAccountTypeFinancialStatementSection"
                element={<AddAccountTypeFinancialStatementSection />}
            />
        </Route>
        <Route path="BankReconcilation" element={<ViewBankReconcilation />}>
            <Route
                path="AddBankReconcilation"
                element={<AddBankReconcilation />}
            />
        </Route>
        <Route path="ChartOfAccountFiles" element={<ViewChartOfAccountFiles />}>
            <Route
                path="AddChartOfAccountFiles"
                element={<AddChartOfAccountFiles />}
            />
        </Route>
        <Route
            path="ReconcilationTransaction"
            element={<ViewReconcilationTransaction />}
        >
            <Route
                path="AddReconcilationTransaction"
                element={<AddReconcilationTransaction />}
            />
        </Route>
        <Route path="Asset" element={<ViewAsset />}>
            <Route path="AddAsset" element={<AddAsset />} />
        </Route>
        <Route
            path="EstimatedTotalProductionUnit"
            element={<ViewEstimatedTotalProductionUnit />}
        >
            <Route
                path="AddEstimatedTotalProductionUnit"
                element={<AddEstimatedTotalProductionUnit />}
            />
        </Route>
        <Route
            path="RecurringJournalOccurrence"
            element={<ViewRecurringJournalOccurrence />}
        >
            <Route
                path="AddRecurringJournalOccurrence"
                element={<AddRecurringJournalOccurrence />}
            />
        </Route>
        <Route path="Budget" element={<ViewBudget />}>
            <Route path="AddBudget" element={<AddBudget />} />
        </Route>
        <Route path="BudgetAccount" element={<ViewBudgetAccount />}>
            <Route path="AddBudgetAccount" element={<AddBudgetAccount />} />
        </Route>
        <Route path="BudgetAccountPeriod" element={<ViewBudgetAccountPeriod />}>
            <Route
                path="AddBudgetAccountPeriod"
                element={<AddBudgetAccountPeriod />}
            />
        </Route>
        <Route path="BudgetControlAction" element={<ViewBudgetControlAction />}>
            <Route
                path="AddBudgetControlAction"
                element={<AddBudgetControlAction />}
            />
        </Route>
        <Route
            path="GeneralJournalDetail"
            element={<ViewGeneralJournalDetail />}
        >
            <Route
                path="AddGeneralJournalDetail"
                element={<AddGeneralJournalDetail />}
            />
        </Route>
        <Route path="GeneralJournalFiles" element={<ViewGeneralJournalFiles />}>
            <Route
                path="AddGeneralJournalFiles"
                element={<AddGeneralJournalFiles />}
            />
        </Route>
        <Route
            path="GeneralJournalHeader"
            element={<ViewGeneralJournalHeader />}
        >
            <Route
                path="AddGeneralJournalHeader"
                element={<AddGeneralJournalHeader />}
            />
        </Route>
        <Route path="GeneralLedger" element={<ViewGeneralLedger />}>
            <Route path="AddGeneralLedger" element={<AddGeneralLedger />} />
        </Route>
        <Route path="JournalComment" element={<ViewJournalComment />}>
            <Route path="AddJournalComment" element={<AddJournalComment />} />
        </Route>
        <Route path="NumberTracker" element={<ViewNumberTracker />}>
            <Route path="AddNumberTracker" element={<AddNumberTracker />} />
        </Route>
        <Route
            path="RecurringGeneralJournal"
            element={<ViewRecurringGeneralJournal />}
        >
            <Route
                path="AddRecurringGeneralJournal"
                element={<AddRecurringGeneralJournal />}
            />
        </Route>
        <Route path="TransactionLock" element={<ViewTransactionLock />}>
            <Route path="AddTransactionLock" element={<AddTransactionLock />} />
        </Route>
        <Route path="OpeningBalance" element={<ViewOpeningBalance />}>
            <Route path="AddOpeningBalance" element={<AddOpeningBalance />} />
        </Route>
        <Route path="CashPaymentCustom" element={<ViewCashPaymentCustom />}>
            <Route
                path="AddCashPaymentCustom"
                element={<AddCashPaymentCustom />}
            />
        </Route>
        <Route path="CashPaymentVoucher" element={<ViewCashPaymentVoucher />}>
            <Route
                path="AddCashPaymentVoucher"
                element={<AddCashPaymentVoucher />}
            />
        </Route>
        <Route path="CpvBank" element={<ViewCpvBank />}>
            <Route path="AddCpvBank" element={<AddCpvBank />} />
        </Route>
        <Route path="CpvPaymentRequest" element={<ViewCpvPaymentRequest />}>
            <Route
                path="AddCpvPaymentRequest"
                element={<AddCpvPaymentRequest />}
            />
        </Route>
        <Route path="CpvReceipt" element={<ViewCpvReceipt />}>
            <Route path="AddCpvReceipt" element={<AddCpvReceipt />} />
        </Route>
        <Route path="CpvType" element={<ViewCpvType />}>
            <Route path="AddCpvType" element={<AddCpvType />} />
        </Route>
        <Route path="PettyCash" element={<ViewPettyCash />}>
            <Route path="AddPettyCash" element={<AddPettyCash />} />
        </Route>
        <Route path="CrvPayment" element={<ViewCrvPayment />}>
            <Route path="AddCrvPayment" element={<AddCrvPayment />} />
        </Route>
        <Route path="CrvType" element={<ViewCrvType />}>
            <Route path="AddCrvType" element={<AddCrvType />} />
        </Route>
    </Route>,
];
