import React, { useState } from "react";
import Header from "./Header/Header.component";
import SideBar from "./SideBar/SideBar.component";

import "../../Assets/Sass/MainScene.style.scss";
import Body from "./Body/Body.componet";
import { Box, Paper, Typography } from "@mui/material";
import { useLocation } from "react-router";
import SideBarMenus from "./SideBar/SideBarMenus.json";

const MainScene = ({ user }) => {
    const [menuToggle, setMenuToggle] = useState(false);
    const location = useLocation();
    console.log(user);
    const menuToggleHandler = () => {
        setMenuToggle(!menuToggle);
    };

    return (
        <Paper className="MainScene">
            {user?.actions?.find((role) => {
                if (role === "PROJECT_ONE") {
                    return true;
                } else return false;
            }) &&
                Boolean(
                    location.pathname.match(
                        new RegExp(
                            `${SideBarMenus.MasterPath}`.replaceAll(",", "|"),
                        ),
                    ),
                ) && (
                    <Box
                        width={"100%"}
                        height={"3ch"}
                        style={{
                            background: "red",
                            position: "sticky",
                            top: "0",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Typography color={"white"}>
                            You are now editing sensitive data, Please be sure
                            before making any changes.
                        </Typography>
                    </Box>
                )}

            <Header setMenuToggle={menuToggleHandler} />
            <Paper className="BodyContainer">
                <SideBar
                    menuToggle={menuToggle}
                    setMenuToggle={menuToggleHandler}
                />
                <Body />
            </Paper>
        </Paper>
    );
};

export default MainScene;
