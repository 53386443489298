import { Button, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";

import { SnackBar } from "../../Components/SnackBar/SnackBar.component";
import { setRefresh } from "../../Redux/Slice/refreshSlice";
import { deleteProject } from "../../Pages/Projects/DeleteProject.page";
import { deleteMaterial } from "../../Pages/MasterData/Material/deleteMaterial";
import { deleteEquipment } from "../../Pages/MasterData/Equipment/deleteEquipment";
import { deleteEvaluation } from "../../Pages/MasterData/Evaluation/deleteEvaluation";
import { deleteInstruction } from "../../Pages/MasterData/Instruction/deleteInstruction";
import { deleteManpower } from "../../Pages/MasterData/Manpower/deleteManpower";
import { deleteMaterialCategory } from "../../Pages/MasterData/MaterialCategory/deleteMaterialCategory";
import { deletePhase } from "../../Pages/MasterData/Phase/deletePhase";
import { deleteWorkCategory } from "../../Pages/MasterData/WorkCategory/deleteWorkCategory";
import { deletePriority } from "../../Pages/MasterData/Priority/deletePriority";
import { deleteWorkLog } from "../../Pages/Projects/Operational/WorkLogAndBranchOfWork/deleteWorkLog";
import { deleteDocumentCategory } from "../../Pages/MasterData/DocumentCategory/deleteDocumentCategory";
import { deleteDocumentation } from "../../Pages/MasterData/Documentation/deleteDocumentation";
import { deleteDailyWorkLog } from "../../Pages/Projects/Operational/DailyWorkLog/deleteDailyWorkLog";
import { deleteInstructionGiven } from "../../Pages/Projects/Operational/InstructionGivenOnProject/deleteInstructionGiven";
import { deleteInvoiceTracking } from "../../Pages/Projects/Operational/InvoiceTracking/deleteInvoiceTracking";
import { deleteManpowerRequired } from "../../Pages/Projects/Operational/ManpowerRequirement/deleteManpowerRequirement";
import { deleteRequest } from "../../Pages/Projects/Operational/Request/deleteRequest";
import { deleteRequiredEquipment } from "../../Pages/Projects/Operational/RequiredEquipment/deleteRequiredEquipment";
import { deleteRequiredMaterial } from "../../Pages/Projects/Operational/RequiredMaterial/deleteRequiredMaterial";
import { deleteRiskTracking } from "../../Pages/Projects/Operational/RiskTracking/deleteRiskTracking";
import { deleteSimpleTask } from "../../Pages/Projects/Operational/SimpleTask/deleteSimpleTask";
import { deleteSubTask } from "../../Pages/Projects/Operational/SubTask/deleteSubTask";
import { deleteTaskManager } from "../../Pages/Projects/Operational/TaskManager/deleteTaskManager";
import { deleteToDos } from "../../Pages/Projects/Operational/ToDos/deleteToDos";
import { deleteWeatherData } from "../../Pages/Projects/Operational/WeatherData/deleteWeatherData";
import { deleteRequestPayment } from "../../Pages/Projects/Operational/RequestPayment/deleteRequestPayment";
import { deleteCurrency } from "../../Pages/HCM/MasterData/Currency/deleteCurrency";
import { deleteCountry } from "../../Pages/HCM/MasterData/Country/deleteCountry";
import { deleteTitle } from "../../Pages/HCM/MasterData/Title/deleteTitle";
import { deleteLanguage } from "../../Pages/HCM/MasterData/Language/deleteLanguage";
import { deleteActionReason } from "../../Pages/HCM/MasterData/ActionReason/deleteActionReason";
import { deleteActionType } from "../../Pages/HCM/MasterData/ActionType/deleteActionType";
import { deleteAddressType } from "../../Pages/HCM/MasterData/AddressType/deleteAddressType";
import { deleteCommitment } from "../../Pages/HCM/MasterData/Commitment/deleteCommitment";
import { deleteCustomReport } from "../../Pages/HCM/MasterData/CustomReport/deleteCustomReport";
import { deleteDisciplineCaseType } from "../../Pages/HCM/MasterData/DisciplineCaseType/deleteDisciplineCaseType";
import { deleteDurationMeasure } from "../../Pages/HCM/MasterData/DurationMeasure/deleteDurationMeasure";
import { deleteEmployeeGroup } from "../../Pages/HCM/MasterData/EmployeeGroup/deleteEmployeeGroup";
import { deleteEmployeeType } from "../../Pages/HCM/MasterData/EmployeeType/deleteEmployeeType";
import { deleteFieldOfStudy } from "../../Pages/HCM/MasterData/FieldOfStudy/deleteFieldOfStudy";
import { deleteHcmConfiguration } from "../../Pages/HCM/MasterData/HCMConfiguration/deleteHCMConfiguration";
import { deleteEmployeeTransactionLock } from "../../Pages/HCM/MasterData/EmployeeTransactionLock/deleteEmployeeTransactionLock";
import { deleteLicenseType } from "../../Pages/HCM/MasterData/LicenseType/deleteLicenseType";
import { deleteNationality } from "../../Pages/HCM/MasterData/Nationality/deleteNationality";
import { deleteReligion } from "../../Pages/HCM/MasterData/Religion/deleteReligion";
import { deleteTrainingType } from "../../Pages/HCM/MasterData/TrainingType/deleteTrainingType";
import { deleteBusinessUnit } from "../../Pages/HCM/CompanyStructure/BusinessUnit/deleteBusinessUnit";
import { deleteCompany } from "../../Pages/HCM/CompanyStructure/Company/deleteCompany";
import { deleteLocation } from "../../Pages/HCM/CompanyStructure/Location/deleteLocation";
import { deleteEmployeeBackPenalityDeduction } from "../../Pages/HCM/Payroll/EmployeeBackPenalityDeduction/deleteEmployeeBackPenalityDeduction";
import { deleteEmployeePayScale } from "../../Pages/HCM/Payroll/EmployeePayScale/deleteEmployeePayScale";
import { deleteEmployeePenality } from "../../Pages/HCM/Payroll/EmployeePenality/deleteEmployeePenality";
import { deleteEmployeePenalityDetailMst } from "../../Pages/HCM/Payroll/EmployeePenalityDetailMst/deleteEmployeePenalityDetailMst";
import { deleteEmployeePenalityType } from "../../Pages/HCM/Payroll/EmployeePenalityType/deleteEmployeePenalityType";
import { deleteEmployeeTax } from "../../Pages/HCM/Payroll/EmployeeTax/deleteEmployeeTax";
import { deleteGlobalPayrollAccountMapping } from "../../Pages/HCM/Payroll/GlobalPayrollAccountMapping/deleteGlobalPayrollAccountMapping";
import { deleteOvertime } from "../../Pages/HCM/Payroll/Overtime/deleteOvertime";
import { deleteOvertimeRate } from "../../Pages/HCM/Payroll/OvertimeRate/deleteOvertimeRate";
import { deletePaygrade } from "../../Pages/HCM/Payroll/Paygrade/deletePaygrade";
import { deletePaygradeSalaryComponent } from "../../Pages/HCM/Payroll/PaygradeSalaryComponent/deletePaygradeSalaryComponent";
import { deletePaygradeScale } from "../../Pages/HCM/Payroll/PaygradeScale/deletePaygradeScale";
import { deletePayrollDetail } from "../../Pages/HCM/Payroll/PayrollDetail/deletePayrollDetail";
import { deletePayrollDetailHistory } from "../../Pages/HCM/Payroll/PayrollDetailHistory/deletePayrollDetailHistory";
import { deletePayrollFrequencyType } from "../../Pages/HCM/Payroll/PayrollFrequencyType/deletePayrollFrequencyType";
import { deletePayrollLocationSetting } from "../../Pages/HCM/Payroll/PayrollLocationSetting/deletePayrollLocationSetting";
import { deletePayrollLog } from "../../Pages/HCM/Payroll/PayrollLog/deletePayrollLog";
import { deletePayrollLogEmployee } from "../../Pages/HCM/Payroll/PayrollLogEmployee/deletePayrollLogEmployee";
import { deletePayrollPeriodAutogen } from "../../Pages/HCM/Payroll/PayrollPeriodAutogen/deletePayrollPeriodAutogen";
import { deletePayrollPeriodAutogenLog } from "../../Pages/HCM/Payroll/PayrollPeriodAutogenLog/deletePayrollPeriodAutogenLog";
import { deletePayrollPeriodTemplate } from "../../Pages/HCM/Payroll/PayrollPeriodTemplate/deletePayrollPeriodTemplate";
import { deletePayrollPostingLog } from "../../Pages/HCM/Payroll/PayrollPostingLog/deletePayrollPostingLog";
import { deletePayrollProcessingLog } from "../../Pages/HCM/Payroll/PayrollProcessingLog/deletePayrollProcessingLog";
import { deletePayrollSummary } from "../../Pages/HCM/Payroll/PayrollSummary/deletePayrollSummary";
import { deletePayrollSummaryHistory } from "../../Pages/HCM/Payroll/PayrollSummaryHistory/deletePayrollSummaryHistory";
import { deletePayScaleHistoryLog } from "../../Pages/HCM/Payroll/PayScaleHistoryLog/deletePayScaleHistoryLog";
import { deleteSalaryAdjustment } from "../../Pages/HCM/Payroll/SalaryAdjustment/deleteSalaryAdjustment";
import { deleteSalaryComponent } from "../../Pages/HCM/Payroll/SalaryComponent/deleteSalaryComponent";
import { deleteSalaryComponentAccountMapping } from "../../Pages/HCM/Payroll/SalaryComponentAccountMapping/deleteSalaryComponentAccountMapping";
import { deleteAddress } from "../../Pages/HCM/EmployeeMaster/Address/deleteAddress";
import { deleteAttachment } from "../../Pages/HCM/EmployeeMaster/Attachment/deleteAttachment";
import { deleteDependent } from "../../Pages/HCM/EmployeeMaster/Dependent/deleteDependent";
import { deleteDiscipline } from "../../Pages/HCM/EmployeeMaster/Discipline/deleteDiscipline";
import { deleteEducation } from "../../Pages/HCM/EmployeeMaster/Education/deleteEducation";
import { deleteEmployee } from "../../Pages/HCM/EmployeeMaster/Employee/deleteEmployee";
import { deleteEmployeeAction } from "../../Pages/HCM/EmployeeMaster/EmployeeAction/deleteEmployeeAction";
import { deleteEmployeeAttachment } from "../../Pages/HCM/EmployeeMaster/EmployeeAttachment/deleteEmployeeAttachment";
import { deleteEmployeeCommitment } from "../../Pages/HCM/EmployeeMaster/EmployeeCommitment/deleteEmployeeCommitment";
import { deleteEmployeeContact } from "../../Pages/HCM/EmployeeMaster/EmployeeContact/deleteEmployeeContact";
import { deleteEmployeeIdRange } from "../../Pages/HCM/EmployeeMaster/EmployeeIdRange/deleteEmployeeIdRange";
import { deleteEmployeeLoanRepayment } from "../../Pages/HCM/EmployeeMaster/EmployeeLoanRepayment/deleteEmployeeLoanRepayment";
import { deleteEmployeePayFrequency } from "../../Pages/HCM/EmployeeMaster/EmployeePayFrequency/deleteEmployeePayFrequency";
import { deleteEmployeePaygrade } from "../../Pages/HCM/EmployeeMaster/EmployeePaygrade/deleteEmployeePaygrade";
import { deleteEmployeeSalaryComponent } from "../../Pages/HCM/EmployeeMaster/EmployeeSalaryComponent/deleteEmployeeSalaryComponent";
import { deleteExperience } from "../../Pages/HCM/EmployeeMaster/Experience/deleteExperience";
import { deleteLeaveAssignment } from "../../Pages/HCM/EmployeeMaster/LeaveAssignment/deleteLeaveAssignment";
import { deleteLeaveEntitlement } from "../../Pages/HCM/EmployeeMaster/LeaveEntitlement/deleteLeaveEntitlement";
import { deleteLeaveTransfer } from "../../Pages/HCM/EmployeeMaster/LeaveTransfer/deleteLeaveTransfer";
import { deleteLicense } from "../../Pages/HCM/EmployeeMaster/License/deleteLicense";
import { deleteOrgAssignment } from "../../Pages/HCM/EmployeeMaster/OrgAssignment/deleteOrgAssignment";
import { deleteServiceAllowancePay } from "../../Pages/HCM/EmployeeMaster/ServiceAllowancePay/deleteServiceAllowancePay";
import { deleteShiftAssignment } from "../../Pages/HCM/EmployeeMaster/ShiftAssignment/deleteShiftAssignment";
import { deleteJobCategory } from "../../Pages/HCM/JobPositions/JobCategory/deleteJobCategory";
import { deleteJobSafetyEquipment } from "../../Pages/HCM/JobPositions/JobSafetyEquipment/deleteJobSafetyEquipment";
import { deleteJobTitle } from "../../Pages/HCM/JobPositions/JobTitle/deleteJobTitle";
import { deleteSafetyEquipment } from "../../Pages/HCM/JobPositions/SafetyEquipment/deleteSafetyEquipment";
import { deleteExternalApplicant } from "../../Pages/HCM/Recruitment/ExternalApplicant/deleteExternalApplicant";
import { deleteVacancy } from "../../Pages/HCM/Recruitment/Vacancy/deleteVacancy";
import { deleteVacancyResult } from "../../Pages/HCM/Recruitment/VacancyResult/deleteVacancyResult";
import { deleteVacancyExaminer } from "../../Pages/HCM/Recruitment/VacancyExaminer/deleteVacancyExaminer";
import { deleteVacancyInternalApplicant } from "../../Pages/HCM/Recruitment/VacancyInternalApplicant/deleteVacancyInternalApplicant";
import { deleteVacancyRequestReason } from "../../Pages/HCM/Recruitment/VacancyRequestReason/deleteVacancyRequestReason";
import { deleteAttendanceAbscenceType } from "../../Pages/HCM/TimeAndLeave/AttendanceAbscenceType/deleteAttendanceAbscenceType";
import { deleteAttendanceCaptured } from "../../Pages/HCM/TimeAndLeave/AttendanceCaptured/deleteAttendanceCaptured";
import { deleteAttendancePayroll } from "../../Pages/HCM/TimeAndLeave/AttendancePayroll/deleteAttendancePayroll";
import { deleteDeviceIdMapping } from "../../Pages/HCM/TimeAndLeave/DeviceIdMapping/deleteDeviceIdMapping";
import { deleteHoliday } from "../../Pages/HCM/TimeAndLeave/Holiday/deleteHoliday";
import { deleteHolidayCharacter } from "../../Pages/HCM/TimeAndLeave/HolidayCharacter/deleteHolidayCharacter";
import { deleteLeavePeriod } from "../../Pages/HCM/TimeAndLeave/LeavePeriod/deleteLeavePeriod";
import { deleteLeavePlan } from "../../Pages/HCM/TimeAndLeave/LeavePlan/deleteLeavePlan";
import { deletePunch } from "../../Pages/HCM/TimeAndLeave/Punch/deletePunch";
import { deletePunchDevice } from "../../Pages/HCM/TimeAndLeave/PunchDevice/deletePunchDevice";
import { deletePunchLog } from "../../Pages/HCM/TimeAndLeave/PunchLog/deletePunchLog";
import { deletePunchManualImport } from "../../Pages/HCM/TimeAndLeave/PunchManualImport/deletePunchManualImport";
import { deletePunchTime } from "../../Pages/HCM/TimeAndLeave/PunchTime/deletePunchTime";
import { deleteShiftScheduleDtl } from "../../Pages/HCM/TimeAndLeave/ShiftScheduleDtl/deleteShiftScheduleDtl";
import { deleteShiftScheduleHdr } from "../../Pages/HCM/TimeAndLeave/ShiftScheduleHdr/deleteShiftScheduleHdr";
import { deleteSubShiftGroup } from "../../Pages/HCM/TimeAndLeave/SubShiftGroup/deleteSubShiftGroup";
import { deleteCompanyPrimaryContact } from "../../Pages/HCM/CompanyStructure/CompanyPrimaryContact/deleteCompanyPrimaryContact";
import { deleteUnitOfMeasure } from "../../Pages/Inventory/InventoryMaster/UnitOfMeasure/deleteUnitOfMeasure";
import { deleteWarehouse } from "../../Pages/Inventory/InventoryMaster/Warehouse/deleteWarehouse";
import { deleteStockBatch } from "../../Pages/Inventory/InventoryStock/StockBatch/deleteStockBatch";
import { deleteStockItem } from "../../Pages/Inventory/InventoryStock/StockItem/deleteStockItem";
import { deleteStockTransactionDetail } from "../../Pages/Inventory/InventoryStock/StockTransactionDetail/deleteStockTransactionDetail";
import { deleteStockTransactionHeader } from "../../Pages/Inventory/InventoryStock/StockTransactionHeader/deleteStockTransactionHeader";
import { deleteAccountCategory } from "../../Pages/Finance/FinanceMaster/AccountCategory/deleteAccountCategory";
import { deleteAccountType } from "../../Pages/Finance/FinanceMaster/AccountType/deleteAccountType";
import { deleteClosingNote } from "../../Pages/Finance/FinanceMaster/ClosingNote/deleteClosingNote";
import { deleteFinancialStatementSection } from "../../Pages/Finance/FinanceMaster/FinancialStatementSection/deleteFinancialStatementSection";
import { deleteBank } from "../../Pages/Finance/FinanceMaster/Bank/deleteBank";
import { deleteContact } from "../../Pages/Finance/FinanceMaster/Contact/deleteContact";
import { deleteContactAddress } from "../../Pages/Finance/FinanceMaster/ContactAddress/deleteContactAddress";
import { deleteContactPerson } from "../../Pages/Finance/FinanceMaster/ContactPerson/deleteContactPerson";
import { deleteCostCenter } from "../../Pages/Finance/FinanceMaster/CostCenter/deleteCostCenter";
import { deleteCostCenterAccounts } from "../../Pages/Finance/FinanceMaster/CostCenterAccounts/deleteCostCenterAccounts";
import { deletePaymentTerm } from "../../Pages/Finance/FinanceMaster/PaymentTerm/deletePaymentTerm";
import { deleteExchangeRate } from "../../Pages/Finance/FinanceMaster/ExchangeRate/deleteExchangeRate";
import { deleteCompanyAddress } from "../../Pages/Finance/FinanceMaster/CompanyAddress/deleteCompanyAddress";
import { deleteDateFormatType } from "../../Pages/Finance/FinanceMaster/DateFormatType/deleteDateFormatType";
import { deleteFinancialSettings } from "../../Pages/Finance/FinanceMaster/FinancialSettings/deleteFinancialSettings";
import { deleteIndustry } from "../../Pages/Finance/FinanceMaster/Industry/deleteIndustry";
import { deleteJournalUsers } from "../../Pages/Finance/FinanceMaster/JournalUsers/deleteJournalUsers";
import { deletePrimaryContact } from "../../Pages/Finance/FinanceMaster/PrimaryContact/deletePrimaryContact";
import { deleteFootNote } from "../../Pages/Finance/FinanceMaster/FootNote/deleteFootNote";
import { deleteAssociatedTaxGroup } from "../../Pages/Finance/FinanceMaster/AssociatedTaxGroup/deleteAssociatedTaxGroup";
import { deleteTax } from "../../Pages/Finance/FinanceMaster/Tax/deleteTax";
import { deleteTaxAuthority } from "../../Pages/Finance/FinanceMaster/TaxAuthority/deleteTaxAuthority";
import { deleteTaxExemption } from "../../Pages/Finance/FinanceMaster/TaxExemption/deleteTaxExemption";
import { deleteTaxGroup } from "../../Pages/Finance/FinanceMaster/TaxGroup/deleteTaxGroup";
import { deleteTaxRule } from "../../Pages/Finance/FinanceMaster/TaxRule/deleteTaxRule";
import { deleteAccountingPeriod } from "../../Pages/Finance/FinanceMaster/AccountingPeriod/deleteAccountingPeriod";
import { deleteJournalType } from "../../Pages/Finance/FinanceMaster/JournalType/deleteJournalType";
import { deleteChartOfAccount } from "../../Pages/Finance/FinanceOperational/ChartOfAccount/deleteChartOfAccount";
import { deleteAccountTypeFinancialStatementSection } from "../../Pages/Finance/FinanceOperational/AccountTypeFinancialStatementSection/deleteAccountTypeFinancialStatementSection";
import { deleteBankReconcilation } from "../../Pages/Finance/FinanceOperational/BankReconcilation/deleteBankReconcilation";
import { deleteChartOfAccountFiles } from "../../Pages/Finance/FinanceOperational/ChartOfAccountFiles/deleteChartOfAccountFiles";
import { deleteReconcilationTransaction } from "../../Pages/Finance/FinanceOperational/ReconcilationTransaction/deleteReconcilationTransaction";
import { deleteAsset } from "../../Pages/Finance/FinanceOperational/Asset/deleteAsset";
import { deleteEstimatedTotalProductionUnit } from "../../Pages/Finance/FinanceOperational/EstimatedTotalProductionUnit/deleteEstimatedTotalProductionUnit";
import { deleteRecurringJournalOccurrence } from "../../Pages/Finance/FinanceOperational/RecurringJournalOccurrence/deleteRecurringJournalOccurrence";
import { deleteBudget } from "../../Pages/Finance/FinanceOperational/Budget/deleteBudget";
import { deleteBudgetAccount } from "../../Pages/Finance/FinanceOperational/BudgetAccount/deleteBudgetAccount";
import { deleteBudgetAccountPeriod } from "../../Pages/Finance/FinanceOperational/BudgetAccountPeriod/deleteBudgetAccountPeriod";
import { deleteBudgetControlAction } from "../../Pages/Finance/FinanceOperational/BudgetControlAction/deleteBudgetControlAction";
import { deleteGeneralJournalDetail } from "../../Pages/Finance/FinanceOperational/GeneralJournalDetail/deleteGeneralJournalDetail";
import { deleteGeneralJournalFiles } from "../../Pages/Finance/FinanceOperational/GeneralJournalFiles/deleteGeneralJournalFiles";
import { deleteGeneralJournalHeader } from "../../Pages/Finance/FinanceOperational/GeneralJournalHeader/deleteGeneralJournalHeader";
import { deleteGeneralLedger } from "../../Pages/Finance/FinanceOperational/GeneralLedger/deleteGeneralLedger";
import { deleteJournalComment } from "../../Pages/Finance/FinanceOperational/JournalComment/deleteJournalComment";
import { deleteNumberTracker } from "../../Pages/Finance/FinanceOperational/NumberTracker/deleteNumberTracker";
import { deleteRecurringGeneralJournal } from "../../Pages/Finance/FinanceOperational/RecurringGeneralJournal/deleteRecurringGeneralJournal";
import { deleteTransactionLock } from "../../Pages/Finance/FinanceOperational/TransactionLock/deleteTransactionLock";
import { deleteOpeningBalance } from "../../Pages/Finance/FinanceOperational/OpeningBalance/deleteOpeningBalance";
import { deleteClient } from "../../Pages/Client/deleteClient";
import { deleteCashPaymentCustom } from "../../Pages/Finance/FinanceOperational/CashPaymentCustom/deleteCashPaymentCustom";
import { deleteCashPaymentVoucher } from "../../Pages/Finance/FinanceOperational/CashPaymentVoucher/deleteCashPaymentVoucher";
import { deleteCpvBank } from "../../Pages/Finance/FinanceOperational/CpvBank/deleteCpvBank";
import { deleteCpvPaymentRequest } from "../../Pages/Finance/FinanceOperational/CpvPaymentRequest/deleteCpvPaymentRequest";
import { deleteCpvReceipt } from "../../Pages/Finance/FinanceOperational/CpvReceipt/deleteCpvReceipt";
import { deleteCpvType } from "../../Pages/Finance/FinanceOperational/CpvType/deleteCpvType";
import { deletePettyCash } from "../../Pages/Finance/FinanceOperational/PettyCash/deletePettyCash";
import { deleteCrvPayment } from "../../Pages/Finance/FinanceOperational/CrvPayment/deleteCrvPayment";
import { deleteCrvType } from "../../Pages/Finance/FinanceOperational/CrvType/deleteCrvType";
import { deleteUser } from "../../Pages/Account/User/deleteUser";
import { deleteRole } from "../../Pages/Account/Role/deleteRole";
import { deletePrivilege } from "../../Pages/Account/Privilege/deletePrivilege";

const DeleteModal = (props) => {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const dispatch = useDispatch();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const selectedDetail = useSelector(
        (state) => state.selectedDetail.selected
    );

    const id = props.id;

    const successHandler = () => {
        dispatch(setRefresh(!refreshTable));
        setSuccess(true);
        setError(false);
        setTimeout(() => {
            props.close();
        }, 1000);
    };

    const errorHandler = () => {
        setError(true);
        setSuccess(false);
        setTimeout(() => {
            props.close();
        }, 6000);
    };

    const onResponce = (res) => {
        setLoading(false);
        if (res === "Success") {
            successHandler();
        } else {
            errorHandler();
        }
    };

    // console.log(props.id)

    const deleteHandler = () => {
        if (location.pathname === "/View/Material") {
            deleteMaterial(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/Account/User") {
            deleteUser(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/Account/Role") {
            deleteRole(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/Account/Privilege") {
            deletePrivilege(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/Equipment") {
            deleteEquipment(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/ViewProject") {
            deleteProject(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/ViewClient") {
            deleteClient(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/MaterialCategory") {
            deleteMaterialCategory(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/Instruction") {
            deleteInstruction(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/Evaluation") {
            deleteEvaluation(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/Manpower") {
            deleteManpower(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/Phase") {
            deletePhase(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/WorkCategory") {
            deleteWorkCategory(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/Priority") {
            deletePriority(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/Documentation") {
            deleteDocumentation(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/DocumentCategory") {
            deleteDocumentCategory(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/ViewProject/${selectedRow.id}/DailyWorkLog`
        ) {
            deleteDailyWorkLog(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/ViewProject/${selectedRow.id}/InstructionGiven`
        ) {
            deleteInstructionGiven(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/ViewProject/${selectedRow.id}/InvoiceTracking`
        ) {
            deleteInvoiceTracking(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/ViewProject/${selectedRow.id}/ManpowerRequirement`
        ) {
            deleteManpowerRequired(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname === `/View/ViewProject/${selectedRow.id}/Request`
        ) {
            deleteRequest(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/ViewProject/${selectedRow.id}/RequiredEquipment`
        ) {
            deleteRequiredEquipment(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/ViewProject/${selectedRow.id}/RequiredMaterial`
        ) {
            deleteRequiredMaterial(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/ViewProject/${selectedRow.id}/RiskTracking`
        ) {
            deleteRiskTracking(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/ViewProject/${selectedRow.id}/SimpleTask`
        ) {
            deleteSimpleTask(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname === `/View/ViewProject/${selectedRow.id}/SubTask`
        ) {
            deleteSubTask(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/ViewProject/${selectedRow.id}/TaskManager`
        ) {
            deleteTaskManager(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname === `/View/ViewProject/${selectedRow.id}/ToDos`
        ) {
            deleteToDos(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/ViewProject/${selectedRow.id}/WeatherData`
        ) {
            deleteWeatherData(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/ViewProject/${selectedRow.id}/RequestPayment`
        ) {
            deleteRequestPayment(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/ViewProject/${selectedRow.id}/WorkLogAndBranchOfWork`
        ) {
            deleteWorkLog(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/Currency") {
            deleteCurrency(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/Country") {
            deleteCountry(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/Title") {
            deleteTitle(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/Language") {
            deleteLanguage(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/ActionReason") {
            deleteActionReason(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/ActionType") {
            deleteActionType(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/AddressType") {
            deleteAddressType(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/Commitment") {
            deleteCommitment(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/CustomReport") {
            deleteCustomReport(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/DisciplineCaseType") {
            deleteDisciplineCaseType(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/DurationMeasure") {
            deleteDurationMeasure(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/EmployeeGroup") {
            deleteEmployeeGroup(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/EmployeeType") {
            deleteEmployeeType(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/FieldOfStudy") {
            deleteFieldOfStudy(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/HCMConfiguration") {
            deleteHcmConfiguration(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/EmployeeTransactionLock") {
            deleteEmployeeTransactionLock(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/LicenseType") {
            deleteLicenseType(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/Nationality") {
            deleteNationality(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/Religion") {
            deleteReligion(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/TrainingType") {
            deleteTrainingType(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/BusinessUnit") {
            deleteBusinessUnit(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/Company") {
            deleteCompany(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/Location") {
            deleteLocation(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/CompanyPrimaryContact") {
            deleteCompanyPrimaryContact(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/JobCategory") {
            deleteJobCategory(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/JobSafetyEquipment") {
            deleteJobSafetyEquipment(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/JobTitle") {
            deleteJobTitle(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/SafetyEquipment") {
            deleteSafetyEquipment(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/HCM/Vacancy/${selectedRow?.id}/ExternalApplicant`
        ) {
            deleteExternalApplicant(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/Vacancy") {
            deleteVacancy(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/HCM/Vacancy/${selectedRow?.id}/VacancyResult`
        ) {
            deleteVacancyResult(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/HCM/Vacancy/${selectedRow?.id}/VacancyExaminer`
        ) {
            deleteVacancyExaminer(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/HCM/Vacancy/${selectedRow?.id}/VacancyInternalApplicant`
        ) {
            deleteVacancyInternalApplicant(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === `/View/HCM/VacancyRequestReason`) {
            deleteVacancyRequestReason(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/AttendanceAbscenceType") {
            deleteAttendanceAbscenceType(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/AttendanceCaptured") {
            deleteAttendanceCaptured(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/AttendancePayroll") {
            deleteAttendancePayroll(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/DeviceIdMapping") {
            deleteDeviceIdMapping(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/Holiday") {
            deleteHoliday(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/HolidayCharacter") {
            deleteHolidayCharacter(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/LeavePeriod") {
            deleteLeavePeriod(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/LeavePlan") {
            deleteLeavePlan(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/Punch") {
            deletePunch(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/PunchDevice") {
            deletePunchDevice(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/PunchLog") {
            deletePunchLog(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/PunchManualImport") {
            deletePunchManualImport(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/PunchTime") {
            deletePunchTime(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/ShiftScheduleDtl") {
            deleteShiftScheduleDtl(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/ShiftScheduleHdr") {
            deleteShiftScheduleHdr(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/SubShiftGroup") {
            deleteSubShiftGroup(id).then((res) => {
                onResponce(res);
            });
            //==============================================EMPLOYEE MASTER===================================================
        } else if (
            location.pathname ===
            `/View/HCM/Employee/${selectedDetail?.id}/Address`
        ) {
            deleteAddress(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/Attachment") {
            deleteAttachment(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/HCM/Employee/${selectedDetail?.id}/Dependent`
        ) {
            deleteDependent(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/HCM/Employee/${selectedDetail?.id}/Discipline`
        ) {
            deleteDiscipline(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/HCM/Employee/${selectedDetail?.id}/Education`
        ) {
            deleteEducation(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/HCM/Employee/${selectedRow?.id}/EmployeeBackPenalityDeduction`
        ) {
            deleteEmployeeBackPenalityDeduction(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/HCM/Employee/${selectedRow?.id}/EmployeePenality`
        ) {
            deleteEmployeePenality(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/HCM/Employee/${selectedRow?.id}/EmployeePenalityDetailMst`
        ) {
            deleteEmployeePenalityDetailMst(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/Employee") {
            deleteEmployee(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/HCM/Employee/${selectedDetail?.id}/EmployeeAction`
        ) {
            deleteEmployeeAction(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/HCM/Employee/${selectedDetail?.id}/EmployeeAttachment`
        ) {
            deleteEmployeeAttachment(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/HCM/Employee/${selectedDetail?.id}/EmployeeCommitment`
        ) {
            deleteEmployeeCommitment(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/HCM/Employee/${selectedDetail?.id}/EmployeeContact`
        ) {
            deleteEmployeeContact(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/EmployeeIdRange") {
            deleteEmployeeIdRange(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/HCM/Employee/${selectedDetail?.id}/EmployeeLoanRepayment`
        ) {
            deleteEmployeeLoanRepayment(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/HCM/Employee/${selectedDetail?.id}/EmployeePayFrequency`
        ) {
            deleteEmployeePayFrequency(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/HCM/Employee/${selectedDetail?.id}/EmployeePaygrade`
        ) {
            deleteEmployeePaygrade(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/HCM/Employee/${selectedDetail?.id}/EmployeeSalaryComponent`
        ) {
            deleteEmployeeSalaryComponent(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/HCM/Employee/${selectedDetail?.id}/Experience`
        ) {
            deleteExperience(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/HCM/Employee/${selectedDetail?.id}/LeaveAssignment`
        ) {
            deleteLeaveAssignment(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/HCM/Employee/${selectedDetail?.id}/LeaveEntitlement`
        ) {
            deleteLeaveEntitlement(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/HCM/Employee/${selectedDetail?.id}/LeaveTransfer`
        ) {
            deleteLeaveTransfer(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/HCM/Employee/${selectedDetail?.id}/License`
        ) {
            deleteLicense(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/OrgAssignment") {
            deleteOrgAssignment(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/HCM/Employee/${selectedDetail?.id}/ServiceAllowancePay`
        ) {
            deleteServiceAllowancePay(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            `/View/HCM/Employee/${selectedDetail?.id}/ShiftAssignment`
        ) {
            deleteShiftAssignment(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname === "/View/HCM/EmployeeBackPenalityDeduction"
        ) {
            deleteEmployeeBackPenalityDeduction(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/EmployeePayScale") {
            deleteEmployeePayScale(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/EmployeePenality") {
            deleteEmployeePenality(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname === "/View/HCM/EmployeePenalityDetailMst"
        ) {
            deleteEmployeePenalityDetailMst(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/EmployeePenalityType") {
            deleteEmployeePenalityType(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/EmployeeTax") {
            deleteEmployeeTax(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname === "/View/HCM/GlobalPayrollAccountMapping"
        ) {
            deleteGlobalPayrollAccountMapping(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/Overtime") {
            deleteOvertime(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/OvertimeRate") {
            deleteOvertimeRate(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/Paygrade") {
            deletePaygrade(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/PaygradeSalaryComponent") {
            deletePaygradeSalaryComponent(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/PaygradeScale") {
            deletePaygradeScale(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/PayrollDetail") {
            deletePayrollDetail(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/PayrollDetailHistory") {
            deletePayrollDetailHistory(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/PayrollFrequencyType") {
            deletePayrollFrequencyType(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/PayrollLocationSetting") {
            deletePayrollLocationSetting(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/PayrollLog") {
            deletePayrollLog(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/PayrollLogEmployee") {
            deletePayrollLogEmployee(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/PayrollPeriodAutogen") {
            deletePayrollPeriodAutogen(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/PayrollPeriodAutogenLog") {
            deletePayrollPeriodAutogenLog(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/PayrollPeriodTemplate") {
            deletePayrollPeriodTemplate(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/PayrollPostingLog") {
            deletePayrollPostingLog(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/PayrollProcessingLog") {
            deletePayrollProcessingLog(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/PayrollSummary") {
            deletePayrollSummary(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/PayrollSummaryHistory") {
            deletePayrollSummaryHistory(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/PayScaleHistoryLog") {
            deletePayScaleHistoryLog(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/SalaryAdjustment") {
            deleteSalaryAdjustment(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/SalaryComponent") {
            deleteSalaryComponent(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname === "/View/HCM/SalaryComponentAccountMapping"
        ) {
            deleteSalaryComponentAccountMapping(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/UnitOfMeasure") {
            deleteUnitOfMeasure(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/Warehouse") {
            deleteWarehouse(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/StockBatch") {
            deleteStockBatch(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/StockItem") {
            deleteStockItem(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/StockTransactionDetail") {
            deleteStockTransactionDetail(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/StockTransactionHeader") {
            deleteStockTransactionHeader(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/AccountCategory") {
            deleteAccountCategory(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/AccountType") {
            deleteAccountType(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/ClosingNote") {
            deleteClosingNote(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname === "/View/HCM/FinancialStatementSection"
        ) {
            deleteFinancialStatementSection(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/Bank") {
            deleteBank(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/Contact") {
            deleteContact(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/ContactAddress") {
            deleteContactAddress(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/ContactPerson") {
            deleteContactPerson(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/CostCenter") {
            deleteCostCenter(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/CostCenterAccounts") {
            deleteCostCenterAccounts(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/PaymentTerm") {
            deletePaymentTerm(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/ExchangeRate") {
            deleteExchangeRate(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/CompanyAddress") {
            deleteCompanyAddress(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/DateFormatType") {
            deleteDateFormatType(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/FinancialSettings") {
            deleteFinancialSettings(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/Industry") {
            deleteIndustry(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/JournalUsers") {
            deleteJournalUsers(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/PrimaryContact") {
            deletePrimaryContact(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/FootNote") {
            deleteFootNote(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/AssociatedTaxGroup") {
            deleteAssociatedTaxGroup(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/Tax") {
            deleteTax(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/TaxAuthority") {
            deleteTaxAuthority(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/TaxExemption") {
            deleteTaxExemption(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/TaxGroup") {
            deleteTaxGroup(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/TaxRule") {
            deleteTaxRule(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/AccountingPeriod") {
            deleteAccountingPeriod(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/JournalType") {
            deleteJournalType(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/ChartOfAccount") {
            deleteChartOfAccount(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname ===
            "/View/HCM/AccountTypeFinancialStatementSection"
        ) {
            deleteAccountTypeFinancialStatementSection(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/BankReconcilation") {
            deleteBankReconcilation(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/ChartOfAccountFiles") {
            deleteChartOfAccountFiles(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/ReconcilationTransaction") {
            deleteReconcilationTransaction(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/Asset") {
            deleteAsset(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname === "/View/HCM/EstimatedTotalProductionUnit"
        ) {
            deleteEstimatedTotalProductionUnit(id).then((res) => {
                onResponce(res);
            });
        } else if (
            location.pathname === "/View/HCM/RecurringJournalOccurrence"
        ) {
            deleteRecurringJournalOccurrence(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/Budget") {
            deleteBudget(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/BudgetAccount") {
            deleteBudgetAccount(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/BudgetAccountPeriod") {
            deleteBudgetAccountPeriod(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/BudgetControlAction") {
            deleteBudgetControlAction(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/GeneralJournalDetail") {
            deleteGeneralJournalDetail(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/GeneralJournalFiles") {
            deleteGeneralJournalFiles(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/GeneralJournalHeader") {
            deleteGeneralJournalHeader(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/GeneralLedger") {
            deleteGeneralLedger(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/JournalComment") {
            deleteJournalComment(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/NumberTracker") {
            deleteNumberTracker(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/RecurringGeneralJournal") {
            deleteRecurringGeneralJournal(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/TransactionLock") {
            deleteTransactionLock(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/OpeningBalance") {
            deleteOpeningBalance(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/CashPaymentCustom") {
            deleteCashPaymentCustom(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/CashPaymentVoucher") {
            deleteCashPaymentVoucher(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/CpvBank") {
            deleteCpvBank(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/CpvPaymentRequest") {
            deleteCpvPaymentRequest(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/CpvReceipt") {
            deleteCpvReceipt(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/CpvType") {
            deleteCpvType(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/PettyCash") {
            deletePettyCash(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/CrvPayment") {
            deleteCrvPayment(id).then((res) => {
                onResponce(res);
            });
        } else if (location.pathname === "/View/HCM/CrvType") {
            deleteCrvType(id).then((res) => {
                onResponce(res);
            });
        }
    };

    return (
        <Paper
            style={{ display: "flex", flexDirection: "column", padding: "5ch" }}
        >
            <Typography>
                {`Are you sure you want to delete? ${
                    props.name !== undefined ? props.name : ""
                }`}
            </Typography>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "2ch",
                    justifyContent: "space-between",
                }}
            >
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={props.close}
                >
                    Cancel
                </Button>
                <LoadingButton
                    loading={loading}
                    variant="contained"
                    color="error"
                    onClick={() => {
                        setLoading(true);
                        deleteHandler();
                    }}
                >
                    Delete
                </LoadingButton>
                <SnackBar
                    error={error}
                    success={success}
                    message={
                        error
                            ? "Error Occurred Item Not Deleted!!"
                            : success && "Item Deleted!!"
                    }
                />
            </div>
        </Paper>
    );
};

export default DeleteModal;
