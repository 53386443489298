import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
    Box,
    Stack,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { caller, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    BudgetURL,
    CostCenterURL,
    ChartOfAccountURL,
} from "../../../../utils/config";

const AddBudget = () => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState("");
    const [options1, setOptions1] = useState([]);
    const [optionsId1, setOptionsID1] = useState();
    const [optionSelect1, setOptionSelect1] = useState("");
    const [filter2, setFilter2] = useState("");
    const [options2, setOptions2] = useState([]);
    const [optionSelect2, setOptionSelect2] = useState("");
    const [BudgetReason, setBudgetReason] = useState(1);
    const [msg, setMsg] = useState("");
    const [isApplicableMaterialRequest, setIsApplicableMaterialRequest] =
        useState(false);
    const [purchaseRequest, setPurchaseRequest] = useState(false);
    const [bookingActualExpense, setBookingActualExpense] = useState(false);
    const [annualBudgetExceededMr, setAnnualBudgetExceededMr] = useState();
    const [annualBudgetExceededPo, setAnnualBudgetExceededPo] = useState();
    const [annualBudgetExceededActual, setAnnualBudgetExceededActual] =
        useState();

    const BUDGET_REASON = ["Cost Center", "Project"];
    const ANNUAL_BUDGET = ["Stop", "Warning", "Ignore"];

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        name: "",
        fiscal_year: "",
        budget_reason: BudgetReason,
        project_name: "",
        cost_center_id: null,
        is_applicable_material_request: isApplicableMaterialRequest,
        purchase_request: purchaseRequest,
        booking_actual_expense: bookingActualExpense,
        accounts: [],
        annual_budget_exceeded_mr: annualBudgetExceededMr,
        annual_budget_exceeded_po: annualBudgetExceededPo,
        annual_budget_exceeded_actual: annualBudgetExceededActual,
    };

    const FORM_VALIDATION = Yup.object().shape({
        name: Yup.string().min(1),
        fiscal_year: Yup.string().min(1).required("*Required"),
        budget_reason: Yup.number().min(1).required("*Required"),
        project_name: Yup.string().min(1),
        // cost_center_id: Yup.number().min(1),
    });
    useEffect(() => {
        get(CostCenterURL, {
            limit: 10,
            filter: {
                cost_center_code: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.cost_center_code}`
                    );
                });
            res && setOptionsID1(res.data);
            setOptions1(data);
        });
        get(ChartOfAccountURL, {
            limit: 10,
            filter: {
                all: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.account_code}`
                    );
                });
            setOptions2(data);
        });
    }, [filter, filter2]);

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        caller(BudgetURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                actions.resetForm();
                return 1;
            }
        });
    };

    return (
        <div>
            <TitleHeader title="Add Budget" icon={<AddRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        values.budget_reason = BudgetReason;
                        values.is_applicable_material_request =
                            isApplicableMaterialRequest;
                        values.purchase_request = purchaseRequest;
                        values.booking_actual_expense = bookingActualExpense;
                        values.annual_budget_exceeded_mr =
                            annualBudgetExceededMr;
                        values.annual_budget_exceeded_po =
                            annualBudgetExceededPo;
                        values.annual_budget_exceeded_actual =
                            annualBudgetExceededActual;

                        optionsId1.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.cost_center_code}` ===
                                optionSelect1
                            ) {
                                values.cost_center_id = name.id;
                            }
                        });

                        optionSelect2.forEach((option, index) => {
                            let account_id = parseInt(option.split("-")[0]);
                            values.accounts.push(account_id);
                        });

                        const budget = {
                            name: values.name,
                            fiscal_year: values.fiscal_year,
                            budget_reason: values.budget_reason,
                            project_name: values.project_name,
                            cost_center_id: values.cost_center_id,
                            is_applicable_material_request:
                                values.is_applicable_material_request,
                            purchase_request: values.purchase_request,
                            booking_actual_expense:
                                values.booking_actual_expense,
                        };
                        const budgetControlAction = {
                            accounts: values.accounts,
                            annual_budget_exceeded_mr:
                                values.annual_budget_exceeded_mr,
                            annual_budget_exceeded_po:
                                values.annual_budget_exceeded_po,
                            annual_budget_exceeded_actual:
                                values.annual_budget_exceeded_actual,
                        };

                        formSubmitHandler(
                            { budget, budgetControlAction },
                            actions
                        );
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="name"
                                    label="Name"
                                    variant="outlined"
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>Fiscal Year</FormLabel>
                                    <TextFieldWrapper
                                        name="fiscal_year"
                                        variant="outlined"
                                        type="date"
                                    />
                                </FormControl>
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    select
                                    name="budget_reason"
                                    label="Budget Reason"
                                    variant="outlined"
                                    value={BudgetReason}
                                    options={BUDGET_REASON ?? " "}
                                    onChange={(e) => {
                                        setBudgetReason(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="project_name"
                                    label="Project Name"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    loading={options1.length < 1 ? true : false}
                                    disablePortal
                                    options={options1}
                                    onChange={(event, newValue) => {
                                        setOptionSelect1(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Cost Center"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Stack spacing={3}>
                                    <Autocomplete
                                        multiple
                                        getOptionLabel={(option) =>
                                            option || ""
                                        }
                                        filterSelectedOptions
                                        loading={
                                            options2.length < 1 ? true : false
                                        }
                                        options={options2}
                                        onChange={(event, newValue) => {
                                            setOptionSelect2(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Accounts"
                                                onChange={(event) => {
                                                    setFilter2(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    select
                                    name="annual_budget_exceeded_mr"
                                    label="Annual Budget Exceeded Mr"
                                    variant="outlined"
                                    value={annualBudgetExceededMr}
                                    options={ANNUAL_BUDGET ?? " "}
                                    onChange={(e) => {
                                        setAnnualBudgetExceededMr(
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    select
                                    name="annual_budget_exceeded_po"
                                    label="Annual Budget Exceeded Po"
                                    variant="outlined"
                                    value={annualBudgetExceededPo}
                                    options={ANNUAL_BUDGET ?? " "}
                                    onChange={(e) => {
                                        setAnnualBudgetExceededPo(
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    select
                                    name="annualBudgetExceededActual"
                                    label="Annual Budget Exceeded Actual"
                                    variant="outlined"
                                    value={annualBudgetExceededActual}
                                    options={ANNUAL_BUDGET ?? " "}
                                    onChange={(e) => {
                                        setAnnualBudgetExceededActual(
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>
                                        Is Applicable Material Request
                                    </FormLabel>
                                    <Checkbox
                                        name="is_applicable_material_request"
                                        checked={isApplicableMaterialRequest}
                                        onChange={(event) => {
                                            setIsApplicableMaterialRequest(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>Purchase Request</FormLabel>
                                    <Checkbox
                                        name="purchase_request"
                                        checked={purchaseRequest}
                                        onChange={(event) => {
                                            setPurchaseRequest(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>
                                        Booking Actual Expense
                                    </FormLabel>
                                    <Checkbox
                                        name="booking_actual_expense"
                                        checked={bookingActualExpense}
                                        onChange={(event) => {
                                            setBookingActualExpense(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default AddBudget;
