import PropTypes from "prop-types";
import { Delete, EditRounded, ViewColumn } from "@mui/icons-material";
import {
    Box,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    Modal,
    Paper,
    TablePagination,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setSelected } from "../../Redux/Slice/selectedRow";
import { SnackBar } from "../SnackBar/SnackBar.component";

const ListTable = ({
    loading,
    rows,
    deleteFunc,
    addLimit,
    error,
    success,
    msg,
    body,
    title,
    subtitle,
    description,
}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const closeModal = () => {
        setOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        addLimit();
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div className="TableContainer">
            {loading && <LinearProgress />}
            {!loading && rows.length === 0 && (
                <div
                    style={{
                        width: "100%",
                        height: "40vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography
                        style={{
                            fontSize: "3rem",
                            fontWeight: "bolder",
                            color: "#e5e5e5",
                        }}
                    >
                        Noting here
                    </Typography>
                </div>
            )}
            <List>
                {rows.length > 0 &&
                    rows
                        .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                        )
                        .map((data, index) => (
                            <ListItem
                                key={index}
                                style={{
                                    display: "flex",
                                    borderRadius: "5px",
                                    boxShadow: "0 0 15px #e5e5e5",
                                    justifyContent: "space-between",
                                    padding: "2ch",
                                    width: "100%",
                                    cursor: "pointer",
                                    marginBottom: "2ch",
                                }}
                                onClick={() => {
                                    dispatch(setSelected(data));
                                    setOpen(true);
                                }}
                            >
                                <Box>
                                    <Typography fontWeight={"bold"}>
                                        {data[title]}
                                    </Typography>
                                    <Typography>{data[subtitle]}</Typography>
                                    <Typography
                                        style={{
                                            fontWeight: "bolder",
                                        }}
                                    >
                                        {data[description]}
                                    </Typography>
                                </Box>
                                <Box
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: "2ch",
                                    }}
                                >
                                    <IconButton
                                        onClick={() => {
                                            dispatch(setSelected(data));
                                            setOpen(true);
                                        }}
                                    >
                                        <ViewColumn color="primary" />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => {
                                            dispatch(setSelected(data));
                                            setOpen(true);
                                        }}
                                    >
                                        <EditRounded color="success" />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => {
                                            deleteFunc(data._id);
                                        }}
                                    >
                                        <Delete color="error" />
                                    </IconButton>
                                </Box>
                            </ListItem>
                        ))}
            </List>
            <TablePagination
                rowsPerPageOptions={[8, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Modal className="Modal" open={open} onClose={closeModal}>
                <Box
                    className="ModalContainer"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        maxWidth: "90%",
                        width: "70%",
                        objectFit: "fill",
                    }}
                >
                    <Paper
                        style={{
                            minWidth: "100%",
                            minHeight: "50vh",
                            maxHeight: "80vh",
                            overflowY: "scroll",
                            // padding: "2ch",
                        }}
                    >
                        {body}
                    </Paper>
                    {error || success ? (
                        <SnackBar
                            error={error}
                            success={success}
                            message={error ? msg : `Success^${Math.random()}`}
                        />
                    ) : null}
                </Box>
            </Modal>
        </div>
    );
};

ListTable.propTypes = {
    addLimit: PropTypes.func,
    body: PropTypes.any,
    deleteFunc: PropTypes.func,
    description: PropTypes.string,
    error: PropTypes.bool,
    loading: PropTypes.bool,
    msg: PropTypes.string,
    rows: PropTypes.array,
    subtitle: PropTypes.string,
    success: PropTypes.string,
    title: PropTypes.string,
};

export default ListTable;
