import { Add, SearchRounded } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import OperationsTable from "../../../../Components/Table/OperationsTable.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { get } from "../../../../utils/Caller";
import { VacancyResultURL } from "../../../../utils/config";

const APPLICANT_STATUS = ["Pending", "Selected", "Hired", "Accepted"];
const IS_EMPLOYEE = ["Employee", "ExternalApplicant"];

const ViewVacancyResult = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(20);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState();
    const [Search, setSearch] = useState();
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    const columns = [
        {
            field: "name",
            headerName: "Name",
            width: 150,
        },
        {
            field: "application_date",
            headerName: "Application Date",
            width: 150,
        },
        {
            field: "remark",
            headerName: "Remark",
            width: 150,
        },
        {
            field: "scale",
            headerName: "Scale",
            width: 150,
        },
        {
            field: "result",
            headerName: "Result",
            width: 150,
        },
        {
            field: "applicationStatus",
            headerName: "Application Status",
            width: 150,
        },
    ];

    useEffect(() => {
        let tableData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(VacancyResultURL, {
            limit: limit,
            vacancy_id: selectedRow?.id,
            filter: { all: filter },
        }).then((res) => {
            // console.log(res);
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    tableData.push({
                        ...data,
                        externalApplicant:
                            data.external_applicant?.applicant_name,
                        employeeName: data.employee?.first_name,
                        vacancyName: data.vacancy?.remark,
                        applicationStatus:
                            APPLICANT_STATUS[data.application_status - 1],
                        isEmployee: IS_EMPLOYEE[data.is_employee - 1],
                    });
                });
            setRows(tableData);
        });
    }, [refreshTable, filter, limit, rows.length, selectedRow?.id]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setloading(true);
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <div style={{ display: "flex", gap: "2ch" }}>
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<Add />}
                        onClick={() => {
                            navigate(
                                `/View/HCM/Vacancy/${selectedRow?.id}/VacancyResult/AddVacancyResult`
                            );
                        }}
                    >
                        Add Vacancy Result
                    </Button>
                </div>
            </div>
            <div className="TableContainer">
                <OperationsTable
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                />
            </div>
        </div>
    );
};

export default ViewVacancyResult;
