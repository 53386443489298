import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedToDo: null,
};

export const selectedToDo = createSlice({
    name: "selectedToDo",
    initialState,
    reducers: {
        setSelectedToDo: (state, action) => {
            state.selectedToDo = action.payload;
        },
    },
});

export const { setSelectedToDo } = selectedToDo.actions;

export default selectedToDo.reducer;
