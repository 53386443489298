import { Add, SearchRounded } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ExpandableTable from "../../../../Components/Table/ExpandableTable.component";

// import Table from "../../../../Components/Table/Table.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { get } from "../../../../utils/Caller";
import { ShiftScheduleHdrURL } from "../../../../utils/config";

const ViewShiftScheduleHdr = () => {
    const [rows, setRows] = useState([]);
    const [subRows, setSubRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(20);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState();
    const [Search, setSearch] = useState();

    // const DAYS = [
    //     "Monday",
    //     "Tuesday",
    //     "Wednesday",
    //     "Thursday",
    //     "Friday",
    //     "Saturday",
    //     "Sunday",
    // ];

    const columns = [
        {
            field: "shift_name",
            headerName: "Shift Name",
            width: 150,
        },
        {
            field: "subShiftGroup",
            headerName: "Sub Shift Group",
            width: 150,
        },
    ];

    const subColumns = [
        {
            field: "clock_in",
            headerName: "Clock In",
        },
        {
            field: "clock_out",
            headerName: "Clock Out",
        },
        {
            field: "min_working_hours",
            headerName: "Min Working Hours",
        },
        {
            field: "working_day",
            headerName: "Working Day",
        },
    ];

    useEffect(() => {
        let tableData = [];
        let subRow = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(ShiftScheduleHdrURL, {
            limit: limit,
            filter: { all: filter },
        }).then((res) => {
            // console.log(res);
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    tableData.push({
                        ...data,
                        subShiftGroup: data.sub_shift_group?.sub_shift_name,
                    });
                    subRow.push(data.shift_schedule_dtl);
                });
            setRows(tableData);
            setSubRows(subRow);
        });
    }, [refreshTable, filter, limit, rows.length]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setloading(true);
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <div style={{ display: "flex", gap: "2ch" }}>
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<Add />}
                        onClick={() => {
                            navigate(
                                "/View/HCM/ShiftScheduleHdr/AddShiftScheduleHdr"
                            );
                        }}
                    >
                        Add Shift ScheduleHdr
                    </Button>
                </div>
            </div>
            <div className="TableContainer">
                <ExpandableTable
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                    subColumns={subColumns}
                    subRows={subRows}
                    subTitle="Shift Detail"
                />
            </div>
        </div>
    );
};

export default ViewShiftScheduleHdr;
