import { PrintRounded, CancelRounded } from "@mui/icons-material";
import {
    Typography,
    Paper,
    TableCell,
    Table,
    TableRow,
    TableHead,
    TableBody,
    IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { CompanyURL } from "../../../../utils/config";
import { baseURL, get } from "../../../../utils/Caller";

const RequestPrint = () => {
    const { state } = useLocation();
    const { column, rowsDetail, fieldName } = state;
    const [load, setLoad] = useState(false);
    const [header, setHeader] = useState();
    const [footer, setFooter] = useState();

    useEffect(() => {
        get(CompanyURL, {
            limit: 1,
        }).then((res) => {
            res &&
                res.data.forEach((data, index) => {
                    setHeader(data?.header);
                    setFooter(data?.footer);
                });
        });
    }, []);

    useEffect(() => {
        let retry = setInterval(() => {
            if (load) {
                window.print();
                clearInterval(retry);
            }
        }, 1000);
    }, [load]);

    return (
        <div className="Print">
            <Box
                className="hide-on-print"
                style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                }}
            >
                <IconButton
                    onClick={() => {
                        window.print();
                    }}
                >
                    <PrintRounded />
                </IconButton>
                <IconButton
                    onClick={() => {
                        window.history.back();
                    }}
                >
                    <CancelRounded color="error" />
                </IconButton>
            </Box>

            <Box width={"100%"} style={{ objectFit: "scale-down" }}>
                <img
                    src={baseURL + header}
                    alt=""
                    width={"100%"}
                    onLoad={() => {
                        setLoad(true);
                    }}
                />
            </Box>
            <Paper
                style={{
                    minHeight: "50vh",
                    padding: "2ch",
                }}
                elevation={0}
            >
                <Table>
                    <TableHead>
                        {column.map((head, i) => (
                            <TableCell key={i}>{head.headerName}</TableCell>
                        ))}
                    </TableHead>
                    <TableBody>
                        {rowsDetail.map((rowData, ind) =>
                            rowData?.individual_requests.map((row, i) => (
                                <TableRow key={i}>
                                    {fieldName.map((field) => (
                                        <TableCell>{row[field]}</TableCell>
                                    ))}
                                </TableRow>
                            )),
                        )}
                    </TableBody>
                </Table>
                <Box
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "2ch",
                        justifyContent: "right",
                        alignItems: "flex-end",
                    }}
                >
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            minWidth: "30%",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography fontWeight={"bolder"}>Vat</Typography>
                        <Typography>{rowsDetail[0]?.vat_amount}</Typography>
                    </Box>
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            minWidth: "30%",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography fontWeight={"bolder"}>Sub Total</Typography>
                        <Typography>{rowsDetail[0]?.sub_total}</Typography>
                    </Box>
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            minWidth: "30%",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography fontWeight={"bolder"}>Total</Typography>
                        <Typography>{rowsDetail[0]?.total_amount}</Typography>
                    </Box>
                </Box>
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "2ch",
                    }}
                >
                    <Typography fontWeight={"bolder"}>Prepared By -</Typography>
                    <Typography>
                        {`${rowsDetail[0]?.prepared_by?.first_name} ${rowsDetail[0]?.prepared_by?.last_name}`}
                    </Typography>
                </Box>
            </Paper>
            <Box
                width={"100%"}
                style={{
                    objectFit: "scale-down",
                    position: "absolute",
                    bottom: 0,
                }}
            >
                <img src={baseURL + footer} alt="" width={"100%"} />
            </Box>
        </div>
    );
};

export default RequestPrint;
