import React, { useEffect, useRef, useState } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import ProjectsChart from "./ProjectsChart.component";
import ProjectStatistics from "./ProjectsStatistics.component";
import RequestsCard from "./RequestsCard.page";
import OnGoingProjects from "./OnGoingProject.page";
import { Box } from "@mui/system";
import { ProjectDashboardURL } from "../../../utils/config";
import { get } from "../../../utils/Caller";
import DashboardSkeleton from "./DashBoardSkeleton.page";
// import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useSelector } from "react-redux";
import Calendar from "./Calendar.page";
import LeaveRequest from "../HCMDashboard/LeaveRequest.page";
import EmployeesDashboard from "../HCMDashboard/EmployeesDashboard.component";

const Dashboard = () => {
  const carousel = useRef();
  const [dashboardData, setDashboardData] = useState(null);
  const user = useSelector((state) => state.userState.user);
  const refreshTable = useSelector((state) => state.refreshTable.init);

  useEffect(() => {
    get(ProjectDashboardURL).then((res) => {
      if (res?.data) {
        setDashboardData({
          ...res?.data,
        });
      }
    });
  }, [refreshTable]);

  return (
    <div className="Dashboard">
      <Paper variant="outlined" style={{ minHeight: "100vh", padding: "3ch" }}>
        {dashboardData && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={"700"}>
                Dashboard
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <ProjectsChart
                projectsDone={dashboardData.completedProjectsLenth}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <ProjectStatistics
                onGoing={dashboardData.inCompleteProjectsLength}
                completed={dashboardData.completedProjectsLenth}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <RequestsCard
                requestLength={dashboardData.requests?.total}
                requests={dashboardData.requests}
              />
            </Grid>
            <Grid item xs={12} md={12} display={"flex"} flexDirection={"row"}>
              <Box
                ref={carousel}
                className={"ScrollDash"}
                style={{
                  display: "flex",
                  gap: "3ch",
                }}
              >
                {dashboardData.onGoingProjects?.map((data, i) => (
                  <OnGoingProjects
                    key={i}
                    projectName={data.name}
                    projectManager={data.project_manager?.first_name}
                    projectStartDate={data.project_start_date}
                    projectEndDate={data.project_end_date}
                    projectLocation={data.project_address}
                    project={data}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Calendar />
            </Grid>
            {/* {user.actions?.length === 0 && ( */}
            <Grid item xs={12} md={4}>
              <LeaveRequest />
            </Grid>
            {/* )} */}

            {user.actions?.find((role) => {
              if (
                role === "HEAD" ||
                role === "HCM_ONE" ||
                role === "HCM_TWO" ||
                role === "admin" ||
                role === "super"
              ) {
                return true;
              } else return false;
            }) && (
              <Grid item xs={12} md={12}>
                <EmployeesDashboard />
              </Grid>
            )}
          </Grid>
        )}
        {dashboardData === null && <DashboardSkeleton />}
      </Paper>
    </div>
  );
};

export default Dashboard;
