import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { caller, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    BusinessUnitURL,
    EmployeeURL,
    PayrollFrequencyTypeURL,
    PayrollSummaryURL,
} from "../../../../utils/config";

const AddPayrollSummary = () => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState("");
    const [filter2, setFilter2] = useState("");
    const [filter3, setFilter3] = useState("");
    const [options1, setOptions1] = useState([]);
    const [optionsId1, setOptionsID1] = useState();
    const [optionSelect1, setOptionSelect1] = useState("");
    const [options2, setOptions2] = useState([]);
    const [optionsId2, setOptionsID2] = useState();
    const [optionSelect2, setOptionSelect2] = useState("");
    const [options3, setOptions3] = useState([]);
    const [optionsId3, setOptionsID3] = useState();
    const [optionSelect3, setOptionSelect3] = useState("");
    const [msg, setMsg] = useState("");

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        expected_working_hours: "",
        total_worked_hours: "",
        total_worked_OT_hours: "",
        total_worked_OT_amount: "",
        total_absent_hours: "",
        total_absent_amount: "",
        total_amount: "",
        payroll_frequency_type_id: null,
        employee_id: null,
        business_unit_id: null,
    };

    const FORM_VALIDATION = Yup.object().shape({
        expected_working_hours: Yup.number().min(1).required("*Required"),
        total_worked_hours: Yup.number().min(1).required("*Required"),
        total_worked_OT_hours: Yup.number().min(1),
        total_worked_OT_amount: Yup.number().min(1),
        total_absent_hours: Yup.number().min(1),
        total_absent_amount: Yup.number().min(1),
        total_amount: Yup.number().min(1),
        // payroll_frequency_type_id: Yup.number().min(1),
        // employee_id: Yup.number().min(1),
        // business_unit_id: Yup.number().min(1),
    });
    useEffect(() => {
        get(PayrollFrequencyTypeURL, {
            limit: 10,
            filter: {
                payroll_frequency_desc: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.payroll_frequency_desc}`
                    );
                });
            res && setOptionsID1(res.data);
            setOptions1(data);
        });

        get(EmployeeURL, {
            limit: 10,
            filter: {
                first_name: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.first_name}`
                    );
                });
            res && setOptionsID2(res.data);
            setOptions2(data);
        });

        get(BusinessUnitURL, {
            limit: 10,
            filter: {
                name: filter3,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.name}`
                    );
                });
            res && setOptionsID3(res.data);
            setOptions3(data);
        });
    }, [filter, filter2, filter3]);

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        caller(PayrollSummaryURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                actions.resetForm();
                return 1;
            }
        });
    };

    return (
        <div>
            <TitleHeader title="Add Payroll Summary" icon={<AddRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        optionsId1.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.payroll_frequency_desc}` ===
                                optionSelect1
                            ) {
                                values.payroll_frequency_type_id = name.id;
                            }
                        });
                        optionsId2.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.first_name}` ===
                                optionSelect2
                            ) {
                                values.employee_id = name.id;
                            }
                        });
                        optionsId3.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.name}` === optionSelect3
                            ) {
                                values.business_unit_id = name.id;
                            }
                        });

                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="expected_working_hours"
                                    label="Expected Working Hours"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="total_worked_hours"
                                    label="Total Worked Hours"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="total_worked_OT_hours"
                                    label="Total Worked O T Hours"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="total_worked_OT_amount"
                                    label="Total Worked O T Amount"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="total_absent_hours"
                                    label="Total Absent Hours"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="total_absent_amount"
                                    label="Total Absent Amount"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="total_amount"
                                    label="Total Amount"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    loading={options1.length < 1 ? true : false}
                                    disablePortal
                                    options={options1}
                                    onChange={(event, newValue) => {
                                        setOptionSelect1(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Payroll Frequency Type"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    loading={options2.length < 1 ? true : false}
                                    disablePortal
                                    options={options2}
                                    onChange={(event, newValue) => {
                                        setOptionSelect2(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Employee"
                                            onChange={(event) => {
                                                setFilter2(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    loading={options3.length < 1 ? true : false}
                                    disablePortal
                                    options={options3}
                                    onChange={(event, newValue) => {
                                        setOptionSelect3(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Business Unit"
                                            onChange={(event) => {
                                                setFilter3(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default AddPayrollSummary;
