import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import AddSimpleTask from "./AddSimpleTask.page";
import ViewSimpleTask from "./ViewSimpleTask.page";

const SimpleTask = ({ next, name, handleTabChange }) => {
    const location = useLocation();
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    return (
        <>
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/SimpleTask/AddSimpleTask` && (
                <AddSimpleTask
                    next={next}
                    name={name}
                    handleTabChange={handleTabChange}
                />
            )}
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/SimpleTask` && (
                <>
                    <ViewSimpleTask />
                </>
            )}
        </>
    );
};

export default SimpleTask;
