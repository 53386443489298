import React, { useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    // Autocomplete,
    // TextField,
    Divider,
    // FormControl,
    // FormLabel,
    Grid,
    Paper,
    // TextField,
    Typography,
} from "@mui/material";
import {
    Approval,
    // Cancel,
    ExpandMore,
    PostAdd,
    RunCircle,
} from "@mui/icons-material";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import TextFieldWrapper from "../../Components/TextFieldWrapper.component";
import { Box } from "@mui/system";
import { caller, get } from "../../utils/Caller";
import { LoadingButton } from "@mui/lab";
import { SnackBar } from "../../Components/SnackBar/SnackBar.component";
// import { useSelector } from "react-redux";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { PostURL, RunURL } from "../../utils/config";

const PayrollControl = () => {
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [limit, setLimit] = useState(8);
    // const [filter, setFilter] = useState();
    // const [options, setOptions] = useState([]);
    // const [optionSelect, setOptionSelect] = useState();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setMsg] = useState("");
    const [pageNo, setPageNo] = useState(0);
    // const user = useSelector((state) => state.userState.user);
    const [open, setOpen] = useState(true);
    // const [ids, setIds] = useState([]);
    const [type, setType] = useState(1);
    const [filterData, setFilterData] = useState(null);
    let url = "";

    const TYPE = ["Run", "Post"];

    const runColumns = [
        {
            field: "id",
            headerName: "ID",
            width: 150,
        },
        {
            field: "payroll_frequency_desc",
            headerName: "Payroll Frequency Desc",
            width: 400,
        },
        {
            field: "run",
            headerName: "Run",
            type: "actions",
            width: 150,
            getActions: (cellValues) => [
                <GridActionsCellItem
                    icon={<RunCircle color="primary" />}
                    label={"Run"}
                    onClick={(event) => {
                        runPayroll(event, cellValues);
                    }}
                />,
            ],
        },
    ];

    const postColumns = [
        {
            field: "year",
            headerName: "Year",
            width: 150,
        },
        {
            field: "is_period_generated",
            headerName: "Is Period Generated",
            width: 150,
        },
        {
            field: "post",
            headerName: "Post",
            type: "actions",
            width: 150,
            getActions: (cellValues) => [
                <GridActionsCellItem
                    icon={<PostAdd color="primary" />}
                    label={"Post"}
                    onClick={(event) => {
                        postPayroll(event, cellValues);
                    }}
                />,
            ],
        },
    ];

    const INITIAL_VALUES = {
        startDate: "",
        endDate: "",
        employee_id: null,
        type: type,
    };

    const FORM_VALIDATION = Yup.object().shape({});

    const formSubmitHandler = (data) => {
        setLoading(true);
        let tableData = [];

        if (rows.length === 0) {
            setLoading(true);
        }

        if (type === 1) {
            url = RunURL;
            setColumns(runColumns);
        } else if (type === 2) {
            url = PostURL;
            setColumns(postColumns);
        }

        get(url).then((res) => {
            // console.log(res);
            setLoading(false);
            res?.data?.forEach((data) => {
                tableData.push({
                    ...data,
                });
            });
            setRows(tableData);
        });
    };

    const runPayroll = (_event, cellValues) => {
        setLoading(true);
        caller(RunURL, { id: cellValues.id }).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data?.success) {
                setSuccess(true);
                setError(false);
                formSubmitHandler(filterData);
            } else if (res.data?.success === false) {
                console.log(res.data);
                setMsg(res.data.message);
                setSuccess(false);
                setError(true);
            }
        });
    };

    const postPayroll = (_event, cellValues) => {
        setLoading(true);
        caller(PostURL, { id: cellValues.id }).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data?.success) {
                setSuccess(true);
                setError(false);
                formSubmitHandler(filterData);
            } else if (res.data?.success === false) {
                setMsg(res.data.message);
                setSuccess(false);
                setError(true);
                formSubmitHandler(filterData);
            }
        });
    };

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "2ch" }}>
            <Accordion
                expanded={open}
                onChange={() => {
                    setOpen(!open);
                }}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Approval />
                    <Divider variant="middle" />
                    <Typography fontWeight={600}>Payment Control</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Formik
                        initialValues={{ ...INITIAL_VALUES }}
                        validationSchema={FORM_VALIDATION}
                        onSubmit={(values, actions) => {
                            setFilterData(values);
                            formSubmitHandler(values, actions);
                        }}
                    >
                        <Form>
                            <Grid
                                container
                                spacing={2}
                                style={{ alignItems: "end" }}
                            >
                                <Grid item xs={6}>
                                    <TextFieldWrapper
                                        select
                                        size={"small"}
                                        name="type"
                                        label="Type"
                                        variant="outlined"
                                        options={TYPE}
                                        value={type}
                                        onChange={(e) => {
                                            setType(e.target.value);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <div>
                                <LoadingButton
                                    data-testid="AddButton"
                                    loading={loading}
                                    type="submit"
                                    className="ButtonContained"
                                    style={{
                                        marginTop: "2ch",
                                        float: "right",
                                    }}
                                >
                                    {loading ? null : "Search"}
                                </LoadingButton>
                            </div>
                        </Form>
                    </Formik>
                </AccordionDetails>
            </Accordion>
            <Box>
                <Paper
                    style={{
                        boxShadow: "0 0 5px silver",
                        padding: "1ch",
                    }}
                    elevation={0}
                >
                    <DataGrid
                        // checkboxSelection
                        loading={loading}
                        rows={rows}
                        columns={columns}
                        onPageChange={(page) => {
                            if (pageNo < page) {
                                setPageNo(pageNo + 1);
                                addLimit();
                            }
                        }}
                        autoHeight={true}
                        pageSize={8}
                        rowsPerPageOptions={[8]}
                        // onSelectionModelChange={(id) => {
                        //     setIds(id);
                        // }}
                    />
                </Paper>
            </Box>

            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};

export default PayrollControl;
