import {
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  Box,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { Add, AddRounded, Cancel } from "@mui/icons-material";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../../../../Components/SnackBar/SnackBar.component.jsx";
import { caller } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import { ProjectRequestURL } from "../../../../utils/config";
import { useSelector } from "react-redux";
// import { useNavigate } from "react-router";

const AddRequestForm = ({ requirement, requirementType, setIsTouched }) => {
  const [loading, setLoading] = useState(false);
  const [protectedEdit, setProtectedEdit] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [msg, setMsg] = useState("");
  const selectedRow = useSelector((state) => state.selectedRow.selected);
  // const navigate = useNavigate();

  // const [filter1, setFilter1] = useState("");
  // const [options1, setOptions1] = useState([]);
  // const [optionSelect1, setOptionSelect1] = useState("");
  const [requestType, setRequestType] = useState(1);
  const [returnableState, setReturnableState] = useState({ 0: false });
  const [priority, setPriority] = useState(1);
  // const [approvalStatus, setApprovalStatus] = useState(1);

  const REQUEST_TYPE = ["Payment", "Manpower", "Equipment/Material"];
  const PRIORITY = ["Low", "Medium", "High"];

  const INITIAL_VALUES = {
    isProtectedForEdit: false,
    remark: "",
    request_type: requestType,
    prepared_by_id: null,
    priority: "",
    request: [
      {
        persons: "",
        description: "",
        returnable: false,
        return_date: "",
        quantity: "",
        quantity_type: "",
        unit_rate: "",
        vat: false,
      },
    ],
  };

  const FORM_VALIDATION = Yup.object().shape({
    request_type: Yup.string().min(1).required("*Required"),
    priority: Yup.string(),
    amount: Yup.string(),
    request: Yup.array().of(
      Yup.object().shape({
        description: Yup.string(),
        returnable: Yup.bool(),
        return_date: Yup.string(),
        quantity: Yup.string(),
        unit_rate: Yup.string(),
        vat: Yup.bool(),
      }),
    ),
  });
  // useEffect(() => {
  //     get(EmployeeURL, {
  //         limit: 10,
  //         filter: {
  //             all: filter1,
  //         },
  //     }).then((res) => {
  //         let data = [];
  //         res &&
  //             res.data.forEach((name, index) => {
  //                 data.push(
  //                     `${res.data[index]?.id} - ${res.data[index]?.first_name}`
  //                 );
  //             });
  //         setOptions1(data);
  //     });
  // }, [filter1]);

  const formSubmitHandler = (data, actions) => {
    setLoading(true);
    caller(ProjectRequestURL, data).then((res) => {
      setLoading(false);
      if (res.error) {
        Object.keys(res.error).forEach((data, i) => {
          setMsg(res.error[data]);
        });
        setSuccess(false);
        setError(true);
      } else if (res.data.success) {
        setSuccess(true);
        setError(false);
        actions.resetForm();
      }
    });
  };

  useEffect(() => {
    if (requirementType) {
      setRequestType(requirementType);
    }
  }, [requirementType]);

  return (
    <div>
      <TitleHeader
        title={
          requirementType
            ? `Add ${REQUEST_TYPE[requirementType - 1]}`
            : "Add Request"
        }
        icon={<AddRounded />}
      />
      <div className="FormContainer">
        <Formik
          initialValues={{ ...INITIAL_VALUES }}
          validationSchema={FORM_VALIDATION}
          onSubmit={(values, actions) => {
            values.isProtectedForEdit = protectedEdit;
            if (requirement) {
              values.request_type = requirementType;
            } else values.request_type = requestType;
            values.priority = priority;
            values.project_id = selectedRow.id;
            values.requirement = requirement || false;

            // values.prepared_by_id = parseInt(
            //     optionSelect1.split("-")[0]
            // );
            formSubmitHandler(values, actions);
          }}
        >
          {({ values, setValues }) => {
            Object.keys(INITIAL_VALUES).forEach((field) => {
              if (field !== "request_type") {
                if (
                  INITIAL_VALUES[field]?.toString() !==
                  values[field]?.toString()
                )
                  if (setIsTouched) setIsTouched(true);
              }
            });
            return (
              <Form>
                <Box
                  style={{
                    width: "100%",
                    paddingBottom: "2ch",
                  }}
                >
                  {!requirement && (
                    <TextFieldWrapper
                      select
                      size={"small"}
                      name={"request_type"}
                      label="Request Type"
                      variant="outlined"
                      value={requestType}
                      options={REQUEST_TYPE ?? " "}
                      onChange={(e) => {
                        setRequestType(e.target.value);
                      }}
                    />
                  )}
                </Box>
                {!requirement && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextFieldWrapper
                        size={"small"}
                        name={`remark`}
                        label="Remark"
                        variant="outlined"
                        multiline
                        rows={2}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextFieldWrapper
                        select
                        size={"small"}
                        name={`priority`}
                        label="Priority"
                        variant="outlined"
                        value={priority}
                        options={PRIORITY ?? " "}
                        onChange={(e) => {
                          setPriority(e.target.value);
                        }}
                      />
                    </Grid>
                    {/* <Grid item xs={6}>
                                    <Autocomplete
                                        size={"small"}
                                        loading={
                                            options1.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options1}
                                        onChange={(event, newValue) => {
                                            setOptionSelect1(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                size={"small"}
                                                {...params}
                                                label="Prepared By"
                                                onChange={(event) => {
                                                    setFilter1(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid> */}

                    <Grid item xs={6}>
                      <FormControl
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          checked={protectedEdit}
                          onChange={(event) => {
                            setProtectedEdit(event.target.checked);
                          }}
                        />
                        <FormLabel>Protected For Edit</FormLabel>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}

                <FieldArray name="request">
                  {({ insert, remove, push }) => (
                    <div>
                      {values.request.length > 0 &&
                        values.request.map((req, index) => (
                          <Grid container spacing={2}>
                            <Box
                              width={"100%"}
                              display={"flex"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              borderBottom={"solid silver 1px"}
                              borderTop={"solid silver 1px"}
                              padding={"0.5ch"}
                              marginTop={"2ch"}
                            >
                              <Typography fontWeight={"bold"}>
                                {requirement
                                  ? `Requirement ${index + 1}`
                                  : `Request ${index + 1}`}
                              </Typography>
                              {values.request.length > 1 && (
                                <IconButton onClick={() => remove(index)}>
                                  <Cancel color={"error"} />
                                </IconButton>
                              )}
                            </Box>

                            <Box width={"100%"} />
                            {requestType === 2 && (
                              <Grid item xs={6}>
                                <TextFieldWrapper
                                  size={"small"}
                                  name={`request.${index}.persons`}
                                  label="Profession"
                                  variant="outlined"
                                />
                              </Grid>
                            )}
                            {(requestType === 1 || requestType === 3) && (
                              <Grid item xs={12}>
                                <TextFieldWrapper
                                  size={"small"}
                                  name={`request.${index}.description`}
                                  label="Description"
                                  variant="outlined"
                                  multiline={2}
                                  rows={2}
                                />
                              </Grid>
                            )}
                            <Box width={"100%"} />
                            {requestType === 3 && (
                              <>
                                <Grid item xs={4}>
                                  <FormControl
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Checkbox
                                      name={`request.${index}.returnable`}
                                      checked={returnableState[index]}
                                      onChange={(event) => {
                                        setReturnableState({
                                          ...returnableState,
                                          [index]: event.target.checked,
                                        });
                                      }}
                                    />
                                    <FormLabel>Returnable</FormLabel>
                                  </FormControl>
                                </Grid>
                                {returnableState[index] === true && (
                                  <Grid item xs={4}>
                                    <FormControl>
                                      <FormLabel>Return Date</FormLabel>
                                      <TextFieldWrapper
                                        size={"small"}
                                        name={`request.${index}.return_date`}
                                        variant="outlined"
                                        type="date"
                                      />
                                    </FormControl>
                                  </Grid>
                                )}
                              </>
                            )}
                            <Box width={"100%"} />
                            <Grid item xs={4}>
                              <TextFieldWrapper
                                size={"small"}
                                name={`request.${index}.quantity`}
                                label={
                                  requestType === 2
                                    ? "Number of Personnel"
                                    : "Quantity"
                                }
                                variant="outlined"
                                type="number"
                              />
                            </Grid>
                            {requestType !== 2 && (
                              <Grid item xs={4}>
                                <TextFieldWrapper
                                  size={"small"}
                                  name={`request.${index}.quantity_type`}
                                  label="Unit"
                                  variant="outlined"
                                />
                              </Grid>
                            )}
                            {(requestType === 1 || requestType === 2) && (
                              <Grid item xs={4}>
                                <TextFieldWrapper
                                  size={"small"}
                                  name={`request.${index}.unit_rate`}
                                  label="Unit Rate"
                                  variant="outlined"
                                  type="number"
                                />
                              </Grid>
                            )}
                            {requestType === 1 && (
                              <Grid item xs={6}>
                                <FormControl
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <Checkbox
                                    name={`request.${index}.vat`}
                                    checked={values.request[index].vat}
                                    onChange={(event) => {
                                      values.request[index].vat =
                                        event.target.checked;
                                      setValues(values);
                                    }}
                                  />
                                  <FormLabel>Include Vat</FormLabel>
                                </FormControl>
                              </Grid>
                            )}
                          </Grid>
                        ))}
                      <Button
                        startIcon={<Add />}
                        onClick={() =>
                          push({
                            persons: "",
                            description: "",
                            returnable: false,
                            return_date: "",
                            quantity: "",
                            quantity_type: "",
                            unit_rate: "",
                            vat: false,
                          })
                        }
                      >
                        {requirement
                          ? "Add Another Requirement"
                          : "Add Another Request"}
                      </Button>
                    </div>
                  )}
                </FieldArray>
                <div>
                  <LoadingButton
                    data-testid="AddButton"
                    loading={loading}
                    type="submit"
                    className="ButtonContained"
                    style={{
                      marginTop: "2ch",
                      float: "right",
                    }}
                  >
                    {loading ? null : "Add"}
                  </LoadingButton>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {error || success ? (
        <SnackBar
          error={error}
          success={success}
          message={error ? msg : `Success^${Math.random()}`}
        />
      ) : null}
    </div>
  );
};
export default AddRequestForm;
