import {
    DeleteRounded,
    EditRounded,
    SearchRounded,
    ViewTimelineRounded,
} from "@mui/icons-material";
import {
    Box,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AlertDialog from "../../../../Components/DeleteAlert/DeleteAlert.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";

import { get } from "../../../../utils/Caller";
import { SubTaskURL } from "../../../../utils/config";
import EditSubTask from "./EditSubTask.page";

const ViewSubTask = (props) => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(16);
    const [refreshTable, setRefreshTable] = useState(false);
    const [filter, setFilter] = useState();
    const [pageNo, setPageNo] = useState(0);
    const [editPage, setEditPage] = useState(false);
    const [deletePage, setDeletePage] = useState(false);
    const [selectedValue, setSelectedValue] = useState();
    const dispatch = useDispatch();
    const [Search, setSearch] = useState("");
    const editButtonHandler = (event, value) => {
        setEditPage(true);
        setSelectedValue(value);
    };

    const deleteButtonHandler = (event, value) => {
        setDeletePage(true);
        setSelectedValue(value);
    };

    const columns = [
        {
            field: "name",
            headerName: "Name",
            width: 100,
        },
        {
            field: "task_start_date",
            headerName: "Task Start Date",
            width: 100,
        },
        {
            field: "task_end_date",
            headerName: "Task End Date",
            width: 100,
        },
        {
            field: "description",
            headerName: "Description",
            width: 200,
        },
        {
            field: "duration_in_days",
            headerName: "Duration In Days",
            width: 100,
        },
        {
            field: "working_days",
            headerName: "Working Days",
            width: 100,
        },
        {
            field: "isProtectedForEdit",
            headerName: "Actions",
            type: "actions",
            width: 100,
            getActions: (cellValues) => [
                <GridActionsCellItem
                    icon={<EditRounded color="primary" />}
                    label="Edit"
                    onClick={(event) => {
                        // event.stopPropagation();
                        editButtonHandler(event, cellValues);
                    }}
                />,
                <GridActionsCellItem
                    icon={<DeleteRounded color="error" />}
                    label="Delete"
                    onClick={(event) => {
                        // event.stopPropagation();
                        deleteButtonHandler(event, cellValues);
                    }}
                />,
            ],
        },
        {
            field: "todo",
            headerName: "View Todo's",
            type: "actions",
            minWidth: 150,
            flex: 1,
            getActions: (cellValues) => [
                <GridActionsCellItem
                    icon={<ViewTimelineRounded color="primary" />}
                    label="View Todo's"
                    onClick={(event) => {
                        event.stopPropagation();
                        props.setToDoModal(cellValues.row.todos);
                    }}
                />,
            ],
        },
    ];

    useEffect(() => {
        if (rows.length === 0) {
            setloading(true);
        }
        get(SubTaskURL, {
            limit: limit,
            filter: {
                all: filter,
                task_manager_id: props.taskManager?.id,
            },
        }).then((res) => {
            setloading(false);
            setRows(res.data);
        });
    }, [refreshTable, limit, filter, rows.length, props.taskManager?.id]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <Paper
            style={{
                alignSelf: "center",
                width: "70%",
                padding: "5ch",
                maxHeight: "80vh",
                minWidth: "100%",
                overflowY: "scroll",
            }}
        >
            {!editPage ? (
                <>
                    <div className="SearchField">
                        <TextField
                            name="searchField"
                            label="Search"
                            variant="standard"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton
                                            onClick={(e) => {
                                                setFilter(Search);
                                                dispatch(
                                                    setRefresh(!refreshTable),
                                                );
                                            }}
                                        >
                                            <SearchRounded />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                        />
                    </div>
                    <Box height={"2ch"} />
                    <div style={{ height: 530, width: "100%" }}>
                        <DataGrid
                            loading={loading}
                            rows={rows}
                            columns={columns}
                            pageSize={8}
                            rowsPerPageOptions={[8]}
                            onPageChange={(page) => {
                                if (pageNo < page) {
                                    setPageNo(pageNo + 1);
                                    addLimit();
                                }
                            }}
                            onRowClick={(values) => {
                                props.setToDoModal(values.row.todos);
                            }}
                        />
                    </div>
                </>
            ) : (
                <EditSubTask
                    values={selectedValue}
                    close={() => {
                        setEditPage(false);
                        setRefreshTable(!refreshTable);
                    }}
                />
            )}

            {deletePage && (
                <AlertDialog
                    id={selectedValue.id}
                    open={deletePage}
                    close={() => {
                        setDeletePage(false);
                        setRefreshTable(!refreshTable);
                    }}
                    subTask={true}
                />
            )}
        </Paper>
    );
};

export default ViewSubTask;
