import { Card, Grid, Modal, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";

import { get } from "../../../utils/Caller";
import { CalendarURL } from "../../../utils/config";
import { ViewCalendar } from "../../HCM/EmployeeMaster/Calendar/ViewCalendar.page";

const Calendar = ({ requestLength, requests }) => {
    // const [loading, setLoading] = useState(false);
    const [calendarData, setCalendarData] = useState(false);
    const [open, setOpen] = useState(false);

    const closeModal = () => {
        setOpen(false);
    };

    useEffect(() => {
        get(CalendarURL, {
            limit: 1000,
        }).then((res) => {
            setCalendarData(res?.data);
        });
    }, []);

    return (
        <>
            <Card
                sx={{
                    bgcolor: "#FFF4DD",
                    "&:hover": { bgcolor: "#EEE3CC" },
                    borderRadius: "15px",
                }}
                onClick={() => {
                    setOpen(true);
                }}
                elevation={0}
            >
                <Grid
                    container
                    sx={{
                        p: 2,
                        pb: 0,
                        color: "#fff",
                        height: window.innerHeight / 4,
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Grid item xs={12}>
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                            flexDirection={"column"}
                        >
                            <Grid item>
                                <Typography
                                    variant="h3"
                                    color={"#14213d"}
                                    fontWeight={"bolder"}
                                >
                                    Calendar
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
            <Modal className="Modal" open={open} onClose={closeModal}>
                <Box
                    className="ModalContainer"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minWidth: "80%",
                        maxWidth: "90%",
                    }}
                >
                    <Paper
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            minWidth: "100%",
                            minHeight: "50vh",
                            maxHeight: "80vh",
                            overflowY: "scroll",
                            padding: "2ch",
                            gap: "2ch",
                        }}
                    >
                        <ViewCalendar schedulerData={calendarData} />
                    </Paper>
                </Box>
            </Modal>
        </>
    );
};

export default Calendar;
