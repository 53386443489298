import React from "react";
import { useLocation } from "react-router";
import AddEquipment from "./AddEquipment.page";
import ViewEquipment from "./ViewEquipment.page";

const Equipment = () => {
    const location = useLocation();

    return (
        <>
            {location.pathname === "/View/Equipment/AddEquipment" && (
                <AddEquipment />
            )}
            {location.pathname === "/View/Equipment" && <ViewEquipment />}
        </>
    );
};

export default Equipment;
