import { Add, SearchRounded } from "@mui/icons-material";
import { Button, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import Table from "../../../../Components/Table/Table.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { get } from "../../../../utils/Caller";
import { StockItemURL } from "../../../../utils/config";

const ViewStockItem = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(20);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState();

    const columns = [
        {
            field: "item_name",
            headerName: "Item Name",
            width: 150,
        },
        {
            field: "SKU",
            headerName: "SKU",
            width: 150,
        },
        {
            field: "item_type",
            headerName: "Item Type",
            width: 150,
        },
        {
            field: "reorder_level",
            headerName: "Reorder Level",
            width: 150,
        },
        {
            field: "stock_item_tracking",
            headerName: "Stock Item Tracking",
            width: 150,
        },
        {
            field: "item_can_be",
            headerName: "Item Can Be",
            width: 150,
        },
        {
            field: "is_item_returnable",
            headerName: "Is Item Returnable",
            width: 150,
        },
        {
            field: "UPC",
            headerName: "UPC",
            width: 150,
        },
        {
            field: "EAN",
            headerName: "EAN",
            width: 150,
        },
        {
            field: "MPN",
            headerName: "MPN",
            width: 150,
        },
        {
            field: "ISBN",
            headerName: "ISBN",
            width: 150,
        },
        {
            field: "selling_price",
            headerName: "Selling Price",
            width: 150,
        },
        {
            field: "additional_sales_info",
            headerName: "Additional Sales Info",
            width: 150,
        },
        {
            field: "purchasing_price",
            headerName: "Purchasing Price",
            width: 150,
        },
        {
            field: "addtional_purchase_info",
            headerName: "Addtional Purchase Info",
            width: 150,
        },
        {
            field: "item_group_id",
            headerName: "Item Group Id",
            width: 150,
        },
        {
            field: "dimensions",
            headerName: "Dimensions",
            width: 150,
        },
        {
            field: "manufacturer",
            headerName: "Manufacturer",
            width: 150,
        },
        {
            field: "inventory_tracking_chart_of_account",
            headerName: "Inventory Tracking Chart Of Account",
            width: 150,
        },
        {
            field: "purchase_chart_of_account",
            headerName: "Purchase Chart Of Account",
            width: 150,
        },
        {
            field: "sales_chart_of_account",
            headerName: "Sales Chart Of Account",
            width: 150,
        },
        {
            field: "unit_of_measure",
            headerName: "Unit Of Measure",
            width: 150,
        },
        {
            field: "tax",
            headerName: "Tax",
            width: 150,
        },
    ];

    useEffect(() => {
        let tableData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(StockItemURL, {
            limit: limit,
            filter: { item_name: filter },
        }).then((res) => {
            console.log(res);
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    tableData.push({
                        ...data,
                        inventory_tracking_chart_of_account:
                            data.chart_of_account.account_code,
                        purchase_chart_of_account:
                            data.chart_of_account.account_code,
                        sales_chart_of_account:
                            data.chart_of_account.account_code,
                        unit_of_measure: data.unit_of_measure.short_code,
                        tax: data.tax.tax_name,
                    });
                });
            setRows(tableData);
        });
    }, [refreshTable, filter, limit, rows.length]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <SearchRounded />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setFilter(e.target.value);
                        dispatch(setRefresh(!refreshTable));
                    }}
                />
                <div style={{ display: "flex", gap: "2ch" }}>
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<Add />}
                        onClick={() => {
                            navigate("/View/Inventory/StockItem/AddStockItem");
                        }}
                    >
                        Add Stock Item
                    </Button>
                </div>
            </div>
            <div className="TableContainer">
                <Table
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                />
            </div>
        </div>
    );
};

export default ViewStockItem;
