import React, { useEffect, useState } from "react";
import { Autocomplete, Grid, Paper, TextField } from "@mui/material";
import { ApprovalRounded, Cancel } from "@mui/icons-material";
import { Box } from "@mui/system";
import { get, patcher } from "../../utils/Caller";
import {
    ProjectAllRequestURL,
    EmployeeURL,
    // PVRequestURL,
} from "../../utils/config";
import { LoadingButton } from "@mui/lab";
import { SnackBar } from "../../Components/SnackBar/SnackBar.component";
// import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
// import FinanceApprovalColumns from "./FinanceApprovalColumns.json";

// const AATYPE = ["Attendance", "Absence"];

const FinanceApproval = () => {
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [limit, setLimit] = useState(8);
    const [filter, setFilter] = useState();
    const [options, setOptions] = useState([]);
    const [optionSelect, setOptionSelect] = useState();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setMsg] = useState("");
    const [pageNo, setPageNo] = useState(0);
    // const user = useSelector((state) => state.userState.user);
    const [ids, setIds] = useState([]);
    // const [filterData, setFilterData] = useState(null);
    const [actionNote, setActionNote] = useState("");

    const columns = [
        {
            field: "remark",
            headerName: "Description",
        },
        {
            field: "quantity",
            headerName: "Quantity",
        },
        {
            field: "quantity_type",
            headerName: "Unit",
        },
        {
            field: "unit_rate",
            headerName: "Unit Rate",
        },
        {
            field: "vat_amount",
            headerName: "Vat",
        },
        {
            field: "total_amount",
            headerName: "Amount",
        },
    ];

    useEffect(() => {
        setLoading(true);
        let tableData = [];
        if (rows.length === 0) {
            setLoading(true);
        }
        get(ProjectAllRequestURL, {
            approval_status: 2,
            finance_approval_status: 1,
            limit: 100,
        }).then((res) => {
            // console.log(res);
            setLoading(false);
            res?.data?.forEach((data) => {
                tableData.push({
                    ...data,
                });
            });
            console.log(tableData)
            setRows(tableData);
        });
    }, [rows.length]);

    const sendApproval = () => {
        let approved = {
            id: ids,
            approval_status: 3,
            approved_by_id: parseInt(optionSelect.split("-")[0]),
            action_note: actionNote,
        };
        apiHandler(approved);
    };

    const sendRejection = () => {
        let rejected = {
            id: ids,
            approval_status: 3,
            approved_by_id: parseInt(optionSelect.split("-")[0]),
            action_note: actionNote,
        };
        apiHandler(rejected);
    };

    const apiHandler = (data) => {
        patcher(ProjectAllRequestURL, { ...data }).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data?.success) {
                setSuccess(true);
                setError(false);
                // formSubmitHandler(filterData);
            }
        });
    };

    const addLimit = () => {
        setLimit(limit + 8);
    };

    useEffect(() => {
        get(EmployeeURL, {
            limit: 10,
            filter: {
                first_name: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.first_name}`
                    );
                });
            setOptions(data);
        });
    }, [filter]);

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "2ch" }}>
            <Box>
                {columns.length > 0 && (
                    <Paper
                        style={{
                            boxShadow: "0 0 5px silver",
                            padding: "1ch",
                        }}
                        elevation={0}
                    >
                        <Box
                            style={{
                                marginBottom: "1ch",
                                display: "flex",
                                gap: "2ch",
                            }}
                        >
                            <LoadingButton
                                style={{ height: "5ch" }}
                                data-testid="ApproveButton"
                                loading={loading}
                                color="success"
                                variant="contained"
                                endIcon={<ApprovalRounded />}
                                onClick={() => {
                                    sendApproval();
                                }}
                            >
                                {loading ? null : "Approve"}
                            </LoadingButton>
                            <LoadingButton
                                style={{ height: "5ch" }}
                                data-testid="ApproveButton"
                                loading={loading}
                                color="error"
                                variant="contained"
                                endIcon={<Cancel />}
                                onClick={() => {
                                    sendRejection();
                                }}
                            >
                                {loading ? null : "Reject"}
                            </LoadingButton>
                            <Grid item xs={6}>
                                <Autocomplete
                                    size="small"
                                    loading={options.length < 1 ? true : false}
                                    disablePortal
                                    options={options}
                                    onChange={(_event, newValue) => {
                                        setOptionSelect(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            size={"small"}
                                            {...params}
                                            label="Approved By"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6} width={"100%"}>
                                <TextField
                                    fullWidth
                                    size={"small"}
                                    name="action_note"
                                    label="Remark"
                                    variant="outlined"
                                    multiline
                                    onChange={(e) => {
                                        setActionNote(e.target.value);
                                    }}
                                    value={actionNote}
                                />
                            </Grid>
                        </Box>

                        <DataGrid
                            checkboxSelection
                            loading={loading}
                            rows={rows}
                            columns={columns}
                            onPageChange={(page) => {
                                if (pageNo < page) {
                                    setPageNo(pageNo + 1);
                                    addLimit();
                                }
                            }}
                            autoHeight={true}
                            pageSize={8}
                            rowsPerPageOptions={[8]}
                            onSelectionModelChange={(id) => {
                                setIds(id);
                            }}
                        />
                    </Paper>
                )}
            </Box>

            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};

export default FinanceApproval;
