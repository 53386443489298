import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
    Box,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { EditRounded } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { patcher, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    CurrencyURL,
    GeneralJournalHeaderURL,
    GetUser,
    JournalTypeURL,
    RecurringGeneralJournalURL,
} from "../../../../utils/config";
import dateFormatter from "../../../../utils/dateFormater";

const EditGeneralJournalHeader = (props) => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState("");
    const [filter2, setFilter2] = useState("");
    const [filter3, setFilter3] = useState("");
    const [filter4, setFilter4] = useState("");
    const [options1, setOptions1] = useState([]);
    const [optionsId1, setOptionsID1] = useState();
    const [optionSelect1, setOptionSelect1] = useState("");
    const [options2, setOptions2] = useState([]);
    const [optionsId2, setOptionsID2] = useState();
    const [optionSelect2, setOptionSelect2] = useState("");
    const [options3, setOptions3] = useState([]);
    const [optionsId3, setOptionsID3] = useState();
    const [optionSelect3, setOptionSelect3] = useState("");
    const [options4, setOptions4] = useState([]);
    const [optionsId4, setOptionsID4] = useState();
    const [optionSelect4, setOptionSelect4] = useState("");
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [msg, setMsg] = useState("");
    const [JournalStatus, setJournalStatus] = useState(
        selectedRow.journal_status
    );
    const [ReportBasis, setReportBasis] = useState(selectedRow.report_basis);
    const [JournalSource, setJournalSource] = useState(
        selectedRow.journal_source
    );
    const [JournalPostingStatus, setJournalPostingStatus] = useState(
        selectedRow.journal_posting_status
    );

    const JOURNAL_STATUS = ["Active", "Reversed", "Deleted", "Void"];
    const REPORT_BASIS = ["Accrual", "Cash", "Both"];
    const JOURNAL_SOURCE = [
        "Manual Journal",
        "Interfaced Journal",
        "Uploaded",
        "Auto",
    ];
    const JOURNAL_POSTING_STATUS = ["POSTED", "DRAFT", "SCHEDULED", "VOID"];

    useEffect(() => {
        if (optionSelect1 === "")
            setOptionSelect1(
                `${selectedRow.journal_type?.id} - ${selectedRow.journalType}`
            );
        if (optionSelect2 === "")
            setOptionSelect2(
                `${selectedRow.recurring_general_journal?.id} - ${selectedRow.recurringGeneralJournal}`
            );
        if (optionSelect3 === "")
            setOptionSelect3(
                `${selectedRow.currency?.id} - ${selectedRow.currencyName}`
            );
        if (optionSelect4 === "")
            setOptionSelect4(
                `${selectedRow.posting_responsible_user?.id} - ${selectedRow.userName}`
            );
    }, [
        selectedRow,
        optionSelect1,
        optionSelect2,
        optionSelect3,
        optionSelect4,
    ]);

    const INITIAL_VALUES = {
        journal_date: dateFormatter(selectedRow.journal_date),
        posting_reference: selectedRow.posting_reference,
        reference_number: selectedRow.reference_number,
        notes: selectedRow.notes,
        journal_type_reference: selectedRow.journal_type_reference,
        total_amount: selectedRow.total_amount,
        journal_type_id: selectedRow.journal_type?.id,
        recurring_general_journal_id: selectedRow.recurring_general_journal?.id,
        currency_id: selectedRow.currency?.id,
        posting_responsible_user_id: selectedRow.posting_responsible_user?.id,
        isProtectedForEdit: false,
        journal_status: JournalStatus,
        report_basis: ReportBasis,
        journal_source: JournalSource,
        journal_posting_status: JournalPostingStatus,
    };

    const FORM_VALIDATION = Yup.object().shape({
        journal_date: Yup.string().min(1).required("*Required"),
        posting_reference: Yup.string().min(1).required("*Required"),
        reference_number: Yup.string().min(1),
        journal_status: Yup.number().min(1).required("*Required"),
        notes: Yup.string().min(1).required("*Required"),
        report_basis: Yup.number().min(1).required("*Required"),
        journal_source: Yup.number().min(1).required("*Required"),
        journal_posting_status: Yup.number().min(1).required("*Required"),
        journal_type_reference: Yup.string().min(1),
        total_amount: Yup.number().min(1),
        // journal_type_id: Yup.number().min(1),
        // recurring_general_journal_id: Yup.number().min(1),
        // currency_id: Yup.number().min(1).required("*Required"),
        // posting_responsible_user_id: Yup.number().min(1).required("*Required"),
    });

    useEffect(() => {
        get(JournalTypeURL, {
            limit: 10,
            filter: {
                type: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.type}`
                    );
                });
            res && setOptionsID1(res.data);
            setOptions1(data);
        });

        get(RecurringGeneralJournalURL, {
            limit: 10,
            filter: {
                profile_name: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.profile_name}`
                    );
                });
            res && setOptionsID2(res.data);
            setOptions2(data);
        });

        get(CurrencyURL, {
            limit: 10,
            filter: {
                name: filter3,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.name}`
                    );
                });
            res && setOptionsID3(res.data);
            setOptions3(data);
        });

        get(GetUser, {
            limit: 10,
            filter: {
                username: filter4,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.username}`
                    );
                });
            res && setOptionsID4(res.data);
            setOptions4(data);
        });
    }, [filter, filter2, filter3, filter4]);

    const formSubmitHandler = (updateData, actions) => {
        setLoading(true);
        const update = {
            id: selectedRow.id,
            updateData,
        };

        patcher(GeneralJournalHeaderURL, update).then((res) => {
            setLoading(false);
            // console.log(res.error);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                dispatch(setRefresh(!refreshTable));
                setSuccess(true);
                setError(false);
                props.close();
            }
        });
    };

    return (
        <div>
            <TitleHeader
                title="Edit General Journal Header"
                icon={<EditRounded />}
            />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;

                        optionsId1.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.type}` === optionSelect1
                            ) {
                                values.journal_type_id = name.id;
                            }
                        });
                        optionsId2.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.profile_name}` ===
                                optionSelect2
                            ) {
                                values.recurring_general_journal_id = name.id;
                            }
                        });
                        optionsId3.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.name}` === optionSelect3
                            ) {
                                values.currency_id = name.id;
                            }
                        });
                        optionsId4.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.username}` ===
                                optionSelect4
                            ) {
                                values.posting_responsible_user_id = name.id;
                            }
                        });

                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>Journal Date</FormLabel>
                                    <TextFieldWrapper
                                        name="journal_date"
                                        variant="outlined"
                                        type="date"
                                    />
                                </FormControl>
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="posting_reference"
                                    label="Posting Reference"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="reference_number"
                                    label="Reference Number"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    select
                                    name="journal_status"
                                    label="Journal Status"
                                    variant="outlined"
                                    value={JournalStatus}
                                    options={JOURNAL_STATUS ?? " "}
                                    onChange={(e) => {
                                        setJournalStatus(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="notes"
                                    label="Notes"
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    select
                                    name="report_basis"
                                    label="Report Basis"
                                    variant="outlined"
                                    value={ReportBasis}
                                    options={REPORT_BASIS ?? " "}
                                    onChange={(e) => {
                                        setReportBasis(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    select
                                    name="journal_source"
                                    label="Journal Source"
                                    variant="outlined"
                                    value={JournalSource}
                                    options={JOURNAL_SOURCE ?? " "}
                                    onChange={(e) => {
                                        setJournalSource(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    select
                                    name="journal_posting_status"
                                    label="Journal Posting Status"
                                    variant="outlined"
                                    value={JournalPostingStatus}
                                    options={JOURNAL_POSTING_STATUS ?? " "}
                                    onChange={(e) => {
                                        setJournalPostingStatus(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="journal_type_reference"
                                    label="Journal Type Reference"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="total_amount"
                                    label="Total Amount"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect1}
                                    loading={options1.length < 1 ? true : false}
                                    disablePortal
                                    options={options1}
                                    onChange={(event, newValue) => {
                                        setOptionSelect1(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Journal Type"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect2}
                                    loading={options2.length < 1 ? true : false}
                                    disablePortal
                                    options={options2}
                                    onChange={(event, newValue) => {
                                        setOptionSelect2(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Recurring General Journal"
                                            onChange={(event) => {
                                                setFilter2(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect3}
                                    loading={options3.length < 1 ? true : false}
                                    disablePortal
                                    options={options3}
                                    onChange={(event, newValue) => {
                                        setOptionSelect3(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Currency"
                                            onChange={(event) => {
                                                setFilter3(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect4}
                                    loading={options4.length < 1 ? true : false}
                                    disablePortal
                                    options={options4}
                                    onChange={(event, newValue) => {
                                        setOptionSelect4(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Posting Responsible User"
                                            onChange={(event) => {
                                                setFilter4(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Apply"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default EditGeneralJournalHeader;
