import {
    Autocomplete,
    Box,
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { caller, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import { AddRounded } from "@mui/icons-material";
import { EquipmentURL, RequiredEquipmentURL } from "../../../../utils/config";
import { useSelector } from "react-redux";
// import { useNavigate } from "react-router";

const AddRequiredEquipment = ({ next, name, handleTabChange }) => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const [options, setOptions] = useState([]);
    const [optionsId, setOptionsID] = useState();
    const [optionSelect, setOptionSelect] = useState("");
    const [optionsLoading, setOptionsLoading] = useState(true);
    const [filter, setFilter] = useState("");
    const [msg, setMsg] = useState("");
    // const navigate = useNavigate();

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        remark: "",
        actual_equipment_number: "",
        planned_equipment_number: "",
        equipment_lifetime: "",
        expense: "",
        house_rent: "",
        transportation: "",
        total_expense: "",
        equipment_id: null,
    };

    const FORM_VALIDATION = Yup.object().shape({
        actual_equipment_number: Yup.number().min(1).required("Required"),
        planned_equipment_number: Yup.number().min(1).required("Required"),
        equipment_lifetime: Yup.string().min(1).required("*Required"),
        expense: Yup.number().min(2).required("*Required"),
        house_rent: Yup.number().min(2).required("*Required"),
        transportation: Yup.number().min(1).required("*Required"),
        total_expense: Yup.number().min(2).required("*Required"),
    });

    const formSubmitHandler = (data, actions) => {
        // console.log(data);
        setLoading(true);
        caller(RequiredEquipmentURL, data).then((res) => {
            setLoading(false);
            // console.log(res.error);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                actions.resetForm();
            }
        });
    };

    useEffect(() => {
        get(EquipmentURL, {
            limit: 10,
            filter: {
                name: filter,
            },
        }).then((res) => {
            let data = [];
            if (res.data.length === 0) {
                setOptionsLoading(false);
            }
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].name}`
                    );
                });
            res && setOptionsID(res.data);
            setOptions(data);
        });
    }, [filter]);

    return (
        <div>
            <TitleHeader title="Add Required Equipment" icon={<AddRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        values.project_id = selectedRow.id;
                        optionsId.forEach((name, index) => {
                            if (`${name?.id} - ${name.name}` === optionSelect) {
                                values.equipment_id = name.id;
                            }
                        });
                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="actual_equipment_number"
                                    label="Number of Actual Equipment"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="planned_equipment_number"
                                    label="Number of Planned Equipment"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="equipment_lifetime"
                                    label="Equipment Lifetime"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="expense"
                                    label="Expense"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="house_rent"
                                    label="House Rent"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="transportation"
                                    label="Transportation"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="total_expense"
                                    label="Total Expense"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={4}>
                                <Autocomplete
                                    name="equipment_id"
                                    loading={
                                        options.length < 1 && optionsLoading
                                    }
                                    disablePortal
                                    options={options}
                                    onChange={(event, newValue) => {
                                        setOptionSelect(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Equipment Name"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="remark"
                                    label="Remark"
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                    maxRows={3}
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div
                            style={{
                                position: "relative",
                                bottom: "-12ch",
                                right: "60px",
                            }}
                        >
                            {/* <Button
                                variant="text"
                                onClick={() => {
                                    handleTabChange(null, "3");
                                    // navigate(next);
                                }}
                            >
                                {name}
                            </Button> */}
                            <LoadingButton
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};

export default AddRequiredEquipment;
