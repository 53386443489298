import React from "react";
import { useLocation } from "react-router";

import AddDocumentCategory from "./AddDocumentCategory.page";
import ViewDocumentCategory from "./ViewDocumentCategory.page";

const DocumentCategory = () => {
    const location = useLocation();

    return (
        <>
            {location.pathname === "/View/DocumentCategory/AddDocumentCategory" && (
                <AddDocumentCategory />
            )}
            {location.pathname === "/View/DocumentCategory" && <ViewDocumentCategory />}
        </>
    );
};

export default DocumentCategory;
