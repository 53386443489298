import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import AddManpowerRequirement from "./AddManpowerRequirement.page";
import ViewManpowerRequirement from "./ViewManpowerRequirement.page";

const ManpowerRequirement = ({ next, name, handleTabChange }) => {
    const location = useLocation();
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    return (
        <>
            {(location.pathname ===
                `/View/ViewProject/ProjectAddDetail/AddProjectTask` ||
                location.pathname ===
                    `/View/ViewProject/${selectedRow.id}/ManpowerRequirement/AddManpowerRequirement`) && (
                <AddManpowerRequirement
                    next={next}
                    name={name}
                    handleTabChange={handleTabChange}
                />
            )}
            {(location.pathname ===
                `/View/ViewProject/ProjectAddDetail/AddProjectTask/ManpowerRequirement` ||
                location.pathname ===
                    `/View/ViewProject/${selectedRow.id}/ManpowerRequirement`) && (
                <>
                    <ViewManpowerRequirement />
                </>
            )}
        </>
    );
};

export default ManpowerRequirement;
