import { AddRounded } from "@mui/icons-material";
import React from "react";
import { useNavigate } from "react-router";
import TitleHeader from "../../Components/TitleHeader/TitleHeader.component";
import { caller } from "../../utils/Caller";
import { SalesURL } from "../../utils/config";
import "./addQuote.style.css";
const AddQuote = () => {
    const navigate = useNavigate();
    return (
        <div>
            <TitleHeader title="Add Quote" icon={<AddRounded />} />
            <div className="FormContainer">
                <form
                    name="registrationForm"
                    id="registration_form"
                    action=""
                    class="bg-white p-5 contact-form"
                    onSubmit={(e) => {
                        e.preventDefault();
                        const formData = new FormData(e.target);
                        let values = Object.fromEntries(formData);
                        Object.keys(values).map((value) => {
                            if (values[value] === "on") {
                                return (values[value] = true);
                            } else return values[value];
                        });
                        caller(SalesURL, { ...values })
                            .then((res) => {
                                console.log(res);
                                navigate("/View/ViewQuote");
                            })
                            .catch((e) => {
                                console.log(e);
                            });
                    }}
                >
                    <strong>Personal Details</strong>
                    <div class="row">
                        <div class="form-group col-md-8">
                            <input
                                type="text"
                                class="form-control"
                                name="name_of_company"
                                id="name_of_company"
                                placeholder="Name Of Company"
                                required
                            />
                            <span
                                id="name_of_company_error"
                                class="error-message"
                            ></span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-8">
                            <input
                                type="text"
                                class="form-control"
                                name="full_name"
                                id="full_name"
                                placeholder="Full Name"
                                required
                            />
                            <span
                                id="full_name_error"
                                class="error-message"
                            ></span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="form-group col-md-6">
                            <input
                                type="email"
                                class="form-control"
                                name="email"
                                id="email"
                                placeholder="Email Address"
                                required
                            />
                            <span id="email_error" class="error-message"></span>
                        </div>
                        <div class="form-group col-md-6">
                            <input
                                type="tel"
                                class="form-control"
                                name="phone_number"
                                id="phone_number"
                                placeholder="Phone Number"
                                minlength="10"
                                pattern="[0-9]+"
                                maxlength="14"
                                required
                            />
                            <span
                                id="phone_number_error"
                                class="error-message"
                            ></span>
                        </div>
                    </div>
                    <strong>Address</strong>
                    <div class="row">
                        <div class="form-group col-md-6">
                            <input
                                type="text"
                                class="form-control"
                                name="street_name"
                                id="street_name"
                                placeholder="Street Name"
                                required
                            />
                            <span
                                id="street_name_error"
                                class="error-message"
                            ></span>
                        </div>
                        <div class="form-group col-md-6">
                            <input
                                type="text"
                                class="form-control"
                                name="region"
                                id="region"
                                placeholder="Region"
                                required
                            />
                            <span
                                id="region_error"
                                class="error-message"
                            ></span>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="form-group col-md-6">
                            <input
                                type="text"
                                class="form-control"
                                name="city"
                                id="city"
                                placeholder="City"
                                required
                            />
                            <span id="city_error" class="error-message"></span>
                        </div>
                    </div>
                    <hr />
                    <strong>Project Details</strong>
                    <br />
                    <span>Aluminum Profile</span>
                    <div class="row mt-4">
                        <div class="form-check col-md-4 mb-4">
                            <input
                                class="form-check-input checkbox-2x"
                                type="checkbox"
                                id="ltz_profile"
                                name="ltz_profile"
                            />
                            <label
                                class="form-check-label h5"
                                for="flexCheckDefault"
                            >
                                L, T, Z Profile
                            </label>
                        </div>
                        <div class="form-check col-md-4 mb-4">
                            <input
                                class="form-check-input checkbox-2x"
                                type="checkbox"
                                id="curtain_profile"
                                name="curtain_profile"
                            />
                            <label
                                class="form-check-label h5"
                                for="flexCheckDefault"
                            >
                                Curtain Profile
                            </label>
                        </div>
                        <div class="form-check col-md-4 mb-4">
                            <input
                                class="form-check-input checkbox-2x"
                                type="checkbox"
                                id="fasha_zocolo"
                                name="fasha_zocolo"
                            />
                            <label
                                class="form-check-label h5"
                                for="flexCheckDefault"
                            >
                                Fasha &#38; Zocolo
                            </label>
                        </div>
                        <div class="form-check col-md-4 mb-4">
                            <input
                                class="form-check-input checkbox-2x"
                                type="checkbox"
                                id="oval_flat_ferma"
                                name="oval_flat_ferma"
                            />
                            <label
                                class="form-check-label h5"
                                for="flexCheckDefault"
                            >
                                Oval &#38; Flat Ferma
                            </label>
                        </div>
                        <div class="form-check col-md-4 mb-4">
                            <input
                                class="form-check-input checkbox-2x"
                                type="checkbox"
                                id="sliding_cup"
                                name="sliding_cup"
                            />
                            <label
                                class="form-check-label h5"
                                for="flexCheckDefault"
                            >
                                Sliding Cup
                            </label>
                        </div>
                        <div class="form-check col-md-4 mb-4">
                            <input
                                class="form-check-input checkbox-2x"
                                type="checkbox"
                                id="pressure_plate"
                                name="pressure_plate"
                            />
                            <label
                                class="form-check-label h5"
                                for="flexCheckDefault"
                            >
                                Pressure Plate
                            </label>
                        </div>
                        <div class="form-check col-md-4 mb-4">
                            <input
                                class="form-check-input checkbox-2x"
                                type="checkbox"
                                id="RHS"
                                name="RHS"
                            />
                            <label
                                class="form-check-label h5"
                                for="flexCheckDefault"
                            >
                                RHS
                            </label>
                        </div>
                        <div class="form-check col-md-4 mb-4">
                            <input
                                class="form-check-input checkbox-2x"
                                type="checkbox"
                                id="external_internal_profile"
                                name="external_internal_profile"
                            />
                            <label
                                class="form-check-label h5"
                                for="flexCheckDefault"
                            >
                                External &#38; Internal Profile
                            </label>
                        </div>
                        <div class="form-check col-md-4 mb-4">
                            <input
                                class="form-check-input checkbox-2x"
                                type="checkbox"
                                id="other_profile_checkbox"
                                name="other_profile_checkbox"
                            />
                            <label
                                class="form-check-label h5"
                                for="flexCheckDefault"
                            >
                                Other
                            </label>
                        </div>
                        <div id="other_profile">
                            <div class="form-group">
                                <input
                                    type="text"
                                    class="form-control"
                                    name="other_profile_text"
                                    id="other_profile_text"
                                    placeholder="Other Profile"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-6 row border-dark-right me-4">
                            <span>Glass Color</span>
                            <br />
                            <div class="form-check col-md-6 mb-4">
                                <input
                                    class="form-check-input checkbox-2x"
                                    type="checkbox"
                                    id="clear"
                                    name="clear"
                                />
                                <label
                                    class="form-check-label h5"
                                    for="flexCheckDefault"
                                >
                                    Clear
                                </label>
                            </div>
                            <div class="form-check col-md-6 mb-4">
                                <input
                                    class="form-check-input checkbox-2x"
                                    type="checkbox"
                                    id="reflective"
                                    name="reflective"
                                />
                                <label
                                    class="form-check-label h5"
                                    for="flexCheckDefault"
                                >
                                    Reflective
                                </label>
                            </div>
                            <div class="form-check col-md-6 mb-4">
                                <input
                                    class="form-check-input checkbox-2x"
                                    type="checkbox"
                                    id="tinted"
                                    name="tinted"
                                />
                                <label
                                    class="form-check-label h5"
                                    for="flexCheckDefault"
                                >
                                    Tinted
                                </label>
                            </div>
                            <div class="form-check col-md-6 mb-4">
                                <input
                                    class="form-check-input checkbox-2x"
                                    type="checkbox"
                                    id="tempered"
                                    name="tempered"
                                />
                                <label
                                    class="form-check-label h5"
                                    for="flexCheckDefault"
                                >
                                    Tempered
                                </label>
                            </div>
                            <div class="form-check col-md-6 mb-4">
                                <input
                                    class="form-check-input checkbox-2x"
                                    type="checkbox"
                                    id="laminated"
                                    name="laminated"
                                />
                                <label
                                    class="form-check-label h5"
                                    for="flexCheckDefault"
                                >
                                    Laminated
                                </label>
                            </div>
                            <div class="form-check col-md-6 mb-4">
                                <input
                                    class="form-check-input checkbox-2x"
                                    type="checkbox"
                                    id="other_glass_checkbox"
                                    name="other_glass_checkbox"
                                />
                                <label
                                    class="form-check-label h5"
                                    for="flexCheckDefault"
                                >
                                    Other
                                </label>
                            </div>
                            <div class="col-md-12" id="other_glass">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="other_glass_text"
                                        id="other_glass_text"
                                        placeholder="Other Glass Color"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 row">
                            <span>Aluminum Color</span>
                            <br />
                            <div class="form-check col-md-6 mb-4">
                                <input
                                    class="form-check-input checkbox-2x"
                                    type="checkbox"
                                    id="silver"
                                    name="silver"
                                />
                                <label
                                    class="form-check-label h5"
                                    for="flexCheckDefault"
                                >
                                    Silver
                                </label>
                            </div>
                            <div class="form-check col-md-6 mb-4">
                                <input
                                    class="form-check-input checkbox-2x"
                                    type="checkbox"
                                    id="coffee"
                                    name="coffee"
                                />
                                <label
                                    class="form-check-label h5"
                                    for="flexCheckDefault"
                                >
                                    Coffee
                                </label>
                            </div>
                            <div class="form-check col-md-6 mb-4">
                                <input
                                    class="form-check-input checkbox-2x"
                                    type="checkbox"
                                    id="black"
                                    name="black"
                                />
                                <label
                                    class="form-check-label h5"
                                    for="flexCheckDefault"
                                >
                                    Black
                                </label>
                            </div>
                            <div class="form-check col-md-6 mb-4">
                                <input
                                    class="form-check-input checkbox-2x"
                                    type="checkbox"
                                    id="grey"
                                    name="grey"
                                />
                                <label
                                    class="form-check-label h5"
                                    for="flexCheckDefault"
                                >
                                    Grey
                                </label>
                            </div>
                            <div class="form-check col-md-6 mb-4">
                                <input
                                    class="form-check-input checkbox-2x"
                                    type="checkbox"
                                    id="ivory"
                                    name="ivory"
                                />
                                <label
                                    class="form-check-label h5"
                                    for="flexCheckDefault"
                                >
                                    Ivory
                                </label>
                            </div>
                            <div class="form-check col-md-6 mb-4">
                                <input
                                    class="form-check-input checkbox-2x"
                                    type="checkbox"
                                    id="other_aluminum_checkbox"
                                    name="other_aluminum_checkbox"
                                />
                                <label
                                    class="form-check-label h5"
                                    for="flexCheckDefault"
                                >
                                    Other
                                </label>
                            </div>
                            <div class="col-md-12" id="other_aluminum">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="other_aluminum_text"
                                        id="other_aluminum_text"
                                        placeholder="Other Aluminum Color"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5 mb-5">
                        <div class="form-group">
                            <textarea
                                name="quantity_remark"
                                id="quantity_remark"
                                cols="30"
                                rows="7"
                                class="form-control"
                                placeholder="Quantity &#38; Remark"
                            ></textarea>
                        </div>
                        {/* <div class="form-group">
                            <input
                                type="text"
                                class="form-control"
                                name="sales_rep"
                                id="sales_rep"
                                placeholder="Sales Rep"
                                required
                            />
                            <span
                                id="sales_rep_error"
                                class="error-message"
                            ></span>
                        </div> */}
                    </div>
                    <div class="form-group">
                        <input
                            type="submit"
                            value="Submit"
                            class="btn btn-primary py-3 px-5"
                            style={{ float: "right" }}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};
export default AddQuote;
