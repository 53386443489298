import { Add, SearchRounded } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { WeatherDataURL } from "../../../../utils/config";

import { get } from "../../../../utils/Caller";
import OperationsTable from "../../../../Components/Table/OperationsTable.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";

const ViewWeatherData = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(16);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const [filter, setFilter] = useState("");
    const dispatch = useDispatch();
    const [Search, setSearch] = useState("");

    const columns = [
        {
            field: "reason",
            headerName: "Reason",
            width: 100,
        },
        {
            field: "total_lost_hour",
            headerName: "Total Lost Hour",
            width: 100,
        },
        {
            field: "duration_of_lost_hour",
            headerName: "Duration Of Lost Hour",
            width: 100,
        },
        {
            field: "date",
            headerName: "Date",
            width: 100,
        },
        {
            field: "reference",
            headerName: "Reference",
            width: 100,
        },
        {
            field: "manpowerName",
            headerName: "Manpower",
            width: 100,
        },
        {
            field: "projectName",
            headerName: "Project Name",
            width: 100,
        },
    ];

    useEffect(() => {
        let requestData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(WeatherDataURL, {
            limit: limit,
            filter: { project_id: selectedRow.id, all: filter },
        }).then((res) => {
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    requestData.push({
                        ...data,
                        manpowerName: data.manpower?.name,
                        projectName: data.project?.name,
                    });
                });
            setRows(requestData);
        });
    }, [refreshTable, limit, rows.length, selectedRow, filter]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <Button
                    variant="contained"
                    color="success"
                    endIcon={<Add />}
                    onClick={() => {
                        navigate(
                            `/View/ViewProject/${selectedRow.id}/WeatherData/AddWeatherData`
                        );
                    }}
                >
                    Add Weather Data
                </Button>
            </div>
            <div className="TableContainer">
                <OperationsTable
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                />
            </div>
        </div>
    );
};

export default ViewWeatherData;
