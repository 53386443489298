import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { EditRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../../Components/TextFieldWrapper.component";
import "../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../../../Components/SnackBar/SnackBar.component.jsx";
import { patcher, get } from "../../../utils/Caller";
import TitleHeader from "../../../Components/TitleHeader/TitleHeader.component";
import { GetUser, RoleURL } from "../../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import { setRefresh } from "../../../Redux/Slice/refreshSlice";

const EditUser = (props) => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setMsg] = useState("");

    const [filter1, setFilter1] = useState("");
    const [options1, setOptions1] = useState([]);
    const [optionsId1, setOptionsID1] = useState();
    const [optionSelect1, setOptionSelect1] = useState("");
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.userState.user);

    const phoneRegex = /^\d*$/gm;

    useEffect(() => {
        if (optionSelect1 === "")
            setOptionSelect1(
                `${selectedRow.role?.id} - ${selectedRow.roleName}`
            );
    }, [optionSelect1, selectedRow.role?.id, selectedRow.roleName]);

    const INITIAL_VALUES = {
        email: selectedRow.email,
        phone_number: selectedRow.phone_number,
        username: selectedRow.username,
        roleId: selectedRow.role?.id,
        isProtectedForEdit: false,
    };

    const FORM_VALIDATION = Yup.object().shape({
        email: Yup.string()
            .email("Must be a vaild email")
            .required("*Required"),
        phone_number: Yup.string()
            .min(4)
            .matches(phoneRegex, "Enter a vaild Phone number")
            .required("*Required"),
        username: Yup.string().min(1).required("*Required"),
    });
    useEffect(() => {
        get(RoleURL, {
            limit: 10,
            filter: {
                name: filter1,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.name}`
                    );
                });
            res && setOptionsID1(res.data);
            setOptions1(data);
            // console.log(data);
        });
    }, [filter1]);

    const formSubmitHandler = (updateData, actions) => {
        setLoading(true);
        const update = {
            id: selectedRow.id,
            updateData,
            concurrency_stamp: user.concurrency_stamp,
        };

        patcher(GetUser, update).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                dispatch(setRefresh(!refreshTable));
                setSuccess(true);
                setError(false);
                actions.resetForm();
                return 1;
            }
        });
    };

    return (
        <div>
            <TitleHeader title="Edit User" icon={<EditRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;

                        optionsId1.forEach((name, index) => {
                            if (`${name.id} - ${name.name}` === optionSelect1) {
                                values.roleId = name.id;
                            }
                        });

                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="phone_number"
                                    label="Phone Number"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="username"
                                    label="Username"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect1}
                                    loading={options1.length < 1 ? true : false}
                                    disablePortal
                                    options={options1}
                                    onChange={(event, newValue) => {
                                        setOptionSelect1(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Role"
                                            onChange={(event) => {
                                                setFilter1(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Apply"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default EditUser;
