import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import AddToDos from "./AddToDos.page";
import ViewToDos from "./ViewToDos.page";

const ToDos = ({ next, name, handleTabChange }) => {
    const location = useLocation();
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    return (
        <>
            {(location.pathname ===
                `/View/ViewProject/ProjectAddDetail/AddProjectTask` ||
                location.pathname ===
                    `/View/ViewProject/${selectedRow.id}/ToDos/AddToDos`) && (
                <AddToDos
                    next={next}
                    name={name}
                    handleTabChange={handleTabChange}
                />
            )}
            {(location.pathname ===
                `/View/ViewProject/ProjectAddDetail/AddProjectTask/ToDos` ||
                location.pathname ===
                    `/View/ViewProject/${selectedRow.id}/ToDos`) && (
                <>
                    <ViewToDos />
                </>
            )}
        </>
    );
};

export default ToDos;
