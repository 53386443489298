import React from "react";
import { useLocation } from "react-router";
import AddEvaluation from "./AddEvaluation.page";
import ViewEvaluation from "./ViewEvaluation.page";

const Evaluation = () => {
    const location = useLocation();

    return (
        <>
            {location.pathname === "/View/Evaluation/AddEvaluation" && (
                <AddEvaluation />
            )}
            {location.pathname === "/View/Evaluation" && <ViewEvaluation />}
        </>
    );
};

export default Evaluation;
