import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import AddRequestPayment from "./AddRequestPayment.page";
import ViewRequestPayment from "./ViewRequestPayment.page";

const RequestPayment = ({ next, name }) => {
    const location = useLocation();
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    return (
        <>
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/RequestPayment/AddRequestPayment` && (
                <AddRequestPayment next={next} name={name} />
            )}
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/RequestPayment` && (
                <>
                    <ViewRequestPayment />
                </>
            )}
        </>
    );
};

export default RequestPayment;
