import interact from "interactjs";

export const resize = () => {
    interact(".resize-drag")
        .resizable({
            // resize from all edges and corners
            edges: { left: false, right: true, bottom: false, top: false },

            listeners: {
                move(event) {
                    var target = event.target;
                    var x = parseFloat(target.getAttribute("data-x")) || 0;
                    // var y = parseFloat(target.getAttribute("data-y")) || 0;

                    // update the element's style
                    target.style.width = event.rect.width + "px";
                    // target.style.height = event.rect.height + "px";
                    // translate when resizing from top or left edges
                    x += event.deltaRect.left;
                    // y += event.deltaRect.top;

                    // target.style.transform =
                    //     "translate(" + x + "px," + y + "px)";
                    target.style.transform =
                        "translate(" + x + "px," + 0 + "px)";

                    target.setAttribute("data-x", x);
                    // target.setAttribute("data-y", y);
                    // target.textContent =
                    //     Math.round(event.rect.width) +
                    //     "\u00D7" +
                    //     Math.round(event.rect.height);
                },
            },
            modifiers: [
                // keep the edges inside the parent
                interact.modifiers.restrictEdges({
                    outer: "parent",
                }),

                // minimum size
                interact.modifiers.restrictSize({
                    min: { width: 500, height: 50 },
                }),
            ],

            inertia: true,
        })
        .draggable({
            listeners: { move: window.dragMoveListener },
            inertia: true,
            modifiers: [
                interact.modifiers.restrictRect({
                    restriction: "parent",
                    endOnly: true,
                }),
            ],
        });
};
