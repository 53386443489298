import React, { useState } from "react";
import {
  Box,
  Divider,
  List,
  ListItemButton,
  Paper,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { CollapseMenu } from "../../../Components/CollapseMenu/CollapseMenu.component";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import {
  HomeWorkRounded,
  InventoryRounded,
  LocalAtmRounded,
  Person,
  Speed,
  ManageAccounts,
  ShoppingBasket,
} from "@mui/icons-material";
import SideBarMenus from "./SideBarMenus.json";
import { useSelector } from "react-redux";

const SideBar = ({ menuToggle, setMenuToggle }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.userState.user);
  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener("resize", (e) => {
    let innerWidth = window.innerWidth;
    if (typeof e.currentTarget.window.innerWidth === "number") {
      innerWidth = e.currentTarget.window.innerWidth;
    }
    setWidth(innerWidth);
  });

  return (
    <Paper
      className="SideBar"
      style={{ minWidth: !menuToggle && "25ch", display: "flex" }}
    >
      {!menuToggle && (
        <List
          className={"SideBarWithCaption"}
          style={{ marginTop: "2ch", padding: "0ch 0ch 0ch 1ch" }}
        >
          <ListItemButton
            key={"Dashboard"}
            onClick={() => navigate("/View/Dashboard")}
          >
            <Box
              style={{
                width: "100%",
                backgroundColor:
                  location.pathname === "/View/Dashboard" && "#fca311",
                borderRadius: "10px",
                padding: "1ch",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Speed
                style={{
                  color:
                    // location.pathname === "/View/Dashboard"
                    "#14213d",
                  // : "#e5e5e5",
                  paddingRight: "1ch",
                  fontSize: "xxx-large",
                }}
              />
              <Typography
                style={{
                  fontWeight: "bolder",
                  letterSpacing: "1px",
                  color:
                    // location.pathname === "/View/Dashboard"
                    "#14213d",
                  // : "#e5e5e5",
                }}
              >
                Dashboard
              </Typography>
            </Box>
          </ListItemButton>
          <Divider
            key={"divider"}
            style={{
              borderColor: "silver",
            }}
          />
          {user.actions?.find((role) => {
            if (role === "admin" || role === "super") {
              return true;
            } else return false;
          }) && (
            <CollapseMenu
              key={0}
              icon={<ManageAccounts />}
              title="Accounts"
              menus={SideBarMenus.Account}
              pathname={SideBarMenus.AccountPath}
              open={Boolean(
                location.pathname.match(
                  new RegExp(
                    `${SideBarMenus.AccountPath}`.replaceAll(/,/g, "|"),
                  ),
                ),
              )}
            />
          )}
          {user.actions?.find((role) => {
            if (
              role === "PROJECT_TWO" ||
              role === "PROJECT_ONE" ||
              role === "admin" ||
              role === "super"
            ) {
              return true;
            } else return false;
          }) && (
            <CollapseMenu
              key={1}
              icon={<HomeWorkRounded />}
              title="Projects"
              menus={[
                user.actions?.find((role) => {
                  if (
                    role === "PROJECT_ONE" ||
                    role === "admin" ||
                    role === "super"
                  ) {
                    return true;
                  } else return false;
                }) ? (
                  <CollapseMenu
                    // icon={<AccountTreeIcon />}
                    title="Master"
                    menus={SideBarMenus.Master}
                    pathname={SideBarMenus.MasterPath}
                    open={Boolean(
                      location.pathname.match(
                        new RegExp(
                          `${SideBarMenus.MasterPath}`.replaceAll(",", "|"),
                        ),
                      ),
                    )}
                  />
                ) : (
                  ""
                ),
                "Add",
                "View",
              ]}
              pathname={
                user.actions?.find((role) => {
                  if (
                    role === "PROJECT_ONE" ||
                    role === "admin" ||
                    role === "super"
                  ) {
                    return true;
                  } else return false;
                })
                  ? ["", "/View/AddProject", "/View/ViewProject"]
                  : ["/View/AddProject", "/View/ViewProject"]
              }
              open={Boolean(
                location.pathname.match(
                  new RegExp(
                    `${[
                      "/View/AddProject",
                      "/View/ViewProject",
                      ...SideBarMenus.MasterPath,
                    ]}`.replaceAll(/,/g, "|"),
                  ),
                ),
              )}
            />
          )}

          {user.actions?.find((role) => {
            if (
              role === "HCM_TWO" ||
              role === "HCM_ONE" ||
              role === "admin" ||
              role === "super"
            ) {
              return true;
            } else return false;
          }) && (
            <CollapseMenu
              key={2}
              icon={<DashboardCustomizeIcon />}
              title="HCM"
              menus={[
                user.actions?.find((role) => {
                  if (
                    role === "HCM_ONE" ||
                    role === "admin" ||
                    role === "super"
                  ) {
                    return true;
                  } else return false;
                })
                  ? [
                      <CollapseMenu
                        key={1}
                        // icon={<AccountTreeIcon />}
                        title="Master"
                        menus={SideBarMenus.HCMMaster}
                        pathname={SideBarMenus.HCMMasterPath}
                        open={Boolean(
                          location.pathname.match(
                            new RegExp(
                              `${SideBarMenus.HCMMasterPath}`.replaceAll(
                                ",",
                                "|",
                              ),
                            ),
                          ),
                        )}
                      />,
                      <CollapseMenu
                        key={2}
                        // icon={<AccountTreeIcon />}
                        title="Job Positions"
                        menus={SideBarMenus.HCMJobPositions}
                        pathname={SideBarMenus.HCMJobPositionsPath}
                        open={Boolean(
                          location.pathname.match(
                            new RegExp(
                              `${SideBarMenus.HCMJobPositionsPath}`.replaceAll(
                                ",",
                                "|",
                              ),
                            ),
                          ),
                        )}
                      />,
                      <CollapseMenu
                        key={4}
                        // icon={<AccountTreeIcon />}
                        title="Company Structure"
                        menus={SideBarMenus.HCMCompanyStructure}
                        pathname={SideBarMenus.HCMCompanyStructurePath}
                        open={Boolean(
                          location.pathname.match(
                            new RegExp(
                              `${SideBarMenus.HCMCompanyStructurePath}`.replaceAll(
                                ",",
                                "|",
                              ),
                            ),
                          ),
                        )}
                      />,
                      <CollapseMenu
                        key={5}
                        // icon={<AccountTreeIcon />}
                        title="Payroll"
                        menus={["Payroll Slip", ...SideBarMenus.HCMPayroll]}
                        pathname={[
                          "/View/HCM/PayrollSlip",
                          ...SideBarMenus.HCMPayrollPath,
                        ]}
                        open={Boolean(
                          location.pathname.match(
                            new RegExp(
                              `${SideBarMenus.HCMPayrollPath}`.replaceAll(
                                ",",
                                "|",
                              ),
                            ),
                          ),
                        )}
                      />,
                      <CollapseMenu
                        key={6}
                        // icon={<AccountTreeIcon />}
                        title="Recruitment"
                        menus={SideBarMenus.HCMRecruitment}
                        pathname={SideBarMenus.HCMRecruitmentPath}
                        open={Boolean(
                          location.pathname.match(
                            new RegExp(
                              `${SideBarMenus.HCMRecruitmentPath}`.replaceAll(
                                ",",
                                "|",
                              ),
                            ),
                          ),
                        )}
                      />,
                      <CollapseMenu
                        key={7}
                        // icon={<AccountTreeIcon />}
                        title="Time And Leave"
                        menus={[
                          <CollapseMenu
                            title="Attendance Sheet"
                            menus={SideBarMenus.AttendanceSheet}
                            pathname={SideBarMenus.AttendanceSheetPath}
                            open={
                              location.pathname ===
                                "/View/HCM/AttendanceRelease" ||
                              location.pathname === "/View/HCM/AttendanceCreate"
                            }
                          />,
                          ...SideBarMenus.HCMTimeAndLeave,
                        ]}
                        pathname={["", ...SideBarMenus.HCMTimeAndLeavePath]}
                        open={Boolean(
                          location.pathname.match(
                            new RegExp(
                              `${SideBarMenus.HCMTimeAndLeavePath}`.replaceAll(
                                ",",
                                "|",
                              ),
                            ),
                          ),
                        )}
                      />,
                    ]
                  : "",
                "HCM Approval",
                "Calendar",
                "Employee",
              ]}
              pathname={[
                user.actions?.find((role) => {
                  if (
                    role === "HCM_ONE" ||
                    role === "admin" ||
                    role === "super"
                  ) {
                    return true;
                  } else return false;
                })
                  ? []
                  : "",
                "/View/HCM/Approval",
                "/View/HCM/AddCalendar",
                "/View/HCM/Employee",
              ]}
              open={Boolean(location.pathname.match("HCM"))}
            />
          )}
          {user.actions?.find((role) => {
            if (role === "inventory" || role === "admin" || role === "super") {
              return true;
            } else return false;
          }) && (
            <CollapseMenu
              key={3}
              icon={<InventoryRounded />}
              title="Inventory"
              menus={[
                <CollapseMenu
                  key={1}
                  // icon={<AccountTreeIcon />}
                  title="Inventory Master"
                  menus={SideBarMenus.InventoryMaster}
                  pathname={SideBarMenus.InventoryMasterPath}
                  open={Boolean(
                    location.pathname.match(
                      new RegExp(
                        `${SideBarMenus.InventoryMasterPath}`.replaceAll(
                          ",",
                          "|",
                        ),
                      ),
                    ),
                  )}
                />,
                <CollapseMenu
                  key={2}
                  // icon={<AccountTreeIcon />}
                  title="Inventory Stock"
                  menus={SideBarMenus.InventoryStock}
                  pathname={SideBarMenus.InventoryStockPath}
                  open={Boolean(
                    location.pathname.match(
                      new RegExp(
                        `${SideBarMenus.InventoryStockPath}`.replaceAll(
                          ",",
                          "|",
                        ),
                      ),
                    ),
                  )}
                />,
              ]}
              pathname={[""]}
              open={Boolean(location.pathname.match("Inventory"))}
            />
          )}
          {user.actions?.find((role) => {
            if (
              role === "FINANCE_TWO" ||
              role === "FINANCE_ONE" ||
              role === "admin" ||
              role === "super"
            ) {
              return true;
            } else return false;
          }) && (
            <CollapseMenu
              key={4}
              icon={<LocalAtmRounded />}
              title="Finance"
              menus={[
                user.actions?.find((role) => {
                  if (
                    role === "FINANCE_ONE" ||
                    role === "admin" ||
                    role === "super"
                  ) {
                    return true;
                  } else return false;
                }) ? (
                  <CollapseMenu
                    key={1}
                    title="Finance Master"
                    menus={SideBarMenus.FinanceMaster}
                    pathname={SideBarMenus.FinanceMasterPath}
                    open={Boolean(
                      location.pathname.match(
                        new RegExp(
                          `${SideBarMenus.FinanceMasterPath}`.replaceAll(
                            ",",
                            "|",
                          ),
                        ),
                      ),
                    )}
                  />
                ) : (
                  ""
                ),
                <CollapseMenu
                  key={2}
                  title="Finance Operations"
                  menus={SideBarMenus.FinanceOperations}
                  pathname={SideBarMenus.FinanceOperationsPath}
                  open={Boolean(
                    location.pathname.match(
                      new RegExp(
                        `${SideBarMenus.FinanceOperationsPath}`.replaceAll(
                          ",",
                          "|",
                        ),
                      ),
                    ),
                  )}
                />,
              ]}
              pathname={[
                user.actions?.find((role) => {
                  if (
                    role === "FINANCE_ONE" ||
                    role === "admin" ||
                    role === "super"
                  ) {
                    return true;
                  } else return false;
                })
                  ? []
                  : "",
                "",
              ]}
              open={Boolean(location.pathname.match("Finance"))}
            />
          )}
          {user.actions?.find((role) => {
            if (
              role === "PROJECT_ONE" ||
              role === "PROJECT_TWO" ||
              role === "admin" ||
              role === "super"
            ) {
              return true;
            } else return false;
          }) && (
            <CollapseMenu
              key={5}
              icon={<Person />}
              title="Client"
              menus={["Add", "View"]}
              pathname={["/View/AddClient", "/View/ViewClient"]}
              open={Boolean(
                location.pathname.match(
                  new RegExp(
                    `${["/View/AddClient", "/View/ViewClient"]}`.replaceAll(
                      ",",
                      "|",
                    ),
                  ),
                ),
              )}
            />
          )}
          {user.actions?.find((role) => {
            if (role === "sales" || role === "admin" || role === "super") {
              return true;
            } else return false;
          }) && (
            <ListItemButton
              onClick={() => {
                navigate("/View/Sales/ViewQuote");
              }}
            >
              <Box
                style={{
                  alignItems: "center",
                  width: "100%",
                  backgroundColor:
                    location.pathname.match("/View/Sales") && "#fca311",
                  borderRadius: "10px",
                  padding: "1ch",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <ShoppingBasket
                  style={{
                    color:
                      // location.pathname === "/View/Dashboard"
                      "#14213d",
                    // : "#e5e5e5",
                    paddingRight: "1ch",
                    fontSize: "xxx-large",
                  }}
                />
                <Typography
                  style={{
                    fontWeight: "bolder",
                    letterSpacing: "1px",
                    color:
                      // location.pathname === "/View/Dashboard"
                      "#14213d",
                    // : "#e5e5e5",
                  }}
                >
                  Sales
                </Typography>
              </Box>
            </ListItemButton>
          )}
        </List>
      )}

      {menuToggle && width > 600 && (
        <List
          className={"SideBarIconOnly"}
          style={{ marginTop: "2ch", padding: "0ch 0ch 6ch 0.5ch" }}
        >
          <ListItemButton
            onClick={() => {
              navigate("/View/Dashboard");
            }}
          >
            <Box
              style={{
                borderRadius: "10px",
                backgroundColor: location.pathname.match("/View/Dashboard")
                  ? "#fca311"
                  : null,
                display: "flex",
                alignItems: "center",
                padding: "1ch",
              }}
            >
              <Speed
                style={{
                  color:
                    // location.pathname === "/View/Dashboard"
                    "#14213d",
                  // : "#e5e5e5",
                }}
              />
            </Box>
          </ListItemButton>
          <Divider
            key={"divider"}
            style={{
              borderColor: "silver",
            }}
          />
          {user.actions?.find((role) => {
            if (role === "admin" || role === "super") {
              return true;
            } else return false;
          }) && (
            <ListItemButton
              onClick={() => {
                setMenuToggle();
              }}
            >
              <Box
                style={{
                  borderRadius: "10px",
                  backgroundColor: location.pathname.match("/View/Account")
                    ? "#fca311"
                    : null,
                  display: "flex",
                  alignItems: "center",
                  padding: "1ch",
                }}
              >
                <ManageAccounts
                  style={{
                    color:
                      // location.pathname.match("/View/HCM")
                      "#14213d",
                    // : "#e5e5e5",
                  }}
                />
              </Box>
            </ListItemButton>
          )}
          {user.actions?.find((role) => {
            if (
              role === "PROJECT_TWO" ||
              role === "admin" ||
              role === "super"
            ) {
              return true;
            } else return false;
          }) && (
            <ListItemButton
              onClick={() => {
                navigate("/View/ViewProject");
              }}
            >
              <Box
                style={{
                  borderRadius: "10px",
                  backgroundColor: location.pathname.match(
                    new RegExp(
                      `${[
                        "/View/AddProject",
                        "/View/ViewProject",
                        ...SideBarMenus.MasterPath,
                      ]}`.replaceAll(",", "|"),
                    ),
                  )
                    ? "#fca311"
                    : null,
                  display: "flex",
                  alignItems: "center",
                  padding: "1ch",
                }}
              >
                <HomeWorkRounded
                  style={{
                    color: "#14213d",
                  }}
                />
              </Box>
            </ListItemButton>
          )}
          {user.actions?.find((role) => {
            if (
              role === "HCM_TWO" ||
              role === "HCM_ONE" ||
              role === "admin" ||
              role === "super"
            ) {
              return true;
            } else return false;
          }) && (
            <ListItemButton
              onClick={() => {
                setMenuToggle();
              }}
            >
              <Box
                style={{
                  borderRadius: "10px",
                  backgroundColor: location.pathname.match("/View/HCM")
                    ? "#fca311"
                    : null,
                  display: "flex",
                  alignItems: "center",
                  padding: "1ch",
                }}
              >
                <DashboardCustomizeIcon
                  style={{
                    color:
                      // location.pathname.match("/View/HCM")
                      "#14213d",
                    // : "#e5e5e5",
                  }}
                />
              </Box>
            </ListItemButton>
          )}
          {user.actions?.find((role) => {
            if (role === "inventory" || role === "admin" || role === "super") {
              return true;
            } else return false;
          }) && (
            <ListItemButton
              onClick={() => {
                setMenuToggle();
              }}
            >
              <Box
                style={{
                  borderRadius: "10px",
                  backgroundColor: location.pathname.match("/View/Inventory")
                    ? "#fca311"
                    : null,
                  display: "flex",
                  alignItems: "center",
                  padding: "1ch",
                }}
              >
                <InventoryRounded
                  style={{
                    color:
                      // location.pathname.match(
                      //     "/View/Inventory"
                      // )
                      "#14213d",
                    // : "#e5e5e5",
                  }}
                />
              </Box>
            </ListItemButton>
          )}
          {user.actions?.find((role) => {
            if (
              role === "FINANCE_ONE" ||
              role === "FINANCE_TWO" ||
              role === "admin" ||
              role === "super"
            ) {
              return true;
            } else return false;
          }) && (
            <ListItemButton
              onClick={() => {
                setMenuToggle();
              }}
            >
              <Box
                style={{
                  borderRadius: "10px",
                  backgroundColor: location.pathname.match("/View/Finance")
                    ? "#fca311"
                    : null,
                  display: "flex",
                  alignItems: "center",
                  padding: "1ch",
                }}
              >
                <LocalAtmRounded
                  style={{
                    color:
                      // location.pathname.match(
                      //     "/View/Finance"
                      // )
                      "#14213d",
                    // : "#e5e5e5",
                  }}
                />
              </Box>
            </ListItemButton>
          )}
          {user.actions?.find((role) => {
            if (
              role === "PROJECT_TWO" ||
              role === "admin" ||
              role === "super"
            ) {
              return true;
            } else return false;
          }) && (
            <ListItemButton
              onClick={() => {
                navigate("/View/ViewClient");
              }}
            >
              <Box
                style={{
                  borderRadius: "10px",
                  backgroundColor: location.pathname.match(
                    /\/View\/ViewClient|\/View\/AddClient/,
                  )
                    ? "#fca311"
                    : null,
                  display: "flex",
                  alignItems: "center",
                  padding: "1ch",
                }}
              >
                <Person
                  style={{
                    color:
                      // location.pathname.match(
                      //     /\/View\/ViewClient|\/View\/AddClient/
                      // )
                      "#14213d",
                    // : "#e5e5e5",
                  }}
                />
              </Box>
            </ListItemButton>
          )}
          {user.actions?.find((role) => {
            if (role === "sales" || role === "admin" || role === "super") {
              return true;
            } else return false;
          }) && (
            <ListItemButton
              onClick={() => {
                navigate("/View/Sales/ViewQuote");
              }}
            >
              <Box
                style={{
                  borderRadius: "10px",
                  backgroundColor: location.pathname.match("/View/Sales")
                    ? "#fca311"
                    : null,
                  display: "flex",
                  alignItems: "center",
                  padding: "1ch",
                }}
              >
                <ShoppingBasket
                  style={{
                    color: "#14213d",
                  }}
                />
              </Box>
            </ListItemButton>
          )}
        </List>
      )}
    </Paper>
  );
};

export default SideBar;
