import { Add, SearchRounded } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RequiredMaterialURL } from "../../../../utils/config";

import { get } from "../../../../utils/Caller";
import OperationsTable from "../../../../Components/Table/OperationsTable.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";

const ViewRequiredMaterial = ({ addButton }) => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(16);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const [filter, setFilter] = useState("");
    const [Search, setSearch] = useState("");
    const dispatch = useDispatch();
    const user = useSelector((state) => state.userState.user);

    const columns = [
        {
            field: "delivered_quantity",
            headerName: "Delivered Quantity",
            width: 100,
        },
        {
            field: "delivered_unit_price",
            headerName: "Delivered Unit Price",
            width: 100,
        },
        {
            field: "delivered_total_amount",
            headerName: "Delivered Total Amount",
            width: 100,
        },
        {
            field: "total_expense",
            headerName: "Total Expense",
            width: 100,
        },
        {
            field: "delivery_date",
            headerName: "Delivery Date",
            width: 100,
        },
        {
            field: "planned_quantity",
            headerName: "Planned Quantity",
            width: 100,
        },
        {
            field: "planned_unit_price",
            headerName: "Planned Unit Price",
            width: 100,
        },
        {
            field: "planned_total_amount",
            headerName: "Planned Total Amount",
            width: 100,
        },
        {
            field: "materialName",
            headerName: "Material Name",
            width: 100,
        },
        {
            field: "projectName",
            headerName: "Project Name",
            width: 100,
        },
    ];

    useEffect(() => {
        let requestData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(RequiredMaterialURL, {
            limit: limit,
            filter: { project_id: selectedRow.id, all: filter },
        }).then((res) => {
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    requestData.push({
                        ...data,
                        materialName: data.material?.name,
                        projectName: data.project?.name,
                    });
                });
            setRows(requestData);
        });
    }, [refreshTable, limit, selectedRow, filter, rows.length]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                {addButton && (
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<Add />}
                        onClick={() => {
                            navigate(
                                `/View/ViewProject/${selectedRow.id}/RequiredMaterial/AddRequiredMaterial`
                            );
                        }}
                    >
                        Add Required Material
                    </Button>
                )}
            </div>
            <div className="TableContainer">
                <OperationsTable
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={
                        user.actions?.find((role) => {
                            if (
                                role === "PROJECT_ONE" ||
                                role === "admin" ||
                                role === "super"
                            ) {
                                return true;
                            } else return false;
                        })
                            ? true
                            : false
                    }
                    addLimit={addLimit}
                />
            </div>
        </div>
    );
};

export default ViewRequiredMaterial;
