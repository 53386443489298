import { Add, SearchRounded } from "@mui/icons-material";
import {
    Button,
    InputAdornment,
    TextField,
    IconButton,
    Modal,
    Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import Table from "../../../../Components/Table/Table.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { get } from "../../../../utils/Caller";
import { GeneralJournalHeaderURL } from "../../../../utils/config";
import GeneralExport from "../GeneralExport/GeneralExport.page";

const JOURNAL_STATUS = ["Active", "Reversed", "Deleted", "Void"];
const REPORT_BASIS = ["Accrual", "Cash", "Both"];
const JOURNAL_SOURCE = [
    "Manual Journal",
    "Interfaced Journal",
    "Uploaded",
    "Auto",
];
const JOURNAL_POSTING_STATUS = ["POSTED", "DRAFT", "SCHEDULED", "VOID"];

const ViewGeneralJournalHeader = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(20);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState();
    const [Search, setSearch] = useState();
    const [open, setOpen] = useState(false);

    const columns = [
        {
            field: "journal_date",
            headerName: "Journal Date",
            width: 150,
        },
        {
            field: "posting_reference",
            headerName: "Posting Reference",
            width: 150,
        },
        {
            field: "reference_number",
            headerName: "Reference Number",
            width: 150,
        },
        {
            field: "journalStatus",
            headerName: "Journal Status",
            width: 150,
        },
        {
            field: "notes",
            headerName: "Notes",
            width: 150,
        },
        {
            field: "reportBasis",
            headerName: "Report Basis",
            width: 150,
        },
        {
            field: "journalSource",
            headerName: "Journal Source",
            width: 150,
        },
        {
            field: "journalPostingStatus",
            headerName: "Journal Posting Status",
            width: 150,
        },
        {
            field: "journal_type_reference",
            headerName: "Journal Type Reference",
            width: 150,
        },
        {
            field: "total_amount",
            headerName: "Total Amount",
            width: 150,
        },
        {
            field: "journalType",
            headerName: "Journal Type",
            width: 150,
        },
        {
            field: "recurringGeneralJournal",
            headerName: "Recurring General Journal",
            width: 150,
        },
        {
            field: "currencyName",
            headerName: "Currency",
            width: 150,
        },
        {
            field: "userName",
            headerName: "Posting Responsible User",
            width: 150,
        },
    ];

    useEffect(() => {
        let tableData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(GeneralJournalHeaderURL, {
            limit: limit,
            filter: { all: filter },
        }).then((res) => {
            // console.log(res);
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    tableData.push({
                        ...data,
                        journalType: data.journal_type?.type,
                        recurringGeneralJournal:
                            data.recurring_general_journal?.profile_name,
                        currencyName: data.currency?.name,
                        userName: data.posting_responsible_user?.username,
                        journalSource: JOURNAL_SOURCE[data.journal_source - 1],
                        journalStatus: JOURNAL_STATUS[data.journal_status - 1],
                        reportBasis: REPORT_BASIS[data.report_basis - 1],
                        journalPostingStatus:
                            JOURNAL_POSTING_STATUS[
                                data.journal_posting_status - 1
                            ],
                    });
                });
            setRows(tableData);
        });
    }, [refreshTable, filter, limit, rows.length]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setloading(true);
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <div style={{ display: "flex", gap: "2ch" }}>
                    <Button
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        Export
                    </Button>
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<Add />}
                        onClick={() => {
                            navigate(
                                "/View/Finance/GeneralJournalHeader/AddGeneralJournalHeader"
                            );
                        }}
                    >
                        Add General Journal Header
                    </Button>
                </div>
            </div>
            <div className="TableContainer">
                <Table
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                />
            </div>
            <Modal
                className="Modal"
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <GeneralExport type={31} />
                </Box>
            </Modal>
        </div>
    );
};

export default ViewGeneralJournalHeader;
