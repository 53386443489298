import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
    Box,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { EditRounded } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { patcher, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    ChartOfAccountURL,
    CurrencyURL,
    GeneralJournalHeaderURL,
    GeneralLedgerURL,
    TaxGroupURL,
    TaxURL,
} from "../../../../utils/config";
import dateFormatter from "../../../../utils/dateFormater";

const EditGeneralLedger = (props) => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState("");
    const [filter2, setFilter2] = useState("");
    const [filter3, setFilter3] = useState("");
    const [filter4, setFilter4] = useState("");
    const [filter5, setFilter5] = useState("");
    const [options1, setOptions1] = useState([]);
    const [optionsId1, setOptionsID1] = useState();
    const [optionSelect1, setOptionSelect1] = useState("");
    const [options2, setOptions2] = useState([]);
    const [optionsId2, setOptionsID2] = useState();
    const [optionSelect2, setOptionSelect2] = useState("");
    const [options3, setOptions3] = useState([]);
    const [optionsId3, setOptionsID3] = useState();
    const [optionSelect3, setOptionSelect3] = useState("");
    const [options4, setOptions4] = useState([]);
    const [optionsId4, setOptionsID4] = useState();
    const [optionSelect4, setOptionSelect4] = useState("");
    const [options5, setOptions5] = useState([]);
    const [optionsId5, setOptionsID5] = useState();
    const [optionSelect5, setOptionSelect5] = useState("");
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [LedgerStatus, setLedgerStatus] = useState(selectedRow.ledger_status);
    const [msg, setMsg] = useState("");

    const LEDGER_STATUS = ["Active", "Reversed", "Deleted", "Void"];

    useEffect(() => {
        if (optionSelect1 === "")
            setOptionSelect1(
                `${selectedRow.currency?.id} - ${selectedRow.currencyName}`
            );
        if (optionSelect2 === "")
            setOptionSelect2(`${selectedRow.tax?.id} - ${selectedRow.taxName}`);
        if (optionSelect3 === "")
            setOptionSelect3(
                `${selectedRow.tax_group?.id} - ${selectedRow.taxGroup}`
            );
        if (optionSelect4 === "")
            setOptionSelect4(
                `${selectedRow.general_journal_header?.id} - ${selectedRow.generalJournalHeader}`
            );
        if (optionSelect5 === "")
            setOptionSelect5(
                `${selectedRow.chart_of_account?.id} - ${selectedRow.chartOfAccount}`
            );
    }, [
        selectedRow,
        optionSelect1,
        optionSelect2,
        optionSelect3,
        optionSelect4,
        optionSelect5,
    ]);

    const INITIAL_VALUES = {
        amount_credit: selectedRow.amount_credit,
        amount_debit: selectedRow.amount_debit,
        ledger_status: LedgerStatus,
        posting_reference: selectedRow.posting_reference,
        group_posting_reference: selectedRow.group_posting_reference,
        description: selectedRow.description,
        journal_date: dateFormatter(selectedRow.journal_date),
        posting_date: dateFormatter(selectedRow.posting_date),
        currency_id: selectedRow.currency?.id,
        tax_id: selectedRow.tax?.id,
        tax_group_id: selectedRow.tax_group?.id,
        general_journal_header_id: selectedRow.general_journal_header?.id,
        chart_of_account_id: selectedRow.chart_of_account?.id,
        isProtectedForEdit: false,
    };

    const FORM_VALIDATION = Yup.object().shape({
        amount_credit: Yup.number().min(1).required("*Required"),
        amount_debit: Yup.number().min(1).required("*Required"),
        ledger_status: Yup.number().min(1),
        posting_reference: Yup.string().min(1),
        group_posting_reference: Yup.string().min(1),
        description: Yup.string().min(1).required("*Required"),
        journal_date: Yup.string().min(1),
        posting_date: Yup.string().min(1),
        // currency_id: Yup.number().min(1),
        // tax_id: Yup.number().min(1).required("*Required"),
        // tax_group_id: Yup.number().min(1).required("*Required"),
        // general_journal_header_id: Yup.number().min(1).required("*Required"),
        // chart_of_account_id: Yup.number().min(1),
    });
    useEffect(() => {
        get(CurrencyURL, {
            limit: 10,
            filter: {
                name: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.name}`
                    );
                });
            res && setOptionsID1(res.data);
            setOptions1(data);
        });

        get(TaxURL, {
            limit: 10,
            filter: {
                tax_name: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.tax_name}`
                    );
                });
            res && setOptionsID2(res.data);
            setOptions2(data);
        });

        get(TaxGroupURL, {
            limit: 10,
            filter: {
                tax_group_name: filter3,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.tax_group_name}`
                    );
                });
            res && setOptionsID3(res.data);
            setOptions3(data);
        });

        get(GeneralJournalHeaderURL, {
            limit: 10,
            filter: {
                posting_reference: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.posting_reference}`
                    );
                });
            res && setOptionsID4(res.data);
            setOptions4(data);
        });

        get(ChartOfAccountURL, {
            limit: 10,
            filter: {
                account_code: filter5,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.account_code}`
                    );
                });
            res && setOptionsID5(res.data);
            setOptions5(data);
        });
    }, [filter, filter2, filter3, filter4, filter5]);

    const formSubmitHandler = (updateData, actions) => {
        setLoading(true);
        const update = {
            id: selectedRow.id,
            updateData,
        };

        patcher(GeneralLedgerURL, update).then((res) => {
            setLoading(false);
            // console.log(res.error);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                dispatch(setRefresh(!refreshTable));
                setSuccess(true);
                setError(false);
                props.close();
            }
        });
    };

    return (
        <div>
            <TitleHeader title="Edit General Ledger" icon={<EditRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        values.ledger_status = LedgerStatus;

                        optionsId1.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.name}` === optionSelect1
                            ) {
                                values.currency_id = name.id;
                            }
                        });
                        optionsId2.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.tax_name}` ===
                                optionSelect2
                            ) {
                                values.tax_id = name.id;
                            }
                        });
                        optionsId3.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.tax_group_name}` ===
                                optionSelect3
                            ) {
                                values.tax_group_id = name.id;
                            }
                        });
                        optionsId4.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.posting_reference}` ===
                                optionSelect4
                            ) {
                                values.general_journal_header_id = name.id;
                            }
                        });
                        optionsId5.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.account_code}` ===
                                optionSelect5
                            ) {
                                values.chart_of_account_id = name.id;
                            }
                        });

                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="amount_credit"
                                    label="Amount Credit"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="amount_debit"
                                    label="Amount Debit"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    select
                                    name="ledger_status"
                                    label="Ledger Status"
                                    variant="outlined"
                                    value={LedgerStatus}
                                    options={LEDGER_STATUS ?? " "}
                                    onChange={(e) => {
                                        setLedgerStatus(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="posting_reference"
                                    label="Posting Reference"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="group_posting_reference"
                                    label="Group Posting Reference"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldWrapper
                                    name="description"
                                    label="Description"
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>Journal Date</FormLabel>
                                    <TextFieldWrapper
                                        name="journal_date"
                                        variant="outlined"
                                        type="date"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>Posting Date</FormLabel>
                                    <TextFieldWrapper
                                        name="posting_date"
                                        variant="outlined"
                                        type="date"
                                    />
                                </FormControl>
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect1}
                                    loading={options1.length < 1 ? true : false}
                                    disablePortal
                                    options={options1}
                                    onChange={(event, newValue) => {
                                        setOptionSelect1(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Currency"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect2}
                                    loading={options2.length < 1 ? true : false}
                                    disablePortal
                                    options={options2}
                                    onChange={(event, newValue) => {
                                        setOptionSelect2(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Tax"
                                            onChange={(event) => {
                                                setFilter2(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect3}
                                    loading={options3.length < 1 ? true : false}
                                    disablePortal
                                    options={options3}
                                    onChange={(event, newValue) => {
                                        setOptionSelect3(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Tax Group"
                                            onChange={(event) => {
                                                setFilter3(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect4}
                                    loading={options4.length < 1 ? true : false}
                                    disablePortal
                                    options={options4}
                                    onChange={(event, newValue) => {
                                        setOptionSelect4(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="General Journal Header"
                                            onChange={(event) => {
                                                setFilter4(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect5}
                                    loading={options5.length < 1 ? true : false}
                                    disablePortal
                                    options={options5}
                                    onChange={(event, newValue) => {
                                        setOptionSelect5(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Chart Of Account"
                                            onChange={(event) => {
                                                setFilter5(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Apply"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default EditGeneralLedger;
