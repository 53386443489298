import React from "react";
import { useLocation } from "react-router";

import AddDocumentation from "./AddDocumentation.page";
import ViewDocumentation from "./ViewDocumentation.page";

const Documentation = () => {
    const location = useLocation();

    return (
        <>
            {location.pathname === "/View/Documentation/AddDocumentation" && (
                <AddDocumentation />
            )}
            {location.pathname === "/View/Documentation" && <ViewDocumentation />}
        </>
    );
};

export default Documentation;
