import { Person } from "@mui/icons-material";
import { Box, Paper, Typography, Tab, Tabs } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";

import { TabPanel } from "../../../../Components/Tab/Tab.component";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";

const ContactDetailView = ({ close, selectedRow }) => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const columns = {
        contact: [
            {
                field: "contactType",
                headerName: "Contact Type",
            },
            {
                field: "company_name",
                headerName: "Company Name",
            },
            {
                field: "contact_display_name",
                headerName: "Contact Display Name",
            },
            {
                field: "remark",
                headerName: "Remark",
            },
            {
                field: "chartOfAccount",
                headerName: "Chart Of Account",
            },
            {
                field: "currencyName",
                headerName: "Currency",
            },
            {
                field: "paymentTerm",
                headerName: "Payment Term",
            },
        ],
        address: [
            {
                field: "attention",
                headerName: "Attention",
            },
            {
                field: "street1",
                headerName: "Street 1",
            },
            {
                field: "street2",
                headerName: "Street 2",
            },
            {
                field: "city",
                headerName: "City",
            },
            {
                field: "state",
                headerName: "State",
            },
            {
                field: "phone",
                headerName: "Phone",
            },
            {
                field: "fax",
                headerName: "Fax",
            },
            {
                field: "zip_code",
                headerName: "Zip Code",
            },
            {
                field: "contactName",
                headerName: "Contact",
            },
            {
                field: "countryName",
                headerName: "Country",
            },
        ],
        person: [
            {
                field: "first_name",
                headerName: "First Name",
            },
            {
                field: "last_name",
                headerName: "Last Name",
            },
            {
                field: "work_phone",
                headerName: "Work Phone",
            },
            {
                field: "mobile",
                headerName: "Mobile",
            },
            {
                field: "email",
                headerName: "Email",
            },
            {
                field: "designation",
                headerName: "Designation",
            },
            {
                field: "department",
                headerName: "Department",
            },
            {
                field: "is_primary_contact",
                headerName: "Is Primary Contact",
            },
            {
                field: "contactName",
                headerName: "Contact",
                valueGetter: (params) => params.row.contact?.company_name,
            },
            {
                field: "titleName",
                headerName: "Title",
                valueGetter: (params) => params.row.title?.name,
            },
        ],
    };

    return (
        <Paper
            style={{
                padding: "2ch",
                overflowY: "scroll",
                height: "80vh",
                minWidth: (3 * window.innerWidth) / 4,
            }}
        >
            <TitleHeader title={selectedRow.id} icon={<Person />} />
            <div className="FormContainer">
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "3ch",
                        border: "silver 1px solid",
                        margin: "2ch",
                        borderRadius: "5px",
                    }}
                >
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs value={value} onChange={handleChange}>
                            <Tab label="Contact Details" id={0} />
                            <Tab label="Contact Address" id={1} />
                            <Tab label="Contact Person" id={2} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                padding: "3ch",
                                border: "silver 1px solid",
                                margin: "2ch",
                                borderRadius: "5px",
                            }}
                        >
                            {columns.contact.map((column, index) => (
                                <Box
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        height: "7ch",
                                        borderBottom: "silver 1px solid",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography
                                        fontWeight={"600"}
                                        color={"#131417"}
                                        key={index}
                                        letterSpacing={"1.2px"}
                                    >
                                        {column.headerName}
                                    </Typography>
                                    <Typography>
                                        {selectedRow[column.field]}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                padding: "3ch",
                                border: "silver 1px solid",
                                margin: "2ch",
                                borderRadius: "5px",
                            }}
                        >
                            {selectedRow.contact_address.map(
                                (contactAddress, index) => {
                                    return (
                                        <>
                                            {contactAddress.address_type ===
                                                1 && (
                                                <Typography>
                                                    Billing Address
                                                </Typography>
                                            )}
                                            {contactAddress.address_type ===
                                                2 && (
                                                <Typography>
                                                    Shiping Address
                                                </Typography>
                                            )}
                                            {columns.address.map(
                                                (column, index) => (
                                                    <Box
                                                        style={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "row",
                                                            height: "7ch",
                                                            borderBottom:
                                                                "silver 1px solid",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "space-between",
                                                        }}
                                                    >
                                                        <Typography
                                                            fontWeight={"600"}
                                                            color={"#131417"}
                                                            key={index}
                                                            letterSpacing={
                                                                "1.2px"
                                                            }
                                                        >
                                                            {column.headerName}
                                                        </Typography>
                                                        <Typography>
                                                            {
                                                                contactAddress[
                                                                    column.field
                                                                ]
                                                            }
                                                            {column.field ===
                                                                "countryName" &&
                                                                contactAddress
                                                                    .country
                                                                    ?.country_name}

                                                            {column.field ===
                                                                "contactName" &&
                                                                contactAddress
                                                                    .contact
                                                                    ?.company_name}
                                                        </Typography>
                                                    </Box>
                                                )
                                            )}
                                        </>
                                    );
                                }
                            )}
                        </Box>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <DataGrid
                            autoHeight
                            columns={columns.person}
                            rows={selectedRow.contact_person}
                            pageSize={8}
                            rowsPerPageOptions={[8]}
                        />
                    </TabPanel>
                </Box>
            </div>
        </Paper>
    );
};

export default ContactDetailView;
