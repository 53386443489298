import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { MdOutlineFiberNew } from "react-icons/md";
import NotificationNavigate from "./NotificationNavigate.json";
import { useNavigate } from "react-router";
import { get } from "../../utils/Caller";
import { NotificationsURL } from "../../utils/config";

export default function NotificationDrawer({ open, handleClose }) {
    const navigate = useNavigate();
    const [rows, setRows] = useState([]);

    useEffect(() => {
        get(NotificationsURL, {
            limit: 1000,
        }).then((res) => {
            setRows(res?.data);
        });
    }, []);
    return (
        <div>
            <Drawer anchor={"right"} open={open} onClose={handleClose}>
                <Paper
                    elevation={0}
                    style={{
                        minWidth: "300px",
                        maxWidth: window.screen.width / 3,
                    }}
                >
                    <Typography fontWeight={"bolder"} padding={"2ch"}>
                        Notifications
                    </Typography>
                    <Divider variant="fullWidth" />
                    <Box onClick={handleClose} onKeyDown={handleClose}>
                        <List>
                            {rows?.map((text, index) => (
                                <ListItem key={index} disablePadding>
                                    <ListItemButton
                                        onClick={() => {
                                            try {
                                                navigate(
                                                    NotificationNavigate[
                                                        text.name
                                                    ],
                                                    {
                                                        state: {
                                                            search: true,
                                                            searchType:
                                                                text?.type + 1,
                                                        },
                                                    }
                                                );
                                            } catch (err) {
                                                console.log(err);
                                            }
                                        }}
                                    >
                                        <ListItemIcon>
                                            <MdOutlineFiberNew
                                                fontSize="30px"
                                                color="red"
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={text.message} />
                                        <ListItemText
                                            primary={text.count}
                                            style={{
                                                backgroundColor: "#ff000066",
                                                borderRadius: "50%",
                                                padding: "0px 5px 0px 5px",
                                                marginLeft: "2ch",
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Paper>
            </Drawer>
        </div>
    );
}
