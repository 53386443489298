import React from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { Error, HouseRounded, ReplayOutlined } from "@mui/icons-material";
import "../../Assets/Sass/Glitch.scss";

const Error500 = () => {
    return (
        <div className="NotFound" style={{ background: "black" }}>
            <Error fontSize={"large"} color="error" />
            <div className="container">
                <div className="glitch" data-text="500">
                    500
                </div>
                <div className="glow">500</div>
            </div>
            <div className="scanlines"></div>
            <Divider style={{ width: "70%" }} />
            <Typography fontFamily={"monospace"} variant="h3" color={"white"}>
                Server Error
            </Typography>
            <Box style={{ display: "flex", gap: "10ch" }}>
                <Button
                    variant="outlined"
                    startIcon={<HouseRounded />}
                    onClick={() => {
                        window.open(window.location.origin + "/", "_self");
                    }}
                >
                    Go To Dashboard
                </Button>
                <Button
                    variant="contained"
                    startIcon={<ReplayOutlined />}
                    onClick={() => {
                        window.history.back();
                    }}
                >
                    Reload
                </Button>
            </Box>
        </div>
    );
};

export default Error500;
