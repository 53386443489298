import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
    Box,
    Typography,
    Divider,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { caller, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    ShiftScheduleHdrURL,
    SubShiftGroupURL,
} from "../../../../utils/config";

const AddShiftScheduleHdr = () => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState("");
    const [options1, setOptions1] = useState([]);
    const [optionsId1, setOptionsID1] = useState();
    const [optionSelect1, setOptionSelect1] = useState("");
    const [msg, setMsg] = useState("");
    const [workingDay, setWorkingDay] = useState([]);
    const [ClockIn, setClockIn] = useState({
        Monday: null,
        Tuesday: null,
        Wednesday: null,
        Thursday: null,
        Friday: null,
        Saturday: null,
        Sunday: null,
    });
    const [ClockOut, setClockOut] = useState({
        Monday: null,
        Tuesday: null,
        Wednesday: null,
        Thursday: null,
        Friday: null,
        Saturday: null,
        Sunday: null,
    });
    const [minWorkingHour, setMinWorkingHour] = useState({
        Monday: null,
        Tuesday: null,
        Wednesday: null,
        Thursday: null,
        Friday: null,
        Saturday: null,
        Sunday: null,
    });

    const DAYS = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        shift_name: "",
        sub_shift_group_id: null,
        clock_in: "",
        clock_out: "",
        min_working_hours: "",
    };

    const FORM_VALIDATION = Yup.object().shape({
        shift_name: Yup.string().min(1).required("*Required"),
        clock_in: Yup.number().min(1),
        clock_out: Yup.number().min(1),
        min_working_hours: Yup.number().min(1),
        // sub_shift_group_id: Yup.number().min(1),
    });

    useEffect(() => {
        get(SubShiftGroupURL, {
            limit: 10,
            filter: {
                sub_shift_name: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.sub_shift_name}`
                    );
                });
            res && setOptionsID1(res.data);
            setOptions1(data);
        });
    }, [filter]);
    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        caller(ShiftScheduleHdrURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                actions.resetForm();
            }
        });
    };

    const timeFormat = (time) => {
        let times = time.split(":");
        times[1] = String((parseInt(times[1]) * 10) / 60).replace(/\./g, "");
        times[0] = parseInt(times[0]);
        return parseFloat(`${times[0]}.${times[1]}`);
    };

    return (
        <div>
            <TitleHeader title="Add Shift Schedule Hdr" icon={<AddRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;

                        optionsId1.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.sub_shift_name}` ===
                                optionSelect1
                            ) {
                                values.sub_shift_group_id = name.id;
                            }
                        });
                        let detail = [];
                        for (let active in workingDay) {
                            let data = {};
                            data["working_day"] = workingDay[active] + 1;
                            data["clock_in"] = timeFormat(
                                ClockIn[DAYS[workingDay[active]]]
                            );
                            data["clock_out"] = timeFormat(
                                ClockOut[DAYS[workingDay[active]]]
                            );
                            data["min_working_hours"] = parseInt(
                                minWorkingHour[DAYS[workingDay[active]]]
                            );
                            detail.push(data);
                        }

                        let formData = {
                            shiftScheduleDtls: detail,
                            shiftScheduleHdr: {
                                shift_name: values.shift_name,
                                sub_shift_group_id: values.sub_shift_group_id,
                            },
                        };

                        formSubmitHandler(formData, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="shift_name"
                                    label="Shift Name"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    loading={options1.length < 1 ? true : false}
                                    disablePortal
                                    options={options1}
                                    onChange={(event, newValue) => {
                                        setOptionSelect1(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Sub Shift Group"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Box
                                style={{
                                    width: "100%",
                                    borderRadius: "5px",
                                    border: "solid 1px silver",
                                    margin: "2ch 0 0 2ch",
                                }}
                            >
                                <div>
                                    <Typography
                                        style={{
                                            fontWeight: "600",
                                            letterSpacing: "1px",
                                            padding: "1ch 0 1ch 2ch",
                                        }}
                                    >
                                        Detail
                                    </Typography>
                                    <Divider />
                                </div>
                                {DAYS.map((day, index) => {
                                    return (
                                        <Grid
                                            key={index}
                                            width={"100%"}
                                            style={{
                                                flexDirection: "row",
                                                display: "flex",
                                                padding: "1ch",
                                                gap: "2ch",
                                                alignItems: "flex-end",
                                            }}
                                        >
                                            <Grid item xs={2}>
                                                <FormControl
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Checkbox
                                                        name={day}
                                                        checked={Boolean(
                                                            workingDay.indexOf(
                                                                index
                                                            ) !== -1
                                                        )}
                                                        onChange={(event) => {
                                                            if (
                                                                workingDay.indexOf(
                                                                    index
                                                                ) !== -1
                                                            ) {
                                                                setWorkingDay(
                                                                    workingDay.filter(
                                                                        (key) =>
                                                                            key !==
                                                                            index
                                                                    )
                                                                );
                                                            } else {
                                                                setWorkingDay([
                                                                    ...workingDay,
                                                                    index,
                                                                ]);
                                                            }
                                                        }}
                                                    />
                                                    <FormLabel>{day}</FormLabel>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <FormControl>
                                                    <FormLabel>
                                                        Clock In
                                                    </FormLabel>
                                                    <TextFieldWrapper
                                                        type="time"
                                                        name={`clock_in`}
                                                        onChange={(e) => {
                                                            setClockIn({
                                                                ...ClockIn,
                                                                [day]: e.target
                                                                    .value,
                                                            });
                                                        }}
                                                        value={ClockIn[day]}
                                                        variant="outlined"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <FormControl>
                                                    <FormLabel>
                                                        Clock Out
                                                    </FormLabel>
                                                    <TextFieldWrapper
                                                        name={`clock_out`}
                                                        onChange={(e) => {
                                                            setClockOut({
                                                                ...ClockOut,
                                                                [day]: e.target
                                                                    .value,
                                                            });
                                                        }}
                                                        value={ClockOut[day]}
                                                        variant="outlined"
                                                        type="time"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextFieldWrapper
                                                    name={`${day}-min_working_hours`}
                                                    onChange={(e) => {
                                                        setMinWorkingHour({
                                                            ...minWorkingHour,
                                                            [day]: e.target
                                                                .value,
                                                        });
                                                    }}
                                                    value={minWorkingHour[day]}
                                                    label="Min Working Hours"
                                                    variant="outlined"
                                                    type="number"
                                                />
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </Box>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default AddShiftScheduleHdr;
