import { Route } from "react-router";
import ViewUnitOfMeasure from "../Pages/Inventory/InventoryMaster/UnitOfMeasure/ViewUnitOfMeasure.page";
import AddUnitOfMeasure from "../Pages/Inventory/InventoryMaster/UnitOfMeasure/AddUnitOfMeasure.page";
import ViewWarehouse from "../Pages/Inventory/InventoryMaster/Warehouse/ViewWarehouse.page";
import AddWarehouse from "../Pages/Inventory/InventoryMaster/Warehouse/AddWarehouse.page";
import ViewStockBatch from "../Pages/Inventory/InventoryStock/StockBatch/ViewStockBatch.page";
import AddStockBatch from "../Pages/Inventory/InventoryStock/StockBatch/AddStockBatch.page";
import ViewStockItem from "../Pages/Inventory/InventoryStock/StockItem/ViewStockItem.page";
import AddStockItem from "../Pages/Inventory/InventoryStock/StockItem/AddStockItem.page";
import ViewStockTransactionDetail from "../Pages/Inventory/InventoryStock/StockTransactionDetail/ViewStockTransactionDetail.page";
import AddStockTransactionDetail from "../Pages/Inventory/InventoryStock/StockTransactionDetail/AddStockTransactionDetail.page";
import ViewStockTransactionHeader from "../Pages/Inventory/InventoryStock/StockTransactionHeader/ViewStockTransactionHeader.page";
import AddStockTransactionHeader from "../Pages/Inventory/InventoryStock/StockTransactionHeader/AddStockTransactionHeader.page";

export const InventoryRoutes = [
    <Route path="Inventory">
        {/* ======================================INVENTORY MASTER============================================ */}
        <Route path="UnitOfMeasure" element={<ViewUnitOfMeasure />}>
            <Route path="AddUnitOfMeasure" element={<AddUnitOfMeasure />} />
        </Route>
        <Route path="Warehouse" element={<ViewWarehouse />}>
            <Route path="AddWarehouse" element={<AddWarehouse />} />
        </Route>

        {/* ==================================INVENTORY STOCK============================================ */}

        <Route path="StockBatch" element={<ViewStockBatch />}>
            <Route path="AddStockBatch" element={<AddStockBatch />} />
        </Route>
        <Route path="StockItem" element={<ViewStockItem />}>
            <Route path="AddStockItem" element={<AddStockItem />} />
        </Route>
        <Route
            path="StockTransactionDetail"
            element={<ViewStockTransactionDetail />}
        >
            <Route
                path="AddStockTransactionDetail"
                element={<AddStockTransactionDetail />}
            />
        </Route>
        <Route
            path="StockTransactionHeader"
            element={<ViewStockTransactionHeader />}
        >
            <Route
                path="AddStockTransactionHeader"
                element={<AddStockTransactionHeader />}
            />
        </Route>
    </Route>,
];
