import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selected: null,
};

export const selectedDetail = createSlice({
    name: "selectedDetail",
    initialState,
    reducers: {
        setSelectedDetail: (state, action) => {
            state.selected = action.payload;
        },
    },
});

export const { setSelectedDetail } = selectedDetail.actions;

export default selectedDetail.reducer;
