import { Add, SearchRounded } from "@mui/icons-material";
import { Button, InputAdornment, TextField, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import Table from "../../../../Components/Table/Table.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { get } from "../../../../utils/Caller";
import { CashPaymentVoucherURL } from "../../../../utils/config";

const ViewCashPaymentVoucher = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(20);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState();
    const [Search, setSearch] = useState();

    const columns = [
        {
            field: "date",
            headerName: "Date",
            width: 150,
        },
        {
            field: "paid_to",
            headerName: "Paid To",
            width: 150,
        },
        {
            field: "amount",
            headerName: "Amount",
            width: 150,
        },
        {
            field: "description",
            headerName: "Description",
            width: 150,
        },
        {
            field: "check_number",
            headerName: "Check Number",
            width: 150,
        },
        {
            field: "pv_number",
            headerName: "Pv Number",
            width: 150,
        },
        {
            field: "remaining_balance",
            headerName: "Remaining Balance",
            width: 150,
        },
        {
            field: "cpvBank",
            headerName: "Cpv Bank",
            width: 150,
        },
        {
            field: "preparedBy",
            headerName: "Prepared By",
            width: 150,
        },
        {
            field: "checkedBy",
            headerName: "Checked By",
            width: 150,
        },
        {
            field: "projectName",
            headerName: "Project",
            width: 150,
        },
        {
            field: "cpvType",
            headerName: "Cpv Type",
            width: 150,
        },
        {
            field: "requestPayment",
            headerName: "Request Payment",
            width: 150,
        },
    ];

    useEffect(() => {
        let tableData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(CashPaymentVoucherURL, {
            limit: limit,
            filter: { all: filter },
        }).then((res) => {
            // console.log(res);
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    tableData.push({
                        ...data,
                        cpvBank: data.cpv_bank?.name,
                        preparedBy: data.prepared_by?.first_name,
                        checkedBy: data.checked_by?.first_name,
                        projectName: data.project?.name,
                        cpvType: data.cpv_type?.name,
                        requestPayment: data.request_payment?.name,
                    });
                });
            setRows(tableData);
        });
    }, [refreshTable, filter, limit, rows.length]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setloading(true);
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <div style={{ display: "flex", gap: "2ch" }}>
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<Add />}
                        onClick={() => {
                            navigate(
                                "/View/Finance/CashPaymentVoucher/AddCashPaymentVoucher"
                            );
                        }}
                    >
                        Add Cash Payment Voucher
                    </Button>
                </div>
            </div>
            <div className="TableContainer">
                <Table
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                />
            </div>
        </div>
    );
};

export default ViewCashPaymentVoucher;
