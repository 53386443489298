import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
    Box,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { EditRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { patcher, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    CrvPaymentURL,
    CrvTypeURL,
    ProjectURL,
} from "../../../../utils/config";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { useDispatch, useSelector } from "react-redux";
import dateFormatter from "../../../../utils/dateFormater";

const EditCrvPayment = (props) => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setMsg] = useState("");

    const [filter1, setFilter1] = useState("");
    const [options1, setOptions1] = useState([]);
    const [optionSelect1, setOptionSelect1] = useState("");
    const [filter2, setFilter2] = useState("");
    const [options2, setOptions2] = useState([]);
    const [optionSelect2, setOptionSelect2] = useState("");
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();

    useEffect(() => {
        if (optionSelect1 === "")
            setOptionSelect1(
                `${selectedRow.project?.id} - ${selectedRow.projectName}`
            );
        if (optionSelect2 === "")
            setOptionSelect2(
                `${selectedRow.crv_type?.id} - ${selectedRow.crvType}`
            );
    }, [selectedRow, optionSelect1, optionSelect2]);

    const INITIAL_VALUES = {
        date: dateFormatter(selectedRow.date),
        name: selectedRow.name,
        customer_name: selectedRow.customer_name,
        description: selectedRow.description,
        amount_before_vat: selectedRow.amount_before_vat,
        vat: selectedRow.vat,
        amount_with_vat: selectedRow.amount_with_vat,
        payment_description: selectedRow.payment_description,
        withholding: selectedRow.withholding,
        check_amount: selectedRow.check_amount,
        cash_amount: selectedRow.cash_amount,
        project_id: selectedRow.project?.id,
        crv_type_id: selectedRow.crv_type?.id,
        isProtectedForEdit: false,
    };

    const FORM_VALIDATION = Yup.object().shape({
        date: Yup.string().min(1),
        name: Yup.string().min(1),
        customer_name: Yup.string().min(1),
        description: Yup.string().min(1),
        amount_before_vat: Yup.number().min(1),
        vat: Yup.number().min(1),
        amount_with_vat: Yup.number().min(1),
        payment_description: Yup.string().min(1),
        withholding: Yup.number().min(1),
        check_amount: Yup.number().min(1),
        cash_amount: Yup.number().min(1),
        // project_id: Yup.number().min(1),
        // crv_type_id: Yup.number().min(1),
    });
    useEffect(() => {
        get(ProjectURL, {
            limit: 10,
            filter: {
                name: filter1,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.name}`
                    );
                });
            setOptions1(data);
        });

        get(CrvTypeURL, {
            limit: 10,
            filter: {
                name: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.name}`
                    );
                });
            setOptions2(data);
        });
    }, [filter1, filter2]);

    const formSubmitHandler = (updateData, actions) => {
        setLoading(true);
        const update = {
            id: selectedRow.id,
            updateData,
        };
        patcher(CrvPaymentURL, update).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                dispatch(setRefresh(!refreshTable));
                setSuccess(true);
                setError(false);
                actions.resetForm();
                return 1;
            }
        });
    };

    return (
        <div>
            <TitleHeader title="Edit  Crv Payment" icon={<EditRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        values.project_id = parseInt(
                            optionSelect1?.split("-")[0]
                        );
                        values.crv_type_id = parseInt(
                            optionSelect2?.split("-")[0]
                        );

                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl>
                                    <FormLabel>Date</FormLabel>
                                    <TextFieldWrapper
                                        name="date"
                                        type="date"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="name"
                                    label="Name"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="customer_name"
                                    label="Customer Name"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="amount_before_vat"
                                    label="Amount Before Vat"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="vat"
                                    label="Vat"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="amount_with_vat"
                                    label="Amount With Vat"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="payment_description"
                                    label="Payment Description"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="withholding"
                                    label="Withholding"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="check_amount"
                                    label="Check Amount"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="cash_amount"
                                    label="Cash Amount"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect1}
                                    loading={options1.length < 1 ? true : false}
                                    disablePortal
                                    options={options1}
                                    onChange={(event, newValue) => {
                                        setOptionSelect1(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Project"
                                            onChange={(event) => {
                                                setFilter1(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect2}
                                    loading={options2.length < 1 ? true : false}
                                    disablePortal
                                    options={options2}
                                    onChange={(event, newValue) => {
                                        setOptionSelect2(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Crv Type"
                                            onChange={(event) => {
                                                setFilter2(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="description"
                                    label="Description"
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Apply"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default EditCrvPayment;
