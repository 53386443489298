import { DialogTitle, Dialog, Paper } from "@mui/material";
import { CheckCircle, Error } from "@mui/icons-material";
import React, { useEffect, useState } from "react";

export const SnackBar = (props) => {
    const [open, setOpen] = useState(false);

    const snackBarToggle = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (props.error || props.success) snackBarToggle();
    }, [props.error, props.success]);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                handleClose();
            }}
        >
            <Paper
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "2ch 5ch 1ch 5ch",
                }}
            >
                {props.success ? (
                    <CheckCircle color={"success"} fontSize="large" />
                ) : (
                    <Error color={"error"} />
                )}
                <DialogTitle>{props.message}</DialogTitle>
            </Paper>
        </Dialog>
    );
};
