import { Card, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { get } from "../../../utils/Caller";
import { EmployeeURL } from "../../../utils/config";

const EmployeesDashboard = () => {
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalEmployees, setTotalEmployees] = useState(0);

    useEffect(() => {
        setLoading(true);
        get(EmployeeURL, { limit: 1000 }).then((res) => {
            setLoading(false);
            let cleanData = [];
            res.data &&
                res.data?.forEach((data) => {
                    let date = new Date(data.creationDate).setHours(0, 0, 0, 0);
                    cleanData.push(new Date(date).toISOString());
                });
            let a = {};
            setTotalEmployees(cleanData.length);
            cleanData.forEach((data) => {
                let test = Object.keys(a).find((b) => {
                    return b === data;
                });
                if (!test) {
                    a = {
                        ...a,
                        [data]: 1,
                    };
                } else {
                    a = {
                        ...a,
                        [data]: a[data] + 1,
                    };
                }
            });
            cleanData = Object.keys(a).map((d) => [new Date(d), a[d]]);
            setEmployees(cleanData);
        });
    }, []);

    const columns = [
        { type: "date", label: "Day" },
        `Total Employees ${totalEmployees}`,
    ];

    return (
        <Card elevation={0} width="100%">
            {loading && <Skeleton />}
            <Chart
                chartType="Line"
                data={[columns, ...employees]}
                width="100%"
                height="100%"
                legendToggle
                onLoad={() => {
                    setLoading(false);
                }}
                options={{
                    height: 400,
                }}
                chartEvents={[
                    {
                        eventName: "select",
                        callback({ chartWrapper }) {
                            let a = chartWrapper.getChart().getSelection();
                            console.log(a);
                        },
                    },
                    {
                        eventName: "error",
                        callback({ google }) {
                            google.visualization.errors.removeAll();
                        },
                    },
                ]}
            />
        </Card>
    );
};

export default EmployeesDashboard;
