import PropTypes from "prop-types";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { EditRounded, DeleteRounded } from "@mui/icons-material";
import { Modal, TablePagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSelected } from "../../Redux/Slice/selectedRow";
import EditModal from "../../Layouts/Modal/EditModal.modal";
import DeleteModal from "../../Layouts/Modal/DeleteModal.modal";

const ExpandableTable = ({
    actions,
    columns,
    rows,
    subColumns,
    subRows,
    subTitle,
}) => {
    const [open, setOpen] = useState(-1); 

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const dispatch = useDispatch();
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const editButtonHandler = (event, values) => {
        dispatch(setSelected(values));
        setEditModal(true);
    };

    const deleteButtonHandler = (event, values) => {
        dispatch(setSelected(values));
        setDeleteModal(true);
    };

    const closeModal = () => {
        setEditModal(false);
        setDeleteModal(false);
    };

    /**
     * @returns rows of table
     */
    const Row = () => {
        return (
            <>
                {rows.length > 0 &&
                    rows
                        .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                        )
                        .map((rowData, ind) => {
                            return (
                                <>
                                    <TableRow
                                        hover
                                        key={ind}
                                        sx={{
                                            "& > *": { borderBottom: "unset" },
                                        }}
                                    >
                                        <TableCell
                                            style={{ padding: "0 !important" }}
                                        >
                                            <IconButton
                                                size="small"
                                                onClick={() =>
                                                    setOpen(
                                                        ind === open ? -1 : ind
                                                    )
                                                }
                                            >
                                                {ind === open ? (
                                                    <KeyboardArrowUpIcon />
                                                ) : (
                                                    <KeyboardArrowDownIcon />
                                                )}
                                            </IconButton>
                                        </TableCell>
                                        {columns.map(({ field }, index) => {
                                            return (
                                                <TableCell
                                                    key={index}
                                                    scope="row"
                                                    style={{
                                                        padding: "0 !important",
                                                    }}
                                                >
                                                    {rowData[field]}
                                                </TableCell>
                                            );
                                        })}
                                        {actions && (
                                            <TableCell
                                                style={{
                                                    display: "flex",
                                                    // height: "5.2ch",
                                                }}
                                                scope="row"
                                            >
                                                <IconButton
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        editButtonHandler(
                                                            event,
                                                            rowData
                                                        );
                                                    }}
                                                >
                                                    <EditRounded color="primary" />
                                                </IconButton>
                                                <IconButton
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        deleteButtonHandler(
                                                            event,
                                                            rowData
                                                        );
                                                    }}
                                                >
                                                    <DeleteRounded color="error" />
                                                </IconButton>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                    {subColumns?.map((subRowData, index) => {
                                        return (
                                            ind === index && (
                                                <TableRow>
                                                    <TableCell
                                                        style={{
                                                            paddingBottom: 0,
                                                            paddingTop: 0,
                                                        }}
                                                        colSpan={6}
                                                    >
                                                        <Collapse
                                                            in={open === ind}
                                                            timeout="auto"
                                                            unmountOnExit
                                                        >
                                                            <Box
                                                                sx={{
                                                                    margin: 1,
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="h6"
                                                                    gutterBottom
                                                                    component="div"
                                                                >
                                                                    {subTitle}
                                                                </Typography>
                                                                <Table size="small">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            {subColumns.map(
                                                                                (
                                                                                    {
                                                                                        headerName,
                                                                                    },
                                                                                    key
                                                                                ) => {
                                                                                    return (
                                                                                        <TableCell
                                                                                            key={
                                                                                                key
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                headerName
                                                                                            }
                                                                                        </TableCell>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {subRows.length >
                                                                            0 &&
                                                                            subRows[
                                                                                index
                                                                            ].map(
                                                                                (
                                                                                    subRow,

                                                                                    key
                                                                                ) => {
                                                                                    return (
                                                                                        <TableRow
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                        >
                                                                                            {[
                                                                                                ...subColumns,
                                                                                            ].map(
                                                                                                (
                                                                                                    {
                                                                                                        field,
                                                                                                    },
                                                                                                    key
                                                                                                ) => {
                                                                                                    return (
                                                                                                        <TableCell
                                                                                                            key={
                                                                                                                key
                                                                                                            }
                                                                                                        >
                                                                                                            {
                                                                                                                subRow[
                                                                                                                    field
                                                                                                                ]
                                                                                                            }
                                                                                                        </TableCell>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                        </TableRow>
                                                                                    );
                                                                                }
                                                                            )}
                                                                    </TableBody>
                                                                </Table>
                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        );
                                    })}
                                </>
                            );
                        })}
            </>
        );
    };
    return (
        <Paper variant="outlined">
            <TableContainer>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow
                            style={{
                                backgroundColor: "rgba(95, 158, 160, 0.39)",
                            }}
                        >
                            <TableCell />
                            {columns.length > 0 &&
                                columns.map(({ headerName, width }, index) => {
                                    return (
                                        <>
                                            <TableCell
                                                key={index}
                                                width={width}
                                                style={{
                                                    textOverflow: "clip",
                                                }}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        fontWeight: "700",
                                                        borderRight:
                                                            "solid 0.5px silver",
                                                        textOverflow: "clip",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        paddingRight: "10px",
                                                    }}
                                                >
                                                    {headerName}
                                                </Typography>
                                            </TableCell>
                                        </>
                                    );
                                })}
                            <TableCell
                                style={{
                                    fontWeight: "700",
                                }}
                            >
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <Row />
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[8, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Modal
                className="Modal"
                open={editModal || deleteModal}
                onClose={() => {
                    setEditModal(false);
                    setDeleteModal(false);
                }}
            >
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {editModal && <EditModal close={closeModal} />}
                    {deleteModal && (
                        <DeleteModal
                            name={selectedRow.name}
                            id={selectedRow.id}
                            close={closeModal}
                        />
                    )}
                </Box>
            </Modal>
        </Paper>
    );
};

ExpandableTable.propTypes = {
    actions: PropTypes.bool,
    columns: PropTypes.array,
    rows: PropTypes.array,
    subColumns: PropTypes.array,
    subRows: PropTypes.array,
    subTitle: PropTypes.string,
};

export default ExpandableTable;
