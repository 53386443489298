import { Checkbox, FormControl, FormLabel, Grid, Box } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { caller } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import { LeaveTransferURL } from "../../../../utils/config";
import { useSelector } from "react-redux";

const AddLeaveTransfer = () => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    // const [filter, setFilter] = useState("");
    // const [options, setOptions] = useState([]);
    // const [optionsId, setOptionsID] = useState();
    // const [optionSelect, setOptionSelect] = useState("");
    // const [LeaveReqStat, setLeaveReqStat] = useState("");
    const [msg, setMsg] = useState("");
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    // const LEAVE_REQ_STATUS = [
    //     "Pending",
    //     "Approved",
    //     "Rejected",
    //     "Released",
    //     "Locked",
    // ];

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        from_year: "",
        to_year: "",
        number_of_days: "",
        // leave_request_status: "",
        remark: "",
        action_date: "",
        delegated_user_name: "",
        employee_id: selectedRow.id,
    };

    const FORM_VALIDATION = Yup.object().shape({
        from_year: Yup.number().min(1).required("*Required"),
        to_year: Yup.number().min(1).required("*Required"),
        number_of_days: Yup.number().min(1).required("*Required"),
        // leave_request_status: Yup.number().min(1),
        remark: Yup.string().min(1),
        action_date: Yup.string().min(1).required("*Required"),
        delegated_user_name: Yup.string().min(1).required("*Required"),
    });
    // useEffect(() => {
    //     get(EmployeeURL, {
    //         limit: 10,
    //         filter: {
    //             first_name: filter,
    //         },
    //     }).then((res) => {
    //         let data = [];
    //         res &&
    //             res.data.forEach((name, index) => {
    //                 data.push(
    //                     `${res.data[index]?.id} - ${res.data[index]?.first_name}`
    //                 );
    //             });
    //         res && setOptionsID(res.data);
    //         setOptions(data);
    //     });
    // }, [filter]);

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        caller(LeaveTransferURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                actions.resetForm();
                return 1;
            }
        });
    };

    return (
        <div>
            <TitleHeader title="Add Leave Transfer" icon={<AddRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        // values.leave_request_status = LeaveReqStat;
                        values.employee_id = selectedRow.id;
                        // optionsId.forEach((name, index) => {
                        //     if (
                        //         `${name?.id} - ${name.first_name}` ===
                        //         optionSelect
                        //     ) {
                        //         values.employee_id = name.id;
                        //     }
                        // });

                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="from_year"
                                    label="From Year"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="to_year"
                                    label="To Year"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="number_of_days"
                                    label="Number Of Days"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            {/* <Grid item xs={6}>
                                <TextFieldWrapper
                                    select
                                    name="leave_request_status"
                                    label="Leave Request Status"
                                    variant="outlined"
                                    options={LEAVE_REQ_STATUS}
                                    value={LeaveReqStat}
                                    onChange={(e) => {
                                        setLeaveReqStat(e.target.value);
                                    }}
                                />
                            </Grid> */}
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="remark"
                                    label="Remark"
                                    variant="outlined"
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <FormLabel>Action Date</FormLabel>
                                    <TextFieldWrapper
                                        name="action_date"
                                        variant="outlined"
                                        type="date"
                                    />
                                </FormControl>
                            </Grid>
                            <Box width={"100%"} />

                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="delegated_user_name"
                                    label="Delegated User Name"
                                    variant="outlined"
                                />
                            </Grid>

                            {/* <Grid item xs={6}>
                                <Autocomplete
                                    loading={options.length < 1 ? true : false}
                                    disablePortal
                                    options={options}
                                    onChange={(event, newValue) => {
                                        setOptionSelect(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Employee"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid> */}

                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default AddLeaveTransfer;
