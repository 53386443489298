import {
    Autocomplete,
    Box,
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../../../../Components/SnackBar/SnackBar2.component.jsx";
import { get, patcher } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import { EditRounded } from "@mui/icons-material";
import {
    DocumentationURL,
    RequiredDocumentationURL,
} from "../../../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import dateFormatter from "../../../../utils/dateFormater";
// import { useSelector } from "react-redux";

const EditRequiredDocumentation = (props) => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState();
    const [options, setOptions] = useState([]);
    const [optionsId, setOptionsID] = useState();
    const [optionSelect, setOptionSelect] = useState("");
    const [optionsLoading, setOptionsLoading] = useState(true);
    // const selectedRow = useSelector((state) => state.selectedRow.selected);
    const selectedDetail = useSelector(
        (state) => state.selectedDetail.selected
    );
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [msg, setMsg] = useState("");

    useEffect(() => {
        if (optionSelect === "")
            setOptionSelect(
                `${selectedDetail.documentation?.id} - ${selectedDetail.documentationName}`
            );
    }, [selectedDetail, optionSelect]);

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        spec_reference: selectedDetail.spec_reference,
        requested_by: selectedDetail.requested_by,
        quantity_needed: selectedDetail.quantity_needed,
        date_requested: dateFormatter(selectedDetail.date_requested),
        date_needed: dateFormatter(selectedDetail.date_needed),
        assigned_to: selectedDetail.assigned_to,
        location: selectedDetail.location,
        documentation_id: selectedDetail.documentation?.id,
        received: selectedDetail.received,
        received_date: dateFormatter(selectedDetail.received_date),
    };

    const FORM_VALIDATION = Yup.object().shape({
        spec_reference: Yup.string().min(4),
        requested_by: Yup.string().min(4).required("*Required"),
        quantity_needed: Yup.number().min(4).required("*Required"),
        date_requested: Yup.string().min(4).required("*Required"),
        date_needed: Yup.string().min(4).required("*Required"),
        assigned_to: Yup.string().min(4).required("*Required"),
        location: Yup.string().min(4).required("*Required"),
        received: Yup.string().min(4).required("*Required"),
        received_date: Yup.string().min(4).required("*Required"),
    });

    useEffect(() => {
        get(DocumentationURL, {
            limit: 10,
            filter: {
                name: filter,
            },
        }).then((res) => {
            let data = [];
            if (res.data.length === 0) {
                setOptionsLoading(false);
            }
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].name}`
                    );
                });
            res && setOptionsID(res.data);
            setOptions(data);
        });
    }, [filter]);

    const formSubmitHandler = (updateData, actions) => {
        setLoading(true);
        const update = {
            id: selectedDetail.id,
            updateData,
        };

        // console.log(update);
        patcher(RequiredDocumentationURL, update).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                dispatch(setRefresh(!refreshTable));
                setSuccess(true);
                setError(false);
                props.close();
            }
        });
    };

    return (
        <div>
            <TitleHeader
                title="Edit Required Documentation"
                icon={<EditRounded />}
            />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        optionsId.forEach((name, index) => {
                            if (`${name?.id} - ${name.name}` === optionSelect) {
                                values.documentation_id = name.id;
                            }
                        });
                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="spec_reference"
                                    label="Spec. Reference"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="requested_by"
                                    label="Requested By"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="quantity_needed"
                                    label="Quantity Needed"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>Date Requested</FormLabel>
                                    <TextFieldWrapper
                                        name="date_requested"
                                        variant="outlined"
                                        type="date"
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <FormLabel>Date Needed</FormLabel>
                                    <TextFieldWrapper
                                        name="date_needed"
                                        variant="outlined"
                                        type="date"
                                    />
                                </FormControl>
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="assigned_to"
                                    label="Assigned To"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="location"
                                    label="Location"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    value={optionSelect}
                                    loading={
                                        options.length < 1 && optionsLoading
                                    }
                                    disablePortal
                                    options={options}
                                    onChange={(event, newValue) => {
                                        setOptionSelect(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Manpower Name"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="received"
                                    label="Received"
                                    variant="outlined"
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>Received Date</FormLabel>
                                    <TextFieldWrapper
                                        name="received_date"
                                        variant="outlined"
                                        type="date"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Apply"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};

export default EditRequiredDocumentation;
