import {
    Box,
    TextField,
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { EditRoadRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../Components/TextFieldWrapper.component";
import "../../Assets/Sass/AddProject.style.scss";
import { Client, ProjectURL, EmployeeURL } from "../../utils/config";
import { patcher, get } from "../../utils/Caller";
import TitleHeader from "../../Components/TitleHeader/TitleHeader.component";
import { SnackBar } from "../../Components/SnackBar/SnackBar.component";
import { useDispatch, useSelector } from "react-redux";
import { setRefresh } from "../../Redux/Slice/refreshSlice";
import dateFormatter from "../../utils/dateFormater";

const EditProject = (props) => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [filter, setFilter] = useState("");
    const [options, setOptions] = useState([]);
    const [optionsId, setOptionsID] = useState();
    const [optionSelect, setOptionSelect] = useState("");
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [msg, setMsg] = useState("");
    const [optionSelect2, setOptionSelect2] = useState("");
    const [optionSelect3, setOptionSelect3] = useState("");
    const [optionSelect4, setOptionSelect4] = useState("");
    const [options2, setOptions2] = useState([]);
    const [filter2, setFilter2] = useState("");

    useEffect(() => {
        if (optionSelect === "") setOptionSelect(selectedRow.client?.tradeName);
        if (optionSelect2 === "")
            setOptionSelect2(
                `${selectedRow.project_manager?.id} - ${selectedRow.projectManager}`
            );
        if (optionSelect3 === "")
            setOptionSelect3(
                `${selectedRow.site_engineer?.id} - ${selectedRow.siteEngineer}`
            );
        if (optionSelect4 === "")
            setOptionSelect4(
                `${selectedRow.dupty_manager?.id} - ${selectedRow.duptyManager}`
            );
    }, [
        selectedRow,
        optionSelect,
        optionSelect2,
        optionSelect3,
        optionSelect4,
    ]);

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        project_description: selectedRow.project_description,
        name: selectedRow.name,
        project_manager: selectedRow.project_manager?.id,
        project_start_date: dateFormatter(selectedRow.project_start_date),
        project_end_date: dateFormatter(selectedRow.project_end_date),
        contract_number: selectedRow.contract_number,
        site_engineer: selectedRow.site_engineer?.id,
        dupty_manager: selectedRow.dupty_manager?.id,
        project_address: selectedRow.project_address,
        project_id: selectedRow.project_id,
        client_id: selectedRow.client_id,
    };
    const FORM_VALIDATION = Yup.object().shape({
        project_description: Yup.string().min(4),
        name: Yup.string().min(3).required("*Required"),
        // project_manager: Yup.string().min(4).required("*Required"),
        project_start_date: Yup.string().min(4).required("*Required"),
        project_end_date: Yup.string().min(4).required("*Required"),
        contract_number: Yup.string().min(2).required("*Required"),
        // site_engineer: Yup.string().min(3).required("*Required"),
        // dupty_manager: Yup.string().min(3).required("*Required"),
        project_address: Yup.string().min(2).required("*Required"),
    });

    const submitFormHandler = (updateData, actions) => {
        setLoading(true);
        const update = {
            id: selectedRow.id,
            updateData,
        };

        patcher(ProjectURL, update).then((res) => {
            setLoading(false);
            // console.log(res.error);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                dispatch(setRefresh(!refreshTable));
                setSuccess(true);
                setError(false);
                props.close();
            }
        });
    };

    useEffect(() => {
        get(Client, {
            limit: 10,
            filter: {
                tradeName: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res?.data?.forEach((name, index) => {
                    data?.push(res.data[index].tradeName);
                });
            res && setOptionsID(res.data);
            setOptions(data);
        });
        get(EmployeeURL, {
            limit: 10,
            filter: {
                all: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.first_name}`
                    );
                });
            setOptions2(data);
        });
    }, [filter, filter2]);

    return (
        <div>
            <TitleHeader title="Edit Project" icon={<EditRoadRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        optionsId.forEach((name, index) => {
                            if (name.tradeName === optionSelect) {
                                values.client_id = name.id;
                            }
                        });

                        values.isProtectedForEdit = protectedEdit;
                        values.project_manager = parseInt(
                            optionSelect2?.split("-")[0]
                        );
                        values.site_engineer = parseInt(
                            optionSelect3?.split("-")[0]
                        );
                        values.dupty_manager = parseInt(
                            optionSelect4?.split("-")[0]
                        );

                        submitFormHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="project_id"
                                    label="Project ID"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="name"
                                    label="Project Name"
                                    variant="outlined"
                                />
                            </Grid>
                            {/* <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="project_manager"
                                    label="Project Manager"
                                    variant="outlined"
                                />
                            </Grid> */}
                            <Grid item xs={4}>
                                <Autocomplete
                                    value={optionSelect2}
                                    loading={options2.length < 1 ? true : false}
                                    disablePortal
                                    options={options2}
                                    onChange={(event, newValue) => {
                                        setOptionSelect2(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Project Manager"
                                            onChange={(event) => {
                                                setFilter2(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>Project Start Date</FormLabel>
                                    <TextFieldWrapper
                                        name="project_start_date"
                                        variant="outlined"
                                        type="date"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>Project End Date</FormLabel>
                                    <TextFieldWrapper
                                        name="project_end_date"
                                        variant="outlined"
                                        type="date"
                                    />
                                </FormControl>
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="contract_number"
                                    label="Contract  Number"
                                    variant="outlined"
                                />
                            </Grid>
                            {/* <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="site_engineer"
                                    label="Site Engineer"
                                    variant="outlined"
                                />
                            </Grid> */}
                            <Grid item xs={4}>
                                <Autocomplete
                                    value={optionSelect3}
                                    loading={options2.length < 1 ? true : false}
                                    disablePortal
                                    options={options2}
                                    onChange={(event, newValue) => {
                                        setOptionSelect3(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Site Engineer"
                                            onChange={(event) => {
                                                setFilter2(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Box width="100%" />
                            {/* <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="dupty_manager"
                                    label="Dupty Manager"
                                    variant="outlined"
                                />
                            </Grid> */}
                            <Grid item xs={4}>
                                <Autocomplete
                                    value={optionSelect4}
                                    loading={options2.length < 1 ? true : false}
                                    disablePortal
                                    options={options2}
                                    onChange={(event, newValue) => {
                                        setOptionSelect4(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Dupty Manager"
                                            onChange={(event) => {
                                                setFilter2(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="project_address"
                                    label="Project Address"
                                    variant="outlined"
                                />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={4}>
                                <Autocomplete
                                    value={optionSelect}
                                    loading={options.length < 1 ? true : false}
                                    disablePortal
                                    options={options}
                                    onChange={(event, newValue) => {
                                        setOptionSelect(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Client Trade Name"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldWrapper
                                    name="project_description"
                                    label="Description"
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Apply"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
                {error || success ? (
                    <SnackBar
                        error={error}
                        success={success}
                        message={error ? msg : `Success^${Math.random()}`}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default EditProject;
