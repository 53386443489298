import { Add, SearchRounded } from "@mui/icons-material";
import { Button, InputAdornment, TextField, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ManpowerRequirementURL } from "../../../../utils/config";

import { get } from "../../../../utils/Caller";
import OperationsTable from "../../../../Components/Table/OperationsTable.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";

const ViewManpowerRequirement = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(16);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState("");
    const [Search, setSearch] = useState("");

    const columns = [
        {
            field: "number_of_actual_man_power",
            headerName: "Actual Manpower ",
            width: 100,
        },
        {
            field: "number_of_planned_man_power",
            headerName: "Planned Manpower",
            width: 100,
        },
        {
            field: "food_expense",
            headerName: "Food Expense",
            width: 100,
        },
        {
            field: "wage",
            headerName: "Wage",
            width: 100,
        },
        {
            field: "house_rent",
            headerName: "House Rent",
            width: 100,
        },
        {
            field: "transportation",
            headerName: "Transportation",
            width: 100,
        },
        {
            field: "total_expense",
            headerName: "Total Expense",
            width: 100,
        },
        {
            field: "manpowerName",
            headerName: "Manpower Id.",
            width: 100,
        },
    ];

    useEffect(() => {
        let tableData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(ManpowerRequirementURL, {
            limit: limit,
            filter: { project_id: selectedRow.id, all: filter },
        }).then((res) => {
            // console.log(res);
            res && res.data && setloading(false);
            res &&
                res.data?.forEach((data, index) => {
                    tableData.push({
                        ...data,
                        manpowerName: data.manpower?.name,
                    });
                });
            setRows(tableData);
        });
    }, [refreshTable, filter, limit, selectedRow, rows.length]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <Button
                    variant="contained"
                    color="success"
                    endIcon={<Add />}
                    onClick={() => {
                        navigate(
                            `/View/ViewProject/${selectedRow.id}/ManpowerRequirement/AddManpowerRequirement`
                        );
                    }}
                >
                    Add Manpower Requirement
                </Button>
            </div>
            <div className="TableContainer">
                <OperationsTable
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                />
            </div>
        </div>
    );
};

export default ViewManpowerRequirement;
