import dayjs from "dayjs";

/**
 *
 * @param {TIME} time
 * @returns HH:mm formatted Time
 */

const timeFormatter = (time) => {
    let minute = time - parseInt(time);
    minute *= 60;
    return dayjs(`Wed Feb 02 2022 ${parseInt(time)}:${minute}:00`).format(
        `HH:mm`
    );
};

export default timeFormatter;
