import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selected: null,
};

export const selectedRow = createSlice({
    name: "setSelectedRow",
    initialState,
    reducers: {
        setSelected: (state, action) => {
            state.selected = action.payload;
            localStorage.setItem("selected", JSON.stringify(action.payload));
        },
    },
});

export const { setSelected } = selectedRow.actions;

export default selectedRow.reducer;
