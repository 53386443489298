import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
    Stack,
    Box,
    IconButton,
    Modal,
    Paper,
    Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../../Components/TextFieldWrapper.component";
import "../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../Components/SnackBar/SnackBar.component.jsx";
import { caller, get } from "../../../utils/Caller";
import TitleHeader from "../../../Components/TitleHeader/TitleHeader.component";
import { RoleURL, PrivilegeURL } from "../../../utils/config";
import { AiOutlineInfoCircle } from "react-icons/ai";

const AddRole = () => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setMsg] = useState("");
    const [filter, setFilter] = useState("");
    const [options, setOptions] = useState([]);
    const [optionSelect, setOptionSelect] = useState("");
    const [privileges, setPrivileges] = useState([]);
    const [open, setOpen] = useState(false);

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        name: "",
        privileges: [],
        description: "",
    };

    const FORM_VALIDATION = Yup.object().shape({
        name: Yup.string().min(1).required("*Required"),
        description: Yup.string(),
    });

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        get(PrivilegeURL, {
            limit: 20,
            filter: {
                all: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.action}`,
                    );
                });
            setOptions(data);
        });
    }, [filter]);

    useEffect(() => {
        get(PrivilegeURL, {
            limit: 1000,
        }).then((res) => {
            res && setPrivileges(res.data);
        });
    }, []);

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        caller(RoleURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                actions.resetForm();
            }
        });
    };

    return (
        <div>
            <TitleHeader title="Add Role" icon={<AddRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        optionSelect.forEach((option, index) => {
                            let privilege_id = parseInt(option.split("-")[0]);
                            values.privileges.push(privilege_id);
                        });
                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="name"
                                    label="Name"
                                    variant="outlined"
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={6}>
                                <Stack spacing={3}>
                                    <Autocomplete
                                        multiple
                                        getOptionLabel={(option) =>
                                            option || ""
                                        }
                                        filterSelectedOptions
                                        loading={
                                            options.length < 1 ? true : false
                                        }
                                        options={options}
                                        onChange={(event, newValue) => {
                                            setOptionSelect(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Privileges"
                                                onChange={(event) => {
                                                    setFilter(
                                                        event.target.value,
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Stack>
                                <IconButton
                                    onClick={() => {
                                        setOpen(true);
                                    }}
                                >
                                    <AiOutlineInfoCircle />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldWrapper
                                    name="description"
                                    label="Description"
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked,
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
            <Modal className="Modal" open={open} onClose={handleClose}>
                <Paper style={{ width: "60%", height: "70vh" }}>
                    {privileges.map((privilege, index) => {
                        return (
                            <Box
                                key={index}
                                width="100%"
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Typography>{privilege.name}</Typography>
                                <Typography>{privilege.description}</Typography>
                            </Box>
                        );
                    })}
                </Paper>
            </Modal>
        </div>
    );
};
export default AddRole;
