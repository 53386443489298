import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import AddWorkLog from "../WorkLogAndBranchOfWork/AddWorkLog.page";
import AddDailyWorkLog from "./AddDailyWorkLog.page";
import ViewDailyWorkLog from "./ViewDailyWorkLog.page";

const DailyWorkLog = ({ next, name, handleTabChange }) => {
    const location = useLocation();
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    return (
        <>
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/DailyWorkLog/AddDailyWorkLog` && (
                <AddDailyWorkLog
                    next={next}
                    name={name}
                    handleTabChange={handleTabChange}
                />
            )}
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/DailyWorkLog` && (
                <>
                    <ViewDailyWorkLog />
                </>
            )}
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/DailyWorkLog/AddWorkLogAndBranchOfWork` && (
                <AddWorkLog />
            )}
        </>
    );
};

export default DailyWorkLog;
