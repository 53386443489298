import { Add, SearchRounded } from "@mui/icons-material";
import { Button, InputAdornment, TextField, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RequiredDocumentationURL } from "../../../../utils/config";

import { get } from "../../../../utils/Caller";
import OperationsTable from "../../../../Components/Table/OperationsTable.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";

const ViewRequiredDocumentation = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(16);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const [filter, setFilter] = useState("");
    const [Search, setSearch] = useState("");
    const dispatch = useDispatch();

    const columns = [
        {
            field: "spec_reference",
            headerName: "Spec. Reference",
            width: 100,
        },
        {
            field: "requested_by",
            headerName: "Requested By",
            width: 100,
        },
        {
            field: "quantity_needed",
            headerName: "Quantity Needed",
            width: 100,
        },
        {
            field: "date_requested",
            headerName: "Date Requested",
            width: 100,
        },
        {
            field: "date_needed",
            headerName: "Date Needed",
            width: 100,
        },
        {
            field: "assigned_to",
            headerName: "Assigned To",
            width: 100,
        },
        {
            field: "location",
            headerName: "Location",
            width: 100,
        },
        {
            field: "documentationName",
            headerName: "Documentation Id",
            width: 100,
        },
        {
            field: "received",
            headerName: "Received",
            width: 100,
        },
        {
            field: "received_date",
            headerName: "Received Date",
            width: 100,
        },
        {
            field: "projectName",
            headerName: "Project Name",
            width: 100,
        },
    ];

    useEffect(() => {
        let requiredData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(RequiredDocumentationURL, {
            limit: limit,
            filter: { project_id: selectedRow.id, all: filter },
        }).then((res) => {
            // console.log(res);
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    requiredData.push({
                        ...data,
                        projectName: data.project?.name,
                        documentationName: data.documentation?.name,
                    });
                });
            setRows(requiredData);
        });
    }, [refreshTable, limit, selectedRow, filter, rows.length]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <Button
                    variant="contained"
                    color="success"
                    endIcon={<Add />}
                    onClick={() => {
                        navigate(
                            `/View/ViewProject/${selectedRow.id}/RequiredDocumentation/AddRequiredDocumentation`
                        );
                    }}
                >
                    Add Required Documentation
                </Button>
            </div>
            <div className="TableContainer">
                <OperationsTable
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                />
            </div>
        </div>
    );
};

export default ViewRequiredDocumentation;
