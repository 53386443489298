import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { caller, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    ChartOfAccountURL,
    ContactURL,
    CostCenterURL,
    GeneralJournalDetailURL,
    GeneralJournalHeaderURL,
    TaxGroupURL,
    TaxURL,
} from "../../../../utils/config";

const AddGeneralJournalDetail = () => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState("");
    const [filter2, setFilter2] = useState("");
    const [filter3, setFilter3] = useState("");
    const [filter4, setFilter4] = useState("");
    const [filter5, setFilter5] = useState("");
    const [filter6, setFilter6] = useState("");
    const [options1, setOptions1] = useState([]);
    const [optionsId1, setOptionsID1] = useState();
    const [optionSelect1, setOptionSelect1] = useState("");
    const [options2, setOptions2] = useState([]);
    const [optionsId2, setOptionsID2] = useState();
    const [optionSelect2, setOptionSelect2] = useState("");
    const [options3, setOptions3] = useState([]);
    const [optionsId3, setOptionsID3] = useState();
    const [optionSelect3, setOptionSelect3] = useState("");
    const [options4, setOptions4] = useState([]);
    const [optionsId4, setOptionsID4] = useState();
    const [optionSelect4, setOptionSelect4] = useState("");
    const [options5, setOptions5] = useState([]);
    const [optionsId5, setOptionsID5] = useState();
    const [optionSelect5, setOptionSelect5] = useState("");
    const [options6, setOptions6] = useState([]);
    const [optionsId6, setOptionsID6] = useState();
    const [optionSelect6, setOptionSelect6] = useState("");
    const [DebitOrCredit, setDebitOrCredit] = useState(1);
    const [msg, setMsg] = useState("");

    const DEBIT_OR_CREDIT = ["Credit", "Debit"];

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        description: "",
        debit_or_credit: DebitOrCredit,
        tax_group_id: "",
        amount_credit: "",
        posting_reference: "",
        reference_code: "",
        amount_debit: "",
        tax_id: null,
        contact_id: null,
        general_journal_header_id: null,
        cost_center_id: null,
        chart_of_account_id: null,
    };

    const FORM_VALIDATION = Yup.object().shape({
        description: Yup.string().min(1),
        debit_or_credit: Yup.number().min(1).required("*Required"),
        // tax_group_id: Yup.number().min(1),
        amount_credit: Yup.number().min(1),
        posting_reference: Yup.string().min(1).required("*Required"),
        reference_code: Yup.string().min(1).required("*Required"),
        amount_debit: Yup.number().min(1),
        // tax_id: Yup.number().min(1),
        // contact_id: Yup.number().min(1),
        // general_journal_header_id: Yup.number().min(1).required("*Required"),
        // cost_center_id: Yup.number().min(1).required("*Required"),
        // chart_of_account_id: Yup.number().min(1).required("*Required"),
    });
    
    useEffect(() => {
        get(TaxGroupURL, {
            limit: 10,
            filter: {
                tax_group_name: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.tax_group_name}`
                    );
                });
            res && setOptionsID1(res.data);
            setOptions1(data);
        });

        get(TaxURL, {
            limit: 10,
            filter: {
                tax_name: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.tax_name}`
                    );
                });
            res && setOptionsID2(res.data);
            setOptions2(data);
        });

        get(ContactURL, {
            limit: 10,
            filter: {
                company_name: filter3,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.company_name}`
                    );
                });
            res && setOptionsID3(res.data);
            setOptions3(data);
        });

        get(GeneralJournalHeaderURL, {
            limit: 10,
            filter: {
                posting_reference: filter4,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.posting_reference}`
                    );
                });
            res && setOptionsID4(res.data);
            setOptions4(data);
        });

        get(CostCenterURL, {
            limit: 10,
            filter: {
                cost_center_code: filter5,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.cost_center_code}`
                    );
                });
            res && setOptionsID5(res.data);
            setOptions5(data);
        });

        get(ChartOfAccountURL, {
            limit: 10,
            filter: {
                account_code: filter6,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.account_code}`
                    );
                });
            res && setOptionsID6(res.data);
            setOptions6(data);
        });
    }, [filter, filter2, filter3, filter4, filter5, filter6]);

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        caller(GeneralJournalDetailURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                actions.resetForm();
                return 1;
            }
        });
    };

    return (
        <div>
            <TitleHeader
                title="Add General Journal Detail"
                icon={<AddRounded />}
            />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        values.debit_or_credit = DebitOrCredit;

                        optionsId1.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.tax_group_name}` ===
                                optionSelect1
                            ) {
                                values.tax_group_id = name.id;
                            }
                        });
                        optionsId2.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.tax_name}` ===
                                optionSelect2
                            ) {
                                values.tax_id = name.id;
                            }
                        });
                        optionsId3.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.company_name}` ===
                                optionSelect3
                            ) {
                                values.contact_id = name.id;
                            }
                        });
                        optionsId4.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.posting_reference}` ===
                                optionSelect4
                            ) {
                                values.general_journal_header_id = name.id;
                            }
                        });
                        optionsId5.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.cost_center_code}` ===
                                optionSelect5
                            ) {
                                values.cost_center_id = name.id;
                            }
                        });
                        optionsId6.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.account_code}` ===
                                optionSelect6
                            ) {
                                values.chart_of_account_id = name.id;
                            }
                        });

                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    select
                                    name="debit_or_credit"
                                    label="Debit Or Credit"
                                    variant="outlined"
                                    value={DebitOrCredit}
                                    options={DEBIT_OR_CREDIT ?? " "}
                                    onChange={(e) => {
                                        setDebitOrCredit(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    loading={options1.length < 1 ? true : false}
                                    disablePortal
                                    options={options1}
                                    onChange={(event, newValue) => {
                                        setOptionSelect1(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Tax Group"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="amount_credit"
                                    label="Amount Credit"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="posting_reference"
                                    label="Posting Reference"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="reference_code"
                                    label="Reference Code"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="amount_debit"
                                    label="Amount Debit"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    loading={options2.length < 1 ? true : false}
                                    disablePortal
                                    options={options2}
                                    onChange={(event, newValue) => {
                                        setOptionSelect2(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Tax"
                                            onChange={(event) => {
                                                setFilter2(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    loading={options3.length < 1 ? true : false}
                                    disablePortal
                                    options={options3}
                                    onChange={(event, newValue) => {
                                        setOptionSelect3(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Contact"
                                            onChange={(event) => {
                                                setFilter3(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    loading={options4.length < 1 ? true : false}
                                    disablePortal
                                    options={options4}
                                    onChange={(event, newValue) => {
                                        setOptionSelect4(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="General Journal Header"
                                            onChange={(event) => {
                                                setFilter4(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    loading={options5.length < 1 ? true : false}
                                    disablePortal
                                    options={options5}
                                    onChange={(event, newValue) => {
                                        setOptionSelect5(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Cost Center"
                                            onChange={(event) => {
                                                setFilter5(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    loading={options6.length < 1 ? true : false}
                                    disablePortal
                                    options={options6}
                                    onChange={(event, newValue) => {
                                        setOptionSelect6(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Chart Of Account"
                                            onChange={(event) => {
                                                setFilter6(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldWrapper
                                    name="description"
                                    label="Description"
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default AddGeneralJournalDetail;
