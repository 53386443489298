import { Add, SearchRounded } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import OperationsTable from "../../../../Components/Table/OperationsTable.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { get } from "../../../../utils/Caller";
import { EmployeePayFrequencyURL } from "../../../../utils/config";

const ViewEmployeePayFrequency = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(20);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState();
    const [Search, setSearch] = useState();

    const selectedRow = useSelector((state) => state.selectedRow.selected);

    const columns = [
        {
            field: "expected_working_hrs",
            headerName: "Expected Working Hrs",
            width: 150,
        },
        {
            field: "employeeName",
            headerName: "Employee",
            width: 150,
        },
        {
            field: "payrollFrequencyType",
            headerName: "Payroll Frequency Type",
            width: 150,
        },
    ];

    useEffect(() => {
        let tableData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(EmployeePayFrequencyURL, {
            limit: limit,
            filter: {
                all: filter,
                employee_id: selectedRow?.id,
            },
        }).then((res) => {
            // console.log(res);
            res && res.data && setloading(false);
            res &&
                res.data?.forEach((data, index) => {
                    tableData.push({
                        ...data,
                        employeeName: data.employee?.first_name,
                        payrollFrequencyType:
                            data.payroll_frequency_type?.payroll_frequency_desc,
                    });
                });
            setRows(tableData);
        });
    }, [refreshTable, filter, limit, rows.length, selectedRow?.id]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setloading(true);
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <div style={{ display: "flex", gap: "2ch" }}>
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<Add />}
                        onClick={() => {
                            navigate(
                                "/View/HCM/EmployeePayFrequency/AddEmployeePayFrequency"
                            );
                        }}
                    >
                        Add Employee Pay Frequency
                    </Button>
                </div>
            </div>
            <div className="TableContainer">
                <OperationsTable
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                />
            </div>
        </div>
    );
};

export default ViewEmployeePayFrequency;
