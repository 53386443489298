import React from "react";
import { useLocation } from "react-router";
import AddManpower from "./AddManpower.page";
import ViewManpower from "./ViewManpower.page";

const Manpower = () => {
    const location = useLocation();

    return (
        <>
            {location.pathname === "/View/Manpower/AddManpower" && (
                <AddManpower />
            )}
            {location.pathname === "/View/Manpower" && <ViewManpower />}
        </>
    );
};

export default Manpower;
