export const Columns = {
  manpowerRequest: [
    // {
    //     field: "priorityName",
    //     headerName: "Priority ",
    // },
    {
      field: "persons",
      headerName: "Persons ",
    },
    {
      field: "description",
      headerName: "Description ",
    },
    // {
    //     field: "number_of_planned_man_power",
    //     headerName: "Planned Manpower",
    // },
    {
      field: "quantity",
      headerName: "Quantity",
    },
    {
      field: "unit_rate",
      headerName: "Unit Rate",
    },
    // {
    //     field: "sub_total",
    //     headerName: "Sub Total",
    // },
    // {
    //     field: "vat",
    //     headerName: "Vat",
    // },
    // {
    //     field: "total",
    //     headerName: "Total",
    // },
  ],
  request: [
    {
      field: "priorityName",
      headerName: "Priority",
    },
    {
      field: "request_description",
      headerName: "Request Description",
    },
    {
      field: "date_required",
      headerName: "Date Required",
    },
    {
      field: "date_responded",
      headerName: "Date Responded",
    },
    {
      field: "RFI_no",
      headerName: "RFI No",
    },
    {
      field: "current_status",
      headerName: "Current Status",
    },
    {
      field: "request_by",
      headerName: "Request By",
    },
    {
      field: "assigned_to",
      headerName: "Assigned To",
    },
    {
      field: "date_requested",
      headerName: "Date Requested",
    },
    {
      field: "notes",
      headerName: "Notes",
    },
    {
      field: "projectName",
      headerName: "Project Name",
    },
  ],
  requestPayment: [
    {
      field: "description",
      headerName: "Description",
    },
    {
      field: "quantity",
      headerName: "Quantity",
    },
    {
      field: "quantity_type",
      headerName: "Unit",
    },
    {
      field: "unit_rate",
      headerName: "Unit Rate",
    },
    // {
    //   field: "vat",
    //   headerName: "Vat",
    // },
  ],
  requiredDocument: [
    {
      field: "spec_reference",
      headerName: "Spec. Reference",
    },
    {
      field: "requested_by",
      headerName: "Requested By",
    },
    {
      field: "quantity_needed",
      headerName: "Quantity Needed",
    },
    {
      field: "date_requested",
      headerName: "Date Requested",
    },
    {
      field: "date_needed",
      headerName: "Date Needed",
    },
    {
      field: "assigned_to",
      headerName: "Assigned To",
    },
    {
      field: "location",
      headerName: "Location",
    },
    {
      field: "documentationName",
      headerName: "Documentation Id",
    },
    {
      field: "received",
      headerName: "Received",
    },
    {
      field: "received_date",
      headerName: "Received Date",
    },
    {
      field: "projectName",
      headerName: "Project Name",
    },
  ],
  store: [
    // {
    //   field: "priortyName",
    //   headerName: "Priority",
    // },
    // {
    //   field: "requested_date",
    //   headerName: "Date",
    // },
    // {
    //   field: "persons",
    //   headerName: "Persons",
    // },
    {
      field: "description",
      headerName: "Description",
    },
    {
      field: "returnable",
      headerName: "Returnable",
    },
    {
      field: "return_date",
      headerName: "Return Date",
    },
    {
      field: "quantity",
      headerName: "Quantity",
    },
    {
      field: "quantity_type",
      headerName: "Unit",
    },
    // {
    //   field: "unit_rate",
    //   headerName: "Unit Rate",
    // },
    // {
    //   field: "vat",
    //   headerName: "Vat",
    // },
  ],
  pvRequest: [
    {
      field: "to",
      headerName: "To",
    },
    {
      field: "from",
      headerName: "From",
    },
    {
      field: "amount",
      headerName: "Amount",
    },
    {
      field: "for",
      headerName: "For",
    },
    {
      field: "date",
      headerName: "Date",
    },
    {
      field: "prepare_payment_to_id",
      headerName: "Prepare Payment To ID",
    },
    {
      field: "prepare_payment_to_name",
      headerName: "Prepare Payment To Name",
    },
    {
      field: "project_request_id",
      headerName: "Project Request ID",
    },
    {
      field: "pv_no",
      headerName: "PV No",
    },
    {
      field: "check_no",
      headerName: "Check No",
    },
    {
      field: "account_number",
      headerName: "Account Number",
    },
    {
      field: "bank_id",
      headerName: "Bank",
    },
  ],
  ProjectPaymentRequests: [
    {
      field: "createdBy",
      headerName: "Created By",
    },
    {
      field: "creationDate",
      headerName: "Creation Date",
    },
    {
      field: "from_year",
      headerName: "From Year",
    },
    {
      field: "idNumber",
      headerName: "ID Number",
    },
    {
      field: "number_of_days",
      headerName: "Number Of Days",
    },
    {
      field: "to_year",
      headerName: "To Year",
    },
  ],
};
