import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import EditRoadRoundedIcon from "@mui/icons-material/EditRoadRounded";

import TitleHeader from "../../Components/TitleHeader/TitleHeader.component.jsx";
import "../../Assets/Sass/Style.scss";
// import ManpowerRequirement from "./Operational/ManpowerRequirement/ManpowerRequirement.page.jsx";
// import RequiredEquipment from "./Operational/RequiredEquipment/RequiredEquipment.page.jsx";
// import RequiredMaterial from "./Operational/RequiredMaterial/RequiredMaterial.page.jsx";
import ToDos from "./Operational/ToDos/ToDos.page.jsx";
import TaskManager from "./Operational/TaskManager/TaskManager.page.jsx";
import AddRequestForm from "./Operational/RequestForm/AddRequestForm.page.jsx";

const AddProjectTask = (props) => {
    const [value, setValue] = useState("0");
    const navigate = useNavigate();
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);

        if (newValue === "0") {
            navigate(path[0]);
        } else if (newValue === "1") {
            navigate(path[1]);
        } else if (newValue === "2") {
            navigate(path[2]);
        } else if (newValue === "3") {
            navigate(path[3]);
        } else if (newValue === "4") {
            navigate(path[4]);
        } else if (newValue === "5") {
            navigate(path[5]);
        }
    };

    const Tabs = [
        "Main Task",
        // "Manpower Requirement",
        // "Required Equipment",
        // "Required Material",
        // "To Do",
    ];

    const path = [
        `/View/ViewProject/ProjectAddDetail/AddProjectTask`,
        `/View/ViewProject/ProjectAddDetail/AddProjectTask`,
        `/View/ViewProject/ProjectAddDetail/AddProjectTask`,
        `/View/ViewProject/ProjectAddDetail/AddProjectTask`,
        `/View/ViewProject/ProjectAddDetail/AddProjectTask`,
    ];
    const addPath = [
        `/View/ViewProject/ProjectAddDetail/AddProjectTask/TaskManager/AddTaskManager`,
        `/View/ViewProject/ProjectAddDetail/AddProjectTask/ManpowerRequirement/AddManpowerRequirement`,
        `/View/ViewProject/ProjectAddDetail/AddProjectTask/RequiredEquipment/AddRequiredEquipment`,
        `/View/ViewProject/ProjectAddDetail/AddProjectTask/RequiredMaterial/AddRequiredMaterial`,
        `/View/ViewProject/ProjectAddDetail/AddProjectTask/ToDos/AddToDos`,
    ];

    return (
        <>
            <TitleHeader
                icon={<EditRoadRoundedIcon />}
                title={selectedRow?.name}
            />
            <div className="AddProjectTask">
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1 }}>
                        <TabList
                            onChange={handleTabChange}
                            variant="scrollable"
                        >
                            {Tabs.map((tab, index) => {
                                return (
                                    <Tab
                                        wrapped
                                        key={index}
                                        label={tab}
                                        value={String(index)}
                                        style={{
                                            // width: "6ch",
                                            color: "#0a1128",
                                        }}
                                    />
                                );
                            })}
                        </TabList>
                    </Box>
                    <TabPanel value="0">
                        <TaskManager
                            next={addPath[1]}
                            name={`Add ${Tabs[1]}?`}
                            handleTabChange={handleTabChange}
                        />
                    </TabPanel>
                    <TabPanel value="1">
                        {/* <ManpowerRequirement
                            next={addPath[2]}
                            name={`Add ${Tabs[2]}?`}
                            handleTabChange={handleTabChange}
                        /> */}
                        <AddRequestForm
                            requirement={true}
                            requirementType={2}
                        />
                    </TabPanel>
                    <TabPanel value="2">
                        <AddRequestForm
                            requirement={true}
                            requirementType={3}
                        />
                        {/* <RequiredEquipment
                            next={addPath[3]}
                            name={`Add ${Tabs[3]}?`}
                            handleTabChange={handleTabChange}
                        /> */}
                    </TabPanel>
                    <TabPanel value="3">
                        <AddRequestForm
                            requirement={true}
                            requirementType={3}
                        />
                        {/* <RequiredMaterial
                            next={addPath[4]}
                            name={`Add ${Tabs[4]}?`}
                            handleTabChange={handleTabChange}
                        /> */}
                    </TabPanel>
                    <TabPanel value="4">
                        <ToDos
                            next={addPath[5]}
                            name={`Add ${Tabs[5]}?`}
                            handleTabChange={handleTabChange}
                        />
                    </TabPanel>
                </TabContext>
            </div>
        </>
    );
};

export default AddProjectTask;
