import { IconButton, Paper, Badge } from "@mui/material";
import React, { useState, useEffect } from "react";
import Profile from "../../../Components/Profile/Profile.component";
import Logo from "../../../Assets/img/logo.svg";
import { MenuRounded, Notifications } from "@mui/icons-material";
import "../../../Assets/Sass/HeaderAnimation.style.scss";
import NotificationDrawer from "../../../Components/Notification/Notification.component";
import { get } from "../../../utils/Caller";
import { NotificationsURL } from "../../../utils/config";

const Header = ({ setMenuToggle }) => {
    const [open, setOpen] = useState(false);
    const [total, setTotal] = useState(0);

    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        get(NotificationsURL, {
            limit: 1000,
            filter: {
                total: true,
            },
        }).then((res) => {
            setTotal(res?.data?.total);
        });
    });

    return (
        <Paper className="Header" elevation={0}>
            <div className="cube" />
            <IconButton
                onClick={() => {
                    setMenuToggle();
                }}
            >
                <MenuRounded fontSize={"large"} style={{ color: `#14213d` }} />
            </IconButton>
            <img
                className="Logo"
                alt="El-Hadar ERP"
                src={Logo}
                width={"40px"}
                style={{
                    alignSelf: "center",
                    marginLeft: "10ch",
                    // padding: "2ch",
                }}
            />
            <div>
                <Profile />
                <IconButton
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    <Badge badgeContent={total} color="error" max={99}>
                        <Notifications />
                    </Badge>
                </IconButton>
                <NotificationDrawer open={open} handleClose={handleClose} />
            </div>
        </Paper>
    );
};

export default Header;
