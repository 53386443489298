import { Lock, LockOpen } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SnackBar } from "../../../../Components/SnackBar/SnackBar.component";

import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { caller, get } from "../../../../utils/Caller";
import {
    AccountingPeriodChangeStatusURL,
    AccountingPeriodProcessClosingURL,
    AccountingPeriodURL,
} from "../../../../utils/config";
import { GrDocumentConfig } from "react-icons/gr";

const ACCOUNTING_PERIOD_STATUS = ["Open", "Closed", "Future", "Access Locked"];
const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Augest",
    "September",
    "October",
    "November",
    "December",
    "Other",
];

const ViewAccountingPeriod = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [loadingLock, setLoadingLock] = useState(false);
    const [loadingProcess, setLoadingProcess] = useState(false);

    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setMsg] = useState("");

    const lockButtonHandler = (event, data) => {
        setLoadingLock(true);
        caller(AccountingPeriodChangeStatusURL, data).then((res) => {
            setLoadingLock(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                dispatch(setRefresh(!refreshTable));
                setSuccess(true);
                setError(false);
            }
        });
    };
    const processHandler = (event, data) => {
        setLoadingProcess(true);
        caller(AccountingPeriodProcessClosingURL, data).then((res) => {
            setLoadingProcess(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                dispatch(setRefresh(!refreshTable));
                setSuccess(true);
                setError(false);
            }
        });
    };

    const renderAction = (params) => {
        if (params.row?.is_current_posting_period) {
            return (
                <>
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            if (params.row?.accounting_period_status === 1) {
                                lockButtonHandler(event, {
                                    id: params.row?.id,
                                    accounting_period_status: 4,
                                });
                            } else if (
                                params.row?.accounting_period_status === 4
                            ) {
                                lockButtonHandler(event, {
                                    id: params.row?.id,
                                    accounting_period_status: 1,
                                });
                            }
                        }}
                    >
                        {loadingLock ? (
                            <CircularProgress size={"20px"} />
                        ) : params.row?.accounting_period_status === 1 ? (
                            <Lock color="primary" />
                        ) : (
                            <LockOpen color="primary" />
                        )}
                    </IconButton>

                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            processHandler(event, { id: params.row?.id });
                        }}
                    >
                        {loadingProcess ? (
                            <CircularProgress size={"20px"}/>
                        ) : (
                            <GrDocumentConfig color="blue" />
                        )}
                    </IconButton>
                </>
            );
        } else return null;
    };

    const columns = [
        {
            field: "month",
            headerName: "Month",
            width: 150,
        },
        {
            field: "period_number",
            headerName: "Period Number",
            width: 150,
        },
        {
            field: "period_starting_date",
            headerName: "Period Starting Date",
            width: 150,
            valueGetter: (params) =>
                new Date(params.row?.period_starting_date).toDateString(),
        },
        {
            field: "period_ending_date",
            headerName: "Period Ending Date",
            width: 150,
            valueGetter: (params) =>
                new Date(params.row?.period_ending_date).toDateString(),
        },
        {
            field: "accountingPeriodStatus",
            headerName: "Status",
            width: 150,
        },
        {
            field: "is_current_posting_period",
            headerName: "Is Current Posting Period",
            width: 150,
        },
        {
            field: "actions",
            headerName: "Actions",
            // type: "actions",
            minWidth: 150,
            flex: 1,
            renderCell: (params) => renderAction(params),
        },
    ];

    useEffect(() => {
        let tableData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(AccountingPeriodURL, {
            limit: 12,
        }).then((res) => {
            // console.log(res);
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    tableData.push({
                        ...data,
                        month: MONTHS[data.months - 1],
                        accountingPeriodStatus:
                            ACCOUNTING_PERIOD_STATUS[
                                data.accounting_period_status - 1
                            ],
                    });
                });
            setRows(tableData);
        });
    }, [refreshTable, rows.length]);

    return (
        <div className="ViewProject">
            {/* <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <SearchRounded />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setFilter(e.target.value);
                        dispatch(setRefresh(!refreshTable));
                    }}
                />
            </div> */}
            <div className="TableContainer">
                <DataGrid
                    density="compact"
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    autoHeight={true}
                    pageSize={12}
                    // rowsPerPageOptions={[8]}
                    onRowClick={(selected) => {}}
                />
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};

export default ViewAccountingPeriod;
