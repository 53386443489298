import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
    Box,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { EditRounded } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../../../../Components/SnackBar/SnackBar.component.jsx";
import { get, patcher } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    EmployeeURL,
    ProjectAllRequestURL,
    BankURL,
    PVRequestURL,
} from "../../../../utils/config";
import dateFormatter from "../../../../utils/dateFormater";

const FOR = ["Petty Cash Replenishment", "Project Request", "Other"];
const REQUEST_TYPE = ["Payment Request", "Manpower Request", "Store"];

const EditPVRequest = (props) => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setMsg] = useState("");
    const [filter, setFilter] = useState("");
    const [filter2, setFilter2] = useState("");
    const [filter3, setFilter3] = useState("");
    const [options1, setOptions1] = useState([]);
    const [optionSelect1, setOptionSelect1] = useState("");
    const [options2, setOptions2] = useState([]);
    const [optionSelect2, setOptionSelect2] = useState("");
    const [options3, setOptions3] = useState([]);
    const [optionSelect3, setOptionSelect3] = useState("");
    const [optionSelect4, setOptionSelect4] = useState("");
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const [forState, setForState] = useState(selectedRow.for);
    const dispatch = useDispatch();

    useEffect(() => {
        if (optionSelect1 === "")
            setOptionSelect1(
                `${selectedRow.prepare_payment_to?.id} - ${selectedRow.preparePaymentTo}`
            );
        if (optionSelect2 === "")
            setOptionSelect2(
                `${selectedRow.project_request?.id} - ${selectedRow.projectRequest}`
            );
        if (optionSelect3 === "")
            setOptionSelect1(
                `${selectedRow.bank?.id} - ${selectedRow.bankName}`
            );
        if (optionSelect4 === "")
            setOptionSelect2(
                `${selectedRow.prepared_by?.id} - ${selectedRow.preparedBy}`
            );
    }, [
        selectedRow,
        optionSelect1,
        optionSelect2,
        optionSelect3,
        optionSelect4,
    ]);

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        to: selectedRow.to,
        from: selectedRow.from,
        amount: selectedRow.amount,
        for: forState,
        date: dateFormatter(selectedRow.date),
        prepare_payment_to_id: null,
        prepare_payment_to_name: selectedRow.prepare_payment_to_name,
        project_request_id: null,
        prepared_by_id: null,
        pv_no: selectedRow.pv_no,
        check_no: selectedRow.check_no,
        account_number: selectedRow.account_number,
        bank_id: null,
    };

    const FORM_VALIDATION = Yup.object().shape({
        to: Yup.string().required("*Required"),
        from: Yup.string().required("*Required"),
        amount: Yup.string().required("Required*"),
        date: Yup.string().required("Required*"),
    });

    useEffect(() => {
        get(EmployeeURL, {
            limit: 10,
            filter: {
                all: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.first_name}`
                    );
                });
            setOptions1(data);
        });

        get(ProjectAllRequestURL, {
            limit: 10,
            filter: {
                all: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${
                            res.data[index]?.prepared_by?.first_name
                        } - ${REQUEST_TYPE[res.data[index]?.request_type - 1]}`
                    );
                });
            setOptions2(data);
        });
        get(BankURL, {
            limit: 10,
            filter: {
                all: filter3,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.name}`
                    );
                });
            setOptions3(data);
        });
    }, [filter, filter2, filter3]);

    const formSubmitHandler = (updateData, actions) => {
        setLoading(true);
        patcher(PVRequestURL, { id: selectedRow.id, updateData }).then(
            (res) => {
                setLoading(false);
                // console.log(res.error);
                if (res.error) {
                    Object.keys(res.error).forEach((data, i) => {
                        setMsg(res.error[data]);
                    });
                    setSuccess(false);
                    setError(true);
                } else if (res.data?.success) {
                    dispatch(setRefresh(!refreshTable));
                    setSuccess(true);
                    setError(false);
                }
            }
        );
    };

    return (
        <div>
            <TitleHeader title="Edit PV Request" icon={<EditRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;

                        values.prepare_payment_to_id = parseInt(
                            optionSelect1?.split("-")[0]
                        );
                        values.project_request_id = parseInt(
                            optionSelect2?.split("-")[0]
                        );
                        values.bank_id = parseInt(optionSelect3?.split("-")[0]);
                        values.prepared_by_id = parseInt(
                            optionSelect4?.split("-")[0]
                        );
                        values.for = forState;
                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="to"
                                    label="To"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="from"
                                    label="From"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="amount"
                                    label="Amount"
                                    type="number"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    select
                                    name="for"
                                    label="For"
                                    variant="outlined"
                                    value={forState}
                                    options={FOR ?? " "}
                                    onChange={(e) => {
                                        setForState(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={6}>
                                <FormControl>
                                    <FormLabel>Date</FormLabel>
                                    <TextFieldWrapper
                                        name="date"
                                        type="date"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>
                            <Box width={"100%"} />

                            <Grid item xs={4}>
                                <Autocomplete
                                    loading={options1.length < 1 ? true : false}
                                    disablePortal
                                    options={options1}
                                    onChange={(event, newValue) => {
                                        setOptionSelect1(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Prepare Payment To"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    loading={options1.length < 1 ? true : false}
                                    disablePortal
                                    options={options1}
                                    onChange={(event, newValue) => {
                                        setOptionSelect4(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Prepared By"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            {!optionSelect1 && (
                                <Grid item xs={4}>
                                    <TextFieldWrapper
                                        name="prepare_payment_to_name"
                                        label="Prepare Payment To Name"
                                        variant="outlined"
                                    />
                                </Grid>
                            )}

                            {forState === 2 && (
                                <Grid item xs={4}>
                                    <Autocomplete
                                        loading={
                                            options2.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options2}
                                        onChange={(event, newValue) => {
                                            setOptionSelect2(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Project Request"
                                                onChange={(event) => {
                                                    setFilter2(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            )}

                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="pv_number"
                                    label="PV Number"
                                    type="number"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="check_number"
                                    label="Check Number"
                                    type="number"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="account_number"
                                    label="Account Number"
                                    type="number"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    loading={options3.length < 1 ? true : false}
                                    disablePortal
                                    options={options3}
                                    onChange={(event, newValue) => {
                                        setOptionSelect3(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Bank"
                                            onChange={(event) => {
                                                setFilter3(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default EditPVRequest;
