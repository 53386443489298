/**
 * It takes a date in the format of "YYYY-MM-DD" and returns a date in the format of "YYYY-MM-DD".
 * @param {Date} dadate - the date you want to format
 * @returns A function that takes a date and returns a formatted date.
 */
const dateFormatter = (dadate) => {
    let date = new Date(dadate); 
    let month = date.getMonth();
    let day = date.getDate();
    let year = date.getFullYear(); 
    return (
        year + "-" + ("0" + (month + 1)).slice(-2) + "-" + ("0" + day).slice(-2)
    );
};

export default dateFormatter;
