import { Add, FileDownload, SearchRounded } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import Table from "../../../../Components/Table/Table.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { baseURL, get } from "../../../../utils/Caller";
import { GeneralJournalFilesURL } from "../../../../utils/config";

const ViewGeneralJournalFiles = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(20);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState();
    const [Search, setSearch] = useState();

    const renderFileDownload = (url) => {
        return (
            <IconButton
                onClick={() => window.open(baseURL + url?.row?.path, "_blank")}
            >
                <FileDownload />
            </IconButton>
        );
    };

    const columns = [
        {
            field: "files",
            headerName: "Files",
            width: 150,
            renderCell: renderFileDownload,
        },
        {
            field: "name",
            headerName: "Name",
            width: 150,
        },
        {
            field: "type",
            headerName: "Type",
            width: 150,
        },
        {
            field: "generalJournalHeader",
            headerName: "General Journal Header",
            width: 150,
        },
    ];

    useEffect(() => {
        let tableData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(GeneralJournalFilesURL, {
            limit: limit,
            filter: { all: filter },
        }).then((res) => {
            // console.log(res);
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    tableData.push({
                        ...data,
                        generalJournalHeader:
                            data.general_journal_header?.posting_reference,
                        files: renderFileDownload(data?.path),
                    });
                });
            setRows(tableData);
        });
    }, [refreshTable, filter, limit, rows.length]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setloading(true);
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <div style={{ display: "flex", gap: "2ch" }}>
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<Add />}
                        onClick={() => {
                            navigate(
                                "/View/Finance/GeneralJournalFiles/AddGeneralJournalFiles"
                            );
                        }}
                    >
                        Add General Journal Files
                    </Button>
                </div>
            </div>
            <div className="TableContainer">
                <Table
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                />
            </div>
        </div>
    );
};

export default ViewGeneralJournalFiles;
