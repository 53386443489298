import { Box, Skeleton } from "@mui/material";

const DashboardSkeleton = () => {
    return (
        <Box display={"flex"} flexDirection={"column"} width={"100%"}>
            <Skeleton
                variant="rectangular"
                animation="pulse"
                width={"20%"}
                height={window.innerHeight / 10}
                style={{ borderRadius: "5px", marginBottom: "2ch" }}
            />
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "2ch",
                    marginBottom: "2ch",
                }}
            >
                <Skeleton
                    variant="rectangular"
                    animation="pulse"
                    width={"30%"}
                    height={window.innerHeight / 4}
                    style={{ borderRadius: "5px" }}
                />
                <Skeleton
                    variant="rectangular"
                    animation="pulse"
                    width={"30%"}
                    height={window.innerHeight / 4}
                    style={{ borderRadius: "5px" }}
                />
                <Skeleton
                    variant="rectangular"
                    animation="pulse"
                    width={"40%"}
                    height={window.innerHeight / 4}
                    style={{ borderRadius: "5px" }}
                />
            </div>
            <Skeleton
                variant="rectangular"
                animation="pulse"
                width={"100%"}
                height={window.innerHeight / 4}
                style={{ borderRadius: "5px" }}
            />
            <Skeleton
                variant="text"
                animation="pulse"
                width={"100%"}
                style={{ borderRadius: "5px" }}
            />
            <Skeleton
                variant="text"
                animation="pulse"
                width={"100%"}
                style={{ borderRadius: "5px" }}
            />
        </Box>
    );
};
export default DashboardSkeleton;
