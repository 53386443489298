import React from "react";
import { HouseRounded } from "@mui/icons-material";
import { Paper, Box, Button } from "@mui/material";
import { useNavigate } from "react-router";
import TitleHeader from "../../Components/TitleHeader/TitleHeader.component";
import { useSelector } from "react-redux";

const ProjectAddDetail = ({ projectName }) => {
    const navigate = useNavigate();
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    return (
        <Paper>
            <TitleHeader icon={<HouseRounded />} title={selectedRow?.name} />
            <Box className={"BigButtonContainer"}>
                <Button
                    className="BigButton"
                    variant="outlined"
                    onClick={() => {
                        navigate(
                            "/View/ViewProject/ProjectAddDetail/AddProjectTask"
                        );
                    }}
                >
                    Add Project Timeline
                </Button>
                <Button
                    className="BigButton"
                    variant="outlined"
                    onClick={() => {
                        navigate(
                            "/View/ViewProject/ProjectAddDetail/AddInvoiceTracking"
                        );
                    }}
                >
                    Invoice Tracking
                </Button>
                <Button
                    className="BigButton"
                    variant="outlined"
                    onClick={() => {
                        navigate(
                            "/View/ViewProject/ProjectAddDetail/AddRiskTracking"
                        );
                    }}
                >
                    Risk Tracking
                </Button>
                <Button
                    className="BigButton"
                    variant="outlined"
                    onClick={() => {
                        navigate(
                            "/View/ViewProject/ProjectAddDetail/AddRequest"
                        );
                    }}
                >
                    Request
                </Button>
                <Button
                    className="BigButton"
                    variant="outlined"
                    onClick={() => {
                        navigate(
                            "/View/ViewProject/ProjectAddDetail/AddWeatherData"
                        );
                    }}
                >
                    Weather Data
                </Button>
                <Button
                    className="BigButton"
                    variant="outlined"
                    onClick={() => {
                        navigate(
                            "/View/ViewProject/ProjectAddDetail/AddRequiredDocumentation"
                        );
                    }}
                >
                    Required Documentation
                </Button>
                <Button
                    className="BigButton"
                    variant="outlined"
                    onClick={() => {
                        navigate(
                            "/View/ViewProject/ProjectAddDetail/AddRequestPayment"
                        );
                    }}
                >
                    Request Payment
                </Button>
            </Box>
        </Paper>
    );
};

export default ProjectAddDetail;
