import PropTypes from "prop-types";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Collapse, List, ListItemButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";

export const CollapseMenu = (props) => {
    const [open, setOpen] = useState(props.open || false);
    const [selectedIndex, setSelectedIndex] = useState("");
    const location = useLocation();
    const navigate = useNavigate();

    const handleClick = () => {
        setOpen(!open);
    };

    const handleListItemClick = (event, index, path) => {
        setSelectedIndex(index);
        navigate(path);
    };
    return (
        <div data-testid="CollapseMenu">
            <ListItemButton
                data-testid="collapseButton"
                onClick={handleClick}
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "1.5ch 1ch 1.5ch 1ch",
                    // color: "#1B4965",
                    color: "#14213d",
                }}
            >
                <div style={{ display: "flex", gap: "2ch" }}>
                    {props.icon}
                    <Typography
                        style={
                            {
                                // fontWeight: "bolder",
                                // letterSpacing: "1px",
                            }
                        }
                    >
                        {props.title}
                    </Typography>
                </div>

                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            {props.menus
                .filter((value) => value)
                .map((menu, index) => {
                    let type = typeof menu === "string" ? true : false;
                    return (
                        <Collapse
                            key={index}
                            in={open}
                            timeout="auto"
                            unmountOnExit
                        >
                            <List
                                component="div"
                                disablePadding
                                style={{
                                    borderLeft: type && "solid 1px silver",
                                    paddingLeft: "1ch",
                                }}
                            >
                                <ListItemButton
                                    key={index}
                                    data-testid="subMenuButton"
                                    selected={
                                        selectedIndex === { menu } &&
                                        new RegExp(
                                            props.pathname[index] +
                                                "$|" +
                                                props.pathname[index] +
                                                "/"
                                        )
                                    }
                                    onClick={(event) => {
                                        return (
                                            type &&
                                            handleListItemClick(
                                                event,
                                                menu,
                                                props.pathname[index]
                                            )
                                        );
                                    }}
                                >
                                    <Box
                                        style={{
                                            width: "100%",

                                            backgroundColor:
                                                location.pathname.match(
                                                    new RegExp(
                                                        props.pathname[index] +
                                                            "$|" +
                                                            props.pathname[
                                                                index
                                                            ] +
                                                            "/"
                                                    )
                                                ) && type
                                                    ? "#fca311"
                                                    : null,
                                            borderRadius: "10px",
                                            padding: type && "1ch",
                                            paddingLeft: type && "1ch",
                                        }}
                                    >
                                        {type ? (
                                            <Typography
                                                style={{
                                                    // fontWeight: "bolder",
                                                    letterSpacing: "1px",
                                                    color:
                                                        // location.pathname.match(
                                                        //     new RegExp(
                                                        //         props.pathname[index] +
                                                        //             "$|" +
                                                        //             props.pathname[
                                                        //                 index
                                                        //             ] +
                                                        //             "/"
                                                        //     )
                                                        // )
                                                        "#14213d",
                                                    // :"#e5e5e5",
                                                }}
                                            >
                                                {menu}
                                            </Typography>
                                        ) : (
                                            menu
                                        )}
                                    </Box>
                                </ListItemButton>
                            </List>
                        </Collapse>
                    );
                })}
        </div>
    );
};

CollapseMenu.propTypes = {
    icon: PropTypes.element,
    /**Component of an icon 'Material Ui' */
    menus: PropTypes.array,
    /**list of menus inside the collapse menu */
    open: PropTypes.bool,
    /**Boolean to triger open/close of the collapse menu */
    pathname: PropTypes.array,
    /**Path where the menus are navigating */
    title: PropTypes.string.isRequired,
    /**Buttons name */
};
