import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { caller, get } from "../../../../utils/Caller";
import {
  ProjectWorkingDaysURL,
  TaskManagerURL,
} from "../../../../utils/config";
import { useSelector } from "react-redux";
import { FormStepper } from "../../../../Components/Stepper/FormStepper.component";
import AddSubTask from "../SubTask/AddSubTask.page";
// import AddRequiredMaterial from "../RequiredMaterial/AddRequiredMaterial.page";
// import AddRequiredEquipment from "../RequiredEquipment/AddRequiredEquipment.page";
// import AddManpowerRequirement from "../ManpowerRequirement/AddManpowerRequirement.page";
import AddRequestForm from "../RequestForm/AddRequestForm.page";
// import { useNavigate } from "react-router";
const steps = [
  "Main Task",
  "Sub Task",
  "Required Equipment",
  "Required Material",
  "Manpower Requirement",
];

const MainTask = ({ setIsTouched }) => {
  const [loading, setLoading] = useState(false);
  const [protectedEdit, setProtectedEdit] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const selectedRow = useSelector((state) => state.selectedRow.selected);
  const [msg, setMsg] = useState("");
  const [options, setOptions] = useState([]);
  const [optionSelect, setOptionSelect] = useState([]);
  const [filter, setFilter] = useState("");
  const [taskStartDate, setTaskStartDate] = useState("");
  const [taskEndDate, setTaskEndDate] = useState("");
  const [differenceInDays, setDifferenceInDays] = useState("");
  const [workingDays, setWorkingDays] = useState("");

  const INITIAL_VALUES = {
    isProtectedForEdit: false,
    name: "",
    task_start_date: "",
    task_end_date: "",
    duration_in_days: "",
    description: "",
    prerequisite: [],
    working_days: "",
  };

  const FORM_VALIDATION = Yup.object().shape({
    name: Yup.string().min(1).required("Required"),
    task_start_date: Yup.string(),
    task_end_date: Yup.string(),
    duration_in_days: Yup.string(),
    description: Yup.string(),
    working_days: Yup.string(),
  });

  useEffect(() => {
    get(TaskManagerURL, {
      limit: 10,
      filter: {
        all: filter,
        project_id: selectedRow.id,
      },
    }).then((res) => {
      let data = [];
      res &&
        res.data.forEach((name, index) => {
          data.push(`${res.data[index]?.id} - ${res.data[index]?.name}`);
        });
      setOptions(data);
    });
  }, [filter, selectedRow.id]);

  useEffect(() => {
    get(ProjectWorkingDaysURL, {
      date1: taskStartDate,
      date2: taskEndDate,
    }).then((res) => {
      if (res?.data) {
        setWorkingDays(res.data?.workingDays);
        setDifferenceInDays(res.data?.differenceInDays);
      }
    });
  }, [taskStartDate, taskEndDate]);

  const formSubmitHandler = (data, actions) => {
    // console.log(data);
    setLoading(true);
    caller(TaskManagerURL, data).then((res) => {
      setLoading(false);
      // console.log(res.error);
      if (res.error) {
        Object.keys(res.error).forEach((data, i) => {
          setMsg(res.error[data]);
        });
        setSuccess(false);
        setError(true);
      } else if (res.data.success) {
        setSuccess(true);
        setError(false);
        actions.resetForm();
        // return 1;
      }
    });
  };

  return (
    <div className="FormContainer">
      <Formik
        initialValues={{ ...INITIAL_VALUES }}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values, actions) => {
          values.isProtectedForEdit = protectedEdit;
          values.project_id = selectedRow.id;
          values.task_start_date = taskStartDate;
          values.task_end_date = taskEndDate;
          values.duration_in_days = String(differenceInDays);
          values.working_days = String(workingDays);

          optionSelect?.forEach((option, index) => {
            let task_id = parseInt(option.split("-")[0]);
            values.prerequisite.push(task_id);
          });

          formSubmitHandler(values, actions);
        }}
      >
        {({ values }) => {
          Object.keys(INITIAL_VALUES).forEach((field) => {
            if (INITIAL_VALUES[field].toString() !== values[field].toString())
              if (setIsTouched) setIsTouched(true);
          });
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextFieldWrapper
                    name="name"
                    label="Name"
                    variant="outlined"
                  />
                </Grid>
                <Box width={"100%"} />
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <FormLabel>Task Start Date</FormLabel>
                    <TextFieldWrapper
                      name="task_start_date"
                      variant="outlined"
                      type="date"
                      value={taskStartDate}
                      onChange={(e) => {
                        setTaskStartDate(e.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <FormLabel>Task End Date</FormLabel>
                    <TextFieldWrapper
                      name="task_end_date"
                      variant="outlined"
                      type="date"
                      value={taskEndDate}
                      onChange={(e) => {
                        setTaskEndDate(e.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
                <Box width={"100%"} />
                <Grid item xs={4}>
                  <TextFieldWrapper
                    disabled
                    name="duration_in_days"
                    label="Duration In Days"
                    variant="outlined"
                    value={differenceInDays}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextFieldWrapper
                    disabled
                    name="working_days"
                    label="Working Days"
                    variant="outlined"
                    value={workingDays}
                  />
                </Grid>
                <Box width={"100%"} />
                <Grid item xs={6}>
                  <Stack spacing={3}>
                    <Autocomplete
                      multiple
                      getOptionLabel={(option) => option || ""}
                      filterSelectedOptions
                      loading={options.length < 1 ? true : false}
                      options={options}
                      onChange={(event, newValue) => {
                        setOptionSelect(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Prerequisite"
                          onChange={(event) => {
                            setFilter(event.target.value);
                          }}
                        />
                      )}
                    />
                  </Stack>
                </Grid>
                <Box width={"100%"} />
                <Grid item xs={12}>
                  <TextFieldWrapper
                    name="description"
                    label="Description"
                    variant="outlined"
                    multiline
                    rows={3}
                    maxRow={3}
                  />
                </Grid>
                <Box width={"100%"} />
                <Grid item xs={6}>
                  <FormControl
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      checked={protectedEdit}
                      onChange={(event) => {
                        setProtectedEdit(event.target.checked);
                      }}
                    />
                    <FormLabel>Protected For Edit</FormLabel>
                  </FormControl>
                </Grid>
              </Grid>
              <div
                style={{
                  position: "relative",
                  bottom: "-12ch",
                  right: "60px",
                }}
              >
                <LoadingButton
                  loading={loading}
                  type="submit"
                  className="ButtonContained"
                  style={{
                    marginTop: "2ch",
                    float: "right",
                  }}
                >
                  {loading ? null : "Add"}
                </LoadingButton>
              </div>
            </Form>
          );
        }}
      </Formik>
      {error || success ? (
        <SnackBar
          error={error}
          success={success}
          message={error ? msg : `Success^${Math.random()}`}
        />
      ) : null}
    </div>
  );
};
const AddTaskManager = ({ next, name, handleTabChange }) => {
  // const navigate = useNavigate();
  const [isTouched, setIsTouched] = useState(false);

  return (
    <div>
      {/* <TitleHeader title="Main Task" icon={<AddRounded />} /> */}
      <FormStepper
        touched={isTouched}
        setTouched={setIsTouched}
        key={1}
        steps={steps}
        page={[
          <MainTask setIsTouched={setIsTouched} />,
          <AddSubTask setIsTouched={setIsTouched} />,
          <AddRequestForm
            requirement={true}
            requirementType={3}
            setIsTouched={setIsTouched}
          />,
          <AddRequestForm
            requirement={true}
            requirementType={3}
            setIsTouched={setIsTouched}
          />,
          <AddRequestForm
            requirement={true}
            requirementType={2}
            setIsTouched={setIsTouched}
          />,
          // <AddRequiredEquipment />,
          // <AddRequiredMaterial />,
          // <AddManpowerRequirement />,
        ]}
      />
    </div>
  );
};

export default AddTaskManager;
