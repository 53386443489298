import { deleter } from "../../../../utils/Caller";
        import { LeaveTransferURL } from "../../../../utils/config";
        
        export const deleteLeaveTransfer = (id) => {
            const body = {
                id: id,
            };
            // console.log(body);
            return new Promise((resolve, reject) => {
                deleter(LeaveTransferURL, body).then((res) => {
                    if (res.error) {
                        reject("Error Occured!!");
                    } else if (res.data.success) {
                        resolve("Success");
                    }
                });
            });
        };
        