import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
    Scheduler,
    DayView,
    Appointments,
} from "@devexpress/dx-react-scheduler-material-ui";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Button, Typography } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { Box } from "@mui/system";
import styled from "styled-components";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

export const ViewCalendar = ({ schedulerData }) => {
    const [value, setValue] = useState(new Date());
    const [page, setPage] = useState(1);

    const handleChange = (event) => {
        setValue(event);
        setPage(2);
    };

    return (
        <Paper
            style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
                flexDirection: "column",
            }}
            elevation={0}
        >
            {page > 1 && (
                <Box
                    style={{
                        backgroundColor: "white",
                        width: "100%",
                        position: "sticky",
                        top: "0",
                    }}
                >
                    <Button
                        startIcon={<ChevronLeft />}
                        onClick={() => {
                            if (page === 2) {
                                setPage(1);
                            }
                        }}
                    >
                        Back
                    </Button>
                </Box>
            )}

            {page === 1 && (
                <>
                    <Typography>Choose Date</Typography>
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <StaticDatePicker
                            orientation="landscape"
                            openTo="day"
                            value={value}
                            // shouldDisableDate={isWeekend}
                            onChange={handleChange}
                            // renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider> */}
                    <CalendarContainer>
                        <Calendar onChange={handleChange} value={value} />
                    </CalendarContainer>
                </>
            )}
            {page === 2 && (
                <Scheduler data={schedulerData}>
                    <ViewState currentDate={value} />
                    <DayView startDayHour={8} endDayHour={18} />
                    <Appointments />
                </Scheduler>
            )}
        </Paper>
    );
};

const CalendarContainer = styled.div`
    /* ~~~ container styles ~~~ */
    max-width: 600px;
    margin: auto;
    margin-top: 20px;
    background-color: #d4f7d4;
    padding: 10px;
    border-radius: 3px;
    /* ~~~ navigation styles ~~~ */
    .react-calendar__navigation {
        display: flex;
        .react-calendar__navigation__label {
            font-weight: bold;
        }
        .react-calendar__navigation__arrow {
            flex-grow: 0.333;
        }
    }
    /* ~~~ label styles ~~~ */
    .react-calendar__month-view__weekdays {
        text-align: center;
    }
    /* ~~~ button styles ~~~ */
    button {
        margin: 3px;
        background-color: dodgerblue;
        border: 0;
        border-radius: 3px;
        color: white;
        padding: 5px 0;
        &:hover {
            background-color: #556b55;
        }
        &:active {
            background-color: #a5c1a5;
        }
    }
    /* ~~~ day grid styles ~~~ */
    .react-calendar__month-view__days {
        display: grid !important;
        grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%;

        .react-calendar__tile {
            max-width: initial !important;
        }
        .react-calendar__tile--range {
            box-shadow: 0 0 6px 2px darkgray;
        }
    }
    /* ~~~ neighboring month & weekend styles ~~~ */
    .react-calendar__month-view__days__day--neighboringMonth {
        opacity: 0.7;
    }
    .react-calendar__month-view__days__day--weekend {
        color: #dfdfdf;
    }
    /* ~~~ other view styles ~~~ */
    .react-calendar__year-view__months,
    .react-calendar__decade-view__years,
    .react-calendar__century-view__decades {
        display: grid !important;
        grid-template-columns: 20% 20% 20% 20% 20%;
        &.react-calendar__year-view__months {
            grid-template-columns: 33.3% 33.3% 33.3%;
        }

        .react-calendar__tile {
            max-width: initial !important;
        }
    }
`;
