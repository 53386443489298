import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Button,
    // TextField,
    Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useRef } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddRounded, FileUploadRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { fileCaller } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import { AttachmentURL } from "../../../../utils/config";
import { useSelector } from "react-redux";

const AddAttachment = () => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    // const [filter, setFilter] = useState("");
    // const [options, setOptions] = useState([]);
    // const [optionsId, setOptionsID] = useState();
    // const [optionSelect, setOptionSelect] = useState("");
    const [path, setPath] = useState();
    const [fileName, setFileName] = useState("");
    const UploadRef = useRef();
    const [msg, setMsg] = useState("");
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        description: "",
        file: [],
        employee_id: "",
    };

    const FORM_VALIDATION = Yup.object().shape({
        description: Yup.string().min(2),
        // path: Yup.array().min(1, "select at least file"),
    });

    // useEffect(() => {
    //     get(EmployeeURL, {
    //         limit: 10,
    //         filter: {
    //             first_name: filter,
    //         },
    //     }).then((res) => {
    //         let data = [];
    //         res &&
    //             res.data.forEach((name, index) => {
    //                 data.push(
    //                     `${res.data[index]?.id} - ${res.data[index]?.first_name}`
    //                 );
    //             });
    //         res && setOptionsID(res.data);
    //         setOptions(data);
    //     });
    // }, [filter]);

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        fileCaller(AttachmentURL, data).then((res) => {
            setLoading(false);
            if (res?.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res?.data?.success) {
                setSuccess(true);
                setError(false);
                actions.resetForm();
                return 0;
            }
        });
    };

    return (
        <div>
            <TitleHeader title="Add Attachment" icon={<AddRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        values.file = path;
                        values.employee_id = selectedRow.id;
                        // optionsId.forEach((name, index) => {
                        //     if (
                        //         `${name?.id} - ${name.first_name}` ===
                        //         optionSelect
                        //     ) {
                        //         values.employee_id = name.id;
                        //     }
                        //     return 0;
                        // });

                        /* Then create a new FormData obj */
                        let formData = new FormData();

                        /* append input field values to formData */
                        for (let value in values) {
                            if (value === "isProtectedForEdit") {
                                values[value] = false;
                            }
                            formData.append(value, values[value]);
                        }

                        /* Can't console.log(formData), must
                             use formData.entries() - example:  */
                        // for (let property of formData.entries()) {
                        //     console.log(property[0], typeof property[1]);
                        // }

                        formSubmitHandler(formData, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextFieldWrapper
                                    name="description"
                                    label="Description"
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                />
                            </Grid>

                            {/* <Grid item xs={6}>
                                <Autocomplete
                                    loading={options.length < 1 ? true : false}
                                    disablePortal
                                    options={options}
                                    onChange={(event, newValue) => {
                                        setOptionSelect(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Employee"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid> */}
                            <Grid
                                item
                                xs={12}
                                display={"flex"}
                                sx={{ gap: "2ch" }}
                            >
                                <Button
                                    data-testid="uploadButton"
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                        UploadRef.current.click();
                                    }}
                                >
                                    Attachment File
                                    {
                                        <FileUploadRounded
                                            style={{ paddingLeft: "2ch" }}
                                        />
                                    }
                                </Button>
                                <input
                                    name="file"
                                    hidden
                                    ref={UploadRef}
                                    type="file"
                                    accept=".pdf"
                                    onChange={(e) => {
                                        setPath(e.target.files[0]);
                                        setFileName(e.target.files[0].name);
                                    }}
                                />
                                <Typography>{fileName}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default AddAttachment;
