import { Add, SearchRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { SnackBar } from "../../../../Components/SnackBar/SnackBar.component";

import Table from "../../../../Components/Table/Table.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { fileCaller, get } from "../../../../utils/Caller";
import {
    EmployeePayScaleURL,
    UploadPayScaleURL,
} from "../../../../utils/config";

const ViewEmployeePayScale = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(20);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState();
    const [Search, setSearch] = useState();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const uploadRef = useRef();
    const [msg, setMsg] = useState("");

    const columns = [
        {
            field: "scale",
            headerName: "Scale",
            width: 150,
        },
        {
            field: "employeeName",
            headerName: "Employee",
            width: 150,
        },
    ];

    useEffect(() => {
        let tableData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(EmployeePayScaleURL, {
            limit: limit,
            filter: { name: filter },
        }).then((res) => {
            // console.log(res);
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    tableData.push({
                        ...data,
                        employeeName: data.employee?.first_name,
                    });
                });
            setRows(tableData);
        });
    }, [refreshTable, filter, limit, rows.length]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    const fileUploader = (data) => {
        let formData = new FormData();
        formData.append("file", data);

        fileCaller(UploadPayScaleURL, formData).then((res) => {
            if (res?.error) {
                setError(true);
                setSuccess(false);
                setMsg(res?.error?.file);
            } else if (res?.data?.success) {
                setSuccess(true);
                setError(false);
            }
        });
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setloading(true);
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <div style={{ display: "flex", gap: "2ch" }}>
                    <LoadingButton
                        className="ButtonContained"
                        onClick={() => {
                            uploadRef.current.click();
                        }}
                    >
                        Upload File
                    </LoadingButton>
                    <input
                        hidden
                        ref={uploadRef}
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={(e) => {
                            fileUploader(e.target.files[0]);
                        }}
                    />
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<Add />}
                        onClick={() => {
                            navigate(
                                "/View/HCM/EmployeePayScale/AddEmployeePayScale"
                            );
                        }}
                    >
                        Add Employee Pay Scale
                    </Button>
                </div>
            </div>
            <div className="TableContainer">
                <Table
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                />
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};

export default ViewEmployeePayScale;
