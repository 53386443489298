import {
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  Autocomplete,
  TextField,
  Box,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddRounded, Cancel } from "@mui/icons-material";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../../../../Components/SnackBar/SnackBar.component.jsx";
import { fileCaller, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
  EmployeeURL,
  ProjectAllRequestURL,
  BankURL,
  PVRequestURL,
} from "../../../../utils/config";
const REQUEST_TYPE = ["Payment Request", "Manpower Request", "Store"];

const AddPVRequest = () => {
  const [loading, setLoading] = useState(false);
  const [protectedEdit, setProtectedEdit] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [msg, setMsg] = useState("");
  const [filter, setFilter] = useState("");
  const [filter2, setFilter2] = useState("");
  const [filter3, setFilter3] = useState("");
  const [options1, setOptions1] = useState([]);
  const [optionSelect1, setOptionSelect1] = useState("");
  const [options2, setOptions2] = useState([]);
  const [optionSelect2, setOptionSelect2] = useState("");
  const [options3, setOptions3] = useState([]);
  const [optionSelect3, setOptionSelect3] = useState("");
  // const [optionSelect4, setOptionSelect4] = useState("");
  const [forState, setForState] = useState(1);
  const [additionalDocs, setAdditionalDocs] = useState([]);
  const addDocsRef = useRef();
  const [paidToName, setPaidToName] = useState("");

  const FOR = ["Petty Cash Replenishment", "Project Request", "Other"];

  const INITIAL_VALUES = {
    isProtectedForEdit: "",
    to: "",
    from: "",
    amount: "",
    for: forState,
    date: new Date(),
    prepare_payment_to_id: null,
    prepare_payment_to_name: "",
    additional_docs: "",
    project_request_id: null,
    prepared_by_id: null,
    pv_no: "",
    check_no: "",
    account_number: "",
    bank_id: null,
  };

  const FORM_VALIDATION = Yup.object().shape({
    to: Yup.string(),
    from: Yup.string().required("*Required"),
    amount: Yup.string(),
    date: Yup.string().required("Required*"),
  });

  useEffect(() => {
    get(EmployeeURL, {
      limit: 10,
      filter: {
        all: filter,
      },
    }).then((res) => {
      let data = [];

      res &&
        res.data.forEach((name, index) => {
          data.push(`${res.data[index]?.id} - ${res.data[index]?.first_name}`);
        });
      setOptions1(data);
    });

    get(ProjectAllRequestURL, {
      limit: 10,
      filter: {
        finance_approval_status: 2,
        all: filter2,
      },
    }).then((res) => {
      let data = [];
      res?.data?.forEach((name, index) => {
        data.push(
          `${name?.id} - ${name?.prepared_by?.first_name} - ${
            REQUEST_TYPE[name?.request_type - 1]
          }`,
        );
      });
      setOptions2(data);
    });
    get(BankURL, {
      limit: 10,
      filter: {
        all: filter3,
      },
    }).then((res) => {
      let data = [];
      res &&
        res.data.forEach((name, index) => {
          data.push(`${res.data[index]?.id} - ${res.data[index]?.bank_name}`);
        });
      setOptions3(data);
    });
  }, [filter, filter2, filter3]);

  const formSubmitHandler = (data, actions) => {
    setLoading(true);
    fileCaller(PVRequestURL, data).then((res) => {
      setLoading(false);
      if (res.error) {
        Object.keys(res.error).forEach((data, i) => {
          setMsg(res.error[data]);
        });
        setSuccess(false);
        setError(true);
      } else if (res.data.success) {
        setSuccess(true);
        setError(false);
        actions.resetForm();
      }
    });
  };

  console.log(additionalDocs);

  return (
    <div>
      <TitleHeader title="Add PV Request" icon={<AddRounded />} />
      <div className="FormContainer">
        <Formik
          initialValues={{ ...INITIAL_VALUES }}
          validationSchema={FORM_VALIDATION}
          onSubmit={(values, actions) => {
            values.isProtectedForEdit = "";

            values.prepare_payment_to_id = parseInt(
              optionSelect1?.split("-")[0],
            );
            values.project_request_id = parseInt(optionSelect2?.split("-")[0]);
            values.bank_id = parseInt(optionSelect3?.split("-")[0]);
            values.for = forState;
            values.additional_docs = additionalDocs;

            let formData = new FormData();

            for (let data in values) {
              formData.append(data, values[data]);
            }

            for (let a in formData.entries()) {
              console.log(a);
            }

            formSubmitHandler(formData, actions);
          }}
        >
          {({ setValues }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextFieldWrapper
                    select
                    name="for"
                    label="For"
                    variant="outlined"
                    value={forState}
                    options={FOR ?? " "}
                    onChange={(e) => {
                      setForState(e.target.value);
                    }}
                  />
                </Grid>
                {forState === 2 && (
                  <Grid item xs={4}>
                    <Autocomplete
                      loading={options2.length < 1 ? true : false}
                      disablePortal
                      options={options2}
                      onChange={(event, newValue) => {
                        setOptionSelect2(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Project Request"
                          onChange={(event) => {
                            setFilter2(event.target.value);
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={4}>
                  <Autocomplete
                    loading={options1.length < 1 ? true : false}
                    disablePortal
                    options={options1}
                    onChange={(event, newValue) => {
                      setOptionSelect1(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Prepare Payment To"
                        onChange={(event) => {
                          setFilter(event.target.value);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextFieldWrapper
                    name="to"
                    label="To"
                    variant="outlined"
                    disabled={forState === 2 ? true : false}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextFieldWrapper
                    name="from"
                    label="From"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextFieldWrapper
                    name="amount"
                    label="Amount"
                    type="number"
                    variant="outlined"
                    disabled={forState === 2 ? true : false}
                  />
                </Grid>
                <Box width={"100%"} />
                <Grid item xs={6}>
                  <FormControl>
                    <FormLabel>Date</FormLabel>
                    <TextFieldWrapper
                      name="date"
                      type="date"
                      variant="outlined"
                      onChange={(e) => {
                        setValues({ date: e.target.value });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Box width={"100%"} />
                {/* <Grid item xs={4}>
                                <Autocomplete
                                    loading={options1.length < 1 ? true : false}
                                    disablePortal
                                    options={options1}
                                    onChange={(event, newValue) => {
                                        setOptionSelect4(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Prepared By"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid> */}
                {!optionSelect1 && (
                  <>
                    <Grid item xs={4}>
                      <TextFieldWrapper
                        name="prepare_payment_to_name"
                        label="Prepare Payment To Name"
                        variant="outlined"
                        value={paidToName}
                        onChange={(e) => {
                          setPaidToName(e.target.value);
                        }}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={4}>
                  <TextFieldWrapper
                    name="pv_number"
                    label="PV Number"
                    type="number"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextFieldWrapper
                    name="check_number"
                    label="Check Number"
                    type="number"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextFieldWrapper
                    name="account_number"
                    label="Account Number"
                    type="number"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    loading={options3.length < 1 ? true : false}
                    disablePortal
                    options={options3}
                    onChange={(event, newValue) => {
                      setOptionSelect3(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Bank"
                        onChange={(event) => {
                          setFilter3(event.target.value);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      border: "solid silver 2px",
                    }}
                  >
                    <Typography fontWeight="bolder">
                      Add Additional Docs
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "40%",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Button
                          onClick={() => {
                            addDocsRef.current.click();
                          }}
                        >
                          Choose Files
                        </Button>
                        <input
                          type="file"
                          multiple
                          ref={addDocsRef}
                          hidden
                          onChange={(e) => {
                            setAdditionalDocs(e.target.files);
                          }}
                        />
                        {Object.keys(additionalDocs).map((doc) => {
                          return (
                            <Typography>{additionalDocs[doc].name}</Typography>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        gap: "2ch",
                      }}
                    >
                      {Object.values(additionalDocs)?.map((img, index) => {
                        if (img) {
                          return (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <img
                                style={{
                                  width: "100%",
                                  height: "30vh",
                                  objectFit: "cover",
                                }}
                                src={URL.createObjectURL(img)}
                                alt={`doc_${index}`}
                                onClick={() => {
                                  window.open(
                                    URL.createObjectURL(img),
                                    "_blank",
                                  );
                                }}
                              />
                              <IconButton
                                onClick={() => {
                                  let docs = additionalDocs;
                                  docs.splice(index, 1);
                                  setAdditionalDocs(docs);
                                }}
                              >
                                <Cancel />
                              </IconButton>
                            </div>
                          );
                        } else return "";
                      })}
                    </div>
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <FormControl
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      checked={protectedEdit}
                      onChange={(event) => {
                        setProtectedEdit(event.target.checked);
                      }}
                    />
                    <FormLabel>Protected For Edit</FormLabel>
                  </FormControl>
                </Grid>
              </Grid>
              <div>
                <LoadingButton
                  data-testid="AddButton"
                  loading={loading}
                  type="submit"
                  className="ButtonContained"
                  style={{
                    marginTop: "2ch",
                    float: "right",
                  }}
                >
                  {loading ? null : "Add"}
                </LoadingButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {error || success ? (
        <SnackBar
          error={error}
          success={success}
          message={error ? msg : `Success^${Math.random()}`}
        />
      ) : null}
    </div>
  );
};
export default AddPVRequest;
