import { Grid, Modal, Paper, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { Box } from "@mui/material";
import jwt_encode from "jwt-encode";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Login } from "@mui/icons-material";

import TextFieldWrapper from "../../../Components/TextFieldWrapper.component";
import "../../../Assets/Sass/Login.style.scss";
import { LogInURL } from "../../../utils/config";
import { baseURL, logInPost } from "../../../utils/Caller";
import { SnackBar } from "../../../Components/SnackBar/SnackBar.component";
import { GetUser, KEY } from "../../../utils/config";
import ForgotPassword from "../Password/ForgotPassword.page";
import { userLogIn } from "../../../Redux/Slice/userSlice";
import ELHADAR from "../../../Assets/img/logo.svg";

const LogInPage = () => {
    const [loading, setloading] = useState(false);
    const [error, setError] = useState(false);
    const [msg, setMsg] = useState("");
    const [openFPModal, setOpenFPModal] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const emailRegex =
    //     /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    const phoneRegex = /^\d*$/gm;

    //------------------------------------------FORM VALIDATIONS-----------------------------------------
    const INITIAL_VALUES = {
        userName: "",
        password: "",
    };

    const FORM_VALIDATION = Yup.object().shape({
        password: Yup.string().required("Enter Password"),
        userName: Yup.string().required("*Required"),
    });
    //------------------------------------------------------------------------------------------------

    const submitLogIn = async (user) => {
        setloading(true);
        try {
            await logInPost(LogInURL, {
                password: user.password,
                email: user.email,
                phone_number: user.phone,
            }).then(async (res) => {
                if (res.error) {
                    setloading(false);
                    Object.keys(res.error).forEach((data, i) => {
                        setMsg(res.error[data]);
                    });
                    setError(true);
                } else {
                    if (res.data.first_login) {
                        window.open(
                            window.location.href + "changePassword",
                            "_self"
                        );
                    }
                    localStorage.setItem("accessToken", res?.data?.accessToken);
                    localStorage.setItem(
                        "refreshToken",
                        res?.data?.refreshToken
                    );
                    axios.defaults.headers.common = {
                        Authorization: `bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    };

                    const body = {
                        limit: 1,
                        filter: {
                            id: res?.data?.id,
                        },
                    };
                    const theUser = await axios.get(baseURL + GetUser, {
                        params: { body: JSON.stringify(body) },
                    });
                    if (theUser?.data) {
                        // setloading(false);
                        localStorage.setItem(
                            "User",
                            jwt_encode(JSON.stringify(theUser.data[0]), KEY)
                        );
                        dispatch(userLogIn(theUser.data[0]));
                        if (!res.data.first_login) {
                            window.open(
                                window.location.href + "View/Dashboard",
                                "_self"
                            );
                        }
                    } else {
                        setloading(false);
                        navigate("/");
                        setMsg("Wrong Credentials!!");
                        setError(true);
                    }
                }
            });
        } catch (error) {
            console.log("CHECK NETWORK");
            setloading(false);
            setError(true);
            setMsg("Network Error!!!");
        }
    };

    return (
        <div className="LogInPage">
            <Paper className="logInBox" variant="outlined">
                <Box className={"SideDisplay"}>
                    <img
                        className={"logo"}
                        alt="El Hadar ERP"
                        src={ELHADAR}
                        width={"20%"}
                        style={{ alignSelf: "center", paddingBottom: "3ch" }}
                    />
                    <Typography className={"Title"}>EL-Hadar ERP</Typography>
                </Box>
                <Box style={{ padding: "5ch", width: "100%" }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "5ch",
                            paddingTop: "2ch",
                        }}
                    >
                        <div className="LogoTop">
                            <img
                                className={"logo"}
                                alt="El Hadar ERP"
                                src={ELHADAR}
                                width={"50px"}
                                style={{
                                    alignSelf: "center",
                                    paddingBottom: "3ch",
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "2ch",
                            }}
                        >
                            <Login />
                            <Typography variant="h6" fontWeight={600}>
                                Log In
                            </Typography>
                        </div>
                    </div>
                    <Formik
                        initialValues={{ ...INITIAL_VALUES }}
                        validationSchema={FORM_VALIDATION}
                        onSubmit={(values, actions) => {
                            const user = {
                                email: "",
                                phone: "",
                                password: "",
                            };
                            if (phoneRegex.test(values.userName)) {
                                user.phone = values.userName;
                                user.password = values.password;
                            } else {
                                user.email = values.userName;
                                user.password = values.password;
                            }
                            submitLogIn(user);
                        }}
                    >
                        <Form className="LogInForm" data-testid="logInForm">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextFieldWrapper
                                        name="userName"
                                        label="User Name"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextFieldWrapper
                                        name="password"
                                        label="Password"
                                        variant="outlined"
                                        type="password"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>

                            <LoadingButton
                                data-testid="login"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    justifySelf: "center",
                                }}
                            >
                                {loading ? null : "Log In"}
                            </LoadingButton>
                            <Typography
                                variant="caption"
                                onClick={() => {
                                    setOpenFPModal(true);
                                }}
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                Forgot Password?
                            </Typography>
                        </Form>
                    </Formik>
                </Box>
            </Paper>
            <Modal
                open={openFPModal}
                className="Modal"
                onClose={() => {
                    setOpenFPModal(false);
                }}
            >
                <Box>
                    <ForgotPassword />
                </Box>
            </Modal>
            {error && <SnackBar error={error} message={msg} />}
        </div>
    );
};

export default LogInPage;
