import { Add, FileDownload, SearchRounded } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import OperationsTable from "../../../../Components/Table/OperationsTable.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { baseURL, get } from "../../../../utils/Caller";
import { DisciplineURL } from "../../../../utils/config";

const ViewDiscipline = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(20);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState();
    const [Search, setSearch] = useState();

    const selectedRow = useSelector((state) => state.selectedRow.selected);

    const renderFileDownload = (url) => {
        return (
            <IconButton
                onClick={() =>
                    window.open(baseURL + url?.row?.attachment?.path, "_blank")
                }
            >
                <FileDownload />
            </IconButton>
        );
    };

    const columns = [
        {
            field: "case_description",
            headerName: "Case Description",
            width: 150,
        },
        {
            field: "attachmentName",
            headerName: "Attachment",
            width: 150,
            renderCell: renderFileDownload,
        },
        {
            field: "caseType",
            headerName: "Case Type",
            width: 150,
        },
        {
            field: "employeeName",
            headerName: "Employee",
            width: 150,
        },
    ];

    useEffect(() => {
        let tableData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(DisciplineURL, {
            limit: limit,
            filter: {
                all: filter,
                employee_id: selectedRow?.id,
            },
        }).then((res) => {
            console.log(res);
            res && res.data && setloading(false);
            res &&
                res.data?.forEach((data, index) => {
                    tableData.push({
                        ...data,
                        attachmentName: renderFileDownload(data),
                        caseType: data.case_type?.case_description,
                        employeeName: data.employee?.first_name,
                    });
                });
            setRows(tableData);
        });
    }, [refreshTable, filter, limit, rows.length, selectedRow?.id]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setloading(true);
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <div style={{ display: "flex", gap: "2ch" }}>
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<Add />}
                        onClick={() => {
                            navigate("/View/HCM/Discipline/AddDiscipline");
                        }}
                    >
                        Add Discipline
                    </Button>
                </div>
            </div>
            <div className="TableContainer">
                <OperationsTable
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                />
            </div>
        </div>
    );
};

export default ViewDiscipline;
