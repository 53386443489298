import {
  Add,
  SearchRounded,
  PrintRounded,
  Cancel,
  EditRounded,
  ChevronLeft,
  Delete,
} from "@mui/icons-material";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Button,
  InputAdornment,
  TextField,
  IconButton,
  List,
  ListItem,
  Typography,
  LinearProgress,
  Modal,
  Paper,
  // TableCell,
  // Table,
  // TableRow,
  // TableHead,
  // TableBody,
  TablePagination,
  Card,
  Divider,
  Tab,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { SnackBar } from "../../../../Components/SnackBar/SnackBar.component";

// import OperationsTable from "../../../../Components/Table/OperationsTable.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { setSelected } from "../../../../Redux/Slice/selectedRow";
import { baseURL, caller, get, deleter } from "../../../../utils/Caller";
import {
  PVRequestDocsURL,
  PVRequestURL,
  RemoveAttachmentURL,
} from "../../../../utils/config";
import { deletePVRequest } from "./deletePVRequest";
import EditPVRequest from "./EditPVRequest.page";

const FOR = ["Petty Cash Replenishment", "Project Request", "Other"];

const ViewPVRequest = () => {
  const [rows, setRows] = useState([]);
  const [rowsDetail, setRowsDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [pageNo, setPageNo] = useState(0);
  const refreshTable = useSelector((state) => state.refreshTable.init);
  const selectedRow = useSelector((state) => state.selectedRow.selected);
  const [filter, setFilter] = useState("");
  const dispatch = useDispatch();
  const [Search, setSearch] = useState("");
  // const [fieldName, setFieldName] = useState([]);
  const user = useSelector((state) => state.userState.user);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const addDocsRef = useRef();
  const idRef = useRef();
  const [additionalDocs, setAdditionalDocs] = useState([]);
  const [idCard, setIdCard] = useState();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [msg, setMsg] = useState("");
  const [value, setValue] = useState("1");
  const [limit, setLimit] = useState(20);
  const [editPage, setEditPage] = useState(1);

  const columns = [
    {
      field: "to",
      headerName: "To",
    },
    {
      field: "from",
      headerName: "From",
    },
    {
      field: "amount",
      headerName: "Amount",
    },
    {
      field: "For",
      headerName: "For",
    },
    {
      field: "date",
      headerName: "Date",
    },
    {
      field: "preparePaymentTo",
      headerName: "Prepare Payment To ID",
    },
    {
      field: "prepare_payment_to_name",
      headerName: "Prepare Payment To Name",
    },
    {
      field: "projectRequest",
      headerName: "Project Request ID",
    },
    {
      field: "pv_no",
      headerName: "PV No",
    },
    {
      field: "check_no",
      headerName: "Check No",
    },
    {
      field: "account_number",
      headerName: "Account Number",
    },
    {
      field: "bankName",
      headerName: "Bank",
    },
  ];

  const pettyCashColumns = [
    {
      field: "amount_paid",
      headerName: "Amount Paid",
      width: 150,
    },
    {
      field: "approved_by",
      headerName: "Approved By",
      width: 150,
    },
    {
      field: "checked_by",
      headerName: "Checked By",
      width: 150,
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
    },
    {
      field: "money_received",
      headerName: "Money Received",
      width: 150,
    },
    {
      field: "paid_to",
      headerName: "Paid To",
      width: 150,
    },
    {
      field: "paid_to_id_file",
      headerName: "Paid to ID",
      width: 150,
    },
    {
      field: "paid_to_name",
      headerName: "Paid To Name",
      width: 150,
    },
    {
      field: "pcpv_number",
      headerName: "PCPV Number",
      width: 150,
    },
    {
      field: "preparedBy",
      headerName: "Prepared By",
      width: 150,
    },
    {
      field: "reason",
      headerName: "Reason",
      width: 150,
    },
    {
      field: "remark",
      headerName: "Remark",
      width: 150,
    },
  ];

  useEffect(() => {
    let tableData = [];
    if (rows.length === 0) {
      setLoading(true);
    }
    get(PVRequestURL, {
      limit: 100,
      filter: { all: filter },
      sort: { creationDate: 0 },
    }).then((res) => {
      // console.log(res);
      res?.data && setLoading(false);
      res &&
        res.data.forEach((data, index) => {
          tableData.push({
            ...data,
            preparePaymentTo: data.prepare_payment_to?.first_name,
            projectRequest: data.project_request?.total_amount,
            date: new Date(data.date).toDateString(),
            bankName: data.bank?.name,
            For: FOR[data.for - 1],
            additional_docs: JSON.parse(data.additional_docs),
          });
          data.petty_cash.forEach((pettyCash, i) => {
            let pettyCashData = {
              ...data.petty_cash[i],
              approved_by: data.petty_cash[i]?.approved_by?.first_name,
              checked_by: data.petty_cash[i]?.checked_by?.first_name,
              date: new Date(data.petty_cash[i]?.date).toDateString(),
              paid_to: data.petty_cash[i]?.paid_to?.first_name,
              prepared_by: data.petty_cash[i]?.prepared_by?.first_name,
            };
            let petty_cash = [];
            petty_cash.push(pettyCash);
            petty_cash[i] = pettyCashData;
            tableData[index] = {
              ...tableData[index],
              petty_cash,
            };
          });
        });
      console.log(tableData);
      setRows(tableData);
    });
  }, [refreshTable, filter, rows.length]);

  const closeModal = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const addLimit = () => {
    setLimit(limit + 8);
  };

  const deleteHandler = (id, index) => {
    deleter(RemoveAttachmentURL, { id: id, removedIndex: index }).then(
      (res) => {
        if (res?.data.success) {
          console.log("Removed Attachment");
          dispatch(setRefresh(!refreshTable));
          delete rowsDetail.additional_docs[index];
          console.log(rowsDetail.additional_docs);
        }
      },
    );
  };
  const apiHandler = (formData) => {
    caller(PVRequestDocsURL, formData).then((res) => {
      setLoading(false);
      if (res.error) {
        Object.keys(res.error).forEach((data, i) => {
          setMsg(res.error[data]);
        });
        setSuccess(false);
        setError(true);
      } else if (res.data.success) {
        dispatch(setRefresh(!refreshTable));
        setRowsDetail((prevData) => ({
          ...prevData,
          additional_docs: JSON.parse(res.data?.data?.additional_docs),
        }));
        setSuccess(true);
        setError(false);
      }
    });
  };

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="ViewProject">
      <div className="SearchField">
        <TextField
          name="searchField"
          label="Search"
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  onClick={(e) => {
                    setLoading(true);
                    setFilter(Search);
                    dispatch(setRefresh(!refreshTable));
                  }}
                >
                  <SearchRounded />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <div style={{ display: "flex", gap: "2ch" }}>
          {user.actions?.indexOf("HEAD") === -1 && (
            <Button
              variant="contained"
              color="success"
              endIcon={<Add />}
              onClick={() => {
                navigate(`/View/Finance/PVRequest/AddPVRequest`);
              }}
            >
              Add PV Request
            </Button>
          )}
        </div>
      </div>
      <div className="TableContainer">
        {loading && <LinearProgress />}
        {!loading && rows.length === 0 && (
          <div
            style={{
              width: "100%",
              height: "40vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              style={{
                fontSize: "3rem",
                fontWeight: "bolder",
                color: "#e5e5e5",
              }}
            >
              No Requests Found
            </Typography>
          </div>
        )}
        <List>
          {rows.length > 0 &&
            rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((data, index) => (
                <ListItem
                  key={index}
                  style={{
                    display: "flex",
                    borderRadius: "5px",
                    boxShadow: "0 0 15px #e5e5e5",
                    justifyContent: "space-between",
                    padding: "2ch",
                    width: "100%",
                    cursor: "pointer",
                    marginBottom: "2ch",
                  }}
                  onClick={() => {
                    setRowsDetail(data);
                    setOpen(true);
                  }}
                >
                  <Box>
                    <Typography fontWeight={"bold"}>PV Request</Typography>
                    <Typography>{data.For}</Typography>
                    <Typography
                      style={{
                        fontWeight: "bolder",
                      }}
                    >
                      {data.preparePaymentTo}
                    </Typography>
                  </Box>
                  <IconButton
                    onClick={() => {
                      deletePVRequest(data._id);
                    }}
                  >
                    <Delete color="error" />
                  </IconButton>
                </ListItem>
              ))}
        </List>
        <TablePagination
          rowsPerPageOptions={[8, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Modal className="Modal" open={open} onClose={closeModal}>
          <Box
            className="ModalContainer"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              maxWidth: "90%",
              width: "70%",
              objectFit: "fill",
            }}
          >
            <Paper
              style={{
                minWidth: "100%",
                minHeight: "50vh",
                maxHeight: "80vh",
                overflowY: "scroll",
                // padding: "2ch",
              }}
            >
              <TabContext value={value}>
                <Box
                  sx={{
                    position: "sticky",
                    top: 0,
                    borderBottom: 1,
                    borderColor: "divider",
                    backdropFilter: "blur(5px)",
                  }}
                >
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="PV Request Detail" value="1" />
                    {rowsDetail.for === 1 && (
                      <Tab label="Petty Cash" value="2" />
                    )}
                  </TabList>
                </Box>
                <TabPanel value="1">
                  {editPage === 1 && (
                    <div>
                      <Box
                        width={"100%"}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Button
                          endIcon={<EditRounded />}
                          color="success"
                          onClick={() => {
                            dispatch(setSelected(rowsDetail));
                            console.log(selectedRow);
                            setEditPage(2);
                          }}
                        >
                          Edit
                        </Button>
                      </Box>
                      <Card>
                        {columns.map((column, index) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                alignItems: "center",
                                marginTop: "1ch",
                              }}
                            >
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  width: "80%",
                                }}
                              >
                                <Typography fontWeight={"bolder"}>
                                  {column.headerName}
                                </Typography>
                                <Typography>
                                  {rowsDetail[column.field]}
                                </Typography>
                              </div>
                              <Divider
                                style={{
                                  width: "90%",
                                }}
                              />
                            </div>
                          );
                        })}
                      </Card>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          border: "solid silver 2px",
                        }}
                      >
                        <Typography fontWeight="bolder">
                          Add Additional Docs
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "40%",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <Button
                              onClick={() => {
                                addDocsRef.current.click();
                              }}
                            >
                              Choose Files
                            </Button>
                            <input
                              type={"file"}
                              multiple
                              ref={addDocsRef}
                              hidden
                              onChange={(e) => {
                                setAdditionalDocs(e.target.files);
                              }}
                            />
                            {Object.keys(additionalDocs).map((doc) => {
                              return (
                                <Typography>
                                  {additionalDocs[doc].name}
                                </Typography>
                              );
                            })}
                          </div>

                          <LoadingButton
                            variant="contained"
                            style={{
                              height: "4ch",
                            }}
                            onClick={() => {
                              const formData = new FormData();
                              Object.keys(additionalDocs).forEach((doc) => {
                                formData.append("file[]", additionalDocs[doc]);
                              });
                              formData.append("id", rowsDetail.id);

                              // for (let property of formData.entries()) {
                              //     console.log(
                              //         property[0],
                              //         property[1]
                              //     );
                              // }

                              apiHandler(formData);
                            }}
                          >
                            Add
                          </LoadingButton>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            gap: "2ch",
                          }}
                        >
                          {rowsDetail?.additional_docs?.map((img, index) => {
                            return (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  style={{
                                    width: "100%",
                                    height: "30vh",
                                    objectFit: "cover",
                                  }}
                                  src={baseURL + img}
                                  alt={`doc_${index}`}
                                  onClick={() => {
                                    window.open(baseURL + img, "_blank");
                                  }}
                                />
                                <IconButton
                                  onClick={() => {
                                    deleteHandler(rowsDetail.id, index);
                                  }}
                                >
                                  <Cancel />
                                </IconButton>
                              </div>
                            );
                          })}
                        </div>
                        {rowsDetail.prepare_payment_to_name !== null && (
                          <div>
                            <Typography fontWeight="bolder">ID</Typography>
                            <div>
                              <Button
                                onClick={() => {
                                  idRef.current.click();
                                }}
                              >
                                Choose File
                              </Button>
                              <input
                                type={"file"}
                                multiple
                                ref={idRef}
                                hidden
                                onChange={(e) => {
                                  setIdCard(e.target.files[0]);
                                }}
                              />
                              <Typography>{idCard?.name}</Typography>
                            </div>

                            <LoadingButton
                              onClick={() => {
                                const formData = new FormData();
                                formData.append("file[]", idCard);
                                formData.append("id", rowsDetail.id);
                                apiHandler(formData);
                              }}
                            >
                              Add Receiver ID Image
                            </LoadingButton>
                          </div>
                        )}
                      </Box>
                      <Button
                        style={{
                          float: "right",
                          marginTop: "2ch",
                        }}
                        endIcon={<PrintRounded />}
                        onClick={() => {
                          let fieldName = [];
                          columns.forEach((head, index) =>
                            fieldName.push(head.field),
                          );
                          navigate(`/PVRequestPrint`, {
                            state: {
                              column: columns,
                              rowsDetail,
                              fieldName,
                            },
                          });
                        }}
                      >
                        Print
                      </Button>
                    </div>
                  )}
                  {editPage === 2 && (
                    <div>
                      <IconButton
                        onClick={() => {
                          setEditPage(1);
                        }}
                      >
                        <ChevronLeft />
                      </IconButton>
                      <EditPVRequest />
                    </div>
                  )}
                </TabPanel>
                <TabPanel value="2">
                  <DataGrid
                    loading={loading}
                    rows={rowsDetail?.petty_cash}
                    columns={pettyCashColumns}
                    onPageChange={(page) => {
                      if (pageNo < page) {
                        setPageNo(pageNo + 1);
                        addLimit();
                      }
                    }}
                    autoHeight={true}
                    pageSize={8}
                    rowsPerPageOptions={[8]}
                  />
                </TabPanel>
              </TabContext>
            </Paper>
            {error || success ? (
              <SnackBar
                error={error}
                success={success}
                message={error ? msg : `Success^${Math.random()}`}
              />
            ) : null}
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default ViewPVRequest;
