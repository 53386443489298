import { Add, SearchRounded } from "@mui/icons-material";
import { Button, InputAdornment, TextField, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import OperationsTable from "../../../../Components/Table/OperationsTable.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { get } from "../../../../utils/Caller";
import { RequestPaymentURL } from "../../../../utils/config";

const REQUEST_TYPE = ["Material", "Expense"];
const APPROVAL_STATUS = ["Pending", "Approve", "Reject"];

const ViewRequestPayment = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(16);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const [filter, setFilter] = useState("");
    const dispatch = useDispatch();
    const [Search, setSearch] = useState("");

    const columns = [
        {
            field: "date",
            headerName: "Date",
            width: 150,
        },
        {
            field: "amount",
            headerName: "Amount",
            width: 150,
        },
        {
            field: "requestType",
            headerName: "Request Type",
            width: 150,
        },
        {
            field: "approvalStatus",
            headerName: "Approval Status",
            width: 150,
        },
        {
            field: "description",
            headerName: "Description",
            width: 150,
        },
        {
            field: "check_number",
            headerName: "Check Number",
            width: 150,
        },
        {
            field: "remaining_balance",
            headerName: "Remaining Balance",
            width: 150,
        },
        {
            field: "preparedBy",
            headerName: "Prepared By",
            width: 150,
        },
        {
            field: "checkedBy",
            headerName: "Checked By",
            width: 150,
        },
        {
            field: "approvedBy",
            headerName: "Approved By",
            width: 150,
        },
        {
            field: "projectName",
            headerName: "Project",
            width: 150,
        },
        {
            field: "cashPaymentVoucher",
            headerName: "Cash Payment Voucher",
            width: 150,
        },
        {
            field: "fromName",
            headerName: "From",
            width: 150,
        },
    ];

    useEffect(() => {
        let tableData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(RequestPaymentURL, {
            limit: limit,
            filter: { project_id: selectedRow.id, all: filter },
        }).then((res) => {
            // console.log(res);
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    tableData.push({
                        ...data,
                        preparedBy: data.prepared_by?.first_name,
                        checkedBy: data.checked_by?.first_name,
                        approvedBy: data.approved_by?.first_name,
                        projectName: data.project?.name,
                        cashPaymentVoucher:
                            data.cash_payment_voucher?.description,
                        fromName: data.from?.name,
                        requestType: REQUEST_TYPE[data.request_type - 1],
                        approvalStatus:
                            APPROVAL_STATUS[data.approval_status - 1],
                    });
                });
            setRows(tableData);
        });
    }, [refreshTable, filter, limit, rows.length, selectedRow.id]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setloading(true);
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <div style={{ display: "flex", gap: "2ch" }}>
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<Add />}
                        onClick={() => {
                            navigate(
                                `/View/ViewProject/${selectedRow.id}/RequestPayment/AddRequestPayment`
                            );
                        }}
                    >
                        Add Request Payment
                    </Button>
                </div>
            </div>
            <div className="TableContainer">
                <OperationsTable
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                />
            </div>
        </div>
    );
};

export default ViewRequestPayment;
