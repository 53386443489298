import React, { useEffect, useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    // Autocomplete,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    Paper,
    // TextField,
    Typography,
} from "@mui/material";
import {
    Approval,
    ApprovalRounded,
    Cancel,
    ExpandMore,
} from "@mui/icons-material";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import TextFieldWrapper from "../../Components/TextFieldWrapper.component";
import { Box } from "@mui/system";
import { get, patcher } from "../../utils/Caller";
import {
    AttendanceApprovalURL,
    // EmployeeURL,
    LeaveAssignmentApprovalURL,
    LeavePlanApprovalURL,
    LeaveTransferApprovalURL,
    OvertimeApprovalURL,
} from "../../utils/config";
import { LoadingButton } from "@mui/lab";
import { SnackBar } from "../../Components/SnackBar/SnackBar.component";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import HCMApprovalColumns from "./HCMApprovalColumns.json";
import { useLocation } from "react-router";

const AATYPE = ["Attendance", "Absence"];

const HCMApproval = () => {
    const { state } = useLocation();
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [limit, setLimit] = useState(8);
    // const [filter, setFilter] = useState();
    // const [options, setOptions] = useState([]);
    // const [optionSelect, setOptionSelect] = useState();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setMsg] = useState("");
    const [type, setType] = useState(state?.search ? state.searchType : 1);
    const [pageNo, setPageNo] = useState(0);
    const user = useSelector((state) => state.userState.user);
    const [open, setOpen] = useState(true);
    const [ids, setIds] = useState([]);
    const [urlState, setUrlState] = useState("");
    const [filterData, setFilterData] = useState(null);
    let url = "";

    const TYPE = [
        "Leave Transfer",
        // "Leave Assignment",
        // "Leave Plan",
        // "Attendance",
        // "Overtime",
    ];

    const INITIAL_VALUES = {
        startDate: "",
        endDate: "",
        employee_id: null,
    };

    const FORM_VALIDATION = Yup.object().shape({
        // startDate: Yup.string().required("*Required"),
        // endDate: Yup.string().required("*Required"),
    });

    useEffect(() => {
        if (state?.search) {
            setType(state.searchType);
            formSubmitHandler();
        } else formSubmitHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    const formSubmitHandler = (data) => {
        setLoading(true);
        let tableData = [];
        if (type === 1) {
            url = LeaveTransferApprovalURL;
            setColumns(HCMApprovalColumns.LeaveTransfer);
        } else if (type === 2) {
            url = LeaveAssignmentApprovalURL;
            setColumns(HCMApprovalColumns.LeaveAssignment);
        } else if (type === 3) {
            url = LeavePlanApprovalURL;
            setColumns(HCMApprovalColumns.LeavePlan);
        } else if (type === 4) {
            url = AttendanceApprovalURL;
            setColumns(HCMApprovalColumns.Attendance);
        } else if (type === 5) {
            url = OvertimeApprovalURL;
            setColumns(HCMApprovalColumns.Overtime);
        }

        setUrlState(url);
        if (rows.length === 0) {
            setLoading(true);
        }

        get(url, {
            employee_id: data?.employee_id,
            startDate: data?.startDate,
            endDate: data?.endDate,
            delegated_username: user?.username,
        }).then((res) => {
            // console.log(res);
            setLoading(false);
            res?.data?.forEach((data) => {
                tableData.push({
                    ...data,
                    idNumber: data.employee?.id_number,
                    FullName: `${data.employee?.first_name} ${data.employee?.middle_name}`,
                    is_absence_includes_day_off:
                        data.attendance_abscence_type
                            ?.is_absence_includes_day_off,
                    aa_description:
                        data.attendance_abscence_type?.aa_description,
                    aa_type: AATYPE[data.attendance_abscence_type?.aa_type - 1],
                    rate: data.overtime_rate?.rate,
                });
            });
            setRows(tableData);
        });
    };

    const sendApproval = () => {
        let data = [];
        ids.forEach((id) => {
            let approved = {
                id: id,
                approve: true,
            };
            data.push(approved);
        });
        apiHandler(data);
    };

    const sendRejection = () => {
        let data = [];
        ids.forEach((id) => {
            let approved = {
                id: id,
                approve: false,
            };
            data.push(approved);
        });
        apiHandler(data);
    };

    const apiHandler = (data) => {
        patcher(urlState, { approvalList: data }).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data?.success) {
                setSuccess(true);
                setError(false);
                formSubmitHandler(filterData);
            }
        });
    };

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "2ch" }}>
            <Accordion
                expanded={open}
                onChange={() => {
                    setOpen(!open);
                }}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Approval />
                    <Divider variant="middle" />
                    <Typography fontWeight={600}>Select Approval</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Formik
                        initialValues={{ ...INITIAL_VALUES }}
                        validationSchema={FORM_VALIDATION}
                        onSubmit={(values, actions) => {
                            // values.employee_id = parseInt(
                            //     optionSelect.split("-")[0]
                            // );
                            setFilterData(values);
                            formSubmitHandler(values, actions);
                        }}
                    >
                        <Form>
                            <Grid
                                container
                                spacing={2}
                                style={{ alignItems: "end" }}
                            >
                                <Grid item xs={4}>
                                    <TextFieldWrapper
                                        size={"small"}
                                        select
                                        name="type"
                                        label="Approval Option"
                                        variant="outlined"
                                        options={TYPE}
                                        value={type}
                                        onChange={(e) => {
                                            setType(e.target.value);
                                        }}
                                    />
                                </Grid>
                                {/* <Grid item>
                                    <FormControl>
                                        <FormLabel>Start Date</FormLabel>
                                        <TextFieldWrapper
                                            size={"small"}
                                            name="startDate"
                                            variant="outlined"
                                            type="Date"
                                            fullWidth
                                        />
                                    </FormControl>
                                </Grid> */}
                                <Grid item>
                                    <FormControl>
                                        <FormLabel>End Date</FormLabel>
                                        <TextFieldWrapper
                                            size={"small"}
                                            name="endDate"
                                            variant="outlined"
                                            type="Date"
                                            fullWidth
                                        />
                                    </FormControl>
                                </Grid>
                                {/* <Grid item xs={6}>
                                    <Autocomplete
                                        size="small"
                                        loading={
                                            options.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options}
                                        onChange={(event, newValue) => {
                                            setOptionSelect(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                size={"small"}
                                                {...params}
                                                label="Employee"
                                                onChange={(event) => {
                                                    setFilter(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid> */}
                            </Grid>
                            <div>
                                <LoadingButton
                                    data-testid="AddButton"
                                    loading={loading}
                                    type="submit"
                                    className="ButtonContained"
                                    style={{
                                        marginTop: "2ch",
                                        float: "right",
                                    }}
                                >
                                    {loading ? null : "Search"}
                                </LoadingButton>
                            </div>
                        </Form>
                    </Formik>
                </AccordionDetails>
            </Accordion>
            <Box>
                {columns.length > 0 && (
                    <Paper
                        style={{
                            boxShadow: "0 0 5px silver",
                            padding: "1ch",
                        }}
                        elevation={0}
                    >
                        <Box
                            style={{
                                marginBottom: "1ch",
                                display: "flex",
                                gap: "2ch",
                            }}
                        >
                            <LoadingButton
                                data-testid="ApproveButton"
                                loading={loading}
                                color="success"
                                variant="contained"
                                endIcon={<ApprovalRounded />}
                                onClick={() => {
                                    sendApproval();
                                }}
                            >
                                {loading ? null : "Approve"}
                            </LoadingButton>
                            <LoadingButton
                                data-testid="ApproveButton"
                                loading={loading}
                                color="error"
                                variant="contained"
                                endIcon={<Cancel />}
                                onClick={() => {
                                    sendRejection();
                                }}
                            >
                                {loading ? null : "Reject"}
                            </LoadingButton>
                        </Box>

                        <DataGrid
                            checkboxSelection
                            loading={loading}
                            rows={rows}
                            columns={columns}
                            onPageChange={(page) => {
                                if (pageNo < page) {
                                    setPageNo(pageNo + 1);
                                    addLimit();
                                }
                            }}
                            autoHeight={true}
                            pageSize={8}
                            rowsPerPageOptions={[8]}
                            onSelectionModelChange={(id) => {
                                setIds(id);
                            }}
                        />
                    </Paper>
                )}
            </Box>

            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};

export default HCMApproval;
