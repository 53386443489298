import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
    Typography,
    Button,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { fileCaller, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    GeneralJournalFilesURL,
    GeneralJournalHeaderURL,
} from "../../../../utils/config";

const AddGeneralJournalFiles = () => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState("");
    const [options1, setOptions1] = useState([]);
    const [optionsId1, setOptionsID1] = useState();
    const [optionSelect1, setOptionSelect1] = useState("");
    const [fileName, setFileName] = useState("");
    const [path, setPath] = useState("");
    const UploadRef = useRef();
    const [msg, setMsg] = useState("");

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        file: "",
        name: "",
        type: "",
        general_journal_header_id: null,
    };

    const FORM_VALIDATION = Yup.object().shape({
        file: Yup.string().min(1).required("*Required"),
        name: Yup.string().min(1),
        type: Yup.string().min(1),
        // general_journal_header_id: Yup.number().min(1).required("*Required"),
    });
    useEffect(() => {
        get(GeneralJournalHeaderURL, {
            limit: 10,
            filter: {
                posting_reference: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.posting_reference}`
                    );
                });
            res && setOptionsID1(res.data);
            setOptions1(data);
        });
    }, [filter]);

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        fileCaller(GeneralJournalFilesURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                actions.resetForm();
                return 1;
            }
        });
    };

    return (
        <div>
            <TitleHeader
                title="Add General Journal Files"
                icon={<AddRounded />}
            />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        values.file = path;

                        optionsId1.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.posting_reference}` ===
                                optionSelect1
                            ) {
                                values.general_journal_header_id = name.id;
                            }
                        });

                        /* Then create a new FormData obj */
                        let formData = new FormData();

                        /* append input field values to formData */
                        for (let value in values) {
                            if (value === "isProtectedForEdit") {
                                values[value] = false;
                            }
                            formData.append(value, values[value]);
                        }

                        /* Can't console.log(formData), must
                             use formData.entries() - example:  */
                        // for (let property of formData.entries()) {
                        //     console.log(property[0], typeof property[1]);
                        // }

                        formSubmitHandler(formData, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xs={12}
                                display={"flex"}
                                sx={{ gap: "2ch" }}
                            >
                                <Button
                                    data-testid="uploadButton"
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                        UploadRef.current.click();
                                    }}
                                >
                                    File
                                </Button>
                                <input
                                    name="file"
                                    hidden
                                    ref={UploadRef}
                                    type="file"
                                    accept=".pdf"
                                    onChange={(e) => {
                                        setPath(e.target.files[0]);
                                        setFileName(e.target.files[0].name);
                                    }}
                                />
                                <Typography>{fileName}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="name"
                                    label="Name"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="type"
                                    label="Type"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    loading={options1.length < 1 ? true : false}
                                    disablePortal
                                    options={options1}
                                    onChange={(event, newValue) => {
                                        setOptionSelect1(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="General Journal Header"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default AddGeneralJournalFiles;
