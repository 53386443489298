import { Add, SearchRounded } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { InstructionGivenURL } from "../../../../utils/config";

import { get } from "../../../../utils/Caller";
import OperationsTable from "../../../../Components/Table/OperationsTable.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";

const ViewInstructionGiven = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(16);
    const [filter, setFilter] = useState("");
    const [Search, setSearch] = useState("");
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const dispatch = useDispatch();

    const columns = [
        {
            field: "instructionName",
            headerName: "Instruction",
            width: 100,
        },
        {
            field: "remarks",
            headerName: "Remarks",
            width: 300,
        },
        {
            field: "date",
            headerName: "Date",
            width: 100,
        },
        {
            field: "project_id",
            headerName: "Project ID",
            width: 100,
        },
    ];

    useEffect(() => {
        let tableData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(InstructionGivenURL, {
            limit: limit,
            filter: { project_id: selectedRow.id, all: filter },
        }).then((res) => {
            // console.log(res);
            res && res.data && setloading(false);
            res &&
                res.data?.forEach((data, index) => {
                    tableData.push({
                        ...data,
                        instructionName: data.instruction?.name,
                    });
                });
            setRows(tableData);
        });
    }, [selectedRow, refreshTable, filter, limit, rows.length]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <Button
                    variant="contained"
                    color="success"
                    endIcon={<Add />}
                    onClick={() => {
                        navigate(
                            `/View/ViewProject/${selectedRow.id}/InstructionGiven/AddInstructionGiven`
                        );
                    }}
                >
                    Add Instruction Given On Project
                </Button>
            </div>
            <div className="TableContainer">
                <OperationsTable
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                />
            </div>
        </div>
    );
};

export default ViewInstructionGiven;
