import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
    Box,
    Divider,
    IconButton,
    Typography,
    Button,
    Stepper,
    Step,
    StepLabel,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { Add, AddRounded, Cancel } from "@mui/icons-material";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { caller, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    AssetURL,
    RecurringJournalOccurrenceURL,
    ChartOfAccountURL,
    ContactURL,
    CostCenterURL,
    TaxGroupURL,
    TaxURL,
    GetUser,
    CurrencyURL,
    JournalTypeURL,
    RecurringGeneralJournalCreateURL,
} from "../../../../utils/config";

const AddRecurringGeneralJournal = () => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [IsCashBasedJournal, setIsCashBasedJournal] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState("");
    const [filter2, setFilter2] = useState("");
    const [filter3, setFilter3] = useState("");
    // const [filter4, setFilter4] = useState("");
    const [filter5, setFilter5] = useState("");
    // const [filter6, setFilter6] = useState("");
    const [filter7, setFilter7] = useState("");
    const [filter8, setFilter8] = useState("");
    const [filter9, setFilter9] = useState("");
    const [filter10, setFilter10] = useState("");
    const [filter11, setFilter11] = useState("");
    const [options1, setOptions1] = useState([]);
    const [optionSelect1, setOptionSelect1] = useState("");
    const [options2, setOptions2] = useState([]);
    const [optionSelect2, setOptionSelect2] = useState("");
    const [options3, setOptions3] = useState([]);
    // const [optionSelect3, setOptionSelect3] = useState("");
    const [options4, setOptions4] = useState([]);
    // const [optionSelect4, setOptionSelect4] = useState("");
    const [options5, setOptions5] = useState([]);
    const [optionSelect5, setOptionSelect5] = useState("");
    // const [options6, setOptions6] = useState([]);
    // const [optionSelect6, setOptionSelect6] = useState("");
    const [options7, setOptions7] = useState([]);
    const [optionSelect7, setOptionSelect7] = useState("");
    const [options8, setOptions8] = useState([]);
    const [optionSelect8, setOptionSelect8] = useState("");
    const [options9, setOptions9] = useState([]);
    const [optionSelect9, setOptionSelect9] = useState("");
    const [options10, setOptions10] = useState([]);
    const [optionSelect10, setOptionSelect10] = useState("");
    const [options11, setOptions11] = useState([]);
    const [optionSelect11, setOptionSelect11] = useState("");
    const [RGJS, setRGJS] = useState("");
    const [NeverExpires, setNeverExpires] = useState(false);
    const [msg, setMsg] = useState("");
    const RECURRING_GENERAL_JOURNAL_STATUS = ["Active", "Stopped", "Expired"];
    const [GJDsLength, setGJDsLength] = useState([0]);
    const [DebitOrCredit, setDebitOrCredit] = useState({ 0: 1 });
    const [taxCategoryValue, setTaxCategoryValue] = useState({ 0: 1 });
    const [JournalStatus, setJournalStatus] = useState(1);
    const [JournalSource, setJournalSource] = useState(1);
    const [activeStep, setActiveStep] = useState(0);
    const [taxOptions, setTaxOptions] = useState([]);
    const [taxOptionSelect, setTaxOptionSelect] = useState("");

    const DEBIT_OR_CREDIT = ["Credit", "Debit"];
    const TAX_CATEGORY = ["Tax", "Tax Group"];
    const JOURNAL_STATUS = ["Active", "Reversed", "Deleted", "Void"];
    const JOURNAL_SOURCE = [
        "Manual Journal",
        "Interfaced Journal",
        "Uploaded",
        "Auto",
    ];
    const steps = [
        "Recurring General Journal",
        "General Journal Header",
        "General Journal Detail",
        // "Tax View Model",
    ];

    const INITIAL_VALUES = {
        recurringJournal: {
            isProtectedForEdit: false,
            profile_name: "",
            start_on: "",
            end_on: "",
            never_expires: false,
            depreciable_value: "",
            recurring_general_journal_status: "",
            asset_id: null,
            recurring_journal_occurrence_id: null,
        },
        generalJournalDetail: [
            {
                tax_category: 1, //tax - taxGroup
                description: "",
                debit_or_credit: 1,
                tax_group_id: null,
                amount_credit: "",
                posting_reference: "",
                reference_code: "",
                amount_debit: "",
                tax_id: null,
                contact_id: null,
                general_journal_header_id: null,
                cost_center_id: null,
                chart_of_account_id: null,
            },
        ],
        generalJournalHeader: {
            posting_reference: "",
            reference_number: "",
            journal_status: JournalStatus,
            notes: "",
            isCashbasedJournal: IsCashBasedJournal,
            journal_source: JournalSource,
            journal_type_reference: "",
            total_amount: "",
            journal_type_id: null,
            currency_id: null,
            posting_responsible_user_id: null,
        },
    };

    const FORM_VALIDATION = Yup.object().shape({
        recurringJournal: Yup.object().shape({
            profile_name: Yup.string().min(1),
            start_on: Yup.string().min(1).required("*Required"),
            end_on: Yup.string().min(1).required("*Required"),
            never_expires: Yup.string().min(1).required("*Required"),
            depreciable_value: Yup.number().min(1).required("*Required"),
            recurring_general_journal_status: Yup.number().min(1),
        }),
        generalJournalDetail: Yup.array().of(
            Yup.object().shape({
                description: Yup.string().min(1),
                amount_credit: Yup.number().min(1),
                posting_reference: Yup.string().min(1).required("*Required"),
                reference_code: Yup.string().min(1).required("*Required"),
                amount_debit: Yup.number().min(1),
            })
        ),
        generalJournalHeader: Yup.object().shape({
            posting_reference: Yup.string().min(1).required("*Required"),
            reference_number: Yup.string().min(1),
            journal_status: Yup.number().min(1).required("*Required"),
            notes: Yup.string().min(1).required("*Required"),
            journal_source: Yup.number().min(1).required("*Required"),
            journal_type_reference: Yup.string().min(1),
            total_amount: Yup.number().min(1),
        }),
    });

    useEffect(() => {
        get(AssetURL, {
            limit: 10,
            filter: {
                name: filter,
            },
        }).then((res) => {
            // console.log(res);
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.name}`
                    );
                });
            setOptions1(data);
        });

        get(RecurringJournalOccurrenceURL, {
            limit: 10,
            filter: {
                name: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.name}`
                    );
                });
            setOptions2(data);
        });

        get(TaxGroupURL, {
            limit: 10,
            filter: {
                tax_group_name: filter3,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.tax_group_name}`
                    );
                });
            setOptions3(data);
        });

        get(TaxURL, {
            limit: 10,
            filter: {
                tax_name: filter3,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.tax_name}`
                    );
                });
            setOptions4(data);
        });

        get(ContactURL, {
            limit: 10,
            filter: {
                company_name: filter5,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.company_name}`
                    );
                });
            setOptions5(data);
        });

        // get(GeneralJournalHeaderURL, {
        //     limit: 10,
        //     filter: {
        //         posting_reference: filter6,
        //     },
        // }).then((res) => {
        //     let data = [];
        //     res &&
        //         res.data.forEach((name, index) => {
        //             data.push(
        //                 `${res.data[index]?.id} - ${res.data[index]?.posting_reference}`
        //             );
        //         });
        //     setOptions6(data);
        // });

        get(CostCenterURL, {
            limit: 10,
            filter: {
                cost_center_code: filter7,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.cost_center_code}`
                    );
                });
            setOptions7(data);
        });

        get(ChartOfAccountURL, {
            limit: 10,
            filter: {
                account_code: filter8,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.account_code}`
                    );
                });
            setOptions8(data);
        });

        get(JournalTypeURL, {
            limit: 10,
            filter: {
                type: filter9,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.type}`
                    );
                });
            setOptions9(data);
        });

        get(CurrencyURL, {
            limit: 10,
            filter: {
                name: filter10,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.name}`
                    );
                });
            setOptions10(data);
        });

        get(GetUser, {
            limit: 10,
            filter: {
                username: filter11,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.username}`
                    );
                });
            setOptions11(data);
        });
    }, [
        filter,
        filter10,
        filter11,
        filter2,
        filter3,
        filter5,
        filter7,
        filter8,
        filter9,
    ]);

    useEffect(() => {
        taxOptions.length === 0 && setTaxOptions((prevData) => options4);
    }, [options4, taxOptions.length]);

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        caller(RecurringGeneralJournalCreateURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                actions.resetForm();
                return 1;
            }
        });
    };

    const taxCategoryCheck = (i) => {
        if (taxCategoryValue[i] === 2) {
            setTaxOptions(options4);
        } else if (taxCategoryValue[i] === 1) {
            setTaxOptions(options3);
        }
    };

    const GJD = (key) => {
        return (
            <Grid container spacing={2} key={key}>
                <Grid item xs={4}>
                    <TextFieldWrapper
                        select
                        name={`generalJournalDetail.${key}.debit_or_credit`}
                        label="Debit Or Credit"
                        variant="outlined"
                        value={DebitOrCredit[key]}
                        options={DEBIT_OR_CREDIT ?? " "}
                        onChange={(e) => {
                            setDebitOrCredit({
                                ...DebitOrCredit,
                                [key]: e.target.value,
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextFieldWrapper
                        select
                        name={`generalJournalDetail.${key}.tax_category`}
                        label="Tax Category"
                        variant="outlined"
                        value={taxCategoryValue[key]}
                        options={TAX_CATEGORY ?? " "}
                        onChange={(e) => {
                            setTaxCategoryValue({
                                ...taxCategoryValue,
                                [key]: e.target.value,
                            });
                            taxCategoryCheck(key);
                            setTaxOptionSelect({
                                ...taxOptionSelect,
                                [key]: null,
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        key={key}
                        value={taxOptionSelect[key]}
                        loading={taxOptions.length < 1 ? true : false}
                        disablePortal
                        options={taxOptions}
                        onChange={(event, newValue) => {
                            setTaxOptionSelect({
                                ...taxOptionSelect,
                                [key]: newValue,
                            });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Tax"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(event) => {
                                    setFilter3(event.target.value);
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextFieldWrapper
                        name={`generalJournalDetail.${key}.amount_credit`}
                        label="Amount Credit"
                        variant="outlined"
                        type="number"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextFieldWrapper
                        name={`generalJournalDetail.${key}.posting_reference`}
                        label="Posting Reference"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextFieldWrapper
                        name={`generalJournalDetail.${key}.reference_code`}
                        label="Reference Code"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextFieldWrapper
                        name={`generalJournalDetail.${key}.amount_debit`}
                        label="Amount Debit"
                        variant="outlined"
                        type="number"
                    />
                </Grid>
                {/* <Grid item xs={4}>
                    <Autocomplete
                        value={optionSelect4[key]}
                        loading={options4.length < 1 ? true : false}
                        disablePortal
                        options={options4}
                        onChange={(event, newValue) => {
                            setOptionSelect4({
                                ...optionSelect4,
                                [key]: newValue,
                            });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Tax"
                                onChange={(event) => {
                                    setFilter4(event.target.value);
                                }}
                            />
                        )}
                    />
                </Grid> */}
                <Grid item xs={4}>
                    <Autocomplete
                        key={key}
                        value={optionSelect5[key]}
                        loading={options5.length < 1 ? true : false}
                        disablePortal
                        options={options5}
                        onChange={(event, newValue) => {
                            setOptionSelect5({
                                ...optionSelect5,
                                [key]: newValue,
                            });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label="Contact"
                                onChange={(event) => {
                                    setFilter5(event.target.value);
                                }}
                            />
                        )}
                    />
                </Grid>
                {/* <Grid item xs={4}>
                    <Autocomplete
                        value={optionSelect6[key]}
                        loading={options6.length < 1 ? true : false}
                        disablePortal
                        options={options6}
                        onChange={(event, newValue) => {
                            setOptionSelect6({
                                ...optionSelect6,
                                [key]: newValue,
                            });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="General Journal Header"
                                onChange={(event) => {
                                    setFilter6(event.target.value);
                                }}
                            />
                        )}
                    />
                </Grid> */}
                <Grid item xs={4}>
                    <Autocomplete
                        key={key}
                        value={optionSelect7[key]}
                        loading={options7.length < 1 ? true : false}
                        disablePortal
                        options={options7}
                        onChange={(event, newValue) => {
                            setOptionSelect7({
                                ...optionSelect7,
                                [key]: newValue,
                            });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label="Cost Center"
                                onChange={(event) => {
                                    setFilter7(event.target.value);
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        key={key}
                        value={optionSelect8[key]}
                        loading={options8.length < 1 ? true : false}
                        disablePortal
                        options={options8}
                        onChange={(event, newValue) => {
                            setOptionSelect8({
                                ...optionSelect8,
                                [key]: newValue,
                            });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label="Chart Of Account"
                                onChange={(event) => {
                                    setFilter8(event.target.value);
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextFieldWrapper
                        name={`generalJournalDetail.${key}.description`}
                        label="Description"
                        variant="outlined"
                        multiline
                        rows={3}
                    />
                </Grid>
            </Grid>
        );
    };

    const RenderGJD = ({ i }) => {
        return (
            <Box>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography fontWeight={600}>
                        General Journal Detail {i + 1}
                    </Typography>
                    <IconButton
                        onClick={() => {
                            cancelGJD(i);
                        }}
                    >
                        <Cancel color="error" />
                    </IconButton>
                </div>
                <Divider variant="fullWidth" />
                <Box minHeight={"2ch"} />
                {GJD(i)}
            </Box>
        );
    };

    const addGJD = () => {
        const max = Math.max(...GJDsLength);
        setGJDsLength([...GJDsLength, max + 1]);
    };

    const cancelGJD = (i) => {
        if (GJDsLength.length > 1) {
            const theRest = GJDsLength.filter((a) => a !== i);
            setGJDsLength([...theRest]);
        }
    };

    return (
        <div>
            <TitleHeader
                title="Add Recurring General Journal"
                icon={<AddRounded />}
            />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        if (activeStep === 3) {
                            values.recurringJournal.isProtectedForEdit =
                                protectedEdit;
                            values.recurringJournal.recurring_general_journal_status =
                                RGJS;
                            values.recurringJournal.never_expires =
                                NeverExpires;
                            values.recurringJournal.asset_id = parseInt(
                                optionSelect1?.split("-")[0]
                            );
                            values.recurringJournal.recurring_journal_occurrence_id =
                                parseInt(optionSelect2?.split("-")[0]);
                            values.generalJournalHeader.isCashbasedJournal =
                                IsCashBasedJournal;

                            GJDsLength.forEach((data) => {
                                values.generalJournalDetail[
                                    data
                                ].debit_or_credit = DebitOrCredit[data];
                                values.generalJournalDetail[data].tax_category =
                                    taxCategoryValue[data];
                                values.generalJournalDetail[data].tax_id =
                                    parseInt(
                                        taxOptionSelect[data]?.split("-")[0]
                                    );
                                values.generalJournalDetail[data].contact_id =
                                    parseInt(
                                        optionSelect5[data]?.split("-")[0]
                                    );
                                // values.generalJournalDetail[
                                //     data
                                // ].general_journal_header_id = parseInt(
                                //     optionSelect6[data].split("-")[0]
                                // );

                                values.generalJournalDetail[
                                    data
                                ].cost_center_id = parseInt(
                                    optionSelect7[data]?.split("-")[0]
                                );
                                values.generalJournalDetail[
                                    data
                                ].chart_of_account_id = parseInt(
                                    optionSelect8[data]?.split("-")[0]
                                );
                            });

                            values.generalJournalHeader.journal_type_id =
                                parseInt(optionSelect9?.split("-")[0]);
                            values.generalJournalHeader.currency_id = parseInt(
                                optionSelect10?.split("-")[0]
                            );
                            values.generalJournalHeader.posting_responsible_user_id =
                                parseInt(optionSelect11?.split("-")[0]);

                            formSubmitHandler(values, actions);
                        }
                    }}
                >
                    {(props) => (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Stepper activeStep={activeStep}>
                                        {steps.map((label, index) => {
                                            const stepProps = {};
                                            const labelProps = {};
                                            return (
                                                <Step
                                                    key={label}
                                                    {...stepProps}
                                                >
                                                    <StepLabel {...labelProps}>
                                                        {label}
                                                    </StepLabel>
                                                </Step>
                                            );
                                        })}
                                    </Stepper>
                                </Grid>
                                {activeStep === 0 && (
                                    <>
                                        <Grid item xs={4}>
                                            <TextFieldWrapper
                                                name="recurringJournal.profile_name"
                                                label="Profile Name"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Box width={"100%"} />
                                        <Grid item xs={4}>
                                            <FormControl fullWidth>
                                                <FormLabel>Start On</FormLabel>
                                                <TextFieldWrapper
                                                    name="recurringJournal.start_on"
                                                    variant="outlined"
                                                    type="date"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth>
                                                <FormLabel>End On</FormLabel>
                                                <TextFieldWrapper
                                                    name="recurringJournal.end_on"
                                                    variant="outlined"
                                                    type="date"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    alignSelf: "baseline",
                                                }}
                                            >
                                                <FormLabel>
                                                    Never Expires
                                                </FormLabel>
                                                <Checkbox
                                                    name="recurringJournal.never_expires"
                                                    checked={NeverExpires}
                                                    onChange={(event) => {
                                                        setNeverExpires(
                                                            event.target.checked
                                                        );
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextFieldWrapper
                                                name="recurringJournal.depreciable_value"
                                                label="Depreciable Value"
                                                variant="outlined"
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextFieldWrapper
                                                select
                                                name="recurringJournal.recurring_general_journal_status"
                                                label="Recurring General Journal Status"
                                                variant="outlined"
                                                value={RGJS}
                                                options={
                                                    RECURRING_GENERAL_JOURNAL_STATUS
                                                }
                                                onChange={(e) => {
                                                    setRGJS(e.target.value);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Autocomplete
                                                value={optionSelect1}
                                                loading={
                                                    options1.length < 1
                                                        ? true
                                                        : false
                                                }
                                                disablePortal
                                                options={options1}
                                                onChange={(event, newValue) => {
                                                    setOptionSelect1(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Asset"
                                                        onChange={(event) => {
                                                            setFilter(
                                                                event.target
                                                                    .value
                                                            );
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Autocomplete
                                                value={optionSelect2}
                                                loading={
                                                    options2.length < 1
                                                        ? true
                                                        : false
                                                }
                                                disablePortal
                                                options={options2}
                                                onChange={(event, newValue) => {
                                                    setOptionSelect2(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Recurring Journal Occurrence"
                                                        onChange={(event) => {
                                                            setFilter2(
                                                                event.target
                                                                    .value
                                                            );
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Checkbox
                                                    checked={protectedEdit}
                                                    onChange={(event) => {
                                                        setProtectedEdit(
                                                            event.target.checked
                                                        );
                                                    }}
                                                />
                                                <FormLabel>
                                                    Protected For Edit
                                                </FormLabel>
                                            </FormControl>
                                        </Grid>
                                    </>
                                )}
                                {/* ========================================|| GJH ||===================================== */}
                                {activeStep === 1 && (
                                    <>
                                        <Grid item xs={12}>
                                            <Typography fontWeight={600}>
                                                General Journal Header
                                            </Typography>
                                            <Divider variant="fullWidth" />
                                            <Box minHeight={"2ch"} />
                                        </Grid>

                                        <Box width={"100%"} />
                                        <Grid item xs={4}>
                                            <TextFieldWrapper
                                                name="generalJournalHeader.posting_reference"
                                                label="Posting Reference"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextFieldWrapper
                                                name="generalJournalHeader.reference_number"
                                                label="Reference Number"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextFieldWrapper
                                                select
                                                name="generalJournalHeader.journal_status"
                                                label="Journal Status"
                                                variant="outlined"
                                                value={JournalStatus}
                                                options={JOURNAL_STATUS ?? " "}
                                                onChange={(e) => {
                                                    setJournalStatus(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextFieldWrapper
                                                name="generalJournalHeader.notes"
                                                label="Notes"
                                                variant="outlined"
                                                multiline
                                                rows={2}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextFieldWrapper
                                                select
                                                name="generalJournalHeader.journal_source"
                                                label="Journal Source"
                                                variant="outlined"
                                                value={JournalSource}
                                                options={JOURNAL_SOURCE ?? " "}
                                                onChange={(e) => {
                                                    setJournalSource(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextFieldWrapper
                                                name="generalJournalHeader.journal_type_reference"
                                                label="Journal Type Reference"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextFieldWrapper
                                                name="generalJournalHeader.total_amount"
                                                label="Total Amount"
                                                variant="outlined"
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Autocomplete
                                                value={optionSelect9}
                                                loading={
                                                    options9.length < 1
                                                        ? true
                                                        : false
                                                }
                                                disablePortal
                                                options={options9}
                                                onChange={(event, newValue) => {
                                                    setOptionSelect9(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Journal Type"
                                                        onChange={(event) => {
                                                            setFilter9(
                                                                event.target
                                                                    .value
                                                            );
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <Autocomplete
                                                value={optionSelect10}
                                                loading={
                                                    options10.length < 1
                                                        ? true
                                                        : false
                                                }
                                                disablePortal
                                                options={options10}
                                                onChange={(event, newValue) => {
                                                    setOptionSelect10(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Currency"
                                                        onChange={(event) => {
                                                            setFilter10(
                                                                event.target
                                                                    .value
                                                            );
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Autocomplete
                                                value={optionSelect11}
                                                loading={
                                                    options11.length < 1
                                                        ? true
                                                        : false
                                                }
                                                disablePortal
                                                options={options11}
                                                onChange={(event, newValue) => {
                                                    setOptionSelect11(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Posting Responsible User"
                                                        onChange={(event) => {
                                                            setFilter11(
                                                                event.target
                                                                    .value
                                                            );
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    alignSelf: "baseline",
                                                }}
                                            >
                                                <FormLabel>
                                                    Cash Based Journal
                                                </FormLabel>
                                                <Checkbox
                                                    name="generalJournalHeader.isCashBasedJournal"
                                                    checked={IsCashBasedJournal}
                                                    onChange={(event) => {
                                                        setIsCashBasedJournal(
                                                            event.target.checked
                                                        );
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </>
                                )}
                                {activeStep === 2 && (
                                    <>
                                        <Box
                                            style={{
                                                margin: "2ch 0ch 0ch 2ch",
                                            }}
                                        >
                                            {GJDsLength.map((i, index) => {
                                                return (
                                                    <RenderGJD
                                                        i={i}
                                                        key={index}
                                                    />
                                                );
                                            })}
                                            <Button
                                                endIcon={<Add />}
                                                onClick={() => {
                                                    addGJD();
                                                    setTaxOptions([]);
                                                    setTaxCategoryValue(
                                                        (prevValue) => {
                                                            const newKey =
                                                                Object.keys(
                                                                    prevValue
                                                                ).length;
                                                            return {
                                                                ...taxCategoryValue,
                                                                [newKey]: 1,
                                                            };
                                                        }
                                                    );
                                                }}
                                                style={{ margin: "2ch" }}
                                            >
                                                Add Another General Journal
                                                Detail
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            </Grid>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    disabled={activeStep === 0}
                                    onClick={() => {
                                        setActiveStep(
                                            (prevActiveStep) =>
                                                prevActiveStep - 1
                                        );
                                    }}
                                >
                                    Back
                                </Button>

                                {activeStep === 2 ? (
                                    <div>
                                        <LoadingButton
                                            data-testid="AddButton"
                                            loading={loading}
                                            type="submit"
                                            className="ButtonContained"
                                            style={{
                                                marginTop: "2ch",
                                                float: "right",
                                            }}
                                        >
                                            {loading ? null : "Add"}
                                        </LoadingButton>
                                    </div>
                                ) : (
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        onClick={() => {
                                            if (
                                                props.values.recurringJournal
                                                    .start_on !== "" &&
                                                props.values.recurringJournal
                                                    .end_on !== "" &&
                                                props.values.recurringJournal
                                                    .never_expires !== "" &&
                                                props.values.recurringJournal
                                                    .depreciable_value !== ""
                                            ) {
                                                setActiveStep(
                                                    (prevActiveStep) =>
                                                        prevActiveStep + 1
                                                );
                                            }
                                        }}
                                    >
                                        Next
                                    </Button>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default AddRecurringGeneralJournal;
