export const KEY = `eHicbKI&Ubcs789Ft98hT$(Ocd2ceHFc`;

//==================================||  ROUTES FOR THE API  ||=========================================

//#region ===========|| Account  ||==============
export const RefreshURL = `/account/refresh`;
export const LogInURL = `/account/login`;
export const GetUser = `/account`;
export const ForgotPassURL = `/account/forgotpassword`;
export const SendCode = `/account/sendcode`;
export const ChangePasswordURL = `/account/changepassword`;
export const RoleURL = `/role`;
export const PrivilegeURL = `/privilege`;
//#endregion

//#region ===========|| PROJECT MASTER ||=============

export const Client = `/project/master/client`;
export const UploadURL = `/project/master/upload`;
export const DownloadURL = `/project/master/download`;
export const MaterialURL = `/project/master/material`;
export const MaterialCategoryURL = `/project/master/material_category`;
export const EquipmentURL = `/project/master/equipment`;
export const EvaluationURL = `/project/master/evaluation`;
export const InstructionURL = `/project/master/instruction`;
export const ManPowerURL = `/project/master/manpower`;
export const PhaseURL = `/project/master/phase`;
export const WorkCategoryURL = `/project/master/work_category`;
export const PriorityURL = `/project/master/priority`;
export const DocumentationURL = `/project/master/documentation`;
export const DocumentCategoryURL = `/project/master/document_category`;
//#endregion

//#region ===========|| PROJECT OPERATIONAL ||==============

export const ProjectURL = `/project/operational/project`;
export const IsApprovedURL = `/project/operational/project_edit_request/status`;
export const ProjectEditRequestURL = `/project/operational/project_edit_request`;
export const ProjectIDURL = `/project/operational/project/project_id`;
export const DailyWorkLogURL = `/project/operational/daily_work_log`;
export const DailyReportURL = `/project/operational/daily_report`;
export const ReportRemarkURL = `/project/operational/report-remark`;
export const InstructionGivenURL = `/project/operational/instruction_given_on_project`;
export const ManpowerRequirementURL = `/project/operational/manpower_requirement`;
export const RequiredEquipmentURL = `/project/operational/required_equipment`;
export const RequiredMaterialURL = `/project/operational/required_material`;
export const RiskTrackingURL = `/project/operational/risk_tracking`;
export const TaskManagerURL = `/project/operational/task_manager`;
export const SubTaskURL = `/project/operational/sub_task`;
export const SimpleTaskURL = `/project/operational/simple_task`;
export const WorkLogURL = `/project/operational/work_log_and_branch_of_work`;
export const WeatherDataURL = `/project/operational/weather_data`;
export const ToDoURL = `/project/operational/todos`;
export const ProjectToDoURL = `/project/operational/project_todos`;
export const RequestURL = `/project/operational/request`;
export const InvoiceTrackingURL = `/project/operational/invoice_tracking`;
export const RequiredDocumentationURL = `/project/operational/required_document`;
export const RequestPaymentURL = `/project/operational/request_payment`;
export const ProjectRequestURL = `/project/operational/project_request`;
export const ProjectAllRequestURL = `/project/operational/all_project_requests`;
export const ProjectDetailRequestURL = `/project/operational/detail_project_requests`;
export const ProjectRequestApproveURL = `/project/validation/project_request`;
export const ProjectEditRequestApproveURL = `/project/validation/project_edit_request`;
export const ProjectParticipationRequestURL = `/project/validation/project_participation_request`;
export const ProjectWorkingDaysURL = `/project/working_days`;
export const ProjectDashboardURL = `/project/dashboard`;
export const ProjectParticipationURL = `/project/operational/participation_request`;
// #endregion

//#region ===========|| HCM MASTER ||===============

export const CurrencyURL = `/hcm/master/currency`;
export const CountryURL = `/hcm/master/country`;
export const TitleURL = `/hcm/master/title`;
export const LanguageURL = `/hcm/master/language`;
export const ActionReasonURL = `/hcm/master/action_reason`;
export const ActionTypeURL = `/hcm/master/action_type`;
export const AddressTypeURL = `/hcm/master/address_type`;
export const CommitmentURL = `/hcm/master/commitment`;
export const CustomReportURL = `/hcm/master/custom_report`;
export const DisciplineCaseTypeURL = `/hcm/master/discipline_case_type`;
export const DurationMeasureURL = `/hcm/master/duration_measure`;
export const EmployeeGroupURL = `/hcm/master/employee_group`;
export const EmployeeTypeURL = `/hcm/master/employee_type`;
export const FieldOfStudyURL = `/hcm/master/field_of_study`;
export const HCMConfigurationURL = `/hcm/master/hcm_configuration`;
export const EmployeeTransactionLockURL = `/hcm/master/employee_transaction_lock`;
export const LicenseTypeURL = `/hcm/master/license_type`;
export const NationalityURL = `/hcm/master/nationality`;
export const ReligionURL = `/hcm/master/religion`;
export const TrainingTypeURL = `/hcm/master/training_type`;
// #endregion

//#region ===============|| HCM JOB POSITIONS ||=================

export const JobCategoryURL = `/hcm/job_positions/job_category`;
export const JobSafetyEquipmentURL = `/hcm/job_positions/job_safety_equipment`;
export const JobTitleURL = `/hcm/job_positions/job_title`;
export const SafetyEquipmentURL = `/hcm/job_positions/safety_equipment`;
// #endregion

//#region =============||HCM PAYROLL||===================

export const EmployeeBackPenalityDeductionURL = `/hcm/payroll/employee_back_penality_deduction`;
export const EmployeePayScaleURL = `/hcm/payroll/employee_pay_scale`;
export const UploadPayScaleURL = `/hcm/payroll/employee_pay_scale/upload`;
export const EmployeePenalityURL = `/hcm/payroll/employee_penality`;
export const EmployeePenalityDetailMstURL = `/hcm/payroll/employee_penality_detail_mst`;
export const EmployeePenalityTypeURL = `/hcm/payroll/employee_penality_type`;
export const EmployeeTaxURL = `/hcm/payroll/employee_tax`;
export const GlobalPayrollAccountMappingURL = `/hcm/payroll/global_payroll_account_mapping`;
export const OvertimeURL = `/hcm/payroll/overtime`;
export const OvertimeRateURL = `/hcm/payroll/overtime_rate`;
export const PaygradeURL = `/hcm/payroll/paygrade`;
export const PaygradeSalaryComponentURL = `/hcm/payroll/paygrade_salary_component`;
export const PaygradeScaleURL = `/hcm/payroll/paygrade_scale`;
export const PayrollDetailURL = `/hcm/payroll/payroll_detail`;
export const PayrollDetailHistoryURL = `/hcm/payroll/payroll_detail_history`;
export const PayrollFrequencyTypeURL = `/hcm/payroll/payroll_frequency_type`;
export const PayrollLocationSettingURL = `/hcm/payroll/payroll_location_setting`;
export const PayrollLogURL = `/hcm/payroll/payroll_log`;
export const PayrollLogEmployeeURL = `/hcm/payroll/payroll_log_employee`;
export const PayrollPeriodAutogenURL = `/hcm/payroll/payroll_period_autogen`;
export const PayrollPeriodAutogenLogURL = `/hcm/payroll/payroll_period_autogen_log`;
export const PayrollPeriodTemplateURL = `/hcm/payroll/payroll_period_template`;
export const PayrollPostingLogURL = `/hcm/payroll/payroll_posting_log`;
export const PayrollProcessingLogURL = `/hcm/payroll/payroll_processing_log`;
export const PayrollSummaryURL = `/hcm/payroll/payroll_summary`;
export const PayrollSummaryHistoryURL = `/hcm/payroll/payroll_summary_history`;
export const PayScaleHistoryLogURL = `/hcm/payroll/pay_scale_history_log`;
export const SalaryAdjustmentURL = `/hcm/payroll/salary_adjustment`;
export const SalaryComponentURL = `/hcm/payroll/salary_component`;
export const SalaryComponentAccountMappingURL = `/hcm/payroll/salary_component_account_mapping`;
export const RunURL = `/hcm/payroll/run`;
export const PostURL = `/hcm/payroll/post`;
// #endregion

//#region =============|| HCM EMPLOYEE MASTER ||==================

export const UserEmployeeURL = `/hcm/employee_master/user`;
export const NotificationsURL = `/hcm/employee_master/notifications`;
export const CalendarURL = `/hcm/employee_master/announcement`;
export const InitialHiringURL = `/hcm/employee_master/initial_hiring`;
export const AddressURL = `/hcm/employee_master/address`;
export const AttachmentURL = `/hcm/employee_master/attachment`;
export const DependentURL = `/hcm/employee_master/dependent`;
export const DisciplineURL = `/hcm/employee_master/discipline`;
export const EducationURL = `/hcm/employee_master/education`;
export const EmployeeURL = `/hcm/employee_master/employee`;
export const AllEmployeeURL = `/hcm/employee_master/all_employee`;
export const EmployeeActionURL = `/hcm/employee_master/employee_action`;
export const EmployeeAttachmentURL = `/hcm/employee_master/employee_attachment`;
export const EmployeeCommitmentURL = `/hcm/employee_master/employee_commitment`;
export const EmployeeContactURL = `/hcm/employee_master/employee_contact`;
export const EmployeeIdRangeURL = `/hcm/employee_master/employee_id_range`;
export const EmployeeLoanRepaymentURL = `/hcm/employee_master/employee_loan_repayment`;
export const EmployeePayFrequencyURL = `/hcm/employee_master/employee_pay_frequency`;
export const EmployeePaygradeURL = `/hcm/employee_master/employee_paygrade`;
export const EmployeeSalaryComponentURL = `/hcm/employee_master/employee_salary_component`;
export const ExperienceURL = `/hcm/employee_master/experience`;
export const LeaveAssignmentURL = `/hcm/employee_master/leave_assignment`;
export const LeaveEntitlementURL = `/hcm/employee_master/leave_entitlement`;
export const LeaveTransferURL = `/hcm/employee_master/leave_transfer`;
export const LicenseURL = `/hcm/employee_master/license`;
export const OrgAssignmentURL = `/hcm/employee_master/org_assignment`;
export const ServiceAllowancePayURL = `/hcm/employee_master/service_allowance_pay`;
export const ShiftAssignmentURL = `/hcm/employee_master/shift_assignment`;
// #endregion

//#region =============|| HCM TIME AND LEAVE ||=====================

export const AttendanceAbscenceTypeURL = `/hcm/time_and_leave/attendance_abscence_type`;
export const AttendanceCapturedURL = `/hcm/time_and_leave/attendance_captured`;
export const AttendancePayrollURL = `/hcm/time_and_leave/attendance_payroll`;
export const DeviceIdMappingURL = `/hcm/time_and_leave/device_id_mapping`;
export const HolidayURL = `/hcm/time_and_leave/holiday`;
export const HolidayCharacterURL = `/hcm/time_and_leave/holiday_calendar`;
export const LeavePeriodURL = `/hcm/time_and_leave/leave_period`;
export const LeavePlanURL = `/hcm/time_and_leave/leave_plan`;
export const PunchURL = `/hcm/time_and_leave/punch`;
export const PunchDeviceURL = `/hcm/time_and_leave/punch_device`;
export const PunchLogURL = `/hcm/time_and_leave/punch_log`;
export const PunchManualImportURL = `/hcm/time_and_leave/punch_manual_import`;
export const PunchTimeURL = `/hcm/time_and_leave/punch_time`;
export const ShiftScheduleDtlURL = `/hcm/time_and_leave/shift_schedule_dtl`;
export const ShiftScheduleHdrURL = `/hcm/time_and_leave/shift_schedule_hdr`;
export const SubShiftGroupURL = `/hcm/time_and_leave/sub_shift_group`;
export const AttendanceSheetReleaseURL = `/hcm/time_and_leave/attendance_payroll/release`;
export const AttendanceSheetCreateURL = `/hcm/time_and_leave/attendance_payroll/create`;
// #endregion

//#region =============|| HCM COMPANY STRUCTURE ||====================

export const BusinessUnitURL = `/hcm/company_structure/business_unit`;
export const CompanyURL = `/hcm/company_structure/company`;
export const LocationURL = `/hcm/company_structure/location`;
export const CompanyPrimaryContactURL = `/hcm/company_structure/company_primary_contact`;
// #endregion

//#region =============|| HCM RECRUITMENT ||======================

export const ExternalApplicantURL = `/hcm/recruitment/external_applicant`;
export const VacancyResultURL = `/hcm/recruitment/vacancy_result`;
export const VacancyURL = `/hcm/recruitment/vacancy`;
export const VacancyApplicantURL = `/hcm/recruitment/vacancy_applicant`;
export const VacancyExaminerURL = `/hcm/recruitment/vacancy_examiner`;
export const VacancyInternalApplicantURL = `/hcm/recruitment/vacancy_internal_applicant`;
export const VacancyRequestReasonURL = `/hcm/recruitment/vacancy_request_reason`;
// #endregion

//#region =============||FINANCE MASTER ||=======================

export const AccountCategoryURL = `/finance/master/account_category`;
export const AccountTypeURL = `/finance/master/account_type`;
export const ClosingNoteURL = `/finance/master/closing_note`;
export const FinancialStatementSectionURL = `/finance/master/financial_statement_section`;
export const BankURL = `/finance/master/bank`;
export const ContactURL = `/finance/master/contact`;
export const ContactAddressURL = `/finance/master/contact_address`;
export const ContactPersonURL = `/finance/master/contact_person`;
export const CostCenterURL = `/finance/master/cost_center`;
export const CostCenterAccountsURL = `/finance/master/cost_center_accounts`;
export const PaymentTermURL = `/finance/master/payment_term`;
export const ExchangeRateURL = `/finance/master/exchange_rate`;
export const CompanyAddressURL = `/finance/master/company_address`;
export const DateFormatTypeURL = `/finance/master/date_format_type`;
export const FinancialSettingsURL = `/finance/master/financial_settings`;
export const IndustryURL = `/finance/master/industry`;
export const JournalUsersURL = `/finance/master/journal_users`;
export const PrimaryContactURL = `/finance/master/primary_contact`;
export const FootNoteURL = `/finance/master/foot_note`;
export const AssociatedTaxGroupURL = `/finance/master/associated_tax_group`;
export const TaxURL = `/finance/master/tax`;
export const TaxAuthorityURL = `/finance/master/tax_authority`;
export const TaxExemptionURL = `/finance/master/tax_exemption`;
export const TaxGroupURL = `/finance/master/tax_group`;
export const TaxRuleURL = `/finance/master/tax_rule`;
export const AccountingPeriodURL = `/finance/master/accounting_period`;
export const AccountingPeriodProcessClosingURL = `/finance/master/accounting_period/process_closing`;
export const AccountingPeriodChangeStatusURL = `/finance/master/accounting_period/change_status`;
export const JournalTypeURL = `/finance/master/journal_type`;
// #endregion

//#region =============|| FINANCE OPERATIONAL ||==================

export const ChartOfAccountURL = `/finance/operational/chart_of_account`;
export const AccountTypeFinancialStatementSectionURL = `/finance/operational/account_type_financial_statement_section`;
export const BankReconcilationURL = `/finance/operational/bank_reconcilation`;
export const ChartOfAccountFilesURL = `/finance/operational/chart_of_account_files`;
export const ReconcilationTransactionURL = `/finance/operational/reconcilation_transaction`;
export const AssetURL = `/finance/operational/asset`;
export const EstimatedTotalProductionUnitURL = `/finance/operational/estimated_total_production_unit`;
export const RecurringJournalOccurrenceURL = `/finance/operational/recurring_journal_occurrence`;
export const BudgetURL = `/finance/operational/budget`;
export const BudgetAccountURL = `/finance/operational/budget_account`;
export const BudgetAccountPeriodURL = `/finance/operational/budget_account_period`;
export const BudgetControlActionURL = `/finance/operational/budget_control_action`;
export const GeneralJournalDetailURL = `/finance/operational/general_journal_detail`;
export const GeneralJournalFilesURL = `/finance/operational/general_journal_files`;
export const GeneralJournalHeaderURL = `/finance/operational/general_journal_header`;
export const GeneralLedgerURL = `/finance/operational/general_ledger`;
export const JournalCommentURL = `/finance/operational/journal_comment`;
export const NumberTrackerURL = `/finance/operational/number_tracker`;
export const RecurringGeneralJournalURL = `/finance/operational/recurring_general_journal`;
export const RecurringGeneralJournalCreateURL = `/finance/operational/create_recurring`;
export const TransactionLockURL = `/finance/operational/transaction_lock`;
export const OpeningBalanceURL = `/finance/operational/opening_balance`;
export const CashPaymentCustomURL = `/finance/operational/cash_payment_custom`;
export const CashPaymentVoucherURL = `/finance/operational/cash_payment_voucher`;
export const CpvBankURL = `/finance/operational/cpv_bank`;
export const CpvPaymentRequestURL = `/finance/operational/cpv_payment_request`;
export const CpvReceiptURL = `/finance/operational/cpv_receipt`;
export const CpvTypeURL = `/finance/operational/cpv_type`;
export const PettyCashURL = `/finance/operational/petty_cash`;
export const CrvPaymentURL = `/finance/operational/crv_payment`;
export const CrvTypeURL = `/finance/operational/crv_type`;
export const TemplateURL = `/finance/operational/export_template`;
export const GeneralExportURL = `/finance/operational/general_export`;
export const ColumnsURL = `/finance/operational/export_template/column`;
export const PVRequestURL = `/finance/operational/payment_request`;
export const PVRequestApproveURL = `/finance/operational/approvals/payment_request`;
export const PVRequestDocsURL = `/finance/operational/payment_request/add_attachments`;
export const RemoveAttachmentURL = `/finance/operational/payment_request/remove_attachment`;
// #endregion

//#region =============|| INVENTORY MASTER ||======================

export const UnitOfMeasureURL = `/inventory/master/unit_of_measure`;
export const WarehouseURL = `/inventory/master/warehouse`;
// #endregion

//#region =============|| INVENTORY STOCK ||====================

export const StockBatchURL = `/inventory/stock/stock_batch`;
export const StockItemURL = `/inventory/stock/stock_item`;
export const StockTransactionDetailURL = `/inventory/stock/stock_transaction_detail`;
export const StockTransactionHeaderURL = `/inventory/stock/stock_transaction_header`;
// #endregion

//#region =============|| APPROVAL ||=======================

export const LeaveTransferApprovalURL = `/hcm/leave_transfer_approve`;
export const LeaveAssignmentApprovalURL = `/hcm/leave_assignment_approve`;
export const LeavePlanApprovalURL = `/hcm/leave_plan_approve`;
export const AttendanceApprovalURL = `/hcm/attendance_approve`;
export const OvertimeApprovalURL = `/hcm/overtime_approve`;
// #endregion

//#region =============|| Payroll Slip ||========================

export const PayrollSlipURL = `/hcm/payroll/payroll_slip`;
// #endregion

//#region =============||Excel Export||========================

export const CashPaymentCustomExcelURL =
    "/finance/operational/cash_payment_custom/excel_export";
export const CrvPaymentExcelURL =
    "/finance/operational/crv_payment/excel_export";
export const GeneralLedgerExcelURL =
    "/finance/operational/export/general_ledger";
export const AccountTransactionsExcelURL =
    "/finance/operational/export/account_transaction";
export const JournalsExcelURL = "/finance/operational/export/journal";
export const TrailBalanceExcelURL = "/finance/operational/export/trail_balance";
export const TaxSummaryExcelURL = "/finance/operational/export/tax_summary";
export const ProfitLossExcelURL = "/finance/business_export/profit_loss";
export const CashFlowExcelURL = "/finance/business_export/cash_flow";
export const BalanceSheetExcelURL = "/finance/business_export/balance_sheet";
export const SalesURL = "/sales/customer-form";
export const QRURL = "/user/";
// #endregion
