import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { caller, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import { AccountTypeURL, AccountCategoryURL } from "../../../../utils/config";

const AddAccountType = () => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState("");
    const [options1, setOptions1] = useState([]);
    const [optionSelect1, setOptionSelect1] = useState("");
    const [CanBeSubAccount, setCanBeSubAccount] = useState(false);
    const [CanHaveMoreAccount, setCanHaveMoreAccount] = useState(false);
    const [ContainCurrency, setContainCurrency] = useState(false);
    const [ContainAccountNumber, setContainAccountNumber] = useState(false);
    const [msg, setMsg] = useState("");

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        description: "",
        can_be_sub_account: CanBeSubAccount,
        contain_account_number: ContainAccountNumber,
        contain_currency: ContainCurrency,
        tool_tip_description: "",
        can_have_more_than_one_chart_of_account: CanHaveMoreAccount,
        code: "",
        type: "",
        account_category_id: null,
    };

    const FORM_VALIDATION = Yup.object().shape({
        code: Yup.string().min(1).required("*Required"),
        type: Yup.string().min(1).required("*Required"),
        description: Yup.string().min(1),
        can_be_sub_account: Yup.string().min(1),
        contain_account_number: Yup.string().min(1),
        contain_currency: Yup.string().min(1),
        tool_tip_description: Yup.string().min(1),
        can_have_more_than_one_chart_of_account: Yup.string().min(1),
        // account_category_id: Yup.number().min(1).required("*Required"),
    });

    useEffect(() => {
        get(AccountCategoryURL, {
            limit: 10,
            filter: {
                code: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.code}`
                    );
                });
            setOptions1(data);
        });
    }, [filter]);

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        caller(AccountTypeURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                actions.resetForm();
                return 1;
            }
        });
    };

    return (
        <div>
            <TitleHeader title="Add Account Type" icon={<AddRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        values.can_be_sub_account = CanBeSubAccount;
                        values.can_have_more_than_one_chart_of_account =
                            CanHaveMoreAccount;
                        values.contain_account_number = ContainAccountNumber;
                        values.contain_currency = ContainCurrency;
                        values.account_category_id = parseInt(
                            optionSelect1?.split("-")[0]
                        );

                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>Can Be Sub Account</FormLabel>
                                    <Checkbox
                                        name="can_be_sub_account"
                                        checked={CanBeSubAccount}
                                        onChange={(event) => {
                                            setCanBeSubAccount(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>
                                        Contain Account Number
                                    </FormLabel>
                                    <Checkbox
                                        name="contain_account_number"
                                        checked={ContainAccountNumber}
                                        onChange={(event) => {
                                            setContainAccountNumber(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>Contain Currency</FormLabel>
                                    <Checkbox
                                        name="contain_currency"
                                        checked={ContainCurrency}
                                        onChange={(event) => {
                                            setContainCurrency(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="tool_tip_description"
                                    label="Tool Tip Description"
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>
                                        Can Have More Than One Chart Of Account
                                    </FormLabel>
                                    <Checkbox
                                        name="can_have_more_than_one_chart_of_account"
                                        checked={CanHaveMoreAccount}
                                        onChange={(event) => {
                                            setCanHaveMoreAccount(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="code"
                                    label="Code"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="type"
                                    label="Type"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    loading={options1.length < 1 ? true : false}
                                    disablePortal
                                    options={options1}
                                    onChange={(event, newValue) => {
                                        setOptionSelect1(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Account Category"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldWrapper
                                    name="description"
                                    label="Description"
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default AddAccountType;
