import React, { useState, useEffect } from "react";
import { Box, Card, LinearProgress, Typography } from "@mui/material";
import { ManpowerRequirementURL } from "../../../utils/config";
import { get } from "../../../utils/Caller";

const ManpowerOriginalPlan = ({ id }) => {
    const [manpowerOriginal, setManpowerOriginal] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        get(ManpowerRequirementURL, {
            limit: 100,
            filter: { project_id: id },
        }).then((res) => {
            setLoading(false);
            if (res?.data) {
                setManpowerOriginal(res.data);
            }
        });
    }, [id]);

    const columns = [
        {
            name: "remark",
            label: "Remark",
        },
        {
            name: "number_of_actual_man_power",
            label: "Number Of Actual Manpower",
        },
        {
            name: "number_of_planned_man_power",
            label: "Number Of Planned Manpower",
        },
        {
            name: "food_expense",
            label: "Food Expense",
        },
        {
            name: "wage",
            label: "Wage",
        },
        {
            name: "house_rent",
            label: "House Rent",
        },
        {
            name: "transportation",
            label: "Transportation",
        },
        {
            name: "total_expense",
            label: "Total Expense",
        },
    ];

    return (
        <Box>
            {manpowerOriginal.map((manpower, index) => (
                <Card key={index} style={{ padding: "4ch" }}>
                    {loading && <LinearProgress />}
                    {columns.map((column, i) => (
                        <Box
                            key={i}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography>{column.label}</Typography>
                            <Typography>{manpower[column.name]}</Typography>
                        </Box>
                    ))}
                </Card>
            ))}
        </Box>
    );
};

export default ManpowerOriginalPlan;
