import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import AddWeatherData from "./AddWeatherData.page";
import ViewWeatherData from "./ViewWeatherData.page";

const WeatherData = ({ next, name, handleTabChange }) => {
    const location = useLocation();
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    return (
        <>
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/WeatherData/AddWeatherData` && (
                <AddWeatherData
                    next={next}
                    name={name}
                    handleTabChange={handleTabChange}
                />
            )}
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/WeatherData` && (
                <>
                    <ViewWeatherData />
                </>
            )}
        </>
    );
};

export default WeatherData;
