import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { DurationMeasureURL } from "../../../../utils/config";
import { SnackBar } from "../../../../Components/SnackBar/SnackBar.component.jsx";
import { caller } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import { Add, AddRounded } from "@mui/icons-material";
import { Renderlist } from "../../../../Components/RenderList/RenderList.component";

const AddDurationMeasure = () => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setMsg] = useState("");
    const [listLength, setlistLength] = useState([0]);

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        list: [
            {
                measure: "",
            },
        ],
    };

    const FORM_VALIDATION = Yup.object().shape({
        list: Yup.array().of(
            Yup.object().shape({
                measure: Yup.string().min(1).required("*Required"),
            })
        ),
    });

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        caller(DurationMeasureURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                actions.resetForm();
                return 1;
            }
        });
    };

    const list = (key) => {
        return (
            <Grid container spacing={2} key={key}>
                <Grid item xs={6}>
                    <TextFieldWrapper
                        name={`list.${key}.measure`}
                        label="Measure"
                        variant="outlined"
                    />
                </Grid>
            </Grid>
        );
    };

    const addlist = () => {
        const max = Math.max(...listLength);
        setlistLength([...listLength, max + 1]);
    };

    const setListSize = (theRest) => {
        setlistLength([...theRest]);
    };

    return (
        <div>
            <TitleHeader title="Add Duration Measure" icon={<AddRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;

                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <>
                                <Box
                                    style={{
                                        margin: "2ch 0ch 0ch 2ch",
                                        width: "100%",
                                    }}
                                >
                                    {listLength.map((i, index) => {
                                        return (
                                            <Renderlist
                                                label="Duration Measure"
                                                i={i}
                                                key={index}
                                                listLength={listLength}
                                                setListSize={setListSize}
                                                list={list}
                                            />
                                        );
                                    })}
                                    <Button
                                        endIcon={<Add />}
                                        onClick={() => {
                                            addlist();
                                        }}
                                        style={{ margin: "2ch" }}
                                    >
                                        Add Another DurationMeasure
                                    </Button>
                                </Box>
                            </>

                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default AddDurationMeasure;
