import { Add, InsertDriveFile, SearchRounded } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import {
    Button,
    InputAdornment,
    TextField,
    IconButton,
    Modal,
    Box,
    Paper,
    FormControl,
    FormLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";

import Table from "../../../../Components/Table/Table.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { get } from "../../../../utils/Caller";
import {
    CashPaymentCustomExcelURL,
    CashPaymentCustomURL,
} from "../../../../utils/config";
import * as Yup from "yup";
import { SnackBar } from "../../../../Components/SnackBar/SnackBar.component";

const ViewCashPaymentCustom = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [open, setOpen] = useState(false);
    const [loadingFile, setloadingFile] = useState(false);
    const [limit, setLimit] = useState(20);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState();
    const [Search, setSearch] = useState();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setMsg] = useState("");
    // const [excelFiles, setExcelFiles] = useState([]);

    const columns = [
        {
            field: "date",
            headerName: "Date",
            width: 150,
        },
        {
            field: "customer",
            headerName: "Customer",
            width: 150,
        },
        {
            field: "amount",
            headerName: "Amount",
            width: 150,
        },
        {
            field: "tin_number",
            headerName: "Tin Number",
            width: 150,
        },
        {
            field: "tax",
            headerName: "Tax",
            width: 150,
        },
        {
            field: "withholding",
            headerName: "Withholding",
            width: 150,
        },
        {
            field: "merchant_record_card_code",
            headerName: "Merchant Record Card Code",
            width: 150,
        },
        {
            field: "number",
            headerName: "Number",
            width: 150,
        },
    ];

    const INITIAL_VALUES = {
        from: "",
        to: "",
    };

    const FORM_VALIDATION = Yup.object().shape({
        from: Yup.string().min(2).required("*Required"),
        to: Yup.string().min(2).required("*Required"),
    });

    const formSubmitHandler = (data, actions) => {
        setloadingFile(true);
        get(CashPaymentCustomExcelURL, data).then((res) => {
            setloadingFile(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data) {
                toExcel(res.data.data);
                setSuccess(true);
                setError(false);
                actions.resetForm();
            }
        });
    };

    const toExcel = (pdfData) => {
        const strPdf = pdfData;
        const buff = Buffer.from(strPdf);
        const excelFile = URL.createObjectURL(
            new Blob([buff], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64",
            })
        );
        window.open(excelFile, "_blank");
    };

    useEffect(() => {
        let tableData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(CashPaymentCustomURL, {
            limit: limit,
            filter: { all: filter },
        }).then((res) => {
            // console.log(res);
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    tableData.push({
                        ...data,
                    });
                });
            setRows(tableData);
        });
    }, [refreshTable, filter, limit, rows.length]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setloading(true);
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <div style={{ display: "flex", gap: "2ch" }}>
                    <LoadingButton
                        endIcon={<InsertDriveFile />}
                        loading={loadingFile}
                        onClick={() => {
                            setOpen(true);
                        }}
                        variant={"contained"}
                    >
                        Get Excel
                    </LoadingButton>
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<Add />}
                        onClick={() => {
                            navigate(
                                "/View/Finance/CashPaymentCustom/AddCashPaymentCustom"
                            );
                        }}
                    >
                        Add Cash Payment Custom
                    </Button>
                </div>
            </div>
            <div className="TableContainer">
                <Table
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                />
            </div>
            <Modal
                className="Modal"
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Paper style={{ padding: "5ch" }}>
                        <Formik
                            initialValues={{ ...INITIAL_VALUES }}
                            validationSchema={FORM_VALIDATION}
                            onSubmit={(values, actions) => {
                                formSubmitHandler(values, actions);
                            }}
                        >
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <FormLabel>From Date</FormLabel>
                                            <TextFieldWrapper
                                                name="from"
                                                type="date"
                                                variant="outlined"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <FormLabel>To Date</FormLabel>
                                            <TextFieldWrapper
                                                name="to"
                                                type="date"
                                                variant="outlined"
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <div>
                                    <LoadingButton
                                        data-testid="AddButton"
                                        loading={loadingFile}
                                        type="submit"
                                        className="ButtonContained"
                                        style={{
                                            marginTop: "2ch",
                                            float: "right",
                                        }}
                                    >
                                        {loading ? null : "Get Excel"}
                                    </LoadingButton>
                                </div>
                            </Form>
                        </Formik>
                    </Paper>
                </Box>
            </Modal>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};

export default ViewCashPaymentCustom;
