import { Add, SearchRounded } from "@mui/icons-material";
import { Button, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import Table from "../../../../Components/Table/Table.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { setSelected } from "../../../../Redux/Slice/selectedRow";
import { get } from "../../../../utils/Caller";
import { VacancyURL } from "../../../../utils/config";

const VACANCY_STATUS = [
    "Draft",
    "Approved",
    "Rejected",
    "Published",
    "Closed",
    "Locked",
];

const VACANCY_TYPE = ["Internal", "External", "Both"];

const ViewVacancy = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(20);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState();
    const [Search, setSearch] = useState();

    const columns = [
        {
            field: "number_of_position",
            headerName: "Number Of Position",
            width: 150,
        },
        {
            field: "vacancyStatus",
            headerName: "Vacancy Status",
            width: 150,
        },
        {
            field: "opening_date",
            headerName: "Opening Date",
            width: 150,
        },
        {
            field: "closing_date",
            headerName: "Closing Date",
            width: 150,
        },
        {
            field: "action_date",
            headerName: "Action Date",
            width: 150,
        },
        {
            field: "vacancyType",
            headerName: "Vacancy Type",
            width: 150,
        },
        {
            field: "requested_by",
            headerName: "Requested By",
            width: 150,
        },
        {
            field: "approved_by",
            headerName: "Approved By",
            width: 150,
        },
        {
            field: "remark",
            headerName: "Remark",
            width: 150,
        },
        {
            field: "jobTitle",
            headerName: "Job Title",
            width: 150,
        },
        {
            field: "vacancyRequestReason",
            headerName: "Vacancy Request Reason",
            width: 150,
        },
    ];

    useEffect(() => {
        let tableData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(VacancyURL, {
            limit: limit,
            filter: { all: filter },
        }).then((res) => {
            // console.log(res);
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    tableData.push({
                        ...data,
                        jobTitle: data.job_title?.title_name,
                        vacancyRequestReason:
                            data.vacancy_request_reason?.reason,
                        vacancyStatus: VACANCY_STATUS[data.vacancy_status - 1],
                        vacancyType: VACANCY_TYPE[data.vacancy_type - 1],
                    });
                });
            setRows(tableData);
        });
    }, [refreshTable, filter, limit, rows.length]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    const ViewVacancy = (selectedVacancy) => {
        dispatch(setSelected(selectedVacancy));
        navigate(`/View/HCM/Vacancy/${selectedVacancy?.id}/`);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <SearchRounded
                                    onClick={(e) => {
                                        setloading(true);
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <div style={{ display: "flex", gap: "2ch" }}>
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<Add />}
                        onClick={() => {
                            navigate("/View/HCM/Vacancy/AddVacancy");
                        }}
                    >
                        Add Vacancy
                    </Button>
                </div>
            </div>
            <div className="TableContainer">
                <Table
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                    ViewVacancy={ViewVacancy}
                />
            </div>
        </div>
    );
};

export default ViewVacancy;
