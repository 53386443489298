import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import AddSubTask from "../SubTask/AddSubTask.page";
import AddTaskManager from "./AddTaskManager.page";
import ViewTaskManager from "./ViewTaskManager.page";

const TaskManager = ({ next, name, handleTabChange }) => {
    const location = useLocation();
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    return (
        <>
            {(location.pathname ===
                `/View/ViewProject/ProjectAddDetail/AddProjectTask` ||
                location.pathname ===
                    `/View/ViewProject/${selectedRow.id}/TaskManager/AddTaskManager`) && (
                <AddTaskManager
                    next={next}
                    name={name}
                    handleTabChange={handleTabChange}
                />
            )}
            {(location.pathname ===
                `/View/ViewProject/ProjectAddDetail/AddProjectTask/TaskManager` ||
                location.pathname ===
                    `/View/ViewProject/${selectedRow.id}/TaskManager`) && (
                <>
                    <ViewTaskManager />
                </>
            )}
            {(location.pathname ===
                `/View/ViewProject/ProjectAddDetail/AddProjectTask/TaskManager/AddSubTask` ||
                location.pathname ===
                    `/View/ViewProject/${selectedRow.id}/TaskManager/AddSubTask`) && (
                <AddSubTask />
            )}
        </>
    );
};

export default TaskManager;
