import { Add, SearchRounded } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import Table from "../../../../Components/Table/Table.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { get } from "../../../../utils/Caller";
import { JobTitleURL } from "../../../../utils/config";

const ViewJobTitle = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(20);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState();
    const [Search, setSearch] = useState();

    const columns = [
        {
            field: "title_name",
            headerName: "Title Name",
            width: 150,
        },
        {
            field: "job_specification",
            headerName: "Job Specification",
            width: 150,
        },
        {
            field: "min_experience",
            headerName: "Min Experience",
            width: 150,
        },
        {
            field: "paygradeName",
            headerName: "Paygrade",
            width: 150,
        },
        {
            field: "trainingType",
            headerName: "Training Type",
            width: 150,
        },
        {
            field: "jobCategory",
            headerName: "Job Category",
            width: 150,
        },
        {
            field: "safetyEquipment",
            headerName: "Safety Equipment",
            width: 150,
        },
    ];

    useEffect(() => {
        let jobTitleData = [];
        let safetyEquipment = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(JobTitleURL, {
            limit: limit,
            filter: { all: filter },
        }).then((res) => {
            // console.log(res);
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    data.job_safety_equipment.forEach((equipment, i) => {
                        safetyEquipment.push(
                            `${equipment.safety_equipment?.id} - ${equipment.safety_equipment?.name}`
                        );
                    });
                    jobTitleData.push({
                        ...data,
                        jobCategory: data.job_category?.category_description,
                        trainingType: data.training_type?.training_type_name,
                        paygradeName: data.paygrade?.paygrade_name,
                        safetyEquipment: String(safetyEquipment),
                    });
                    safetyEquipment = [];
                });
            setRows(jobTitleData);
        });
    }, [refreshTable, filter, limit, rows.length]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setloading(true);
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <div style={{ display: "flex", gap: "2ch" }}>
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<Add />}
                        onClick={() => {
                            navigate("/View/HCM/JobTitle/AddJobTitle");
                        }}
                    >
                        Add Job Title
                    </Button>
                </div>
            </div>
            <div className="TableContainer">
                <Table
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                />
            </div>
        </div>
    );
};

export default ViewJobTitle;
