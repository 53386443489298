import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";

import TextFieldWrapper from "../../../Components/TextFieldWrapper.component";
import "../../../Assets/Sass/AddProject.style.scss";
import { MaterialCategoryURL, MaterialURL } from "../../../utils/config";
import { SnackBar } from "../../../Components/SnackBar/SnackBar.component.jsx";
import { caller, get } from "../../../utils/Caller";
import TitleHeader from "../../../Components/TitleHeader/TitleHeader.component";
import { Add, AddRounded } from "@mui/icons-material";
import { Renderlist } from "../../../Components/RenderList/RenderList.component";

const AddMaterial = (props) => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState("");
    const [options, setOptions] = useState([]);
    const [optionSelect, setOptionSelect] = useState("");
    const [msg, setMsg] = useState("");
    const [listLength, setlistLength] = useState([0]);

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        list: [
            {
                name: "",
                description: "",
                unit: "",
                material_category_id: "",
            },
        ],
    };

    const FORM_VALIDATION = Yup.object().shape({
        list: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required("*Required"),
                unit: Yup.string().required("*Required"),
                description: Yup.string(),
            })
        ),
    });

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        caller(MaterialURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                actions.resetForm();
            }
        });
    };

    useEffect(() => {
        get(MaterialCategoryURL, {
            limit: 10,
            filter: {
                name: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index].id} - ${res.data[index].name}`
                    );
                });
            setOptions(data);
        });
    }, [filter]);

    const list = (key) => {
        return (
            <Grid container spacing={2} key={key}>
                <Grid item xs={6}>
                    <TextFieldWrapper
                        name={`list.${key}.name`}
                        label="Material Name"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldWrapper
                        name={`list.${key}.unit`}
                        label="Unit"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        name="material_category_id"
                        loading={options.length < 1 ? true : false}
                        disablePortal
                        options={options}
                        onChange={(event, newValue) => {
                            setOptionSelect({
                                ...optionSelect,
                                [key]: newValue,
                            });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Material Category"
                                onChange={(event) => {
                                    setFilter(event.target.value);
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextFieldWrapper
                        name={`list.${key}.description`}
                        label="Description"
                        variant="outlined"
                        multiline
                        rows={4}
                    />
                </Grid>
            </Grid>
        );
    };

    const addlist = () => {
        const max = Math.max(...listLength);
        setlistLength([...listLength, max + 1]);
    };

    const setListSize = (theRest) => {
        setlistLength([...theRest]);
    };

    return (
        <div>
            <TitleHeader title="Add Material" icon={<AddRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;

                        listLength.forEach((data) => {
                            values.list[data].material_category_id = parseInt(
                                optionSelect[data]?.split("-")[0]
                            );
                        });
                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <>
                            <Box
                                style={{
                                    margin: "2ch 0ch 0ch 2ch",
                                    width: '100%'
                                }}
                            >
                                {listLength.map((i, index) => {
                                    return (
                                        <Renderlist
                                            label={"Material"}
                                            i={i}
                                            key={index}
                                            listLength={listLength}
                                            setListSize={setListSize}
                                            list={list}
                                        />
                                    );
                                })}
                                <Button
                                    endIcon={<Add />}
                                    onClick={() => {
                                        addlist();
                                    }}
                                    style={{ margin: "2ch" }}
                                >
                                    Add Another Material
                                </Button>
                            </Box>
                        </>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};

export default AddMaterial;
