import { PrintRounded, CancelRounded } from "@mui/icons-material";
import {
    Typography,
    Paper,
    TableCell,
    Table,
    TableRow,
    TableHead,
    TableBody,
    IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { CompanyURL } from "../../../../utils/config";
import { baseURL, get } from "../../../../utils/Caller";

const PVRequestPrint = () => {
    const { state } = useLocation();
    const { column, rowsDetail, fieldName } = state;
    const [load, setLoad] = useState(false);
    const [header, setHeader] = useState();
    const [footer, setFooter] = useState();
    console.log(rowsDetail);
    useEffect(() => {
        get(CompanyURL, {
            limit: 1,
        }).then((res) => {
            res &&
                res.data.forEach((data, index) => {
                    setHeader(data?.header);
                    setFooter(data?.footer);
                });
        });
    }, []);

    useEffect(() => {
        let retry = setInterval(() => {
            if (load) {
                window.print();
                clearInterval(retry);
            }
        }, 1000);
    }, [load]);

    return (
        <div className="Print">
            <Box
                className="hide-on-print"
                style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                }}
            >
                <IconButton
                    onClick={() => {
                        window.print();
                    }}
                >
                    <PrintRounded />
                </IconButton>
                <IconButton
                    onClick={() => {
                        window.history.back();
                    }}
                >
                    <CancelRounded color="error" />
                </IconButton>
            </Box>

            <Box width={"100%"} style={{ objectFit: "scale-down" }}>
                <img
                    src={baseURL + header}
                    alt=""
                    width={"100%"}
                    onLoad={() => {
                        setLoad(true);
                    }}
                />
            </Box>
            <Paper
                style={{
                    minHeight: "50vh",
                    padding: "2ch",
                }}
                elevation={0}
            >
                <Table>
                    <TableHead>
                        {column?.map((head, i) => (
                            <TableCell key={i}>{head.headerName}</TableCell>
                        ))}
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            {fieldName.map((field) => (
                                <TableCell>{rowsDetail[0][field]}</TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "2ch",
                    }}
                >
                    <Typography fontWeight={"bolder"}>Prepared By -</Typography>
                    <Typography>
                        {`${rowsDetail[0]?.prepared_by?.first_name} ${rowsDetail[0]?.prepared_by?.last_name}`}
                    </Typography>
                </Box>
            </Paper>
            <Box
                width={"100%"}
                style={{
                    objectFit: "scale-down",
                    position: "absolute",
                    bottom: 0,
                }}
            >
                <img src={baseURL + footer} alt="" width={"100%"} />
            </Box>
        </div>
    );
};

export default PVRequestPrint;
