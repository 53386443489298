import {
    Add,
    SearchRounded,
    ChevronLeft,
    PrintRounded,
} from "@mui/icons-material";
import {
    Box,
    Button,
    Checkbox,
    Divider,
    IconButton,
    InputAdornment,
    LinearProgress,
    TextField,
    Typography,
    Modal,
    Paper,
    List,
    ListItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
    SubTaskURL,
    ToDoURL,
    DailyReportURL,
    ReportRemarkURL,
} from "../../../../utils/config";

import { caller, get, patcher } from "../../../../utils/Caller";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { SnackBar } from "../../../../Components/SnackBar/SnackBar.component";

const ViewDailyWorkLog = () => {
    const [rows, setRows] = useState([]);
    const [reportRows, setReportRows] = useState([]);
    const [selectedReport, setSelectedReport] = useState();
    const [loadingSave, setloadingSave] = useState(false);
    const [loading, setloading] = useState(false);
    const [filter, setFilter] = useState();
    // const [filter2, setFilter2] = useState();
    const [Search, setSearch] = useState("");
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const user = useSelector((state) => state.userState.user);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [remark, setRemark] = useState("");
    const [remarkLoad, setRemarkLoad] = useState("");
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setMsg] = useState("");

    const closeModal = () => {
        setOpen(false);
    };

    useEffect(() => {
        let taskData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(SubTaskURL, {
            limit: 100,
            filter: {
                all: filter,
            },
        }).then((res) => {
            setloading(false);
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    if (data.task_manager?.project_id === selectedRow.id) {
                        taskData.push({
                            ...data,
                            mainTask: data.task_manager?.name,
                        });
                    }
                });
            setRows(taskData);
        });
        get(DailyReportURL, {
            limit: 1000,
            filter: {
                project_id: selectedRow.id,
                // all: filter2,
            },
        }).then((res) => {
            res && res.data && setloading(false);
            res && setReportRows(res.data);
        });
    }, [refreshTable, rows.length, selectedRow, filter]);

    const checkedApi = (id, completed) => {
        setloadingSave(true);
        const update = {
            id: id,
            updateData: { completed: completed },
        };
        patcher(ToDoURL, update).then((res) => {
            setloadingSave(false);
            dispatch(setRefresh(!refreshTable));
        });
    };

    const addRemark = (id) => {
        setRemarkLoad(true);
        caller(ReportRemarkURL, { daily_report_id: id, report: remark }).then(
            (res) => {
                setRemarkLoad(false);
                // console.log(res.error);
                if (res.error) {
                    Object.keys(res.error).forEach((data, i) => {
                        setMsg(res.error[data]);
                    });
                    setSuccess(false);
                    setError(true);
                } else if (res.data.success) {
                    setSuccess(true);
                    setError(false);
                }
            },
        );
    };

    return (
        <div className="ViewProject">
            {loading && <LinearProgress />}
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <div style={{ display: "flex", gap: "2ch" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            dispatch(setRefresh(!refreshTable));
                            setOpen(true);
                        }}
                    >
                        View Daily Report
                    </Button>
                    {user.actions?.indexOf("HEAD") === -1 &&
                        (user.actions?.indexOf("PROJECT_ONE") !== -1 ||
                            user.actions?.indexOf("PROJECT_TWO") !== -1) && (
                            <Button
                                variant="contained"
                                color="success"
                                endIcon={<Add />}
                                onClick={() => {
                                    navigate(
                                        `/View/ViewProject/${selectedRow.id}/DailyWorkLog/AddDailyWorkLog`,
                                    );
                                }}
                            >
                                Add Daily Report
                            </Button>
                        )}
                </div>
            </div>
            <div
                style={{
                    backgroundColor: "white",
                    padding: "2ch",
                    borderRadius: "5px",
                    boxShadow: "0px 0px 10px #e6e6e6",
                    minHeight: "80vh",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridGap: "2ch",
                }}
            >
                <div
                    style={{ border: "solid 1px silver", borderRadius: "5px" }}
                >
                    <Typography
                        sx={{ p: 1, backgroundColor: "#e5e5e5" }}
                        fontWeight={"bolder"}
                    >
                        On Going
                    </Typography>
                    <Divider fullWidth />
                    {rows
                        .filter(
                            (value) =>
                                new Date(value.task_start_date).getTime() <=
                                    new Date().getTime() && value,
                        )
                        .filter((value) => {
                            return (
                                value.todos.filter(
                                    (v) => v.completed === false && v,
                                ).length > 0 && value
                            );
                        })
                        .map((sub) => {
                            return (
                                <Box width={"100%"}>
                                    <Box
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography variant="h5" padding={2}>
                                            {sub.mainTask}
                                        </Typography>

                                        {new Date(sub.task_end_date).getTime() <
                                            new Date().getTime() - 86404021 && (
                                            <Typography
                                                variant={"subtitle"}
                                                style={{
                                                    color: "white",
                                                    backgroundColor: "#f7562e",
                                                    padding:
                                                        "5px 10px 5px 10px",
                                                    borderRadius: "50px",
                                                    fontWeight: "bold",
                                                    marginRight: "2ch",
                                                }}
                                            >
                                                Overdue
                                            </Typography>
                                        )}
                                    </Box>

                                    <Divider variant="fullWidth" />
                                    <Typography variant="h6">
                                        {sub.name}
                                    </Typography>
                                    <Divider />
                                    <Box height={"2ch"} />
                                    {loadingSave && <LinearProgress />}
                                    {sub.todos
                                        .filter(
                                            (value) =>
                                                value.completed !== true &&
                                                value,
                                        )
                                        .map((todo) => {
                                            return (
                                                <Box
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        marginLeft: "2ch",
                                                    }}
                                                >
                                                    {user.actions?.indexOf(
                                                        "HEAD",
                                                    ) === -1 &&
                                                        (user.actions?.indexOf(
                                                            "PROJECT_ONE",
                                                        ) !== -1 ||
                                                            user.actions?.indexOf(
                                                                "PROJECT_TWO",
                                                            ) !== -1) && (
                                                            <Checkbox
                                                                defaultChecked={
                                                                    todo.completed
                                                                }
                                                                onChange={(
                                                                    event,
                                                                ) => {
                                                                    checkedApi(
                                                                        todo.id,
                                                                        event
                                                                            .target
                                                                            .checked,
                                                                    );
                                                                }}
                                                            />
                                                        )}

                                                    <Typography
                                                        style={{
                                                            textDecoration:
                                                                todo.completed
                                                                    ? "line-through"
                                                                    : "none",
                                                        }}
                                                    >
                                                        {todo.name}
                                                    </Typography>
                                                </Box>
                                            );
                                        })}
                                </Box>
                            );
                        })}
                </div>
                <div
                    style={{ border: "solid 1px silver", borderRadius: "5px" }}
                >
                    <Typography
                        sx={{ p: 1, backgroundColor: "#e5e5e5" }}
                        fontWeight={"bolder"}
                    >
                        Completed
                    </Typography>
                    <Divider fullWidth />
                    {rows
                        .filter((value) => {
                            return (
                                value.todos.filter(
                                    (v) =>
                                        v?.completed === true &&
                                        !v?.daily_report_id &&
                                        v,
                                ).length > 0 && value
                            );
                        })
                        .map((sub) => {
                            return (
                                <Box width={"100%"}>
                                    <Typography variant="h5" padding={2}>
                                        {sub.mainTask}
                                    </Typography>
                                    <Divider variant="fullWidth" />
                                    <Typography variant="h6">
                                        {sub.name}
                                    </Typography>
                                    <Divider />
                                    <Box height={"2ch"} />
                                    {loadingSave && <LinearProgress />}
                                    {sub.todos
                                        .filter(
                                            (value) =>
                                                value.completed === true &&
                                                !value?.daily_report_id &&
                                                value,
                                        )
                                        .map((todo) => (
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    marginLeft: "2ch",
                                                }}
                                            >
                                                {user.actions?.indexOf(
                                                    "HEAD",
                                                ) === -1 && (
                                                    <Checkbox
                                                        checked={todo.completed}
                                                        onChange={(event) => {
                                                            checkedApi(
                                                                todo.id,
                                                                event.target
                                                                    .checked,
                                                            );
                                                        }}
                                                    />
                                                )}
                                                <Typography
                                                    style={{
                                                        textDecoration:
                                                            todo.completed
                                                                ? "line-through"
                                                                : "none",
                                                    }}
                                                >
                                                    {todo.name}
                                                </Typography>
                                            </Box>
                                        ))}
                                </Box>
                            );
                        })}
                </div>
            </div>
            <Modal className="Modal" open={open} onClose={closeModal}>
                <Box
                    className="ModalContainer"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minWidth: "80%",
                        maxWidth: "90%",
                        width: "70%",
                        height: "80vh",
                    }}
                >
                    <Paper
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "2ch",
                            height: "100%",
                            width: "100%",
                            padding: "5ch",
                            overflow: "scroll",
                        }}
                    >
                        <Box
                            style={{
                                width: "100%",
                                position: "sticky",
                                top: 0,
                                backdropFilter: "blur(10)",
                                height: "10ch",
                            }}
                        >
                            {page >= 2 && (
                                <Button
                                    startIcon={<ChevronLeft />}
                                    onClick={() => {
                                        if (page === 2) {
                                            setPage(1);
                                        }
                                    }}
                                >
                                    Back
                                </Button>
                            )}
                        </Box>
                        {page === 1 && (
                            <List>
                                {reportRows.length <= 0 && (
                                    <Box
                                        width={"100%"}
                                        height={"100%"}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Typography
                                            variant={"h2"}
                                            style={{
                                                fontWidth: "800",
                                                color: "#e5e5e5",
                                            }}
                                        >
                                            No Report
                                        </Typography>
                                    </Box>
                                )}
                                {reportRows?.map((report, index) => (
                                    <ListItem
                                        style={{
                                            display: "flex",
                                            borderRadius: "5px",
                                            boxShadow: "0 0 15px #e5e5e5",
                                            justifyContent: "space-between",
                                            padding: "2ch",
                                            width: "100%",
                                            cursor: "pointer",
                                            marginBottom: "2ch",
                                            minWidth: "300px",
                                            maxHeight: "80vh",
                                        }}
                                        onClick={(e) => {
                                            setSelectedReport(report);
                                            setPage(2);
                                        }}
                                    >
                                        <Box>
                                            <Typography fontWeight={"bold"}>
                                                {`${report.prepared_by?.first_name} ${report.prepared_by?.last_name}`}
                                            </Typography>
                                            <Typography>
                                                {new Date(
                                                    report.date,
                                                ).toDateString()}
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                ))}
                            </List>
                        )}
                        {page === 2 && (
                            <List>
                                <ListItem
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "2ch",
                                    }}
                                >
                                    <Typography fontWeight={"bold"}>
                                        Date -
                                    </Typography>
                                    <Typography>
                                        {new Date(
                                            selectedReport.date,
                                        ).toDateString()}
                                    </Typography>
                                </ListItem>
                                <Divider variant="middle" />
                                <ListItem
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "2ch",
                                    }}
                                >
                                    <Typography fontWeight={"bold"}>
                                        submitted Date -
                                    </Typography>
                                    <Typography>
                                        {new Date(
                                            selectedReport.creationDate,
                                        ).toDateString()}
                                    </Typography>
                                </ListItem>
                                <Divider variant="middle" />
                                <ListItem
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "2ch",
                                    }}
                                >
                                    <Typography fontWeight={"bold"}>
                                        Activities Performed -
                                    </Typography>
                                    <Typography>
                                        {selectedReport.activities_performed}
                                    </Typography>
                                </ListItem>
                                <Divider variant="middle" />
                                <ListItem
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "2ch",
                                    }}
                                >
                                    <Typography fontWeight={"bold"}>
                                        Material Delivered -
                                    </Typography>
                                    <Typography>
                                        {selectedReport.material_delivered}
                                    </Typography>
                                </ListItem>
                                <Divider variant="middle" />
                                <ListItem
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "2ch",
                                    }}
                                >
                                    <Typography fontWeight={"bold"}>
                                        Available Machine On Site -
                                    </Typography>
                                    <Typography>
                                        {
                                            selectedReport.available_machine_on_site
                                        }
                                    </Typography>
                                </ListItem>
                                <Divider variant="middle" />
                                <ListItem
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "2ch",
                                    }}
                                >
                                    <Typography fontWeight={"bold"}>
                                        Problem Encountered -
                                    </Typography>
                                    <Typography>
                                        {selectedReport.problem_encountered}
                                    </Typography>
                                </ListItem>
                                <Divider variant="middle" />
                                <ListItem
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "2ch",
                                    }}
                                >
                                    <Typography fontWeight={"bold"}>
                                        No Of Labours -
                                    </Typography>
                                    <Typography>
                                        {selectedReport.no_of_labours}
                                    </Typography>
                                </ListItem>
                                <Divider variant="middle" />
                                <ListItem
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "2ch",
                                    }}
                                >
                                    <Typography fontWeight={"bold"}>
                                        Remark -
                                    </Typography>
                                    <Typography>
                                        {selectedReport.remark}
                                    </Typography>
                                </ListItem>
                                <Divider variant="middle" />
                                {Array.isArray(selectedReport.report_remarks) &&
                                    selectedReport.report_remarks.length >
                                        0 && (
                                        <>
                                            <ListItem>
                                                <Typography
                                                    fontWeight={"bolder"}
                                                >
                                                    Remarks
                                                </Typography>
                                            </ListItem>
                                            {selectedReport.report_remarks.map(
                                                (remark2) => (
                                                    <>
                                                        <ListItem
                                                            style={{
                                                                display: "flex",
                                                                flexDirection:
                                                                    "row",
                                                                gap: "2ch",
                                                            }}
                                                        >
                                                            <Typography
                                                                fontWeight={
                                                                    "bold"
                                                                }
                                                            >
                                                                {`Remark By ${remark2.remark_written_by?.first_name} ${remark2.remark_written_by?.last_name}`}
                                                            </Typography>
                                                            <Typography>
                                                                {remark2.remark}
                                                            </Typography>
                                                        </ListItem>
                                                    </>
                                                ),
                                            )}
                                        </>
                                    )}
                                <Divider variant="middle" />
                                <ListItem
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "2ch",
                                    }}
                                >
                                    <Typography fontWeight={"bold"}>
                                        Project -
                                    </Typography>
                                    <Typography>
                                        {selectedReport.project?.name}
                                    </Typography>
                                </ListItem>
                                <Divider variant="middle" />
                                <ListItem
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "2ch",
                                    }}
                                >
                                    <Typography fontWeight={"bold"}>
                                        Todo -
                                    </Typography>
                                    <Typography>
                                        {selectedReport.todos.map(
                                            (todo) => todo.name,
                                        )}
                                    </Typography>
                                </ListItem>
                                <Divider variant="middle" />
                                <ListItem
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "2ch",
                                    }}
                                >
                                    <Typography fontWeight={"bold"}>
                                        Employee
                                    </Typography>
                                    <Typography>{`${selectedReport.prepared_by?.first_name} ${selectedReport.prepared_by?.last_name}`}</Typography>
                                </ListItem>
                                <Button
                                    style={{
                                        float: "right",
                                        marginTop: "2ch",
                                    }}
                                    endIcon={<PrintRounded />}
                                    onClick={() => {
                                        navigate(`/DailyReportPrint`, {
                                            state: {
                                                selectedReport,
                                            },
                                        });
                                    }}
                                >
                                    Print
                                </Button>

                                <TextField
                                    fullWidth
                                    variant="filled"
                                    label={"Remark"}
                                    name="action_note"
                                    multiline={3}
                                    rows={3}
                                    value={remark}
                                    onChange={(e) => {
                                        setRemark(e.target.value);
                                    }}
                                />
                                <LoadingButton
                                    loading={remarkLoad}
                                    variant="contained"
                                    color="success"
                                    onClick={() => {
                                        addRemark(selectedReport.id);
                                    }}
                                    style={{ marginTop: "2ch" }}
                                >
                                    Add Remark
                                </LoadingButton>

                                {error || success ? (
                                    <SnackBar
                                        error={error}
                                        success={success}
                                        message={
                                            error
                                                ? msg
                                                : `Success^${Math.random()}`
                                        }
                                    />
                                ) : null}
                            </List>
                        )}
                    </Paper>
                </Box>
            </Modal>
        </div>
    );
};

export default ViewDailyWorkLog;
