import ViewProject from "../Pages/Projects/ViewProject.page";
import AddProject from "../Pages/Projects/AddProject.Page";
import OperationalProjects from "../Pages/Projects/Operational/OperationalProjects.page";
import Material from "../Pages/MasterData/Material/Material.page";
import Equipment from "../Pages/MasterData/Equipment/Equipment.page";
import Evaluation from "../Pages/MasterData/Evaluation/Evaluation.page";
import Instruction from "../Pages/MasterData/Instruction/Instruction.page";
import Manpower from "../Pages/MasterData/Manpower/Manpower.page";
import MaterialCategory from "../Pages/MasterData/MaterialCategory/MaterialCategory.page";
import Phase from "../Pages/MasterData/Phase/Phase.page";
import WorkCategory from "../Pages/MasterData/WorkCategory/WorkCategory.page";
import Priority from "../Pages/MasterData/Priority/Priority.page";
import AddMaterial from "../Pages/MasterData/Material/AddMaterial.page";
import AddEquipment from "../Pages/MasterData/Equipment/AddEquipment.page";
import AddMaterialCategory from "../Pages/MasterData/MaterialCategory/AddMaterialCategory.page";
import AddEvaluation from "../Pages/MasterData/Evaluation/AddEvaluation.page";
import AddInstruction from "../Pages/MasterData/Instruction/AddInstruction.page";
import AddManpower from "../Pages/MasterData/Manpower/AddManpower.page";
import AddPhase from "../Pages/MasterData/Phase/AddPhase.page";
import AddWorkCategory from "../Pages/MasterData/WorkCategory/AddWorkCategory.page";
import AddPriority from "../Pages/MasterData/Priority/AddPriority.page";
import DailyWorkLog from "../Pages/Projects/Operational/DailyWorkLog/DailyWorkLog.page";
import InstructionGivenOnProject from "../Pages/Projects/Operational/InstructionGivenOnProject/IntructionGivenOnProject.page";
import AddDailyWorkLog from "../Pages/Projects/Operational/DailyWorkLog/AddDailyWorkLog.page";
import AddInstructionGiven from "../Pages/Projects/Operational/InstructionGivenOnProject/AddInstructionGiven.page";
import InvoiceTracking from "../Pages/Projects/Operational/InvoiceTracking/InvoiceTracking.page";
import AddInvoiceTracking from "../Pages/Projects/Operational/InvoiceTracking/AddInvoiceTracking.page";
import ManpowerRequirement from "../Pages/Projects/Operational/ManpowerRequirement/ManpowerRequirement.page";
import AddManpowerRequirement from "../Pages/Projects/Operational/ManpowerRequirement/AddManpowerRequirement.page";
import Request from "../Pages/Projects/Operational/Request/Request.page";
import AddRequest from "../Pages/Projects/Operational/Request/AddRequest.page";
import RequiredEquipment from "../Pages/Projects/Operational/RequiredEquipment/RequiredEquipment.page";
import AddRequiredEquipment from "../Pages/Projects/Operational/RequiredEquipment/AddRequiredEquipment.page";
import RequiredMaterial from "../Pages/Projects/Operational/RequiredMaterial/RequiredMaterial.page";
import AddRequiredMaterial from "../Pages/Projects/Operational/RequiredMaterial/AddRequiredMaterial.page";
import RiskTracking from "../Pages/Projects/Operational/RiskTracking/RiskTracking.page";
import AddRiskTracking from "../Pages/Projects/Operational/RiskTracking/AddRiskTracking.page";
import SimpleTask from "../Pages/Projects/Operational/SimpleTask/SimpleTask.page";
import AddSimpleTask from "../Pages/Projects/Operational/SimpleTask/AddSimpleTask.page";
import ToDos from "../Pages/Projects/Operational/ToDos/ToDos.page";
import AddToDos from "../Pages/Projects/Operational/ToDos/AddToDos.page";
import WeatherData from "../Pages/Projects/Operational/WeatherData/WeatherData.page";
import AddWeatherData from "../Pages/Projects/Operational/WeatherData/AddWeatherData.page";
import TaskManager from "../Pages/Projects/Operational/TaskManager/TaskManager.page";
import AddTaskManager from "../Pages/Projects/Operational/TaskManager/AddTaskManager.page";
import AddSubTask from "../Pages/Projects/Operational/SubTask/AddSubTask.page";
import AddWorkLog from "../Pages/Projects/Operational/WorkLogAndBranchOfWork/AddWorkLog.page";
import RequiredDocumentation from "../Pages/Projects/Operational/RequiredDocumentation/RequiredDocumentation.page";
import AddRequiredDocumentation from "../Pages/Projects/Operational/RequiredDocumentation/AddRequiredDocumentation.page";
import Documentation from "../Pages/MasterData/Documentation/Documentation.page";
import AddDocumentation from "../Pages/MasterData/Documentation/AddDocumentation.page";
import AddDocumentCategory from "../Pages/MasterData/DocumentCategory/AddDocumentCategory.page";
import DocumentCategory from "../Pages/MasterData/DocumentCategory/DocumentCategory.page";
import { Route } from "react-router";
import RequestPayment from "../Pages/Projects/Operational/RequestPayment/RequestPayment.page";
import AddRequestPayment from "../Pages/Projects/Operational/RequestPayment/AddRequestPayment.page";
import ProjectAddDetail from "../Pages/Projects/ProjectAddDetail.page";
import AddProjectTask from "../Pages/Projects/AddProjectTask.page";
import AddRequestForm from "../Pages/Projects/Operational/RequestForm/AddRequestForm.page";
import RequestForm from "../Pages/Projects/Operational/RequestForm/RequestForm.page";

export const ProjectRoutes = [
    <Route path="ViewProject" element={<ViewProject />}>
        <Route path="ProjectAddDetail" element={<ProjectAddDetail />}>
            <Route
                path="AddProjectTask"
                element={
                    ((<AddProjectTask />),
                    (<TaskManager />),
                    (<ManpowerRequirement />),
                    (<RequiredEquipment />),
                    (<RequiredEquipment />),
                    (<RequiredMaterial />),
                    (<ToDos />))
                }
            >
                <Route path="TaskManager" element={<TaskManager />} />
                <Route
                    path="ManpowerRequirement"
                    element={<ManpowerRequirement />}
                />
                <Route
                    path="RequiredEquipment"
                    element={<RequiredEquipment />}
                />
                <Route path="RequiredMaterial" element={<RequiredMaterial />} />
                <Route path="ToDos" element={<ToDos />} />
            </Route>
        </Route>
        <Route path="DailyWorkLog" element={<DailyWorkLog />}>
            <Route path="AddDailyWorkLog" element={<AddDailyWorkLog />} />
            <Route path="AddWorkLogAndBranchOfWork" element={<AddWorkLog />} />
        </Route>
        <Route path="InstructionGiven" element={<InstructionGivenOnProject />}>
            <Route
                path="AddInstructionGiven"
                element={<AddInstructionGiven />}
            />
        </Route>
        <Route path="InvoiceTracking" element={<InvoiceTracking />}>
            <Route path="AddInvoiceTracking" element={<AddInvoiceTracking />} />
        </Route>
        <Route path="ManpowerRequirement" element={<ManpowerRequirement />}>
            <Route
                path="AddManpowerRequirement"
                element={<AddManpowerRequirement />}
            />
        </Route>
        <Route path="Request" element={<Request />}>
            <Route path="AddRequest" element={<AddRequest />} />
        </Route>
        <Route path="RequiredEquipment" element={<RequiredEquipment />}>
            <Route
                path="AddRequiredEquipment"
                element={<AddRequiredEquipment />}
            />
        </Route>
        <Route path="RequiredMaterial" element={<RequiredMaterial />}>
            <Route
                path="AddRequiredMaterial"
                element={<AddRequiredMaterial />}
            />
        </Route>
        <Route path="RiskTracking" element={<RiskTracking />}>
            <Route path="AddRiskTracking" element={<AddRiskTracking />} />
        </Route>
        <Route path="SimpleTask" element={<SimpleTask />}>
            <Route path="AddSimpleTask" element={<AddSimpleTask />} />
        </Route>
        <Route path="ToDos" element={<ToDos />}>
            <Route path="AddToDos" element={<AddToDos />} />
        </Route>
        <Route path="WeatherData" element={<WeatherData />}>
            <Route path="AddWeatherData" element={<AddWeatherData />} />
        </Route>
        <Route path="TaskManager" element={<TaskManager />}>
            <Route path="AddTaskManager" element={<AddTaskManager />} />
            <Route path="AddSubTask" element={<AddSubTask />} />
        </Route>
        <Route path="RequiredDocumentation" element={<RequiredDocumentation />}>
            <Route
                path="AddRequiredDocumentation"
                element={<AddRequiredDocumentation />}
            />
        </Route>
        <Route path="RequestPayment" element={<RequestPayment />}>
            <Route path="AddRequestPayment" element={<AddRequestPayment />} />
        </Route>

        <Route path=":ProjectId" element={<OperationalProjects />}>
            {/* <Route path="ProjectRequests" element={<OperationalProjects />} /> */}
            <Route path="ProjectRequests" element={<RequestForm />}>
                <Route path="AddProjectRequests" element={<AddRequestForm />} />
            </Route>
            <Route path="DailyWorkLog" element={<DailyWorkLog />}>
                <Route path="AddDailyWorkLog" element={<AddDailyWorkLog />} />
                <Route
                    path="AddWorkLogAndBranchOfWork"
                    element={<AddWorkLog />}
                />
            </Route>
            <Route
                path="InstructionGiven"
                element={<InstructionGivenOnProject />}
            >
                <Route
                    path="AddInstructionGiven"
                    element={<AddInstructionGiven />}
                />
            </Route>
            <Route path="InvoiceTracking" element={<InvoiceTracking />}>
                <Route
                    path="AddInvoiceTracking"
                    element={<AddInvoiceTracking />}
                />
            </Route>
            <Route path="ManpowerRequirement" element={<ManpowerRequirement />}>
                <Route
                    path="AddManpowerRequirement"
                    element={<AddManpowerRequirement />}
                />
            </Route>
            <Route path="Request" element={<Request />}>
                <Route path="AddRequest" element={<AddRequest />} />
            </Route>
            <Route path="RequiredEquipment" element={<RequiredEquipment />}>
                <Route
                    path="AddRequiredEquipment"
                    element={<AddRequiredEquipment />}
                />
            </Route>
            <Route path="RequiredMaterial" element={<RequiredMaterial />}>
                <Route
                    path="AddRequiredMaterial"
                    element={<AddRequiredMaterial />}
                />
            </Route>
            <Route path="RiskTracking" element={<RiskTracking />}>
                <Route path="AddRiskTracking" element={<AddRiskTracking />} />
            </Route>
            <Route path="SimpleTask" element={<SimpleTask />}>
                <Route path="AddSimpleTask" element={<AddSimpleTask />} />
            </Route>
            <Route path="ToDos" element={<ToDos />}>
                <Route path="AddToDos" element={<AddToDos />} />
            </Route>
            <Route path="WeatherData" element={<WeatherData />}>
                <Route path="AddWeatherData" element={<AddWeatherData />} />
            </Route>
            <Route path="TaskManager" element={<TaskManager />}>
                <Route path="AddTaskManager" element={<AddTaskManager />} />
                <Route path="AddSubTask" element={<AddSubTask />} />
            </Route>
            <Route
                path="RequiredDocumentation"
                element={<RequiredDocumentation />}
            >
                <Route
                    path="AddRequiredDocumentation"
                    element={<AddRequiredDocumentation />}
                />
            </Route>
            <Route path="RequestPayment" element={<RequestPayment />}>
                <Route
                    path="AddRequestPayment"
                    element={<AddRequestPayment />}
                />
            </Route>
        </Route>
    </Route>,
    <Route path="AddProject" element={<AddProject />} />,

    <Route path="Material" element={<Material />}>
        <Route path="AddMaterial" element={<AddMaterial />} />
    </Route>,
    <Route path="Equipment" element={<Equipment />}>
        <Route path="AddEquipment" element={<AddEquipment />} />
    </Route>,
    <Route path="Evaluation" element={<Evaluation />}>
        <Route path="AddEvaluation" element={<AddEvaluation />} />
    </Route>,
    <Route path="Instruction" element={<Instruction />}>
        <Route path="AddInstruction" element={<AddInstruction />} />
    </Route>,
    <Route path="Manpower" element={<Manpower />}>
        <Route path="AddManpower" element={<AddManpower />} />
    </Route>,
    <Route path="MaterialCategory" element={<MaterialCategory />}>
        <Route path="AddMaterialCategory" element={<AddMaterialCategory />} />
    </Route>,
    <Route path="Phase" element={<Phase />}>
        <Route path="AddPhase" element={<AddPhase />} />
    </Route>,
    <Route path="WorkCategory" element={<WorkCategory />}>
        <Route path="AddWorkCategory" element={<AddWorkCategory />} />
    </Route>,
    <Route path="Priority" element={<Priority />}>
        <Route path="AddPriority" element={<AddPriority />} />
    </Route>,
    <Route path="Documentation" element={<Documentation />}>
        <Route path="AddDocumentation" element={<AddDocumentation />} />
    </Route>,
    <Route path="DocumentCategory" element={<DocumentCategory />}>
        <Route path="AddDocumentCategory" element={<AddDocumentCategory />} />
    </Route>,
];
