import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
    Box,
    Button,
    Avatar,
    IconButton,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddRounded, CancelRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { filePatcher, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    PettyCashURL,
    // ProjectURL,
    EmployeeURL,
    // PVRequestURL,
} from "../../../../utils/config";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import dateFormatter from "../../../../utils/dateFormater";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";

const EditPettyCash = () => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setMsg] = useState("");
    // const [filter1, setFilter1] = useState("");
    // const [options1, setOptions1] = useState([]);
    // const [optionSelect1, setOptionSelect1] = useState("");
    const [filter2, setFilter2] = useState("");
    const [options2, setOptions2] = useState([]);
    const [optionSelect2, setOptionSelect2] = useState("");
    // const [filter3, setFilter3] = useState("");
    // const [options3, setOptions3] = useState([]);
    const [optionSelect3, setOptionSelect3] = useState("");
    // const [optionSelect4, setOptionSelect4] = useState("");
    const [paidToId, setPaidToId] = useState();
    const uploadRef = useRef();
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();

    useEffect(() => {
        if (optionSelect2 === "")
            setOptionSelect2(
                `${selectedRow.paid_to?.id} - ${selectedRow.paidTo}`
            );
        if (optionSelect3 === "")
            setOptionSelect3(
                `${selectedRow.prepared_by?.id} - ${selectedRow.preparedBy}`
            );
    }, [selectedRow, optionSelect2, optionSelect3]);

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        amount_paid: selectedRow.amount_paid,
        date: dateFormatter(selectedRow.date),
        money_received: selectedRow.money_received,
        paid_to_id: selectedRow.paid_to?.id,
        paid_to_id_file: selectedRow.paid_to_id_file,
        paid_to_name: selectedRow.paid_to_name,
        pcpv_number: selectedRow.pcpv_number,
        prepared_by_id: selectedRow.prepared_by?.id,
        reason: selectedRow.reason,
        remark: selectedRow.remark,
    };

    const FORM_VALIDATION = Yup.object().shape({
        date: Yup.string().min(1).required("*Required"),
    });
    useEffect(() => {
        // get(ProjectURL, {
        //     limit: 10,
        //     filter: {
        //         name: filter1,
        //     },
        // }).then((res) => {
        //     let data = [];
        //     res &&
        //         res.data.forEach((name, index) => {
        //             data.push(
        //                 `${res.data[index]?.id} - ${res.data[index]?.name}`
        //             );
        //         });
        //     setOptions1(data);
        // });

        get(EmployeeURL, {
            limit: 10,
            filter: {
                name: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.first_name}`
                    );
                });
            setOptions2(data);
        });

        // get(PVRequestURL, {
        //     limit: 10,
        //     filter: {
        //         name: filter3,
        //     },
        // }).then((res) => {
        //     let data = [];
        //     res &&
        //         res.data.forEach((name, index) => {
        //             data.push(
        //                 `${res.data[index]?.id} - ${res.data[index]?.pv_number}`
        //             );
        //         });
        //     setOptions3(data);
        // });
    }, [filter2]);

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        filePatcher(PettyCashURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                dispatch(setRefresh(!refreshTable));
            }
        });
    };

    return (
        <div>
            <TitleHeader title="Add Petty Cash" icon={<AddRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;

                        values.prepared_by_id = parseInt(
                            optionSelect2?.split("-")[0]
                        );
                        values.paid_to_id = parseInt(
                            optionSelect3?.split("-")[0]
                        );

                        values.paid_to_id_file = paidToId;

                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>Date</FormLabel>
                                    <TextFieldWrapper
                                        name="date"
                                        type="Date"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="pcpv_number"
                                    label="PCPV"
                                    variant="outlined"
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="remark"
                                    label="Remark"
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="reason"
                                    label="Reason"
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                />
                            </Grid>
                            {/* <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="debit"
                                    label="Debit"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="credit"
                                    label="Credit"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid> */}
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="amount_paid"
                                    label="Amount Paid"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            {/* <Grid item xs={4}>
                                <Autocomplete
                                    loading={options1.length < 1 ? true : false}
                                    disablePortal
                                    options={options1}
                                    onChange={(event, newValue) => {
                                        setOptionSelect1(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Project"
                                            onChange={(event) => {
                                                setFilter1(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid> */}
                            <Grid item xs={4}>
                                <Autocomplete
                                    value={optionSelect2}
                                    loading={options2.length < 1 ? true : false}
                                    disablePortal
                                    options={options2}
                                    onChange={(event, newValue) => {
                                        setOptionSelect2(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Prepared By"
                                            onChange={(event) => {
                                                setFilter2(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            {!paidToId && (
                                <Grid item xs={4}>
                                    <Autocomplete
                                        value={optionSelect3}
                                        loading={
                                            options2.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options2}
                                        onChange={(event, newValue) => {
                                            setOptionSelect3(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Paid To (Employee)"
                                                onChange={(event) => {
                                                    setFilter2(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            )}
                            {!optionSelect3 && (
                                <>
                                    <Grid item xs={12} className="imagePreview">
                                        {paidToId ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                }}
                                            >
                                                <Avatar
                                                    onClick={() => {
                                                        uploadRef.current.click();
                                                    }}
                                                    src={URL.createObjectURL(
                                                        paidToId
                                                    )}
                                                    alt=""
                                                    style={{
                                                        height: "10ch",
                                                        width: "50%",
                                                    }}
                                                    variant="square"
                                                />
                                                <IconButton
                                                    onClick={() => {
                                                        setPaidToId("");
                                                    }}
                                                >
                                                    <CancelRounded />
                                                </IconButton>
                                            </div>
                                        ) : (
                                            <Button
                                                onClick={() => {
                                                    uploadRef.current.click();
                                                }}
                                                variant="contained"
                                            >
                                                Upload Paid To ID
                                            </Button>
                                        )}
                                        <input
                                            hidden
                                            ref={uploadRef}
                                            type="file"
                                            onChange={(e) => {
                                                setPaidToId(e.target.files[0]);
                                            }}
                                        ></input>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextFieldWrapper
                                            name="paid_to_name"
                                            label="Paid To Name"
                                            variant="outlined"
                                            value={paidToId}
                                            onChange={(e) => {
                                                setPaidToId(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                </>
                            )}
                            {/* <Grid item xs={4}>
                                <Autocomplete
                                    loading={options3.length < 1 ? true : false}
                                    disablePortal
                                    options={options3}
                                    onChange={(_event, newValue) => {
                                        setOptionSelect4(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Payment Voucher"
                                            onChange={(event) => {
                                                setFilter3(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid> */}
                            <Grid item xs={4}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default EditPettyCash;
