import PropTypes from "prop-types";
import { Card, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useDispatch } from "react-redux";
import { setSelected } from "../../../Redux/Slice/selectedRow";
import { useNavigate } from "react-router";

const OnGoingProjects = ({
    projectName,
    projectStartDate,
    projectEndDate,
    projectManager,
    projectLocation,
    project,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <Card
            sx={{
                bgcolor: "#e1eaff",
                minWidth: "30%",
                cursor: "pointer",
                borderRadius: "15px",
            }}
            onClick={() => {
                dispatch(setSelected(project));
                navigate(`/View/ViewProject/${project.id}/DailyWorkLog`);
            }}
            elevation={0}
        >
            <Grid
                container
                sx={{
                    p: 2,
                }}
            >
                <Grid item xs={12}>
                    <Typography
                        variant="h5"
                        color={"#14213d"}
                        fontWeight={"bold"}
                    >
                        {projectName}
                    </Typography>
                </Grid>
                <Box
                    width={"100%"}
                    style={{
                        borderBottom: "solid silver 1px",
                        marginTop: "1ch",
                        marginBottom: "1ch",
                    }}
                />
                <Grid item xs={12}>
                    <div
                        style={{
                            maxWidth: "80%",
                            display: "flex",
                            flexDirection: "row",
                            gap: "2ch",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                            color={"#14213d"}
                            fontWeight={"bold"}
                        >
                            Start Date -
                        </Typography>
                        <Typography variant="subtitle2" color={"#14213d"}>
                            {new Date(projectStartDate).toDateString()}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div
                        style={{
                            maxWidth: "80%",
                            display: "flex",
                            flexDirection: "row",
                            gap: "2ch",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                            color={"#14213d"}
                            fontWeight={"bold"}
                        >
                            End Date -
                        </Typography>
                        <Typography variant="subtitle2" color={"#14213d"}>
                            {new Date(projectEndDate).toDateString()}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div
                        style={{
                            maxWidth: "80%",
                            display: "flex",
                            flexDirection: "row",
                            gap: "2ch",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                            color={"#14213d"}
                            fontWeight={"bold"}
                        >
                            Project Manager -
                        </Typography>
                        <Typography variant="subtitle2" color={"#14213d"}>
                            {projectManager}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div
                        style={{
                            maxWidth: "80%",
                            display: "flex",
                            flexDirection: "row",
                            gap: "2ch",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                            color={"#14213d"}
                            fontWeight={"bold"}
                        >
                            Location -
                        </Typography>
                        <Typography variant="subtitle2" color={"#14213d"}>
                            {projectLocation}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </Card>
    );
};

OnGoingProjects.propTypes = {
    projectEndDate: PropTypes.string,
    projectLocation: PropTypes.string,
    projectManager: PropTypes.string,
    projectName: PropTypes.string,
    projectStartDate: PropTypes.string,
};

export default OnGoingProjects;
