import { LinearProgress } from "@mui/material";
import React, { useState } from "react";
import Chart from "react-google-charts";

const GanttChart = ({ columns, rows }) => {
    const [loading, setloading] = useState(true);

    return (
        <div
            className="Gantt"
            style={{
                width: "100%",
                height: "100vh",
                overflowX: "scroll",
            }}
        >
            {loading && <LinearProgress />}
            <Chart
                chartType="Gantt"
                data={[columns, ...rows]}
                width="100%"
                height="100%"
                legendToggle
                onLoad={() => {
                    setloading(false);
                }}
                options={{
                    height: 400,
                    gantt: {
                        trackHeight: 30,
                        criticalPathEnabled: true,
                        criticalPathStyle: {
                            stroke: "#e64a19",
                            strokeWidth: 5,
                        },
                    },
                }}
                chartEvents={[
                    {
                        eventName: "select",
                        callback({ chartWrapper }) {
                            let a = chartWrapper.getChart().getSelection();
                            console.log(a);
                        },
                    },
                    {
                        eventName: "error",
                        callback({ google }) {
                            google.visualization.errors.removeAll();
                        },
                    },
                ]}
            />
        </div>
    );
};

export default GanttChart;
