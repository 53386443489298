import { Add, ImportExport, SearchRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Button,
    InputAdornment,
    TextField,
    IconButton,
    Modal,
    Box,
    Paper,
    Grid,
    FormControl,
    FormLabel,
    Autocomplete,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import { SnackBar } from "../../../../Components/SnackBar/SnackBar.component";

import Table from "../../../../Components/Table/Table.component";
import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { get } from "../../../../utils/Caller";
import {
    CrvPaymentExcelURL,
    CrvPaymentURL,
    ProjectURL,
} from "../../../../utils/config";

const ViewCrvPayment = () => {
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [loadingFile, setloadingFile] = useState(false);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(20);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState();
    const [Search, setSearch] = useState();
    const [crvTypes, setCrvTypes] = useState(1);
    const [filter1, setFilter1] = useState("");
    const [options1, setOptions1] = useState([]);
    const [optionSelect1, setOptionSelect1] = useState("");
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setMsg] = useState("");

    const CRV_TYPES = ["Cash", "Check"];

    const columns = [
        {
            field: "date",
            headerName: "Date",
            width: 150,
        },
        {
            field: "name",
            headerName: "Name",
            width: 150,
        },
        {
            field: "customer_name",
            headerName: "Customer Name",
            width: 150,
        },
        {
            field: "description",
            headerName: "Description",
            width: 150,
        },
        {
            field: "amount_before_vat",
            headerName: "Amount Before Vat",
            width: 150,
        },
        {
            field: "vat",
            headerName: "Vat",
            width: 150,
        },
        {
            field: "amount_with_vat",
            headerName: "Amount With Vat",
            width: 150,
        },
        {
            field: "payment_description",
            headerName: "Payment Description",
            width: 150,
        },
        {
            field: "withholding",
            headerName: "Withholding",
            width: 150,
        },
        {
            field: "check_amount",
            headerName: "Check Amount",
            width: 150,
        },
        {
            field: "cash_amount",
            headerName: "Cash Amount",
            width: 150,
        },
        {
            field: "projectName",
            headerName: "Project",
            width: 150,
        },
        {
            field: "crvType",
            headerName: "Crv Type",
            width: 150,
        },
    ];

    const INITIAL_VALUES = {
        from: "",
        to: "",
        project_id: null,
        crv_types: crvTypes,
        customer_name: "",
    };

    const FORM_VALIDATION = Yup.object().shape({
        from: Yup.string().min(2).required("*Required"),
        to: Yup.string().min(2).required("*Required"),
        customer_name: Yup.string().min(2).required("*Required"),
    });

    useEffect(() => {
        let tableData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(CrvPaymentURL, {
            limit: limit,
            filter: { all: filter },
        }).then((res) => {
            // console.log(res);
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    tableData.push({
                        ...data,
                        projectName: data.project?.name,
                        crvType: data.crv_type?.name,
                    });
                });
            setRows(tableData);
        });
    }, [refreshTable, filter, limit, rows.length]);

    useEffect(() => {
        get(ProjectURL, {
            limit: 10,
            filter: {
                name: filter1,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.name}`
                    );
                });
            setOptions1(data);
        });
    }, [filter1]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    const formSubmitHandler = (data, actions) => {
        setloadingFile(true);
        get(CrvPaymentExcelURL, data).then((res) => {
            setloadingFile(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data) {
                toExcel(res.data.data);
                setSuccess(true);
                setError(false);
                setTimeout(() => {
                    setOpen(false);
                }, 300);
                actions.resetForm();
            }
        });
    };

    const toExcel = (pdfData) => {
        const strPdf = pdfData;
        const buff = Buffer.from(strPdf);
        const excelFile = URL.createObjectURL(
            new Blob([buff], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64",
            })
        );
        window.open(excelFile, "_blank");
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setloading(true);
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <div style={{ display: "flex", gap: "2ch" }}>
                    <LoadingButton
                        endIcon={<ImportExport />}
                        loading={loadingFile}
                        onClick={() => {
                            setOpen(true);
                        }}
                        variant={"contained"}
                    >
                        Export
                    </LoadingButton>
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<Add />}
                        onClick={() => {
                            navigate("/View/Finance/CrvPayment/AddCrvPayment");
                        }}
                    >
                        Add Crv Payment
                    </Button>
                </div>
            </div>
            <div className="TableContainer">
                <Table
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                />
            </div>
            <Modal
                className="Modal"
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Paper style={{ padding: "5ch" }}>
                        <Formik
                            initialValues={{ ...INITIAL_VALUES }}
                            validationSchema={FORM_VALIDATION}
                            onSubmit={(values, actions) => {
                                values.crv_types = crvTypes;
                                values.project_id = parseInt(
                                    optionSelect1?.split("-")[0]
                                );
                                formSubmitHandler(values, actions);
                            }}
                        >
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <FormLabel>From Date</FormLabel>
                                            <TextFieldWrapper
                                                name="from"
                                                type="date"
                                                variant="outlined"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <FormLabel>To Date</FormLabel>
                                            <TextFieldWrapper
                                                name="to"
                                                type="date"
                                                variant="outlined"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            loading={
                                                options1.length < 1
                                                    ? true
                                                    : false
                                            }
                                            disablePortal
                                            options={options1}
                                            onChange={(event, newValue) => {
                                                setOptionSelect1(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Project"
                                                    onChange={(event) => {
                                                        setFilter1(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextFieldWrapper
                                            select
                                            name="crv_types"
                                            label="CRV Types"
                                            variant="outlined"
                                            value={crvTypes}
                                            options={CRV_TYPES ?? " "}
                                            onChange={(e) => {
                                                setCrvTypes(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextFieldWrapper
                                            name="customer_name"
                                            label="Customer Name"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                                <div>
                                    <LoadingButton
                                        data-testid="AddButton"
                                        loading={loadingFile}
                                        type="submit"
                                        className="ButtonContained"
                                        style={{
                                            marginTop: "2ch",
                                            float: "right",
                                        }}
                                    >
                                        {loading ? null : "Get Excel"}
                                    </LoadingButton>
                                </div>
                            </Form>
                        </Formik>
                    </Paper>
                </Box>
            </Modal>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};

export default ViewCrvPayment;
