import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import AddRequiredEquipment from "./AddRequiredEquipment.page";
import ViewRequiredEquipment from "./ViewRequiredEquipment.page";

const RequiredEquipment = ({ next, name, handleTabChange }) => {
    const location = useLocation();
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    return (
        <>
            {(location.pathname ===
                `/View/ViewProject/ProjectAddDetail/AddProjectTask` ||
                location.pathname ===
                    `/View/ViewProject/${selectedRow.id}/RequiredEquipment/AddRequiredEquipment`) && (
                <AddRequiredEquipment
                    next={next}
                    name={name}
                    handleTabChange={handleTabChange}
                />
            )}
            {(location.pathname ===
                `/View/ViewProject/ProjectAddDetail/AddProjectTask/RequiredEquipment` ||
                location.pathname ===
                    `/View/ViewProject/${selectedRow.id}/RequiredEquipment`) && (
                <>
                    <ViewRequiredEquipment />
                </>
            )}
        </>
    );
};

export default RequiredEquipment;
