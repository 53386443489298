import { RiskTrackingURL } from "../../../../utils/config";
import { deleter } from "../../../../utils/Caller";

export const deleteRiskTracking = (id) => {
    const body = {
        id: id,
    };
    // console.log(body);

    return new Promise((resolve, reject) => {
        deleter(RiskTrackingURL, body).then((res) => {
            if (res.error) {
                reject("Error Occured!!");
            } else if (res.data.success) {
                resolve("Success");
            }
        });
    });
};
