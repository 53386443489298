import { SearchRounded } from "@mui/icons-material";
import {
    Divider,
    IconButton,
    InputAdornment,
    Modal,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Table from "../../Components/Table/Table.component";
import { UploadButton } from "../../Components/UploadFile/UploadButton.component";
import { setRefresh } from "../../Redux/Slice/refreshSlice";
import { get } from "../../utils/Caller";
import { Client } from "../../utils/config";

const ViewClient = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState("");
    const [search, setSearch] = useState("");
    const [limit, setLimit] = useState(16);
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const [data, setData] = useState({});
    const [open, setOpen] = useState(false);

    const columns = [
        {
            field: "name",
            headerName: "Name",
            width: 150,
        },
        {
            field: "tradeName",
            headerName: "Trade Name",
            width: 150,
        },
        {
            field: "address",
            headerName: "Address",
            width: 150,
        },
        {
            field: "tel",
            headerName: "Tel.",
            width: 150,
        },
        {
            field: "tinNumber",
            headerName: "Tin Number",
            width: 150,
        },
        {
            field: "email",
            headerName: "email",
            width: 150,
        },
        {
            field: "contactPersonName",
            headerName: "contactPersonName",
            width: 150,
        },
        {
            field: "subCity",
            headerName: "Subcity",
            width: 150,
        },
        {
            field: "woreda",
            headerName: "Woreda",
            width: 150,
        },
        {
            field: "city",
            headerName: "City",
            width: 150,
        },
    ];

    useEffect(() => {
        if (rows.length === 0) {
            setloading(true);
        }
        get(Client, {
            limit: limit,
            filter: { all: filter },
        }).then((res) => {
            res && res.data && setloading(false);
            res && setRows(res.data);
        });
    }, [filter, refreshTable, limit, rows.length]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    const viewClientDetail = (data) => {
        setData(data);
        setOpen(true);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setloading(true);
                                        setFilter(search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <UploadButton type={"0"} />
            </div>
            <div className="TableContainer">
                <Table
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                    viewClientDetail={viewClientDetail}
                />
            </div>
            <Modal
                className="Modal"
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <Paper
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "60%",
                        padding: "5ch",
                        maxHeight: "70vh",
                        overflow: "scroll",
                    }}
                >
                    {Object.keys(data).map((client, index) => (
                        <div style={{ width: "100%" }}>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    padding: "1ch",
                                }}
                            >
                                <Typography fontWeight="bolder">
                                    {client}
                                </Typography>
                                <Typography>
                                    {new Date(data[client]) instanceof Date &&
                                    isFinite(new Date(data[client]))
                                        ? new Date(data[client]).toDateString()
                                        : data[client]}
                                </Typography>
                            </div>
                            <Divider />
                        </div>
                    ))}
                </Paper>
            </Modal>
        </div>
    );
};

export default ViewClient;
