import { Checkbox, FormControl, FormLabel, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { EditRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { patcher } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import { AttendanceAbscenceTypeURL } from "../../../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";

const EditAttendanceAbscenceType = (props) => {
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [IsWithQuota, setIsWithQuota] = useState(selectedRow.is_with_quota);
    const [IsWithPay, setIsWithPay] = useState(selectedRow.is_with_pay);
    const [IsAnnualLeave, setIsAnnualLeave] = useState(
        selectedRow.is_annual_leave
    );
    const [IsWithEntitlement, setIsWithEntitlement] = useState(
        selectedRow.is_with_entitlement
    );
    const [IsRegularAttendance, setIsRegularAttendance] = useState(
        selectedRow.is_regular_attendance
    );
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [msg, setMsg] = useState("");

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        worked_time: "",
        aa_description: selectedRow.aa_description,
        is_with_quota: IsWithQuota,
        is_with_pay: IsWithPay,
        is_annual_leave: IsAnnualLeave,
        is_with_entitlement: IsWithEntitlement,
        is_regular_attendance: IsRegularAttendance,
        number_of_days: selectedRow.number_of_days,
    };

    const FORM_VALIDATION = Yup.object().shape({
        aa_description: Yup.string().required("*Required"),
        // number_of_days: Yup.number(),
    });

    const formSubmitHandler = (updateData, actions) => {
        setLoading(true);
        const update = {
            id: selectedRow.id,
            updateData,
        };

        patcher(AttendanceAbscenceTypeURL, update).then((res) => {
            setLoading(false);
            // console.log(res.error);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                dispatch(setRefresh(!refreshTable));
                setSuccess(true);
                setError(false);
                props.close();
            }
        });
    };

    return (
        <div>
            <TitleHeader
                title="Edit Attendance Abscence Type"
                icon={<EditRounded />}
            />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        values.is_annual_leave = IsAnnualLeave;
                        values.is_regular_attendance = IsRegularAttendance;
                        values.is_with_entitlement = IsWithEntitlement;
                        values.is_with_pay = IsWithPay;
                        values.is_with_quota = IsWithQuota;
                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextFieldWrapper
                                    name="aa_description"
                                    label="Aa Description"
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="worked_time"
                                    label="Work Hours"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>Is With Quota</FormLabel>
                                    <Checkbox
                                        name="is_with_quota"
                                        checked={IsWithQuota}
                                        onChange={(event) => {
                                            setIsWithQuota(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>Is With Pay</FormLabel>
                                    <Checkbox
                                        name="is_with_pay"
                                        checked={IsWithPay}
                                        onChange={(event) => {
                                            setIsWithPay(event.target.checked);
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>Is Annual Leave</FormLabel>
                                    <Checkbox
                                        name="is_annual_leave"
                                        checked={IsAnnualLeave}
                                        onChange={(event) => {
                                            setIsAnnualLeave(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>Is With Entitlement</FormLabel>
                                    <Checkbox
                                        name="is_with_entitlement"
                                        checked={IsWithEntitlement}
                                        onChange={(event) => {
                                            setIsWithEntitlement(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>Is Regular Attendance</FormLabel>
                                    <Checkbox
                                        name="is_regular_attendance"
                                        checked={IsRegularAttendance}
                                        onChange={(event) => {
                                            setIsRegularAttendance(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="number_of_days"
                                    label="Number Of Days"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Apply"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default EditAttendanceAbscenceType;
