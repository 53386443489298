import { Add, SearchRounded } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { WorkCategoryURL } from "../../../utils/config";

import { get } from "../../../utils/Caller";
import OperationsTable from "../../../Components/Table/OperationsTable.component";
import { setRefresh } from "../../../Redux/Slice/refreshSlice";
import { UploadButton } from "../../../Components/UploadFile/UploadButton.component";
import { SnackBar } from "../../../Components/SnackBar/SnackBar.component";

const ViewWorkCategory = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(16);
    const [filter, setFilter] = useState("");
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setMsg] = useState("");
    const [Search, setSearch] = useState("");

    const columns = [
        {
            field: "name",
            headerName: "Work Category Name",
            width: 150,
        },
        {
            field: "description",
            headerName: "Description",
            width: 450,
        },
    ];

    useEffect(() => {
        if (rows.length === 0) {
            setloading(true);
        }
        get(WorkCategoryURL, {
            limit: limit,
            filter: { all: filter },
        }).then((res) => {
            res && res.data && setloading(false);
            res && setRows(res.data);
        });
    }, [filter, refreshTable, limit, rows.length]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setloading(true);
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <div>
                    <UploadButton
                        type={"9"}
                        setError={() => {
                            setError(!error);
                        }}
                        setSuccess={() => {
                            setSuccess(!success);
                        }}
                        setMsg={(msg) => {
                            setMsg(msg);
                        }}
                    />
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<Add />}
                        onClick={() => {
                            navigate("/View/WorkCategory/AddWorkCategory");
                        }}
                    >
                        Add Work Category
                    </Button>
                </div>
            </div>
            <div className="TableContainer">
                <OperationsTable
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                />
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};

export default ViewWorkCategory;
