import {
    Box,
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    MenuItem,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../../../../Components/SnackBar/SnackBar2.component.jsx";
import { patcher } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import { EditRounded } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { DailyWorkLogURL } from "../../../../utils/config";
import dateFormatter from "../../../../utils/dateFormater";

const EditDailyWorkLog = (props) => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const selectedDetail = useSelector(
        (state) => state.selectedDetail.selected
    );
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const [dayValue, setDayValue] = useState("Monday");
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [msg, setMsg] = useState("");

    const phoneRegex = /^\d*$/gm;
    const DAYS = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ];

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        weather: selectedDetail.weather,
        day: selectedDetail.day,
        temprature: selectedDetail.temprature,
        time: selectedDetail.time,
        name_of_employee: selectedDetail.name_of_employee,
        contact: selectedDetail.contact,
        cell_phone: selectedDetail.cell_phone,
        contract_no: selectedDetail.contract_no,
        location: selectedDetail.location,
        date: dateFormatter(selectedDetail.date),
        project_id: selectedDetail.project_id,
        description: selectedDetail.description,
    };

    const FORM_VALIDATION = Yup.object().shape({
        weather: Yup.string().min(1).required("*Required"),
        day: Yup.string().min(1),
        temprature: Yup.string().min(1).required("*Required"),
        time: Yup.string().min(4).required("*Required"),
        name_of_employee: Yup.string().min(2).required("*Required"),
        contact: Yup.string().min(2).required("*Required"),
        cell_phone: Yup.string()
            .min(4)
            .matches(
                phoneRegex,
                "Please Enter A Valid Phone(with Country Code)"
            )
            .required("*Required"),
        contract_no: Yup.string().min(1).required("*Required"),
        location: Yup.string().min(1).required("*Required"),
        date: Yup.string().min(1).required("*Required"),
        // project_id: Yup.number().min(4).required(),
    });

    const formSubmitHandler = (updateData) => {
        setLoading(true);
        const update = {
            id: selectedDetail.id,
            updateData,
        };

        // console.log(update);
        patcher(DailyWorkLogURL, update).then((res) => {
            setLoading(false);
            // console.log(res.error);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                dispatch(setRefresh(!refreshTable));
                setSuccess(true);
                setError(false);
                props.close();
            }
        });
    };

    return (
        <div>
            <TitleHeader title="Edit Daily Work Log" icon={<EditRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.day = dayValue;
                        values.project_id = selectedRow.id;
                        values.isProtectedForEdit = protectedEdit;
                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="weather"
                                    label="Weather"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    select
                                    name="day"
                                    label="Day"
                                    variant="outlined"
                                    value={dayValue}
                                    onChange={(e) => {
                                        setDayValue(e.target.value);
                                    }}
                                >
                                    {DAYS.map((day, index) => (
                                        <MenuItem key={index} value={day}>
                                            {day}
                                        </MenuItem>
                                    ))}
                                </TextFieldWrapper>
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>Time</FormLabel>
                                    <TextFieldWrapper
                                        name="time"
                                        variant="outlined"
                                        type="time"
                                    />
                                </FormControl>
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="temprature"
                                    label="Temprature"
                                    variant="outlined"
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="name_of_employee"
                                    label="Name Of Employee"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="contact"
                                    label="Contact"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="cell_phone"
                                    label="Cell Phone"
                                    variant="outlined"
                                    type="phone"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="contract_no"
                                    label="Contract No"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="location"
                                    label="Location"
                                    variant="outlined"
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>Date</FormLabel>
                                    <TextFieldWrapper
                                        name="date"
                                        variant="outlined"
                                        type="date"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Apply"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};

export default EditDailyWorkLog;
