import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
    Box,
    Divider,
    Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { AddRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { get, patcher } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    ChartOfAccountURL,
    ContactURL,
    CurrencyURL,
    PaymentTermURL,
    CountryURL,
    TitleURL,
} from "../../../../utils/config";
import HorizontalStepper from "../../../../Components/Stepper/index.page";
import { useDispatch, useSelector } from "react-redux";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";

const EditContact = (props) => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState();
    const [filter2, setFilter2] = useState();
    const [filter3, setFilter3] = useState();
    const [filter4, setFilter4] = useState();
    const [filter5, setFilter5] = useState();
    const [options1, setOptions1] = useState([]);
    const [optionsId1, setOptionsID1] = useState();
    const [optionSelect1, setOptionSelect1] = useState("");
    const [options2, setOptions2] = useState([]);
    const [optionsId2, setOptionsID2] = useState();
    const [optionSelect2, setOptionSelect2] = useState("");
    const [options3, setOptions3] = useState([]);
    const [optionsId3, setOptionsID3] = useState();
    const [optionSelect3, setOptionSelect3] = useState("");
    const [options4, setOptions4] = useState([]);
    const [optionSelect4, setOptionSelect4] = useState({});
    const [options5, setOptions5] = useState([]);
    const [optionSelect5, setOptionSelect5] = useState({});
    const [ContactType, setContactType] = useState(1);
    const [msg, setMsg] = useState("");
    const [IsPrimaryContact, setIsPrimaryContact] = useState({});
    const [contactPersonsLength, setContactPersonsLength] = useState([]);
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();

    const CONTACT_TYPE = ["Customer", "Vendor"];
    const steps = ["Other Details", "Address", "Contact Person"];

    const phoneRegex = /^\d*$/gm;

    useEffect(() => {
        if (optionSelect1 === "")
            setOptionSelect1(
                `${selectedRow.chart_of_account?.id} - ${selectedRow.chartOfAccount}`
            );
        if (optionSelect2 === "")
            setOptionSelect2(
                `${selectedRow.currency?.id} - ${selectedRow.currencyName}`
            );
        if (optionSelect3 === "")
            setOptionSelect3(
                `${selectedRow.payment_term?.id} - ${selectedRow.paymentTerm}`
            );
        if (optionSelect4[0] === undefined)
            setOptionSelect4({
                ...optionSelect4,
                0: `${
                    selectedRow.contact_address.find(
                        ({ address_type }) => address_type === 1
                    ).country?.id
                } - ${
                    selectedRow.contact_address.find(
                        ({ address_type }) => address_type === 1
                    ).country?.country_name
                }`,
            });
        if (optionSelect4[1] === undefined)
            setOptionSelect4({
                ...optionSelect4,
                1: `${
                    selectedRow.contact_address.find(
                        ({ address_type }) => address_type === 2
                    ).country?.id
                } - ${
                    selectedRow.contact_address.find(
                        ({ address_type }) => address_type === 2
                    ).country?.country_name
                }`,
            });
        selectedRow.contact_person.forEach((data, index) => {
            if (contactPersonsLength.indexOf(index) === -1) {
                setContactPersonsLength([...contactPersonsLength, index]);
                if (optionSelect5[index] === undefined)
                    setOptionSelect5({
                        ...optionSelect5,
                        [index]: `${data.title?.id} - ${data.title?.name}`,
                    });
                if (IsPrimaryContact[index] === undefined) {
                    setIsPrimaryContact({
                        ...IsPrimaryContact,
                        [index]: data.is_primary_contact,
                    });
                }
            }
        });
    }, [
        selectedRow,
        optionSelect1,
        optionSelect2,
        optionSelect3,
        optionSelect4,
        optionSelect5,
        contactPersonsLength,
        IsPrimaryContact,
    ]);

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        contact_type: ContactType,
        company_name: selectedRow.company_name,
        contact_display_name: selectedRow.contact_display_name,
        remark: selectedRow.remark,
        chart_of_account_id: selectedRow.chart_of_account_id,
        currency_id: selectedRow.currency_id,
        payment_term_id: selectedRow.payment_term_id,
        contactAddresses: [
            {
                address_type: 1,
                attention: selectedRow.contact_address.find(
                    ({ address_type }) => address_type === 1
                ).attention,
                street1: selectedRow.contact_address.find(
                    ({ address_type }) => address_type === 1
                ).street1,
                street2: selectedRow.contact_address.find(
                    ({ address_type }) => address_type === 1
                ).street2,
                city: selectedRow.contact_address.find(
                    ({ address_type }) => address_type === 1
                ).city,
                state: selectedRow.contact_address.find(
                    ({ address_type }) => address_type === 1
                ).state,
                phone: selectedRow.contact_address.find(
                    ({ address_type }) => address_type === 1
                ).phone,
                fax: selectedRow.contact_address.find(
                    ({ address_type }) => address_type === 1
                ).fax,
                zip_code: selectedRow.contact_address.find(
                    ({ address_type }) => address_type === 1
                ).zip_code,
                country_id: selectedRow.contact_address.find(
                    ({ address_type }) => address_type === 1
                ).country_id,
            },
            {
                address_type: 2,
                attention: selectedRow.contact_address.find(
                    ({ address_type }) => address_type === 2
                ).attention,
                street1: selectedRow.contact_address.find(
                    ({ address_type }) => address_type === 2
                ).street1,
                street2: selectedRow.contact_address.find(
                    ({ address_type }) => address_type === 2
                ).street2,
                city: selectedRow.contact_address.find(
                    ({ address_type }) => address_type === 2
                ).city,
                state: selectedRow.contact_address.find(
                    ({ address_type }) => address_type === 2
                ).state,
                phone: selectedRow.contact_address.find(
                    ({ address_type }) => address_type === 2
                ).phone,
                fax: selectedRow.contact_address.find(
                    ({ address_type }) => address_type === 2
                ).fax,
                zip_code: selectedRow.contact_address.find(
                    ({ address_type }) => address_type === 2
                ).zip_code,
                country_id: selectedRow.contact_address.find(
                    ({ address_type }) => address_type === 2
                ).country_id,
            },
        ],
        contactPersons: selectedRow.contact_person.map((data, index) => {
            return {
                first_name: data.first_name,
                last_name: data.last_name,
                work_phone: data.work_phone,
                mobile: data.mobile,
                email: data.email,
                designation: data.designation,
                department: data.department,
                is_primary_contact: IsPrimaryContact[index],
                title_id: data.title_id,
            };
        }),
    };

    const FORM_VALIDATION = Yup.object().shape({
        contact_type: Yup.number().min(1).required("*Required"),
        company_name: Yup.string().min(1).required("*Required"),
        contact_display_name: Yup.string().min(1).required("*Required"),
        remark: Yup.string().min(1),
        phone: Yup.string().matches(phoneRegex, "Enter a vaild phone number"),
        contactAddresses: Yup.array().of(
            Yup.object().shape({
                attention: Yup.string().min(1),
                street1: Yup.string().min(1),
                street2: Yup.string().min(1),
                city: Yup.string().min(1).required("*Required"),
                state: Yup.string().min(1),
                fax: Yup.string().min(1),
                zip_code: Yup.string().min(1),
            })
        ),
        contactPersons: Yup.array().of(
            Yup.object().shape({
                work_phone: Yup.string().matches(
                    phoneRegex,
                    "Enter a vaild phone number"
                ),
                mobile: Yup.string().matches(
                    phoneRegex,
                    "Enter a vaild phone number"
                ),
                first_name: Yup.string().min(1).required("*Required"),
                last_name: Yup.string().min(1),
                email: Yup.string().min(1),
                designation: Yup.string().min(1),
                department: Yup.string().min(1),
                is_primary_contact: Yup.string().min(1),
            })
        ),
    });

    useEffect(() => {
        get(ChartOfAccountURL, {
            limit: 10,
            filter: {
                account_code: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.account_code}`
                    );
                });
            res && setOptionsID1(res.data);
            setOptions1(data);
        });

        get(CurrencyURL, {
            limit: 10,
            filter: {
                name: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.name}`
                    );
                });
            res && setOptionsID2(res.data);
            setOptions2(data);
        });

        get(PaymentTermURL, {
            limit: 10,
            filter: {
                all: filter3,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.show_it_as}`
                    );
                });
            res && setOptionsID3(res.data);
            setOptions3(data);
        });

        get(CountryURL, {
            limit: 10,
            filter: {
                country_name: filter4,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.country_name}`
                    );
                });
            setOptions4(data);
        });
        get(TitleURL, {
            limit: 10,
            filter: {
                name: filter5,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.name}`
                    );
                });
            setOptions5(data);
        });
    }, [filter, filter2, filter3, filter4, filter5]);

    const formSubmitHandler = (updateData, actions) => {
        setLoading(true);
        const update = {
            id: selectedRow.id,
            ...updateData,
        };

        patcher(ContactURL, update).then((res) => {
            setLoading(false);
            // console.log(res.error);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                dispatch(setRefresh(!refreshTable));
                setSuccess(true);
                setError(false);
                props.close();
            }
        });
    };

    const otherDetails = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Autocomplete
                        value={optionSelect2}
                        loading={options2.length < 1 ? true : false}
                        disablePortal
                        options={options2}
                        onChange={(event, newValue) => {
                            setOptionSelect2(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Currency"
                                onChange={(event) => {
                                    setFilter2(event.target.value);
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        value={optionSelect3}
                        loading={options3.length < 1 ? true : false}
                        disablePortal
                        options={options3}
                        onChange={(event, newValue) => {
                            setOptionSelect3(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Payment Term"
                                onChange={(event) => {
                                    setFilter3(event.target.value);
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldWrapper
                        name="remark"
                        label="Remark"
                        variant="outlined"
                        multiline
                    />
                </Grid>
            </Grid>
        );
    };

    const contactAddresses = () => {
        return (
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography fontWeight={600}>
                            Billing Address
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldWrapper
                            name={`contactAddresses.${0}.attention`}
                            label="Attention"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldWrapper
                            name={`contactAddresses.${0}.street1`}
                            label="Street 1"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldWrapper
                            name={`contactAddresses.${0}.street2`}
                            label="Street 2"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldWrapper
                            name={`contactAddresses.${0}.city`}
                            label="City"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldWrapper
                            name={`contactAddresses.${0}.state`}
                            label="State"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldWrapper
                            name={`contactAddresses.${0}.phone`}
                            label="Phone"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldWrapper
                            name={`contactAddresses.${0}.fax`}
                            label="Fax"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldWrapper
                            name={`contactAddresses.${0}.zip_code`}
                            label="Zip Code"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            value={optionSelect4[0]}
                            loading={options4.length < 1 ? true : false}
                            disablePortal
                            options={options4}
                            onChange={(event, newValue) => {
                                setOptionSelect4({
                                    ...optionSelect4,
                                    0: newValue,
                                });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Country"
                                    onChange={(event) => {
                                        setFilter4(event.target.value);
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Divider
                    orientation="horizontal"
                    variant="middle"
                    style={{
                        border: "silver solid 0.5px",
                    }}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography fontWeight={600}>
                            Shiping Address
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldWrapper
                            name={`contactAddresses.${1}.attention`}
                            label="Attention"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldWrapper
                            name={`contactAddresses.${1}.street1`}
                            label="Street 1"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldWrapper
                            name={`contactAddresses.${1}.street2`}
                            label="Street 2"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldWrapper
                            name={`contactAddresses.${1}.city`}
                            label="City"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldWrapper
                            name={`contactAddresses.${1}.state`}
                            label="State"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldWrapper
                            name={`contactAddresses.${1}.phone`}
                            label="Phone"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldWrapper
                            name={`contactAddresses.${1}.fax`}
                            label="Fax"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldWrapper
                            name={`contactAddresses.${1}.zip_code`}
                            label="Zip Code"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            value={optionSelect4[1]}
                            loading={options4.length < 1 ? true : false}
                            disablePortal
                            options={options4}
                            onChange={(event, newValue) => {
                                setOptionSelect4({
                                    ...optionSelect4,
                                    1: newValue,
                                });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Country"
                                    onChange={(event) => {
                                        setFilter4(event.target.value);
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    };

    const contactPersons = (key) => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextFieldWrapper
                        name={`contactPersons.${key}.first_name`}
                        label="First Name"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextFieldWrapper
                        name={`contactPersons.${key}.last_name`}
                        label="Last Name"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextFieldWrapper
                        name={`contactPersons.${key}.work_phone`}
                        label="Work Phone"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextFieldWrapper
                        name={`contactPersons.${key}.mobile`}
                        label="Mobile"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextFieldWrapper
                        name={`contactPersons.${key}.email`}
                        label="Email"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextFieldWrapper
                        name={`contactPersons.${key}.designation`}
                        label="Designation"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextFieldWrapper
                        name={`contactPersons.${key}.department`}
                        label="Department"
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={4}>
                    <Autocomplete
                        value={optionSelect5[key]}
                        loading={options5.length < 1 ? true : false}
                        disablePortal
                        options={options5}
                        onChange={(event, newValue) => {
                            setOptionSelect5({
                                ...optionSelect5,
                                [key]: newValue,
                            });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Title"
                                onChange={(event) => {
                                    setFilter5(event.target.value);
                                }}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={4}>
                    <FormControl
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <FormLabel>Is Primary Contact</FormLabel>
                        <Checkbox
                            name={`contactPersons.${key}.is_primary_contact`}
                            checked={IsPrimaryContact[key]}
                            onChange={(event) => {
                                setIsPrimaryContact({
                                    ...IsPrimaryContact,
                                    [key]: event.target.checked,
                                });
                            }}
                        />
                    </FormControl>
                </Grid>
                <Box width={"100%"} marginTop="2ch">
                    <Divider />
                </Box>
            </Grid>
        );
    };

    const addContactPerson = () => {
        const max = Math.max(...contactPersonsLength);
        setContactPersonsLength([...contactPersonsLength, max + 1]);
    };

    const cancelContactPerson = (i) => {
        if (contactPersonsLength.length > 1) {
            const theRest = contactPersonsLength.filter((a) => a !== i);
            setContactPersonsLength([...theRest]);
        }
    };

    return (
        <div>
            <TitleHeader title="Add Contact" icon={<AddRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        values.contact_type = ContactType;

                        optionsId1.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.account_code}` ===
                                optionSelect1
                            ) {
                                values.chart_of_account_id = name.id;
                            }
                        });
                        optionsId2.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.name}` === optionSelect2
                            ) {
                                values.currency_id = name.id;
                            }
                        });
                        optionsId3.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.show_it_as}` ===
                                optionSelect3
                            ) {
                                values.payment_term_id = name.id;
                            }
                        });

                        values.contactAddresses[0].country_id = parseInt(
                            optionSelect4[0]?.split("-")[0]
                        );
                        values.contactAddresses[1].country_id = parseInt(
                            optionSelect4[1]?.split("-")[0]
                        );

                        values.contactPersons = contactPersonsLength.map(
                            (data) => {
                                values.contactPersons[data].title_id = parseInt(
                                    optionSelect5[data]?.split("-")[0]
                                );
                                values.contactPersons[data].is_primary_contact =
                                    IsPrimaryContact[data];
                                return values.contactPersons[data];
                            }
                        );

                        const valueToBeSent = {
                            contactPersons: values.contactPersons,
                            contactAddresses: values.contactAddresses,
                            contact: {
                                chart_of_account_id: values.chart_of_account_id,
                                company_name: values.company_name,
                                contact_display_name:
                                    values.contact_display_name,
                                contact_type: values.contact_type,
                                currency_id: values.currency_id,
                                isProtectedForEdit: values.isProtectedForEdit,
                                payment_term_id: values.payment_term_id,
                                remark: values.remark,
                            },
                        };

                        // console.log(values);
                        formSubmitHandler(valueToBeSent, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    select
                                    name="contact_type"
                                    label="Contact Type"
                                    variant="outlined"
                                    value={ContactType}
                                    options={CONTACT_TYPE ?? " "}
                                    onChange={(e) => {
                                        setContactType(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="company_name"
                                    label="Company Name"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="contact_display_name"
                                    label="Contact Display Name"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect1}
                                    loading={options1.length < 1 ? true : false}
                                    disablePortal
                                    options={options1}
                                    onChange={(event, newValue) => {
                                        setOptionSelect1(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Chart Of Account"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Box minHeight={"4ch"} width={"100%"} />
                            <HorizontalStepper
                                steps={steps}
                                otherDetails={otherDetails}
                                contactAddress={contactAddresses}
                                contactPerson={contactPersons}
                                contactPersonsLength={contactPersonsLength}
                                loading={loading}
                                addContactPerson={addContactPerson}
                                cancelContactPerson={cancelContactPerson}
                                buttonLabel={"Apply"}
                            />

                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default EditContact;
