import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { EditRounded } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { patcher, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    PayrollDetailURL,
    PayrollSummaryURL,
    SalaryComponentURL,
} from "../../../../utils/config";

const EditPayrollDetail = (props) => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState("");
    const [filter2, setFilter2] = useState("");
    const [options1, setOptions1] = useState([]);
    const [optionsId1, setOptionsID1] = useState();
    const [optionSelect1, setOptionSelect1] = useState("");
    const [options2, setOptions2] = useState([]);
    const [optionsId2, setOptionsID2] = useState();
    const [optionSelect2, setOptionSelect2] = useState("");
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const [IsEarning, setIsEarning] = useState(selectedRow.isEarning);
    const [IsEmployerPart, setIsEmployerPart] = useState(
        selectedRow.isEmployerPart
    );
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [msg, setMsg] = useState("");
    const [PayrollComponent, setPayrollComponent] = useState(
        selectedRow.payroll_component
    );
    const [PayrollPostingEntryType, setPayrollPostingEntryType] = useState(
        selectedRow.payroll_posting_entry_type
    );

    const PAYROLL_COMPONENT = [
        "Basic",
        "Earning",
        "Deduction",
        "Loan",
        "Income Tax",
        "Overtime",
        "Employeer Pension",
        "Earning Adj",
        "Deduction Adj",
        "Employee Pension",
        "Lost Time",
        "Net Pay",
        "Penality",
    ];
    const PAYROLL_POSTING_ENTRY_TYPE = [
        "Gross",
        "Income Tax",
        "Other Deduction",
        "Employeer Tax",
    ];

    useEffect(() => {
        if (optionSelect1 === "")
            setOptionSelect1(selectedRow.payroll_summary?.id);
        if (optionSelect2 === "")
            setOptionSelect2(
                `${selectedRow.salary_component?.id} - ${selectedRow.salaryComponent}`
            );
    }, [selectedRow, optionSelect1, optionSelect2]);

    const INITIAL_VALUES = {
        description: selectedRow.description,
        total_amount: selectedRow.total_amount,
        payroll_component: PayrollComponent,
        isEmployerPart: IsEmployerPart,
        isEarning: IsEarning,
        payroll_posting_entry_type: PayrollPostingEntryType,
        payroll_summary_id: selectedRow.payroll_summary?.id,
        salary_component_id: selectedRow.salary_component?.id,
        isProtectedForEdit: false,
    };

    const FORM_VALIDATION = Yup.object().shape({
        description: Yup.string().min(1),
        total_amount: Yup.number().min(1),
        payroll_component: Yup.number().min(1).required("*Required"),
        isEmployerPart: Yup.string().min(1),
        isEarning: Yup.string().min(1),
        payroll_posting_entry_type: Yup.number().min(1).required("*Required"),
        // payroll_summary_id: Yup.number().min(1),
        // salary_component_id: Yup.number().min(1).required("*Required"),
    });
    useEffect(() => {
        get(PayrollSummaryURL, {
            limit: 10,
            filter: {
                id: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push((res.data[index]?.id).toString());
                });
            res && setOptionsID1(res.data);
            setOptions1(data);
        });

        get(SalaryComponentURL, {
            limit: 10,
            filter: {
                name: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.name}`
                    );
                });
            res && setOptionsID2(res.data);
            setOptions2(data);
        });
    }, [filter, filter2]);

    const formSubmitHandler = (updateData, actions) => {
        setLoading(true);
        const update = {
            id: selectedRow.id,
            updateData,
        };

        patcher(PayrollDetailURL, update).then((res) => {
            setLoading(false);
            // console.log(res.error);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                dispatch(setRefresh(!refreshTable));
                setSuccess(true);
                setError(false);
                props.close();
            }
        });
    };

    return (
        <div>
            <TitleHeader title="Edit Payroll Detail" icon={<EditRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        values.isEarning = IsEarning;
                        values.isEmployerPart = IsEmployerPart;
                        values.payroll_component = PayrollComponent;
                        values.payroll_posting_entry_type =
                            PayrollPostingEntryType;

                        optionsId1.forEach((name, index) => {
                            if (name?.id === parseInt(optionSelect1)) {
                                values.payroll_summary_id = name.id;
                            }
                        });
                        optionsId2.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.name}` === optionSelect2
                            ) {
                                values.salary_component_id = name.id;
                            }
                        });

                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextFieldWrapper
                                    name="description"
                                    label="Description"
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="total_amount"
                                    label="Total Amount"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    select
                                    name="payroll_component"
                                    label="Payroll Component"
                                    variant="outlined"
                                    options={PAYROLL_COMPONENT}
                                    value={PayrollComponent}
                                    onChange={(e) => {
                                        setPayrollComponent(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>Is Earning</FormLabel>
                                    <Checkbox
                                        name="isEarning"
                                        checked={IsEarning}
                                        onChange={(event) => {
                                            setIsEarning(event.target.checked);
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>Is Employer Part</FormLabel>
                                    <Checkbox
                                        name="isEmployerPart"
                                        checked={IsEmployerPart}
                                        onChange={(event) => {
                                            setIsEmployerPart(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    select
                                    name="payroll_posting_entry_type"
                                    label="Payroll Posting Entry Type"
                                    variant="outlined"
                                    options={PAYROLL_POSTING_ENTRY_TYPE}
                                    value={PayrollPostingEntryType}
                                    onChange={(e) => {
                                        setPayrollPostingEntryType(
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect1}
                                    loading={options1.length < 1 ? true : false}
                                    disablePortal
                                    options={options1}
                                    onChange={(event, newValue) => {
                                        setOptionSelect1(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Payroll Summary"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect2}
                                    loading={options2.length < 1 ? true : false}
                                    disablePortal
                                    options={options2}
                                    onChange={(event, newValue) => {
                                        setOptionSelect2(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Salary Component"
                                            onChange={(event) => {
                                                setFilter2(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Apply"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default EditPayrollDetail;
