import { LoadingButton } from "@mui/lab";
import {
    Grid,
    FormControl,
    FormLabel,
    Checkbox,
    Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { SnackBar } from "../../../../Components/SnackBar/SnackBar.component";
import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import { caller, get } from "../../../../utils/Caller";
import { ColumnsURL, TemplateURL } from "../../../../utils/config";

const AddGeneralExportTemplate = ({ type }) => {
    const [templateColumns, setTemplateColumns] = useState([]);
    const [selectedColumn, setSelectedColumn] = useState([]);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState("");

    const INITIAL_VALUES = {};

    const FORM_VALIDATION = Yup.object().shape({});

    const toSentence = (str) => {
        const toCamel = (s) => {
            return s.replace(/([-_][a-z])/gi, ($1) => {
                return $1.toUpperCase().replace("-", "").replace("_", "");
            });
        };

        let text = toCamel(str);
        let result = text.replace(/([A-Z])/g, " $1");
        let finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult;
    };

    useEffect(() => {
        let availColumns = [];
        get(ColumnsURL, { module_name: type })
            .then((res) => {
                // console.log(res);
                res.data?.columns.forEach((column, index) => {
                    let title = toSentence(column);
                    availColumns.push(title);
                });
                res && setTemplateColumns(availColumns);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [type]);

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        caller(TemplateURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                actions.resetForm();
            }
        });
    };

    return (
        <div
            className="AddGeneralExport"
            style={{
                maxWidth: window.innerWidth * (3 / 4),
                maxHeight: "70vh",
                overflowY: "scroll",
            }}
        >
            <Formik
                initialValues={{ ...INITIAL_VALUES }}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values, actions) => {
                    let name = [];
                    let export_template = [];
                    selectedColumn.forEach((column, index) => {
                        if (values[`${column} - ${templateColumns[column]}`]) {
                            name.push(
                                values[`${column} - ${templateColumns[column]}`]
                            );
                        } else {
                            name.push(templateColumns[column]);
                        }
                        export_template.push(column + 1)
                    });
                    const data = {
                        template_name: values["template_name"],
                        excel_columns: name,
                        export_template_fields: export_template,
                        module_name: type,
                    };
                    formSubmitHandler(data, actions);
                }}
            >
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextFieldWrapper
                                name={"template_name"}
                                label={`Template Name`}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography fontWeight={600}>
                                Available Fields
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography fontWeight={600}>Show As</Typography>
                        </Grid>

                        {templateColumns?.map((name, index) => {
                            return (
                                <>
                                    <Grid item xs={6}>
                                        <FormControl
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Checkbox
                                                name={name}
                                                checked={Boolean(
                                                    selectedColumn.indexOf(
                                                        index
                                                    ) !== -1
                                                )}
                                                onChange={(event) => {
                                                    if (
                                                        selectedColumn.indexOf(
                                                            index
                                                        ) !== -1
                                                    ) {
                                                        setSelectedColumn(
                                                            selectedColumn.filter(
                                                                (key) =>
                                                                    key !==
                                                                    index
                                                            )
                                                        );
                                                    } else {
                                                        setSelectedColumn([
                                                            ...selectedColumn,
                                                            index,
                                                        ]);
                                                    }
                                                }}
                                            />
                                            <FormLabel>{name}</FormLabel>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextFieldWrapper
                                            size={"small"}
                                            placeholder={name}
                                            name={`${index} - ${name}`}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </>
                            );
                        })}
                    </Grid>
                    <LoadingButton
                        data-testid="AddButton"
                        loading={loading}
                        type="submit"
                        className="ButtonContained"
                        style={{
                            marginTop: "2ch",
                            float: "right",
                        }}
                    >
                        {loading ? null : "Save"}
                    </LoadingButton>
                </Form>
            </Formik>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};

export default AddGeneralExportTemplate;
