import { Add, SearchRounded } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import Table from "../../../../Components/Table/Table.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { get } from "../../../../utils/Caller";
import { FinancialSettingsURL } from "../../../../utils/config";

const REPORT_BASIS = ["Accrual", "Cash", "Both"];
const FISCAL_YEAR = [
    "January - December",
    "February - January",
    "March - February",
    "April - March",
    "May - April",
    "June - May",
    "July - June",
    "August - July",
    "September - August",
    "October - September",
    "November - October",
    "December - November",
];
const CLOSING_TYPE = ["Both", "Month End", "Year End"];
const COMPANY_ID = [
    "ACN",
    "BN",
    "CN",
    "CPR",
    "CVR",
    "DIW",
    "KT",
    "ORG",
    "SEC",
    "COMPANY_ID",
];
const TAX_ID = ["ABN", "BN", "CST", "ORG", "TAX", "VST", "TAX_ID"];

const ViewFinancialSettings = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(20);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState();
    const [Search, setSearch] = useState();

    const columns = [
        {
            field: "reportBasis",
            headerName: "Report Basis",
            width: 150,
        },
        {
            field: "fiscalYear",
            headerName: "Fiscal Year",
            width: 150,
        },
        {
            field: "closingType",
            headerName: "Closing Type",
            width: 150,
        },
        {
            field: "currencyName",
            headerName: "Base Currency",
            width: 150,
        },
        {
            field: "timezone",
            headerName: "Timezone",
            width: 150,
        },
        {
            field: "companyId",
            headerName: "Company Id",
            width: 150,
        },
        {
            field: "taxId",
            headerName: "Tax Id",
            width: 150,
        },
        {
            field: "tax_id_number",
            headerName: "Tax Id Number",
            width: 150,
        },
        {
            field: "auto_generate_journal_preference",
            headerName: "Auto Generate Journal Preference",
            width: 150,
        },
        {
            field: "is_acc_code_mandatory",
            headerName: "Is Acc Code Mandatory",
            width: 150,
        },
        {
            field: "enter_unique_acc_code",
            headerName: "Enter Unique Acc Code",
            width: 150,
        },
        {
            field: "is_profile_tax_applied",
            headerName: "Is Profile Tax Applied",
            width: 150,
        },
        {
            field: "is_legal_reserve_applied",
            headerName: "Is Legal Reserve Applied",
            width: 150,
        },
        {
            field: "profit_tax",
            headerName: "Profit Tax",
            width: 150,
        },
        {
            field: "legal_reserve",
            headerName: "Legal Reserve",
            width: 150,
        },
        {
            field: "company_id_number",
            headerName: "Company Id Number",
            width: 150,
        },
        {
            field: "timeFormat",
            headerName: "Time Format",
            width: 150,
        },
        {
            field: "languageName",
            headerName: "Language",
            width: 150,
        },
    ];

    useEffect(() => {
        let tableData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(FinancialSettingsURL, {
            limit: limit,
            filter: { all: filter },
        }).then((res) => {
            // console.log(res);
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    tableData.push({
                        ...data,
                        currencyName: data.base_currency?.name,
                        timeFormat: data.time_format?.date_format,
                        languageName: data.language?.name,
                        reportBasis: REPORT_BASIS[data.report_basis - 1],
                        fiscalYear: FISCAL_YEAR[data.fiscal_year - 1],
                        closingType: CLOSING_TYPE[data.closing_type - 1],
                        companyId: COMPANY_ID[data.company_id - 1],
                        taxId: TAX_ID[data.tax_id - 1],
                    });
                });
            setRows(tableData);
        });
    }, [refreshTable, filter, limit, rows.length]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setloading(true);
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <div style={{ display: "flex", gap: "2ch" }}>
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<Add />}
                        onClick={() => {
                            navigate(
                                "/View/Finance/FinancialSettings/AddFinancialSettings"
                            );
                        }}
                    >
                        Add Financial Settings
                    </Button>
                </div>
            </div>
            <div className="TableContainer">
                <Table
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                />
            </div>
        </div>
    );
};

export default ViewFinancialSettings;
