import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import AddInstructionGiven from "./AddInstructionGiven.page";
import ViewInstructionGiven from "./ViewInstructionGiven.page";

const InstructionGivenOnProject = ({ next, name, handleTabChange }) => {
    const location = useLocation();
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    return (
        <>
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/InstructionGiven/AddInstructionGiven` && (
                <AddInstructionGiven
                    next={next}
                    name={name}
                    handleTabChange={handleTabChange}
                />
            )}
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/InstructionGiven` && (
                <>
                    <ViewInstructionGiven />
                </>
            )}
        </>
    );
};

export default InstructionGivenOnProject;
