import {
    Autocomplete,
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Stack,
    TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { caller, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    BusinessUnitURL,
    JobTitleURL,
    EmployeeURL,
    CalendarURL,
} from "../../../../utils/config";
import { Box } from "@mui/system";

const AddCalendar = () => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState("");
    const [filter2, setFilter2] = useState("");
    const [filter3, setFilter3] = useState("");
    const [options, setOptions] = useState([]);
    const [optionSelect, setOptionSelect] = useState([]);
    const [options2, setOptions2] = useState([]);
    const [optionSelect2, setOptionSelect2] = useState([]);
    const [options3, setOptions3] = useState([]);
    const [optionSelect3, setOptionSelect3] = useState([]);
    const [msg, setMsg] = useState("");
    const [allEmployees, setAllEmployees] = useState(false);

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        date: "",
        start_timme: "",
        end_time: "",
        description: "",
        title: "",
        all_employees: "",
        employee_id: [],
        business_unit_id: [],
        job_title_id: [],
    };

    const FORM_VALIDATION = Yup.object().shape({
        date: Yup.string().required("*Required"),
        title: Yup.string().required("*Required"),
    });

    useEffect(() => {
        get(EmployeeURL, {
            limit: 10,
            filter: {
                all: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].first_name}`
                    );
                });
            setOptions(data);
        });
        get(BusinessUnitURL, {
            limit: 10,
            filter: {
                all: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].name}`
                    );
                });
            setOptions2(data);
        });
        get(JobTitleURL, {
            limit: 10,
            filter: {
                all: filter3,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].title_name}`
                    );
                });
            setOptions3(data);
        });
    }, [filter, filter2, filter3]);

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        caller(CalendarURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                actions.resetForm();
            }
        });
    };

    return (
        <div>
            <TitleHeader title="Add Calendar" icon={<AddRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        optionSelect?.forEach((option) => {
                            let employee_id = parseInt(option.split("-")[0]);
                            values.employee_id.push(employee_id);
                        });
                        optionSelect2?.forEach((option, index) => {
                            let business_unit_id = parseInt(
                                option.split("-")[0]
                            );
                            values.business_unit_id.push(business_unit_id);
                        });
                        optionSelect3?.forEach((option, index) => {
                            let job_title_id = parseInt(option.split("-")[0]);
                            values.job_title_id.push(job_title_id);
                        });
                        values.start_timme =
                            new Date(values.date).toDateString() +
                            " " +
                            values.start_timme;
                        values.end_time =
                            new Date(values.date).toDateString() +
                            " " +
                            values.end_time;
                        values.all_employees = allEmployees;
                        console.log(values);
                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>Date</FormLabel>
                                    <TextFieldWrapper
                                        name="date"
                                        variant="outlined"
                                        type="date"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>Start Time</FormLabel>
                                    <TextFieldWrapper
                                        name="start_timme"
                                        variant="outlined"
                                        type="time"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>End Time</FormLabel>
                                    <TextFieldWrapper
                                        name="end_time"
                                        variant="outlined"
                                        type="time"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="title"
                                    label="Title"
                                    variant="outlined"
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={12}>
                                <TextFieldWrapper
                                    name="description"
                                    label="Description"
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={allEmployees}
                                        onChange={(event) => {
                                            setAllEmployees(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>All Employees</FormLabel>
                                </FormControl>
                            </Grid>
                            {!allEmployees && (
                                <>
                                    <Box width={"100%"} />
                                    {optionSelect2.length === 0 &&
                                        optionSelect3.length === 0 && (
                                            <Grid item xs={6}>
                                                <Stack spacing={3}>
                                                    <Autocomplete
                                                        multiple
                                                        getOptionLabel={(
                                                            option
                                                        ) => option || ""}
                                                        filterSelectedOptions
                                                        loading={
                                                            options.length < 1
                                                                ? true
                                                                : false
                                                        }
                                                        options={options}
                                                        onChange={(
                                                            _event,
                                                            newValue
                                                        ) => {
                                                            setOptionSelect(
                                                                newValue
                                                            );
                                                        }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label="Employee"
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    setFilter(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                        )}
                                    {optionSelect.length === 0 &&
                                        optionSelect3.length === 0 && (
                                            <Grid item xs={6}>
                                                <Stack spacing={3}>
                                                    <Autocomplete
                                                        multiple
                                                        getOptionLabel={(
                                                            option
                                                        ) => option || ""}
                                                        filterSelectedOptions
                                                        loading={
                                                            options2.length < 1
                                                                ? true
                                                                : false
                                                        }
                                                        options={options2}
                                                        onChange={(
                                                            _event,
                                                            newValue
                                                        ) => {
                                                            setOptionSelect2(
                                                                newValue
                                                            );
                                                        }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label="Business Unit"
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    setFilter2(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                        )}
                                    {optionSelect.length === 0 &&
                                        optionSelect2.length === 0 && (
                                            <Grid item xs={6}>
                                                <Stack spacing={3}>
                                                    <Autocomplete
                                                        multiple
                                                        getOptionLabel={(
                                                            option
                                                        ) => option || ""}
                                                        filterSelectedOptions
                                                        loading={
                                                            options3.length < 1
                                                                ? true
                                                                : false
                                                        }
                                                        options={options3}
                                                        onChange={(
                                                            _event,
                                                            newValue
                                                        ) => {
                                                            setOptionSelect3(
                                                                newValue
                                                            );
                                                        }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label="Job Title"
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    setFilter3(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                        )}
                                    <Box width={"100%"} />
                                </>
                            )}

                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default AddCalendar;
