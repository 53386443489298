import React from "react";
import { useLocation } from "react-router";
import AddPriority from "./AddPriority.page";
import ViewPriority from "./ViewPriority.page";

const Priority = () => {
    const location = useLocation();

    return (
        <>
            {location.pathname === "/View/Priority/AddPriority" && (
                <AddPriority />
            )}
            {location.pathname === "/View/Priority" && <ViewPriority />}
        </>
    );
};

export default Priority;
