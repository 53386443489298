import { Add, SearchRounded } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import Table from "../../../../Components/Table/Table.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { get } from "../../../../utils/Caller";
import { AttendancePayrollURL } from "../../../../utils/config";

const ATTENDANCE_STATUS = [
    "Pending",
    "Released",
    "Approved",
    "Rejected",
    "Locked",
];

const ViewAttendancePayroll = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(20);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState(null);
    const [Search, setSearch] = useState();

    const columns = [
        {
            field: "date",
            headerName: "Date",
            width: 150,
        },
        {
            field: "total_worked_hours",
            headerName: "Total Worked Hours",
            width: 150,
        },
        {
            field: "attendanceStatus",
            headerName: "Attendance Status",
            width: 150,
        },
        {
            field: "action_date",
            headerName: "Action Date",
            width: 150,
        },
        {
            field: "delegated_username",
            headerName: "Delegated Username",
            width: 150,
        },
        {
            field: "attendanceAbscenceType",
            headerName: "Attendance Abscence Type",
            width: 150,
        },
        {
            field: "employeeName",
            headerName: "Employee",
            width: 150,
        },
    ];

    useEffect(() => {
        let tableData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(AttendancePayrollURL, {
            limit: limit,
            filter: { all: filter },
        }).then((res) => {
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    tableData.push({
                        ...data,
                        attendanceAbscenceType:
                            data.attendance_abscence_type?.aa_description,
                        employeeName: data.employee?.first_name,
                        attendanceStatus:
                            ATTENDANCE_STATUS[data.attendance_status - 1],
                    });
                });
            setRows(tableData);
        });
    }, [refreshTable, filter, limit, rows.length]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    type="number"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setloading(true);
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <div style={{ display: "flex", gap: "2ch" }}>
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<Add />}
                        onClick={() => {
                            navigate(
                                "/View/HCM/AttendancePayroll/AddAttendancePayroll"
                            );
                        }}
                    >
                        Add Attendance Payroll
                    </Button>
                </div>
            </div>
            <div className="TableContainer">
                <Table
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                />
            </div>
        </div>
    );
};

export default ViewAttendancePayroll;
