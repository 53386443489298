import {
    Grid,
    Paper,
    Divider,
    Typography,
    List,
    ListItemButton,
    ListItemText,
    IconButton,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router";

import "../../../../Assets/Sass/AddProject.style.scss";
import { useSelector } from "react-redux";
import ViewVacancyExaminer from "../VacancyExaminer/ViewVacancyExaminer.page";
import ViewVacancyInternalApplicant from "../VacancyInternalApplicant/ViewVacancyInternalApplicant.page";
import ViewExternalApplicant from "../ExternalApplicant/ViewExternalApplicant.page";
import EditVacancy from "./EditVacancy.page";
import ViewVacancyResult from "../VacancyResult/ViewVacancyResult.page";
import { Box } from "@mui/system";
import dateFormatter from "../../../../utils/dateFormater";
import AddVacancyExaminer from "../VacancyExaminer/AddVacancyExaminer.page";
import AddExternalApplicant from "../ExternalApplicant/AddExternalApplicant.page";
import AddVacancyInternalApplicant from "../VacancyInternalApplicant/AddVacancyInternalApplicant.page";
import AddVacancyResult from "../VacancyResult/AddVacancyResult.page";
import { ChevronLeft, Menu } from "@mui/icons-material";

const VacancyDetail = () => {
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const [selectedIndex, setSelectedIndex] = useState();
    const navigate = useNavigate();
    const location = useLocation();

    const VACANCY = [
        "Vacancy Examiner",
        "External Applicant",
        "Internal Applicant",
        "Result",
    ];
    const VACANCYPATH = [
        `/View/HCM/Vacancy/${selectedRow?.id}/VacancyExaminer`,
        `/View/HCM/Vacancy/${selectedRow?.id}/ExternalApplicant`,
        `/View/HCM/Vacancy/${selectedRow?.id}/VacancyInternalApplicant`,
        `/View/HCM/Vacancy/${selectedRow?.id}/VacancyResult`,
        `/View/HCM/Vacancy/${selectedRow?.id}/VacancyExaminer/AddVacancyExaminer`,
        `/View/HCM/Vacancy/${selectedRow?.id}/ExternalApplicant/AddExternalApplicant`,
        `/View/HCM/Vacancy/${selectedRow?.id}/VacancyInternalApplicant/AddVacancyInternalApplicant`,
        `/View/HCM/Vacancy/${selectedRow?.id}/VacancyResult/AddVacancyResult`,
    ];

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        navigate(`${VACANCYPATH[index]}`);
    };

    const vacancyDetail = () => {
        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Typography fontWeight={600}>Job Title</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>{selectedRow?.jobTitle}</Typography>
                    </Grid>
                    <Box width={`100%`} />
                    <Grid item xs={4}>
                        <Typography fontWeight={600}>
                            No. Of Position
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>
                            {selectedRow?.number_of_position}
                        </Typography>
                    </Grid>
                    <Box width={`100%`} />
                    <Grid item xs={4}>
                        <Typography fontWeight={600}>Vacancy Status</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>{selectedRow?.vacancyStatus}</Typography>
                    </Grid>
                    <Box width={`100%`} />
                    <Grid item xs={4}>
                        <Typography fontWeight={600}>Opening Date</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>
                            {dateFormatter(selectedRow?.opening_date)}
                        </Typography>
                    </Grid>
                    <Box width={`100%`} />
                    <Grid item xs={4}>
                        <Typography fontWeight={600}>Closing Date</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>
                            {dateFormatter(selectedRow?.closing_date)}
                        </Typography>
                    </Grid>
                    <Box width={`100%`} />
                    <Grid item xs={4}>
                        <Typography fontWeight={600}>Vacancy Type</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>{selectedRow?.vacancyType}</Typography>
                    </Grid>
                    <Box width={`100%`} />
                    <Grid item xs={4}>
                        <Typography fontWeight={600}>Vacancy Reason</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>
                            {selectedRow?.vacancyRequestReason}
                        </Typography>
                    </Grid>
                    <Box width={`100%`} />
                    <Grid item xs={4}>
                        <Typography fontWeight={600}>Remark</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>{selectedRow?.remark}</Typography>
                    </Grid>
                    <Box width={`100%`} />
                </Grid>
            </Box>
        );
    };

    return (
        <div>
            <Paper
                style={{ padding: "2ch", boxShadow: "0 0 20px silver" }}
                elevation={0}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: "2ch",
                        justifyContent: "space-between",
                    }}
                >
                    <div>
                        <IconButton
                            onClick={() => {
                                navigate("/View/HCM/Vacancy");
                            }}
                        >
                            <ChevronLeft />
                        </IconButton>
                    </div>
                    <div>
                        <Typography
                            style={{ fontWeight: "700", color: "#14213d" }}
                        >
                            Vacancy Detail
                        </Typography>
                    </div>
                    <div>
                        <IconButton
                            onClick={() =>
                                navigate(
                                    `/View/HCM/Vacancy/${selectedRow?.id}/`
                                )
                            }
                        >
                            <Menu color={"primary"} />
                        </IconButton>
                    </div>
                </div>
                <Divider />
                <Grid container spacing={2} style={{ paddingTop: "2ch" }}>
                    <Grid item xs={2}>
                        <Paper
                            variant="outlined"
                            style={{
                                backgroundColor: "#f5f5f5",
                                maxHeight: "100vh",
                                overflowY: "scroll",
                            }}
                        >
                            <List component="nav">
                                {VACANCY.map((name, index) => {
                                    return (
                                        <ListItemButton
                                            key={index}
                                            selected={selectedIndex === index}
                                            onClick={(event) =>
                                                handleListItemClick(
                                                    event,
                                                    index
                                                )
                                            }
                                            style={{
                                                backgroundColor:
                                                    selectedIndex === index &&
                                                    "#14213d",

                                                color:
                                                    selectedIndex === index &&
                                                    "#ffffff",
                                            }}
                                        >
                                            <ListItemText
                                                primary={name}
                                                style={{
                                                    fontWeight:
                                                        "700 !important",
                                                }}
                                            />
                                        </ListItemButton>
                                    );
                                })}
                            </List>
                        </Paper>
                    </Grid>
                    <Grid item xs={10}>
                        <Paper
                            variant="outlined"
                            style={{
                                padding: "2ch",
                                maxHeight: "100vh",
                                overflow: "scroll",
                            }}
                        >
                            {location.pathname.match(
                                /\/View\/HCM\/Vacancy\/[0-9]+\/$/
                            ) && vacancyDetail()}
                            {location.pathname === `${VACANCYPATH[0]}` && (
                                <ViewVacancyExaminer />
                            )}
                            {location.pathname === `${VACANCYPATH[1]}` && (
                                <ViewExternalApplicant />
                            )}
                            {location.pathname === `${VACANCYPATH[2]}` && (
                                <ViewVacancyInternalApplicant />
                            )}
                            {location.pathname === `${VACANCYPATH[3]}` && (
                                <ViewVacancyResult />
                            )}
                            {location.pathname === `${VACANCYPATH[4]}` && (
                                <AddVacancyExaminer />
                            )}
                            {location.pathname === `${VACANCYPATH[5]}` && (
                                <AddExternalApplicant />
                            )}
                            {location.pathname === `${VACANCYPATH[6]}` && (
                                <AddVacancyInternalApplicant />
                            )}
                            {location.pathname === `${VACANCYPATH[7]}` && (
                                <AddVacancyResult />
                            )}
                            {location.pathname ===
                                `/View/HCM/Vacancy/${selectedRow?.id}/Edit` && (
                                <EditVacancy />
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};
export default VacancyDetail;
