import React from "react";
import { useLocation } from "react-router";
import AddPhase from "./AddPhase.page";
import ViewPhase from "./ViewPhase.page";

const Phase = () => {
    const location = useLocation();

    return (
        <>
            {location.pathname === "/View/Phase/AddPhase" && <AddPhase />}
            {location.pathname === "/View/Phase" && <ViewPhase />}
        </>
    );
};

export default Phase;
