import { Add, SearchRounded } from "@mui/icons-material";
import { Button, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import Table from "../../../../Components/Table/Table.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { get } from "../../../../utils/Caller";
import { WarehouseURL } from "../../../../utils/config";

const ViewWarehouse = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(20);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState();

    const columns = [
        {
            field: "name",
            headerName: "Name",
            width: 150,
        },
        {
            field: "city",
            headerName: "City",
            width: 150,
        },
        {
            field: "state",
            headerName: "State",
            width: 150,
        },
        {
            field: "zip_code",
            headerName: "Zip Code",
            width: 150,
        },
        {
            field: "phone_no",
            headerName: "Phone No",
            width: 150,
        },
        {
            field: "email",
            headerName: "Email",
            width: 150,
        },
        {
            field: "is_primary",
            headerName: "Is Primary",
            width: 150,
        },
        {
            field: "country",
            headerName: "Country",
            width: 150,
        },
    ];

    useEffect(() => {
        let tableData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(WarehouseURL, {
            limit: limit,
            filter: { name: filter },
        }).then((res) => {
            console.log(res);
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    tableData.push({
                        ...data,
                        country: data.country.country_name,
                    });
                });
            setRows(tableData);
        });
    }, [refreshTable, filter, limit, rows.length]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <SearchRounded />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setFilter(e.target.value);
                        dispatch(setRefresh(!refreshTable));
                    }}
                />
                <div style={{ display: "flex", gap: "2ch" }}>
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<Add />}
                        onClick={() => {
                            navigate("/View/Inventory/Warehouse/AddWarehouse");
                        }}
                    >
                        Add Warehouse
                    </Button>
                </div>
            </div>
            <div className="TableContainer">
                <Table
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                />
            </div>
        </div>
    );
};

export default ViewWarehouse;
