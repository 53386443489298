import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { EditRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../../Components/TextFieldWrapper.component";
import "../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from ".././../../Components/SnackBar/SnackBar.component.jsx";
import { patcher } from "../../../utils/Caller";
import TitleHeader from "../../../Components/TitleHeader/TitleHeader.component";
import { GetUser } from "../../../utils/config";

const EditProfile = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    // const phoneRegex = /^(251-)?\d{9}$/;
    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        username: props.user.username,
        email: props.user.email,
        phone_number: props.user.phone_number,
    };

    const FORM_VALIDATION = Yup.object().shape({
        username: Yup.string().min(2).required("*Required"),
        email: Yup.string().email("Enter Valid Email").required("*Required"),
        phone_number: Yup.string()
            // .matches(phoneRegex, "Enter a vaild Phone Number")
            .required("*Required"),
    });

    const formSubmitHandler = (updateData, actions) => {
        setLoading(true);
        const update = {
            id: props.user.id,
            updateData,
            concurrency_stamp: props.user.concurrency_stamp,
        };

        patcher(GetUser, update).then((res) => {
            setLoading(false);
            // console.log(res.error);
            if (res.error) {
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                props.close();
            }
        });
    };

    return (
        <div>
            <TitleHeader title="Edit Profile" icon={<EditRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextFieldWrapper
                                    name="username"
                                    label="User Name"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldWrapper
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldWrapper
                                    name="phone_number"
                                    label="Phone Number"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Apply"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? "Error Occured" : "Success"}
                />
            ) : null}
        </div>
    );
};
export default EditProfile;
