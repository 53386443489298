import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./Slice/userSlice";
import ThemeSlice from "./Slice/Theme";
import selectedRow from "./Slice/selectedRow";
import selectedDetail from "./Slice/selectedDetail";
import refreshSlice from "./Slice/refreshSlice";
import selectedToDo from "./Slice/selectedToDo";

export const store = configureStore({
    reducer: {
        userState: userSlice,
        mode: ThemeSlice,
        selectedRow: selectedRow,
        selectedDetail: selectedDetail,
        selectedToDo: selectedToDo,
        refreshTable: refreshSlice,
    },
});
