import { Box, Checkbox, FormControl, FormLabel, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { EditRounded } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { patcher } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import { AccountingPeriodURL } from "../../../../utils/config";
import dateFormatter from "../../../../utils/dateFormater";

const EditAccountingPeriod = (props) => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const [IsCurrentPostingPeriod, setIsCurrentPostingPeriod] = useState(
        selectedRow.is_current_posting_period
    );
    const [IsYearEndClosing, setIsYearEndClosing] = useState(
        selectedRow.is_year_end_closing
    );
    const [AccountingPeriodStatus, setAccountingPeriodStatus] = useState(
        selectedRow.accounting_period_status
    );
    const [Months, setMonths] = useState(selectedRow.months);
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [msg, setMsg] = useState("");

    const ACCOUNTING_PERIOD_STATUS = [
        "Open",
        "Closed",
        "Future",
        "Access Locked",
    ];
    const MONTHS = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "Augest",
        "September",
        "October",
        "November",
        "December",
        "Other",
    ];

    const INITIAL_VALUES = {
        period_number: selectedRow.period_number,
        period_starting_date: dateFormatter(selectedRow.period_starting_date),
        period_ending_date: dateFormatter(selectedRow.period_ending_date),
        isProtectedForEdit: false,
        months: Months,
        accounting_period_status: AccountingPeriodStatus,
        is_current_posting_period: IsCurrentPostingPeriod,
        is_year_end_closing: IsYearEndClosing,
    };

    const FORM_VALIDATION = Yup.object().shape({
        months: Yup.number().min(1).required("*Required"),
        period_number: Yup.number().min(1).required("*Required"),
        accounting_period_status: Yup.number().min(1).required("*Required"),
        is_current_posting_period: Yup.string().min(1).required("*Required"),
        is_year_end_closing: Yup.string().min(1).required("*Required"),
        period_starting_date: Yup.string().min(1).required("*Required"),
        period_ending_date: Yup.string().min(1).required("*Required"),
    });

    const formSubmitHandler = (updateData, actions) => {
        setLoading(true);
        const update = {
            id: selectedRow.id,
            updateData,
        };

        patcher(AccountingPeriodURL, update).then((res) => {
            setLoading(false);
            // console.log(res.error);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                dispatch(setRefresh(!refreshTable));
                setSuccess(true);
                setError(false);
                props.close();
            }
        });
    };

    return (
        <div>
            <TitleHeader
                title="Edit Accounting Period"
                icon={<EditRounded />}
            />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        values.months = Months;
                        values.accounting_period_status =
                            AccountingPeriodStatus;
                        values.is_current_posting_period =
                            IsCurrentPostingPeriod;
                        values.is_year_end_closing = IsYearEndClosing;
                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    select
                                    name="months"
                                    label="Months"
                                    variant="outlined"
                                    value={Months}
                                    options={MONTHS ?? " "}
                                    onChange={(e) => {
                                        setMonths(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="period_number"
                                    label="Period Number"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    select
                                    name="accounting_period_status"
                                    label="Accounting Period Status"
                                    variant="outlined"
                                    value={AccountingPeriodStatus}
                                    options={ACCOUNTING_PERIOD_STATUS ?? " "}
                                    onChange={(e) => {
                                        setAccountingPeriodStatus(
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>
                                        Is Current Posting Period
                                    </FormLabel>
                                    <Checkbox
                                        name="is_current_posting_period"
                                        checked={IsCurrentPostingPeriod}
                                        onChange={(event) => {
                                            setIsCurrentPostingPeriod(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>Is Year End Closing</FormLabel>
                                    <Checkbox
                                        name="is_year_end_closing"
                                        checked={IsYearEndClosing}
                                        onChange={(event) => {
                                            setIsYearEndClosing(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>Period Starting Date</FormLabel>
                                    <TextFieldWrapper
                                        name="period_starting_date"
                                        variant="outlined"
                                        type="date"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>Period Ending Date</FormLabel>
                                    <TextFieldWrapper
                                        name="period_ending_date"
                                        variant="outlined"
                                        type="date"
                                    />
                                </FormControl>
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Apply"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default EditAccountingPeriod;
