import { Add, SearchRounded } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { SimpleTaskURL } from "../../../../utils/config";

import { get } from "../../../../utils/Caller";
import OperationsTable from "../../../../Components/Table/OperationsTable.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";

const ViewSimpleTask = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(16);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const [filter, setFilter] = useState("");
    const dispatch = useDispatch();
    const [Search, setSearch] = useState("");

    const columns = [
        {
            field: "name",
            headerName: "Name",
            width: 100,
        },
        {
            field: "task_start_date",
            headerName: "Task Start Date",
            width: 100,
        },
        {
            field: "completed_percentage",
            headerName: "Completed Percentage",
            width: 100,
        },
        {
            field: "estimated_hours",
            headerName: "Estimated Hours",
            width: 100,
        },
        {
            field: "note",
            headerName: "Note",
            width: 200,
        },
        {
            field: "priorityName",
            headerName: "Priority",
            width: 100,
        },
        {
            field: "projectName",
            headerName: "Project Name",
            width: 100,
        },
    ];

    useEffect(() => {
        let requestData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(SimpleTaskURL, {
            limit: limit,
            filter: { project_id: selectedRow.id, all: filter },
        }).then((res) => {
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    requestData.push({
                        ...data,
                        priorityName: data.priority?.name,
                        projectName: data.project?.name,
                    });
                });
            setRows(requestData);
        });
    }, [refreshTable, limit, rows.length, selectedRow, filter]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <Button
                    variant="contained"
                    color="success"
                    endIcon={<Add />}
                    onClick={() => {
                        navigate(
                            `/View/ViewProject/${selectedRow.id}/SimpleTask/AddSimpleTask`
                        );
                    }}
                >
                    Add Simple Task
                </Button>
            </div>
            <div className="TableContainer">
                <OperationsTable
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                />
            </div>
        </div>
    );
};

export default ViewSimpleTask;
