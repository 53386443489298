import React, { useState, useEffect } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedDetail } from "../../Redux/Slice/selectedDetail";
import { Box, Modal } from "@mui/material";

import EditModal from "../../Layouts/Modal/EditModal.modal";
import DeleteModal from "../../Layouts/Modal/DeleteModal.modal";
import ViewSubTask from "../../Pages/Projects/Operational/SubTask/ViewSubTask.page";
import {
    DeleteRounded,
    EditRounded,
    MoreHorizRounded,
} from "@mui/icons-material";
import ViewWorkLog from "../../Pages/Projects/Operational/WorkLogAndBranchOfWork/ViewWorkLog.page";
import ViewToDos from "../../Pages/Projects/Operational/ToDos/ViewToDos.page";

const OperationsTable = (props) => {
    let columns = props.columns;

    const [rows, setRows] = useState(props.rows);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [subTaskModal, setSubTaskModal] = useState(false);
    const [workLogModal, setWorkLogModal] = useState(false);
    const [toDoModal, setToDoModal] = useState(false);
    const [pageNo, setPageNo] = useState(0);
    const [selectedTaskData, setSelectedTaskData] = useState();
    const [selectedDailyWorkLog, setSelectedDailyWorkLog] = useState();
    const user = useSelector((state) => state.userState.user);

    const dispatch = useDispatch();
    const selectedDetail = useSelector(
        (state) => state.selectedDetail.selected,
    );

    const editButtonHandler = (event, values) => {
        dispatch(setSelectedDetail(values.row));
        setEditModal(true);
    };

    const deleteButtonHandler = (event, values) => {
        dispatch(setSelectedDetail(values.row));
        setDeleteModal(true);
    };

    const subTaskButtonHandler = (event, values) => {
        setSubTaskModal(true);
        setSelectedTaskData(values);
    };

    const workLogButtonHandler = (event, values) => {
        setWorkLogModal(true);
        setSelectedDailyWorkLog(values);
    };

    const closeModal = () => {
        setEditModal(false);
        setDeleteModal(false);
        setWorkLogModal(false);
    };

    useEffect(() => {
        setRows(props.rows);
    }, [props.rows]);

    useEffect(() => {
        toDoModal
            ? setSubTaskModal(false)
            : !toDoModal && subTaskModal && setSubTaskModal(true);
    }, [subTaskModal, toDoModal]);

    // eslint-disable-next-line array-callback-return
    user.actions?.find((role) => {
        if (role === "PROJECT_ONE" || role === "admin" || role === "super") {
            if (props.actions === true) {
                columns = [
                    ...columns,
                    {
                        field: "actions",
                        headerName: "Actions",
                        type: "actions",
                        minWidth: 150,
                        flex: 1,
                        getActions: (cellValues) => [
                            <GridActionsCellItem
                                icon={<EditRounded color="primary" />}
                                label="Edit"
                                onClick={(event) => {
                                    // event.stopPropagation();
                                    editButtonHandler(event, cellValues);
                                }}
                            />,
                            <GridActionsCellItem
                                icon={<DeleteRounded color="error" />}
                                label="Delete"
                                onClick={(event) => {
                                    // event.stopPropagation();
                                    deleteButtonHandler(event, cellValues);
                                }}
                            />,
                        ],
                    },
                ];
            }
        }
    });

    if (props.subTask || props.workLog) {
        columns = [
            ...columns,
            {
                field: "subTask",
                headerName: props.subTask ? "View Subtask" : "View Worklog",
                type: "actions",
                width: 150,
                getActions: (cellValues) => [
                    <GridActionsCellItem
                        icon={<MoreHorizRounded color="primary" />}
                        label={
                            (props.subTask && "View Subtask",
                            props.workLog && "View Worklog")
                        }
                        onClick={(event) => {
                            props.subTask &&
                                subTaskButtonHandler(event, cellValues);
                            props.workLog &&
                                workLogButtonHandler(event, cellValues);
                        }}
                    />,
                ],
            },
        ];
    }

    return (
        <div style={{ height: 530, width: "100%" }}>
            <DataGrid
                onPageChange={(page) => {
                    if (pageNo < page) {
                        setPageNo(pageNo + 1);
                        props.addLimit();
                    }
                }}
                loading={props.loading}
                rows={rows}
                columns={columns}
                autoHeight={true}
                pageSize={8}
                rowsPerPageOptions={[8]}
                onRowClick={(selected) => {
                    props.viewDetail && props.viewDetail(selected.row);
                }}
            />
            <Modal
                className="Modal"
                open={
                    editModal ||
                    deleteModal ||
                    subTaskModal ||
                    workLogModal ||
                    toDoModal
                }
                onClose={() => {
                    setEditModal(false);
                    setDeleteModal(false);
                    setSubTaskModal(false);
                    setWorkLogModal(false);
                    setToDoModal(false);
                }}
            >
                <Box
                    className="ModalContainer"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        maxWidth: "90%",
                        width: "70%",
                    }}
                >
                    {editModal && <EditModal close={closeModal} />}
                    {deleteModal && (
                        <DeleteModal
                            name={selectedDetail.name}
                            id={selectedDetail.id}
                            close={closeModal}
                        />
                    )}
                    {subTaskModal && (
                        <ViewSubTask
                            close={closeModal}
                            taskManager={selectedTaskData}
                            setToDoModal={setToDoModal}
                        />
                    )}
                    {toDoModal && (
                        <ViewToDos
                            close={closeModal}
                            rows={toDoModal}
                            setToDoModal={setToDoModal}
                        />
                    )}
                    {workLogModal && (
                        <ViewWorkLog
                            close={closeModal}
                            workLog={selectedDailyWorkLog}
                        />
                    )}
                </Box>
            </Modal>
        </div>
    );
};

export default OperationsTable;
