import { Add, SearchRounded, Edit } from "@mui/icons-material";
import {
    Avatar,
    Button,
    IconButton,
    InputAdornment,
    TextField,
    Box,
    Typography,
    Paper,
    Skeleton,
    Modal,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setSelected } from "../../../../Redux/Slice/selectedRow";

import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { baseURL, get } from "../../../../utils/Caller";
import { CompanyURL } from "../../../../utils/config";
import EditModal from "../../../../Layouts/Modal/EditModal.modal";

const ViewCompany = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState();
    const [Search, setSearch] = useState("");
    const [editModal, setEditModal] = useState(false);

    const columns = [
        { label: "Company Logo", field: "logo" },
        { label: "Company Code", field: "code" },
        { label: "Company Name", field: "name" },
        { label: "Country Name", field: "countryName" },
        { label: "Currency", field: "currencyName" },
        { label: "Header", field: "header" },
        { label: "Footer", field: "footer" },
    ];

    useEffect(() => {
        let tableData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(CompanyURL, {
            limit: 1,
            filter: { all: filter },
        }).then((res) => {
            // console.log(res);
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    tableData.push({
                        ...data,
                        countryName: data.country?.country_name,
                        currencyName: data.currency?.name,
                    });
                });
            setRows(tableData);
            dispatch(setSelected({ ...tableData[0] }));
        });
    }, [refreshTable, filter, rows.length, dispatch]);

    const closeModal = () => {
        setEditModal(false);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <div style={{ display: "flex", gap: "2ch" }}>
                    {rows.length > 0 ? (
                        <Button
                            variant="contained"
                            color="success"
                            endIcon={<Edit />}
                            onClick={() => {
                                setEditModal(!editModal);
                            }}
                        >
                            Edit Company info
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            color="success"
                            endIcon={<Add />}
                            onClick={() => {
                                navigate("/View/HCM/Company/AddCompany");
                            }}
                        >
                            Add Company
                        </Button>
                    )}
                </div>
            </div>

            <Paper
                style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100vh",
                    width: "100%",
                }}
            >
                <Box
                    style={{
                        display: "flex",
                        WebkitFlexDirection: "column",
                        MsFlexDirection: "column",
                        flexDirection: "column",
                        padding: "3ch",
                        border: "silver 1px solid",
                        margin: "2ch",
                        borderRadius: "5px",
                    }}
                >
                    {columns.map((column, index) => (
                        <Box
                            style={{
                                WebkitFlexDirection: "row",
                                MsFlexDirection: "row",
                                display: "flex",
                                flexDirection: "row",
                                height: "7ch",
                                borderBottom: "silver 1px solid",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                fontWeight={"600"}
                                color={"#131417"}
                                key={index}
                                letterSpacing={"1.2px"}
                            >
                                {column.label}
                            </Typography>
                            {rows.map((row, i) => {
                                if (!loading) {
                                    if (
                                        column.field !== "logo" &&
                                        column.field !== "header" &&
                                        column.field !== "footer"
                                    ) {
                                        return (
                                            <Typography key={i}>
                                                {row[column.field]}
                                            </Typography>
                                        );
                                    } else if (
                                        column.field === "header" ||
                                        column.field === "footer"
                                    ) {
                                        return (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    width: "40ch",
                                                    height: "5ch",
                                                    transformOrigin: "right",
                                                    transition:
                                                        "0.5s ease-in-out",
                                                    "&:hover": {
                                                        transform: "scale(2)",
                                                    },
                                                }}
                                                src={
                                                    baseURL + row[column.field]
                                                }
                                            />
                                        );
                                    } else {
                                        return (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    width: "5ch",
                                                    height: "5ch",
                                                    transformOrigin: "right",
                                                    transition:
                                                        "0.5s ease-in-out",
                                                    "&:hover": {
                                                        transform: "scale(2)",
                                                    },
                                                }}
                                                src={
                                                    baseURL + row[column.field]
                                                }
                                            />
                                        );
                                    }
                                } else {
                                    return <Skeleton animation="wave" />;
                                }
                            })}
                        </Box>
                    ))}
                </Box>
            </Paper>
            <Modal
                className="Modal"
                open={editModal}
                onClose={() => {
                    setEditModal(false);
                }}
            >
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <EditModal close={closeModal} />
                </Box>
            </Modal>
        </div>
    );
};

export default ViewCompany;
