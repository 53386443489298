import {
    Autocomplete,
    Avatar,
    Box,
    Checkbox,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    Skeleton,
    TextField,
    Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { EditRounded } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { baseURL, filePatcher, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    // EmployeeURL,
    NationalityURL,
    CountryURL,
    LanguageURL,
    TitleURL,
    // ReligionURL,
    EmployeeTypeURL,
    // EmployeeGroupURL,
    // BusinessUnitURL,
    // ActionReasonURL,
    // LocationURL,
    // JobTitleURL,
    EmployeeURL,
} from "../../../../utils/config";
import dateFormatter from "../../../../utils/dateFormater";
import { useNavigate } from "react-router";

const EditEmployee = () => {
    const uploadRef = useRef();
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState("");
    const [filter2, setFilter2] = useState("");
    const [filter3, setFilter3] = useState("");
    const [filter4, setFilter4] = useState("");
    // const [filter5, setFilter5] = useState("");
    const [filter6, setFilter6] = useState("");
    // const [filter7, setFilter7] = useState("");
    // const [filter8, setFilter8] = useState("");
    // const [filter9, setFilter9] = useState("");
    // const [filter10, setFilter10] = useState("");
    // const [filter11, setFilter11] = useState("");
    const [options, setOptions] = useState([]);
    const [optionsId, setOptionsID] = useState();
    const [optionSelect, setOptionSelect] = useState("");
    const [options2, setOptions2] = useState([]);
    const [optionsId2, setOptionsID2] = useState();
    const [optionSelect2, setOptionSelect2] = useState("");
    const [options3, setOptions3] = useState([]);
    const [optionsId3, setOptionsID3] = useState();
    const [optionSelect3, setOptionSelect3] = useState("");
    const [options4, setOptions4] = useState([]);
    const [optionsId4, setOptionsID4] = useState();
    const [optionSelect4, setOptionSelect4] = useState("");
    // const [options5, setOptions5] = useState([]);
    // const [optionsId5, setOptionsID5] = useState();
    // const [optionSelect5, setOptionSelect5] = useState("");
    const [options6, setOptions6] = useState([]);
    const [optionsId6, setOptionsID6] = useState();
    const [optionSelect6, setOptionSelect6] = useState("");
    // const [options7, setOptions7] = useState([]);
    // const [optionsId7, setOptionsID7] = useState();
    // const [optionSelect7, setOptionSelect7] = useState("");
    // const [options8, setOptions8] = useState([]);
    // const [optionsId8, setOptionsID8] = useState();
    // const [optionSelect8, setOptionSelect8] = useState("");
    // const [options9, setOptions9] = useState([]);
    // const [optionsId9, setOptionsID9] = useState();
    // const [optionSelect9, setOptionSelect9] = useState("");
    // const [options10, setOptions10] = useState([]);
    // const [optionsId10, setOptionsID10] = useState();
    // const [optionSelect10, setOptionSelect10] = useState("");
    // const [options11, setOptions11] = useState([]);
    // const [optionsId11, setOptionsID11] = useState();
    // const [optionSelect11, setOptionSelect11] = useState("");
    const [AvatarImage, setAvatarImage] = useState(null);
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const [preview, setPreview] = useState(null);
    const [genderValue, setGenderValue] = useState(selectedRow.gender);
    const [MaritalStatus, setMaritalStatus] = useState(
        selectedRow.marital_status
    );
    // const [isEmployeeActive, setIsEmployeeActive] = useState(
    //     selectedRow.is_employee_active
    // );
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();

    const GENDERS = ["Male", "Female"];
    const MARITALSTATUS = ["Single", "Married", "Widowed", "Divorced"];

    useEffect(() => {
        if (optionSelect === "")
            setOptionSelect(
                `${selectedRow.nationality?.id} - ${selectedRow.nationalityName}`
            );
        if (optionSelect2 === "")
            setOptionSelect2(
                `${selectedRow.country?.id} - ${selectedRow.countryName}`
            );
        if (optionSelect3 === "")
            setOptionSelect3(
                `${selectedRow.language?.id} - ${selectedRow.languageName}`
            );
        if (optionSelect4 === "")
            setOptionSelect4(
                `${selectedRow.title?.id} - ${selectedRow.titleName}`
            );
        // if (optionSelect5 === "")
        //     setOptionSelect5(
        //         `${selectedRow.religion?.id} - ${selectedRow.religionName}`
        //     );
        if (optionSelect6 === "")
            setOptionSelect6(
                `${selectedRow.employee_type?.id} - ${selectedRow.employeeType}`
            );
        // if (optionSelect7 === "")
        //     setOptionSelect7(
        //         `${selectedRow.job_title?.id} - ${selectedRow.jobTitle}`
        //     );
        // if (optionSelect8 === "")
        //     setOptionSelect8(
        //         `${selectedRow.location?.id} - ${selectedRow.locationName}`
        //     );
        // if (optionSelect9 === "")
        //     setOptionSelect9(
        //         `${selectedRow.action_reason?.id} - ${selectedRow.actionReason}`
        //     );
        // if (optionSelect10 === "")
        //     setOptionSelect10(
        //         `${selectedRow.business_unit?.id} - ${selectedRow.businessUnit}`
        //     );
        // if (optionSelect11 === "")
        //     setOptionSelect11(
        //         `${selectedRow.employee_group?.id} - ${selectedRow.employeeGroup}`
        //     );
        if (!preview) setPreview(baseURL + selectedRow.photo);
    }, [
        selectedRow,
        optionSelect,
        optionSelect2,
        optionSelect3,
        optionSelect4,
        // optionSelect5,
        optionSelect6,
        // optionSelect7,
        // optionSelect8,
        // optionSelect9,
        // optionSelect10,
        // optionSelect11,
        preview,
    ]);

    const INITIAL_VALUES = {
        id: selectedRow.id,
        // id_number: selectedRow.id_number,
        first_name: selectedRow.first_name,
        middle_name: selectedRow.middle_name,
        last_name: selectedRow.last_name,
        gender: genderValue,
        date_of_birth: dateFormatter(selectedRow.date_of_birth),
        employment_start_date: dateFormatter(selectedRow.employment_start_date),
        date_of_joining: dateFormatter(selectedRow.date_of_joining),
        marital_status: MaritalStatus,
        marital_since: dateFormatter(selectedRow.marital_since),
        place_of_birth: selectedRow.place_of_birth,
        file: selectedRow.photo,
        // is_employee_active: isEmployeeActive,
        type: selectedRow.type,
        // prev_employment_leave_days: selectedRow.prev_employment_leave_days,
        bank_account_number: selectedRow.bank_account_number,
        document_ref: selectedRow.document_ref,
        pension_ref: selectedRow.pension_ref,
        nationality_id: selectedRow.nationality?.id,
        country_id: selectedRow.country?.id,
        language_id: selectedRow.language?.id,
        title_id: selectedRow.title?.id,
        // religion_id: selectedRow.religion?.id,
        employee_type_id: selectedRow.employee_type?.id,
        startDate: dateFormatter(selectedRow.startDate),
        // endDate: dateFormatter(selectedRow.endDate),
        job_title_id: selectedRow.job_title?.id,
        location_id: selectedRow.location?.id,
        action_reason_id: selectedRow.action_reason?.id,
        business_unit_id: selectedRow.business_unit?.id,
        employee_group_id: selectedRow.employee_group?.id,
        tin_number: selectedRow.tin_number,
        isProtectedForEdit: false,
    };

    const FORM_VALIDATION = Yup.object().shape({
        // id_number: Yup.number().min(1).required("*Required"),
        first_name: Yup.string().min(2).required("*Required"),
        middle_name: Yup.string().min(2).required("*Required"),
        last_name: Yup.string().nullable(),
        // gender: Yup.number().min(1).required("*Required"),
        date_of_birth: Yup.string().min(1).nullable(),
        employment_start_date: Yup.string().min(2).nullable(),
        date_of_joining: Yup.string().min(2).nullable(),
        // marital_status: Yup.number().min(1).required("*Required"),
        // marital_since: Yup.string().nullable(),
        place_of_birth: Yup.string().min(2).nullable(),
        photo: Yup.string().nullable(),
        is_employee_active: Yup.boolean(),
        type: Yup.string().min(1).nullable(),
        prev_employment_leave_days: Yup.number().nullable(),
        bank_account_number: Yup.string().min(2).nullable(),
        document_ref: Yup.string().nullable(),
        pension_ref: Yup.string().nullable(),
    });

    useEffect(() => {
        get(NationalityURL, {
            limit: 10,
            filter: {
                nationality_name: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].nationality_name}`
                    );
                });
            res && setOptionsID(res.data);
            setOptions(data);
        });
        get(CountryURL, {
            limit: 10,
            filter: {
                country_name: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].country_name}`
                    );
                });
            res && setOptionsID2(res.data);
            setOptions2(data);
        });
        get(LanguageURL, {
            limit: 10,
            filter: {
                name: filter3,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].name}`
                    );
                });
            res && setOptionsID3(res.data);
            setOptions3(data);
        });
        get(TitleURL, {
            limit: 10,
            filter: {
                name: filter4,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].name}`
                    );
                });
            res && setOptionsID4(res.data);
            setOptions4(data);
        });
        // get(ReligionURL, {
        //     limit: 10,
        //     filter: {
        //         name: filter5,
        //     },
        // }).then((res) => {
        //     let data = [];
        //     res &&
        //         res.data.forEach((name, index) => {
        //             data.push(
        //                 `${res.data[index]?.id} - ${res.data[index].name}`
        //             );
        //         });
        //     res && setOptionsID5(res.data);
        //     setOptions5(data);
        // });
        get(EmployeeTypeURL, {
            limit: 10,
            filter: {
                description: filter6,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].description}`
                    );
                });
            res && setOptionsID6(res.data);
            setOptions6(data);
        });
        // get(JobTitleURL, {
        //     limit: 10,
        //     filter: {
        //         all: filter7,
        //     },
        // }).then((res) => {
        //     let data = [];
        //     res &&
        //         res.data.forEach((name, index) => {
        //             data.push(
        //                 `${res.data[index]?.id} - ${res.data[index]?.title_name}`
        //             );
        //         });
        //     res && setOptionsID7(res.data);
        //     setOptions7(data);
        // });
        // get(LocationURL, {
        //     limit: 10,
        //     filter: {
        //         all: filter8,
        //     },
        // }).then((res) => {
        //     let data = [];
        //     res &&
        //         res.data.forEach((name, index) => {
        //             data.push(
        //                 `${res.data[index]?.id} - ${res.data[index]?.location_name}`
        //             );
        //         });
        //     res && setOptionsID8(res.data);
        //     setOptions8(data);
        // });
        // get(ActionReasonURL, {
        //     limit: 10,
        //     filter: {
        //         all: filter9,
        //     },
        // }).then((res) => {
        //     let data = [];
        //     res &&
        //         res.data.forEach((name, index) => {
        //             data.push(
        //                 `${res.data[index]?.id} - ${res.data[index]?.reason_description}`
        //             );
        //         });
        //     res && setOptionsID9(res.data);
        //     setOptions9(data);
        // });
        // get(BusinessUnitURL, {
        //     limit: 10,
        //     filter: {
        //         all: filter10,
        //     },
        // }).then((res) => {
        //     let data = [];
        //     res &&
        //         res.data.forEach((name, index) => {
        //             data.push(
        //                 `${res.data[index]?.id} - ${res.data[index]?.name}`
        //             );
        //         });
        //     res && setOptionsID10(res.data);
        //     setOptions10(data);
        // });
        // get(EmployeeGroupURL, {
        //     limit: 10,
        //     filter: {
        //         all: filter11,
        //     },
        // }).then((res) => {
        //     let data = [];
        //     res &&
        //         res.data.forEach((name, index) => {
        //             data.push(
        //                 `${res.data[index]?.id} - ${res.data[index]?.group_description}`
        //             );
        //         });
        //     res && setOptionsID11(res.data);
        //     setOptions11(data);
        // });
    }, [
        filter,
        filter2,
        filter3,
        filter4,
        // filter5,
        filter6,
        // filter7,
        // filter8,
        // filter9,
        // filter10,
        // filter11,
    ]);

    useEffect(() => {
        // create the preview
        if (AvatarImage) {
            const objectUrl = URL.createObjectURL(AvatarImage);
            setPreview(objectUrl);

            // free memory when ever this component is unmounted
            return () => URL.revokeObjectURL(objectUrl);
        }
    }, [AvatarImage]);

    const formSubmitHandler = (updateData, actions) => {
        setLoading(true);

        filePatcher(EmployeeURL, updateData).then((res) => {
            setLoading(false);
            // console.log(res.error);
            if (res?.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res?.data?.success) {
                dispatch(setRefresh(!refreshTable));
                setSuccess(true);
                setError(false);
                // props.close();
                navigate(`/View/HCM/Employee/${selectedRow.id}/`);
            }
        });
    };

    return (
        <div>
            <TitleHeader title="Edit Employee" icon={<EditRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        optionsId?.forEach((name, index) => {
                            if (
                                `${name.id} - ${name.nationality_name}` ===
                                optionSelect
                            ) {
                                values.nationality_id = name.id;
                            }
                        });
                        optionsId2?.forEach((name, index) => {
                            if (
                                `${name.id} - ${name.country_name}` ===
                                optionSelect2
                            ) {
                                values.country_id = name.id;
                            }
                        });
                        optionsId3?.forEach((name, index) => {
                            if (`${name.id} - ${name.name}` === optionSelect3) {
                                values.language_id = name.id;
                            }
                        });
                        optionsId4?.forEach((name, index) => {
                            if (`${name.id} - ${name.name}` === optionSelect4) {
                                values.title_id = name.id;
                            }
                        });
                        // optionsId5?.forEach((name, index) => {
                        //     if (`${name.id} - ${name.name}` === optionSelect5) {
                        //         values.religion_id = name.id;
                        //     }
                        // });
                        optionsId6?.forEach((name, index) => {
                            if (
                                `${name.id} - ${name.description}` ===
                                optionSelect6
                            ) {
                                values.employee_type_id = name.id;
                            }
                        });
                        // optionsId7.forEach((name, index) => {
                        //     if (
                        //         `${name?.id} - ${name.title_name}` ===
                        //         optionSelect7
                        //     ) {
                        //         values.job_title_id = name.id;
                        //     }
                        // });
                        // optionsId8.forEach((name, index) => {
                        //     if (
                        //         `${name?.id} - ${name.location_name}` ===
                        //         optionSelect8
                        //     ) {
                        //         values.location_id = name.id;
                        //     }
                        // });
                        // optionsId9.forEach((name, index) => {
                        //     if (
                        //         `${name?.id} - ${name.reason_description}` ===
                        //         optionSelect9
                        //     ) {
                        //         values.action_reason_id = name.id;
                        //     }
                        // });
                        // optionsId10.forEach((name, index) => {
                        //     if (
                        //         `${name?.id} - ${name.name}` === optionSelect10
                        //     ) {
                        //         values.business_unit_id = name.id;
                        //     }
                        // });
                        // optionsId11.forEach((name, index) => {
                        //     if (
                        //         `${name?.id} - ${name.group_description}` ===
                        //         optionSelect11
                        //     ) {
                        //         values.employee_group_id = name.id;
                        //     }
                        // });

                        // values.is_employee_active = isEmployeeActive;
                        values.gender = genderValue;
                        values.marital_status = MaritalStatus;
                        values.file = AvatarImage;
                        if (MaritalStatus === 1) {
                            values.marital_since = "";
                        }

                        /* Then create a new FormData obj */
                        let formData = new FormData();

                        /* append input field values to formData */
                        for (let value in values) {
                            if (value === "isProtectedForEdit") {
                                values[value] = false;
                            }
                            formData.append(value, values[value]);
                        }

                        /* Can't console.log(formData), must
                              use formData.entries() - example:  */
                        // for (let property of formData.entries()) {
                        //     console.log(property[0], typeof property[1]);
                        // }

                        formSubmitHandler(formData, actions);
                    }}
                >
                    <Form>
                        <Box
                            style={{
                                border: `solid 1px silver`,
                                padding: "2ch",
                                borderRadius: "5px",
                            }}
                        >
                            <div>
                                <Typography
                                    style={{
                                        fontWeight: "700",
                                        fontSize: "large",
                                        letterSpacing: "1px",
                                        paddingBottom: "1ch",
                                    }}
                                >
                                    Personal Detail
                                </Typography>
                                <Divider />
                            </div>
                            <Grid container spacing={2}>
                                <Grid item xs={12} className="imagePreview">
                                    {!loaded && (
                                        <Skeleton
                                            variant="circular"
                                            animation={"wave"}
                                            style={{
                                                height: "10ch",
                                                width: "10ch",
                                            }}
                                            onClick={() => {
                                                uploadRef.current.click();
                                            }}
                                        />
                                    )}
                                    <Avatar
                                        onClick={() => {
                                            uploadRef.current.click();
                                        }}
                                        onLoad={() => {
                                            setLoaded(true);
                                        }}
                                        src={preview}
                                        alt=""
                                        style={{
                                            height: "10ch",
                                            width: "10ch",
                                            display: loaded ? "block" : "none",
                                        }}
                                    />

                                    <input
                                        hidden
                                        ref={uploadRef}
                                        type="file"
                                        onChange={(e) => {
                                            setAvatarImage(e.target.files[0]);
                                        }}
                                    ></input>
                                </Grid>
                                {/* <Grid item xs={4}>
                                    <TextFieldWrapper
                                        name="id_number"
                                        label="ID Number"
                                        variant="outlined"
                                        type="number"
                                    />
                                </Grid> */}
                                <Box width={"100%"} />
                                <Grid item xs={4}>
                                    <TextFieldWrapper
                                        name="first_name"
                                        label="First Name"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextFieldWrapper
                                        name="middle_name"
                                        label="Middle Name"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextFieldWrapper
                                        name="last_name"
                                        label="Last Name"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextFieldWrapper
                                        select
                                        name="gender"
                                        label="Gender"
                                        variant="outlined"
                                        value={genderValue}
                                        options={GENDERS ?? " "}
                                        onChange={(e) => {
                                            setGenderValue(e.target.value);
                                        }}
                                    />
                                </Grid>
                                <Box width={"100%"} />
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <FormLabel>Date Of Birth</FormLabel>
                                        <TextFieldWrapper
                                            name="date_of_birth"
                                            variant="outlined"
                                            type="date"
                                        />
                                    </FormControl>
                                </Grid>
                                {/* <Box width={"100%"} />
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <FormLabel>
                                            Employment Start Date
                                        </FormLabel>
                                        <TextFieldWrapper
                                            name="employment_start_date"
                                            variant="outlined"
                                            type="date"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <FormLabel>Date Of Joining</FormLabel>
                                        <TextFieldWrapper
                                            name="date_of_joining"
                                            variant="outlined"
                                            type="date"
                                        />
                                    </FormControl>
                                </Grid>
                                <Box width={"100%"} /> */}
                                <Grid item xs={4}>
                                    <TextFieldWrapper
                                        select
                                        name="marital_status"
                                        label="Marital Status"
                                        variant="outlined"
                                        value={MaritalStatus ?? " "}
                                        options={MARITALSTATUS}
                                        onChange={(e) => {
                                            setMaritalStatus(e.target.value);
                                        }}
                                    />
                                </Grid>
                                {MaritalStatus !== 1 && (
                                    <>
                                        <Box width={"100%"} />
                                        <Grid item xs={4}>
                                            <FormControl fullWidth>
                                                <FormLabel>
                                                    Married Since
                                                </FormLabel>
                                                <TextFieldWrapper
                                                    name="marital_since"
                                                    variant="outlined"
                                                    type="date"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </>
                                )}
                                <Box width={"100%"} />
                                <Grid item xs={4}>
                                    <TextFieldWrapper
                                        name="place_of_birth"
                                        label="Place of Birth"
                                        variant="outlined"
                                    />
                                </Grid>
                                {/* <Grid item xs={6}>
                                    <FormControl
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <FormLabel>
                                            Is Employee Active
                                        </FormLabel>
                                        <Checkbox
                                            name="is_employee_active"
                                            checked={isEmployeeActive}
                                            onChange={(event) => {
                                                setIsEmployeeActive(
                                                    event.target.checked
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </Grid> */}
                                {/* <Grid item xs={4}>
                                    <TextFieldWrapper
                                        name="type"
                                        label="Type"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextFieldWrapper
                                        name="prev_employment_leave_days"
                                        label="Prev Employment Leave Days"
                                        variant="outlined"
                                        type="number"
                                    />
                                </Grid> */}
                                <Grid item xs={4}>
                                    <TextFieldWrapper
                                        name="bank_account_number"
                                        label="Bank Account Number"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextFieldWrapper
                                        name="tin_number"
                                        label="Tin Number"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextFieldWrapper
                                        name="document_ref"
                                        label="Document Ref"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextFieldWrapper
                                        name="pension_ref"
                                        label="Pension Ref"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Box width={"100%"} />
                                <Grid item xs={4}>
                                    <Autocomplete
                                        value={optionSelect}
                                        loading={
                                            options.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options}
                                        onChange={(event, newValue) => {
                                            setOptionSelect(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Nationality"
                                                onChange={(event) => {
                                                    setFilter(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        value={optionSelect2}
                                        loading={
                                            options2.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options2}
                                        onChange={(event, newValue) => {
                                            setOptionSelect2(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Country"
                                                onChange={(event) => {
                                                    setFilter2(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        value={optionSelect3}
                                        loading={
                                            options3.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options3}
                                        onChange={(event, newValue) => {
                                            setOptionSelect3(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Language"
                                                onChange={(event) => {
                                                    setFilter3(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        value={optionSelect4}
                                        loading={
                                            options4.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options4}
                                        onChange={(event, newValue) => {
                                            setOptionSelect4(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Title"
                                                onChange={(event) => {
                                                    setFilter4(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                {/* <Grid item xs={4}>
                                    <Autocomplete
                                        value={optionSelect5}
                                        loading={
                                            options5.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options5}
                                        onChange={(event, newValue) => {
                                            setOptionSelect5(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Religion"
                                                onChange={(event) => {
                                                    setFilter5(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid> */}
                            </Grid>
                        </Box>
                        <Box
                            style={{
                                border: `solid 1px silver`,
                                padding: "2ch",
                                borderRadius: "5px",
                            }}
                        >
                            <div style={{ marginBottom: "2ch" }}>
                                <Typography
                                    style={{
                                        fontWeight: "700",
                                        fontSize: "large",
                                        letterSpacing: "1px",
                                        paddingBottom: "1ch",
                                    }}
                                >
                                    Hiring Detail
                                </Typography>
                                <Divider />
                            </div>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <FormLabel>Start Date</FormLabel>
                                        <TextFieldWrapper
                                            name="startDate"
                                            variant="outlined"
                                            type="date"
                                        />
                                    </FormControl>
                                </Grid>
                                {/* <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <FormLabel>End Date</FormLabel>
                                        <TextFieldWrapper
                                            name="endDate"
                                            variant="outlined"
                                            type="date"
                                        />
                                    </FormControl>
                                </Grid> */}
                                <Box width="100%" />
                                <Grid item xs={4}>
                                    <Autocomplete
                                        value={optionSelect6}
                                        loading={
                                            options6.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options6}
                                        onChange={(event, newValue) => {
                                            setOptionSelect6(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Employee Type"
                                                onChange={(event) => {
                                                    setFilter6(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                {/* <Grid item xs={4}>
                                    <Autocomplete
                                        loading={
                                            options7.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options7}
                                        onChange={(event, newValue) => {
                                            setOptionSelect7(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Job Title"
                                                onChange={(event) => {
                                                    setFilter7(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        loading={
                                            options8.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options8}
                                        onChange={(event, newValue) => {
                                            setOptionSelect8(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Location"
                                                onChange={(event) => {
                                                    setFilter8(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        loading={
                                            options9.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options9}
                                        onChange={(event, newValue) => {
                                            setOptionSelect9(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Action Reason"
                                                onChange={(event) => {
                                                    setFilter9(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        loading={
                                            options10.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options10}
                                        onChange={(event, newValue) => {
                                            setOptionSelect10(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Business Unit"
                                                onChange={(event) => {
                                                    setFilter10(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        loading={
                                            options11.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options11}
                                        onChange={(event, newValue) => {
                                            setOptionSelect11(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Employee Group"
                                                onChange={(event) => {
                                                    setFilter11(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid> */}
                            </Grid>
                        </Box>
                        <Grid item xs={6}>
                            <FormControl
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <Checkbox
                                    checked={protectedEdit}
                                    onChange={(event) => {
                                        setProtectedEdit(event.target.checked);
                                    }}
                                />
                                <FormLabel>Protected For Edit</FormLabel>
                            </FormControl>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Apply"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default EditEmployee;
