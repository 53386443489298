import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import dateFormatter from "../../../utils/dateFormater";

const ProjectViewDetail = () => {
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    const PROJECT_VALUES = {
        "Project Description": selectedRow.project_description,
        Name: selectedRow.name,
        "Project Manager": selectedRow.project_manager?.id,
        "Project Start Date": dateFormatter(selectedRow.project_start_date),
        "Project End Date": dateFormatter(selectedRow.project_end_date),
        "Contract Number": selectedRow.contract_number,
        "Site Engineer": selectedRow.site_engineer?.id,
        "Dupty Manager": selectedRow.dupty_manager?.id,
        "Project Address": selectedRow.project_address,
        "Project Id": selectedRow.project_id,
        "Client Trade Name": selectedRow.client?.tradeName,
    };
    return (
        <Box
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "2ch",
                padding: "2ch",
            }}
        >
            {Object.keys(PROJECT_VALUES).map((key, index) => {
                return (
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography fontWeight={"bolder"}>{key}</Typography>
                        <Typography>{PROJECT_VALUES[key]}</Typography>
                    </Box>
                );
            })}
        </Box>
    );
};

export default ProjectViewDetail;
