import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Modal, Box, Typography, Paper, Divider } from "@mui/material";
import { useDispatch } from "react-redux";
import { ExitToAppRounded } from "@mui/icons-material";
import jwt_decode from "jwt-decode";

import { userLogIn } from "../../Redux/Slice/userSlice";
import EditProfile from "../../Pages/Account/EditProfile/EditProfile.page";
import { useNavigate } from "react-router";

const Profile = () => {
    const src = "";
    const dispatch = useDispatch();
    let user = null;
    try {
        user = JSON.parse(jwt_decode(localStorage.getItem("User")));
        dispatch(userLogIn(user));
    } catch (error) {}
    const [editProfileModal, setEditProfileModal] = useState(false);
    //------------------------------------------------------------POPOVER-----------------------------------------------------

    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    //------------------------------------------------------------------------------------------------------------------------

    const logOutHandler = () => {
        dispatch(userLogIn(null));
        localStorage.clear();
        window.open("/", "_self");
    };

    const editProfileToggle = () => {
        setEditProfileModal(true);
    };

    const SentenceCaseUserName = (user) => {
        let name = "";
        try {
            name = (user?.username)
                .split(" ")
                .map((word) => word[0].toUpperCase() + word.slice(1))
                .join(" ");
        } catch (error) {}
        return name;
    };

    return (
        <div className="Profile" data-testid="Profile">
            <Typography style={{ paddingRight: "1ch", color: "#14213d" }}>
                {SentenceCaseUserName(user)}
            </Typography>
            <Avatar src={src} onClick={handleClick} />
            <Menu
                sx={{ top: "5ch" }}
                anchorEl={anchorEl}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem disabled={true}>
                    <Typography color={"#14213d"}>
                        {user ? user.username : "---"}
                    </Typography>
                </MenuItem>
                <MenuItem disabled={true}>{user ? user.email : "---"}</MenuItem>
                <MenuItem disabled={true}>
                    {user ? user.role?.name : "---"}
                </MenuItem>
                <Divider width={window.innerWidth / 4} />
                <MenuItem
                    onClick={() => {
                        editProfileToggle();
                    }}
                    style={{
                        padding: "1ch 4ch 1ch 4ch",
                        justifyContent: "space-between",
                    }}
                >
                    Edit Profile
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        navigate("/UpdatePassword");
                    }}
                    style={{
                        padding: "1ch 4ch 1ch 4ch",
                        justifyContent: "space-between",
                    }}
                >
                    Update Password
                </MenuItem>
                {/* <MenuItem
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    style={{
                        padding: "1ch 4ch 1ch 4ch",
                        justifyContent: "space-between",
                    }}
                >
                    Night Mode
                    <IconButton
                        onClick={() => {
                            localStorage.setItem("mode", !mode);
                            mode = !mode;
                        }}
                    >
                        {mode === "false" ? (
                            <Brightness4Rounded />
                        ) : (
                            <Brightness7Rounded />
                        )}
                    </IconButton>
                </MenuItem> */}
                <Divider />
                <MenuItem
                    data-testid="logOut"
                    onClick={() => {
                        logOutHandler();
                    }}
                    style={{ gap: "2ch" }}
                >
                    <ExitToAppRounded />
                    Log Out
                </MenuItem>
            </Menu>
            <Modal
                className="Modal"
                open={editProfileModal}
                onClose={() => {
                    setEditProfileModal(false);
                }}
            >
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Paper style={{ padding: "2ch" }}>
                        <EditProfile
                            user={user}
                            close={() => {
                                setEditProfileModal(false);
                            }}
                        />
                    </Paper>
                </Box>
            </Modal>
        </div>
    );
};

export default Profile;
