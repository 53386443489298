import { PrintRounded, CancelRounded } from "@mui/icons-material";
import {
  CircularProgress,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { baseURL, get } from "../../../utils/Caller";
import { CompanyURL, TaskManagerURL } from "../../../utils/config";

const BOQ = ({ project }) => {
  let { state, pathname } = useLocation();
  const [mainTasks, setMainTasks] = useState();
  const [selectedProject, setSelectedProject] = useState();

  const columns = [
    {
      field: "item",
      headerName: "Item",
      width: 100,
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
    },
    {
      field: "unit",
      headerName: "Unit",
      width: 100,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 100,
    },
    {
      field: "total_area",
      headerName: "Total Area",
      width: 100,
    },
    {
      field: "unit_price",
      headerName: "Unit Price",
      width: 100,
    },
    {
      field: "total_price",
      headerName: "Total Price",
      width: 100,
    },
  ];

  const [header, setHeader] = useState();

  useEffect(() => {
    get(CompanyURL, {
      limit: 1,
    }).then((res) => {
      res &&
        res.data.forEach((data, index) => {
          setHeader(data?.header);
        });
    });
  }, []);

  useEffect(() => {
    if (selectedProject?.id) {
      get(TaskManagerURL, {
        limit: 1000,
        filter: { project_id: selectedProject?.id },
      }).then((res) => {
        res && setMainTasks(res.data);
      });
    }
  }, [selectedProject?.id]);

  useEffect(() => {
    if (project) {
      setSelectedProject(project);
    } else if (state) setSelectedProject(state?.selectedRow);
  }, [project, selectedProject, state]);

  return (
    <div className="ViewProject">
      <Box
        className="hide-on-print"
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        {pathname === "/BOQ" && (
          <IconButton
            onClick={() => {
              window.print();
            }}
          >
            <PrintRounded />
          </IconButton>
        )}

        <IconButton
          onClick={() => {
            window.history.back();
          }}
        >
          <CancelRounded color="error" />
        </IconButton>
      </Box>

      <Box width={"100%"} style={{ objectFit: "scale-down" }}>
        <img src={baseURL + header} alt="" width={"100%"} />
      </Box>
      {!mainTasks && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div style={{ margin: "5ch" }} className="BOQ-table">
        <Table style={{ border: "solid silver 1px", borderRadius: "10px" }}>
          <TableHead>
            {columns?.map((column, index) => (
              <TableCell
                key={index}
                width={`${column.width}px`}
                style={{ fontSize: "larger" }}
                size="medium"
              >
                {column.headerName}
              </TableCell>
            ))}
          </TableHead>
          {mainTasks?.map((mainTask, index) => (
            <>
              <TableRow key={index}>
                <TableCell size="small">{index + 1}</TableCell>
                <TableCell
                  size="small"
                  style={{ fontWeight: "bolder", fontSize: "large" }}
                >
                  {mainTask.name}
                </TableCell>
              </TableRow>
              <TableRow key={`${index}_desc`}>
                <TableCell size="small"></TableCell>
                <TableCell size="small">{mainTask.description}</TableCell>
              </TableRow>
              {mainTask?.sub_task?.map((subtask, index_2) => (
                <>
                  <TableRow key={index}>
                    <TableCell size="small">{`${index + 1}.${
                      index_2 + 1
                    }`}</TableCell>
                    <TableCell size="small" style={{ fontWeight: "bolder" }}>
                      {subtask.name}
                    </TableCell>
                  </TableRow>
                  {subtask.todos?.map((todo, i) => (
                    <TableRow key={i}>
                      <TableCell size="small"></TableCell>
                      <TableCell size="small">{todo.name}</TableCell>
                      <TableCell size="small">{todo.unit}</TableCell>
                      <TableCell size="small">{todo.quantity}</TableCell>
                      <TableCell size="small">{todo.total_area}</TableCell>
                      <TableCell size="small">{todo.unit_price}</TableCell>
                      <TableCell size="small">{todo.total_price}</TableCell>
                    </TableRow>
                  ))}
                </>
              ))}
            </>
          ))}
        </Table>
        {Array.isArray(mainTasks) && mainTasks.length > 0 && (
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              // marginTop: "2ch",
              justifyContent: "right",
              alignItems: "flex-end",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                minWidth: "29.2%",
                justifyContent: "space-between",
                border: "solid silver 1px",
                paddingLeft: "4ch",
              }}
            >
              <Typography fontWeight={"bolder"}>Vat</Typography>
              <Typography>{mainTasks[0]?.project?.tax}</Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                minWidth: "29.2%",
                justifyContent: "space-between",
                border: "solid silver 1px",
                paddingLeft: "4ch",
              }}
            >
              <Typography fontWeight={"bolder"}>Sub Total</Typography>
              <Typography>{mainTasks[0]?.project?.budget}</Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                minWidth: "29.2%",
                justifyContent: "space-between",
                border: "solid silver 1px",
                paddingLeft: "4ch",
              }}
            >
              <Typography fontWeight={"bolder"}>Total</Typography>
              <Typography>{mainTasks[0]?.project?.total_budget}</Typography>
            </Box>
          </Box>
        )}
      </div>
    </div>
  );
};

export default BOQ;
