import React from "react";
import { useLocation } from "react-router";

import AddMaterial from "./AddMaterial.page";
import ViewMaterial from "./ViewMaterial.page";

const Material = () => {
    const location = useLocation();

    return (
        <>
            {location.pathname === "/View/Material/AddMaterial" && (
                <AddMaterial />
            )}
            {location.pathname === "/View/Material" && <ViewMaterial />}
        </>
    );
};

export default Material;
