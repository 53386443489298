import {
    Box,
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Stack,
    Autocomplete,
    TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { caller, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import { AddRounded } from "@mui/icons-material";
import {
    DailyReportURL,
    ProjectToDoURL,
    EmployeeURL,
    BusinessUnitURL,
} from "../../../../utils/config";
import { useSelector } from "react-redux";
import dateFormatter from "../../../../utils/dateFormater";

const AddDailyWorkLog = () => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const [msg, setMsg] = useState("");
    const [dateValue, setDateValue] = useState(dateFormatter(Date.now()));
    const [filter, setFilter] = useState();
    const [options, setOptions] = useState([]);
    const [optionSelect, setOptionSelect] = useState("");
    const [filter2, setFilter2] = useState();
    const [options2, setOptions2] = useState([]);
    const [optionSelect2, setOptionSelect2] = useState("");
    const [filter3, setFilter3] = useState();
    const [options3, setOptions3] = useState([]);
    const [optionSelect3, setOptionSelect3] = useState("");

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        date: dateValue,
        activities_performed: "",
        material_delivered: "",
        available_machine_on_site: "",
        problem_encountered: "",
        no_of_labours: "",
        remark: "",
        todo_ids: [],
        project_id: null,
        employee_ids: [],
    };

    const FORM_VALIDATION = Yup.object().shape({
        date: Yup.string().required("*Required"),
        activities_performed: Yup.string().required("*Required"),
        material_delivered: Yup.string().required("*Required"),
        available_machine_on_site: Yup.string().required("*Required"),
        problem_encountered: Yup.string().required("*Required"),
        no_of_labours: Yup.string().required("*Required"),
        remark: Yup.string().required("*Required"),
    });

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        caller(DailyReportURL, data).then((res) => {
            setLoading(false);
            // console.log(res.error);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                actions.resetForm();
            }
        });
    };

    useEffect(() => {
        let todoList = [];
        get(ProjectToDoURL, {
            limit: 100,
            project_id: selectedRow.id,
        }).then((res) => {
            res?.data &&
                res.data.forEach((name, index) => {
                    todoList.push(`${name?.id} - ${name?.name}`);
                });
            setOptions(todoList);
        });
        get(EmployeeURL, {
            limit: 10,
            filter: {
                all: filter2,
                business_unit_id: parseInt(optionSelect3?.split("-")[0]),
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].first_name} ${res.data[index].middle_name}`,
                    );
                });
            setOptions2(data);
        });
        get(BusinessUnitURL, {
            limit: 10,
            filter: {
                all: filter3,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].name}`,
                    );
                });
            setOptions3(data);
        });
    }, [filter, filter2, filter3, optionSelect3, selectedRow.id]);

    return (
        <div>
            <TitleHeader title="Add Daily Report" icon={<AddRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.project_id = selectedRow.id;
                        values.isProtectedForEdit = protectedEdit;
                        // values.remark = JSON.stringify([values.remark]);
                        optionSelect?.forEach((option, index) => {
                            let todo_id = parseInt(option.split("-")[0]);
                            values.todo_ids.push(todo_id); 
                        });
                        optionSelect2?.forEach((option, index) => {
                            let empID = parseInt(option.split("-")[0]);
                            values.employee_ids.push(empID);
                        });
                        values.date = dateValue;
                        // values.employee_id = parseInt(
                        //     optionSelect2.split("-")[0]
                        // );

                        formSubmitHandler(
                            {
                                ...values,
                                remark: JSON.stringify([values.remark]),
                            },
                            actions,
                        );
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Stack spacing={3}>
                                    <Autocomplete
                                        multiple
                                        getOptionLabel={(option) =>
                                            option || ""
                                        }
                                        filterSelectedOptions
                                        loading={
                                            options.length < 1 ? true : false
                                        }
                                        options={options}
                                        onChange={(event, newValue) => {
                                            setOptionSelect(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Completed Tasks"
                                                onChange={(event) => {
                                                    setFilter(
                                                        event.target.value,
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Stack>
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="activities_performed"
                                    label="Activities Performed"
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="material_delivered"
                                    label="Material Delivered"
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="available_machine_on_site"
                                    label="Available Machine On Site"
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="problem_encountered"
                                    label="Problem Encountered"
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="no_of_labours"
                                    label="List of Professionals"
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={4}>
                                <Autocomplete
                                    loading={options3.length < 1 ? true : false}
                                    disablePortal
                                    options={options3}
                                    onChange={(event, newValue) => {
                                        setOptionSelect3(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Business Unit"
                                            onChange={(event) => {
                                                setFilter3(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={3}>
                                    <Autocomplete
                                        multiple
                                        getOptionLabel={(option2) =>
                                            option2 || ""
                                        }
                                        filterSelectedOptions
                                        loading={
                                            options2.length < 1 ? true : false
                                        }
                                        options={options2}
                                        onChange={(event, newValue) => {
                                            setOptionSelect2(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Company Employee"
                                                onChange={(event) => {
                                                    setFilter2(
                                                        event.target.value,
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldWrapper
                                    name="remark"
                                    label="Remark"
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <FormLabel>Date</FormLabel>
                                    <TextFieldWrapper
                                        name="date"
                                        variant="outlined"
                                        value={dateValue}
                                        onChange={(e) => {
                                            setDateValue(e.target.value);
                                        }}
                                        type="date"
                                    />
                                </FormControl>
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked,
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};

export default AddDailyWorkLog;
