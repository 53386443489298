import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { EditRounded } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { patcher, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    AccountTypeURL,
    ChartOfAccountURL,
    CurrencyURL,
} from "../../../../utils/config";

const EditChartOfAccount = (props) => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState("");
    const [filter2, setFilter2] = useState("");
    const [filter3, setFilter3] = useState("");
    const [filter4, setFilter4] = useState("");
    const [options1, setOptions1] = useState([]);
    const [optionsId1, setOptionsID1] = useState();
    const [optionSelect1, setOptionSelect1] = useState("");
    const [options2, setOptions2] = useState([]);
    const [optionsId2, setOptionsID2] = useState();
    const [optionSelect2, setOptionSelect2] = useState("");
    const [options3, setOptions3] = useState([]);
    const [optionsId3, setOptionsID3] = useState();
    const [optionSelect3, setOptionSelect3] = useState("");
    const [options4, setOptions4] = useState([]);
    const [optionsId4, setOptionsID4] = useState();
    const [optionSelect4, setOptionSelect4] = useState("");
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [IsActive, setIsActive] = useState(selectedRow.is_active);
    const [IsSubAccount, setIsSubAccount] = useState(
        selectedRow.is_sub_account
    );
    const [IsDefault, setIsDefault] = useState(selectedRow.is_default);
    const [IsEmployeeAccount, setIsEmployeeAccount] = useState(
        selectedRow.is_employee_account
    );
    const [IsBankAccount, setIsBankAccount] = useState(
        selectedRow.is_bank_account
    );
    const [HasOpeningBalance, setHasOpeningBalance] = useState(
        selectedRow.has_opening_balance
    );
    const [addToDashboardWatchList, setaddToDashboardWatchList] = useState(
        selectedRow.add_to_dashboard_watch_list
    );
    const [msg, setMsg] = useState("");

    useEffect(() => {
        if (optionSelect1 === "")
            setOptionSelect1(
                `${selectedRow.parent_account?.id} - ${selectedRow.parentAccount}`
            );
        if (optionSelect2 === "")
            setOptionSelect2(
                `${selectedRow.head_account?.id} - ${selectedRow.headAccount}`
            );
        if (optionSelect3 === "")
            setOptionSelect3(
                `${selectedRow.currency?.id} - ${selectedRow.currencyName}`
            );
        if (optionSelect4 === "")
            setOptionSelect4(
                `${selectedRow.account_type?.id} - ${selectedRow.accountType}`
            );
    }, [
        selectedRow,
        optionSelect1,
        optionSelect2,
        optionSelect3,
        optionSelect4,
    ]);

    const INITIAL_VALUES = {
        account_name: selectedRow.account_name,
        account_code: selectedRow.account_code,
        account_number: selectedRow.account_number,
        description: selectedRow.description,
        attachment_path: selectedRow.attachment_path,
        parent_account_id: selectedRow.parent_account_id,
        head_account_id: selectedRow.head_account_id,
        currency_id: selectedRow.currency_id,
        account_type_id: selectedRow.account_type_id,
        isProtectedForEdit: false,
        is_active: IsActive,
        add_to_dashboard_watch_list: addToDashboardWatchList,
        is_sub_account: IsSubAccount,
        is_default: IsDefault,
        is_employee_account: IsEmployeeAccount,
        has_opening_balance: HasOpeningBalance,
        is_bank_account: IsBankAccount,
    };

    const FORM_VALIDATION = Yup.object().shape({
        account_name: Yup.string().min(1).required("*Required"),
        account_code: Yup.string().min(1).required("*Required"),
        account_number: Yup.string().min(1),
        is_active: Yup.string().min(1),
        description: Yup.string().min(1),
        add_to_dashboard_watch_list: Yup.string().min(1).required("*Required"),
        attachment_path: Yup.number().min(1),
        is_sub_account: Yup.string().min(1),
        is_default: Yup.string().min(1),
        is_employee_account: Yup.string().min(1),
        has_opening_balance: Yup.string().min(1),
        is_bank_account: Yup.string().min(1),
        // parent_account_id: Yup.number().min(1),
        // head_account_id: Yup.number().min(1),
        // currency_id: Yup.number().min(1),
        // account_type_id: Yup.number().min(1).required("*Required"),
    });

    useEffect(() => {
        get(ChartOfAccountURL, {
            limit: 10,
            filter: {
                account_code: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.account_code}`
                    );
                });
            res && setOptionsID1(res.data);
            setOptions1(data);
        });

        get(ChartOfAccountURL, {
            limit: 10,
            filter: {
                account_code: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.account_code}`
                    );
                });
            res && setOptionsID2(res.data);
            setOptions2(data);
        });

        get(CurrencyURL, {
            limit: 10,
            filter: {
                name: filter3,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.name}`
                    );
                });
            res && setOptionsID3(res.data);
            setOptions3(data);
        });

        get(AccountTypeURL, {
            limit: 10,
            filter: {
                closed: filter4,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.code}`
                    );
                });
            res && setOptionsID4(res.data);
            setOptions4(data);
        });
    }, [filter, filter2, filter3, filter4]);

    const formSubmitHandler = (updateData, actions) => {
        setLoading(true);
        const update = {
            id: selectedRow.id,
            updateData,
        };

        patcher(ChartOfAccountURL, update).then((res) => {
            setLoading(false);
            // console.log(res.error);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                dispatch(setRefresh(!refreshTable));
                setSuccess(true);
                setError(false);
                props.close();
            }
        });
    };

    return (
        <div>
            <TitleHeader title="Edit Chart Of Account" icon={<EditRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        values.is_active = IsActive;
                        values.is_bank_account = IsBankAccount;
                        values.is_employee_account = IsEmployeeAccount;
                        values.is_sub_account = IsSubAccount;
                        values.is_default = IsDefault;
                        values.has_opening_balance = HasOpeningBalance;
                        values.add_to_dashboard_watch_list =
                            addToDashboardWatchList;

                        optionsId1.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.account_code}` ===
                                optionSelect1
                            ) {
                                values.parent_account_id = name.id;
                            }
                        });
                        optionsId2.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.account_code}` ===
                                optionSelect2
                            ) {
                                values.head_account_id = name.id;
                            }
                        });
                        optionsId3.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.name}` === optionSelect3
                            ) {
                                values.currency_id = name.id;
                            }
                        });
                        optionsId4.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.code}` === optionSelect4
                            ) {
                                values.account_type_id = name.id;
                            }
                        });

                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="account_name"
                                    label="Account Name"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="account_code"
                                    label="Account Code"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="account_number"
                                    label="Account Number"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>Is Active</FormLabel>
                                    <Checkbox
                                        name="is_active"
                                        checked={IsActive}
                                        onChange={(event) => {
                                            setIsActive(event.target.checked);
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldWrapper
                                    name="description"
                                    label="Description"
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>
                                        Add To Dashboard Watch List
                                    </FormLabel>
                                    <Checkbox
                                        name="add_to_dashboard_watch_list"
                                        checked={addToDashboardWatchList}
                                        onChange={(event) => {
                                            setaddToDashboardWatchList(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="attachment_path"
                                    label="Attachment Path"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>Is Sub Account</FormLabel>
                                    <Checkbox
                                        name="is_sub_account"
                                        checked={IsSubAccount}
                                        onChange={(event) => {
                                            setIsSubAccount(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>Is Default</FormLabel>
                                    <Checkbox
                                        name="is_default"
                                        checked={IsDefault}
                                        onChange={(event) => {
                                            setIsDefault(event.target.checked);
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>Is Employee Account</FormLabel>
                                    <Checkbox
                                        name="is_employee_account"
                                        checked={IsEmployeeAccount}
                                        onChange={(event) => {
                                            setIsEmployeeAccount(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>Has Opening Balance</FormLabel>
                                    <Checkbox
                                        name="has_opening_balance"
                                        checked={HasOpeningBalance}
                                        onChange={(event) => {
                                            setHasOpeningBalance(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>Is Bank Account</FormLabel>
                                    <Checkbox
                                        name="is_bank_account"
                                        checked={IsBankAccount}
                                        onChange={(event) => {
                                            setIsBankAccount(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect1}
                                    loading={options1.length < 1 ? true : false}
                                    disablePortal
                                    options={options1}
                                    onChange={(event, newValue) => {
                                        setOptionSelect1(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Parent Account"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect2}
                                    loading={options2.length < 1 ? true : false}
                                    disablePortal
                                    options={options2}
                                    onChange={(event, newValue) => {
                                        setOptionSelect2(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Head Account"
                                            onChange={(event) => {
                                                setFilter2(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect3}
                                    loading={options3.length < 1 ? true : false}
                                    disablePortal
                                    options={options3}
                                    onChange={(event, newValue) => {
                                        setOptionSelect3(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Currency"
                                            onChange={(event) => {
                                                setFilter3(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect4}
                                    loading={options4.length < 1 ? true : false}
                                    disablePortal
                                    options={options4}
                                    onChange={(event, newValue) => {
                                        setOptionSelect4(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Account Type"
                                            onChange={(event) => {
                                                setFilter4(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Apply"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default EditChartOfAccount;
