import PropTypes from "prop-types";
import { Card, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const ProjectsChart = ({ projectsDone }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.userState.user);

  return (
    <Card
      sx={{ bgcolor: "#e1eaff", borderRadius: "15px" }}
      elevation={0}
      onClick={() => {
        const IsValid = user.actions.find((role) => {
          if (
            role === "PROJECT_TWO" ||
            role === "PROJECT_ONE" ||
            role === "HEAD" ||
            role === "admin" ||
            role === "super"
          ) {
            return true;
          } else return false;
        });

        if (IsValid) {
          navigate("/View/ViewProject");
        }
      }}
    >
      <Grid
        container
        sx={{
          p: 2,
          pb: 0,
          color: "#fff",
          height: window.innerHeight / 4,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid item xs={12}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            flexDirection={"column"}
          >
            <Grid item>
              <Typography variant="h3" color={"#14213d"} fontWeight={"bolder"}>
                {projectsDone}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" color={"#14213d"}>
                Projects Done
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

ProjectsChart.propTypes = {
  projectsDone: PropTypes.number,
};

export default ProjectsChart;
