import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import AddRequiredDocumentation from "./AddRequiredDocumentation.page";
import ViewRequiredDocumentation from "./ViewRequiredDocumentation.page";

const RequiredDocumentation = ({ next, name, handleTabChange }) => {
    const location = useLocation();
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    return (
        <>
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/RequiredDocumentation/AddRequiredDocumentation` && (
                <AddRequiredDocumentation
                    next={next}
                    name={name}
                    handleTabChange={handleTabChange}
                />
            )}
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/RequiredDocumentation` && (
                <>
                    <ViewRequiredDocumentation />
                </>
            )}
        </>
    );
};

export default RequiredDocumentation;
