import {
    Autocomplete,
    Box,
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { get, patcher } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import { EditRounded } from "@mui/icons-material";
import {
    DailyWorkLogURL,
    WorkCategoryURL,
    WorkLogURL,
} from "../../../../utils/config";
import dateFormatter from "../../../../utils/dateFormater";
// import { useSelector } from "react-redux";

const EditWorkLog = (props) => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    // const selectedDetail = useSelector(
    //     (state) => state.selectedDetail.selected
    // );
    const [filter, setFilter] = useState("");
    const [options, setOptions] = useState([]);
    const [optionsId, setOptionsID] = useState();
    const [optionSelect, setOptionSelect] = useState("");
    const [optionsLoading, setOptionsLoading] = useState(true);
    const [options2, setOptions2] = useState([]);
    const [optionsId2, setOptionsID2] = useState();
    const [optionSelect2, setOptionSelect2] = useState("");
    const [msg, setMsg] = useState("");

    useEffect(() => {
        if (optionSelect === "")
            setOptionSelect(
                `${props.values.row.work_category?.id} - ${props.values.row.workCategory}`
            );
        if (optionSelect2 === "")
            setOptionSelect2(
                `${props.values.row.daily_work_log?.id} - ${props.values.row.dailyWorkLog}`
            );
    }, [props.values.row, optionSelect, optionSelect2]);

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        skilled_worker: props.values.row.skilled_worker,
        date: dateFormatter(props.values.row.date),
        hours: props.values.row.hours,
        work_category_id: props.values.row.work_category?.id,
        daily_work_log_id: props.values.row.daily_work_log?.id,
    };

    const FORM_VALIDATION = Yup.object().shape({
        date: Yup.string().min(1).required("Required"),
        hours: Yup.number().min(1).required("Required"),
        skilled_worker: Yup.number().min(1).required("Required"),
    });

    const formSubmitHandler = (updateData, actions) => {
        setLoading(true);
        const update = {
            id: props.values.id,
            updateData,
        };

        // console.log(update);
        patcher(WorkLogURL, update).then((res) => {
            setLoading(false);
            // console.log(res.error);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                props.close();
            }
        });
    };

    useEffect(() => {
        get(WorkCategoryURL, {
            limit: 10,
            filter: {
                name: filter,
            },
        }).then((res) => {
            let data = [];
            if (res.data.length === 0) {
                setOptionsLoading(false);
            }
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].name}`
                    );
                });
            res && setOptionsID(res.data);
            setOptions(data);
        });
        get(DailyWorkLogURL, {
            limit: 10,
            filter: {
                name: filter,
            },
        }).then((res) => {
            let data = [];
            if (res.data.length === 0) {
                setOptionsLoading(false);
            }
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].date}`
                    );
                });
            res && setOptionsID2(res.data);
            setOptions2(data);
        });
    }, [filter]);

    return (
        <div>
            <TitleHeader
                title="Edit Work Log And Branch Of Work"
                icon={<EditRounded />}
            />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        optionsId.forEach((name, index) => {
                            if (`${name?.id} - ${name.name}` === optionSelect) {
                                values.work_category_id = name.id;
                            }
                        });
                        optionsId2.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.date}` === optionSelect2
                            ) {
                                values.daily_work_log_id = name.id;
                            }
                        });
                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="skilled_worker"
                                    label="Skilled Worker"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="hours"
                                    label="Hours"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={4}>
                                <Autocomplete
                                    value={optionSelect}
                                    loading={
                                        options.length < 1 && optionsLoading
                                    }
                                    disablePortal
                                    options={options}
                                    onChange={(event, newValue) => {
                                        setOptionSelect(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Work Category"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    value={optionSelect2}
                                    loading={
                                        options2.length < 1 && optionsLoading
                                    }
                                    disablePortal
                                    options={options2}
                                    onChange={(event, newValue) => {
                                        setOptionSelect2(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Daily Work Log"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>Date</FormLabel>
                                    <TextFieldWrapper
                                        name="date"
                                        variant="outlined"
                                        type="date"
                                    />
                                </FormControl>
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Apply"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};

export default EditWorkLog;
