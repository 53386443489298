import {
    Autocomplete,
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { EditRounded } from "@mui/icons-material";

import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { get, patcher } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    JobSafetyEquipmentURL,
    JobTitleURL,
    SafetyEquipmentURL,
} from "../../../../utils/config";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { useDispatch, useSelector } from "react-redux";

const EditJobSafetyEquipment = (props) => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState("");
    const [filter2, setFilter2] = useState("");
    const [options, setOptions] = useState([]);
    const [optionsId, setOptionsID] = useState();
    const [optionSelect, setOptionSelect] = useState("");
    const [options2, setOptions2] = useState([]);
    const [optionsId2, setOptionsID2] = useState();
    const [optionSelect2, setOptionSelect2] = useState("");
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [msg, setMsg] = useState("");

    useEffect(() => {
        if (optionSelect === "")
            setOptionSelect(
                `${selectedRow.safety_equipment?.id} - ${selectedRow.safetyEquipment}`
            );
        if (optionSelect2 === "")
            setOptionSelect2(
                `${selectedRow.job_title?.id} - ${selectedRow.jobTitle}`
            );
    }, [selectedRow, optionSelect, optionSelect2]);

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        safety_equipment_id: selectedRow.safety_equipment?.id,
        job_title_id: selectedRow.job_title?.id,
    };

    const FORM_VALIDATION = Yup.object().shape({});

    const formSubmitHandler = (updateData, actions) => {
        setLoading(true);
        const update = {
            id: selectedRow.id,
            updateData,
        };

        patcher(JobSafetyEquipmentURL, update).then((res) => {
            setLoading(false);
            // console.log(res.error);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                dispatch(setRefresh(!refreshTable));
                setSuccess(true);
                setError(false);
                props.close();
            }
        });
    };

    useEffect(() => {
        get(SafetyEquipmentURL, {
            limit: 10,
            filter: {
                name: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].name}`
                    );
                });
            res && setOptionsID(res.data);
            setOptions(data);
        });
        get(JobTitleURL, {
            limit: 10,
            filter: {
                title_name: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].title_name}`
                    );
                });
            res && setOptionsID2(res.data);
            setOptions2(data);
        });
    }, [filter, filter2]);

    return (
        <div>
            <TitleHeader
                title="Edit Job Safety Equipment"
                icon={<EditRounded />}
            />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;

                        optionsId.forEach((name, index) => {
                            if (`${name?.id} - ${name.name}` === optionSelect) {
                                values.safety_equipment_id = name.id;
                            }
                        });
                        optionsId2.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.title_name}` ===
                                optionSelect2
                            ) {
                                values.job_title_id = name.id;
                            }
                        });

                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect}
                                    loading={options.length < 1 ? true : false}
                                    disablePortal
                                    options={options}
                                    onChange={(event, newValue) => {
                                        setOptionSelect(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Safety Equipment"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect2}
                                    loading={options2.length < 1 ? true : false}
                                    disablePortal
                                    options={options2}
                                    onChange={(event, newValue) => {
                                        setOptionSelect2(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Job Title"
                                            onChange={(event) => {
                                                setFilter2(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Apply"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default EditJobSafetyEquipment;
