import React, { useEffect, useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import { ExpandMore, CheckCircleOutline } from "@mui/icons-material";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import { Box } from "@mui/system";
import { get, patcher } from "../../../../utils/Caller";
import {
    EmployeeURL,
    AttendanceSheetReleaseURL,
    BusinessUnitURL,
    GetUser,
} from "../../../../utils/config";
import { LoadingButton } from "@mui/lab";
import { SnackBar } from "../../../../Components/SnackBar/SnackBar.component";
import { DataGrid } from "@mui/x-data-grid";
import AttendanceSheetColumns from "./AttendanceSheetColumns.json";

const AATYPE = ["Attendance", "Absence"];

const AttendanceRelease = () => {
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [limit, setLimit] = useState(8);
    const [filter, setFilter] = useState();
    const [options, setOptions] = useState([]);
    const [optionSelect, setOptionSelect] = useState();
    const [filter2, setFilter2] = useState();
    const [options2, setOptions2] = useState([]);
    const [optionSelect2, setOptionSelect2] = useState();
    const [filter3, setFilter3] = useState();
    const [options3, setOptions3] = useState([]);
    const [delegatedUsernameId, setDelegatedUsernameId] = useState();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setMsg] = useState("");
    const [pageNo, setPageNo] = useState(0);
    const [open, setOpen] = useState(true);
    const [ids, setIds] = useState([]);
    const [IncludeReleased, setIncludeReleased] = useState(false);

    const ATTENDANCE_STATUS = [
        "Pending",
        "Released",
        "Approved",
        "Rejected",
        "Locked",
    ];

    const INITIAL_VALUES = {
        startDate: "",
        endDate: "",
        employee_id: null,
        business_unit_id: null,
        isReleaseSelected: IncludeReleased,
    };
    const FORM_VALIDATION = Yup.object().shape({
        startDate: Yup.string().required("*Required"),
        endDate: Yup.string()
            .required("*Required")
            .test(
                "test-name",
                "Number of days in the date range(Start Date - End Date) can't be greater than from 31",
                function (value) {
                    const diffDays =
                        (new Date(value) -
                            new Date(this.resolve(Yup.ref("startDate")))) /
                        (1000 * 3600 * 24);
                    if (diffDays > 31) {
                        return false;
                    }
                    return true;
                }
            ),
    });

    useEffect(() => {
        get(EmployeeURL, {
            limit: 10,
            filter: {
                all: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.first_name}`
                    );
                });
            setOptions(data);
        });
        get(BusinessUnitURL, {
            limit: 10,
            filter: {
                all: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.name}`
                    );
                });
            setOptions2(data);
        });
        get(GetUser, {
            limit: 10,
            filter: {
                username: filter3,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.username}`
                    );
                });
            setOptions3(data);
        });
    }, [filter, filter2, filter3]);

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        let tableData = [];
        if (rows.length === 0) {
            setLoading(true);
        }

        get(AttendanceSheetReleaseURL, {
            employee_id: data?.employee_id,
            startDate: data?.startDate,
            endDate: data?.endDate,
            business_unit_id: data?.business_unit_id,
            isReleaseSelected: data?.include_released,
        }).then((resp) => {
            resp && resp.data && setLoading(false);
            resp &&
                resp.data.forEach((dadata, index) => {
                    tableData.push({
                        ...dadata,
                        idNumber: dadata.employee?.id_number,
                        FullName: `${dadata.employee?.first_name} ${dadata.employee?.middle_name}`,
                        is_absence_includes_day_off:
                            dadata.attendance_abscence_type
                                ?.is_absence_includes_day_off,
                        aa_description:
                            dadata.attendance_abscence_type?.aa_description,
                        aa_type:
                            AATYPE[
                                dadata.attendance_abscence_type?.aa_type - 1
                            ],
                        attendanceStatus:
                            ATTENDANCE_STATUS[dadata.attendance_status - 1],
                        rate: dadata.overtime_rate?.rate,
                    });
                });
            setRows(tableData);
        });
    };

    const apiHandler = () => {
        patcher(AttendanceSheetReleaseURL, {
            hcmReleaseList: ids,
            isRelease: true,
            delegated_username: delegatedUsernameId,
        }).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data?.success) {
                setSuccess(true);
                setError(false);
                return 1;
            }
        });
    };

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "2ch" }}>
            <Accordion
                expanded={open}
                onChange={() => {
                    setOpen(!open);
                }}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography fontWeight={600}>Attendance Release</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Formik
                        initialValues={{ ...INITIAL_VALUES }}
                        validationSchema={FORM_VALIDATION}
                        onSubmit={(values, actions) => {
                            values.employee_id = parseInt(
                                optionSelect?.split("-")[0]
                            );
                            values.business_unit_id = parseInt(
                                optionSelect2?.split("-")[0]
                            );
                            values.include_released = IncludeReleased;
                            formSubmitHandler(values, actions);
                        }}
                    >
                        <Form>
                            <Grid
                                container
                                spacing={2}
                                style={{ alignItems: "end" }}
                            >
                                <Grid item>
                                    <FormControl>
                                        <FormLabel>Start Date</FormLabel>
                                        <TextFieldWrapper
                                            size={"small"}
                                            name="startDate"
                                            variant="outlined"
                                            type="Date"
                                            fullWidth
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <FormLabel>End Date</FormLabel>
                                        <TextFieldWrapper
                                            size={"small"}
                                            name="endDate"
                                            variant="outlined"
                                            type="Date"
                                            fullWidth
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        size="small"
                                        loading={
                                            options.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options}
                                        onChange={(event, newValue) => {
                                            setOptionSelect(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                size={"small"}
                                                {...params}
                                                label="Employee"
                                                onChange={(event) => {
                                                    setFilter(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        size="small"
                                        loading={
                                            options2.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options2}
                                        onChange={(event, newValue) => {
                                            setOptionSelect2(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                size={"small"}
                                                {...params}
                                                label="Business Unit"
                                                onChange={(event) => {
                                                    setFilter2(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Checkbox
                                            checked={IncludeReleased}
                                            onChange={(event) => {
                                                setIncludeReleased(
                                                    event.target.checked
                                                );
                                            }}
                                        />
                                        <FormLabel>Include Released</FormLabel>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <div>
                                <LoadingButton
                                    data-testid="AddButton"
                                    loading={loading}
                                    type="submit"
                                    className="ButtonContained"
                                    style={{
                                        marginTop: "2ch",
                                        float: "right",
                                    }}
                                >
                                    {loading ? null : "Search"}
                                </LoadingButton>
                            </div>
                        </Form>
                    </Formik>
                </AccordionDetails>
            </Accordion>
            <Box>
                {AttendanceSheetColumns.release.length > 0 && (
                    <Paper
                        style={{
                            boxShadow: "0 0 5px silver",
                            padding: "1ch",
                        }}
                        elevation={0}
                    >
                        <Box
                            style={{
                                marginBottom: "1ch",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Autocomplete
                                size="small"
                                loading={options3.length < 1 ? true : false}
                                disablePortal
                                options={options3}
                                style={{ width: "40%" }}
                                onChange={(event, newValue) => {
                                    setDelegatedUsernameId(
                                        newValue?.split("-")[0]
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        size={"small"}
                                        {...params}
                                        label="Approver"
                                        onChange={(event) => {
                                            setFilter3(event.target.value);
                                        }}
                                    />
                                )}
                            />
                            <LoadingButton
                                data-testid="ApproveButton"
                                loading={loading}
                                color="success"
                                variant="contained"
                                endIcon={<CheckCircleOutline />}
                                onClick={() => {
                                    apiHandler();
                                }}
                            >
                                {loading ? null : "Release"}
                            </LoadingButton>
                        </Box>

                        <DataGrid
                            checkboxSelection
                            loading={loading}
                            rows={rows}
                            columns={AttendanceSheetColumns.release}
                            onPageChange={(page) => {
                                if (pageNo < page) {
                                    setPageNo(pageNo + 1);
                                    addLimit();
                                }
                            }}
                            autoHeight={true}
                            pageSize={8}
                            rowsPerPageOptions={[8]}
                            onSelectionModelChange={(id) => {
                                setIds(id);
                            }}
                        />
                    </Paper>
                )}
            </Box>

            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};

export default AttendanceRelease;
