import React from "react";
import { useLocation } from "react-router";
import AddInstruction from "./AddInstruction.page";
import ViewInstruction from "./ViewInstruction.page";

const Instruction = () => {
    const location = useLocation();

    return (
        <>
            {location.pathname === "/View/Instruction/AddInstruction" && (
                <AddInstruction />
            )}
            {location.pathname === "/View/Instruction" && <ViewInstruction />}
        </>
    );
};

export default Instruction;
