import React, { useState, useEffect } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { setSelected } from "../../Redux/Slice/selectedRow";
import { Box, Modal } from "@mui/material";

import EditModal from "../../Layouts/Modal/EditModal.modal";
import DeleteModal from "../../Layouts/Modal/DeleteModal.modal";
import { DeleteRounded, EditRounded } from "@mui/icons-material";
import ContactDetailView from "../../Pages/Finance/FinanceMaster/Contact/ContactDetailView.page";

const Table = (props) => {
    // let rows = props.rows;
    let columns = props.columns;

    const [rows, setRows] = useState(props.rows);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [contactModal, setContactModal] = useState(false);
    const [pageNo, setPageNo] = useState(0);
    const dispatch = useDispatch();
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    const editButtonHandler = (event, values) => {
        dispatch(setSelected(values.row));
        setEditModal(true);
    };

    const deleteButtonHandler = (event, values) => {
        dispatch(setSelected(values.row));
        setDeleteModal(true);
    };

    const closeModal = () => {
        setEditModal(false);
        setDeleteModal(false);
    };

    useEffect(() => {
        setRows(props.rows);
    }, [props.rows]);

    if (props.actions === true) {
        columns = [
            ...columns,
            {
                field: "actions",
                headerName: "Actions",
                type: "actions",
                minWidth: 150,
                flex: 1,
                getActions: (cellValues) => [
                    <GridActionsCellItem
                        icon={<EditRounded color="primary" />}
                        label="Edit"
                        onClick={(event) => {
                            event.stopPropagation();
                            editButtonHandler(event, cellValues);
                        }}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteRounded color="error" />}
                        label="Delete"
                        onClick={(event) => {
                            event.stopPropagation();
                            deleteButtonHandler(event, cellValues);
                        }}
                    />,
                ],
            },
        ];
    }

    return (
        <div style={{ height: 500, width: "100%" }}>
            <DataGrid
                onPageChange={(page) => {
                    if (pageNo < page) {
                        setPageNo(pageNo + 1);
                        props.addLimit();
                    }
                }}
                loading={props.loading}
                rows={rows}
                columns={columns}
                autoHeight={true}
                pageSize={8}
                rowsPerPageOptions={[8]}
                onRowClick={(selected) => {
                    props.viewDetail && props.viewDetail(selected.row);
                    props.viewClientDetail &&
                        props.viewClientDetail(selected.row);
                    props.ViewEmployee && props.ViewEmployee(selected.row);
                    props.ViewVacancy && props.ViewVacancy(selected.row);
                    props.contactDetail &&
                        dispatch(setSelected(selected.row)) &&
                        setContactModal(true);
                }}
            />
            <Modal
                className="Modal"
                open={editModal || deleteModal || contactModal}
                onClose={() => {
                    setEditModal(false);
                    setDeleteModal(false);
                    setContactModal(false);
                }}
            >
                <Box
                    className="ModalContainer"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {editModal && <EditModal close={closeModal} />}
                    {deleteModal && (
                        <DeleteModal
                            name={selectedRow.name}
                            id={selectedRow.id}
                            close={closeModal}
                        />
                    )}
                    {contactModal && (
                        <ContactDetailView
                            close={closeModal}
                            selectedRow={selectedRow}
                        />
                    )}
                </Box>
            </Modal>
        </div>
    );
};

export default Table;
