import { ChevronLeft, ViewComfy } from "@mui/icons-material";
import { Button, Card, Grid, Modal, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelected } from "../../../Redux/Slice/selectedRow";
import AddLeaveAssignment from "../../HCM/EmployeeMaster/LeaveAssignment/AddLeaveAssignment.page";
import ViewLeaveAssignment from "../../HCM/EmployeeMaster/LeaveAssignment/ViewLeaveAssignment.page";

const LeaveRequest = () => {
    const user = useSelector((state) => state.userState.user);
    const dispatch = useDispatch();
    dispatch(setSelected(user?.employee));
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(1);

    const closeModal = () => {
        setOpen(false);
    };

    return (
        <>
            <Card
                sx={{
                    bgcolor: "#FFF4DD",
                    "&:hover": { bgcolor: "#EEE3CC" },
                    borderRadius: "15px",
                }}
                onClick={() => {
                    setOpen(true);
                }}
                elevation="0"
            >
                <Grid
                    container
                    sx={{
                        p: 2,
                        pb: 0,
                        color: "#fff",
                        height: window.innerHeight / 4,
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Grid item xs={12}>
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                            flexDirection={"column"}
                        >
                            <Grid item>
                                <Typography
                                    variant="h3"
                                    color={"#14213d"}
                                    fontWeight={"bolder"}
                                >
                                    Leave Request
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
            <Modal className="Modal" open={open} onClose={closeModal}>
                <Box
                    className="ModalContainer"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minWidth: "80%",
                        maxWidth: "90%",
                    }}
                >
                    <Paper
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            minWidth: "100%",
                            minHeight: "50vh",
                            maxHeight: "80vh",
                            overflowY: "scroll",
                            padding: "2ch",
                            gap: "2ch",
                        }}
                    >
                        <Box
                            style={{
                                backgroundColor: "white",
                                width: "100%",
                                position: "sticky",
                                top: "0",
                            }}
                        >
                            {page > 1 && (
                                <Button
                                    startIcon={<ChevronLeft />}
                                    onClick={() => {
                                        if (page === 2) {
                                            setPage(1);
                                        }
                                    }}
                                >
                                    Back
                                </Button>
                            )}
                            <Button
                                startIcon={<ViewComfy />}
                                onClick={() => {
                                    if (page === 1) {
                                        setPage(2);
                                    }
                                }}
                            >
                                View Requests
                            </Button>
                        </Box>
                        {page === 1 && (
                            <AddLeaveAssignment backButton={false} />
                        )}
                        {page === 2 && <ViewLeaveAssignment />}
                    </Paper>
                </Box>
            </Modal>
        </>
    );
};

export default LeaveRequest;
