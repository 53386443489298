import PropTypes from "prop-types";
import { Cancel } from "@mui/icons-material";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import React from "react";

export const Renderlist = ({ i, list, label, listLength, setListSize }) => {
    const cancellist = (i) => {
        if (listLength.length > 1) {
            const theRest = listLength.filter((a) => a !== i);
            setListSize(theRest);
        }
    };

    return (
        <Box>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography fontWeight={600}>
                    {label} {i + 1}
                </Typography>
                {listLength.length > 1 && (
                    <IconButton
                        onClick={() => {
                            cancellist(i);
                        }}
                    >
                        <Cancel color="error" />
                    </IconButton>
                )}
            </div>
            <Divider variant="fullWidth" />
            <Box minHeight={"2ch"} />
            {list(i)}
        </Box>
    );
};

Renderlist.propTypes = {
    i: PropTypes.number.isRequired,
    label: PropTypes.string,
    list: PropTypes.func.isRequired,
    listLength: PropTypes.arrayOf(PropTypes.number).isRequired,
    setListSize: PropTypes.func.isRequired,
};
