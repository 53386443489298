import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
    Box,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { EditRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { patcher, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    RequestPaymentURL,
    EmployeeURL,
    ManPowerURL,
    ProjectURL,
    CashPaymentVoucherURL,
} from "../../../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";

const EditRequestPayment = (props) => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setMsg] = useState("");
    // const selectedRow = useSelector((state) => state.selectedRow.selected);
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const selectedDetail = useSelector(
        (state) => state.selectedDetail.selected
    );
    const [filter1, setFilter1] = useState("");
    const [options1, setOptions1] = useState([]);
    const [optionSelect1, setOptionSelect1] = useState("");
    const [filter2, setFilter2] = useState("");
    const [options2, setOptions2] = useState([]);
    const [optionSelect2, setOptionSelect2] = useState("");
    const [filter3, setFilter3] = useState("");
    const [options3, setOptions3] = useState([]);
    const [optionSelect3, setOptionSelect3] = useState("");
    const [filter4, setFilter4] = useState("");
    const [options4, setOptions4] = useState([]);
    const [optionSelect4, setOptionSelect4] = useState("");
    const [optionSelect5, setOptionSelect5] = useState("");
    const [optionSelect6, setOptionSelect6] = useState("");
    const [requestType, setRequestType] = useState(selectedDetail.request_type);
    const [approvalStatus, setApprovalStatus] = useState(
        selectedDetail.approval_status
    );

    const REQUEST_TYPE = ["Material", "Expense"];
    const APPROVAL_STATUS = ["Pending", "Approve", "Reject"];

    useEffect(() => {
        if (optionSelect1 === "")
            setOptionSelect1(
                `${selectedDetail.prepared_by?.id} - ${selectedDetail.preparedBy}`
            );
        if (optionSelect2 === "")
            setOptionSelect2(
                `${selectedDetail.checked_by?.id} - ${selectedDetail.checkedBy}`
            );
        if (optionSelect3 === "")
            setOptionSelect3(
                `${selectedDetail.approved_by?.id} - ${selectedDetail.approvedBy}`
            );
        if (optionSelect4 === "")
            setOptionSelect4(
                `${selectedDetail.project?.id} - ${selectedDetail.projectName}`
            );
        if (optionSelect5 === "")
            setOptionSelect5(
                `${selectedDetail.cash_payment_voucher?.id} - ${selectedDetail.cashPaymentVoucher}`
            );
        if (optionSelect6 === "")
            setOptionSelect6(
                `${selectedDetail.from?.id} - ${selectedDetail.fromName}`
            );
    }, [
        selectedDetail,
        optionSelect1,
        optionSelect2,
        optionSelect3,
        optionSelect4,
        optionSelect5,
        optionSelect6,
    ]);

    const INITIAL_VALUES = {
        date: selectedDetail.date,
        amount: selectedDetail.amount,
        request_type: selectedDetail.request_type,
        approval_status: selectedDetail.approval_status,
        description: selectedDetail.description,
        check_number: selectedDetail.check_number,
        remaining_balance: selectedDetail.remaining_balance,
        prepared_by_id: selectedDetail.prepared_by?.id,
        checked_by_id: selectedDetail.checked_by?.id,
        approved_by_id: selectedDetail.approved_by?.id,
        project_id: selectedDetail.project?.id,
        cash_payment_voucher_id: selectedDetail.cash_payment_voucher?.id,
        from_id: selectedDetail.from?.id,
        isProtectedForEdit: false,
    };

    const FORM_VALIDATION = Yup.object().shape({
        date: Yup.string().min(1).required("*Required"),
        amount: Yup.number().min(1),
        request_type: Yup.number().min(1),
        approval_status: Yup.number().min(1),
        description: Yup.string().min(1),
        check_number: Yup.string().min(1),
        remaining_balance: Yup.number().min(1),
    });
    useEffect(() => {
        get(EmployeeURL, {
            limit: 10,
            filter: {
                all: filter1,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.first_name}`
                    );
                });
            setOptions1(data);
        });

        get(ProjectURL, {
            limit: 10,
            filter: {
                all: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.name}`
                    );
                });
            setOptions2(data);
        });

        get(CashPaymentVoucherURL, {
            limit: 10,
            filter: {
                all: filter3,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.description}`
                    );
                });
            setOptions3(data);
        });

        get(ManPowerURL, {
            limit: 10,
            filter: {
                all: filter4,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.name}`
                    );
                });
            setOptions4(data);
        });
    }, [filter1, filter2, filter3, filter4]);

    const formSubmitHandler = (updateData, actions) => {
        setLoading(true);
        const update = {
            id: selectedDetail.id,
            updateData,
        };
        patcher(RequestPaymentURL, update).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                dispatch(setRefresh(!refreshTable));
                setSuccess(true);
                setError(false);
                actions.resetForm();
            }
        });
    };

    return (
        <div>
            <TitleHeader title="Edit Request Payment" icon={<EditRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        values.request_type = requestType;
                        values.approval_status = approvalStatus;

                        values.prepared_by_id = parseInt(
                            optionSelect1.split("-")[0]
                        );
                        values.checked_by_id = parseInt(
                            optionSelect2.split("-")[0]
                        );
                        values.approved_by_id = parseInt(
                            optionSelect3.split("-")[0]
                        );
                        values.project_id = parseInt(
                            optionSelect4.split("-")[0]
                        );
                        values.cash_payment_voucher_id = parseInt(
                            optionSelect5.split("-")[0]
                        );
                        values.from_id = parseInt(optionSelect6.split("-")[0]);

                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>Date</FormLabel>
                                    <TextFieldWrapper
                                        name="date"
                                        label="Date"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="amount"
                                    label="Amount"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    select
                                    name="request_type"
                                    label="Request Type"
                                    variant="outlined"
                                    value={requestType}
                                    options={REQUEST_TYPE ?? " "}
                                    onChange={(e) => {
                                        setRequestType(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    select
                                    name="approval_status"
                                    label="Approval Status"
                                    variant="outlined"
                                    value={approvalStatus}
                                    options={APPROVAL_STATUS ?? " "}
                                    onChange={(e) => {
                                        setApprovalStatus(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldWrapper
                                    name="description"
                                    label="Description"
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="check_number"
                                    label="Check Number"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="remaining_balance"
                                    label="Remaining Balance"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    value={optionSelect1}
                                    loading={options1.length < 1 ? true : false}
                                    disablePortal
                                    options={options1}
                                    onChange={(event, newValue) => {
                                        setOptionSelect1(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Prepared By"
                                            onChange={(event) => {
                                                setFilter1(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect2}
                                    loading={options1.length < 1 ? true : false}
                                    disablePortal
                                    options={options1}
                                    onChange={(event, newValue) => {
                                        setOptionSelect2(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Checked By"
                                            onChange={(event) => {
                                                setFilter1(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect3}
                                    loading={options1.length < 1 ? true : false}
                                    disablePortal
                                    options={options1}
                                    onChange={(event, newValue) => {
                                        setOptionSelect3(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Approved By"
                                            onChange={(event) => {
                                                setFilter1(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect4}
                                    loading={options2.length < 1 ? true : false}
                                    disablePortal
                                    options={options2}
                                    onChange={(event, newValue) => {
                                        setOptionSelect4(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Project"
                                            onChange={(event) => {
                                                setFilter2(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect5}
                                    loading={options3.length < 1 ? true : false}
                                    disablePortal
                                    options={options3}
                                    onChange={(event, newValue) => {
                                        setOptionSelect5(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Cash Payment Voucher"
                                            onChange={(event) => {
                                                setFilter3(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Autocomplete
                                    value={optionSelect6}
                                    loading={options4.length < 1 ? true : false}
                                    disablePortal
                                    options={options4}
                                    onChange={(event, newValue) => {
                                        setOptionSelect6(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="From"
                                            onChange={(event) => {
                                                setFilter4(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Apply"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default EditRequestPayment;
