import React from "react";
import { useLocation } from "react-router";
import AddWorkCategory from "./AddWorkCategory.page";
import ViewWorkCategory from "./ViewWorkCategory.page";

const WorkCategory = () => {
    const location = useLocation();

    return (
        <>
            {location.pathname === "/View/WorkCategory/AddWorkCategory" && (
                <AddWorkCategory />
            )}
            {location.pathname === "/View/WorkCategory" && <ViewWorkCategory />}
        </>
    );
};

export default WorkCategory;
