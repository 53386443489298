import { Add, SearchRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    IconButton,
    InputAdornment,
    TextField,
    Modal,
    Button,
    Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import OperationsTable from "../../../../Components/Table/OperationsTable.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { caller, get } from "../../../../utils/Caller";
import {
    IsApprovedURL,
    ProjectEditRequestURL,
    TaskManagerURL,
} from "../../../../utils/config";

const ViewTaskManager = () => {
    // const [loading, setloading] = useState(false);
    const navigate = useNavigate();
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(16);
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const [filter, setFilter] = useState("");
    const dispatch = useDispatch();
    const [Search, setSearch] = useState("");
    const [approved, setApproved] = useState(false);
    const [buttonLoad, setButtonLoad] = useState(false);
    const [request, setRequest] = useState(false);
    const [open, setOpen] = useState(false);
    const [viewToDo, setViewToDo] = useState(false);
    const [reason, setReason] = useState("");

    const columns = [
        {
            field: "name",
            headerName: "Name",
            width: 150,
        },
        {
            field: "task_start_date",
            headerName: "Task Start Date",
            width: 130,
        },
        {
            field: "task_end_date",
            headerName: "Task End Date",
            width: 130,
        },
        {
            field: "description",
            headerName: "Description",
            width: 200,
        },
        {
            field: "duration_in_days",
            headerName: "Duration In Days",
            width: 100,
        },
        {
            field: "working_days",
            headerName: "Working Days",
            width: 100,
        },
        {
            field: "projectName",
            headerName: "Project Name",
            width: 100,
        },
    ];

    useEffect(() => {
        let requestData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        setButtonLoad(true);
        get(TaskManagerURL, {
            limit: limit,
            filter: { project_id: selectedRow.id, all: filter },
        }).then((res) => {
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    requestData.push({
                        ...data,
                        projectName: data.project?.name,
                    });
                });
            setRows(requestData);
        });
        get(IsApprovedURL, { project_id: selectedRow.id }).then((res) => {
            setButtonLoad(false);
            if (res?.data) {
                setApproved(res.data?.isUserApproved);
                if (res.data?.canUserRequest) {
                    setRequest(true);
                }
            }
        });
    }, [refreshTable, limit, rows.length, selectedRow, filter]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    const isApproved = () => {
        setButtonLoad(true);
        get(IsApprovedURL, { project_id: selectedRow.id }).then((res) => {
            setButtonLoad(false);
            if (res?.data) {
                setApproved(res.data.isUserApproved);
            }
        });
    };

    const requestEdit = () => {
        setButtonLoad(true);
        caller(ProjectEditRequestURL, {
            project_id: selectedRow.id,
            reason,
        }).then((res) => {
            setButtonLoad(false);
            if (res?.data) {
                isApproved();
                setRequest(false);
                setOpen(false);
            }
        });
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <div style={{ display: "flex", gap: "2ch" }}>
                    {/* <Button
                        variant="contained"
                        color="primary"
                        endIcon={<Add />}
                        onClick={() => {
                            navigate(
                                `/View/ViewProject/${selectedRow.id}/TaskManager/AddSubTask`
                            );
                        }}
                    >
                        Add Sub Task
                    </Button> */}
                    <LoadingButton
                        loading={buttonLoad}
                        variant="contained"
                        color="success"
                        endIcon={<Add />}
                        onClick={() => {
                            if (approved) {
                                navigate(
                                    `/View/ViewProject/${selectedRow.id}/TaskManager/AddTaskManager`,
                                );
                            } else if (!approved && request) {
                                setOpen(true);
                            }
                        }}
                    >
                        {!request && !approved && "Pending"}
                        {request && "Request Edit"}
                        {approved && !request && "Edit Task"}
                    </LoadingButton>
                </div>
            </div>
            <div className="TableContainer">
                <OperationsTable
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                    subTask={{ value: true, setViewToDo: setViewToDo }}
                    toDos={viewToDo}
                />
            </div>
            <Modal
                className={"Modal"}
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <Paper
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "70%",
                        minWidth: "50%",
                        padding: "3ch",
                        gap: "2ch",
                    }}
                >
                    <TextField
                        multiline
                        rows={3}
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        label={"Reason"}
                    />
                    <Button
                        onClick={() => {
                            if (approved) {
                                navigate(
                                    `/View/ViewProject/${selectedRow.id}/TaskManager/AddTaskManager`,
                                );
                            } else if (!approved && request) {
                                requestEdit();
                            }
                        }}
                    >
                        Request Edit
                    </Button>
                </Paper>
            </Modal>
        </div>
    );
};

export default ViewTaskManager;
