import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik, FieldArray } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { caller, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import { Add, AddRounded, Cancel } from "@mui/icons-material";
import {
  ProjectWorkingDaysURL,
  SubTaskURL,
  TaskManagerURL,
} from "../../../../utils/config";
import { useSelector } from "react-redux";

const AddSubTask = ({ setIsTouched }) => {
  const [loading, setLoading] = useState(false);
  const [protectedEdit, setProtectedEdit] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  // const selectedDetail = useSelector(
  //     (state) => state.selectedDetail.selected
  // );
  const selectedRow = useSelector((state) => state.selectedRow.selected);
  const [filter, setFilter] = useState("");
  const [options, setOptions] = useState([]);
  const [optionsId, setOptionsID] = useState();
  const [optionSelect, setOptionSelect] = useState("");
  const [optionsLoading, setOptionsLoading] = useState(true);
  const [msg, setMsg] = useState("");
  const [options2, setOptions2] = useState([]);
  const [optionSelect2, setOptionSelect2] = useState([]);
  const [filter2, setFilter2] = useState("");
  const [toDo, setToDo] = useState({ 0: "" });
  const [taskStartDate, setTaskStartDate] = useState("");
  const [taskEndDate, setTaskEndDate] = useState("");
  const [differenceInDays, setDifferenceInDays] = useState("");
  const [workingDays, setWorkingDays] = useState("");

  const INITIAL_VALUES = {
    isProtectedForEdit: false,
    task_end_date: "",
    name: "",
    task_start_date: "",
    duration_in_days: "",
    working_days: "",
    prerequisite: [],
    todos: [
      {
        name: "",
        quantity: "",
        total_area: "",
        unit_price: "",
        quantity_type: "",
      },
    ],
    task_manager_id: null,
  };

  const FORM_VALIDATION = Yup.object().shape({
    name: Yup.string().min(1).required("Required"),
    task_start_date: Yup.string().min(1),
    task_end_date: Yup.string().min(1),
    duration_in_days: Yup.number(),
    description: Yup.string(),
    working_days: Yup.number(),
  });

  const formSubmitHandler = (data, actions) => {
    // console.log(data);
    setLoading(true);
    caller(SubTaskURL, data).then((res) => {
      setLoading(false);
      // console.log(res.error);
      if (res.error) {
        Object.keys(res.error).forEach((data, i) => {
          setMsg(res.error[data]);
        });
        setSuccess(false);
        setError(true);
      } else if (res.data.success) {
        setSuccess(true);
        setError(false);
        actions.resetForm();
        setToDo({ 0: "" });
      }
    });
  };

  useEffect(() => {
    get(TaskManagerURL, {
      limit: 10,
      filter: {
        project_id: selectedRow.id,
        name: filter,
      },
    }).then((res) => {
      let data = [];
      if (res.data?.length === 0) {
        setOptionsLoading(false);
      }
      res &&
        res.data.forEach((name, index) => {
          data.push(`${res.data[index]?.id} - ${res.data[index].name}`);
        });
      res && setOptionsID(res.data);
      setOptions(data);
    });
    get(SubTaskURL, {
      limit: 1000,
      filter: {
        all: filter2,
      },
    }).then((res) => {
      let data = [];
      if (res.data.length === 0) {
        setOptionsLoading(false);
      }
      res &&
        res.data.forEach((name, index) => {
          if (name.task_manager?.project_id === selectedRow.id) {
            data.push(`${res.data[index]?.id} - ${res.data[index].name}`);
          }
        });
      setOptions2(data);
    });
  }, [filter, filter2, selectedRow]);

  useEffect(() => {
    get(ProjectWorkingDaysURL, {
      date1: taskStartDate,
      date2: taskEndDate,
    }).then((res) => {
      if (res?.data) {
        setWorkingDays(res.data?.workingDays);
        setDifferenceInDays(res.data?.differenceInDays);
      }
    });
  }, [taskStartDate, taskEndDate]);

  return (
    <div>
      <TitleHeader title="Add Sub Task" icon={<AddRounded />} />
      <div className="FormContainer">
        <Formik
          initialValues={{ ...INITIAL_VALUES }}
          validationSchema={FORM_VALIDATION}
          onSubmit={(values, actions) => {
            values.isProtectedForEdit = protectedEdit;
            values.task_start_date = taskStartDate;
            values.task_end_date = taskEndDate;
            values.duration_in_days = differenceInDays;
            values.working_days = workingDays;

            optionsId.forEach((name, index) => {
              if (`${name?.id} - ${name.name}` === optionSelect) {
                values.task_manager_id = name.id;
              }
            });
            optionSelect2?.forEach((option, index) => {
              let task_id = parseInt(option.split("-")[0]);
              values.prerequisite.push(task_id);
            });
            let todoArray = [];
            Object.keys(toDo).forEach((todo) => {
              todoArray.push(toDo[todo]);
            });
            formSubmitHandler(values, actions);
          }}
        >
          {({ values, setValues }) => {
            Object.keys(INITIAL_VALUES).forEach((field) => {
              if (
                INITIAL_VALUES[field]?.toString() !== values[field]?.toString()
              )
                if (setIsTouched) setIsTouched(true);
            });
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextFieldWrapper
                      name="name"
                      label="Name"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Autocomplete
                      loading={options.length < 1 && optionsLoading}
                      disablePortal
                      options={options}
                      onChange={(event, newValue) => {
                        setOptionSelect(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Main Task"
                          onChange={(event) => {
                            setFilter(event.target.value);
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Box width={"100%"} />
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <FormLabel>Task Start Date</FormLabel>
                      <TextFieldWrapper
                        name="task_start_date"
                        variant="outlined"
                        type="date"
                        value={taskStartDate}
                        onChange={(e) => {
                          setTaskStartDate(e.target.value);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <FormLabel>Task End Date</FormLabel>
                      <TextFieldWrapper
                        name="task_end_date"
                        variant="outlined"
                        type="date"
                        value={taskEndDate}
                        onChange={(e) => {
                          setTaskEndDate(e.target.value);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Box width={"100%"} />
                  <Grid item xs={4}>
                    <TextFieldWrapper
                      disabled
                      name="duration_in_days"
                      label="Duration In Days"
                      variant="outlined"
                      type="number"
                      value={differenceInDays}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextFieldWrapper
                      disabled
                      name="working_days"
                      label="Working Days"
                      variant="outlined"
                      type="number"
                      value={workingDays}
                    />
                  </Grid>
                  <Box width={"100%"} />
                  <Grid item xs={6}>
                    <Stack spacing={3}>
                      <Autocomplete
                        multiple
                        getOptionLabel={(option) => option || ""}
                        filterSelectedOptions
                        loading={options2.length < 1 ? true : false}
                        options={options2}
                        onChange={(event, newValue) => {
                          setOptionSelect2(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Prerequisite"
                            onChange={(event) => {
                              setFilter2(event.target.value);
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                  <Box width={"100%"} height={"2ch"} />
                  <FieldArray name="todos">
                    {({ remove, push }) => (
                      <div>
                        {values.todos.length > 0 &&
                          values.todos.map((todo, index) => (
                            <Grid container spacing={2}>
                              <Box
                                width={"100%"}
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                borderBottom={"solid silver 1px"}
                                // borderTop={
                                //     "solid silver 1px"
                                // }
                                padding={"0.5ch"}
                                marginTop={"4ch"}
                              >
                                <Typography fontWeight={"bold"}>
                                  {`Todo ${index + 1}`}
                                </Typography>
                                {values.todos.length > 1 && (
                                  <IconButton onClick={() => remove(index)}>
                                    <Cancel color={"error"} />
                                  </IconButton>
                                )}
                              </Box>

                              <Box width={"100%"} />

                              <Grid item xs={12}>
                                <TextFieldWrapper
                                  size={"small"}
                                  name={`todos.${index}.name`}
                                  label="Description"
                                  variant="outlined"
                                  rows={2}
                                  multiline
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <TextFieldWrapper
                                  size={"small"}
                                  name={`todos.${index}.quantity_type`}
                                  label="Unit"
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <TextFieldWrapper
                                  size={"small"}
                                  name={`todos.${index}.quantity`}
                                  label="Quantity"
                                  variant="outlined"
                                  type="number"
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <TextFieldWrapper
                                  size={"small"}
                                  name={`todos.${index}.total_area`}
                                  label="Total Area"
                                  variant="outlined"
                                  type="number"
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <TextFieldWrapper
                                  size={"small"}
                                  name={`todos.${index}.unit_price`}
                                  label="Unit price"
                                  variant="outlined"
                                  type="number"
                                />
                              </Grid>
                            </Grid>
                          ))}
                        <Button
                          startIcon={<Add />}
                          onClick={() =>
                            push({
                              name: "",
                              quantity: "",
                              total_area: "",
                              unit_price: "",
                              quantity_type: "",
                            })
                          }
                        >
                          Add To Do
                        </Button>
                      </div>
                    )}
                  </FieldArray>

                  <Box width={"100%"} />
                  <Grid item xs={6}>
                    <FormControl
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        checked={protectedEdit}
                        onChange={(event) => {
                          setProtectedEdit(event.target.checked);
                        }}
                      />
                      <FormLabel>Protected For Edit</FormLabel>
                    </FormControl>
                  </Grid>
                </Grid>
                <div
                  style={{
                    position: "relative",
                    bottom: "-12ch",
                    right: "60px",
                  }}
                >
                  <LoadingButton
                    loading={loading}
                    type="submit"
                    className="ButtonContained"
                    style={{
                      marginTop: "2ch",
                      float: "right",
                    }}
                  >
                    {loading ? null : "Add"}
                  </LoadingButton>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {error || success ? (
        <SnackBar
          error={error}
          success={success}
          message={error ? msg : `Success^${Math.random()}`}
        />
      ) : null}
    </div>
  );
};

export default AddSubTask;
