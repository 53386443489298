import {
  Box,
  TextField,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import AddRoadRoundedIcon from "@mui/icons-material/AddRoadRounded";

import TextFieldWrapper from "../../Components/TextFieldWrapper.component";
import "../../Assets/Sass/AddProject.style.scss";
import {
  Client,
  ProjectIDURL,
  ProjectURL,
  EmployeeURL,
} from "../../utils/config";
import { caller, get } from "../../utils/Caller";
import TitleHeader from "../../Components/TitleHeader/TitleHeader.component";
import { SnackBar } from "../../Components/SnackBar/SnackBar.component";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setSelected } from "../../Redux/Slice/selectedRow";

const AddProject = (props) => {
  const [loading, setLoading] = useState(false);
  const [protectedEdit, setProtectedEdit] = useState(false);
  const [filter, setFilter] = useState("");
  const [options, setOptions] = useState([]);
  const [optionsId, setOptionsID] = useState();
  const [optionSelect, setOptionSelect] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [msg, setMsg] = useState("");
  const [projectID, setProjectID] = useState("");
  const [optionSelect2, setOptionSelect2] = useState("");
  const [optionSelect3, setOptionSelect3] = useState("");
  const [optionSelect4, setOptionSelect4] = useState("");
  const [options2, setOptions2] = useState([]);
  const [filter2, setFilter2] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const INITIAL_VALUES = {
    isProtectedForEdit: false,
    project_description: "",
    name: "",
    project_manager_id: "",
    project_start_date: "",
    project_end_date: "",
    contract_number: "",
    site_engineer_id: "",
    dupty_manager_id: "",
    project_address: "",
    project_id: "",
    client_id: null,
  };

  const FORM_VALIDATION = Yup.object().shape({
    project_description: Yup.string().min(4),
    name: Yup.string().min(3).required("*Required"),
    // project_manager_id: Yup.string().min(4).required("*Required"),
    project_start_date: Yup.string().min(4).required("*Required"),
    project_end_date: Yup.string().min(4).required("*Required"),
    contract_number: Yup.string().min(2).required("*Required"),
    // site_engineer_id: Yup.string().min(3).required("*Required"),
    // dupty_manager_id: Yup.string().min(3).required("*Required"),
    project_address: Yup.string().min(2).required("*Required"),
  });

  const submitFormHandler = (data, actions) => {
    setLoading(true);
    caller(ProjectURL, data).then((res) => {
      setLoading(false);
      if (res.error) {
        Object.keys(res.error).forEach((data, i) => {
          setMsg(res.error[data]);
        });
        setSuccess(false);
        setError(true);
      } else if (res.data.success) {
        setSuccess(true);
        setError(false);
        actions.resetForm();
        dispatch(setSelected(res.data.project));
        navigate(`/View/ViewProject/ProjectAddDetail/AddProjectTask`);
      }
    });
  };

  useEffect(() => {
    get(Client, {
      limit: 10,
      filter: {
        tradeName: filter,
      },
    }).then((res) => {
      let data = [];
      res &&
        res?.data?.forEach((name, index) => {
          data?.push(res.data[index].tradeName);
        });
      res && setOptionsID(res.data);
      setOptions(data);
    });
    get(EmployeeURL, {
      limit: 10,
      filter: {
        all: filter2,
      },
    }).then((res) => {
      let data = [];
      res &&
        res.data.forEach((name, index) => {
          data.push(
            `${res.data[index]?.id} - ${res.data[index]?.first_name} ${res.data[index]?.middle_name}`,
          );
        });
      setOptions2(data);
    });
  }, [filter, filter2]);

  useEffect(() => {
    setLoading(true);
    get(ProjectIDURL, {
      limit: 1,
    }).then((res) => {
      res && setProjectID(res.data?.project_id);
      setLoading(false);
    });
  }, []);

  return (
    <div>
      <TitleHeader title="Add Project" icon={<AddRoadRoundedIcon />} />
      <div className="FormContainer">
        <Formik
          initialValues={{ ...INITIAL_VALUES }}
          validationSchema={FORM_VALIDATION}
          onSubmit={(values, actions) => {
            optionsId.forEach((name, index) => {
              if (name.tradeName === optionSelect) {
                values.client_id = name.id;
              }
            });
            values.project_id = projectID;
            values.isProtectedForEdit = protectedEdit;
            values.project_manager_id = parseInt(optionSelect2?.split("-")[0]);
            values.site_engineer_id = parseInt(optionSelect3?.split("-")[0]);
            values.dupty_manager_id = parseInt(optionSelect4?.split("-")[0]);

            submitFormHandler(values, actions);
          }}
        >
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextFieldWrapper
                  value={projectID}
                  name="project_id"
                  label="Project ID"
                  variant="outlined"
                  onChange={(e) => {
                    setProjectID(e.target.value);
                  }}
                  // type="number"
                  disabled
                />
              </Grid>
              {loading && <CircularProgress />}
              <Box width="100%" />
              <Grid item xs={12} md={4}>
                <TextFieldWrapper
                  name="name"
                  label="Project Name"
                  variant="outlined"
                />
              </Grid>
              {/* <Grid item xs={12} md={4}>
                                <TextFieldWrapper
                                    name="project_manager_id"
                                    label="Project Manager"
                                    variant="outlined"
                                />
                            </Grid> */}
              <Grid item xs={4}>
                <Autocomplete
                  loading={options2.length < 1 ? true : false}
                  disablePortal
                  options={options2}
                  onChange={(event, newValue) => {
                    setOptionSelect2(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Project Manager"
                      onChange={(event) => {
                        setFilter2(event.target.value);
                      }}
                    />
                  )}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel>Project Start Date</FormLabel>
                  <TextFieldWrapper
                    name="project_start_date"
                    variant="outlined"
                    type="date"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel>Project End Date</FormLabel>
                  <TextFieldWrapper
                    name="project_end_date"
                    variant="outlined"
                    type="date"
                  />
                </FormControl>
              </Grid>
              <Box width="100%" />
              <Grid item xs={12} md={4}>
                <TextFieldWrapper
                  name="contract_number"
                  label="Contract  Number"
                  variant="outlined"
                />
              </Grid>
              {/* <Grid item xs={12} md={4}>
                                <TextFieldWrapper
                                    name="site_engineer_id"
                                    label="Site Engineer"
                                    variant="outlined"
                                />
                            </Grid> */}
              <Grid item xs={4}>
                <Autocomplete
                  loading={options2.length < 1 ? true : false}
                  disablePortal
                  options={options2}
                  onChange={(event, newValue) => {
                    setOptionSelect3(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Site Engineer"
                      onChange={(event) => {
                        setFilter2(event.target.value);
                      }}
                    />
                  )}
                />
              </Grid>
              <Box width="100%" />
              {/* <Grid item xs={12} md={4}>
                                <TextFieldWrapper
                                    name="dupty_manager_id"
                                    label="Dupty Manager"
                                    variant="outlined"
                                />
                            </Grid> */}
              <Grid item xs={4}>
                <Autocomplete
                  loading={options2.length < 1 ? true : false}
                  disablePortal
                  options={options2}
                  onChange={(event, newValue) => {
                    setOptionSelect4(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Dupty Manager"
                      onChange={(event) => {
                        setFilter2(event.target.value);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldWrapper
                  name="project_address"
                  label="Project Address"
                  variant="outlined"
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={12} md={4}>
                <Autocomplete
                  name="client_trade_name"
                  loading={options.length < 1 ? true : false}
                  disablePortal
                  options={options}
                  onChange={(event, newValue) => {
                    setOptionSelect(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Client Trade Name"
                      onChange={(event) => {
                        setFilter(event.target.value);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldWrapper
                  name="project_description"
                  label="Description"
                  variant="outlined"
                  multiline
                  rows={5}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    checked={protectedEdit}
                    onChange={(event) => {
                      setProtectedEdit(event.target.checked);
                    }}
                  />
                  <FormLabel>Protected For Edit</FormLabel>
                </FormControl>
              </Grid>
            </Grid>
            <div>
              <LoadingButton
                data-testid="AddButton"
                loading={loading}
                type="submit"
                className="ButtonContained"
                style={{
                  marginTop: "2ch",
                  float: "right",
                }}
              >
                {loading ? null : "Add"}
              </LoadingButton>
            </div>
          </Form>
        </Formik>
        {error || success ? (
          <SnackBar
            error={error}
            success={success}
            message={error ? msg : `Success^${Math.random()}`}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AddProject;
