import { Add, SearchRounded } from "@mui/icons-material";
import {
    Button,
    InputAdornment,
    TextField,
    IconButton,
    Typography,
    Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ListTable from "../../../../Components/List/ListTable.component";

// import Table from "../../../../Components/Table/Table.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { get } from "../../../../utils/Caller";
import { PettyCashURL } from "../../../../utils/config";
import { deletePettyCash } from "./deletePettyCash";

const ViewPettyCash = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(20);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState();
    const [Search, setSearch] = useState();

    const columns = [
        {
            field: "amount_paid",
            headerName: "Amount Paid",
            width: 150,
        },
        {
            field: "date",
            headerName: "Date",
            width: 150,
        },
        {
            field: "money_received",
            headerName: "Money Received",
            width: 150,
        },
        {
            field: "paidTo",
            headerName: "Paid To",
            width: 150,
        },
        {
            field: "paid_to_id_file",
            headerName: "Paid To Id File",
            width: 150,
        },
        {
            field: "paid_to_name",
            headerName: "Paid To Name",
            width: 150,
        },
        {
            field: "pcpv_number",
            headerName: "PCPV Number",
            width: 150,
        },
        {
            field: "preparedBy",
            headerName: "Prepared By",
            width: 150,
        },
        {
            field: "reason",
            headerName: "Reason",
            width: 150,
        },
        {
            field: "remark",
            headerName: "Remark",
            width: 150,
        },
    ];

    useEffect(() => {
        let tableData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(PettyCashURL, {
            limit: limit,
            filter: { all: filter },
        }).then((res) => {
            // console.log(res);
            res && res.data && setloading(false);

            res?.data?.forEach((data, index) => {
                tableData.push({
                    ...data,
                    paidTo: data.paid_to?.first_name,
                    preparedBy: data.prepared_by?.first_name,
                    date: new Date(data.date).toDateString(),
                });
            });
            setRows(tableData);
        });
    }, [refreshTable, filter, limit, rows.length]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    const Body = () => {
        return (
            <div>
                {columns.map((column, index) => {
                    return (
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                alignItems: "center",
                                marginTop: "1ch",
                            }}
                        >
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    width: "80%",
                                }}
                            >
                                <Typography fontWeight={"bolder"}>
                                    {column.headerName}
                                </Typography>
                                <Typography>
                                    {selectedRow[column.field]}
                                </Typography>
                            </div>
                            <Divider
                                style={{
                                    width: "90%",
                                }}
                            />
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setloading(true);
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <div style={{ display: "flex", gap: "2ch" }}>
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<Add />}
                        onClick={() => {
                            navigate("/View/Finance/PettyCash/AddPettyCash");
                        }}
                    >
                        Add Petty Cash
                    </Button>
                </div>
            </div>

            <ListTable
                addLimit={addLimit}
                rows={rows}
                loading={loading}
                title="pcpv_number"
                subtitle="preparedBy"
                description="reason"
                deleteFunc={deletePettyCash}
                body={<Body />}
            />

            {/* <div className="TableContainer">
                <Table
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                />
            </div> */}
        </div>
    );
};

export default ViewPettyCash;
