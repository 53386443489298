import {
    Autocomplete,
    Avatar,
    Box,
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    TextField,
    Typography,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddRounded, PersonAdd } from "@mui/icons-material";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { fileCaller, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    // EmployeeURL,
    NationalityURL,
    CountryURL,
    LanguageURL,
    TitleURL,
    EmployeeTypeURL,
    JobTitleURL,
    LocationURL,
    BusinessUnitURL,
    EmployeeGroupURL,
    InitialHiringURL,
    CommitmentURL,
    RoleURL,
} from "../../../../utils/config";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setSelected } from "../../../../Redux/Slice/selectedRow";

const AddEmployee = () => {
    const uploadRef = useRef();
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState("");
    const [filter2, setFilter2] = useState("");
    const [filter3, setFilter3] = useState("");
    const [filter4, setFilter4] = useState("");
    // const [filter5, setFilter5] = useState("");
    const [filter6, setFilter6] = useState("");
    const [filter7, setFilter7] = useState("");
    const [filter8, setFilter8] = useState("");
    const [filter9, setFilter9] = useState("");
    const [filter10, setFilter10] = useState("");
    const [filter11, setFilter11] = useState("");
    const [filter12, setFilter12] = useState("");
    const [options, setOptions] = useState([]);
    const [optionsId, setOptionsID] = useState();
    const [optionSelect, setOptionSelect] = useState("");
    const [options2, setOptions2] = useState([]);
    const [optionsId2, setOptionsID2] = useState();
    const [optionSelect2, setOptionSelect2] = useState("");
    const [options3, setOptions3] = useState([]);
    const [optionsId3, setOptionsID3] = useState();
    const [optionSelect3, setOptionSelect3] = useState("");
    const [options4, setOptions4] = useState([]);
    const [optionsId4, setOptionsID4] = useState();
    const [optionSelect4, setOptionSelect4] = useState("");
    // const [options5, setOptions5] = useState([]);
    // const [optionsId5, setOptionsID5] = useState();
    // const [optionSelect5, setOptionSelect5] = useState("");
    const [options6, setOptions6] = useState([]);
    const [optionsId6, setOptionsID6] = useState();
    const [optionSelect6, setOptionSelect6] = useState("");
    const [options7, setOptions7] = useState([]);
    const [optionsId7, setOptionsID7] = useState();
    const [optionSelect7, setOptionSelect7] = useState("");
    const [options8, setOptions8] = useState([]);
    const [optionsId8, setOptionsID8] = useState();
    const [optionSelect8, setOptionSelect8] = useState("");
    const [options9, setOptions9] = useState([]);
    const [optionSelect9, setOptionSelect9] = useState("");
    const [options10, setOptions10] = useState([]);
    const [optionsId10, setOptionsID10] = useState();
    const [optionSelect10, setOptionSelect10] = useState("");
    const [options11, setOptions11] = useState([]);
    const [optionsId11, setOptionsID11] = useState();
    const [optionSelect11, setOptionSelect11] = useState("");
    const [options12, setOptions12] = useState([]);
    const [optionsId12, setOptionsID12] = useState();
    const [optionSelect12, setOptionSelect12] = useState("");
    // const [isEmployeeActive, setIsEmployeeActive] = useState(false);
    const [genderValue, setGenderValue] = useState(1);
    const [MaritalStatus, setMaritalStatus] = useState(1);
    const [AvatarImage, setAvatarImage] = useState(null);
    const [preview, setPreview] = useState(null);
    const [msg, setMsg] = useState("");
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let EmployeeData = null;
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    const GENDERS = ["Male", "Female"];
    const MARITALSTATUS = ["Single", "Married", "Widowed", "Divorced"];

    // const phoneRegex = /^(251-)?\d{9}$/;

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        // id_number: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        gender: "",
        date_of_birth: "",
        employment_start_date: "",
        date_of_joining: "",
        marital_status: "",
        marital_since: "",
        place_of_birth: "",
        file: "",
        is_employee_active: "",
        // type: "",
        prev_employment_leave_days: "",
        bank_account_number: "",
        document_ref: "",
        pension_ref: "",
        nationality_id: "",
        country_id: "",
        language_id: "",
        title_id: "",
        religion_id: "",
        employee_type_id: "",
        startDate: "",
        endDate: "",
        job_title_id: "",
        location_id: "",
        action_reason_id: "",
        business_unit_id: "",
        employee_group_id: "",
        // password: "",
        email: "",
        role_id: "",
        // phone_number: "",
        // confirmPassword: "",
        remark: "",
        tin_number: "",
    };

    const FORM_VALIDATION = Yup.object().shape({
        // id_number: Yup.number().min(1).required("*Required"),
        first_name: Yup.string().min(2).required("*Required"),
        middle_name: Yup.string().min(2).required("*Required"),
        last_name: Yup.string(),
        // gender: Yup.number().min(1).required("*Required"),
        date_of_birth: Yup.string(),
        employment_start_date: Yup.string(),
        date_of_joining: Yup.string(),
        // marital_status: Yup.number().min(1).required("*Required"),
        // marital_since: Yup.string().min(1),
        place_of_birth: Yup.string(),
        is_employee_active: Yup.boolean(),
        // type: Yup.string().min(1),
        prev_employment_leave_days: Yup.number(),
        bank_account_number: Yup.string(),
        document_ref: Yup.string(),
        pension_ref: Yup.string(),
        // password: Yup.string().min(8).required("*Required"),
        email: Yup.string()
            .email("Must be a valid email")
            .required("*Required"),
        // phone_number: Yup.string()
        //     .min(4)
        //     .matches(phoneRegex, "Enter a valid Phone number(Start with 251-)")
        //     .required("*Required"),
        // confirmPassword: Yup.string().oneOf(
        //     [Yup.ref("password"), null],
        //     "Passwords must match"
        // ),
    });

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        get(NationalityURL, {
            limit: 10,
            filter: {
                all: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((_name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].nationality_name}`,
                    );
                });
            res && setOptionsID(res.data);
            setOptions(data);
        });
        get(CountryURL, {
            limit: 10,
            filter: {
                all: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((_name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].country_name}`,
                    );
                });
            res && setOptionsID2(res.data);
            setOptions2(data);
        });
        get(LanguageURL, {
            limit: 10,
            filter: {
                all: filter3,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((_name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].name}`,
                    );
                });
            res && setOptionsID3(res.data);
            setOptions3(data);
        });
        get(TitleURL, {
            limit: 10,
            filter: {
                all: filter4,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((_name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].name}`,
                    );
                });
            res && setOptionsID4(res.data);
            setOptions4(data);
        });
        // get(ReligionURL, {
        //     limit: 10,
        //     filter: {
        //         name: filter5,
        //     },
        // }).then((res) => {
        //     let data = [];
        //     res &&
        //         res.data.forEach((name, index) => {
        //             data.push(
        //                 `${res.data[index]?.id} - ${res.data[index].name}`
        //             );
        //         });
        //     res && setOptionsID5(res.data);
        //     setOptions5(data);
        // });
        get(EmployeeTypeURL, {
            limit: 10,
            filter: {
                all: filter6,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((_name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].description}`,
                    );
                });
            res && setOptionsID6(res.data);
            setOptions6(data);
        });
        get(JobTitleURL, {
            limit: 10,
            filter: {
                all: filter7,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((_name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.title_name}`,
                    );
                });
            res && setOptionsID7(res.data);
            setOptions7(data);
        });
        get(LocationURL, {
            limit: 10,
            filter: {
                all: filter8,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((_name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.location_name}`,
                    );
                });
            res && setOptionsID8(res.data);
            setOptions8(data);
        });
        get(RoleURL, {
            limit: 10,
            filter: {
                name: filter9,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.name} - ${name.description}`,
                    );
                });
            setOptions9(data);
        });
        get(BusinessUnitURL, {
            limit: 10,
            filter: {
                all: filter10,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((_name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.name}`,
                    );
                });
            res && setOptionsID10(res.data);
            setOptions10(data);
        });
        get(EmployeeGroupURL, {
            limit: 10,
            filter: {
                all: filter11,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((_name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.group_description}`,
                    );
                });
            res && setOptionsID11(res.data);
            setOptions11(data);
        });
        get(CommitmentURL, {
            limit: 10,
            filter: {
                all: filter12,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((_name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index].type_name}`,
                    );
                });
            res && setOptionsID12(res.data);
            setOptions12(data);
        });
    }, [
        filter,
        filter2,
        filter3,
        filter4,
        filter6,
        filter7,
        filter8,
        filter9,
        filter10,
        filter11,
        filter12,
    ]);

    useEffect(() => {
        // create the preview
        if (AvatarImage) {
            const objectUrl = URL.createObjectURL(AvatarImage);
            setPreview(objectUrl);

            // free memory when ever this component is unmounted
            return () => URL.revokeObjectURL(objectUrl);
        }
    }, [AvatarImage]);

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        fileCaller(InitialHiringURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, _i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                let data = res.data?.employee;
                EmployeeData = {
                    ...data,
                    nationalityName: data?.nationality?.nationality_name,
                    countryName: data?.country?.country_name,
                    date_of_joining: new Date(
                        data?.date_of_joining,
                    ).toDateString(),
                    employment_start_date: new Date(
                        data?.employment_start_date,
                    ).toDateString(),
                    date_of_birth: new Date(data?.date_of_birth).toDateString(),
                    languageName: data?.language?.name,
                    titleName: data?.title?.name,
                    religionName: data?.religion?.name,
                    employeeType: data?.employee_type?.description,
                    jobTitle: data?.employee_action
                        ?.pop()
                        ?.org_assignment?.pop()?.job_title?.title_name,
                    employeeStatus:
                        data?.employee_action?.pop()?.employee_status,
                    locationName: data?.location?.location_name,
                    actionReason: data?.action_reason?.reason_description,
                    businessUnit: data?.business_unit?.name,
                    employeeGroup: data?.employee_group?.group_description,
                    genderValue: GENDERS[data.gender - 1],
                    maritalStatus: MARITALSTATUS[data.marital_status - 1],
                    startDate: new Date(data?.startDate).toDateString(),
                    endDate: new Date(data?.endDate).toDateString(),
                };
                console.log(EmployeeData);
                dispatch(setSelected(EmployeeData));
                handleOpen();
                actions.resetForm();
            }
        });
    };

    return (
        <div>
            <TitleHeader title="Add Employee" icon={<AddRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;

                        optionsId.forEach((name, _index) => {
                            if (
                                `${name?.id} - ${name.nationality_name}` ===
                                optionSelect
                            ) {
                                values.nationality_id = name.id;
                            }
                        });
                        optionsId2.forEach((name, _index) => {
                            if (
                                `${name?.id} - ${name.country_name}` ===
                                optionSelect2
                            ) {
                                values.country_id = name.id;
                            }
                        });
                        optionsId3.forEach((name, _index) => {
                            if (
                                `${name?.id} - ${name.name}` === optionSelect3
                            ) {
                                values.language_id = name.id;
                            }
                        });
                        optionsId4.forEach((name, _index) => {
                            if (
                                `${name?.id} - ${name.name}` === optionSelect4
                            ) {
                                values.title_id = name.id;
                            }
                        });
                        // optionsId5.forEach((name, index) => {
                        //     if (
                        //         `${name?.id} - ${name.name}` === optionSelect5
                        //     ) {
                        //         values.religion_id = name.id;
                        //     }
                        // });
                        optionsId6.forEach((name, _index) => {
                            if (
                                `${name?.id} - ${name.description}` ===
                                optionSelect6
                            ) {
                                values.employee_type_id = name.id;
                            }
                        });
                        optionsId7.forEach((name, _index) => {
                            if (
                                `${name?.id} - ${name.title_name}` ===
                                optionSelect7
                            ) {
                                values.job_title_id = name.id;
                            }
                        });
                        optionsId8.forEach((name, _index) => {
                            if (
                                `${name?.id} - ${name.location_name}` ===
                                optionSelect8
                            ) {
                                values.location_id = name.id;
                            }
                        });
                        values.role_id = parseInt(optionSelect9?.split("-")[0]);
                        optionsId10.forEach((name, _index) => {
                            if (
                                `${name?.id} - ${name.name}` === optionSelect10
                            ) {
                                values.business_unit_id = name.id;
                            }
                        });
                        optionsId11.forEach((name, _index) => {
                            if (
                                `${name?.id} - ${name.group_description}` ===
                                optionSelect11
                            ) {
                                values.employee_group_id = name.id;
                            }
                        });
                        optionsId12.forEach((name, _index) => {
                            if (
                                `${name?.id} - ${name.type_name}` ===
                                optionSelect12
                            ) {
                                values.commitment_type_id = name.id;
                            }
                        });

                        values.is_employee_active = 1;
                        values.gender = genderValue;
                        values.marital_status = MaritalStatus;
                        values.file = AvatarImage;

                        /* Then create a new FormData obj */
                        let formData = new FormData();

                        // /* FormData requires name: id */
                        // formData.append("website", "question");

                        /* append input field values to formData */
                        for (let value in values) {
                            if (value === "isProtectedForEdit") {
                                values[value] = false;
                            }
                            formData.append(value, values[value]);
                        }

                        /* Can't console.log(formData), must
                              use formData.entries() - example:  */
                        // for (let property of formData.entries()) {
                        //     console.log(property[0], typeof property[1]);
                        // }

                        formSubmitHandler(formData, actions);
                    }}
                >
                    <Form>
                        <Box
                            style={{
                                border: `solid 1px silver`,
                                padding: "2ch",
                                borderRadius: "5px",
                            }}
                        >
                            <div>
                                <Typography
                                    style={{
                                        fontWeight: "700",
                                        fontSize: "large",
                                        letterSpacing: "1px",
                                        paddingBottom: "1ch",
                                    }}
                                >
                                    Personal Detail
                                </Typography>
                                <Divider />
                            </div>
                            <Grid container spacing={2}>
                                <Grid item xs={12} className="imagePreview">
                                    {AvatarImage ? (
                                        <Avatar
                                            onClick={() => {
                                                uploadRef.current.click();
                                            }}
                                            src={preview}
                                            alt=""
                                            style={{
                                                height: "10ch",
                                                width: "10ch",
                                            }}
                                        />
                                    ) : (
                                        <PersonAdd
                                            onClick={() => {
                                                uploadRef.current.click();
                                            }}
                                            style={{
                                                color: "lightgray",
                                                height: "10ch",
                                                width: "10ch",
                                            }}
                                        />
                                    )}
                                    <input
                                        hidden
                                        ref={uploadRef}
                                        type="file"
                                        onChange={(e) => {
                                            setAvatarImage(e.target.files[0]);
                                        }}
                                    ></input>
                                </Grid>
                                {/* <Grid item xs={4}>
                                    <TextFieldWrapper
                                        name="id_number"
                                        label="ID Number"
                                        variant="outlined"
                                        type="number"
                                    />
                                </Grid> */}
                                <Box width={"100%"} />
                                <Grid item xs={4}>
                                    <TextFieldWrapper
                                        name="first_name"
                                        label="First Name"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextFieldWrapper
                                        name="middle_name"
                                        label="Middle Name"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextFieldWrapper
                                        name="last_name"
                                        label="Last Name"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextFieldWrapper
                                        select
                                        name="gender"
                                        label="Gender"
                                        variant="outlined"
                                        value={genderValue}
                                        options={GENDERS ?? " "}
                                        onChange={(e) => {
                                            setGenderValue(e.target.value);
                                        }}
                                    />
                                </Grid>
                                <Box width={"100%"} />
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <FormLabel>Date Of Birth</FormLabel>
                                        <TextFieldWrapper
                                            name="date_of_birth"
                                            variant="outlined"
                                            type="date"
                                        />
                                    </FormControl>
                                </Grid>
                                <Box width={"100%"} />
                                {/* <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <FormLabel>
                                            Employment Start Date
                                        </FormLabel>
                                        <TextFieldWrapper
                                            name="employment_start_date"
                                            variant="outlined"
                                            type="date"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <FormLabel>Date Of Joining</FormLabel>
                                        <TextFieldWrapper
                                            name="date_of_joining"
                                            variant="outlined"
                                            type="date"
                                        />
                                    </FormControl>
                                </Grid> */}
                                <Box width={"100%"} />
                                <Grid item xs={4}>
                                    <TextFieldWrapper
                                        select
                                        name="marital_status"
                                        label="Marital Status"
                                        variant="outlined"
                                        value={MaritalStatus ?? " "}
                                        options={MARITALSTATUS}
                                        onChange={(e) => {
                                            setMaritalStatus(e.target.value);
                                        }}
                                    />
                                </Grid>
                                {MaritalStatus !== 1 && (
                                    <>
                                        <Box width={"100%"} />
                                        <Grid item xs={4}>
                                            <FormControl fullWidth>
                                                <FormLabel>
                                                    Married Since
                                                </FormLabel>
                                                <TextFieldWrapper
                                                    name="marital_since"
                                                    variant="outlined"
                                                    type="date"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </>
                                )}
                                <Box width={"100%"} />
                                <Grid item xs={4}>
                                    <TextFieldWrapper
                                        name="place_of_birth"
                                        label="Place of Birth"
                                        variant="outlined"
                                    />
                                </Grid>
                                {/* <Grid item xs={6}>
                                    <FormControl
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <FormLabel>
                                            Is Employee Active
                                        </FormLabel>
                                        <Checkbox
                                            name="is_employee_active"
                                            checked={isEmployeeActive}
                                            onChange={(event) => {
                                                setIsEmployeeActive(
                                                    event.target.checked
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </Grid> */}
                                {/* <Grid item xs={4}>
                                    <TextFieldWrapper
                                        name="type"
                                        label="Type"
                                        variant="outlined"
                                    />
                                </Grid> */}
                                {/* <Grid item xs={4}>
                                    <TextFieldWrapper
                                        name="prev_employment_leave_days"
                                        label="Prev Employment Leave Days"
                                        variant="outlined"
                                        type="number"
                                    />
                                </Grid> */}
                                <Grid item xs={4}>
                                    <TextFieldWrapper
                                        name="bank_account_number"
                                        label="Bank Account Number"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextFieldWrapper
                                        name="tin_number"
                                        label="Tin Number"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextFieldWrapper
                                        name="document_ref"
                                        label="Document Ref"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextFieldWrapper
                                        name="pension_ref"
                                        label="Pension Ref"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Box width={"100%"} />
                                <Grid item xs={4}>
                                    <Autocomplete
                                        loading={
                                            options.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options}
                                        onChange={(_event, newValue) => {
                                            setOptionSelect(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Nationality"
                                                onChange={(event) => {
                                                    setFilter(
                                                        event.target.value,
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        loading={
                                            options2.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options2}
                                        onChange={(_event, newValue) => {
                                            setOptionSelect2(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Country"
                                                onChange={(event) => {
                                                    setFilter2(
                                                        event.target.value,
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        loading={
                                            options3.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options3}
                                        onChange={(_event, newValue) => {
                                            setOptionSelect3(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Language"
                                                onChange={(event) => {
                                                    setFilter3(
                                                        event.target.value,
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        loading={
                                            options4.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options4}
                                        onChange={(_event, newValue) => {
                                            setOptionSelect4(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Title"
                                                onChange={(event) => {
                                                    setFilter4(
                                                        event.target.value,
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                {/* <Grid item xs={4}>
                                    <Autocomplete
                                        loading={
                                            options5.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options5}
                                        onChange={(event, newValue) => {
                                            setOptionSelect5(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Religion"
                                                onChange={(event) => {
                                                    setFilter5(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid> */}
                            </Grid>
                        </Box>
                        <Box
                            style={{
                                border: `solid 1px silver`,
                                padding: "2ch",
                                borderRadius: "5px",
                            }}
                        >
                            <div style={{ marginBottom: "2ch" }}>
                                <Typography
                                    style={{
                                        fontWeight: "700",
                                        fontSize: "large",
                                        letterSpacing: "1px",
                                        paddingBottom: "1ch",
                                    }}
                                >
                                    Hiring Detail
                                </Typography>
                                <Divider />
                            </div>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <FormLabel>Start Date</FormLabel>
                                        <TextFieldWrapper
                                            name="startDate"
                                            variant="outlined"
                                            type="date"
                                        />
                                    </FormControl>
                                </Grid>
                                {/* <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <FormLabel>End Date</FormLabel>
                                        <TextFieldWrapper
                                            name="endDate"
                                            variant="outlined"
                                            type="date"
                                        />
                                    </FormControl>
                                </Grid> */}
                                <Box width="100%" />
                                <Grid item xs={4}>
                                    <Autocomplete
                                        loading={
                                            options6.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options6}
                                        onChange={(_event, newValue) => {
                                            setOptionSelect6(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Employee Type"
                                                onChange={(event) => {
                                                    setFilter6(
                                                        event.target.value,
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        loading={
                                            options7.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options7}
                                        onChange={(_event, newValue) => {
                                            setOptionSelect7(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Job Title"
                                                onChange={(event) => {
                                                    setFilter7(
                                                        event.target.value,
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        loading={
                                            options8.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options8}
                                        onChange={(_event, newValue) => {
                                            setOptionSelect8(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Location"
                                                onChange={(event) => {
                                                    setFilter8(
                                                        event.target.value,
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                {/* <Grid item xs={4}>
                                    <Autocomplete
                                        loading={
                                            options9.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options9}
                                        onChange={(_event, newValue) => {
                                            setOptionSelect9(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Action Reason"
                                                onChange={(event) => {
                                                    setFilter9(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid> */}
                                <Grid item xs={4}>
                                    <Autocomplete
                                        loading={
                                            options10.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options10}
                                        onChange={(_event, newValue) => {
                                            setOptionSelect10(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Business Unit"
                                                onChange={(event) => {
                                                    setFilter10(
                                                        event.target.value,
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        loading={
                                            options11.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options11}
                                        onChange={(_event, newValue) => {
                                            setOptionSelect11(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Employee Group"
                                                onChange={(event) => {
                                                    setFilter11(
                                                        event.target.value,
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box
                            style={{
                                border: `solid 1px silver`,
                                padding: "2ch",
                                borderRadius: "5px",
                            }}
                        >
                            <div style={{ marginBottom: "2ch" }}>
                                <Typography
                                    style={{
                                        fontWeight: "700",
                                        fontSize: "large",
                                        letterSpacing: "1px",
                                        paddingBottom: "1ch",
                                    }}
                                >
                                    Commitment Period
                                </Typography>
                                <Divider />
                            </div>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        loading={
                                            options12.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options12}
                                        onChange={(_event, newValue) => {
                                            setOptionSelect12(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Commitment Type"
                                                onChange={(event) => {
                                                    setFilter12(
                                                        event.target.value,
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextFieldWrapper
                                        name="remark"
                                        label="Remark"
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box
                            style={{
                                border: `solid 1px silver`,
                                padding: "2ch",
                                borderRadius: "5px",
                            }}
                        >
                            <div style={{ marginBottom: "2ch" }}>
                                <Typography
                                    style={{
                                        fontWeight: "700",
                                        fontSize: "large",
                                        letterSpacing: "1px",
                                        paddingBottom: "1ch",
                                    }}
                                >
                                    User Account
                                </Typography>
                                <Divider />
                            </div>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextFieldWrapper
                                        name="email"
                                        label="Email"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        loading={
                                            options9.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options9}
                                        onChange={(event, newValue) => {
                                            setOptionSelect9(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Role"
                                                onChange={(event) => {
                                                    setFilter9(
                                                        event.target.value,
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                {/* <Grid item xs={6}>
                                    <TextFieldWrapper
                                        name="phone_number"
                                        label="Phone Number"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Box width={"100%"} />
                                <Grid item xs={6}>
                                    <TextFieldWrapper
                                        name="password"
                                        label="Password"
                                        variant="outlined"
                                        type="password"
                                        autoComplete="off"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextFieldWrapper
                                        name="confirmPassword"
                                        label="Confirm Password"
                                        variant="outlined"
                                        type="password"
                                        autoComplete="off"
                                    />
                                </Grid> */}
                            </Grid>
                        </Box>
                        <Grid item xs={6}>
                            <FormControl
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <Checkbox
                                    checked={protectedEdit}
                                    onChange={(event) => {
                                        setProtectedEdit(event.target.checked);
                                    }}
                                />
                                <FormLabel>Protected For Edit</FormLabel>
                            </FormControl>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Do You want to add more employee information?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Employee added successfully, you can add more
                        information about the employee now.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Later</Button>
                    <Button
                        onClick={() => {
                            navigate(`/View/HCM/Employee/${selectedRow?.id}/`);
                        }}
                        autoFocus
                    >
                        Add more info
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default AddEmployee;
