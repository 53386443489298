import React from "react";
import { Divider, Typography } from "@mui/material";

const NotFound = () => {
    return (
        <div className="NotFound">
            <Typography fontFamily={"monospace"} variant="h4" color={"error"}>
                404
            </Typography>
            <Divider style={{ width: "70%" }} />
            <Typography fontFamily={"monospace"} variant="h3">
                Not Found
            </Typography>
        </div>
    );
};

export default NotFound;
