import { SearchRounded } from "@mui/icons-material";
import {
    Button,
    IconButton,
    InputAdornment,
    Modal,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { ProjectParticipationURL, ProjectURL } from "../../utils/config";
import { caller, get } from "../../utils/Caller";
import { setSelected } from "../../Redux/Slice/selectedRow";
import { setRefresh } from "../../Redux/Slice/refreshSlice";
import Table from "../../Components/Table/Table.component";
import { SnackBar } from "../../Components/SnackBar/SnackBar.component";

const ViewProject = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(16);
    const [filter, setFilter] = useState("");
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const user = useSelector((state) => state.userState.user);
    const dispatch = useDispatch();
    const [Search, setSearch] = useState("");
    const [projectParticipationModal, setProjectParticipationModal] =
        useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setMsg] = useState("");
    const [remark, setRemark] = useState("");
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    const columns = [
        {
            field: "project_id",
            headerName: "Project ID",
            width: 150,
        },
        {
            field: "name",
            headerName: "Project Name",
            width: 150,
        },
        // {
        //     field: "projectManager",
        //     headerName: "Project Manager",
        //     width: 150,
        // },
        {
            field: "project_start_date",
            headerName: "Project Start Date",
            width: 150,
        },
        {
            field: "project_end_date",
            headerName: "Project End Date",
            width: 150,
        },
        // {
        //     field: "project_address",
        //     headerName: "Project Address",
        //     width: 150,
        // },
        // {
        //     field: "siteEngineer",
        //     headerName: "Site Engineer",
        //     width: 150,
        // },
        // {
        //     field: "duptyManager",
        //     headerName: "Dupty Manager",
        //     width: 150,
        // },
        {
            field: "clientName",
            headerName: "Client Tradename",
            width: 150,
        },
    ];

    useEffect(() => {
        let tableData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(ProjectURL, {
            limit: limit,
            filter: { all: filter },
        }).then((res) => {
            res && res.data && setloading(false);
            res &&
                res.data?.forEach((data, index) => {
                    tableData.push({
                        ...data,
                        clientName: data.client?.tradeName,
                        duptyManager: data.dupty_manager?.first_name,
                        siteEngineer: data.site_engineer?.first_name,
                        projectManager: data.project_manager?.first_name,
                    });
                });
            setRows(tableData);
        });
    }, [filter, refreshTable, limit, rows.length]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    const viewDetail = (selected) => {
        if (Array.isArray(selected.has_access)) {
            const hasAccess = selected.has_access.find((id) => id === user.id);
            if (Boolean(hasAccess)) {
                dispatch(setSelected(selected));
                navigate(`/View/ViewProject/${selected.id}/DailyWorkLog`);
            } else {
                dispatch(setSelected(selected));
                setProjectParticipationModal(true);
            }
        } else {
            dispatch(setSelected(selected));
            navigate(`/View/ViewProject/${selected.id}/DailyWorkLog`);
        }
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
            </div>
            <div className="TableContainer">
                <Table
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={
                        user.actions?.find((role) => {
                            if (
                                role === "PROJECT_ONE" ||
                                role === "admin" ||
                                role === "super"
                            ) {
                                return true;
                            } else return false;
                        })
                            ? true
                            : false
                    }
                    addLimit={addLimit}
                    viewDetail={viewDetail}
                />
                <Modal
                    className="Modal"
                    open={projectParticipationModal}
                    onClose={() => {
                        setProjectParticipationModal(false);
                    }}
                >
                    <Paper
                        className="ModalContainer"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            padding: "2ch",
                            width: "60%",
                            gap: "2ch",
                        }}
                    >
                        <Typography>Request Project Participation</Typography>
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            name="remark"
                            label="Remark"
                            value={remark}
                            onChange={(e) => {
                                setRemark(e.target.value);
                            }}
                        />
                        <Button
                            variant="contained"
                            onClick={() => {
                                caller(ProjectParticipationURL, { 
                                    project_id: selectedRow?.id,
                                    remark,
                                }).then((res) => {
                                    if (res.error) {
                                        Object.keys(res.error).forEach(
                                            (data, i) => {
                                                setMsg(res.error[data]);
                                            },
                                        );
                                        setSuccess(false);
                                        setError(true);
                                    } else if (res.data.success) {
                                        setSuccess(true);
                                        setError(false);
                                        setProjectParticipationModal(false);
                                    }
                                });
                            }}
                        >
                            Request
                        </Button>
                    </Paper>
                </Modal>
                {error || success ? (
                    <SnackBar
                        error={error}
                        success={success}
                        message={error ? msg : `Success^${Math.random()}`}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default ViewProject;
