import {
    CloseRounded,
    DeleteRounded,
    EditRounded,
    SearchRounded,
} from "@mui/icons-material";
import {
    Box,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { WorkLogURL } from "../../../../utils/config";

import { get } from "../../../../utils/Caller";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import AlertDialog from "../../../../Components/DeleteAlert/DeleteAlert.component";
import EditWorkLog from "./EditWorkLog.page";
import { useDispatch } from "react-redux";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";

const ViewWorkLog = (props) => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(16);
    const [refreshTable, setRefreshTable] = useState(false);
    const [filter, setFilter] = useState();
    const [pageNo, setPageNo] = useState(0);
    const [editPage, setEditPage] = useState(false);
    const [deletePage, setDeletePage] = useState(false);
    const [selectedValue, setSelectedValue] = useState();
    const dispatch = useDispatch();
    const [Search, setSearch] = useState("");

    const editButtonHandler = (event, value) => {
        setEditPage(true);
        setSelectedValue(value);
    };

    const deleteButtonHandler = (event, value) => {
        setDeletePage(true);
        setSelectedValue(value);
    };

    const columns = [
        {
            field: "skilled_worker",
            headerName: "Skilled Worker",
            width: 120,
        },
        {
            field: "hours",
            headerName: "Hours",
            width: 100,
        },
        {
            field: "date",
            headerName: "Date",
            width: 150,
        },
        {
            field: "workCategory",
            headerName: "Work Category",
            width: 100,
        },
        {
            field: "dailyWorkLog",
            headerName: "Daily Work Log",
            width: 100,
        },
        {
            field: "isProtectedForEdit",
            headerName: "Actions",
            type: "actions",
            width: 100,
            getActions: (cellValues) => [
                <GridActionsCellItem
                    icon={<EditRounded color="primary" />}
                    label="Edit"
                    onClick={(event) => {
                        // event.stopPropagation();
                        editButtonHandler(event, cellValues);
                    }}
                />,
                <GridActionsCellItem
                    icon={<DeleteRounded color="error" />}
                    label="Delete"
                    onClick={(event) => {
                        // event.stopPropagation();
                        deleteButtonHandler(event, cellValues);
                    }}
                />,
            ],
        },
    ];
    useEffect(() => {
        let requestData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(WorkLogURL, {
            limit: limit,
            filter: {
                all: filter,
                daily_work_log_id: props.workLog?.id,
            },
        }).then((res) => {
            // console.log(res)
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    requestData.push({
                        ...data,
                        workCategory: data.work_category?.name,
                        dailyWorkLog: data.daily_work_log?.date,
                    });
                });
            setRows(requestData);
        });
    }, [refreshTable, limit, filter, rows.length, props.workLog]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <Paper
            style={{
                alignSelf: "center",
                width: "70%",
                padding: "5ch",
                maxHeight: "80vh",
                minWidth: "100%",
                overflowY: "scroll",
            }}
        >
            <IconButton
                color={"error"}
                style={{ float: "right" }}
                onClick={() => {
                    props.close();
                }}
            >
                <CloseRounded />
            </IconButton>
            {!editPage ? (
                <>
                    <div className="SearchField">
                        <TextField
                            name="searchField"
                            label="Search By Date"
                            variant="standard"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton
                                            onClick={(e) => {
                                                setFilter(Search);
                                                dispatch(
                                                    setRefresh(!refreshTable)
                                                );
                                            }}
                                        >
                                            <SearchRounded />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                        />
                    </div>
                    <Box height={"2ch"} />
                    <div style={{ height: 530, width: "100%" }}>
                        <DataGrid
                            loading={loading}
                            rows={rows}
                            columns={columns}
                            pageSize={8}
                            rowsPerPageOptions={[8]}
                            onPageChange={(page) => {
                                if (pageNo < page) {
                                    setPageNo(pageNo + 1);
                                    addLimit();
                                }
                            }}
                        />
                    </div>
                </>
            ) : (
                <EditWorkLog
                    values={selectedValue}
                    close={() => {
                        setEditPage(false);
                        setRefreshTable(!refreshTable);
                    }}
                />
            )}

            {deletePage && (
                <AlertDialog
                    id={selectedValue.id}
                    open={deletePage}
                    close={() => {
                        setDeletePage(false);
                        setRefreshTable(!refreshTable);
                    }}
                    workLog={true}
                />
            )}
        </Paper>
    );
};

export default ViewWorkLog;
