import { Add, SearchRounded } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import Table from "../../../../Components/Table/Table.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { get } from "../../../../utils/Caller";
import { RecurringGeneralJournalURL } from "../../../../utils/config";

const RECURRING_GENERAL_JOURNAL_STATUS = ["Active", "Stopped", "Expired"];

const ViewRecurringGeneralJournal = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(20);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState();
    const [Search, setSearch] = useState();

    const columns = [
        {
            field: "profile_name",
            headerName: "Profile Name",
            width: 150,
        },
        {
            field: "start_on",
            headerName: "Start On",
            width: 150,
        },
        {
            field: "end_on",
            headerName: "End On",
            width: 150,
        },
        {
            field: "never_expires",
            headerName: "Never Expires",
            width: 150,
        },
        {
            field: "depreciable_value",
            headerName: "Depreciable Value",
            width: 150,
        },
        {
            field: "recurringGeneralJournalStatus",
            headerName: "Recurring General Journal Status",
            width: 150,
        },
        {
            field: "assetName",
            headerName: "Asset",
            width: 150,
        },
        {
            field: "recurringJournalOccurrence",
            headerName: "Recurring Journal Occurrence",
            width: 150,
        },
    ];

    useEffect(() => {
        let tableData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(RecurringGeneralJournalURL, {
            limit: limit,
            filter: { all: filter },
        }).then((res) => {
            // console.log(res);
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    tableData.push({
                        ...data,
                        assetName: data.asset?.name,
                        recurringJournalOccurrence:
                            data.recurring_journal_occurrence?.name,
                        recurringGeneralJournalStatus:
                            RECURRING_GENERAL_JOURNAL_STATUS[
                                data.recurring_general_journal_status - 1
                            ],
                    });
                });
            setRows(tableData);
        });
    }, [refreshTable, filter, limit, rows.length]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setloading(true);
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <div style={{ display: "flex", gap: "2ch" }}>
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<Add />}
                        onClick={() => {
                            navigate(
                                "/View/Finance/RecurringGeneralJournal/AddRecurringGeneralJournal"
                            );
                        }}
                    >
                        Add Recurring General Journal
                    </Button>
                </div>
            </div>
            <div className="TableContainer">
                <Table
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                />
            </div>
        </div>
    );
};

export default ViewRecurringGeneralJournal;
