import { IconButton, Paper } from "@mui/material";
import React from "react";
import { useLocation } from "react-router";
import EditProject from "../../Pages/Projects/EditProject.page";
import EditEquipment from "../../Pages/MasterData/Equipment/EditEquipment.page";
import EditEvaluation from "../../Pages/MasterData/Evaluation/EditEvaluation.page";
import EditMaterial from "../../Pages/MasterData/Material/EditMaterial.page";
import EditMaterialCategory from "../../Pages/MasterData/MaterialCategory/EditMaterialCategory.page";
import EditInstruction from "../../Pages/MasterData/Instruction/EditInstruction.page";
import EditManpower from "../../Pages/MasterData/Manpower/EditManpower.page";
import EditPhase from "../../Pages/MasterData/Phase/EditPhase.page";
import EditWorkCategory from "../../Pages/MasterData/WorkCategory/EditWorkCategory.page";
import EditPriority from "../../Pages/MasterData/Priority/EditPriority.page";
import EditDailyWorkLog from "../../Pages/Projects/Operational/DailyWorkLog/EditDailyWorkLog.page";
import { useSelector } from "react-redux";
import { CloseRounded } from "@mui/icons-material";
import EditInstructionGiven from "../../Pages/Projects/Operational/InstructionGivenOnProject/EditInstructionGiven.page";
import EditInvoiceTracking from "../../Pages/Projects/Operational/InvoiceTracking/EditInvoiceTracking.page";
import EditManpowerRequirement from "../../Pages/Projects/Operational/ManpowerRequirement/EditManpowerRequirement.page";
import EditRiskTracking from "../../Pages/Projects/Operational/RiskTracking/EditRiskTracking.page";
import EditRequiredMaterial from "../../Pages/Projects/Operational/RequiredMaterial/EditRequiredMaterial.page";
import EditRequiredEquipment from "../../Pages/Projects/Operational/RequiredEquipment/EditRequiredEquipment.page";
import EditRequest from "../../Pages/Projects/Operational/Request/EditRequest.page";
import EditSimpleTask from "../../Pages/Projects/Operational/SimpleTask/EditSimpleTask.page";
import EditToDos from "../../Pages/Projects/Operational/ToDos/EditToDos.page";
import EditWeatherData from "../../Pages/Projects/Operational/WeatherData/EditWeatherData.page";
import EditRequestPayment from "../../Pages/Projects/Operational/RequestPayment/EditRequestPayment.page";
import EditTaskManager from "../../Pages/Projects/Operational/TaskManager/EditTaskManager.page";
import EditRequiredDocumentation from "../../Pages/Projects/Operational/RequiredDocumentation/EditRequiredDocumentation.page";
import EditDocumentation from "../../Pages/MasterData/Documentation/EditDocumentation.page";
import EditDocumentCategory from "../../Pages/MasterData/DocumentCategory/EditDocumentCategory.page";
import EditActionReason from "../../Pages/HCM/MasterData/ActionReason/EditActionReason.page";
import EditActionType from "../../Pages/HCM/MasterData/ActionType/EditActionType.page";
import EditAddressType from "../../Pages/HCM/MasterData/AddressType/EditAddressType.page";
import EditCountry from "../../Pages/HCM/MasterData/Country/EditCountry.page";
import EditCurrency from "../../Pages/HCM/MasterData/Currency/EditCurrency.page";
import EditCustomReport from "../../Pages/HCM/MasterData/CustomReport/EditCustomReport.page";
import EditTrainingType from "../../Pages/HCM/MasterData/TrainingType/EditTrainingType.page";
import EditTitle from "../../Pages/HCM/MasterData/Title/EditTitle.page";
import EditReligion from "../../Pages/HCM/MasterData/Religion/EditReligion.page";
import EditNationality from "../../Pages/HCM/MasterData/Nationality/EditNationality.page";
import EditLicenseType from "../../Pages/HCM/MasterData/LicenseType/EditLicenseType.page";
import EditLanguage from "../../Pages/HCM/MasterData/Language/EditLanguage.page";
import EditHCMConfiguration from "../../Pages/HCM/MasterData/HCMConfiguration/EditHCMConfiguration.page";
import EditFieldOfStudy from "../../Pages/HCM/MasterData/FieldOfStudy/EditFieldOfStudy.page";
import EditEmployeeType from "../../Pages/HCM/MasterData/EmployeeType/EditEmployeeType.page";
import EditEmployeeTransactionLock from "../../Pages/HCM/MasterData/EmployeeTransactionLock/EditEmployeeTransactionLock.page";
import EditDisciplineCaseType from "../../Pages/HCM/MasterData/DisciplineCaseType/EditDisciplineCaseType.page";
import EditDurationMeasure from "../../Pages/HCM/MasterData/DurationMeasure/EditDurationMeasure.page";
import EditEmployeeGroup from "../../Pages/HCM/MasterData/EmployeeGroup/EditEmployeeGroup.page";
import EditCommitment from "../../Pages/HCM/MasterData/Commitment/EditCommitment.page";
import EditJobCategory from "../../Pages/HCM/JobPositions/JobCategory/EditJobCategory.page";
import EditJobSafetyEquipment from "../../Pages/HCM/JobPositions/JobSafetyEquipment/EditJobSafetyEquipment.page";
import EditSafetyEquipment from "../../Pages/HCM/JobPositions/SafetyEquipment/EditSafetyEquipment.page";
import EditJobTitle from "../../Pages/HCM/JobPositions/JobTitle/EditJobTitle.page";
import EditExternalApplicant from "../../Pages/HCM/Recruitment/ExternalApplicant/EditExternalApplicant.page";
import EditVacancy from "../../Pages/HCM/Recruitment/Vacancy/EditVacancy.page";
import EditVacancyResult from "../../Pages/HCM/Recruitment/VacancyResult/EditVacancyResult.page";
import EditVacancyExaminer from "../../Pages/HCM/Recruitment/VacancyExaminer/EditVacancyExaminer.page";
import EditVacancyInternalApplicant from "../../Pages/HCM/Recruitment/VacancyInternalApplicant/EditVacancyInternalApplicant.page";
import EditVacancyRequestReason from "../../Pages/HCM/Recruitment/VacancyRequestReason/EditVacancyRequestReason.page";
import EditAttendanceAbscenceType from "../../Pages/HCM/TimeAndLeave/AttendanceAbscenceType/EditAttendanceAbscenceType.page";
import EditAttendanceCaptured from "../../Pages/HCM/TimeAndLeave/AttendanceCaptured/EditAttendanceCaptured.page";
import EditAttendancePayroll from "../../Pages/HCM/TimeAndLeave/AttendancePayroll/EditAttendancePayroll.page";
import EditDeviceIdMapping from "../../Pages/HCM/TimeAndLeave/DeviceIdMapping/EditDeviceIdMapping.page";
import EditHoliday from "../../Pages/HCM/TimeAndLeave/Holiday/EditHoliday.page";
import EditHolidayCharacter from "../../Pages/HCM/TimeAndLeave/HolidayCharacter/EditHolidayCharacter.page";
import EditLeavePeriod from "../../Pages/HCM/TimeAndLeave/LeavePeriod/EditLeavePeriod.page";
import EditLeavePlan from "../../Pages/HCM/TimeAndLeave/LeavePlan/EditLeavePlan.page";
import EditPunch from "../../Pages/HCM/TimeAndLeave/Punch/EditPunch.page";
import EditPunchDevice from "../../Pages/HCM/TimeAndLeave/PunchDevice/EditPunchDevice.page";
import EditPunchLog from "../../Pages/HCM/TimeAndLeave/PunchLog/EditPunchLog.page";
import EditPunchManualImport from "../../Pages/HCM/TimeAndLeave/PunchManualImport/EditPunchManualImport.page";
import EditPunchTime from "../../Pages/HCM/TimeAndLeave/PunchTime/EditPunchTime.page";
import EditShiftScheduleDtl from "../../Pages/HCM/TimeAndLeave/ShiftScheduleDtl/EditShiftScheduleDtl.page";
import EditShiftScheduleHdr from "../../Pages/HCM/TimeAndLeave/ShiftScheduleHdr/EditShiftScheduleHdr.page";
import EditSubShiftGroup from "../../Pages/HCM/TimeAndLeave/SubShiftGroup/EditSubShiftGroup.page";
import EditBusinessUnit from "../../Pages/HCM/CompanyStructure/BusinessUnit/EditBusinessUnit.page";
import EditCompany from "../../Pages/HCM/CompanyStructure/Company/EditCompany.page";
import EditCompanyPrimaryContact from "../../Pages/HCM/CompanyStructure/CompanyPrimaryContact/EditCompanyPrimaryContact.page";
import EditLocation from "../../Pages/HCM/CompanyStructure/Location/EditLocation.page";
import EditEmployeeBackPenalityDeduction from "../../Pages/HCM/Payroll/EmployeeBackPenalityDeduction/EditEmployeeBackPenalityDeduction.page";
import EditEmployeePayScale from "../../Pages/HCM/Payroll/EmployeePayScale/EditEmployeePayScale.page";
import EditEmployeePenality from "../../Pages/HCM/Payroll/EmployeePenality/EditEmployeePenality.page";
import EditEmployeePenalityDetailMst from "../../Pages/HCM/Payroll/EmployeePenalityDetailMst/EditEmployeePenalityDetailMst.page";
import EditEmployeePenalityType from "../../Pages/HCM/Payroll/EmployeePenalityType/EditEmployeePenalityType.page";
import EditEmployeeTax from "../../Pages/HCM/Payroll/EmployeeTax/EditEmployeeTax.page";
import EditGlobalPayrollAccountMapping from "../../Pages/HCM/Payroll/GlobalPayrollAccountMapping/EditGlobalPayrollAccountMapping.page";
import EditOvertime from "../../Pages/HCM/Payroll/Overtime/EditOvertime.page";
import EditOvertimeRate from "../../Pages/HCM/Payroll/OvertimeRate/EditOvertimeRate.page";
import EditPaygrade from "../../Pages/HCM/Payroll/Paygrade/EditPaygrade.page";
import EditPaygradeSalaryComponent from "../../Pages/HCM/Payroll/PaygradeSalaryComponent/EditPaygradeSalaryComponent.page";
import EditPaygradeScale from "../../Pages/HCM/Payroll/PaygradeScale/EditPaygradeScale.page";
import EditPayrollDetail from "../../Pages/HCM/Payroll/PayrollDetail/EditPayrollDetail.page";
import EditPayrollDetailHistory from "../../Pages/HCM/Payroll/PayrollDetailHistory/EditPayrollDetailHistory.page";
import EditPayrollFrequencyType from "../../Pages/HCM/Payroll/PayrollFrequencyType/EditPayrollFrequencyType.page";
import EditPayrollLocationSetting from "../../Pages/HCM/Payroll/PayrollLocationSetting/EditPayrollLocationSetting.page";
import EditPayrollLog from "../../Pages/HCM/Payroll/PayrollLog/EditPayrollLog.page";
import EditPayrollLogEmployee from "../../Pages/HCM/Payroll/PayrollLogEmployee/EditPayrollLogEmployee.page";
import EditPayrollPeriodAutogen from "../../Pages/HCM/Payroll/PayrollPeriodAutogen/EditPayrollPeriodAutogen.page";
import EditPayrollPeriodAutogenLog from "../../Pages/HCM/Payroll/PayrollPeriodAutogenLog/EditPayrollPeriodAutogenLog.page";
import EditPayrollPeriodTemplate from "../../Pages/HCM/Payroll/PayrollPeriodTemplate/EditPayrollPeriodTemplate.page";
import EditPayrollPostingLog from "../../Pages/HCM/Payroll/PayrollPostingLog/EditPayrollPostingLog.page";
import EditPayrollProcessingLog from "../../Pages/HCM/Payroll/PayrollProcessingLog/EditPayrollProcessingLog.page";
import EditPayrollSummary from "../../Pages/HCM/Payroll/PayrollSummary/EditPayrollSummary.page";
import EditPayrollSummaryHistory from "../../Pages/HCM/Payroll/PayrollSummaryHistory/EditPayrollSummaryHistory.page";
import EditPayScaleHistoryLog from "../../Pages/HCM/Payroll/PayScaleHistoryLog/EditPayScaleHistoryLog.page";
import EditSalaryAdjustment from "../../Pages/HCM/Payroll/SalaryAdjustment/EditSalaryAdjustment.page";
import EditSalaryComponent from "../../Pages/HCM/Payroll/SalaryComponent/EditSalaryComponent.page";
import EditSalaryComponentAccountMapping from "../../Pages/HCM/Payroll/SalaryComponentAccountMapping/EditSalaryComponentAccountMapping.page";
import EditAddress from "../../Pages/HCM/EmployeeMaster/Address/EditAddress.page";
import EditAttachment from "../../Pages/HCM/EmployeeMaster/Attachment/EditAttachment.page";
import EditDependent from "../../Pages/HCM/EmployeeMaster/Dependent/EditDependent.page";
import EditDiscipline from "../../Pages/HCM/EmployeeMaster/Discipline/EditDiscipline.page";
import EditEducation from "../../Pages/HCM/EmployeeMaster/Education/EditEducation.page";
import EditEmployee from "../../Pages/HCM/EmployeeMaster/Employee/EditEmployee.page";
import EditEmployeeAction from "../../Pages/HCM/EmployeeMaster/EmployeeAction/EditEmployeeAction.page";
import EditEmployeeAttachment from "../../Pages/HCM/EmployeeMaster/EmployeeAttachment/EditEmployeeAttachment.page";
import EditEmployeeCommitment from "../../Pages/HCM/EmployeeMaster/EmployeeCommitment/EditEmployeeCommitment.page";
import EditEmployeeContact from "../../Pages/HCM/EmployeeMaster/EmployeeContact/EditEmployeeContact.page";
import EditEmployeeIdRange from "../../Pages/HCM/EmployeeMaster/EmployeeIdRange/EditEmployeeIdRange.page";
import EditEmployeeLoanRepayment from "../../Pages/HCM/EmployeeMaster/EmployeeLoanRepayment/EditEmployeeLoanRepayment.page";
import EditEmployeePayFrequency from "../../Pages/HCM/EmployeeMaster/EmployeePayFrequency/EditEmployeePayFrequency.page";
import EditEmployeePaygrade from "../../Pages/HCM/EmployeeMaster/EmployeePaygrade/EditEmployeePaygrade.page";
import EditEmployeeSalaryComponent from "../../Pages/HCM/EmployeeMaster/EmployeeSalaryComponent/EditEmployeeSalaryComponent.page";
import EditExperience from "../../Pages/HCM/EmployeeMaster/Experience/EditExperience.page";
import EditLeaveAssignment from "../../Pages/HCM/EmployeeMaster/LeaveAssignment/EditLeaveAssignment.page";
import EditLeaveEntitlement from "../../Pages/HCM/EmployeeMaster/LeaveEntitlement/EditLeaveEntitlement.page";
import EditLeaveTransfer from "../../Pages/HCM/EmployeeMaster/LeaveTransfer/EditLeaveTransfer.page";
import EditLicense from "../../Pages/HCM/EmployeeMaster/License/EditLicense.page";
import EditOrgAssignment from "../../Pages/HCM/EmployeeMaster/OrgAssignment/EditOrgAssignment.page";
import EditServiceAllowancePay from "../../Pages/HCM/EmployeeMaster/ServiceAllowancePay/EditServiceAllowancePay.page";
import EditShiftAssignment from "../../Pages/HCM/EmployeeMaster/ShiftAssignment/EditShiftAssignment.page";
import EditUnitOfMeasure from "../../Pages/Inventory/InventoryMaster/UnitOfMeasure/EditUnitOfMeasure.page";
import EditWarehouse from "../../Pages/Inventory/InventoryMaster/Warehouse/EditWarehouse.page";
import EditStockBatch from "../../Pages/Inventory/InventoryStock/StockBatch/EditStockBatch.page";
import EditStockItem from "../../Pages/Inventory/InventoryStock/StockItem/EditStockItem.page";
import EditStockTransactionDetail from "../../Pages/Inventory/InventoryStock/StockTransactionDetail/EditStockTransactionDetail.page";
import EditStockTransactionHeader from "../../Pages/Inventory/InventoryStock/StockTransactionHeader/EditStockTransactionHeader.page";
import EditAccountCategory from "../../Pages/Finance/FinanceMaster/AccountCategory/EditAccountCategory.page";
import EditAccountType from "../../Pages/Finance/FinanceMaster/AccountType/EditAccountType.page";
import EditClosingNote from "../../Pages/Finance/FinanceMaster/ClosingNote/EditClosingNote.page";
import EditFinancialStatementSection from "../../Pages/Finance/FinanceMaster/FinancialStatementSection/EditFinancialStatementSection.page";
import EditBank from "../../Pages/Finance/FinanceMaster/Bank/EditBank.page";
import EditContact from "../../Pages/Finance/FinanceMaster/Contact/EditContact.page";
import EditContactAddress from "../../Pages/Finance/FinanceMaster/ContactAddress/EditContactAddress.page";
import EditContactPerson from "../../Pages/Finance/FinanceMaster/ContactPerson/EditContactPerson.page";
import EditCostCenter from "../../Pages/Finance/FinanceMaster/CostCenter/EditCostCenter.page";
import EditCostCenterAccounts from "../../Pages/Finance/FinanceMaster/CostCenterAccounts/EditCostCenterAccounts.page";
import EditPaymentTerm from "../../Pages/Finance/FinanceMaster/PaymentTerm/EditPaymentTerm.page";
import EditExchangeRate from "../../Pages/Finance/FinanceMaster/ExchangeRate/EditExchangeRate.page";
import EditCompanyAddress from "../../Pages/Finance/FinanceMaster/CompanyAddress/EditCompanyAddress.page";
import EditDateFormatType from "../../Pages/Finance/FinanceMaster/DateFormatType/EditDateFormatType.page";
import EditFinancialSettings from "../../Pages/Finance/FinanceMaster/FinancialSettings/EditFinancialSettings.page";
import EditIndustry from "../../Pages/Finance/FinanceMaster/Industry/EditIndustry.page";
import EditJournalUsers from "../../Pages/Finance/FinanceMaster/JournalUsers/EditJournalUsers.page";
import EditPrimaryContact from "../../Pages/Finance/FinanceMaster/PrimaryContact/EditPrimaryContact.page";
import EditFootNote from "../../Pages/Finance/FinanceMaster/FootNote/EditFootNote.page";
import EditAssociatedTaxGroup from "../../Pages/Finance/FinanceMaster/AssociatedTaxGroup/EditAssociatedTaxGroup.page";
import EditTax from "../../Pages/Finance/FinanceMaster/Tax/EditTax.page";
import EditTaxAuthority from "../../Pages/Finance/FinanceMaster/TaxAuthority/EditTaxAuthority.page";
import EditTaxExemption from "../../Pages/Finance/FinanceMaster/TaxExemption/EditTaxExemption.page";
import EditTaxGroup from "../../Pages/Finance/FinanceMaster/TaxGroup/EditTaxGroup.page";
import EditTaxRule from "../../Pages/Finance/FinanceMaster/TaxRule/EditTaxRule.page";
import EditAccountingPeriod from "../../Pages/Finance/FinanceMaster/AccountingPeriod/EditAccountingPeriod.page";
import EditJournalType from "../../Pages/Finance/FinanceMaster/JournalType/EditJournalType.page";
import EditChartOfAccount from "../../Pages/Finance/FinanceOperational/ChartOfAccount/EditChartOfAccount.page";
import EditAccountTypeFinancialStatementSection from "../../Pages/Finance/FinanceOperational/AccountTypeFinancialStatementSection/EditAccountTypeFinancialStatementSection.page";
import EditBankReconcilation from "../../Pages/Finance/FinanceOperational/BankReconcilation/EditBankReconcilation.page";
import EditChartOfAccountFiles from "../../Pages/Finance/FinanceOperational/ChartOfAccountFiles/EditChartOfAccountFiles.page";
import EditReconcilationTransaction from "../../Pages/Finance/FinanceOperational/ReconcilationTransaction/EditReconcilationTransaction.page";
import EditAsset from "../../Pages/Finance/FinanceOperational/Asset/EditAsset.page";
import EditEstimatedTotalProductionUnit from "../../Pages/Finance/FinanceOperational/EstimatedTotalProductionUnit/EditEstimatedTotalProductionUnit.page";
import EditRecurringJournalOccurrence from "../../Pages/Finance/FinanceOperational/RecurringJournalOccurrence/EditRecurringJournalOccurrence.page";
import EditBudget from "../../Pages/Finance/FinanceOperational/Budget/EditBudget.page";
import EditBudgetAccount from "../../Pages/Finance/FinanceOperational/BudgetAccount/EditBudgetAccount.page";
import EditBudgetAccountPeriod from "../../Pages/Finance/FinanceOperational/BudgetAccountPeriod/EditBudgetAccountPeriod.page";
import EditBudgetControlAction from "../../Pages/Finance/FinanceOperational/BudgetControlAction/EditBudgetControlAction.page";
import EditGeneralJournalDetail from "../../Pages/Finance/FinanceOperational/GeneralJournalDetail/EditGeneralJournalDetail.page";
import EditGeneralJournalFiles from "../../Pages/Finance/FinanceOperational/GeneralJournalFiles/EditGeneralJournalFiles.page";
import EditGeneralJournalHeader from "../../Pages/Finance/FinanceOperational/GeneralJournalHeader/EditGeneralJournalHeader.page";
import EditGeneralLedger from "../../Pages/Finance/FinanceOperational/GeneralLedger/EditGeneralLedger.page";
import EditJournalComment from "../../Pages/Finance/FinanceOperational/JournalComment/EditJournalComment.page";
import EditNumberTracker from "../../Pages/Finance/FinanceOperational/NumberTracker/EditNumberTracker.page";
import EditRecurringGeneralJournal from "../../Pages/Finance/FinanceOperational/RecurringGeneralJournal/EditRecurringGeneralJournal.page";
import EditTransactionLock from "../../Pages/Finance/FinanceOperational/TransactionLock/EditTransactionLock.page";
import EditOpeningBalance from "../../Pages/Finance/FinanceOperational/OpeningBalance/EditOpeningBalance.page";
import EditClient from "../../Pages/Client/EditClient.page";
import EditCashPaymentCustom from "../../Pages/Finance/FinanceOperational/CashPaymentCustom/EditCashPaymentCustom.page";
import EditCashPaymentVoucher from "../../Pages/Finance/FinanceOperational/CashPaymentVoucher/EditCashPaymentVoucher.page";
import EditCpvBank from "../../Pages/Finance/FinanceOperational/CpvBank/EditCpvBank.page";
import EditCpvPaymentRequest from "../../Pages/Finance/FinanceOperational/CpvPaymentRequest/EditCpvPaymentRequest.page";
import EditCpvReceipt from "../../Pages/Finance/FinanceOperational/CpvReceipt/EditCpvReceipt.page";
import EditCpvType from "../../Pages/Finance/FinanceOperational/CpvType/EditCpvType.page";
import EditPettyCash from "../../Pages/Finance/FinanceOperational/PettyCash/EditPettyCash.page";
import EditCrvPayment from "../../Pages/Finance/FinanceOperational/CrvPayment/EditCrvPayment.page";
import EditCrvType from "../../Pages/Finance/FinanceOperational/CrvType/EditCrvType.page";
import EditUser from "../../Pages/Account/User/EditUser.page";
import EditRole from "../../Pages/Account/Role/EditRole.page";
import EditPrivilege from "../../Pages/Account/Privilege/EditPrivilege.page";

const EditModal = (props) => {
    const location = useLocation();
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    return (
        <Paper
            className="EditModal"
            style={{
                alignSelf: "center",
                padding: "2ch",
                maxHeight: "80vh",
                maxWidth: "80%",
                overflowY: "scroll",
            }}
        >
            <IconButton
                color={"error"}
                style={{ float: "right", position: "sticky", top: "0" }}
                onClick={() => {
                    props.close();
                }}
            >
                <CloseRounded />
            </IconButton>
            {location.pathname === `/View/Account/User` && (
                <EditUser close={props.close} />
            )}
            {location.pathname === `/View/Account/Role` && (
                <EditRole close={props.close} />
            )}
            {location.pathname === `/View/Account/Privilege` && (
                <EditPrivilege close={props.close} />
            )}
            {location.pathname === "/View/Material" && (
                <EditMaterial close={props.close} />
            )}
            {location.pathname === "/View/ViewProject" && (
                <EditProject close={props.close} />
            )}
            {location.pathname === "/View/ViewClient" && (
                <EditClient close={props.close} />
            )}
            {location.pathname === "/View/Equipment" && (
                <EditEquipment close={props.close} />
            )}
            {location.pathname === "/View/MaterialCategory" && (
                <EditMaterialCategory close={props.close} />
            )}
            {location.pathname === "/View/Evaluation" && (
                <EditEvaluation close={props.close} />
            )}
            {location.pathname === "/View/Instruction" && (
                <EditInstruction close={props.close} />
            )}
            {location.pathname === "/View/Manpower" && (
                <EditManpower close={props.close} />
            )}
            {location.pathname === "/View/Phase" && (
                <EditPhase close={props.close} />
            )}
            {location.pathname === "/View/WorkCategory" && (
                <EditWorkCategory close={props.close} />
            )}
            {location.pathname === "/View/Priority" && (
                <EditPriority close={props.close} />
            )}
            {location.pathname === "/View/Documentation" && (
                <EditDocumentation close={props.close} />
            )}
            {location.pathname === "/View/DocumentCategory" && (
                <EditDocumentCategory close={props.close} />
            )}
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/DailyWorkLog` && (
                <EditDailyWorkLog close={props.close} />
            )}
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/InstructionGiven` && (
                <EditInstructionGiven close={props.close} />
            )}
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/InvoiceTracking` && (
                <EditInvoiceTracking close={props.close} />
            )}
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/ManpowerRequirement` && (
                <EditManpowerRequirement close={props.close} />
            )}
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/Request` && (
                <EditRequest close={props.close} />
            )}
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/RequiredEquipment` && (
                <EditRequiredEquipment close={props.close} />
            )}
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/RequiredMaterial` && (
                <EditRequiredMaterial close={props.close} />
            )}
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/RiskTracking` && (
                <EditRiskTracking close={props.close} />
            )}
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/SimpleTask` && (
                <EditSimpleTask close={props.close} />
            )}
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/ToDos` && (
                <EditToDos close={props.close} />
            )}
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/WeatherData` && (
                <EditWeatherData close={props.close} />
            )}
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/TaskManager` && (
                <EditTaskManager close={props.close} />
            )}
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/RequiredDocumentation` && (
                <EditRequiredDocumentation close={props.close} />
            )}
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/RequestPayment` && (
                <EditRequestPayment close={props.close} />
            )}
            {/* =================================||HCM MASTER DATA||======================================== */}
            {location.pathname === `/View/HCM/ActionReason` && (
                <EditActionReason close={props.close} />
            )}
            {location.pathname === `/View/HCM/ActionType` && (
                <EditActionType close={props.close} />
            )}
            {location.pathname === `/View/HCM/AddressType` && (
                <EditAddressType close={props.close} />
            )}
            {location.pathname === `/View/HCM/Country` && (
                <EditCountry close={props.close} />
            )}
            {location.pathname === `/View/HCM/Currency` && (
                <EditCurrency close={props.close} />
            )}
            {location.pathname === `/View/HCM/Commitment` && (
                <EditCommitment close={props.close} />
            )}
            {location.pathname === `/View/HCM/CustomReport` && (
                <EditCustomReport close={props.close} />
            )}
            {location.pathname === `/View/HCM/DisciplineCaseType` && (
                <EditDisciplineCaseType close={props.close} />
            )}
            {location.pathname === `/View/HCM/DurationMeasure` && (
                <EditDurationMeasure close={props.close} />
            )}
            {location.pathname === `/View/HCM/EmployeeGroup` && (
                <EditEmployeeGroup close={props.close} />
            )}
            {location.pathname === `/View/HCM/EmployeeTransactionLock` && (
                <EditEmployeeTransactionLock close={props.close} />
            )}
            {location.pathname === `/View/HCM/EmployeeType` && (
                <EditEmployeeType close={props.close} />
            )}
            {location.pathname === `/View/HCM/FieldOfStudy` && (
                <EditFieldOfStudy close={props.close} />
            )}
            {location.pathname === `/View/HCM/HCMConfiguration` && (
                <EditHCMConfiguration close={props.close} />
            )}
            {location.pathname === `/View/HCM/Language` && (
                <EditLanguage close={props.close} />
            )}
            {location.pathname === `/View/HCM/LicenseType` && (
                <EditLicenseType close={props.close} />
            )}
            {location.pathname === `/View/HCM/Nationality` && (
                <EditNationality close={props.close} />
            )}
            {location.pathname === `/View/HCM/Religion` && (
                <EditReligion close={props.close} />
            )}
            {location.pathname === `/View/HCM/Title` && (
                <EditTitle close={props.close} />
            )}
            {location.pathname === `/View/HCM/TrainingType` && (
                <EditTrainingType close={props.close} />
            )}
            {/* =====================================||HCM JOB POSITIONS||=============================================== */}
            {location.pathname === `/View/HCM/JobCategory` && (
                <EditJobCategory close={props.close} />
            )}
            {location.pathname === `/View/HCM/JobSafetyEquipment` && (
                <EditJobSafetyEquipment close={props.close} />
            )}
            {location.pathname === `/View/HCM/JobTitle` && (
                <EditJobTitle close={props.close} />
            )}
            {location.pathname === `/View/HCM/SafetyEquipment` && (
                <EditSafetyEquipment close={props.close} />
            )}
            {/* =====================================||HCM RECRUITMENT||=============================================== */}
            {location.pathname === `/View/HCM/Vacancy` && (
                <EditVacancy close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/Vacancy/${selectedRow?.id}/ExternalApplicant` && (
                <EditExternalApplicant close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/Vacancy/${selectedRow?.id}/VacancyResult` && (
                <EditVacancyResult close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/Vacancy/${selectedRow?.id}/VacancyExaminer` && (
                <EditVacancyExaminer close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/Vacancy/${selectedRow?.id}/VacancyInternalApplicant` && (
                <EditVacancyInternalApplicant close={props.close} />
            )}
            {location.pathname === `/View/HCM/VacancyRequestReason` && (
                <EditVacancyRequestReason close={props.close} />
            )}
            {/* =====================================HCM TIME AND LEAVE================================================= */}
            {location.pathname === `/View/HCM/AttendanceAbscenceType` && (
                <EditAttendanceAbscenceType close={props.close} />
            )}
            {location.pathname === `/View/HCM/AttendanceCaptured` && (
                <EditAttendanceCaptured close={props.close} />
            )}
            {location.pathname === `/View/HCM/AttendancePayroll` && (
                <EditAttendancePayroll close={props.close} />
            )}
            {location.pathname === `/View/HCM/DeviceIdMapping` && (
                <EditDeviceIdMapping close={props.close} />
            )}
            {location.pathname === `/View/HCM/Holiday` && (
                <EditHoliday close={props.close} />
            )}
            {location.pathname === `/View/HCM/HolidayCharacter` && (
                <EditHolidayCharacter close={props.close} />
            )}
            {location.pathname === `/View/HCM/LeavePeriod` && (
                <EditLeavePeriod close={props.close} />
            )}
            {location.pathname === `/View/HCM/LeavePlan` && (
                <EditLeavePlan close={props.close} />
            )}
            {location.pathname === `/View/HCM/Punch` && (
                <EditPunch close={props.close} />
            )}
            {location.pathname === `/View/HCM/PunchDevice` && (
                <EditPunchDevice close={props.close} />
            )}
            {location.pathname === `/View/HCM/PunchLog` && (
                <EditPunchLog close={props.close} />
            )}
            {location.pathname === `/View/HCM/PunchManualImport` && (
                <EditPunchManualImport close={props.close} />
            )}
            {location.pathname === `/View/HCM/PunchTime` && (
                <EditPunchTime close={props.close} />
            )}
            {location.pathname === `/View/HCM/ShiftScheduleDtl` && (
                <EditShiftScheduleDtl close={props.close} />
            )}
            {location.pathname === `/View/HCM/ShiftScheduleHdr` && (
                <EditShiftScheduleHdr close={props.close} />
            )}
            {location.pathname === `/View/HCM/SubShiftGroup` && (
                <EditSubShiftGroup close={props.close} />
            )}
            {/* ===========================================HCM COMPANY STRUCTURE================================================ */}
            {location.pathname === `/View/HCM/BusinessUnit` && (
                <EditBusinessUnit close={props.close} />
            )}
            {location.pathname === `/View/HCM/Company` && (
                <EditCompany close={props.close} />
            )}
            {location.pathname === `/View/HCM/CompanyPrimaryContact` && (
                <EditCompanyPrimaryContact close={props.close} />
            )}
            {location.pathname === `/View/HCM/Location` && (
                <EditLocation close={props.close} />
            )}
            {/* ========================================HCM PAYROLL=============================================== */}

            {location.pathname === `/View/HCM/EmployeePayScale` && (
                <EditEmployeePayScale close={props.close} />
            )}
            {location.pathname === `/View/HCM/EmployeePenalityType` && (
                <EditEmployeePenalityType close={props.close} />
            )}
            {location.pathname === `/View/HCM/EmployeeTax` && (
                <EditEmployeeTax close={props.close} />
            )}
            {location.pathname === `/View/HCM/GlobalPayrollAccountMapping` && (
                <EditGlobalPayrollAccountMapping close={props.close} />
            )}
            {location.pathname === `/View/HCM/Overtime` && (
                <EditOvertime close={props.close} />
            )}
            {location.pathname === `/View/HCM/OvertimeRate` && (
                <EditOvertimeRate close={props.close} />
            )}
            {location.pathname === `/View/HCM/Paygrade` && (
                <EditPaygrade close={props.close} />
            )}
            {location.pathname === `/View/HCM/PaygradeSalaryComponent` && (
                <EditPaygradeSalaryComponent close={props.close} />
            )}
            {location.pathname === `/View/HCM/PaygradeScale` && (
                <EditPaygradeScale close={props.close} />
            )}
            {location.pathname === `/View/HCM/PayrollDetail` && (
                <EditPayrollDetail close={props.close} />
            )}
            {location.pathname === `/View/HCM/PayrollDetailHistory` && (
                <EditPayrollDetailHistory close={props.close} />
            )}
            {location.pathname === `/View/HCM/PayrollFrequencyType` && (
                <EditPayrollFrequencyType close={props.close} />
            )}
            {location.pathname === `/View/HCM/PayrollLocationSetting` && (
                <EditPayrollLocationSetting close={props.close} />
            )}
            {location.pathname === `/View/HCM/PayrollLog` && (
                <EditPayrollLog close={props.close} />
            )}
            {location.pathname === `/View/HCM/PayrollLogEmployee` && (
                <EditPayrollLogEmployee close={props.close} />
            )}
            {location.pathname === `/View/HCM/PayrollPeriodAutogen` && (
                <EditPayrollPeriodAutogen close={props.close} />
            )}
            {location.pathname === `/View/HCM/PayrollPeriodAutogenLog` && (
                <EditPayrollPeriodAutogenLog close={props.close} />
            )}
            {location.pathname === `/View/HCM/PayrollPeriodTemplate` && (
                <EditPayrollPeriodTemplate close={props.close} />
            )}
            {location.pathname === `/View/HCM/PayrollPostingLog` && (
                <EditPayrollPostingLog close={props.close} />
            )}
            {location.pathname === `/View/HCM/PayrollProcessingLog` && (
                <EditPayrollProcessingLog close={props.close} />
            )}
            {location.pathname === `/View/HCM/PayrollSummary` && (
                <EditPayrollSummary close={props.close} />
            )}
            {location.pathname === `/View/HCM/PayrollSummaryHistory` && (
                <EditPayrollSummaryHistory close={props.close} />
            )}
            {location.pathname === `/View/HCM/PayScaleHistoryLog` && (
                <EditPayScaleHistoryLog close={props.close} />
            )}
            {location.pathname === `/View/HCM/SalaryAdjustment` && (
                <EditSalaryAdjustment close={props.close} />
            )}
            {location.pathname === `/View/HCM/SalaryComponent` && (
                <EditSalaryComponent close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/SalaryComponentAccountMapping` && (
                <EditSalaryComponentAccountMapping close={props.close} />
            )}
            {/* ================================================HCM EMPLOYEE MASTER====================================================== */}
            {location.pathname ===
                `/View/HCM/Employee/${selectedRow?.id}/Address` && (
                <EditAddress close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/Employee/${selectedRow?.id}/Attachment` && (
                <EditAttachment close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/Employee/${selectedRow?.id}/Dependent` && (
                <EditDependent close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/Employee/${selectedRow?.id}/Discipline` && (
                <EditDiscipline close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/Employee/${selectedRow?.id}/Education` && (
                <EditEducation close={props.close} />
            )}
            {location.pathname === `/View/HCM/Employee` && (
                <EditEmployee close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/Employee/${selectedRow?.id}/EmployeeAction` && (
                <EditEmployeeAction close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/Employee/${selectedRow?.id}/EmployeeAttachment` && (
                <EditEmployeeAttachment close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/Employee/${selectedRow?.id}/EmployeeCommitment` && (
                <EditEmployeeCommitment close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/Employee/${selectedRow?.id}/EmployeeContact` && (
                <EditEmployeeContact close={props.close} />
            )}
            {location.pathname === `/View/HCM/EmployeeIdRange` && (
                <EditEmployeeIdRange close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/Employee/${selectedRow?.id}/EmployeeLoanRepayment` && (
                <EditEmployeeLoanRepayment close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/Employee/${selectedRow?.id}/EmployeePayFrequency` && (
                <EditEmployeePayFrequency close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/Employee/${selectedRow?.id}/EmployeePaygrade` && (
                <EditEmployeePaygrade close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/Employee/${selectedRow?.id}/EmployeeSalaryComponent` && (
                <EditEmployeeSalaryComponent close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/Employee/${selectedRow?.id}/Experience` && (
                <EditExperience close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/Employee/${selectedRow?.id}/LeaveAssignment` && (
                <EditLeaveAssignment close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/Employee/${selectedRow?.id}/LeaveEntitlement` && (
                <EditLeaveEntitlement close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/Employee/${selectedRow?.id}/LeaveTransfer` && (
                <EditLeaveTransfer close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/Employee/${selectedRow?.id}/License` && (
                <EditLicense close={props.close} />
            )}
            {location.pathname === `/View/HCM/OrgAssignment` && (
                <EditOrgAssignment close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/Employee/${selectedRow?.id}/ServiceAllowancePay` && (
                <EditServiceAllowancePay close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/Employee/${selectedRow?.id}/ShiftAssignment` && (
                <EditShiftAssignment close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/Employee/${selectedRow?.id}/EmployeePenality` && (
                <EditEmployeePenality close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/Employee/${selectedRow?.id}/EmployeePenalityDetailMst` && (
                <EditEmployeePenalityDetailMst close={props.close} />
            )}
            {location.pathname ===
                `/View/HCM/Employee/${selectedRow?.id}/EmployeeBackPenalityDeduction` && (
                <EditEmployeeBackPenalityDeduction close={props.close} />
            )}
            {/* =========================================INVENTORY====================================================== */}
            {location.pathname === `/View/Inventory/UnitOfMeasure` && (
                <EditUnitOfMeasure close={props.close} />
            )}
            {location.pathname === `/View/Inventory/Warehouse` && (
                <EditWarehouse close={props.close} />
            )}
            {location.pathname === `/View/Inventory/StockBatch` && (
                <EditStockBatch close={props.close} />
            )}
            {location.pathname === `/View/Inventory/StockItem` && (
                <EditStockItem close={props.close} />
            )}
            {location.pathname === `/View/Inventory/StockTransactionDetail` && (
                <EditStockTransactionDetail close={props.close} />
            )}
            {location.pathname === `/View/Inventory/StockTransactionHeader` && (
                <EditStockTransactionHeader close={props.close} />
            )}
            {/* ==============================================FINANCE MASTER====================================================== */}
            {location.pathname === `/View/Finance/AccountCategory` && (
                <EditAccountCategory close={props.close} />
            )}
            {location.pathname === `/View/Finance/AccountType` && (
                <EditAccountType close={props.close} />
            )}
            {location.pathname === `/View/Finance/ClosingNote` && (
                <EditClosingNote close={props.close} />
            )}
            {location.pathname ===
                `/View/Finance/FinancialStatementSection` && (
                <EditFinancialStatementSection close={props.close} />
            )}
            {location.pathname === `/View/Finance/Bank` && (
                <EditBank close={props.close} />
            )}
            {location.pathname === `/View/Finance/Contact` && (
                <EditContact close={props.close} />
            )}
            {location.pathname === `/View/Finance/ContactAddress` && (
                <EditContactAddress close={props.close} />
            )}
            {location.pathname === `/View/Finance/ContactPerson` && (
                <EditContactPerson close={props.close} />
            )}
            {location.pathname === `/View/Finance/CostCenter` && (
                <EditCostCenter close={props.close} />
            )}
            {location.pathname === `/View/Finance/CostCenterAccounts` && (
                <EditCostCenterAccounts close={props.close} />
            )}
            {location.pathname === `/View/Finance/PaymentTerm` && (
                <EditPaymentTerm close={props.close} />
            )}
            {location.pathname === `/View/Finance/ExchangeRate` && (
                <EditExchangeRate close={props.close} />
            )}
            {location.pathname === `/View/Finance/CompanyAddress` && (
                <EditCompanyAddress close={props.close} />
            )}
            {location.pathname === `/View/Finance/DateFormatType` && (
                <EditDateFormatType close={props.close} />
            )}
            {location.pathname === `/View/Finance/FinancialSettings` && (
                <EditFinancialSettings close={props.close} />
            )}
            {location.pathname === `/View/Finance/Industry` && (
                <EditIndustry close={props.close} />
            )}
            {location.pathname === `/View/Finance/JournalUsers` && (
                <EditJournalUsers close={props.close} />
            )}
            {location.pathname === `/View/Finance/PrimaryContact` && (
                <EditPrimaryContact close={props.close} />
            )}
            {location.pathname === `/View/Finance/FootNote` && (
                <EditFootNote close={props.close} />
            )}
            {location.pathname === `/View/Finance/AssociatedTaxGroup` && (
                <EditAssociatedTaxGroup close={props.close} />
            )}
            {location.pathname === `/View/Finance/Tax` && (
                <EditTax close={props.close} />
            )}
            {location.pathname === `/View/Finance/TaxAuthority` && (
                <EditTaxAuthority close={props.close} />
            )}
            {location.pathname === `/View/Finance/TaxExemption` && (
                <EditTaxExemption close={props.close} />
            )}
            {location.pathname === `/View/Finance/TaxGroup` && (
                <EditTaxGroup close={props.close} />
            )}
            {location.pathname === `/View/Finance/TaxRule` && (
                <EditTaxRule close={props.close} />
            )}
            {location.pathname === `/View/Finance/AccountingPeriod` && (
                <EditAccountingPeriod close={props.close} />
            )}
            {location.pathname === `/View/Finance/JournalType` && (
                <EditJournalType close={props.close} />
            )}
            {/* =================================================FINANCE OPERATION===================================================== */}
            {location.pathname === `/View/Finance/ChartOfAccount` && (
                <EditChartOfAccount close={props.close} />
            )}
            {location.pathname ===
                `/View/Finance/AccountTypeFinancialStatementSection` && (
                <EditAccountTypeFinancialStatementSection close={props.close} />
            )}
            {location.pathname === `/View/Finance/BankReconcilation` && (
                <EditBankReconcilation close={props.close} />
            )}
            {location.pathname === `/View/Finance/ChartOfAccountFiles` && (
                <EditChartOfAccountFiles close={props.close} />
            )}
            {location.pathname === `/View/Finance/ReconcilationTransaction` && (
                <EditReconcilationTransaction close={props.close} />
            )}
            {location.pathname === `/View/Finance/Asset` && (
                <EditAsset close={props.close} />
            )}
            {location.pathname ===
                `/View/Finance/EstimatedTotalProductionUnit` && (
                <EditEstimatedTotalProductionUnit close={props.close} />
            )}
            {location.pathname ===
                `/View/Finance/RecurringJournalOccurrence` && (
                <EditRecurringJournalOccurrence close={props.close} />
            )}
            {location.pathname === `/View/Finance/Budget` && (
                <EditBudget close={props.close} />
            )}
            {location.pathname === `/View/Finance/BudgetAccount` && (
                <EditBudgetAccount close={props.close} />
            )}
            {location.pathname === `/View/Finance/BudgetAccountPeriod` && (
                <EditBudgetAccountPeriod close={props.close} />
            )}
            {location.pathname === `/View/Finance/BudgetControlAction` && (
                <EditBudgetControlAction close={props.close} />
            )}
            {location.pathname === `/View/Finance/GeneralJournalDetail` && (
                <EditGeneralJournalDetail close={props.close} />
            )}
            {location.pathname === `/View/Finance/GeneralJournalFiles` && (
                <EditGeneralJournalFiles close={props.close} />
            )}
            {location.pathname === `/View/Finance/GeneralJournalHeader` && (
                <EditGeneralJournalHeader close={props.close} />
            )}
            {location.pathname === `/View/Finance/GeneralLedger` && (
                <EditGeneralLedger close={props.close} />
            )}
            {location.pathname === `/View/Finance/JournalComment` && (
                <EditJournalComment close={props.close} />
            )}
            {location.pathname === `/View/Finance/NumberTracker` && (
                <EditNumberTracker close={props.close} />
            )}
            {location.pathname === `/View/Finance/RecurringGeneralJournal` && (
                <EditRecurringGeneralJournal close={props.close} />
            )}
            {location.pathname === `/View/Finance/TransactionLock` && (
                <EditTransactionLock close={props.close} />
            )}
            {location.pathname === `/View/Finance/OpeningBalance` && (
                <EditOpeningBalance close={props.close} />
            )}
            {location.pathname === `/View/Finance/CashPaymentCustom` && (
                <EditCashPaymentCustom close={props.close} />
            )}
            {location.pathname === `/View/Finance/CashPaymentVoucher` && (
                <EditCashPaymentVoucher close={props.close} />
            )}
            {location.pathname === `/View/Finance/CpvBank` && (
                <EditCpvBank close={props.close} />
            )}
            {location.pathname === `/View/Finance/CpvPaymentRequest` && (
                <EditCpvPaymentRequest close={props.close} />
            )}
            {location.pathname === `/View/Finance/CpvReceipt` && (
                <EditCpvReceipt close={props.close} />
            )}
            {location.pathname === `/View/Finance/CpvType` && (
                <EditCpvType close={props.close} />
            )}
            {location.pathname === `/View/Finance/PettyCash` && (
                <EditPettyCash close={props.close} />
            )}
            {location.pathname === `/View/Finance/CrvPayment` && (
                <EditCrvPayment close={props.close} />
            )}
            {location.pathname === `/View/Finance/CrvType` && (
                <EditCrvType close={props.close} />
            )}
        </Paper>
    );
};

export default EditModal;
