import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { Downloading, UploadFile } from "@mui/icons-material";
import React, { useRef } from "react";
import { fileCaller, get } from "../../utils/Caller";
import { UploadURL, DownloadURL } from "../../utils/config";
import { toExcel } from "../../utils/toExcel";

export const UploadButton = (props) => {
    const uploadRef = useRef();
    const fileUploader = (file) => {
        let data = {
            file: file,
            type: props.type,
        };
        let formData = new FormData();
        for (let value in data) {
            formData.append(value, data[value]);
        }

        fileCaller(UploadURL, formData).then((res) => {
            if (res?.error) {
                props.setError();
                props.setMsg(res?.error?.file);
            } else if (res?.data?.success) {
                props.setSuccess();
            }
        });
    };

    return (
        <>
            <Button
                data-testid="uploadButton"
                color="primary"
                variant="contained"
                onClick={() => {
                    get(DownloadURL      , {
                        type: props.type,
                    }).then((res) => {
                        res.data &&
                        toExcel(res.data?.file);
                    });
                }}
                endIcon={<Downloading />}
            >
                Download Template
            </Button>
            <Button
                data-testid="uploadButton"
                color="primary"
                variant="contained"
                onClick={() => {
                    uploadRef.current.click();
                }}
                endIcon={<UploadFile />}
            >
                Upload File
            </Button>
            <input
                hidden
                ref={uploadRef}
                type="file"
                accept=".xlsx, .xls"
                onChange={(e) => {
                    fileUploader(e.target.files[0]);
                }}
            ></input>
        </>
    );
};

UploadButton.propTypes = {
    setError: PropTypes.func,
    /**Function to set status to error */
    setMsg: PropTypes.func,
    /**Function to set the message to be displayed */
    setSuccess: PropTypes.func,
    /**Function to set status to success */
    type: PropTypes.string,
    /**Type depends on what data is to be uploaded
     * Number - depends on the API
     */
};
