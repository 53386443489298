import { Add, Edit } from "@mui/icons-material";
import {
    Box,
    Button,
    // IconButton,
    // InputAdornment,
    Modal,
    // TextField,
    Paper,
    Typography,
    Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import EditModal from "../../../../Layouts/Modal/EditModal.modal";
// import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { setSelectedDetail } from "../../../../Redux/Slice/selectedDetail";
import { get } from "../../../../utils/Caller";
import { AddressURL } from "../../../../utils/config";

const ViewAddress = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    // const [filter, setFilter] = useState();
    // const [Search, setSearch] = useState("");
    const [editModal, setEditModal] = useState(false);
    const user = useSelector((state) => state.userState.user);
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    const columns = [
        {
            field: "address_line_1",
            label: "Address Line 1",
        },
        {
            field: "address_line_2",
            label: "Address Line 2",
        },
        {
            field: "region",
            label: "Region",
        },
        {
            field: "city",
            label: "City",
        },
        {
            field: "mobile_number",
            label: "Mobile Number",
        },
        {
            field: "house_number",
            label: "House Number",
        },
        {
            field: "postal_box",
            label: "Postal Box",
        },
        {
            field: "email",
            label: "E-mail",
        },
        {
            field: "employeeName",
            label: "Employee Name",
        },
        {
            field: "addressType",
            label: "Address Type",
        },
    ];

    useEffect(() => {
        let AddressData = [];
        if (rows?.length === 0) {
            setloading(true);
        }
        get(AddressURL, {
            limit: 1,
            filter: { employee_id: selectedRow?.id },
        }).then((res) => {
            // console.log(res);
            res && res.data && setloading(false);
            res &&
                res.data?.forEach((data, index) => {
                    AddressData.push({
                        ...data,
                        employeeName: data.employee?.first_name,
                        addressType: data.address_type?.type_name,
                    });
                });
            setRows(AddressData);
            dispatch(setSelectedDetail({ ...AddressData[0] }));
        });
    }, [refreshTable, rows?.length, selectedRow?.id, dispatch]);

    const closeModal = () => {
        setEditModal(false);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <Box width={"20%"} />
                {/* <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setloading(true);
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                /> */}
                {user.actions?.role?.length !== 0 && (
                    <div style={{ display: "flex", gap: "2ch" }}>
                        {rows?.length > 0 ? (
                            <Button
                                variant="contained"
                                color="success"
                                endIcon={<Edit />}
                                onClick={() => {
                                    setEditModal(!editModal);
                                }}
                            >
                                Edit Address
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                color="success"
                                endIcon={<Add />}
                                onClick={() => {
                                    navigate("/View/HCM/Address/AddAddress");
                                }}
                            >
                                Add Address
                            </Button>
                        )}
                    </div>
                )}
            </div>
            <Paper
                style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100vh",
                    // width: "100%",
                }}
            >
                {rows?.length > 0 ? (
                    <Box
                        style={{
                            display: "flex",
                            WebkitFlexDirection: "column",
                            MsFlexDirection: "column",
                            flexDirection: "column",
                            padding: "3ch",
                            border: "silver 1px solid",
                            margin: "2ch",
                            borderRadius: "5px",
                            width: "auto",
                        }}
                    >
                        {columns.map((column, index) => (
                            <Box
                                style={{
                                    WebkitFlexDirection: "row",
                                    MsFlexDirection: "row",
                                    display: "flex",
                                    flexDirection: "row",
                                    height: "7ch",
                                    borderBottom: "silver 1px solid",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    fontWeight={"600"}
                                    color={"#131417"}
                                    key={index}
                                    letterSpacing={"1.2px"}
                                >
                                    {column.label}
                                </Typography>
                                {rows.map((row, i) => {
                                    if (!loading) {
                                        return (
                                            <Typography key={i}>
                                                {row[column.field]}
                                            </Typography>
                                        );
                                    } else {
                                        return <Skeleton animation="wave" />;
                                    }
                                })}
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <Typography
                        variant="h4"
                        fontWeight={"600"}
                        style={{ alignSelf: "center", justifySelf: "center" }}
                        color="silver"
                        padding={"5ch"}
                    >
                        No Address Registered
                    </Typography>
                )}
            </Paper>
            <Modal
                className="Modal"
                open={editModal}
                onClose={() => {
                    setEditModal(false);
                }}
            >
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <EditModal close={closeModal} />
                </Box>
            </Modal>
        </div>
    );
};

export default ViewAddress;
