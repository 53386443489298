import { Add, SearchRounded } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import Table from "../../../../Components/Table/Table.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { baseURL, get } from "../../../../utils/Caller";
import { ChartOfAccountFilesURL } from "../../../../utils/config";
import { RiFileExcel2Line } from "react-icons/ri";

const ViewChartOfAccountFiles = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(20);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState();

    const renderFileDownload = (url) => {
        return (
            <IconButton
                onClick={() => window.open(baseURL + url?.row?.path, "_blank")}
            >
                <RiFileExcel2Line color={"green"} />
            </IconButton>
        );
    };

    const columns = [
        {
            field: "files",
            headerName: "File",
            width: 150,
            renderCell: renderFileDownload,
        },
        {
            field: "name",
            headerName: "Name",
            width: 150,
        },
        {
            field: "type",
            headerName: "Type",
            width: 150,
        },
        {
            field: "chartOfAccount",
            headerName: "Chart Of Account",
            width: 150,
        },
    ];

    useEffect(() => {
        let tableData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(ChartOfAccountFilesURL, {
            limit: limit,
            filter: { all: filter },
        }).then((res) => {
            // console.log(res);
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    tableData.push({
                        ...data,
                        chartOfAccount: data.chart_of_account?.account_code,
                        files: renderFileDownload(baseURL + data.path),
                    });
                });
            setRows(tableData);
        });
    }, [refreshTable, filter, limit, rows.length]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <SearchRounded />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setFilter(e.target.value);
                        dispatch(setRefresh(!refreshTable));
                    }}
                />
                <div style={{ display: "flex", gap: "2ch" }}>
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<Add />}
                        onClick={() => {
                            navigate(
                                "/View/Finance/ChartOfAccountFiles/AddChartOfAccountFiles"
                            );
                        }}
                    >
                        Add Chart Of Account Files
                    </Button>
                </div>
            </div>
            <div className="TableContainer">
                <Table
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                />
            </div>
        </div>
    );
};

export default ViewChartOfAccountFiles;
