import {
    Add,
    ChevronLeft,
    DeleteRounded,
    EditRounded,
} from "@mui/icons-material";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Paper,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { ToDoURL } from "../../../../utils/config";

import { caller, patcher } from "../../../../utils/Caller";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import { SnackBar } from "../../../../Components/SnackBar/SnackBar.component";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { deleteToDos } from "./deleteToDos";

const ViewToDos = ({ rows, setToDoModal }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [open, setOpen] = useState(false);
    const [addToDo, setAddToDo] = useState(false);
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const [page, setPage] = useState(1);
    const [msg, setMsg] = useState("");
    const [selected, setSelected] = useState({});

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        name: "",
        quantity: "",
        total_area: "",
        unit_price: "",
        quantity_type: "",
    };

    const FORM_VALIDATION = Yup.object().shape({});

    const columns = [
        {
            field: "name",
            headerName: "Name",
            width: 100,
        },
        {
            field: "unit_price",
            headerName: "Unit Rate",
            width: 300,
        },
        {
            field: "quantity",
            headerName: "Quantity",
            width: 100,
        },
        {
            field: "total_area",
            headerName: "Total Area",
            width: 100,
        },
        {
            field: "actions",
            headerName: "Actions",
            type: "actions",
            minWidth: 150,
            flex: 1,
            getActions: (cellValues) => [
                <GridActionsCellItem
                    icon={<EditRounded color="primary" />}
                    label="Edit"
                    onClick={(event) => {
                        event.stopPropagation();
                        setAddToDo({});
                        setSelected(cellValues.row);
                        setPage(2);
                    }}
                />,
                <GridActionsCellItem
                    icon={<DeleteRounded color="error" />}
                    label="Delete"
                    onClick={(event) => {
                        event.stopPropagation();
                        setSelected(cellValues.row);
                        setOpen(true);
                    }}
                />,
            ],
        },
    ];

    const formSubmitHandler = (updateData) => {
        setLoading(true);
        const update = {
            id: selected.id,
            updateData,
        };

        addToDo
            ? caller(ToDoURL, {
                  ...updateData,
                  sub_task_id: selectedRow.sub_task?.id,
              }).then((res) => {
                  setLoading(false);
                  // console.log(res.error);
                  if (res.error) {
                      Object.keys(res.error).forEach((data, i) => {
                          setMsg(res.error[data]);
                      });
                      setSuccess(false);
                      setError(true);
                  } else if (res.data.success) {
                      setSuccess(true);
                      setError(false);
                  }
              })
            : patcher(ToDoURL, update).then((res) => {
                  setLoading(false);
                  // console.log(res.error);
                  if (res.error) {
                      Object.keys(res.error).forEach((data, i) => {
                          setMsg(res.error[data]);
                      });
                      setSuccess(false);
                      setError(true);
                  } else if (res.data.success) {
                      setSuccess(true);
                      setError(false);
                  }
              });
    };

    return (
        <Paper
            className="ViewProject"
            style={{
                width: "100%",
                padding: "2ch",
                display: "flex",
                flexDirection: "column",
                gap: "2ch",
            }}
        >
            {page === 1 && (
                <>
                    <div
                        className="SearchField"
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Button
                            startIcon={<ChevronLeft />}
                            onClick={() => setToDoModal(false)}
                        >
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            endIcon={<Add />}
                            onClick={() => {
                                setAddToDo(true);
                                setSelected(null);
                                setPage(2);
                            }}
                        >
                            Add To Do
                        </Button>
                    </div>
                    <div
                        className="TableContainer"
                        style={{ minHeight: "50vh" }}
                    >
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            autoHeight={true}
                            pageSize={8}
                            rowsPerPageOptions={[8]}
                        />
                    </div>
                    <Dialog
                        open={open}
                        onClose={() => {
                            setOpen(false);
                        }}
                    >
                        <DialogTitle>Are you sure?</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                You are about to delete a todo, are you sure you
                                want to proceed?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpen(false)}>
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    deleteToDos(selected.id);
                                }}
                                color="error"
                            >
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
            {page === 2 && (
                <div style={{ minHeight: "50vh" }}>
                    <Box width={"100%"}>
                        <Button
                            startIcon={<ChevronLeft />}
                            onClick={() => {
                                setPage(1);
                            }}
                        >
                            Back
                        </Button>
                    </Box>
                    <div className="FormContainer">
                        <Formik
                            initialValues={{ ...INITIAL_VALUES }}
                            validationSchema={FORM_VALIDATION}
                            onSubmit={(values, actions) => {
                                values.project_id = selectedRow.id;

                                formSubmitHandler(values, actions);
                            }}
                        >
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextFieldWrapper
                                            value={selected?.name}
                                            onChange={(e) => {
                                                setSelected((prevSelected) => ({
                                                    ...prevSelected,
                                                    name: e.target.value,
                                                }));
                                            }}
                                            size={"small"}
                                            name="name"
                                            label="Name"
                                            variant="outlined"
                                            multiline
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextFieldWrapper
                                            value={selected?.quantity}
                                            onChange={(e) => {
                                                setSelected((prevSelected) => ({
                                                    ...prevSelected,
                                                    quantity: e.target.value,
                                                }));
                                            }}
                                            size={"small"}
                                            name="quantity"
                                            label="Quantity"
                                            variant="outlined"
                                            type="number"
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextFieldWrapper
                                            value={selected?.total_area}
                                            onChange={(e) => {
                                                setSelected((prevSelected) => ({
                                                    ...prevSelected,
                                                    total_area: e.target.value,
                                                }));
                                            }}
                                            size={"small"}
                                            name="total_area"
                                            label="Total Area"
                                            variant="outlined"
                                            type="number"
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextFieldWrapper
                                            value={selected?.quantity_type}
                                            onChange={(e) => {
                                                setSelected((prevSelected) => ({
                                                    ...prevSelected,
                                                    quantity_type:
                                                        e.target.value,
                                                }));
                                            }}
                                            size={"small"}
                                            name="quantity_type"
                                            label="Unit"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextFieldWrapper
                                            value={selected?.unit_price}
                                            onChange={(e) => {
                                                setSelected((prevSelected) => ({
                                                    ...prevSelected,
                                                    unit_price: e.target.value,
                                                }));
                                            }}
                                            size={"small"}
                                            name="unit_price"
                                            label="Unit price"
                                            variant="outlined"
                                            type="number"
                                        />
                                    </Grid>
                                </Grid>
                                <div
                                    style={{
                                        position: "relative",
                                        bottom: "-12ch",
                                        right: "60px",
                                    }}
                                >
                                    <LoadingButton
                                        loading={loading}
                                        type="submit"
                                        className="ButtonContained"
                                        style={{
                                            marginTop: "2ch",
                                            float: "right",
                                        }}
                                    >
                                        {loading ? null : "Apply"}
                                    </LoadingButton>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                    {error || success ? (
                        <SnackBar
                            error={error}
                            success={success}
                            message={error ? msg : `Success^${Math.random()}`}
                        />
                    ) : null}
                </div>
            )}
        </Paper>
    );
};

export default ViewToDos;
