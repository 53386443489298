import { LoadingButton } from "@mui/lab";
import { Box, Checkbox, FormControl, FormLabel, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import PersonAddAlt1RoundedIcon from "@mui/icons-material/PersonAddAlt1Rounded";

import TextFieldWrapper from "../../Components/TextFieldWrapper.component";
import "../../Assets/Sass/AddProject.style.scss";
import { Client } from "../../utils/config";
import { caller } from "../../utils/Caller";
import { SnackBar } from "../../Components/SnackBar/SnackBar.component";
import TitleHeader from "../../Components/TitleHeader/TitleHeader.component";

const AddClient = () => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setMsg] = useState("");

    // const phoneRegex = /^\d*$/gm;

    const INITIAL_VALUES = {
        name: "",
        tradeName: "",
        address: "",
        tel: "",
        tinNumber: "",
        contactPersonName: "",
        contactPersonPhone: "",
        contactPersonEmail: "",
        email: "",
        subCity: "",
        woreda: "",
        city: "",
        protectedEdit: false,
    };

    const FORM_VALIDATION = Yup.object().shape({
        name: Yup.string().min(4).required("*Required"),
        tradeName: Yup.string().min(4).required("*Required"),
        address: Yup.string().min(4).required("*Required"),
        tel: Yup.string()
            // .matches(phoneRegex, "Enter a valid phone number(251-123456789)")
            .required("*Required"),
        tinNumber: Yup.string().min(4).required("*Required"),
        contactPersonName: Yup.string().min(4).required("*Required"),
        contactPersonPhone: Yup.string()
            .min(4)
            // .matches(phoneRegex, "Enter a valid phone number(251-123456789)")
            .required("*Required"),
        contactPersonEmail: Yup.string()
            .email("Must be a valid email")
            .required("*Required"),
        email: Yup.string()
            .email("Must be a valid email")
            .required("*Required"),
        subCity: Yup.string().min(4).required("*Required"),
        woreda: Yup.number("Woreda must be a number").min(2).required("*Required"),
        city: Yup.string().min(4).required("*Required"),
    });

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        caller(Client, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                actions.resetForm();
                return 1;
            }
        });
    };

    return (
        <>
            <TitleHeader
                icon={<PersonAddAlt1RoundedIcon />}
                title="Add Client"
            />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        const data = {
                            ...values,
                            protectedEdit: protectedEdit,
                        };
                        setLoading(true);
                        formSubmitHandler(data, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <TextFieldWrapper
                                    name="name"
                                    label="Client Name"
                                    variant="outlined"
                                />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={12} md={4}>
                                <TextFieldWrapper
                                    name="tradeName"
                                    label="Trade Name"
                                    variant="outlined"
                                />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={12} md={4}>
                                <TextFieldWrapper
                                    name="address"
                                    label="Address"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextFieldWrapper
                                    name="tel"
                                    label="Tel."
                                    variant="outlined"
                                />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={12} md={4}>
                                <TextFieldWrapper
                                    name="tinNumber"
                                    label="Tin Number"
                                    variant="outlined"
                                />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={12} md={4}>
                                <TextFieldWrapper
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    type="email"
                                />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={12} md={4}>
                                <TextFieldWrapper
                                    name="contactPersonName"
                                    label="Contact Person Name"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <TextFieldWrapper
                                    name="contactPersonPhone"
                                    label="Contact Person Phone"
                                    variant="outlined"
                                />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={12} md={4}>
                                <TextFieldWrapper
                                    name="contactPersonEmail"
                                    label="Contact Person Email"
                                    variant="outlined"
                                    type="email"
                                />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={12} md={4}>
                                <TextFieldWrapper
                                    name="subCity"
                                    label="SubCity"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextFieldWrapper
                                    name="woreda"
                                    label="Woreda"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextFieldWrapper
                                    name="city"
                                    label="City"
                                    variant="outlined"
                                />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                type="submit"
                                loading={loading}
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
                {error || success ? (
                    <SnackBar
                        error={error}
                        success={success}
                        message={error ? msg : `Success^${Math.random()}`}
                    />
                ) : null}
            </div>
        </>
    );
};

export default AddClient;
