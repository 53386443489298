import PropTypes from "prop-types";
import {
  Button,
  Card,
  Grid,
  LinearProgress,
  List,
  ListItem,
  Modal,
  Paper,
  Typography,
  TableCell,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import {
  ProjectDetailRequestURL,
  ProjectEditRequestURL,
  ProjectRequestApproveURL,
  ProjectEditRequestApproveURL,
  PVRequestURL,
  ProjectParticipationURL,
  PVRequestApproveURL,
  ProjectAllRequestURL,
  ProjectParticipationRequestURL,
} from "../../../utils/config";
import { get, patcher } from "../../../utils/Caller";
import { ChevronLeft, PrintRounded } from "@mui/icons-material";
import { Columns } from "./ColumnsData";
import { LoadingButton } from "@mui/lab";
import { SnackBar } from "../../../Components/SnackBar/SnackBar.component";
import ManpowerOriginalPlan from "./ManpowerOriginalPlan.page";
import PaymentRequestOriginalPlan from "./PaymentRequestOriginalPlan.page";
import { useSelector, useDispatch } from "react-redux";
import { setRefresh } from "../../../Redux/Slice/refreshSlice";
import { useNavigate } from "react-router";

const APPROVAL_STATUS = ["Pending", "Approved", "Rejected", "Checked"];
const REQUEST_TYPE = [
  "Payment Request",
  "Manpower Request",
  "Store",
  "Document Request",
  "PV Request",
  "Project Edit Request",
  "Project Participation Request",
  "Payment Request For Finance",
];
const PRIORITY = ["Low", "Medium", "High"];
const FOR = ["Petty Cash Replenishment", "Project Request", "Other"];

const CardComponent = ({ numberOfReq, nameOfRequest, loading, onClick }) => {
  return (
    <Card sx={{ bgcolor: "#e1eaff" }} onClick={onClick}>
      {loading && <LinearProgress />}
      <Grid
        container
        sx={{
          p: 2,
          pb: 0,
          color: "#fff",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid item xs={12}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            flexDirection={"column"}
          >
            <Grid item>
              <Typography variant="h3" color={"#14213d"} fontWeight={"bolder"}>
                {numberOfReq}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" color={"#14213d"}>
                {nameOfRequest}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

const RequestsCard = ({ requestLength, requests }) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [requestType, setRequestType] = useState(1);
  const [column, setColumn] = useState([]);
  const [fieldName, setfieldName] = useState([]);
  const [selectedReq, setSelectedReq] = useState(null);
  const [remark, setRemark] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [msg, setMsg] = useState("");
  const [approveLoad, setApproveLoad] = useState(false);
  const [editApproveLoad, setEditApproveLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.userState.user);
  const refreshTable = useSelector((state) => state.refreshTable.init);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeModal = () => {
    setOpen(false);
  };

  const fetchData = (request_type) => {
    setLoading(true);
    if (request_type === 5) {
      let tableData = [];
      get(PVRequestURL, {
        approval_status: 1,
        limit: 100,
      }).then((res) => {
        setOpen(true);
        setLoading(false);
        res?.data?.forEach((data) => {
          tableData.push({
            ...data,
            preparePaymentTo: data.prepare_payment_to?.first_name,
            projectRequest: data.project_request?.total_amount,
            date: new Date(data.date).toDateString(),
            bankName: data.bank?.name,
            For: FOR[data.for - 1],
            additional_docs: JSON.parse(data.additional_docs),
          });
        });
        setRows(tableData);
        setPage(2);
      });
    } else {
      get(ProjectDetailRequestURL, {
        limit: 1000,
        filter: { request_type, approval_status: 1 },
      }).then((res) => {
        setLoading(false);

        setOpen(true);
        if (res?.data) {
          setRows(res?.data);
        }
        setPage(2);
      });
    }
  };

  const fetchProjectEdit = () => {
    setLoading(true);
    get(ProjectEditRequestURL, {
      limit: 1000,
    }).then((res) => {
      setLoading(false);
      setOpen(true);
      if (res?.data) {
        setRows(res?.data);
      }
      setPage(2);
    });
  };

  const fetchProjectParticipation = () => {
    setLoading(true);
    get(ProjectParticipationURL, {
      limit: 1000,
    }).then((res) => {
      setLoading(false);
      setOpen(true);
      if (res?.data) {
        setRows(res?.data);
      }
      setPage(2);
    });
  };

  const fetchFinancePaymentRequest = () => {
    setLoading(true);
    get(ProjectDetailRequestURL, {
      filter: {
        approval_status: 2,
        finance_approval_status: 1,
        request_type: 1,
      },
      limit: 100,
    }).then((res) => {
      setOpen(true);
      setLoading(false);
      res?.data && setRows(res.data);
      setPage(2);
    });
  };

  const approve = (id, approveStat) => {
    patcher(PVRequestApproveURL, {
      approval_status: approveStat,
      action_note: remark,
      id: [id],
    }).then((res) => {
      setApproveLoad(false);
      if (res.error) {
        Object.keys(res.error).forEach((data, i) => {
          setMsg(res.error[data]);
        });
        setSuccess(false);
        setError(true);
      } else if (res.data.success) {
        dispatch(setRefresh(!refreshTable));
        setSuccess(true);
        setError(false);
        closeModal();
      }
    });
  };

  const editApprove = (id, approveStat) => {
    setEditApproveLoad(true);
    patcher(
      requestType === 7
        ? ProjectParticipationRequestURL
        : ProjectEditRequestApproveURL,
      {
        approval_status: approveStat,
        id: id,
      },
    ).then((res) => {
      setEditApproveLoad(false);
      if (res.error) {
        Object.keys(res.error).forEach((data, i) => {
          setMsg(res.error[data]);
        });
        setSuccess(false);
        setError(true);
      } else if (res.data?.success) {
        dispatch(setRefresh(!refreshTable));
        setSuccess(true);
        setError(false);
        setPage(1);
        closeModal();
      }
    });
  };

  const sendApproval = (id) => {
    let approved = {
      id: id,
      approval_status: 2,
      action_note: remark,
    };
    apiHandler(approved);
  };

  const sendRejection = (id) => {
    let rejected = {
      id: id,
      approval_status: 3,
      action_note: remark,
    };
    apiHandler(rejected);
  };

  const apiHandler = (data) => {
    patcher(ProjectRequestApproveURL, { ...data }).then((res) => {
      setLoading(false);
      setApproveLoad(false);
      if (res.error) {
        Object.keys(res.error).forEach((data) => {
          setMsg(res.error[data]);
        });
        setSuccess(false);
        setError(true);
      } else if (res.data?.success) {
        setSuccess(true);
        setError(false);
        closeModal();
      }
    });
  };

  const financeSendApproval = (id) => {
    let approved = {
      id,
      approval_status: 3,
      action_note: remark,
    };
    financeApiHandler(approved);
  };

  const financeSendRejection = (id) => {
    let rejected = {
      id: id,
      approval_status: 3,
      action_note: remark,
    };
    financeApiHandler(rejected);
  };

  const financeApiHandler = (data) => {
    patcher(ProjectAllRequestURL, { ...data }).then((res) => {
      setLoading(false);
      if (res.error) {
        Object.keys(res.error).forEach((data) => {
          setMsg(res.error[data]);
        });
        setSuccess(false);
        setError(true);
      } else if (res.data?.success) {
        setSuccess(true);
        setError(false);
        closeModal();
      }
    });
  };

  useEffect(() => {
    setRemark("");
  }, []);

  return (
    <>
      <Card
        sx={{
          bgcolor: "#FFF4DD",
          "&:hover": { bgcolor: "#EEE3CC" },
          borderRadius: "15px",
        }}
        onClick={() => {
          requestLength > 0 && setOpen(true);
        }}
        elevation={0}
      >
        <Grid
          container
          sx={{
            p: 2,
            pb: 0,
            color: "#fff",
            height: window.innerHeight / 4,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid item xs={12}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              flexDirection={"column"}
            >
              <Grid item>
                <Typography
                  variant="h3"
                  color={"#14213d"}
                  fontWeight={"bolder"}
                >
                  {requestLength}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" color={"#14213d"}>
                  Requests
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <Modal className="Modal" open={open} onClose={closeModal}>
        <Box
          className="ModalContainer"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "80%",
            maxWidth: "90%",
          }}
        >
          {page === 1 && (
            <Paper
              style={{
                display: "flex",
                flexDirection: "column",
                minWidth: "100%",
                minHeight: "50vh",
                padding: "2ch",
                gap: "2ch",
              }}
            >
              {requests?.payment_request > 0 && (
                <CardComponent
                  loading={loading}
                  nameOfRequest={"Payment Request"}
                  numberOfReq={requests.payment_request}
                  onClick={() => {
                    fetchData(1);
                    setRequestType(1);
                  }}
                />
              )}
              {requests?.manpower_request > 0 && (
                <CardComponent
                  loading={loading}
                  nameOfRequest={"Manpower Request"}
                  numberOfReq={requests.manpower_request}
                  onClick={() => {
                    fetchData(2);
                    setRequestType(2);
                  }}
                />
              )}
              {requests?.store_request > 0 && (
                <CardComponent
                  loading={loading}
                  nameOfRequest={"Store Request"}
                  numberOfReq={requests.store_request}
                  onClick={() => {
                    fetchData(3);
                    setRequestType(3);
                  }}
                />
              )}
              {requests?.required_document > 0 && (
                <CardComponent
                  loading={loading}
                  nameOfRequest={"Document Request"}
                  numberOfReq={requests.required_document}
                  onClick={() => {
                    fetchData(4);
                    setRequestType(4);
                  }}
                />
              )}
              {requests?.pv_request > 0 && (
                <CardComponent
                  loading={loading}
                  nameOfRequest={"PV Request"}
                  numberOfReq={requests.pv_request}
                  onClick={() => {
                    fetchData(5);
                    setRequestType(5);
                  }}
                />
              )}
              {requests?.project_edit_request > 0 && (
                <CardComponent
                  loading={loading}
                  nameOfRequest={"Project Edit Request"}
                  numberOfReq={requests.project_edit_request}
                  onClick={() => {
                    fetchProjectEdit();
                    setRequestType(6);
                  }}
                />
              )}
              {requests?.project_participation_request > 0 && (
                <CardComponent
                  loading={loading}
                  nameOfRequest={"Project Participation Request"}
                  numberOfReq={requests.project_participation_request}
                  onClick={() => {
                    fetchProjectParticipation();
                    setRequestType(7);
                  }}
                />
              )}
              {requests?.payment_request_for_finance > 0 && (
                <CardComponent
                  loading={loading}
                  nameOfRequest={"Payment Request For Finance"}
                  numberOfReq={requests.payment_request_for_finance}
                  onClick={() => {
                    fetchFinancePaymentRequest();
                    setRequestType(8);
                  }}
                />
              )}
            </Paper>
          )}
          {page === 2 && (
            <Paper
              style={{
                height: "100%",
                width: "100%",
                padding: "5ch",
                overflow: "scroll",
              }}
            >
              <Box style={{ width: "100%", position: "sticky" }}>
                <Button
                  startIcon={<ChevronLeft />}
                  onClick={() => {
                    if (page === 2) {
                      setPage(1);
                    } else if (page === 3) {
                      setPage(2);
                    }
                  }}
                >
                  Back
                </Button>
              </Box>

              <List>
                {rows?.map((data, index) => (
                  <ListItem
                    style={{
                      display: "flex",
                      borderRadius: "5px",
                      boxShadow: "0 0 15px #e5e5e5",
                      justifyContent: "space-between",
                      padding: "2ch",
                      width: "100%",
                      cursor: "pointer",
                      marginBottom: "2ch",
                      minWidth: "300px",
                      maxHeight: "80vh",
                    }}
                    onClick={(e) => {
                      let arr = [];
                      if (data.request_type === 1) {
                        setColumn(Columns.requestPayment);
                        Columns.requestPayment.forEach((head, index) =>
                          arr.push(head.field),
                        );
                      } else if (data.request_type === 2) {
                        setColumn(Columns.manpowerRequest);
                        Columns.manpowerRequest.forEach((head, index) =>
                          arr.push(head.field),
                        );
                      } else if (data.request_type === 3) {
                        setColumn(Columns.store);
                        Columns.store.forEach((head, index) =>
                          arr.push(head.field),
                        );
                      } else if (requestType === 5) {
                        setColumn(Columns.pvRequest);
                        Columns.pvRequest.forEach((head, index) =>
                          arr.push(head.field),
                        );
                      } else if (requestType === 6) {
                        setColumn([]);
                      } else if (requestType === 8) {
                        setColumn(Columns.ProjectPaymentRequests);
                        Columns.ProjectPaymentRequests.forEach((head, index) =>
                          arr.push(head.field),
                        );
                      }
                      setfieldName(arr);
                      if (requestType !== 6 && requestType !== 7) setPage(3);
                      setSelectedReq(index);
                    }}
                  >
                    <Box>
                      <Typography fontWeight={"bold"}>
                        {REQUEST_TYPE[requestType - 1]}
                      </Typography>
                      {requestType !== 6 && requestType !== 7 && (
                        <>
                          <Typography>
                            {`${data.prepared_by?.first_name} ${data.prepared_by?.last_name}`}
                          </Typography>
                          <Typography
                            style={{
                              color:
                                data.priority === 3
                                  ? "red"
                                  : data.priority === 2
                                  ? "dodgerblue"
                                  : "green",
                              fontWeight: "bolder",
                            }}
                          >
                            {PRIORITY[data.priority - 1]}
                          </Typography>
                          <Typography>
                            {data?.creation &&
                              new Date(data?.creationDate).toDateString()}
                          </Typography>
                        </>
                      )}
                      {/* @note */}
                      {(requestType === 6 || requestType === 7) && (
                        <>
                          <Typography>{data.requester?.username}</Typography>
                          <Typography>{data.reason || data.remark}</Typography>
                          <Typography>
                            {new Date(data.requested_date).toLocaleString()}
                          </Typography>
                        </>
                      )}
                    </Box>

                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {requestType !== 6 && requestType !== 7 && (
                        <Typography
                          style={{
                            color:
                              data.approval_status === 1
                                ? "dodgerblue"
                                : data.approval_status === 2
                                ? "green"
                                : data.approval_status === 4
                                ? "warning"
                                : "red",
                            fontWeight: "bolder",
                          }}
                        >
                          {APPROVAL_STATUS[data.approval_status - 1]}
                        </Typography>
                      )}
                      {(requestType === 6 || requestType === 7) && (
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "2ch",
                          }}
                        >
                          <LoadingButton
                            loading={editApproveLoad}
                            variant="contained"
                            color="success"
                            onClick={() => {
                              editApprove(data.id, 2);
                            }}
                          >
                            Approve
                          </LoadingButton>
                          <LoadingButton
                            loading={editApproveLoad}
                            variant="outlined"
                            color="error"
                            onClick={() => {
                              editApprove(data.id, 3);
                            }}
                          >
                            Deny
                          </LoadingButton>
                        </Box>
                      )}
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Paper>
          )}
          {page === 3 && (
            <Paper
              style={{
                minWidth: "100%",
                minHeight: "50vh",
                maxHeight: "80vh",
                overflow: "scroll",
                padding: "2ch",
              }}
            >
              <Box
                className="hide-on-print"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  startIcon={<ChevronLeft />}
                  onClick={() => {
                    if (page === 2) {
                      setPage(1);
                    } else if (page === 3) {
                      setPage(2);
                    }
                  }}
                >
                  Back
                </Button>
                {requestType !== 6 && requestType !== 8 && (
                  <Button
                    onClick={() => {
                      setPage(4);
                    }}
                  >
                    {requestType === 5 ? "Attachment" : "Original Plan"}
                  </Button>
                )}
              </Box>
              <Box>
                {loading && <LinearProgress />}
                <Typography
                  style={{
                    textAlign: "center",
                    fontWidth: "bolder",
                  }}
                >
                  {REQUEST_TYPE[requestType - 1]}
                </Typography>
                {requestType === 5 && (
                  <>
                    <Table>
                      <TableHead>
                        {column?.map((head, i) => (
                          <TableCell key={i}>{head.headerName}</TableCell>
                        ))}
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          {fieldName.map((field) => (
                            <TableCell>{rows[selectedReq][field]}</TableCell>
                          ))}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </>
                )}
                {(requestType < 5 || requestType === 8) && (
                  <>
                    <Table>
                      <TableHead>
                        {column?.map((head, i) => (
                          <TableCell key={i}>{head.headerName}</TableCell>
                        ))}
                      </TableHead>
                      <TableBody>
                        {rows[selectedReq]?.individual_requests?.map(
                          (row, i) => (
                            <TableRow key={i}>
                              {fieldName.map((field) => (
                                <TableCell>{row[field]}</TableCell>
                              ))}
                            </TableRow>
                          ),
                        )}
                      </TableBody>
                    </Table>
                    <Box
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "2ch",
                        justifyContent: "right",
                        alignItems: "flex-end",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          minWidth: "30%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography fontWeight={"bolder"}>Vat</Typography>
                        <Typography>{rows[selectedReq]?.vat_amount}</Typography>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          minWidth: "30%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography fontWeight={"bolder"}>Sub Total</Typography>
                        <Typography>{rows[selectedReq]?.sub_total}</Typography>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          minWidth: "30%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography fontWeight={"bolder"}>Total</Typography>
                        <Typography>
                          {rows[selectedReq]?.total_amount}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "2ch",
                      }}
                    >
                      <Typography fontWeight={"bolder"}>
                        Prepared By -
                      </Typography>
                      <Typography>
                        {`${rows[selectedReq]?.prepared_by?.first_name} ${rows[selectedReq]?.prepared_by?.middle_name}`}
                      </Typography>
                    </Box>
                    {rows[selectedReq]?.checked_by && (
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "2ch",
                        }}
                      >
                        <Typography fontWeight={"bolder"}>
                          Checked By -
                        </Typography>
                        <Typography>
                          {`${rows[selectedReq]?.checked_by?.first_name} ${rows[selectedReq]?.checked_by?.middle_name}`}
                        </Typography>
                      </Box>
                    )}
                    {rows[selectedReq]?.approved_by && (
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "2ch",
                        }}
                      >
                        <Typography fontWeight={"bolder"}>
                          Approved By -
                        </Typography>
                        <Typography>
                          {`${rows[selectedReq]?.approved_by?.first_name} ${rows[selectedReq]?.approved_by?.middle_name}`}
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
                {requestType !== 8 && (
                  <Button
                    style={{
                      float: "right",
                      marginTop: "2ch",
                    }}
                    endIcon={<PrintRounded />}
                    onClick={() => {
                      if (requestType < 5) {
                        navigate(`/RequestPrint`, {
                          state: {
                            column,
                            rowsDetail: [rows[selectedReq]],
                            fieldName,
                          },
                        });
                      } else if (requestType === 5) {
                        navigate(`/PVRequestPrint`, {
                          state: {
                            column,
                            rowsDetail: [rows[selectedReq]],
                            fieldName,
                          },
                        });
                      }
                    }}
                  >
                    Print
                  </Button>
                )}

                <Box height={"2ch"} />
                <>
                  <TextField
                    fullWidth
                    variant="filled"
                    label={"Remark"}
                    name="action_note"
                    multiline
                    rows={3}
                    value={remark}
                    onChange={(e) => {
                      setRemark(e.target.value);
                    }}
                  />
                  <Box height={"2ch"} />
                  <Box
                    className="hide-on-print"
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      gap: "2ch",
                    }}
                  >
                    <LoadingButton
                      loading={approveLoad}
                      variant="contained"
                      color="success"
                      onClick={() => {
                        setApproveLoad(true);
                        if (requestType < 5) {
                          sendApproval(rows[selectedReq]?.id);
                        } else if (requestType === 5) {
                          approve(rows[selectedReq].id, 2);
                        } else if (requestType === 8) {
                          financeSendApproval(rows[selectedReq].id);
                        }
                      }}
                    >
                      {user.actions?.indexOf("HEAD") === -1
                        ? "Check"
                        : "Approve"}
                    </LoadingButton>
                    <LoadingButton
                      loading={approveLoad}
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setApproveLoad(true);
                        if (requestType < 5) {
                          sendRejection(rows[selectedReq]?.id);
                        } else if (requestType === 5) {
                          approve(rows[selectedReq].id, 3);
                        } else if (requestType === 8) {
                          financeSendRejection(rows[selectedReq].id);
                        }
                      }}
                    >
                      Deny
                    </LoadingButton>
                  </Box>
                </>
              </Box>
            </Paper>
          )}
          {page === 4 && (
            <Paper
              style={{
                minWidth: "100%",
                minHeight: "50vh",
                maxHeight: "80vh",
                overflow: "scroll",
                padding: "2ch",
              }}
            >
              <Box
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  position: "sticky",
                  top: "0",
                }}
              >
                <Button
                  startIcon={<ChevronLeft />}
                  onClick={() => {
                    if (page === 4) {
                      setPage(3);
                    }
                  }}
                >
                  Back
                </Button>
              </Box>

              <Box>
                {requestType === 1 && (
                  <PaymentRequestOriginalPlan
                    projectId={rows[selectedReq].project.id}
                  />
                )}
                {(requestType === 2 || requestType === 3) && (
                  <ManpowerOriginalPlan
                    projectId={rows[selectedReq].project.id}
                  />
                )}
              </Box>
            </Paper>
          )}
          {error || success ? (
            <SnackBar
              error={error}
              success={success}
              message={error ? msg : `Success^${Math.random()}`}
            />
          ) : null}
        </Box>
      </Modal>
    </>
  );
};

RequestsCard.propTypes = {
  requestLength: PropTypes.number,
  /**
   * @param {{
   * manpower_requirement: number,
   * request: number,
   * request_payment: number,
   * required_document: number,
   * required_equipment: number,
   * required_material: number}} any each request length
   */
  requests: PropTypes.any,
};

export default RequestsCard;
