import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import AddRequestForm from "./AddRequestForm.page";
import ViewRequestForm from "./ViewRequestForm.page";

const RequestForm = () => {
    const location = useLocation();
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    return (
        <>
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/ProjectRequests/AddProjectRequests` && (
                <AddRequestForm />
            )}
            {location.pathname ===
                `/View/ViewProject/${selectedRow.id}/ProjectRequests` && (
                <>
                    <ViewRequestForm />
                </>
            )}
        </>
    );
};

export default RequestForm;
