import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    init: false,
};

export const refreshSlice = createSlice({
    name: "refreshState",
    initialState,
    reducers: {
        setRefresh: (state, action) => {
            state.init = action.payload;
        },
    },
});

export const { setRefresh } = refreshSlice.actions;

export default refreshSlice.reducer;
 