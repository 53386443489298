import React from "react";
import { Card, Grid } from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

const ProjectStatistics = ({ onGoing, completed }) => {
    const data = {
        labels: ["On Going", "Completed"],
        datasets: [
            {
                label: "Project Statistics",
                data: [onGoing, completed],
                backgroundColor: [
                    "rgba(255, 99, 132, 0.8)",
                    "rgba(54, 162, 235, 0.8)",
                ],
            },
        ],
    };
    ChartJS.register(ArcElement, Tooltip, Legend);

    return (
        <Card sx={{ bgcolor: "#E8E1FF", borderRadius: "15px" }} elevation={0}>
            <Grid
                container
                sx={{
                    p: 2,
                    pb: 0,
                    color: "#fff",
                    height: window.innerHeight / 4,
                }}
            >
                <Grid item xs={12} height="90%">
                    <Doughnut
                        data={data}
                        options={{
                            // responsive: true,
                            maintainAspectRatio: false,
                        }}
                    />
                </Grid>
            </Grid>
        </Card>
    );
};

export default ProjectStatistics;
