import { SearchRounded } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router";

import Table from "../../../../Components/Table/Table.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { get } from "../../../../utils/Caller";
import { PayrollDetailURL } from "../../../../utils/config";

const PAYROLL_COMPONENT = [
    "Basic",
    "Earning",
    "Deduction",
    "Loan",
    "IncomeTax",
    "Over Time",
    "Employeer Pension",
    "Earning Adj",
    "Deduction Adj",
    "Employee Pension",
    "Lost Time",
    "Net Pay",
    "Penality",
];
const PAYROLL_POSTING_ENTRY_TYPE = [
    "Gross",
    "Income Tax",
    "Other Deduction",
    "Employeer Tax",
];

const ViewPayrollDetail = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(20);
    // const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState();
    const [Search, setSearch] = useState();

    const columns = [
        {
            field: "description",
            headerName: "Description",
            width: 150,
        },
        {
            field: "total_amount",
            headerName: "Total Amount",
            width: 150,
        },
        {
            field: "payrollComponent",
            headerName: "Payroll Component",
            width: 150,
        },
        {
            field: "isEmployerPart",
            headerName: "Is Employer Part",
            width: 150,
        },
        {
            field: "isEarning",
            headerName: "Is Earning",
            width: 150,
        },
        {
            field: "payrollPostingEntryType",
            headerName: "Payroll Posting Entry Type",
            width: 150,
        },
        {
            field: "payrollSummary",
            headerName: "Payroll Summary",
            width: 150,
        },
        {
            field: "salaryComponent",
            headerName: "Salary Component",
            width: 150,
        },
    ];

    useEffect(() => {
        let tableData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(PayrollDetailURL, {
            limit: limit,
            filter: { all: filter },
        }).then((res) => {
            // console.log(res);
            res && res.data && setloading(false);
            res &&
                res.data.forEach((data, index) => {
                    tableData.push({
                        ...data,
                        payrollSummary: data.payroll_summary?.id,
                        salaryComponent: data.salary_component?.name,
                        payrollComponent:
                            PAYROLL_COMPONENT[data.payroll_component - 1],
                        payrollPostingEntryType:
                            PAYROLL_POSTING_ENTRY_TYPE[
                                data.payroll_posting_entry_type - 1
                            ],
                    });
                });
            setRows(tableData);
        });
    }, [refreshTable, filter, limit, rows.length]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setloading(true);
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                {/* <div style={{ display: "flex", gap: "2ch" }}>
                    <Button
                        variant="contained"
                        color="success"
                        endIcon={<Add />}
                        onClick={() => {
                            navigate(
                                "/View/HCM/PayrollDetail/AddPayrollDetail"
                            );
                        }}
                    >
                        Add Payroll Detail
                    </Button>
                </div> */}
            </div>
            <div className="TableContainer">
                <Table
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                />
            </div>
        </div>
    );
};

export default ViewPayrollDetail;
