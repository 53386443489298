import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { caller, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    CurrencyURL,
    DateFormatTypeURL,
    FinancialSettingsURL,
    LanguageURL,
} from "../../../../utils/config";

const AddFinancialSettings = () => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState("");
    const [filter2, setFilter2] = useState("");
    const [filter3, setFilter3] = useState("");
    const [options1, setOptions1] = useState([]);
    const [optionsId1, setOptionsID1] = useState();
    const [optionSelect1, setOptionSelect1] = useState("");
    const [options2, setOptions2] = useState([]);
    const [optionsId2, setOptionsID2] = useState();
    const [optionSelect2, setOptionSelect2] = useState("");
    const [options3, setOptions3] = useState([]);
    const [optionsId3, setOptionsID3] = useState();
    const [optionSelect3, setOptionSelect3] = useState("");
    const [TaxID, setTaxID] = useState(1);
    const [FiscalYear, setFiscalYear] = useState(1);
    const [ReportBasics, setReportBasics] = useState(1);
    const [ClosingType, setClosingType] = useState(1);
    const [CompanyID, setCompanyID] = useState(1);
    const [IsAccCodeMandatory, setIsAccCodeMandatory] = useState(false);
    const [IsProfileTaxApplied, setIsProfileTaxApplied] = useState(false);
    const [IsLegalReserveApplied, setIsLegalReserveApplied] = useState(false);
    const [AutoGenerate, setAutoGenerate] = useState(false);
    const [EnterUniqueAccCode, setEnterUniqueAccCode] = useState(false);
    const [msg, setMsg] = useState("");

    const REPORT_BASIS = ["Accrual", "Cash", "Both"];
    const FISCAL_YEAR = [
        "January - December",
        "February - January",
        "March - February",
        "April - March",
        "May - April",
        "June - May",
        "July - June",
        "August - July",
        "September - August",
        "October - September",
        "November - October",
        "December - November",
    ];
    const CLOSING_TYPE = ["Both", "Month End", "Year End"];
    const COMPANY_ID = [
        "ACN",
        "BN",
        "CN",
        "CPR",
        "CVR",
        "DIW",
        "KT",
        "ORG",
        "SEC",
        "COMPANY_ID",
    ];
    const TAX_ID = ["ABN", "BN", "CST", "ORG", "TAX", "VST", "TAX_ID"];

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        report_basis: ReportBasics,
        fiscal_year: FiscalYear,
        closing_type: ClosingType,
        base_currency_id: null,
        timezone: "",
        company_id: CompanyID,
        tax_id: TaxID,
        tax_id_number: "",
        auto_generate_journal_preference: AutoGenerate,
        is_acc_code_mandatory: IsAccCodeMandatory,
        enter_unique_acc_code: EnterUniqueAccCode,
        is_profile_tax_applied: IsProfileTaxApplied,
        is_legal_reserve_applied: IsLegalReserveApplied,
        profit_tax: "",
        legal_reserve: "",
        company_id_number: "",
        time_format_id: null,
        language_id: null,
    };

    const FORM_VALIDATION = Yup.object().shape({
        report_basis: Yup.number().min(1).required("*Required"),
        fiscal_year: Yup.number().min(1).required("*Required"),
        closing_type: Yup.number().min(1).required("*Required"),
        timezone: Yup.string().min(1),
        company_id: Yup.number().min(1).required("*Required"),
        tax_id: Yup.number().min(1).required("*Required"),
        tax_id_number: Yup.string().min(1),
        auto_generate_journal_preference: Yup.string().min(1),
        is_acc_code_mandatory: Yup.string().min(1),
        enter_unique_acc_code: Yup.string().min(1),
        is_profile_tax_applied: Yup.string().min(1),
        is_legal_reserve_applied: Yup.string().min(1),
        profit_tax: Yup.number().min(1),
        legal_reserve: Yup.number().min(1),
        company_id_number: Yup.string().min(1),
        // time_format_id: Yup.number().min(1),
        // language_id: Yup.number().min(1),
        // base_currency_id: Yup.number().min(1).required("*Required"),
    });

    useEffect(() => {
        get(CurrencyURL, {
            limit: 10,
            filter: {
                name: filter,
                // is_base_currency: true,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.name}`
                    );
                });
            res && setOptionsID1(res.data);
            setOptions1(data);
        });

        get(DateFormatTypeURL, {
            limit: 10,
            filter: {
                date_format: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.date_format}`
                    );
                });
            res && setOptionsID2(res.data);
            setOptions2(data);
        });

        get(LanguageURL, {
            limit: 10,
            filter: {
                name: filter3,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.name}`
                    );
                });
            res && setOptionsID3(res.data);
            setOptions3(data);
        });
    }, [filter, filter2, filter3]);

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        caller(FinancialSettingsURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                actions.resetForm();
                return 1;
            }
        });
    };

    return (
        <div>
            <TitleHeader title="Add Financial Settings" icon={<AddRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        values.auto_generate_journal_preference = AutoGenerate;
                        values.enter_unique_acc_code = EnterUniqueAccCode;
                        values.is_acc_code_mandatory = IsAccCodeMandatory;
                        values.is_legal_reserve_applied = IsLegalReserveApplied;
                        values.is_profile_tax_applied = IsProfileTaxApplied;
                        values.report_basis = ReportBasics;
                        values.closing_type = ClosingType;
                        values.fiscal_year = FiscalYear;
                        values.company_id = CompanyID;
                        values.tax_id = TaxID;

                        optionsId1.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.name}` === optionSelect1
                            ) {
                                values.base_currency_id = name.id;
                            }
                        });
                        optionsId2.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.date_format}` ===
                                optionSelect2
                            ) {
                                values.time_format_id = name.id;
                            }
                        });
                        optionsId3.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.name}` === optionSelect3
                            ) {
                                values.language_id = name.id;
                            }
                        });

                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    select
                                    name="report_basis"
                                    label="Report Basis"
                                    variant="outlined"
                                    value={ReportBasics}
                                    options={REPORT_BASIS ?? " "}
                                    onChange={(e) => {
                                        setReportBasics(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    select
                                    name="fiscal_year"
                                    label="Fiscal Year"
                                    variant="outlined"
                                    value={FiscalYear}
                                    options={FISCAL_YEAR ?? " "}
                                    onChange={(e) => {
                                        setFiscalYear(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    select
                                    name="closing_type"
                                    label="Closing Type"
                                    variant="outlined"
                                    value={ClosingType}
                                    options={CLOSING_TYPE ?? " "}
                                    onChange={(e) => {
                                        setClosingType(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    loading={options1.length < 1 ? true : false}
                                    disablePortal
                                    options={options1}
                                    onChange={(event, newValue) => {
                                        setOptionSelect1(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Base Currency"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="timezone"
                                    label="Timezone"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    select
                                    name="company_id"
                                    label="Company ID"
                                    variant="outlined"
                                    value={CompanyID}
                                    options={COMPANY_ID ?? " "}
                                    onChange={(e) => {
                                        setCompanyID(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    select
                                    name="tax_id"
                                    label="Tax ID"
                                    variant="outlined"
                                    value={TaxID}
                                    options={TAX_ID ?? " "}
                                    onChange={(e) => {
                                        setTaxID(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="tax_id_number"
                                    label="Tax Id Number"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>
                                        Auto Generate Journal Preference
                                    </FormLabel>
                                    <Checkbox
                                        name="auto_generate_journal_preference"
                                        checked={AutoGenerate}
                                        onChange={(event) => {
                                            setAutoGenerate(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>Is Acc Code Mandatory</FormLabel>
                                    <Checkbox
                                        name="is_acc_code_mandatory"
                                        checked={IsAccCodeMandatory}
                                        onChange={(event) => {
                                            setIsAccCodeMandatory(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>Enter Unique Acc Code</FormLabel>
                                    <Checkbox
                                        name="enter_unique_acc_code"
                                        checked={EnterUniqueAccCode}
                                        onChange={(event) => {
                                            setEnterUniqueAccCode(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>
                                        Is Profile Tax Applied
                                    </FormLabel>
                                    <Checkbox
                                        name="is_profile_tax_applied"
                                        checked={IsProfileTaxApplied}
                                        onChange={(event) => {
                                            setIsProfileTaxApplied(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>
                                        Is Legal Reserve Applied
                                    </FormLabel>
                                    <Checkbox
                                        name="is_legal_reserve_applied"
                                        checked={IsLegalReserveApplied}
                                        onChange={(event) => {
                                            setIsLegalReserveApplied(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="profit_tax"
                                    label="Profit Tax"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="legal_reserve"
                                    label="Legal Reserve"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="company_id_number"
                                    label="Company Id Number"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    loading={options2.length < 1 ? true : false}
                                    disablePortal
                                    options={options2}
                                    onChange={(event, newValue) => {
                                        setOptionSelect2(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Time Format"
                                            onChange={(event) => {
                                                setFilter2(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    loading={options3.length < 1 ? true : false}
                                    disablePortal
                                    options={options3}
                                    onChange={(event, newValue) => {
                                        setOptionSelect3(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Language"
                                            onChange={(event) => {
                                                setFilter3(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default AddFinancialSettings;
