import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
    Stack,
    Box,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { EditRounded } from "@mui/icons-material";

import TextFieldWrapper from "../../../Components/TextFieldWrapper.component";
import "../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../../../Components/SnackBar/SnackBar.component.jsx";
import { patcher, get } from "../../../utils/Caller";
import TitleHeader from "../../../Components/TitleHeader/TitleHeader.component";
import { RoleURL, PrivilegeURL } from "../../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import { setRefresh } from "../../../Redux/Slice/refreshSlice";

const EditRole = (props) => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setMsg] = useState("");
    const [filter, setFilter] = useState("");
    const [options, setOptions] = useState([]);
    const [optionSelect, setOptionSelect] = useState("");
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.userState.user);

    useEffect(() => {
        if (optionSelect === "") {
            let value = [];
            selectedRow.privileges.forEach((privilege, index) => {
                let name = `${privilege.id} - ${privilege.action}`;
                value.push(name);
            });
            setOptionSelect(value);
        }
    }, [optionSelect, selectedRow.privileges]);

    const INITIAL_VALUES = {
        name: selectedRow.name,
        privileges: selectedRow.privileges,
        description: selectedRow.description,
        isProtectedForEdit: false,
    };

    const FORM_VALIDATION = Yup.object().shape({
        name: Yup.string().min(1).required("*Required"),
        description: Yup.string(),
    });

    useEffect(() => {
        get(PrivilegeURL, {
            limit: 10,
            filter: {
                all: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.action}`
                    );
                });
            setOptions(data);
        });
    }, [filter]);

    const formSubmitHandler = (updateData, actions) => {
        setLoading(true);
        const update = {
            id: selectedRow.id,
            updateData,
            concurrency_stamp: user.concurrency_stamp,
        };
        patcher(RoleURL, update).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                dispatch(setRefresh(!refreshTable));
                setSuccess(true);
                setError(false);
                actions.resetForm();
                return 1;
            }
        });
    };

    return (
        <div>
            <TitleHeader title="Edit  Role" icon={<EditRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        let privileges = [];
                        optionSelect.forEach((opt, index) => {
                            let privilege_id = parseInt(opt?.split("-")[0]);
                            if (typeof privilege_id === "number") {
                                privileges.push(privilege_id);
                            }
                        });

                        values.privileges = privileges;
                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="name"
                                    label="Name"
                                    variant="outlined"
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={6}>
                                <Stack spacing={3}>
                                    <Autocomplete
                                        value={optionSelect}
                                        multiple
                                        getOptionLabel={(option) =>
                                            option || ""
                                        }
                                        filterSelectedOptions
                                        loading={
                                            options.length < 1 ? true : false
                                        }
                                        options={options}
                                        onChange={(event, newValue) => {
                                            setOptionSelect(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Privileges"
                                                onChange={(event) => {
                                                    setFilter(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldWrapper
                                    name="description"
                                    label="Description"
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Apply"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default EditRole;
