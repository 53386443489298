import React, { useState, useEffect } from "react";
import { Box, Card, Typography, Divider, LinearProgress } from "@mui/material";
import {
    RequiredMaterialURL,
    RequiredEquipmentURL,
} from "../../../utils/config";
import { get } from "../../../utils/Caller";

const PaymentRequestOriginalPlan = ({ id }) => {
    const [materialOriginal, setMaterialOriginal] = useState();
    const [equipmentOriginal, setEquipmentOriginal] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        get(RequiredMaterialURL, {
            limit: 100,
            filter: { project_id: id },
        }).then((res) => {
            setLoading(false);
            if (res?.data) {
                setMaterialOriginal(res.data);
            }
        });
        get(RequiredEquipmentURL, {
            limit: 100,
            filter: { project_id: id },
        }).then((res) => {
            if (res?.data) {
                setEquipmentOriginal(res.data);
            }
        });
    }, [id]);

    const equipmentColumns = [
        {
            name: "actual_equipment_number",
            label: "Actual Equipment Number",
        },
        {
            name: "planned_equipment_number",
            label: "Planned Equipment Number",
        },
        {
            name: "equipment_lifetime",
            label: "Equipment Lifetime",
        },
        {
            name: "expense",
            label: "Expense",
        },
        {
            name: "house_rent",
            label: "House Rent",
        },
        {
            name: "transportation",
            label: "Transportation",
        },
        {
            name: "total_expense",
            label: "Total Expense",
        },
        {
            name: "equipmentName",
            label: "Equipment",
        },
        {
            name: "projectName",
            label: "Project Name",
        },
    ];

    const materialColumns = [
        {
            name: "delivered_quantity",
            label: "Delivered Quantity",
        },
        {
            name: "delivered_unit_price",
            label: "Delivered Unit Price",
        },
        {
            name: "delivered_total_amount",
            label: "Delivered Total Amount",
        },
        {
            name: "total_expense",
            label: "Total Expense",
        },
        {
            name: "remark",
            label: "Remark",
        },
        {
            name: "delivery_date",
            label: "Delivery Date",
        },
        {
            name: "planned_quantity",
            label: "Planned Quantity",
        },
        {
            name: "planned_unit_price",
            label: "Planned Unit Price",
        },
        {
            name: "planned_total_amount",
            label: "Planned Total Amount",
        },
    ];

    return (
        <Box>
            <Box>
                <Typography
                    style={{
                        fontWeight: "bolder",
                        padding: "1ch",
                    }}
                >
                    Material Original Plan
                </Typography>
                <Divider variant="middle" />
                {materialOriginal?.map((material, index) => (
                    <Card
                        key={index}
                        style={{ padding: "4ch", marginTop: "1ch" }}
                    >
                        {loading && <LinearProgress />}
                        {materialColumns.map((column, i) => (
                            <Box
                                key={i}
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography style={{ fontWeight: "bold" }}>
                                    {column.label}
                                </Typography>
                                <Typography>{material[column.name]}</Typography>
                            </Box>
                        ))}
                    </Card>
                ))}
            </Box>
            <Divider variant={"fullWidth"} />
            <Box>
                <Typography
                    style={{
                        fontWeight: "bolder",
                        padding: "1ch",
                        marginTop: "1ch",
                    }}
                >
                    Equipment Original Plan
                </Typography>
                <Divider variant="middle" />
                {equipmentOriginal?.map((equipment, index) => (
                    <Card key={index} style={{ padding: "4ch" }}>
                        {loading && <LinearProgress />}
                        {equipmentColumns.map((column, i) => (
                            <Box
                                key={i}
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography style={{ fontWeight: "bold" }}>
                                    {column.label}
                                </Typography>
                                <Typography>
                                    {equipment[column.name]}
                                </Typography>
                            </Box>
                        ))}
                    </Card>
                ))}
            </Box>
        </Box>
    );
};

export default PaymentRequestOriginalPlan;
