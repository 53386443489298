import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Paper,
  Button,
  Tab,
  Modal,
  Popover,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import EditRoadRoundedIcon from "@mui/icons-material/EditRoadRounded";

import TitleHeader from "../../../Components/TitleHeader/TitleHeader.component.jsx";
import "../../../Assets/Sass/Style.scss";
import DailyWorkLog from "./DailyWorkLog/DailyWorkLog.page.jsx";
// import InstructionGivenOnProject from "./InstructionGivenOnProject/IntructionGivenOnProject.page.jsx";
// import InvoiceTracking from "./InvoiceTracking/InvoiceTracking.page.jsx";
import RequiredEquipment from "./RequiredEquipment/RequiredEquipment.page.jsx";
import RequiredMaterial from "./RequiredMaterial/RequiredMaterial.page.jsx";
import RiskTracking from "./RiskTracking/RiskTracking.page.jsx";
import SimpleTask from "./SimpleTask/SimpleTask.page.jsx";
import ToDos from "./ToDos/ToDos.page.jsx";
import WeatherData from "./WeatherData/WeatherData.page.jsx";
import TaskManager from "./TaskManager/TaskManager.page.jsx";
import RequiredDocumentation from "./RequiredDocumentation/RequiredDocumentation.page.jsx";
import RequestPayment from "./RequestPayment/RequestPayment.page.jsx";
import GanttChart from "../../../Components/Gantt/GanttChart.component.jsx";
import { TaskManagerURL } from "../../../utils/config.js";
import { get } from "../../../utils/Caller.js";
import RequestForm from "./RequestForm/RequestForm.page.jsx";
import ProjectViewDetail from "./ProjectViewDetail.page.jsx";
import ViewRequiredMaterialCard from "./RequiredMaterial/ViewRequiredMaterialCard.page.jsx";
import ViewRequiredEquipmentCard from "./RequiredEquipment/ViewRequiredEquipmentCard.page.jsx";
import ViewRequiredManpowerCard from "./ManpowerRequirement/ViewRequiredManpowerCard.page.jsx";

const OperationalProjects = (props) => {
  const [value, setValue] = useState("0");
  const navigate = useNavigate();
  const selectedRow = useSelector((state) => state.selectedRow.selected);
  const refreshTable = useSelector((state) => state.refreshTable.init);
  const [rows, setRows] = useState([]);
  const [detailModal, setDetailModal] = useState(false);
  const [tabValue, setTabValue] = useState("1");
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === "0") {
      navigate(path[0]);
    } else if (newValue === "1") {
      navigate(path[1]);
    } else if (newValue === "2") {
      navigate(path[2]);
    } else if (newValue === "3") {
      navigate(path[3]);
    } else if (newValue === "4") {
      navigate(path[4]);
    } else if (newValue === "5") {
      navigate(path[5]);
    } else if (newValue === "6") {
      navigate(path[6]);
    } else if (newValue === "7") {
      navigate(path[7]);
    } else if (newValue === "8") {
      navigate(path[8]);
    } else if (newValue === "9") {
      navigate(path[9]);
    } else if (newValue === "10") {
      navigate(path[10]);
    } else if (newValue === "11") {
      navigate(path[11]);
    } else if (newValue === "12") {
      navigate(path[12]);
    } else if (newValue === "13") {
      navigate(path[13]);
    }
  };

  const handlePlanTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const Tabs = [
    "Daily Report",
    "Request",
    // "Invoice Tracking",
    "Project Tasks",
  ];

  const path = [
    `/View/ViewProject/${selectedRow.id}/DailyWorkLog`,
    `/View/ViewProject/${selectedRow.id}/ProjectRequests`,
    // `/View/ViewProject/${selectedRow.id}/InvoiceTracking`,
    `/View/ViewProject/${selectedRow.id}/TaskManager`,
    `/View/ViewProject/${selectedRow.id}/ManpowerRequirement`,
    `/View/ViewProject/${selectedRow.id}/Request`,
    `/View/ViewProject/${selectedRow.id}/RequiredEquipment`,
    `/View/ViewProject/${selectedRow.id}/RequiredMaterial`,
    `/View/ViewProject/${selectedRow.id}/RiskTracking`,
    `/View/ViewProject/${selectedRow.id}/SimpleTask`,
    `/View/ViewProject/${selectedRow.id}/ToDos`,
    `/View/ViewProject/${selectedRow.id}/WeatherData`,
    `/View/ViewProject/${selectedRow.id}/RequiredDocumentation`,
    `/View/ViewProject/${selectedRow.id}/RequestPayment`,
  ];
  const addPath = [
    `/View/ViewProject/${selectedRow.id}/DailyWorkLog/AddDailyWorkLog`,
    `/View/ViewProject/${selectedRow.id}/InstructionGiven/AddInstructionGiven`,
    `/View/ViewProject/${selectedRow.id}/InvoiceTracking/AddInvoiceTracking`,
    `/View/ViewProject/${selectedRow.id}/TaskManager/AddTaskManager`,
    `/View/ViewProject/${selectedRow.id}/ManpowerRequirement/AddManpowerRequirement`,
    `/View/ViewProject/${selectedRow.id}/Request/AddRequest`,
    `/View/ViewProject/${selectedRow.id}/RequiredEquipment/AddRequiredEquipment`,
    `/View/ViewProject/${selectedRow.id}/RequiredMaterial/AddRequiredMaterial`,
    `/View/ViewProject/${selectedRow.id}/RiskTracking/AddRiskTracking`,
    `/View/ViewProject/${selectedRow.id}/SimpleTask/AddSimpleTask`,
    `/View/ViewProject/${selectedRow.id}/ToDos/AddToDos`,
    `/View/ViewProject/${selectedRow.id}/WeatherData/AddWeatherData`,
    `/View/ViewProject/${selectedRow.id}/RequiredDocumentation/AddRequiredDocumentation`,
    `/View/ViewProject/${selectedRow.id}/RequestPayment/AddRequestPayment`,
  ];

  const columns = [
    { type: "string", label: "Task ID" },
    { type: "string", label: "Task Name" },
    { type: "string", label: "Resource" },
    { type: "date", label: "Start Date" },
    { type: "date", label: "End Date" },
    { type: "number", label: "Duration" },
    { type: "number", label: "Percent Complete" },
    { type: "string", label: "Dependencies" },
  ];

  useEffect(() => {
    if (
      location.pathname === `/View/ViewProject/${selectedRow.id}/DailyWorkLog`
    ) {
      setValue("0");
    } else if (
      location.pathname ===
      `/View/ViewProject/${selectedRow.id}/ProjectRequests`
    ) {
      setValue("1");
    } else if (
      location.pathname === `/View/ViewProject/${selectedRow.id}/TaskManager`
    ) {
      setValue("2");
    }
  }, [location.pathname, selectedRow.id]);

  useEffect(() => {
    let tasks = [];
    get(TaskManagerURL, {
      limit: 1000,
      filter: { project_id: selectedRow.id },
    }).then((res) => {
      // console.log(res);
      res &&
        res.data.forEach((data, index) => {
          if (data.sub_task) {
            data.sub_task.forEach((subtask) => {
              let subPrerequisite = [];
              subtask.prerequisites?.length > 0 &&
                subtask.prerequisites.forEach((prerequisite) => {
                  subPrerequisite.push(prerequisite.name);
                });
              tasks.push([
                subtask.name,
                subtask.name,
                subtask.name,
                new Date(subtask.task_start_date),
                new Date(subtask.task_end_date),
                parseInt(subtask.duration_in_days),
                parseInt(subtask.progress),
                String(subPrerequisite),
              ]);
            });
          }
        });
      setRows(tasks);
    });
  }, [refreshTable, rows.length, selectedRow]);

  return (
    <>
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        gap={"2ch"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <TitleHeader
          icon={<EditRoadRoundedIcon />}
          title={selectedRow.name}
          style={{ width: "100%" }}
        />

        <Button variant="contained" onClick={handleClick}>
          More
        </Button>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handleClose}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate("/BOQ", {
                    state: {
                      selectedRow,
                    },
                  });
                }}
              >
                <ListItemText primary="BOQ" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  setDetailModal(true);
                }}
              >
                <ListItemText primary="Project Detail" />
              </ListItemButton>
            </ListItem>
          </List>
        </Popover>
      </Box>

      <Modal
        className="Modal"
        open={detailModal}
        onClose={() => {
          setDetailModal(false);
        }}
      >
        <Paper
          className="ModalContainer"
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "90%",
            width: "70%",
            height: "80vh",
            overflow: "scroll",
          }}
        >
          <TabContext value={tabValue}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                position: "sticky",
                top: 0,
                zIndex: 100,
                backgroundColor: "white",
              }}
            >
              <TabList onChange={handlePlanTabChange}>
                <Tab label="Project" value="1" />
                <Tab label="Material Requirement" value="2" />
                <Tab label="Equipment Requirement" value="3" />
                <Tab label="Manpower Requirement" value="4" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <ProjectViewDetail />
            </TabPanel>
            <TabPanel value="2">
              <ViewRequiredMaterialCard />
            </TabPanel>
            <TabPanel value="3">
              <ViewRequiredEquipmentCard />
            </TabPanel>
            <TabPanel value="4">
              <ViewRequiredManpowerCard />
            </TabPanel>
          </TabContext>
        </Paper>
      </Modal>

      <div className="TabContainer">
        <div
          className={"resize-drag"}
          style={{ borderRight: "solid 5px gray" }}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1 }}>
              <TabList onChange={handleTabChange} variant="scrollable">
                {Tabs.map((tab, index) => {
                  return (
                    <Tab
                      wrapped
                      key={index}
                      label={tab}
                      value={String(index)}
                      style={{
                        width: "6ch",
                        color: "#0a1128",
                      }}
                    />
                  );
                })}
              </TabList>
            </Box>
            <TabPanel value="0">
              <DailyWorkLog
                next={addPath[1]}
                name={`Add ${Tabs[1]}?`}
                handleTabChange={handleTabChange}
              />
            </TabPanel>
            <TabPanel value="1">
              <RequestForm />
              {/* <IconButton
                                onClick={() => {
                                    navigate(path[1]);
                                }}
                            >
                                <HomeRounded />
                            </IconButton>
                            {location.pathname === path[1] && (
                                <Paper
                                    elevation={0}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyItems: "flex-start",
                                    }}
                                >
                                    <List>
                                        {Tabs2.map((tab, index) => (
                                            <ListItemButton
                                                style={{
                                                    justifyItems:
                                                        "space-between",
                                                    borderBottom:
                                                        "solid 1px gray",
                                                }}
                                                onClick={() => {
                                                    navigate(PathTabs2[index]);
                                                }}
                                            >
                                                {tab}
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Paper>
                            )}
                            {(location.pathname === PathTabs2[0] ||
                                location.pathname === addPath2[0]) && (
                                <ManpowerRequirement />
                            )}
                            {/* {(location.pathname === PathTabs2[1] ||
                                location.pathname === addPath2[1]) && (
                                <Request />
                            )} */}
              {/* {(location.pathname === PathTabs2[1] ||
                                location.pathname === addPath2[1]) && (
                                <RequestForm />
                            )}
                            {(location.pathname === PathTabs2[2] ||
                                location.pathname === addPath2[2]) && (
                                <RequiredEquipment />
                            )}
                            {(location.pathname === PathTabs2[3] ||
                                location.pathname === addPath2[3]) && (
                                <RequiredMaterial />
                            )}
                            {(location.pathname === PathTabs2[4] ||
                                location.pathname === addPath2[4]) && (
                                <RequiredDocumentation />
                            )}
                            {(location.pathname === PathTabs2[5] ||
                                location.pathname === addPath2[5]) && (
                                <RequestPayment />
                            )}  */}
            </TabPanel>
            {/* <TabPanel value="3">
                            <InvoiceTracking
                                next={addPath[3]}
                                name={`Add ${Tabs[3]}?`}
                                handleTabChange={handleTabChange}
                            />
                        </TabPanel> */}
            <TabPanel value="2">
              {/* <ManpowerRequirement
                                next={addPath[4]}
                                name={`Add ${Tabs[4]}?`}
                                handleTabChange={handleTabChange}
                            /> */}
              <TaskManager
                next={addPath[10]}
                name={`Add ${Tabs[10]}?`}
                handleTabChange={handleTabChange}
              />
            </TabPanel>
            <TabPanel value="4">
              <RequestForm />
            </TabPanel>
            <TabPanel value="5">
              <RequiredEquipment
                next={addPath[6]}
                name={`Add ${Tabs[6]}?`}
                handleTabChange={handleTabChange}
              />
            </TabPanel>
            <TabPanel value="6">
              <RequiredMaterial
                next={addPath[7]}
                name={`Add ${Tabs[7]}?`}
                handleTabChange={handleTabChange}
              />
            </TabPanel>
            <TabPanel value="7">
              <RiskTracking
                next={addPath[8]}
                name={`Add ${Tabs[8]}?`}
                handleTabChange={handleTabChange}
              />
            </TabPanel>
            <TabPanel value="8">
              <SimpleTask
                next={addPath[9]}
                name={`Add ${Tabs[9]}?`}
                handleTabChange={handleTabChange}
              />
            </TabPanel>
            <TabPanel value="9">
              <TaskManager
                next={addPath[10]}
                name={`Add ${Tabs[10]}?`}
                handleTabChange={handleTabChange}
              />
            </TabPanel>
            <TabPanel value="10">
              <ToDos
                next={addPath[11]}
                name={`Add ${Tabs[11]}?`}
                handleTabChange={handleTabChange}
              />
            </TabPanel>
            <TabPanel value="11">
              <WeatherData
                next={addPath[12]}
                name={`Add ${Tabs[12]}?`}
                handleTabChange={handleTabChange}
              />
            </TabPanel>
            <TabPanel value="12">
              <RequiredDocumentation
                next={addPath[13]}
                name={`Add ${Tabs[13]}?`}
                handleTabChange={handleTabChange}
              />
            </TabPanel>
            <TabPanel value="13">
              <RequestPayment />
            </TabPanel>
          </TabContext>
        </div>
        <div>
          {columns && rows && <GanttChart columns={columns} rows={rows} />}
        </div>
      </div>
    </>
  );
};

export default OperationalProjects;
