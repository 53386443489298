import PropTypes from "prop-types";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";
import { Add, Cancel } from "@mui/icons-material";
import { Divider, IconButton } from "@mui/material";

export default function HorizontalStepper({
    addContactPerson,
    cancelContactPerson,
    contactAddress,
    contactPerson,
    contactPersonsLength,
    loading,
    otherDetails,
    steps,
    buttonLabel,
}) {
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    // const [contactPersonsLength, setContactPersonsLength] = useState([0]);
    // const [contactForms, setContactForms] = useState([]);
    // let a = [];

    const isStepOptional = (step) => {
        return null;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const addButton = () => {
        return (
            <div>
                <LoadingButton
                    data-testid="AddButton"
                    loading={loading}
                    type="submit"
                    className="ButtonContained"
                    style={{
                        marginTop: "2ch",
                        float: "right",
                    }}
                >
                    {loading ? null : buttonLabel}
                </LoadingButton>
            </div>
        );
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }
 
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const RenderContactPerson = ({ i }) => {
        return (
            <Box>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography fontWeight={600}>
                        Contact Person {i + 1}
                    </Typography>
                    <IconButton
                        onClick={() => {
                            cancelContactPerson(i);
                        }}
                    >
                        <Cancel color="error" />
                    </IconButton>
                </div>
                <Divider variant="fullWidth" />
                <Box minHeight={"2ch"} />
                {contactPerson(i)}
            </Box>
        );
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            <React.Fragment>
                <Box
                    style={{
                        margin: "2ch 0ch 0ch 2ch",
                    }}
                >
                    {activeStep === 0 && otherDetails()}
                    {activeStep === 1 && contactAddress()}
                    {activeStep === 2 &&
                        contactPersonsLength.map((i, index) => {
                            return <RenderContactPerson i={i} />;
                        })}
                    {activeStep === 2 && (
                        <Button
                            endIcon={<Add />}
                            onClick={() => {
                                addContactPerson();
                            }}
                            style={{ margin: "2ch" }}
                        >
                            Add Another Contact Person
                        </Button>
                    )}
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    {isStepOptional(activeStep) && (
                        <Button
                            color="inherit"
                            onClick={handleSkip}
                            sx={{ mr: 1 }}
                        >
                            Skip
                        </Button>
                    )}
                    {activeStep < steps.length - 1 && (
                        <Button onClick={handleNext}>Next</Button>
                    )}
                    {activeStep === steps.length - 1 && addButton()}
                </Box>
            </React.Fragment>
        </Box>
    );
}

HorizontalStepper.propTypes = {
    addContactPerson: PropTypes.func,
    buttonLabel: PropTypes.string.isRequired,
    cancelContactPerson: PropTypes.func,
    contactAddress: PropTypes.func.isRequired,
    contactPerson: PropTypes.func.isRequired,
    contactPersonsLength: PropTypes.arrayOf(PropTypes.number).isRequired,
    loading: PropTypes.bool,
    otherDetails: PropTypes.func.isRequired,
    steps: PropTypes.arrayOf(PropTypes.string).isRequired,
};
