import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import AddRequiredMaterial from "./AddRequiredMaterial.page";
import ViewRequiredMaterial from "./ViewRequiredMaterial.page";

const RequiredMaterial = ({ next, name, handleTabChange }) => {
    const location = useLocation();
    const selectedRow = useSelector((state) => state.selectedRow.selected);

    return (
        <>
            {(location.pathname ===
                `/View/ViewProject/ProjectAddDetail/AddProjectTask` ||
                location.pathname ===
                    `/View/ViewProject/${selectedRow.id}/RequiredMaterial/AddRequiredMaterial`) && (
                <AddRequiredMaterial
                    next={next}
                    name={name}
                    handleTabChange={handleTabChange}
                />
            )}
            {(location.pathname ===
                `/View/ViewProject/ProjectAddDetail/AddProjectTask/RequiredMaterial` ||
                location.pathname ===
                    `/View/ViewProject/${selectedRow.id}/RequiredMaterial`) && (
                <>
                    <ViewRequiredMaterial />
                </>
            )}
        </>
    );
};

export default RequiredMaterial;
