import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { RefreshURL } from "./config";

let refreshToken, response;
// export const baseURL = `https://erpback.elhadar.com/api`;
// export const hostURL = `https://erpback.elhadar.com/`;

// export const baseURL = `http://aac8-197-156-107-205.ngrok.io/api`;
// export const hostURL = `http://aac8-197-156-107-205.ngrok.io/`;

export const baseURL = `https://erp-backend.onrender.com/api`;
export const hostURL = `https://erp-backend.onrender.com/`;

// export const baseURL = `http://192.168.1.67:3000/api`;
// export const hostURL = `http://192.168.1.67:3000/`;

// export const baseURL = `http://192.168.8.103:3000/api`;
// export const hostURL = `http://192.168.8.103:3000/`;

try {
    refreshToken = localStorage.getItem("refreshToken");
} catch (error) {
    console.error(error);
}

// =============================|| Axios ||================================

let headers = {
    "access-control-allow-origin": "*",
    "content-type": "application/json; charset=utf-8",
    Authorization: `bearer ${localStorage.getItem("accessToken")}`,
};

//=========================================================================

const axiosInstance = axios.create({
    baseURL,
    headers,
});

/* Checking if the token is expired and if it is, it is sending a request to the server to get a new
token. */
axiosInstance.interceptors.request.use(async (req) => {
    const user = jwt_decode(localStorage.getItem("accessToken"));
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
    // console.log(isExpired);
    if (isExpired) {
        console.log("Refreshing...");
        axios.post(baseURL + RefreshURL, { refreshToken }).then((res) => {
            localStorage.setItem("accessToken", res.data?.accessToken);
            window.location.reload();
        });
    }
    return req;
});

/**
 *
 * @param {string} url URL String
 * @param {object} body Object of data to be sent
 * @returns data or error
 */
export const logInPost = async (url, body) => {
    // console.log(url);
    await axios
        .post(baseURL + url, body)
        .then((res) => {
            response = res;
        })
        .catch((err) => {
            if (err.response?.status === 500) {
                window.open(window.location.origin + "/500", "_self");
            }
            if (err.response) {
                response = err.response.data;
                // console.log(err.response.data.error);
            } else {
                console.log("Connection Error -- " + err);
            }
        });
    return response;
};
/**
 *
 * @param {string} url URL String
 * @param {object} body Object of data to be sent
 * @returns data or error
 */
export const caller = async (url, body) => {
    // console.log(body);
    await axiosInstance
        .post(url, body)
        .then((res) => {
            response = res;
        })
        .catch((err) => {
            if (err.response?.status === 500) {
                window.open(window.location.origin + "/500", "_self");
            }
            if (err.response) {
                response = err.response.data;
                // console.log(err.response.data.error);
            } else {
                console.log("Connection Error -- " + err);
            }
        });
    return response;
};
/**
 *
 * @param {string} url URL String
 * @param {object} body Object of data to be sent
 * @returns data or error
 */
export const putter = async (url, body) => {
    // console.log(body);
    await axiosInstance
        .put(url, body)
        .then((res) => {
            response = res;
        })
        .catch((err) => {
            if (err.response?.status === 500) {
                window.open(window.location.origin + "/500", "_self");
            }
            if (err.response) {
                response = err.response.data;
                // console.log(err.response.data.error);
            } else {
                console.log("Connection Error -- " + err);
            }
        });
    return response;
};
/**
 *
 * @param {string} url URL String
 * @param {object} body Object of data to be sent
 * @returns data or error
 */
export const fileCaller = async (url, body) => {
    // console.log(body);
    await axios
        .post(url, body, {
            baseURL,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `bearer ${localStorage.getItem("accessToken")}`,
            },
        })
        .then((res) => {
            response = res;
        })
        .catch((err) => {
            if (err.response?.status === 500) {
                window.open(window.location.origin + "/500", "_self");
            }
            if (err.response) {
                response = err.response.data;
                // console.log(err.response.data.error);
            } else {
                console.log("Connection Error -- " + err);
            }
        });
    return response;
};
/**
 *
 * @param {string} url URL String
 * @param {object} body Object of data to be sent
 * @returns data or error
 */
export const filePatcher = async (url, body) => {
    // console.log(body);
    await axios
        .patch(url, body, {
            baseURL,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `bearer ${localStorage.getItem("accessToken")}`,
            },
        })
        .then((res) => {
            response = res;
        })
        .catch((err) => {
            if (err.response?.status === 500) {
                window.open(window.location.origin + "/500", "_self");
            }
            if (err.response) {
                response = err.response.data;
                // console.log(err.response.data.error);
            } else {
                console.log("Connection Error -- " + err);
            }
        });
    return response;
};
/**
 *
 * @param {string} url URL String
 * @param {object} body Object of data to be sent
 * @returns data or error
 */
export const get = async (url, body) => {
    // console.log(body);
    response = null;
    const a = JSON.stringify(body);
    await axiosInstance
        .get(url, { params: { body: a } })
        .then((res) => {
            response = res;
        })
        .catch((err) => {
            if (err.response?.status === 500) {
                window.open(window.location.origin + "/500", "_self");
            }
            console.log("Connection Error -- " + err);
        });
    return response;
};

/**
 *
 * @param {string} url URL String
 * @param {object} body Object of data to be sent
 * @returns data or error
 */
export const patcher = async (url, body) => {
    await axiosInstance
        .patch(url, body)
        .then((res) => {
            response = res;
        })
        .catch((err) => {
            if (err.response?.status === 500) {
                window.open(window.location.origin + "/500", "_self");
            }
            if (err.response) {
                response = err.response.data;
                // console.log(err.response.data.error);
            } else {
                console.log("Connection Error -- " + err);
            }
        });
    return response;
};

/**
 *
 * @param {string} url URL String
 * @param {object} body Object of data to be sent
 * @returns data or error
 */
export const deleter = async (url, body) => {
    await axiosInstance
        .delete(url, { data: body })
        .then((res) => {
            response = res;
        })
        .catch((err) => {
            if (err.response?.status === 500) {
                window.open(window.location.origin + "/500", "_self");
            }
            if (err.response) {
                response = err.response.data;
                console.log(err.response.data.error);
            } else {
                console.log("Connection Error -- " + err);
            }
        });
    return response;
};
