import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddRounded } from "@mui/icons-material";

import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../.././../../Components/SnackBar/SnackBar.component.jsx";
import { caller, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    BusinessUnitURL,
    EmployeeActionURL,
    EmployeeGroupURL,
    JobTitleURL,
    LocationURL,
    OrgAssignmentURL,
} from "../../../../utils/config";

const AddOrgAssignment = () => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState("");
    const [filter2, setFilter2] = useState("");
    const [filter3, setFilter3] = useState("");
    const [filter4, setFilter4] = useState("");
    const [filter5, setFilter5] = useState("");
    const [options, setOptions] = useState([]);
    const [optionsId, setOptionsID] = useState();
    const [optionSelect, setOptionSelect] = useState("");
    const [options2, setOptions2] = useState([]);
    const [optionsId2, setOptionsID2] = useState();
    const [optionSelect2, setOptionSelect2] = useState("");
    const [options3, setOptions3] = useState([]);
    const [optionsId3, setOptionsID3] = useState();
    const [optionSelect3, setOptionSelect3] = useState("");
    const [options4, setOptions4] = useState([]);
    const [optionsId4, setOptionsID4] = useState();
    const [optionSelect4, setOptionSelect4] = useState("");
    const [options5, setOptions5] = useState([]);
    const [optionsId5, setOptionsID5] = useState();
    const [optionSelect5, setOptionSelect5] = useState("");
    const [msg, setMsg] = useState("");

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        employee_group_id: null,
        location_id: null,
        employee_action_id: null,
        job_title_id: null,
        business_unit_id: null,
    };

    const FORM_VALIDATION = Yup.object().shape({
        // employee_group_id: Yup.number(),
        // location_id: Yup.number(),
        // employee_action_id: Yup.number(),
        // job_title_id: Yup.number(),
        // business_unit_id: Yup.number(),
    });
    useEffect(() => {
        get(EmployeeGroupURL, {
            limit: 10,
            filter: {
                group_description: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.group_description}`
                    );
                });
            res && setOptionsID(res.data);
            setOptions(data);
        });

        get(LocationURL, {
            limit: 10,
            filter: {
                location_name: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.location_name}`
                    );
                });
            res && setOptionsID2(res.data);
            setOptions2(data);
        });

        get(EmployeeActionURL, {
            limit: 10,
            filter: {
                id: filter3,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.id}`
                    );
                });
            res && setOptionsID3(res.data);
            setOptions3(data);
        });

        get(JobTitleURL, {
            limit: 10,
            filter: {
                title_name: filter4,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.title_name}`
                    );
                });
            res && setOptionsID4(res.data);
            setOptions4(data);
        });

        get(BusinessUnitURL, {
            limit: 10,
            filter: {
                name: filter5,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.name}`
                    );
                });
            res && setOptionsID5(res.data);
            setOptions5(data);
        });
    }, [filter, filter2, filter3, filter4, filter5]);

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        caller(OrgAssignmentURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                setSuccess(true);
                setError(false);
                actions.resetForm();
                return 1;
            }
        });
    };

    return (
        <div>
            <TitleHeader title="Add  Org Assignment" icon={<AddRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        optionsId.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.group_description}` ===
                                optionSelect
                            ) {
                                values.employee_group_id = name.id;
                            }
                        });
                        optionsId2.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.location_name}` ===
                                optionSelect2
                            ) {
                                values.location_id = name.id;
                            }
                        });
                        optionsId3.forEach((name, index) => {
                            if (`${name?.id} - ${name.id}` === optionSelect3) {
                                values.employee_action_id = name.id;
                            }
                        });
                        optionsId4.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.title_name}` ===
                                optionSelect4
                            ) {
                                values.job_title_id = name.id;
                            }
                        });
                        optionsId5.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.name}` === optionSelect5
                            ) {
                                values.business_unit_id = name.id;
                            }
                        });

                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Autocomplete
                                    loading={options.length < 1 ? true : false}
                                    disablePortal
                                    options={options}
                                    onChange={(event, newValue) => {
                                        setOptionSelect(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Employee Group"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    loading={options2.length < 1 ? true : false}
                                    disablePortal
                                    options={options2}
                                    onChange={(event, newValue) => {
                                        setOptionSelect2(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Location"
                                            onChange={(event) => {
                                                setFilter2(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    loading={options3.length < 1 ? true : false}
                                    disablePortal
                                    options={options3}
                                    onChange={(event, newValue) => {
                                        setOptionSelect3(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Employee Action"
                                            onChange={(event) => {
                                                setFilter3(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    loading={options4.length < 1 ? true : false}
                                    disablePortal
                                    options={options4}
                                    onChange={(event, newValue) => {
                                        setOptionSelect4(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Job Title"
                                            onChange={(event) => {
                                                setFilter4(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    loading={options5.length < 1 ? true : false}
                                    disablePortal
                                    options={options5}
                                    onChange={(event, newValue) => {
                                        setOptionSelect5(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Business Unit"
                                            onChange={(event) => {
                                                setFilter5(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Add"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default AddOrgAssignment;
