import React, { useEffect, useState } from "react";
import { BiExport } from "react-icons/bi";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
    Autocomplete,
    Grid,
    TextField,
    Paper,
    Button,
    Typography,
    Divider,
    IconButton,
    FormControl,
    FormLabel,
} from "@mui/material";
import { AddRounded, ArrowBackIos } from "@mui/icons-material";

import { SnackBar } from "../../../../Components/SnackBar/SnackBar.component";
import {
    TemplateURL,
    GeneralExportURL,
    AccountCategoryURL,
    ColumnsURL,
} from "../../../../utils/config";
import { get, caller } from "../../../../utils/Caller";
import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";
import AddGeneralExportTemplate from "./AddGeneralExportTemplate.page";

const GeneralExport = ({ type }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter1, setFilter1] = useState("");
    const [options1, setOptions1] = useState([]);
    const [optionSelect1, setOptionSelect1] = useState("");
    const [filter2, setFilter2] = useState("");
    const [options2, setOptions2] = useState([]);
    const [optionSelect2, setOptionSelect2] = useState("");
    const [ExportAs, setExportAs] = useState(1);
    const [msg, setMsg] = useState("");
    const [page, setPage] = useState(1);
    const [accountStatus, setAccountStatus] = useState(1);
    const [journalPostingStatus, setJournalPostingStatus] = useState(1);
    const [periodFilter, setPeriodFilter] = useState(1);
    const [sortBy, setSortBy] = useState();
    const [sortByOption, setSortByOption] = useState([]);
    const [order, setOrder] = useState(1);

    const EXPORT_AS = ["xlsx", "csv"];
    const ACCOUNT_STATUS = ["Inactive", "Active"];
    const JOURNAL_POSTING_STATUS = ["POSTED", "DRAFT", "SCHEDULED", "VOID"];
    const PERIOD_FILTER = [
        "Today",
        "This Week",
        "This Month",
        "This Quarter",
        "This Year",
    ];

    const INITIAL_VALUES = {
        template_id: null,
        from: "",
        to: "",
        account_category_name: "",
        account_status: accountStatus,
        journal_posting_status: journalPostingStatus,
        period_filter: periodFilter,
        exportAs: "",
    };

    const FORM_VALIDATION = Yup.object().shape({
        from: Yup.string(),
        to: Yup.string(),
    });

    useEffect(() => {
        get(TemplateURL, {
            limit: 10,
            filter: {
                all: filter1,
                module_name: type,
            },
        }).then((res) => {
            let data = [];
            res &&
                res?.data?.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.template_name}`
                    );
                });
            setOptions1(data);
        });
        get(AccountCategoryURL, {
            limit: 10,
            filter: {
                all: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res?.data?.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.name}`
                    );
                });
            setOptions2(data);
        });
    }, [filter1, filter2, type]);

    const toSentence = (str) => {
        const toCamel = (s) => {
            return s.replace(/([-_][a-z])/gi, ($1) => {
                return $1.toUpperCase().replace("-", "").replace("_", "");
            });
        };

        let text = toCamel(str);
        let result = text.replace(/([A-Z])/g, " $1");
        let finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult;
    };

    useEffect(() => {
        let availColumns = [];
        get(ColumnsURL, { module_name: type })
            .then((res) => {
                // console.log(res);
                res.data?.columns.forEach((column, index) => {
                    let title = toSentence(column);
                    availColumns.push(title);
                });
                res && setSortByOption(availColumns);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [type]);

    const formSubmitHandler = (data, actions) => {
        caller(GeneralExportURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data) {
                if (ExportAs === 1) {
                    toExcel(res.data);
                } else if (ExportAs === 2) {
                    toCSV(res.data);
                }
                setSuccess(true);
                setError(false);
            }
        });
    };

    const toExcel = (pdfData) => {
        const strPdf = pdfData;
        const buff = Buffer.from(strPdf);
        const excelFile = URL.createObjectURL(
            new Blob([buff], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64",
            })
        );
        window.open(excelFile, "_blank");
    };
    const toCSV = (pdfData) => {
        const strPdf = pdfData;
        const buff = Buffer.from(strPdf);
        const excelFile = URL.createObjectURL(
            new Blob([buff], {
                type: "text/csv;charset=utf-8",
            })
        );
        window.open(excelFile, "_blank");
    };

    return (
        <Paper style={{ padding: "5ch", minWidth: window.innerWidth / 2 }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "2ch 1ch 2ch 1ch",
                    gap: "2ch",
                    maxWidth: window.innerWidth * (3 / 4),
                    maxHeight: "70vh",
                }}
            >
                {page === 2 && (
                    <IconButton
                        onClick={() => {
                            setPage(1);
                        }}
                    >
                        <ArrowBackIos />
                    </IconButton>
                )}

                <BiExport />
                <Typography>General Export</Typography>
            </div>
            <Divider variant="fullWidth" />
            <Box height={"2ch"} />
            {page === 1 && (
                <div className="TableContainer">
                    <Formik
                        initialValues={{ ...INITIAL_VALUES }}
                        validationSchema={FORM_VALIDATION}
                        onSubmit={(values, actions) => {
                            values.template_id = parseInt(
                                optionSelect1.split("-")[0]
                            );
                            values.exportAs = EXPORT_AS[ExportAs - 1];
                            if (type === 1) {
                                values.account_category_name = optionSelect2
                                    .split("-")[1]
                                    .trim();
                                if (accountStatus === 1)
                                    values.account_status = accountStatus;
                            }
                            if (type === 31) {
                                values.journal_posting_status =
                                    journalPostingStatus;
                                values.period_filter = periodFilter;
                            }
                            formSubmitHandler(values, actions);
                        }}
                    >
                        <Form>
                            <Grid
                                container
                                spacing={2}
                                style={{ alignItems: "end" }}
                            >
                                {type !== 32 && (
                                    <>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <FormLabel>From Date</FormLabel>
                                                <TextFieldWrapper
                                                    size="small"
                                                    name="from"
                                                    type="date"
                                                    variant="outlined"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <FormLabel>To Date</FormLabel>
                                                <TextFieldWrapper
                                                    size="small"
                                                    name="to"
                                                    type="date"
                                                    variant="outlined"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </>
                                )}
                                {type === 32 && (
                                    <>
                                        <Grid item xs={6}>
                                            <TextFieldWrapper
                                                size="small"
                                                select
                                                name="sort_by"
                                                label="Sort Field"
                                                variant="outlined"
                                                value={sortBy}
                                                options={sortByOption ?? " "}
                                                onChange={(e) => {
                                                    setSortBy(e.target.value);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextFieldWrapper
                                                size="small"
                                                select
                                                name="order"
                                                label="Order"
                                                variant="outlined"
                                                value={order}
                                                options={[
                                                    "Ascending",
                                                    "Descending",
                                                ]}
                                                onChange={(e) => {
                                                    setOrder(e.target.value);
                                                }}
                                            />
                                        </Grid>
                                    </>
                                )}

                                <Grid item xs={6}>
                                    <Autocomplete
                                        size="small"
                                        loading={
                                            options1.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options1}
                                        onChange={(event, newValue) => {
                                            setOptionSelect1(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                size={"small"}
                                                {...params}
                                                label="Select Template"
                                                onChange={(event) => {
                                                    setFilter1(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Button
                                    style={{
                                        marginLeft: "2ch",
                                    }}
                                    endIcon={<AddRounded />}
                                    onClick={() => {
                                        setPage(2);
                                    }}
                                >
                                    Add New
                                </Button>
                                {type === 1 && (
                                    <>
                                        <Box width={"100%"} />
                                        <Grid item xs={6}>
                                            <TextFieldWrapper
                                                size="small"
                                                select
                                                name="account_status"
                                                label="Account Status"
                                                variant="outlined"
                                                value={accountStatus}
                                                options={ACCOUNT_STATUS ?? " "}
                                                onChange={(e) => {
                                                    setAccountStatus(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        {accountStatus === 2 && (
                                            <Grid item xs={6}>
                                                <Autocomplete
                                                    size="small"
                                                    loading={
                                                        options2.length < 1
                                                            ? true
                                                            : false
                                                    }
                                                    disablePortal
                                                    options={options2}
                                                    onChange={(
                                                        event,
                                                        newValue
                                                    ) => {
                                                        setOptionSelect2(
                                                            newValue
                                                        );
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            size={"small"}
                                                            {...params}
                                                            label="Account Category"
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                setFilter2(
                                                                    event.target
                                                                        .value
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        )}
                                    </>
                                )}
                                {type === 31 && (
                                    <>
                                        <Box width={"100%"} />
                                        <Grid item xs={6}>
                                            <TextFieldWrapper
                                                size="small"
                                                select
                                                name="journal_posting_status"
                                                label="Journal Posting Status"
                                                variant="outlined"
                                                value={journalPostingStatus}
                                                options={
                                                    JOURNAL_POSTING_STATUS ??
                                                    " "
                                                }
                                                onChange={(e) => {
                                                    setJournalPostingStatus(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextFieldWrapper
                                                size="small"
                                                select
                                                name="period_filter"
                                                label="Period Filter"
                                                variant="outlined"
                                                value={periodFilter}
                                                options={PERIOD_FILTER ?? " "}
                                                onChange={(e) => {
                                                    setPeriodFilter(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    </>
                                )}

                                <Box width={"100%"} />
                                <Grid item xs={3}>
                                    <TextFieldWrapper
                                        select
                                        size="small"
                                        name="exportAs"
                                        label="Export As"
                                        variant="filled"
                                        value={ExportAs}
                                        options={EXPORT_AS ?? " "}
                                        onChange={(e) => {
                                            setExportAs(e.target.value);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <div>
                                <LoadingButton
                                    data-testid="AddButton"
                                    loading={loading}
                                    type="submit"
                                    className="ButtonContained"
                                    style={{
                                        marginTop: "2ch",
                                        float: "right",
                                    }}
                                >
                                    {loading ? null : "Export"}
                                </LoadingButton>
                            </div>
                        </Form>
                    </Formik>
                </div>
            )}
            {page === 2 && <AddGeneralExportTemplate type={type} />}

            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </Paper>
    );
};

export default GeneralExport;
