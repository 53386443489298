import "./Assets/Sass/Style.scss";
import { Routes, Route } from "react-router-dom";
import { createTheme, Paper, ThemeProvider } from "@mui/material";
import jwt_decode from "jwt-decode";
import { useLocation } from "react-router";

import LogInPage from "./Pages/Account/LogIn/Login.page";
import MainScene from "./Layouts/MainScene/MainScene.layout";
import Dashboard from "./Pages/Dashboard/ProjectDashboard/Dashboard.page";
import ChangePassword from "./Pages/Account/Password/ChangePassword.page";
import NotFound from "./Pages/Errors/NotFound.page";
import AddClient from "./Pages/Client/AddClient.page";
import ViewClient from "./Pages/Client/ViewClient.Page";
import { ProjectRoutes } from "./Routes/Project.routes";
import { HCMRoutes } from "./Routes/HCM.routes";
import { InventoryRoutes } from "./Routes/Inventory.routes";
import { FinanceRoutes } from "./Routes/Finance.routes";
import Error500 from "./Pages/Errors/Error500.page";
import AddUser from "./Pages/Account/User/AddUser.page";
import ViewUser from "./Pages/Account/User/ViewUser.page";
import ViewRole from "./Pages/Account/Role/ViewRole.page";
import AddRole from "./Pages/Account/Role/AddRole.page";
import ViewPrivilege from "./Pages/Account/Privilege/ViewPrivilege.page";
import AddPrivilege from "./Pages/Account/Privilege/AddPrivilege.page";
import { useDispatch } from "react-redux";
import { setSelected } from "./Redux/Slice/selectedRow";
import { resize } from "./utils/resize";
import RequestPrint from "./Pages/Projects/Operational/RequestForm/RequestPrint.page";
import DailyReportPrint from "./Pages/Projects/Operational/DailyWorkLog/DailyReportPrint.page";
import ViewQuote from "./Pages/Sales/ViewQuote.page";
import AddQuote from "./Pages/Sales/AddQuote.page";
import UpdatePassword from "./Pages/Account/Password/UpdatePassword.page";
import PVRequestPrint from "./Pages/Finance/FinanceOperational/PVRequest/PVRequestPrint.page";
import BOQ from "./Pages/Projects/Operational/BOQ.page";

function App() {
  // const AccessToken = localStorage.getItem("accessToken");
  const location = useLocation();
  // const navigate = useNavigate();
  // localStorage.clear()

  const dispatch = useDispatch();
  let selected = localStorage.getItem("selected");
  if (selected) {
    dispatch(setSelected(JSON.parse(selected)));
  }
  resize();
  let user;
  /**
   * If the user is set, return true, else return false.
   * @returns A boolean value.
   */
  const isUserSet = () => {
    try {
      user = JSON.parse(jwt_decode(localStorage.getItem("User")));
      if (user) {
        return true;
      } else return false;
    } catch (error) {
      console.log(error);
    }
  };
  let mode = true;
  try {
    mode = localStorage.getItem("mode");
  } catch (error) {}

  const theme = createTheme({
    palette: {
      mode: mode === "false" ? "dark" : "light",
      // mode: "dark",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Paper
        style={{
          display: "grid",
          borderRadius: "0",
          minHeight: "100vh",
        }}
      >
        <Routes>
          {
            isUserSet() &&
              location.pathname === "/" &&
              !user.first_login &&
              window.open("/View/Dashboard", "_self")
            // navigate("/View/Dashboard")
          }
          {!isUserSet() && <Route path="/" element={<LogInPage />} />}
          {isUserSet() && (
            <Route path="View" element={<MainScene user={user} />}>
              <Route path="Dashboard" element={<Dashboard />} />
              <Route path="AddClient" element={<AddClient />} />
              <Route path="ViewClient" element={<ViewClient />} />
              <Route path="Account">
                <Route path="User" element={<ViewUser />}>
                  <Route path="AddUser" element={<AddUser />} />
                </Route>
                <Route path="Role" element={<ViewRole />}>
                  <Route path="AddRole" element={<AddRole />} />
                </Route>
                <Route path="Privilege" element={<ViewPrivilege />}>
                  <Route path="AddPrivilege" element={<AddPrivilege />} />
                </Route>
              </Route>

              {user.actions.find((role) => {
                if (
                  role === "PROJECT_TWO" ||
                  role === "PROJECT_ONE" ||
                  role === "HEAD" ||
                  role === "admin" ||
                  role === "super"
                ) {
                  return true;
                } else return false;
              }) && ProjectRoutes}
              {(user.actions.find((role) => {
                if (
                  role === "HCM_ONE" ||
                  role === "HCM_TWO" ||
                  role === "admin" ||
                  role === "super"
                ) {
                  return true;
                } else return false;
              }) ||
                user.actions?.length === 0) &&
                HCMRoutes}
              {user.actions.find((role) => {
                if (
                  role === "STORE_ONE" ||
                  role === "STORE_TWO" ||
                  role === "admin" ||
                  role === "super"
                ) {
                  return true;
                } else return false;
              }) && InventoryRoutes}
              {user.actions.find((role) => {
                if (
                  role === "FINANCE_ONE" ||
                  role === "FINANCE_TWO" ||
                  role === "admin" ||
                  role === "super"
                ) {
                  return true;
                } else return false;
              }) && FinanceRoutes}
              {user.actions.find((role) => {
                if (role === "sales" || role === "admin" || role === "super") {
                  return true;
                } else return false;
              }) && (
                <Route path="Sales">
                  <Route path="ViewQuote" element={<ViewQuote />} />
                  <Route path="AddQuote" element={<AddQuote />} />
                </Route>
              )}
            </Route>
          )}
          <Route path="BOQ" element={<BOQ />} />

          <Route path="RequestPrint" element={<RequestPrint />} />
          <Route path="PVRequestPrint" element={<PVRequestPrint />} />
          <Route path="DailyReportPrint" element={<DailyReportPrint />} />
          <Route path="ChangePassword" element={<ChangePassword />} />
          <Route path="UpdatePassword" element={<UpdatePassword />} />

          {/* ----------------------------404-------------------------------- */}
          <Route path="*" element={<NotFound />} />
          <Route path="/500" element={<Error500 />} />
        </Routes>
      </Paper>
    </ThemeProvider>
  );
}

export default App;
