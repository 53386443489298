import {
    Autocomplete,
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";

import TextFieldWrapper from "../../../Components/TextFieldWrapper.component";
import "../../../Assets/Sass/AddProject.style.scss";
import { DocumentationURL, DocumentCategoryURL } from "../../../utils/config";
import { SnackBar } from "../../../Components/SnackBar/SnackBar2.component.jsx";
import { get, patcher } from "../../../utils/Caller";
import TitleHeader from "../../../Components/TitleHeader/TitleHeader.component";
import { EditRounded } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setRefresh } from "../../../Redux/Slice/refreshSlice";

const EditDocumentation = (props) => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState("");
    const [msg, setMsg] = useState("");
    const [options, setOptions] = useState([]);
    const [documentationCategoryName, setDocumentationCategoryName] =
        useState("");
    const [optionsId, setOptionsID] = useState();
    const [optionSelect, setOptionSelect] = useState("");
    const selectedRow = useSelector((state) => state.selectedRow.selected);
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();

    useEffect(() => {
        if (documentationCategoryName === "") {
            setDocumentationCategoryName(selectedRow?.documentCategory);
        }
    }, [selectedRow, documentationCategoryName]);

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        name: selectedRow.name,
        description: selectedRow.description,
        documentation_category_id: selectedRow.documentation_category?.id,
    };

    const FORM_VALIDATION = Yup.object().shape({
        name: Yup.string().min(4).required("*Required"),
        unit: Yup.string().min(3).required("*Required"),
        documentation_category_id: Yup.string().required("*Required"),
    });

    const formSubmitHandler = (updateData, actions) => {
        setLoading(true);

        const update = {
            id: selectedRow.id,
            updateData,
        };

        // console.log(update);

        patcher(DocumentationURL, update).then((res) => {
            setLoading(false);
            // console.log(res.error);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                dispatch(setRefresh(!refreshTable));
                setSuccess(true);
                setError(false);
            }
        });
    };

    useEffect(() => {
        get(DocumentCategoryURL, {
            limit: 10,
            filter: {
                name: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.map((name, index) => {
                    data.push(res.data[index].name);
                    return 1;
                });
            res && setOptionsID(res.data);
            setOptions(data);
        });
    }, [filter]);

    return (
        <div>
            <TitleHeader title="Edit Documentation" icon={<EditRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        optionsId.map((name, index) => {
                            if (
                                name.name === optionSelect ||
                                name.name === documentationCategoryName
                            ) {
                                values.documentation_category_id = name.id;
                            }
                            return 0;
                        });
                        // console.log(values);
                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="name"
                                    label="Document Name"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={documentationCategoryName}
                                    loading={options.length < 1 ? true : false}
                                    disablePortal
                                    options={options}
                                    onChange={(event, newValue) => {
                                        setOptionSelect(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            // name="documentation_category"
                                            {...params}
                                            label="Documentation Category"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldWrapper
                                    name="description"
                                    label="Description"
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Apply"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};

export default EditDocumentation;
