import React from "react";
import { useLocation } from "react-router";
import AddMaterialCategory from "./AddMaterialCategory.page";
import ViewMaterialCategory from "./ViewMaterialCategory.page";

const MaterialCategory = () => {
    const location = useLocation();

    return (
        <>
            {location.pathname === "/View/MaterialCategory/AddMaterialCategory" && (
                <AddMaterialCategory />
            )}
            {location.pathname === "/View/MaterialCategory" && <ViewMaterialCategory />}
        </>
    );
};

export default MaterialCategory;
