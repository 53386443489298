import { Add, SearchRounded } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import OperationsTable from "../../../../Components/Table/OperationsTable.component";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import { get } from "../../../../utils/Caller";
import { LeaveAssignmentURL } from "../../../../utils/config";

const LEAVE_ASSIGNMENT_TYPE = ["Direct", "Request", "Plan"];
const LEAVE_REQUEST_STATUS = [
    "Pending",
    "Approved",
    "Rejected",
    "Released",
    "Locked",
];

const ViewLeaveAssignment = () => {
    const [rows, setRows] = useState([]);
    const [loading, setloading] = useState(false);
    const [limit, setLimit] = useState(20);
    const navigate = useNavigate();
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState();
    const [Search, setSearch] = useState();
    const location = useLocation();

    const selectedRow = useSelector((state) => state.selectedRow.selected);

    const columns = [
        {
            field: "is_half_day",
            headerName: "Is Half Day",
            width: 150,
        },
        {
            field: "leaveAssignmentType",
            headerName: "Leave Assignment Type",
            width: 150,
        },
        {
            field: "leaveRequestStatus",
            headerName: "Leave Request Status",
            width: 150,
        },
        {
            field: "action_date",
            headerName: "Action Date",
            width: 150,
        },
        {
            field: "end_date",
            headerName: "End Date",
            width: 150,
        },
        {
            field: "delegated_user_name",
            headerName: "Delegated User Name",
            width: 150,
        },
        {
            field: "employeeName",
            headerName: "employee",
            width: 150,
        },
        {
            field: "attendanceAbscenceType",
            headerName: "Attendance Abscence Type",
            width: 150,
        },
    ];

    useEffect(() => {
        let tableData = [];
        if (rows.length === 0) {
            setloading(true);
        }
        get(LeaveAssignmentURL, {
            limit: limit,
            filter: {
                all: filter,
                employee_id: selectedRow?.id,
            },
        }).then((res) => {
            res && res.data && setloading(false);
            res &&
                res.data?.forEach((data, index) => {
                    tableData.push({
                        ...data,
                        employeeName: data.employee?.first_name,
                        attendanceAbscenceType:
                            data.attendance_abscence_type?.aa_description,
                        leaveAssignmentType:
                            LEAVE_ASSIGNMENT_TYPE[
                                data.leave_assignment_type - 1
                            ],
                        leaveRequestStatus:
                            LEAVE_REQUEST_STATUS[data.leave_request_status - 1],
                    });
                });
            setRows(tableData);
        });
    }, [refreshTable, filter, limit, rows.length, selectedRow?.id]);

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div className="ViewProject">
            <div className="SearchField">
                <TextField
                    name="searchField"
                    label="Search"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={(e) => {
                                        setloading(true);
                                        setFilter(Search);
                                        dispatch(setRefresh(!refreshTable));
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                {location.pathname !== "/View/Dashboard" && (
                    <div style={{ display: "flex", gap: "2ch" }}>
                        <Button
                            variant="contained"
                            color="success"
                            endIcon={<Add />}
                            onClick={() => {
                                navigate(
                                    "/View/HCM/LeaveAssignment/AddLeaveAssignment"
                                );
                            }}
                        >
                            Add Leave Assignment
                        </Button>
                    </div>
                )}
            </div>
            <div className="TableContainer">
                <OperationsTable
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    actions={true}
                    addLimit={addLimit}
                />
            </div>
        </div>
    );
};

export default ViewLeaveAssignment;
