import React, { useCallback, useEffect, useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import { ExpandMore, CheckCircleOutline } from "@mui/icons-material";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import { Box } from "@mui/system";
import { get, patcher } from "../../../../utils/Caller";
import {
    BusinessUnitURL,
    EmployeeURL,
    AttendanceSheetCreateURL,
    AttendanceAbscenceTypeURL,
} from "../../../../utils/config";
import { LoadingButton } from "@mui/lab";
import { SnackBar } from "../../../../Components/SnackBar/SnackBar.component";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";

const AttendanceCreate = () => {
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [limit, setLimit] = useState(8);
    const [filter, setFilter] = useState();
    const [options, setOptions] = useState([]);
    const [optionSelect, setOptionSelect] = useState();
    const [filter2, setFilter2] = useState();
    const [options2, setOptions2] = useState([]);
    const [filter3, setFilter3] = useState();
    const [options3, setOptions3] = useState([]);
    const [optionSelect2, setOptionSelect2] = useState("");
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setMsg] = useState("");
    const [pageNo, setPageNo] = useState(0);
    const [open, setOpen] = useState(true);
    const [date, setDate] = useState();
    const [selectedAATypes, setSelectedAATypes] = useState({});
    const [IncludeReleased, setIncludeReleased] = useState(false);
    const [editRowsModel, setEditRowsModel] = useState({});
    const [editedRows, setEditedRows] = useState({});
    const user = useSelector((state) => state.userState.user);

    const handleEditRowsModelChange = useCallback((model) => {
        setEditRowsModel(model);
    }, []);

    const INITIAL_VALUES = {
        date: "",
        business_unit_id: null,
        employee_id: null,
        isReleaseSelected: IncludeReleased,
    };

    const FORM_VALIDATION = Yup.object().shape({
        // startDate: Yup.string().required("*Required"),
        // endDate: Yup.string()
        //     .required("*Required")
        //     .test(
        //         "test-name",
        //         "Number of days in the date range(Start Date - End Date) can't be greater than from 31",
        //         function (value) {
        //             const diffDays =
        //                 (new Date(value) -
        //                     new Date(this.resolve(Yup.ref("startDate")))) /
        //                 (1000 * 3600 * 24);
        //             if (diffDays > 31) {
        //                 return false;
        //             }
        //             return true;
        //         }
        //     ),
    });

    useEffect(() => {
        get(EmployeeURL, {
            limit: 10,
            filter: {
                all: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.first_name}`
                    );
                });
            setOptions(data);
        });
        get(AttendanceAbscenceTypeURL, {
            limit: 10,
            filter: {
                all: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.aa_description}`
                    );
                });
            setOptions2(data);
        });
        get(BusinessUnitURL, {
            limit: 10,
            filter: {
                all: filter3,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.name}`
                    );
                });
            setOptions3(data);
        });
    }, [filter, filter2, filter3]);
    // console.log(editedRows);

    const handleValueChange = (id, newValue) => {
        let temp = {};
        temp[id] = parseInt(newValue?.split("-")[0]);
        setSelectedAATypes((selectedAAType) => ({
            ...selectedAAType,
            ...temp,
        }));
    };

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        let tableData = [];
        let tableColumns = [
            {
                field: "employee",
                headerName: "Employee",
                width: 400,
            },
            {
                field: "aaType",
                headerName: "AA Type",
                width: 400,
                renderCell: (cellValue) => {
                    return (
                        <Autocomplete
                            key={cellValue.id}
                            defaultValue={cellValue.value}
                            fullWidth
                            size="small"
                            loading={options2.length < 1 ? true : false}
                            disablePortal
                            options={options2}
                            onChange={(_event, newValue) => {
                                // setOptionSelect2(newValue);
                                handleValueChange(cellValue.id, newValue);
                            }}
                            style={{ paddingTop: "1ch" }}
                            renderInput={(params) => (
                                <TextField
                                    size={"small"}
                                    {...params}
                                    label="AA Type"
                                    onChange={(event) => {
                                        setFilter2(event.target.value);
                                    }}
                                />
                            )}
                        />
                    );
                },
            },
        ];
        if (rows.length === 0) {
            setLoading(true);
        }

        get(AttendanceSheetCreateURL, {
            employee_id: data?.employee_id,
            date: data?.date,
            business_unit_id: data?.business_unit_id,
        }).then((res) => {
            res && res.data && setLoading(false);
            res &&
                res.data?.employees?.forEach((data, index) => {
                    // console.log(data);
                    // let temp = {};
                    // data.days?.forEach((day, i) => {
                    //     temp[day.dateKey] = day.hours;
                    //     temp[`${day.dateKey} - attendance_payroll_id`] =
                    //         day.attendance_payroll_id;
                    //     temp[`${day.dateKey} - date`] = day.date;
                    // });
                    let aaTypeValue = null;
                    let employee = `${data?.id} - ${data?.first_name}`;
                    if (data.aaType?.id) {
                        aaTypeValue = `${data.aaType?.attendance_abscence_type.id} - ${data.aaType?.attendance_abscence_type.aa_description}`;
                        setSelectedAATypes({
                            ...selectedAATypes,
                            [index]: parseInt(
                                data.aaType.attendance_abscence_type.id
                            ),
                        });
                    }

                    tableData[index] = {
                        ...tableData[1],
                        id: index,
                        aaType: aaTypeValue,
                        employee,
                    };
                });
            // Object.keys(res.data.actualHours)?.forEach((aHour, index) => {
            //     tableData[0] = {
            //         ...tableData[0],
            //         ...res.data.actualHours,
            //         id: 0,
            //         aaType: "Actual Hours",
            //     };
            //     let columnFormat = {
            //         field: aHour,
            //         headerName: aHour,
            //         editable: true,
            //         width: 100,
            //         type: "number",
            //     };
            //     tableColumns.push(columnFormat);
            // });
            // Object.keys(res.data.targetHours)?.forEach((tHour, index) => {
            //     tableData[1] = {
            //         ...tableData[1],
            //         ...res.data.targetHours,
            //         id: 1,
            //         aaType: "Target Hours",
            //     };
            // });
            setRows(tableData);
            setColumns(tableColumns);
            // console.log(rows);
            // console.log(columns);
        });
    };

    const createAttendance = () => {
        let attendanceList = {
            delegated_username: user?.username,
            attendanceList: [],
        };
        Object.keys(selectedAATypes).forEach((row, i) => {
            // let aaDateHours = [];
            attendanceList.attendanceList.push({
                id: parseInt(selectedAATypes[row]),
                // aaDateHours,
                date,
                employee: parseInt(rows[row]?.employee?.split("-")[0]),
            });
            // if (editedRows[row]) {
            //     Object.keys(editedRows[row]).forEach((cell, i) => {
            //         aaDateHours.push(editedRows[row][cell]);
            //     });
            // }
            // console.log(attendanceList);
        });
        // console.log(attendanceList);
        if (attendanceList.attendanceList.length > 0) {
            apiHandler(attendanceList);
        }
    };

    /**
     *
     * @param {{delegated_username:string,
     * employee_id:number,
     * attendanceList:[{id: number,aaDateHours:[{attendance_payroll_id: number,date: Date,hours: number,}]}]}} data
     */

    const apiHandler = (data) => {
        setLoading(true);
        patcher(AttendanceSheetCreateURL, data).then((res) => {
            setLoading(false);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data?.success) {
                setSuccess(true);
                setError(false);
            }
        });
    };

    const addLimit = () => {
        setLimit(limit + 8);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "2ch" }}>
            <Accordion
                expanded={open}
                onChange={() => {
                    setOpen(!open);
                }}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography fontWeight={600}>Attendance Create</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Formik
                        initialValues={{ ...INITIAL_VALUES }}
                        validationSchema={FORM_VALIDATION}
                        onSubmit={(values, actions) => {
                            setDate(values.date);
                            values.employee_id = parseInt(
                                optionSelect?.split("-")[0]
                            );
                            values.business_unit_id = parseInt(
                                optionSelect2?.split("-")[0]
                            );
                            formSubmitHandler(values, actions);
                        }}
                    >
                        <Form>
                            <Grid
                                container
                                spacing={2}
                                style={{ alignItems: "end" }}
                            >
                                <Grid item>
                                    <FormControl>
                                        <FormLabel>Date</FormLabel>
                                        <TextFieldWrapper
                                            size={"small"}
                                            name="date"
                                            variant="outlined"
                                            type="date"
                                            fullWidth
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        size="small"
                                        loading={
                                            options.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options}
                                        onChange={(event, newValue) => {
                                            setOptionSelect(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                size={"small"}
                                                {...params}
                                                label="Employee"
                                                onChange={(event) => {
                                                    setFilter(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        size="small"
                                        loading={
                                            options3.length < 1 ? true : false
                                        }
                                        disablePortal
                                        options={options3}
                                        onChange={(_event, newValue) => {
                                            setOptionSelect2(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                size={"small"}
                                                {...params}
                                                label="Business Unit"
                                                onChange={(event) => {
                                                    setFilter3(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Checkbox
                                            checked={IncludeReleased}
                                            onChange={(event) => {
                                                setIncludeReleased(
                                                    event.target.checked
                                                );
                                            }}
                                        />
                                        <FormLabel>Include Released</FormLabel>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <div>
                                <LoadingButton
                                    data-testid="AddButton"
                                    loading={loading}
                                    type="submit"
                                    className="ButtonContained"
                                    style={{
                                        marginTop: "2ch",
                                        float: "right",
                                    }}
                                >
                                    {loading ? null : "Search"}
                                </LoadingButton>
                            </div>
                        </Form>
                    </Formik>
                </AccordionDetails>
            </Accordion>
            <Box>
                {columns.length > 0 && (
                    <Paper
                        style={{
                            boxShadow: "0 0 5px silver",
                            padding: "1ch",
                        }}
                        elevation={0}
                    >
                        <Box
                            style={{
                                marginBottom: "1ch",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <LoadingButton
                                data-testid="ApproveButton"
                                loading={loading}
                                color="success"
                                variant="contained"
                                endIcon={<CheckCircleOutline />}
                                onClick={() => {
                                    createAttendance();
                                }}
                            >
                                {loading ? null : "Create"}
                            </LoadingButton>
                        </Box>

                        <DataGrid
                            // checkboxSelection
                            loading={loading}
                            rows={rows}
                            columns={columns}
                            onPageChange={(page) => {
                                if (pageNo < page) {
                                    setPageNo(pageNo + 1);
                                    addLimit();
                                }
                            }}
                            autoHeight={true}
                            pageSize={8}
                            rowsPerPageOptions={[8]}
                            editRowsModel={editRowsModel}
                            onEditRowsModelChange={handleEditRowsModelChange}
                            onCellFocusOut={(e) => {
                                const key = Object.keys(editRowsModel)[0];
                                const dayKey =
                                    editRowsModel[key] &&
                                    Object.keys(editRowsModel[key])[0];
                                if (dayKey) {
                                    let hours =
                                        editRowsModel[key][dayKey].value;
                                    let attendance_payroll_id =
                                        e.row[
                                            `${dayKey} - attendance_payroll_id`
                                        ];
                                    let date = e.row[`${dayKey} - date`];
                                    let editedTemp = { ...editedRows };
                                    let edited = {
                                        attendance_payroll_id,
                                        date,
                                        hours,
                                    };

                                    editedTemp[key] = {
                                        ...editedTemp[key],
                                        [dayKey]: {
                                            ...edited,
                                        },
                                    };
                                    setEditedRows({
                                        ...editedTemp,
                                    });
                                }
                            }}
                        />
                    </Paper>
                )}
            </Box>

            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};

export default AttendanceCreate;
