import {
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Autocomplete,
    TextField,
    Box,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { EditRounded } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setRefresh } from "../../../../Redux/Slice/refreshSlice";
import TextFieldWrapper from "../../../../Components/TextFieldWrapper.component";
import "../../../../Assets/Sass/AddProject.style.scss";
import { SnackBar } from "../../../../Components/SnackBar/SnackBar.component.jsx";
import { patcher, get } from "../../../../utils/Caller";
import TitleHeader from "../../../../Components/TitleHeader/TitleHeader.component";
import {
    EmployeeURL,
    ExternalApplicantURL,
    VacancyApplicantURL,
    VacancyURL,
} from "../../../../utils/config";
import dateFormatter from "../../../../utils/dateFormater";

const EditVacancyApplicant = (props) => {
    const [loading, setLoading] = useState(false);
    const [protectedEdit, setProtectedEdit] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [filter, setFilter] = useState("");
    const [filter2, setFilter2] = useState("");
    const [filter3, setFilter3] = useState("");
    const [options1, setOptions1] = useState([]);
    const [optionsId1, setOptionsID1] = useState();
    const [optionSelect1, setOptionSelect1] = useState("");
    const [options2, setOptions2] = useState([]);
    const [optionsId2, setOptionsID2] = useState();
    const [optionSelect2, setOptionSelect2] = useState("");
    const [options3, setOptions3] = useState([]);
    const [optionsId3, setOptionsID3] = useState();
    const [optionSelect3, setOptionSelect3] = useState("");
    const selectedDetail = useSelector((state) => state.selectedDetail.selected);
    const [applicantStatus, setApplicantStatus] = useState(
        selectedDetail.application_status
    );
    const [IsEmployee, setIsEmployee] = useState(selectedDetail.is_employee);
    const refreshTable = useSelector((state) => state.refreshTable.init);
    const dispatch = useDispatch();
    const [msg, setMsg] = useState("");

    useEffect(() => {
        if (optionSelect1 === "")
            setOptionSelect1(
                `${selectedDetail.external_applicant?.id} - ${selectedDetail.externalApplicant}`
            );
        if (optionSelect2 === "")
            setOptionSelect2(
                `${selectedDetail.employee?.id} - ${selectedDetail.employeeName}`
            );
        if (optionSelect3 === "")
            setOptionSelect3(
                `${selectedDetail.vacancy?.id} - ${selectedDetail.vacancyName}`
            );
    }, [selectedDetail, optionSelect1, optionSelect2, optionSelect3]);

    const APPLICANT_STATUS = ["Pending", "Selected", "Hired", "Accepted"];
    const IS_EMPLOYEE = ["Employee", "External Applicant"];

    const INITIAL_VALUES = {
        isProtectedForEdit: false,
        name: selectedDetail.name,
        application_date: dateFormatter(selectedDetail.application_date),
        remark: selectedDetail.remark,
        scale: selectedDetail.scale,
        result: selectedDetail.result,
        application_status: applicantStatus,
        is_employee: IsEmployee,
        external_applicant_id: selectedDetail.external_applicant?.id,
        employee_id: selectedDetail.employee?.id,
        vacancy_id: selectedDetail.vacancy?.id,
    };

    const FORM_VALIDATION = Yup.object().shape({
        name: Yup.string().min(1).required("*Required"),
        application_date: Yup.string().min(1).required("*Required"),
        remark: Yup.string().min(1),
        scale: Yup.number().min(1),
        result: Yup.number().min(1),
        // application_status: Yup.number().min(1),
        // is_employee: Yup.number().min(1),
        // external_applicant_id: Yup.number().min(1).required("*Required"),
        // employee_id: Yup.number().min(1).required("*Required"),
        // vacancy_id: Yup.number().min(1),
    });

    useEffect(() => {
        get(ExternalApplicantURL, {
            limit: 10,
            filter: {
                applicant_name: filter,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.applicant_name}`
                    );
                });
            res && setOptionsID1(res.data);
            setOptions1(data);
        });

        get(EmployeeURL, {
            limit: 10,
            filter: {
                first_name: filter2,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.first_name}`
                    );
                });
            res && setOptionsID2(res.data);
            setOptions2(data);
        });

        get(VacancyURL, {
            limit: 10,
            filter: {
                remark: filter3,
            },
        }).then((res) => {
            let data = [];
            res &&
                res.data.forEach((name, index) => {
                    data.push(
                        `${res.data[index]?.id} - ${res.data[index]?.remark}`
                    );
                });
            res && setOptionsID3(res.data);
            setOptions3(data);
        });
    }, [filter, filter2, filter3]);

    const formSubmitHandler = (updateData, actions) => {
        setLoading(true);
        const update = {
            id: selectedDetail.id,
            updateData,
        };

        patcher(VacancyApplicantURL, update).then((res) => {
            setLoading(false);
            // console.log(res.error);
            if (res.error) {
                Object.keys(res.error).forEach((data, i) => {
                    setMsg(res.error[data]);
                });
                setSuccess(false);
                setError(true);
            } else if (res.data.success) {
                dispatch(setRefresh(!refreshTable));
                setSuccess(true);
                setError(false);
                props.close();
            }
        });
    };

    return (
        <div>
            <TitleHeader title="Edit Vacancy Result" icon={<EditRounded />} />
            <div className="FormContainer">
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.isProtectedForEdit = protectedEdit;
                        values.application_status = applicantStatus;
                        values.is_employee = IsEmployee;

                        optionsId1.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.applicant_name}` ===
                                optionSelect1
                            ) {
                                values.external_applicant_id = name.id;
                            }
                        });
                        optionsId2.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.first_name}` ===
                                optionSelect2
                            ) {
                                values.employee_id = name.id;
                            }
                        });
                        optionsId3.forEach((name, index) => {
                            if (
                                `${name?.id} - ${name.remark}` === optionSelect3
                            ) {
                                values.vacancy_id = name.id;
                            }
                        });

                        formSubmitHandler(values, actions);
                    }}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="name"
                                    label="Name"
                                    variant="outlined"
                                />
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>Application Date</FormLabel>
                                    <TextFieldWrapper
                                        name="application_date"
                                        variant="outlined"
                                        type="date"
                                    />
                                </FormControl>
                            </Grid>
                            <Box width={"100%"} />
                            <Grid item xs={12}>
                                <TextFieldWrapper
                                    name="remark"
                                    label="Remark"
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="scale"
                                    label="Scale"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    name="result"
                                    label="Result"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldWrapper
                                    select
                                    name="application_status"
                                    label="Application Status"
                                    variant="outlined"
                                    value={applicantStatus}
                                    options={APPLICANT_STATUS ?? " "}
                                    onChange={(e) => {
                                        setApplicantStatus(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    select
                                    name="is_employee"
                                    label="Is Employee"
                                    variant="outlined"
                                    options={IS_EMPLOYEE}
                                    value={IsEmployee}
                                    onChange={(e) => {
                                        setIsEmployee(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    value={optionSelect1}
                                    loading={options1.length < 1 ? true : false}
                                    disablePortal
                                    options={options1}
                                    onChange={(event, newValue) => {
                                        setOptionSelect1(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="External Applicant"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    value={optionSelect2}
                                    loading={options2.length < 1 ? true : false}
                                    disablePortal
                                    options={options2}
                                    onChange={(event, newValue) => {
                                        setOptionSelect2(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Employee"
                                            onChange={(event) => {
                                                setFilter2(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    value={optionSelect3}
                                    loading={options3.length < 1 ? true : false}
                                    disablePortal
                                    options={options3}
                                    onChange={(event, newValue) => {
                                        setOptionSelect3(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Vacancy"
                                            onChange={(event) => {
                                                setFilter3(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={protectedEdit}
                                        onChange={(event) => {
                                            setProtectedEdit(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    <FormLabel>Protected For Edit</FormLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <LoadingButton
                                data-testid="AddButton"
                                loading={loading}
                                type="submit"
                                className="ButtonContained"
                                style={{
                                    marginTop: "2ch",
                                    float: "right",
                                }}
                            >
                                {loading ? null : "Apply"}
                            </LoadingButton>
                        </div>
                    </Form>
                </Formik>
            </div>
            {error || success ? (
                <SnackBar
                    error={error}
                    success={success}
                    message={error ? msg : `Success^${Math.random()}`}
                />
            ) : null}
        </div>
    );
};
export default EditVacancyApplicant;
