import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mode: true,
};

export const ThemeSlice = createSlice({
    name: "mode",
    initialState,
    reducers: {
        setMode: (state, action) => {
            state.mode = action.payload;
        },
    },
});

export const { setMode } = ThemeSlice.actions;

export default ThemeSlice.reducer;
